import React, { useRef, useState, Fragment, useEffect } from "react";
import useHttp from "../../services/use-http";
import { getCrypticCommand, closeCrypticCommand } from "../../services/CrypticCommand-API";
import { Link } from 'react-scroll'


const Cryptic = () => {
    const commandInputRef = useRef("");
    const [command, setCommand] = useState("");
    const [blankCommandError, setBlankCommandError] = useState(false);
    const [error, setError] = useState(false);
    const { sendRequest, data: loadedOutput, status, } = useHttp(getCrypticCommand);
    const { sendRequest: sendRequestExit, data: loadedExitOutput, status: exitStatus, } = useHttp(closeCrypticCommand);

    const latestOutputRef = useRef(null);
    const listRef = useRef < HTMLUListElement | null > (null);
    const dashedLine = ".".repeat(250);
    const scollToRef = useRef();
    const rightArrow = "<<";
    const [outputs, setOutput] = useState([]);
    const [lastOutputs, setLastOutput] = useState([]);
    const [oldOutputs, setOldOutput] = useState([]);
   

    useEffect(() => {
        window.scrollTo({
            top: 150,
            behavior: "smooth",
        });

        if (status === 'completed') {

            if (loadedOutput && loadedOutput.data.errors.status === 'FALSE') {
                const inputCommand = commandInputRef.current.value;
                if (inputCommand.charAt(0) === '*') {
                    inputCommand.slice(1);
                }

                let prevOutput = [];
                if (sessionStorage.getItem('outputCommmand') !== null && sessionStorage.getItem('outputCommmand') !== '') {
                    const storedOutput = (sessionStorage.getItem('outputCommmand'));
                    //const parsedOutput = storedOutput ? JSON.parse(storedOutput) : [];
                    // console.log(parsedOutput);
                    prevOutput.push(storedOutput);
                    setOldOutput(storedOutput);
                    // prevOutput.push(output + "\n" + dashedLine + "\n"
                    //     + inputCommand + "\n" + dashedLine + "\n" + loadedOutput.data.commandResponse[0].text.replace(/Â|¥/g, ''));
                    prevOutput.push(inputCommand + rightArrow + "\n" + dashedLine + "\n" + loadedOutput.data.commandResponse[0].text.replace(/Â|¥|/g, ''))
                    setLastOutput(inputCommand + rightArrow + "\n" + dashedLine + "\n" + loadedOutput.data.commandResponse[0].text.replace(/Â|¥|/g, ''));
                    //document.getElementById('idLatestResult').value=loadedOutput.data.commandResponse[0].text;
                } else {
                    setOldOutput(inputCommand + rightArrow );
                    // prevOutput.push(inputCommand + " \n" + dashedLine + "\n" + loadedOutput.data.commandResponse[0].text.replace(/Â|¥/g, '') + "\n" + dashedLine + "\n");
                    prevOutput.push(inputCommand + rightArrow + "\n" + dashedLine + "\n" + loadedOutput.data.commandResponse[0].text.replace(/Â|¥|/g, ''))
                    setLastOutput( loadedOutput.data.commandResponse[0].text.replace(/Â|¥|/g, ''));
                    //document.getElementById('idLatestResult').value=loadedOutput.data.commandResponse[0].text;
                }
                sessionStorage.setItem("outputCommmand", (prevOutput));
                //  let newOutput = prevOutput.replace(/Â/g, '')
                //  setOutput(item => [...item, newOutput]);
                console.log(loadedOutput);

                setOutput(prevOutput)

                sessionStorage.setItem("SabreSessionToken", loadedOutput.data.echoToken);
                // const substringIndex = output.indexOf(commandInputRef.current.value);
                // if (substringIndex !== -1) {
                //     output.scrollTop = output.scrollHeight;
                //     output.setSelectionRange(substringIndex, substringIndex + commandInputRef.current.value.length);
                // }
                handleClickScroll();
                commandInputRef.current.value = ''
                // var content = document.getElementById('textarea3');
                // content.scrollTop = content.scrollHeight;


            }
            else {
                setError(true)
                commandInputRef.current.value = ''
            }


        }

    }, [status]);
    /**
 * target - target to scroll to (DOM element, scrollTop Number, 'top', or 'bottom'
 * containerEl - DOM element for the container with scrollbars
 */
    var scrollToTarget = function (target, containerEl) {
        // Moved up here for readability:
        var isElement = target && target.nodeType === 1,
            isNumber = Object.prototype.toString.call(target) === '[object Number]';

        if (isElement) {
            containerEl.scrollTop = target.offsetTop - 110;
        } else if (isNumber) {
            containerEl.scrollTop = target;
        } else if (target === 'bottom') {
            containerEl.scrollTop = containerEl.scrollHeight - containerEl.offsetHeight;
        } else if (target === 'top') {
            containerEl.scrollTop = 0;
        }
    };
    const handleClickScroll = () => {
        let idSection = document.getElementById('scrolling_div');
        let idLatestResult = document.getElementById('idLatestResult'); 
        console.log(idSection);
        console.log(idLatestResult);
        scrollToTarget(idLatestResult, idSection);
    };
   
    useEffect(() => {
        if (exitStatus === 'completed') {  
            setOldOutput([]);
            setLastOutput(["Exit Connection"]);
            sessionStorage.setItem("outputCommmand", "");
            sessionStorage.setItem("SabreSessionToken", "");

        }

    }, [exitStatus])


    function exitCommand(event) {

        event.preventDefault();
        if (event.key === 'Enter')
            sendCommand(event)

        sendRequestExit({

            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "data": {
                "UserID": JSON.parse(localStorage.getItem('userID')),
                "TraceId": JSON.parse(localStorage.getItem('traceID')),
                "echoToken": sessionStorage.getItem("SabreSessionToken") !== undefined ? sessionStorage.getItem("SabreSessionToken") : null

            }

        });
        setError(false);
    }



    function sendCommand(event) {
        console.log("sendCommand")
        event.preventDefault();
        const inputCommand = commandInputRef.current.value
        if (inputCommand === '') {
            commandInputRef.current.focus();
            setBlankCommandError(true);
            return;
        } else {
            setBlankCommandError(false);
        }
        const inputJson = ({

            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "data": {
                "UserID": JSON.parse(localStorage.getItem('userID')),
                "TraceId": JSON.parse(localStorage.getItem('traceID')),
                "requestCommand": [
                    {
                        "text": commandInputRef.current.value,
                    }
                ],
                "echoToken": (sessionStorage.getItem("SabreSessionToken") !== undefined && sessionStorage.getItem("SabreSessionToken") !== "") ? sessionStorage.getItem("SabreSessionToken") : null
            }
        });
        sendRequest(inputJson);
        ;
    }
    function changeToUpperCase(event) {
        console.log(event.target.value);
        event.target.value = event.target.value.toUpperCase().replace("\\", "Σ").replace("\'", "¥").replace("[", "¤");
    }
    function keyDownHandler(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendCommand(event);
        }
    }



    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">


                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 mb-30">
                        <div className="card card-primary card-outline">

                            <div className="card-body">
                                <div className="col-md-12 ">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Sabre Host</label>

                                        <form className='form'>
                                            <div className="row">
                                            <label htmlFor="command" className="mb-2 h6 text-primary ">Command<span className="error-icon"> *</span></label>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-flex d-md-flex">
                                                  
                                                    <input id="command"
                                                        ref={commandInputRef}
                                                        onKeyDown={keyDownHandler}
                                                        autoFocus
                                                        type='text'
                                                        placeholder="TYPE COMMAND HERE"
                                                        onInput={changeToUpperCase}
                                                        className="form-control form-control-sm mt-1"
                                                    >
                                                    </input>

                                                    {!error && blankCommandError && <p className="error-text">Command is required.</p>}
                                                    {error && <p className="error-text">Please try again, some error occured.</p>}

                                                {/* </div> */}
                                                {/* <div className="col-lg-3 col-md-5 col-sm-12 col-12">
                                                    <label htmlFor="command" className="mb-2 h6 text-primary ">&nbsp;</label> */}
                                                    <span className='control d-lg-flex d-md-flex'>
                                                    <button className='btn gt-btn_md text-white  gt-main-btn float-lg-end  float-md-end  ms-2 mt-1' id="btnSend" onClick={sendCommand} >
                                                            {status === 'pending' ?
                                                                <i className="fa fa-spinner fa-spin" ></i>
                                                                : 'Send'}
                                                        </button>

                                                        <button className='btn gt-btn_md text-white  float-lg-end  float-md-end gt-main-btn ms-2 mt-1' id="btnExit" onClick={exitCommand}  >

                                                        {exitStatus === 'pending' ?
                                                            <i className="fa fa-spinner fa-spin" ></i>
                                                            : ' Exit'}
                                                        </button>
                                                       
                                                       
                                                    </span>
                                                </div>
                                            </div>



                                            <div id='scrolling_div' className="textBox w-100 mt-2">

                                                {/*    {oldOutputs.map((output, index) => (<div className="display-linebreak">
                                                    {output}
                                                    <hr></hr>
                                                </div>))} */}
                                                <div className="display-linebreak">
                                                    {oldOutputs}
                                                    <br></br>
                                                    {oldOutputs.length !== 0 && dashedLine}
                                                </div>
                                                <div id="idLatestResult" className="display-linebreak">
                                                    {lastOutputs}
                                                    <br></br>
                                                    {lastOutputs.length !== 0 && dashedLine}

                                                </div>
                                               
                                            </div>
                                            <div className="col-md-12 my-2">
                                                <div className="fw-bold">Sabre keys:  
                                                <span className="text-primary"> Σ <span className="fw-light">= \,</span> <span className="text-18px"> ¤ </span><span className="fw-light ms-1">= [ ,</span>  ¥ <span className="fw-light">= '</span></span></div>
                                            </div>



                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>

                </div>
            </div>
        </section >
    </Fragment >

}
export default Cryptic;

