import {
    GET_CONFIGUARTION,
    UPDATE_CONFIGURATION,
    SERACH_CONIGURATION,
    ADD_CONFIGURATION,
}
from './CONSTANTS'
export async function getSingleConfiguration(ID) {
   // console.log(ID);
    const response = await fetch(GET_CONFIGUARTION(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }
   // console.log(data);
    return data;
}
export async function updateConfiguration(updateData) {
   // console.log(updateData);
    const response = await fetch(UPDATE_CONFIGURATION(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update configuration');
    }
    // console.log(data);
    return data;
}
export async function serachConfiguration(searchData) {
    //console.log(searchData)
    const response = await fetch(SERACH_CONIGURATION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()
    
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch configuration');
    }

    // console.log(data)
    return data;


}
export async function addConfiguration(addData){
    // console.log(addData);
    const response =await fetch(ADD_CONFIGURATION(),{
        method:"POST",
        body:JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add configuration');
    }
    //  console.log(data);
    return data;

}