import {
    SEARCH_HOTEL,
    DETAILS_HOTEL, HOTEL_PRICE_CHECK, HOTEL_BOOKING, HOTEL_RETRIEVE_TRIP,
    CANCEL_HOTEL,
    HOTEL_SEND_EMAIL

} from './CONSTANTS'
export async function searchHotel(searchData) {

    const response = await fetch(SEARCH_HOTEL(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}
export async function detailsHotel(searchData) {

    const response = await fetch(DETAILS_HOTEL(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}

export async function hotelPriceCheck(searchData) {

    const response = await fetch(HOTEL_PRICE_CHECK(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}
export async function hotelBooking(searchData) {

    const response = await fetch(HOTEL_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}


export async function getRetrieveHotel(name) {
    console.log(name);
    const response = await fetch(HOTEL_RETRIEVE_TRIP(name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }

    return data;
}

export async function cancelHotel(searchData) {
    const response = await fetch(CANCEL_HOTEL(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}
export async function SendMailHotel(mailData) {
    console.log(mailData);
    const response = await fetch(HOTEL_SEND_EMAIL(), {
        method: 'POST',
        body: JSON.stringify(mailData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not send email.');
    }
    console.log(data);
    return data;

}