import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let rightList = [];
let selectedRightList = [];
let isDisabled = false;

function checkAllSelectedNodes(selectedRightList) {
    let ids = [];
    selectedRightList?.forEach(({ value, children }) => {
        ids = [...ids, value, ...checkAllSelectedNodes(children)];
    });
    return ids;
}

class Widget extends React.Component {
    constructor(props) {        
        isDisabled = parseInt(sessionStorage.getItem('isEditable'))===0?true:false;        
        rightList = props.allRights !== undefined && props.allRights.length > 0 ? props.allRights[0].data.moduleDetail : [];
        selectedRightList = props.selectedRights !== undefined && props.selectedRights.length > 0 ? props.selectedRights : [];
        
        super(props);
        this.state = { checked: checkAllSelectedNodes(selectedRightList), expanded: ['Master'] };       
    }    

    getSelectedItem(value) {
        this.props.fetchSelectedItem(value);
    }

    render() {
        const { checked, expanded } = this.state;
        
        return (
            <CheckboxTree
                nodes={rightList}
                checked={checked}
                expanded={expanded}
                onCheck={checked => this.setState({ checked })}
                onClick={this.getSelectedItem(checked)}
                onExpand={expanded => this.setState({ expanded })}
                disabled={isDisabled !== undefined ? isDisabled === true ? true : false : false}
                iconsClass="fa5"
                icons={{
                    check: <span className="rct-icon rct-icon-check" />,
                    uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    halfCheck: <span className="rct-icon rct-icon-half-check" />,
                    expandClose: <span className="rct-icon rct-icon-expand-close" />,
                    expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                    expandAll: <span className="rct-icon rct-icon-expand-all" />,
                    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                    parentClose: <span className="rct-icon rct-icon-parent-close" />,
                    parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                    leaf: <span className="rct-icon rct-icon-leaf" />,
                }}
            />
        );
    }
}

export default Widget;
