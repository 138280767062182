import { Fragment, useEffect, useState, useCallback } from "react";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { useHistory } from 'react-router-dom';
import { searchOfficeList } from '../../../services/Office-API'
import { serachConfiguration } from "../../../services/Configuration-API";
import useHttp from "../../../services/use-http";
import Dropdown from 'react-select';
import ConfigurationList from './Components/ConfigurationList';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const SearchConfiguration = () => {
    useDocumentTitle('Search Configuration');
    //Constants
    const { t } = useTranslation(['translation.Configuration']);
    const history = useHistory();
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    const { sendRequest: sendConfiguration, status, data: loadedConfigurationData, error: configurationSerachError } = useHttp(serachConfiguration);

  
    //State
    const [companyNameInput, setCompanyNameInput] = useState();
    const [error, setError] = useState(false);
    const [isFirstTime, setFirstTime] = useState("");

    //Change Handlers
    function comapnyNameChaneHandler(event) {     
        setCompanyNameInput(event.value) 
    }
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);
      
        const onSerachConfiguration = ({
            officeID:companyNameInput,
            parentOfficeID:  JSON.parse(localStorage.getItem('officeID')),         
        });
        sendConfiguration(onSerachConfiguration);
    }, [sendConfiguration,companyNameInput])


    useEffect(() => {
        if (status === 'completed') {
            if (loadedConfigurationData['errors'].error['code'] === '1008' || loadedConfigurationData['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedConfigurationData])  
   
    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration:true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    if (configurationSerachError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }
    let loadedData = [];
    for (let i in loadedConfigurationData) {
        if (loadedConfigurationData['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedConfigurationData[i];
            }
        }
    }

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        //console.log(companyArrayData[index].id);
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }
    //Event Handlers
    function onResetHandler(event) {
        history.go('/serachConfiguration');
    }
    function okayHandler() {
        setError(false);
        history.go('/serachConfiguration');

    }


    const configurationList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <ConfigurationList configuration={loadedData} /> : ""

    return (
        <Fragment>
            {error && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i>{t('searchConfiguration')}</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}</label>
                                                        <Dropdown onChange={comapnyNameChaneHandler}
                                                            value={companyOptions.filter(option => option.value === companyNameInput)}
                                                            options={companyOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} 
                                                            controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            autoFocus
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Settings", "Configuration","Settings_Configuration_Search") &&
                                                    <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            { configurationList}
        </Fragment>
    );

}
export default SearchConfiguration;