import { Fragment } from 'react';
const Services = (props) => {
    let service = props.hotelDetails.data.hotelResults[0].hotelAmenities;
    let services = [];
    service.map(result => {
        services.push(<li><i className="fas fa-check"></i>{result.name}</li>)

    });
    return (<Fragment>
        <div className="col-lg-4 margin-top-15 ">
            <div className="tour_detail_right_sidebar">
                <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                        <h3>Amenities</h3>
                    </div>
                    <div className="tour_package_details_bar_list first_child_padding_none">
                        <ul>
                            {services}
                        </ul>
                    </div>
                    {/* <span id='room_section'></span> */}
                </div>
            </div>

        </div>
    </Fragment>)
}

export default Services;