import { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { ChangePassword } from '../../../services/Authentication-API'
import { useTranslation } from 'react-i18next';
import { passwordValidation } from '../../../utils/Validation'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useHttp from '../../../services/use-http'


import { Tooltip as ReactTooltip } from "react-tooltip";

const ChangePasswordForm = () => {
  useDocumentTitle('Change Password')

  const { t } = useTranslation(['translation.ForgotPassword']);
  const oldPasswordInputRef = useRef();
  const NewPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const history = useHistory();
  const { sendRequest, status, data: ChangePasswordDetails, error } = useHttp(ChangePassword);

  const [errors, setErrors] = useState();
  const [oldPasswordInputInputHasError, setOldPasswordInputInputHasError] = useState(false);
  const [oldPasswordValidInputInputHasError, setOldPasswordValidInputInputHasError] = useState(false);
  const [newPasswordInputInputHasError, setnewPasswordInputInputHasError] = useState(false);
  const [newPasswordValidInputInputHasError, setnewPasswordValidInputInputHasError] = useState(false);
  const [confirmPasswordInputInputHasError, setconfirmPasswordInputInputHasError] = useState(false);
  const [confirmPasswordValidInputInputHasError, setconfirmPasswordValidInputInputHasError] = useState(false);
  useEffect(() => {
    oldPasswordInputRef.current.focus();
  }, [])

  const errorHandler = () => {
    setErrors(null);
    oldPasswordInputRef.current.value = '';
    NewPasswordInputRef.current.value = '';
    confirmPasswordInputRef.current.value = '';
  };

  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in ChangePasswordDetails) {
        if (ChangePasswordDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            loadedData = ChangePasswordDetails[i];
            setErrors({
              title: "Message",
              message: `Password updated successfully.`,
            });
          }
        }
        else if (ChangePasswordDetails['errors'].status === 'TRUE') {
        
          if (ChangePasswordDetails.errors.error.code === "1019") {
            setErrors({
              title: "Message",
              message: 'You have already used that password, Please try another combination.',
            });
          } 
          else  if (ChangePasswordDetails.errors.error.code === "1013") {
            setErrors({
              title: "Message",
              message: 'Your old password is not correct.',
            });
          } 
          else {
            setErrors({
              title: "Message",
              message: 'Sorry, an unexpected error has occurred. Please try again.',
            });
          }
        }
      }
    }
    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, ChangePasswordDetails, history])
  const submitHandler = useCallback((event) => {
    event.preventDefault();
    const enteredOldPassword = oldPasswordInputRef.current.value;
    const enteredNewPassword = NewPasswordInputRef.current.value;
    const enteredConfirmPassword = confirmPasswordInputRef.current.value;
    // optional: Could validate here
    if (enteredOldPassword === '') {
      oldPasswordInputRef.current.focus();
      setOldPasswordInputInputHasError(true);
      setOldPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredOldPassword)) {
      setOldPasswordInputInputHasError(false);
      setOldPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setOldPasswordValidInputInputHasError(false);
    }
    if (enteredNewPassword === '') {
      NewPasswordInputRef.current.focus();
      setnewPasswordInputInputHasError(true);
      setnewPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredNewPassword)) {
      setnewPasswordInputInputHasError(false);
      setnewPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setnewPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword === '') {
      NewPasswordInputRef.current.focus();
      setconfirmPasswordInputInputHasError(true);
      setconfirmPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredConfirmPassword)) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword !== enteredNewPassword) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }

    sendRequest({
      loginEmailID: JSON.parse(localStorage.getItem('loginEmailID')),
      newPassword: enteredNewPassword,
      oldPassword: enteredOldPassword
    });
    // optional: Add validation 
  }, [sendRequest]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);
  return (
    <Fragment>
      {errors && (
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}


      <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
        <div className=" container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
              <div className="card card-primary card-outline gt-common_author_boxed mb-5">
                <div className="card-body gt-change-pass">

                  <div className="col-md-10 col-sm-12 mx-auto">
                    <label className="mb-2 gt-heading_theme h5 text-primary mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Change Password</label>
                    <div className="form-group col-md-12">
                      <form onSubmit={submitHandler}>
                        <div className=''>
                          <div className='input-group position-relative  tool-tip-alignment col-sm-12'>
                            <label className="mb-2 h6 text-primary col-lg-12 mt-3" for="email">Old Password <span className="error-icon"> *</span></label>
                            <div className="input-group mb-1 col-lg-12">
                            <input className="form-control form-control-sm pw col-sm-12"
                                type='password' id='oldPassword'
                                ref={oldPasswordInputRef} />
                              <ReactTooltip
                                anchorId="oldPassword"
                                place="bottom"
                                variant="info"
                                className="tooltip"
                                html={t('passwordHint')} />
                                <span className="input-group-text w-50px h-50px">
                                <i className="fa fa-eye-slash text-primary" id="togglePassword1"></i>
                                </span>
                          
                           
                          </div>
                          {oldPasswordInputInputHasError && <p className="error-text">{t('validationOldPassword')}</p>}
                          {oldPasswordValidInputInputHasError && <p className="error-text">{t('validationValidOldpassword')}</p>}

                        </div>
                        <div className='input-group position-relative  tool-tip-alignment col-sm-12'>
                          <label className="mb-2 h6 text-primary col-lg-12 mt-3"
                            htmlFor='password'>New Password <span className="error-icon"> *</span></label>
                            <div className="input-group mb-1 col-lg-12"></div>
                              <input className="form-control pw col-sm-12"
                                type='password'
                                id='NewPassword'
                                ref={NewPasswordInputRef}
                              />
                              <ReactTooltip
                                anchorId="NewPassword"
                                place="bottom"
                                className="tooltip"
                                variant="info"
                                html={t('passwordHint')} />
                                <span className="input-group-text w-50px h-50px">
                                  <i className="fa fa-eye-slash text-primary" id="togglePassword2"></i>
                                </span>
                              
                            </div>  
                            {newPasswordInputInputHasError && <p className="error-text">{t('validationNewPassword')}</p>}
                              {newPasswordValidInputInputHasError && <p className="error-text">{t('validationValidNewpassword')}</p>}
                        </div>
                        <div>

                        </div>
                        <div className='input-group position-relative  tool-tip-alignment col-sm-12'>
                          <label className="mb-2 h6 text-primary col-lg-12 mt-3" htmlFor='OfficeID'>Confirm Password <span className="error-icon"> *</span></label>
                          <div className="input-group mb-1 col-lg-12">
                            <input className="form-control pw col-sm-12"
                              type='password' id='confirmPassword'
                              ref={confirmPasswordInputRef} />
                            <ReactTooltip
                              anchorId="confirmPassword"
                              place="bottom"
                              variant="info"
                              multiline={true}
                              className="tooltip"
                              html={t('passwordHint')} />
                            <span className="input-group-text w-50px h-50px">
                              <i className="fa fa-eye-slash text-primary" id="togglePassword3"></i>
                            </span>
                            
                          </div>
                          {confirmPasswordInputInputHasError && <p className="error-text col-md-12">{t('validationConfirmpassword')}</p>}
                          {confirmPasswordValidInputInputHasError && <p className="error-text col-md-12">{t('validationMatchConfirmpassword')}</p>}
                          <div> </div>
                        </div>
                        <div className='row'>
                          {/* <div className='col-md-12 text-primary'>
                        <input  className="" type='checkbox' id='OfficeID' onclick="myFunction()" /> Show Password
                        </div> */}
                        </div>
                        <div className='actions mt-3'>

                          <button className='btn gt-btn_md text-white gt-main-btn float-end'>
                            {status === 'pending' ? <i className="fa fa-spinner fa-spin" ></i> : 'Submit'}
                          </button>


                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ChangePasswordForm;