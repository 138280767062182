import {

    GET_AIRPROGRAM_DATA,
    GEt_CREDIT_CARD_DATA,
    GET_MEAL_DETAILS_DATA,
    GET_SERVICE_REQUEST_DATA,
    GET_API_KEY_DATA
} from './CONSTANTS';

export async function getAirProgramList() {
    const response = await fetch(GET_AIRPROGRAM_DATA(), {
        method: 'GET',
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;
}
export async function getCreditCardDetails() {
    const response = await fetch(GEt_CREDIT_CARD_DATA(), {
        method: 'GET',
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;
}
export async function getMealDetails() {
    const response = await fetch(GET_MEAL_DETAILS_DATA(), {
        method: 'GET',
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;
}
export async function getSpecialService() {
    const response = await fetch(GET_SERVICE_REQUEST_DATA(), {
        method: 'GET',
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;
}
export async function searchAPIKeys(searchData) {
    const response = await fetch(GET_API_KEY_DATA(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch keys');
    }

    return data;
}