import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import { getRetrieveTrip, getFlightPlusHotelRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';
import { useHistory } from "react-router-dom";
import { getRetrieveHotel } from '../../../../services/Hotel-API'

const WalletUtilizationItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    const { sendRequest: sendRequestRetrieveFlightPlusHotel, status: statusRetrieveFlightPlusHotel, data: loadedRetrieveFlightPlusHotel, error: errorRetrieveFlightPlusHotel } = useHttp(getFlightPlusHotelRetrieveTrip);
    const { sendRequest: sendRequestRetrieveHotel, status: statusRetrieveHotel, data: loadedRetrieveHotel, error: errorRetrieveHotel } = useHttp(getRetrieveHotel);

    function openRetrieveBooking(id, serviceCode) {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        if (serviceCode === "FL")
            sendRequest(id);
        else if (serviceCode === "FLHT") {
            sendRequestRetrieveFlightPlusHotel(id)
        } else {
            sendRequestRetrieveHotel(id);
        }
    }

    useEffect(() => {
        if (status === 'completed') {
            let bookingJSON = [];


            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", loadedRetrieveTripDetails.data.airItinerary[0].isCancelled);

                // history.push({
                //     pathname: '/RetrieveTrip',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])
    useEffect(() => {
        if (statusRetrieveHotel === 'completed') {
            let bookingJSON = [];

            if (loadedRetrieveHotel.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveHotel;
                sessionStorage.setItem("HotelBookingData", JSON.stringify(loadedRetrieveHotel));
                sessionStorage.setItem("myTripIsHotelCancelled", loadedRetrieveHotel.data.hotelResults[0].isCancelled);
                // history.push({
                //     pathname: '/RetrieveHotel',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveHotel?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [statusRetrieveHotel, loadedRetrieveHotel])
    useEffect(() => {
        if (statusRetrieveFlightPlusHotel === 'completed') {
            let bookingJSON = [];
            let isHotelFailed = false, isFlightFailed = false;
            if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                isHotelFailed = (loadedRetrieveFlightPlusHotel.data.requestType === 'ON' && loadedRetrieveHotel.data.hotelResults[0].isCancelled === false && loadedRetrieveFlightPlusHotel.data.hotelResults[0].confirmationNumber === undefined) ? true : false

                isFlightFailed = (loadedRetrieveFlightPlusHotel.data.requestType === 'ON' && loadedRetrieveHotel.data.airItinerary[0].isCancelled === false && loadedRetrieveFlightPlusHotel.data.bookingReferenceID[0].id === undefined) ? true : false
            }
            if (!isHotelFailed && !isFlightFailed) {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    bookingJSON = loadedRetrieveFlightPlusHotel;
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", loadedRetrieveFlightPlusHotel.data.airItinerary[0].isCancelled);
                    sessionStorage.setItem("myTripIsHotelCancelled", loadedRetrieveHotel.data.hotelResults[0].isCancelled);
                    sessionStorage.setItem('isHotelFailed', 'false');
                    // history.push({
                    //     pathname: RETRIEVESHOPPINGCART,
                    //     mytrip: { isCanceled: props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false },
                    //     hotelTrip: { isCanceled: props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false }
                    // });
                    window.open('/TripDetails?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
                }

            } else if (isHotelFailed && !isFlightFailed) {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    bookingJSON = loadedRetrieveFlightPlusHotel;
                    localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", loadedRetrieveFlightPlusHotel.data.airItinerary[0].isCancelled);
                    sessionStorage.setItem('isHotelFailed', 'true');
                    window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
                }

            } else {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    bookingJSON = loadedRetrieveFlightPlusHotel;
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", loadedRetrieveFlightPlusHotel.data.airItinerary[0].isCancelled);
                    sessionStorage.setItem("myTripIsHotelCancelled", loadedRetrieveHotel.data.hotelResults[0].isCancelled);
                    sessionStorage.setItem('isHotelFailed', 'true');
                    window.open('/TripDetails?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
                }

            }



        }
    }, [statusRetrieveFlightPlusHotel, loadedRetrieveFlightPlusHotel])


    return <Fragment>
        {
            (props.reportJSON.sequenceNo !== "0") &&
            <tr>
                <td align={'right'}>{props.reportJSON.sequenceNo}</td>
                {props.reportJSON.confirmationNo !== undefined ?
                    <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking(props.reportJSON.confirmationNo, props.reportJSON.serviceCode)}><div className='min-width-75'>{props.reportJSON.confirmationNo} </div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
                    : <td scope="row"></td>}

                <td scope="row">{getFormatDate(props.reportJSON.transactionDateTime.split('T')[0].replaceAll('-', ''))} {props.reportJSON.transactionDateTime.split('T')[1] !== undefined ? getFormatTime(props.reportJSON.transactionDateTime.split('T')[1].replaceAll(':', '').substring(0, 4)) : ''}</td>
                <td scope="row">{props.reportJSON.companyName}</td>
                <td scope="row">{props.reportJSON.serviceCode === 'FL' ? 'Flight' : props.reportJSON.serviceCode === 'HT' ? 'Hotel' : props.reportJSON.serviceCode === 'FLHT' ? <div className='min-width-165 '>Flight and Hotel</div> : ""}</td>
                <td scope="row">{props.reportJSON.transactionType}</td>
                <td scope="row" align='center'>{props.reportJSON.currencyCode}</td>
                <td scope="row" align={'right'}>{props.reportJSON.transactionAmount}</td>
                <td scope="row" align={'right'}>{props.reportJSON.balanceAmount}</td>
                <td scope="row">{props.reportJSON.remarks}</td>
                <td scope="row" align={'left'}>{props.reportJSON.transactionDoneBy}</td>
            </tr>
        }
        {/* {
            (props.reportJSON.sequenceNo === "0" && props.reportJSON.equivTotalFare !== '') &&
            <tr>
                <td></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
            </tr>
        } */}

    </Fragment>

};

export default WalletUtilizationItem;