import { Fragment } from 'react';

const BillingDetails = (props) => {
    return <Fragment>
        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                Payment
            </h5>
            <div
                aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                <div className='row'>
                    <div className='col-md-12'>

                        {props.print === undefined ? <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="h6 mb-2 text-primary"> Payment Mode </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                <div className="h6 mb-2">{props.hotelBookingJSON.data.paymentInfo.paymentMode[0].name === "CREDITCARD" ? "Credit Card" : props.hotelBookingJSON.data.paymentInfo.paymentMode[0].name === "WALLET" ? "Wallet" : "Profile Credit Card "}</div>
                            </div>
                        </div> : <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div>
                                <span className="h6 mb-2 text-primary"> Payment Mode </span>
                                <span className="h6 mb-2 ms-3">{props.hotelBookingJSON.data.paymentInfo.paymentMode[0].name === "CREDITCARD" ? "Credit Card" : props.hotelBookingJSON.data.paymentInfo.paymentMode[0].name === "WALLET" ? "Wallet" : "Profile Credit Card "}</span>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default BillingDetails;