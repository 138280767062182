import { useHistory } from "react-router-dom";
import { useRef, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { getBase64, generateUUID } from '../../../../utils';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import ApproverDialog from './ApproverDialog';
import FareRulePopupModal from '../../../../components/Common/Flight/FareRulePopupModal';

const ApproverForm = (props) => {
    const airlineCodeInputRef = useRef('');
    const airlineNameInputRef = useRef('');
    const approverInputRef = useRef('');
    const airlineLogoInputRef = useRef('');
    const [pageTitle, setPageTitle] = useState("Approver")
    const [imgUploading, setImgUploading] = useState(false);
    const { t } = useTranslation(['translation.Approver']);

    const [imageBinaryFile, setImageBinaryFile] = useState('');
    const [airlineCodeInputHasError, setAirlineCodeInputHasError] = useState(false);
    const [airlineCodeInvalidInputError, setairlineCodeInvalidInputError] = useState(false);
    const [airlineNameInputHasError, setAirlineNameInputHasError] = useState(false);
    const [airlinenameInvalidInputError, setAirlineNameInvalidInputError] = useState(false);

    const [airlineCodeLengthHasError, setAirlineCodeLengthHasError] = useState(false);
    const [airlineImageInputHasError, setAirlineImageInputHasError] = useState(false);
    const [airlineLogoInputHasError, setAirlineLogoInputHasError] = useState(false);
    const [airlineLogoVisible, setAirlineLogoVisible] = useState(false);

    const [confirm, setConfirm] = useState();
    const [approverRefNumberForDelete, SetApproverRefNumberForDelete] = useState();
    const [errorApproverDialog, setErrorApproverDialog] = useState();

    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    const history = useHistory();

    const maxSelectFile = (event) => {
        let files = event.target.files // create file object
        if (files.length > 1) {
            //  const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null // discard selected file
            return false;
        }
        return true;
    }
    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/NewApprover');
            history.go();
        }

        if (event.target.id === 'btnReset') {

            if (props.loadedProfileDetails.length === 0) {
                history.replace('/NewApprover');
            } else {
                history.go(`/NewApprover/${props.loadedProfileDetails.id}`);
            }
        }
    }
    const checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = ''
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'image/gif']

        // loop access array
        for (let x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err += files[x].type + ' is not a supported format\n';
            }
        };

        if (err !== '') { // if message not same old that mean has error 
            //event.target.value = null // discard selected file
            setImageBinaryFile('');
            uploadimg([]);
            return false;
        }
        return true;

    }

    useEffect(() => {

        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });

        if (props.loadedProfileDetails.length !== 0) {

            // airlineCodeInputRef.current.value = `${props.loadedProfileDetails.airlineCode !== undefined ? props.loadedProfileDetails.airlineCode : ''}`;
            // airlineNameInputRef.current.value = props.loadedProfileDetails.airlineName;
            // if (props.loadedProfileDetails.airlineBinaryImage !== null) {
            //     setImageBinaryFile({
            //         base64URL: props.loadedProfileDetails.airlineBinaryImage,
            //     });

            // }


        }
        if (props.loadedProfileDetails.length === 0) {

            document.getElementById("airlineCode").focus();
            setPageTitle(" Delegate Approver");
            setAirlineCodeInputHasError(true);

        }
        else if (isEditMode === 0) {
            setPageTitle(" View Approver")
            document.getElementById("airlineName").value = 'Human Resources';
            document.getElementById("airlineName").readOnly = true;
            document.getElementById("approver").readOnly = true;
            document.getElementById("airlineCode").readOnly = true;
            document.getElementById("airlineLogo").disabled = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";


        }
        else if (isEditMode === 1) {
            document.getElementById("airlineName").value = 'Human Resources';
            document.getElementById("airlineName").focus();
            document.getElementById("airlineName").readOnly = false;
            document.getElementById("approver").readOnly = false;
            document.getElementById("airlineCode").readOnly = true;
            document.getElementById("airlineLogo").disabled = false;
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            setPageTitle(" Edit Approver");

        }


    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails.airlineName,
    props.loadedProfileDetails.airlineCode,
    props.loadedProfileDetails.airlineLogo,
        isEditMode,
        airlineLogoInputHasError
    ]);

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }
    const [imgfile, uploadimg] = useState([])
    const imgFilehandler = (e) => {

        if (maxSelectFile(e) && checkMimeType(e) && e.target.files.length !== 0) {
            // if return true allow to setState

            uploadimg([]);
            uploadimg(imgfile => [...imgfile, URL.createObjectURL(e.target.files[0])])
            setAirlineImageInputHasError(false);


            setImageBinaryFile({
                selectedFile: e.target.files[0],
                loaded: 0,
            })


            let { file } = imageBinaryFile;

            file = e.target.files[0];

            getBase64(file)
                .then(result => {
                    file["base64"] = result;
                    setImageBinaryFile({
                        base64URL: result,
                        file
                    });
                })
                .catch(err => {
                });

            setImageBinaryFile({
                file: e.target.files[0]
            });
            setAirlineLogoInputHasError(false);

        } else {
            setAirlineLogoInputHasError(false);
        }
        setImgUploading(true);
    }
    useEffect(() => {
        if (imageBinaryFile === '') {
            setAirlineLogoVisible(false);
        }
        else {
            setAirlineLogoVisible(true);
        }

    }, [imageBinaryFile])


    const onBlurCheck = (event) => {
        if (event.target.id !== 'btnReset') {

            const enteredAirlineCode = airlineCodeInputRef.current.value;
            const enteredAirlineName = airlineNameInputRef.current.value;


            // if (enteredAirlineCode.trim().length === 0) {
            //     setAirlineCodeInputHasError(true);
            //     setAirlineCodeLengthHasError(false);
            //     setairlineCodeInvalidInputError(false);

            //     return;

            // }
            // else {
            //     setAirlineCodeInputHasError(false);
            // }
            // if (enteredAirlineCode.length !== 2) {
            //     setAirlineCodeLengthHasError(true);
            //     setAirlineCodeInputHasError(true);
            //     setairlineCodeInvalidInputError(false);
            //     return;
            // } else {
            //     setAirlineCodeLengthHasError(false);
            // }
            // if (!enteredAirlineCode.match('^[a-zA-Z0-9]+$')) {
            //     setairlineCodeInvalidInputError(true);
            //     setAirlineCodeInputHasError(false);
            //     return;
            // } else {
            //     setairlineCodeInvalidInputError(false);
            // }


            // if (enteredAirlineName.trim().length === 0) {
            //     setAirlineNameInputHasError(true);
            //     setAirlineNameInvalidInputError(false);
            //     return;
            // } else {
            //     setAirlineNameInputHasError(false);
            // }
            // if (!enteredAirlineName.match('^[a-zA-Z0-9 .]*$')) {
            //     setAirlineNameInvalidInputError(true);
            //     setAirlineNameInputHasError(false);
            //     return;
            // }
            // else {
            //     setAirlineNameInvalidInputError(false);
            // }

            // console.log(imageBinaryFile)
            // console.log(imageBinaryFile.base64URL)
            // if (imageBinaryFile === '' || imageBinaryFile.base64URL === '' || imageBinaryFile.base64URL === null) {
            //     setAirlineLogoInputHasError(true);
            //     return;
            // }
            // else {
            //     setAirlineLogoInputHasError(false);
            // }

        }
    };
    const submitFormHandler = (event) => {
        event.preventDefault();


        const enteredAirlineCode = airlineCodeInputRef.current.value;
        const enteredAirlineName = airlineNameInputRef.current.value;


        // if (enteredAirlineCode.trim().length === 0) {
        //     airlineCodeInputRef.current.focus();
        //     setAirlineCodeInputHasError(true);
        //     return;
        // } else {
        //     setAirlineCodeInputHasError(false);
        // }
        // if (enteredAirlineCode.length !== 2) {
        //     airlineCodeInputRef.current.focus();
        //     setAirlineCodeLengthHasError(true);
        //     return;
        // }
        // else {
        //     setAirlineCodeLengthHasError(false);
        // }
        // if (!enteredAirlineCode.match('^[a-zA-Z0-9]+$')) {
        //     airlineCodeInputRef.current.focus();
        //     setairlineCodeInvalidInputError(true);
        //     return;
        // }
        // else {
        //     setairlineCodeInvalidInputError(false);
        // }

        // if (enteredAirlineName.trim().length === 0) {
        //     airlineNameInputRef.current.focus();
        //     setAirlineNameInputHasError(true);
        //     return;
        // } else {
        //     setAirlineNameInputHasError(false);

        // }
        // if (!enteredAirlineName.match('^[a-zA-Z0-9 ]*$')) {
        //     airlineNameInputRef.current.focus();
        //     setAirlineNameInvalidInputError(true);
        //     return;
        // } else {
        //     setAirlineNameInvalidInputError(false);
        // }

        // if (imageBinaryFile === '' || imageBinaryFile.base64URL === '') {


        //     document.getElementById("airlineLogo").focus();
        //     setAirlineLogoInputHasError(true);
        //     return;
        // }
        // if (imageBinaryFile.base64URL === null) {

        //     document.getElementById("airlineLogo").focus();
        //     setAirlineLogoInputHasError(true);
        //     return;
        // } else {
        //     setAirlineLogoInputHasError(false);
        // }
        // let ID;
        // if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
        //     ID = '';
        // } else {
        //     ID = props.loadedProfileDetails.id;
        // }

        // props.onAddAirline({
        //     id: ID,
        //     airlineName: enteredAirlineName,
        //     airlineCode: enteredAirlineCode,
        //     airlineBinaryImage: imageBinaryFile.base64URL,
        //     airlineLogo: '',
        //     traceID: JSON.parse(localStorage.getItem('traceID')),
        //     userID: JSON.parse(localStorage.getItem('userID')),
        //     iPAddress: localStorage.getItem("IPAddress"),
        // });

    };

    function onAddApprover(event) {
        event.preventDefault();
    }

    function onApproverConfirm(approverRefNumber) {
        //SetTravelerRefNumberForDelete(travelerRefNumber);
        setConfirm(true);
    }
    function okHandler() {
        //onDeleteTraveler(travelerRefNumberForDelete);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function onEditApprover(approverRefNumber) {
        //setDisabledControl(false);
    }

    function onAddFallbackApprover(event) {
        event.preventDefault();
    }

    function onFallbackApproverConfirm(approverRefNumber) {
        //SetTravelerRefNumberForDelete(travelerRefNumber);
        setConfirm(true);
    }
    function okHandler() {
        //onDeleteTraveler(travelerRefNumberForDelete);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function onEditFallbackApprover(approverRefNumber) {
        //setDisabledControl(false);
    }

    function openApproverPopup() {
        setErrorApproverDialog({
            title: "Employee",
            message: <ApproverDialog errorHandlerApproverDialog={errorHandlerApproverDialog} ></ApproverDialog>,
        });
    }

    function errorHandlerApproverDialog() {
        setErrorApproverDialog(null);
    };

    const airlineImage = props.loadedProfileDetails.airlineBinaryImage === undefined ? ('') :
        (<img height="130" width="65" src={props.loadedProfileDetails.airlineBinaryImage} alt="airline">
        </img>)

    return <Fragment>
        {errorApproverDialog && (
            <FareRulePopupModal
                title={errorApproverDialog.title}
                message={errorApproverDialog.message}
                onConfirm={errorHandlerApproverDialog}
            ></FareRulePopupModal>
        )}
        <form className='form'>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i>{pageTitle}</label>

                                                        {/* Approver Details */}

                                                        <div className='control d-none'>
                                                            <label htmlFor="airlineCode" className="mb-2 h6 text-primary col-lg-12"> Employee Name<span className="error-icon"> *</span></label>
                                                            <input id="airlineCode"
                                                                type='text'
                                                                onInput={changeToUpperCase}
                                                                minLength="2"
                                                                maxLength="2"
                                                                onBlur={onBlurCheck}
                                                                value={'Mr. Rahul Srinivasan'}
                                                                className="form-control form-control-sm"
                                                            >
                                                            </input>
                                                        </div>
                                                        <div className='control'>
                                                            <label htmlFor="airlineName" className="mb-2 h6 text-primary col-lg-12">Employee Name<span className="error-icon"> *</span></label>
                                                            <input id="airlineName"
                                                                ref={airlineNameInputRef}
                                                                minLength="1"
                                                                maxLength="100"
                                                                onBlur={onBlurCheck}
                                                                value={'Mr. Rahul Srinivasan'}
                                                                className="form-control form-control-sm"
                                                            />
                                                            {airlineNameInputHasError && <p className="error-text">{t('validationAirlinename')}</p>}
                                                            {airlinenameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                        </div>
                                                        <div className="row">
                                                            <div className='control col-md-11'>
                                                                <label htmlFor="approver" className="mb-2 h6 text-primary col-lg-12">Select Delegate Approver<span className="error-icon"> *</span></label>
                                                                <input id="approver"
                                                                    ref={approverInputRef}
                                                                    minLength="1"
                                                                    maxLength="100"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                />
                                                                {airlineNameInputHasError && <p className="error-text">{t('validationAirlinename')}</p>}
                                                                {airlinenameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                            </div>
                                                            <div className="col-md-1 mt-4">
                                                                <a className='cursor-pointer d-flex' onClick={() => openApproverPopup()}><i className="fas fa-external-link-alt line-height-22px"></i></a>
                                                            </div>
                                                        </div>

                                                        <div className='control d-none'>

                                                            <label htmlFor="airlineLogo" className="mb-2 h6 text-primary col-lg-12">{t('uploadAirlinelogo')}<span className="error-icon"> *</span></label>
                                                            <br></br>
                                                            <span className="note">{t('imageFormat')}</span>
                                                            <input id="airlineLogo" type="file" className="form-control form-control-sm"
                                                                onChange={imgFilehandler}
                                                            />

                                                            {airlineLogoVisible && <label htmlFor="airlineLogo" className="mb-2 h6 text-primary col-lg-12">{t('airlinelogo')}</label>}
                                                            {imgfile.map((elem) => {
                                                                return < span key={generateUUID(10)}>
                                                                    <span>
                                                                        <img src={elem} ref={airlineLogoInputRef} height="130" width="65" alt="" />

                                                                    </span>

                                                                </span>
                                                            })}
                                                            {!imgUploading && airlineImage}
                                                            {airlineLogoInputHasError && <p className="error-text">{t('validationAirlineLogo')}</p>}
                                                            {!airlineLogoInputHasError && airlineImageInputHasError && <p className="error-text">{t('validationInputAirlineLogo')}</p>}
                                                        </div>
                                                        <div className="row">
                                                            <div className='col-md-12 mt-5'>
                                                                <button id='btnAddApprover' className="btn text-white gt-main-btn ms-1 btn-sm float-lg-end float-md-end" onClick={onAddApprover}>&nbsp; &nbsp;&nbsp; Add &nbsp; &nbsp;&nbsp;</button>
                                                            </div>
                                                        </div>
                                                        <label className="h5 mb-3 col-lg-12 mt-3">Selected Delegate Approver</label>
                                                        <div className='row mt-3'>
                                                            <div className='col-md-12'>
                                                                <div className=' table-responsive mt-2 table-wrapper width-900px'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr >
                                                                                <th scope='col'>S. No.</th>
                                                                                <th scope='col'>Approver</th>
                                                                                <th scope="col">Department</th>
                                                                                <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td>Mr. Vikash Kumar</td>
                                                                                <td>Human Resources</td>
                                                                                <td className='text-center sticky-col last-col bg-light'>
                                                                                    <div className="text-center">
                                                                                        <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn d-none" title="Edit" onClick={() => onEditApprover(1)}><i className="fa-solid fa-pen"></i></a>
                                                                                        <a title="Delete" className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" ><i className="fas fa-trash-alt"></i></a>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr></hr>




                                                        <div className="row">
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="airlineCode" className="mb-2 h6 text-primary col-lg-12"> From Date<span className="error-icon"> *</span></label>
                                                                <input id="airlineCode"
                                                                    type='date'
                                                                    onInput={changeToUpperCase}
                                                                    minLength="2"
                                                                    maxLength="2"
                                                                    defaultValue={"2024-03-12"}
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                >
                                                                </input>
                                                            </div>
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="fallbackApprover" className="mb-2 h6 text-primary col-lg-12">To Date<span className="error-icon"> *</span></label>
                                                                <input id="fallbackApprover"
                                                                    minLength="1"
                                                                    maxLength="100"
                                                                    onBlur={onBlurCheck}
                                                                    type='date'
                                                                    defaultValue={"2024-03-15"}
                                                                    className="form-control form-control-sm"
                                                                />
                                                                {airlineNameInputHasError && <p className="error-text">{t('validationAirlinename')}</p>}
                                                                {airlinenameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="airlineCode" className="mb-2 h6 text-primary col-lg-12"> From Time<span className="error-icon"> *</span></label>
                                                                <input id="airlineCode"
                                                                    type='time'
                                                                    onInput={changeToUpperCase}
                                                                    minLength="2"
                                                                    maxLength="2"
                                                                    onBlur={onBlurCheck}
                                                                    defaultValue={'09:30'}
                                                                    className="form-control form-control-sm"
                                                                >
                                                                </input>
                                                            </div>
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="fallbackApprover" className="mb-2 h6 text-primary col-lg-12">To Time<span className="error-icon"> *</span></label>
                                                                <input id="fallbackApprover"
                                                                    minLength="1"
                                                                    maxLength="100"
                                                                    onBlur={onBlurCheck}
                                                                    defaultValue={'14:30'}
                                                                    className="form-control form-control-sm"
                                                                    type='time'
                                                                />
                                                                {airlineNameInputHasError && <p className="error-text">{t('validationAirlinename')}</p>}
                                                                {airlinenameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                            </div>

                                                        </div>



                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">

                                                               
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    

                                                                    <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Save')}</button> 

                                                                    <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Reset')}</button>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </Fragment>
};
export default ApproverForm;