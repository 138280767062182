

import { getFormatDate, getFormatTime, getMinutetoHrsMin } from '../../../utils/CommonFunction';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const FlightDetails = (props) => {

    let flightData = [];
    if (props.FlightData.airItinerary !== undefined) {
        flightData = props.FlightData.airItinerary;

    } else {
        flightData = props.FlightData;
    }
    let validatingCarrier = flightData.validatingCarrier !== undefined ? flightData.validatingCarrier : props.FlightData.ValidatingCarrier;
    let validatingCarrierName = flightData.validatingCarrierName !== undefined ? flightData.validatingCarrierName : props.FlightData.ValidatingCarrierName;
    let equipmentName = flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].equipment[0].name === undefined ? '' : " " + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].equipment[0].name;
    return (<div className='col-md-12'>
        <div className=''>
            <div className='row'>

                <div className='col-md-6 h6 text-black fw-bold my-auto'><i className="fa-solid fa-clock"></i> Flight Duration</div>
                <div className='col-md-6 h6 text-black fw-bold my-auto'> {getMinutetoHrsMin(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].duration)}</div>
            </div>
            <hr className='my-2'></hr>
            <div className='row'>
                <div className='col-md-6 text-14px fw-bold'>Flight Number</div>
                <div className='col-md-6 text-14px my-auto'>
                    {flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].marketingAirline.code}
                    {" " + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].marketingAirline.flightNumber}
                </div>
                {equipmentName !== '' && <div className='col-md-6 text-14px fw-bold'>Equipment Type</div>}
                {
                    equipmentName !== "" && <div className='col-md-6 text-14px my-auto'>
                        {flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].equipment[0].name === undefined ? '' : " " + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].equipment[0].name}
                    </div>
                }

            </div>
            <div className='row'>
                <div className='col-md-6 text-14px fw-bold'>Journey Duration</div>
                <div className='col-md-6 text-14px my-auto'> {getMinutetoHrsMin(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].duration)}</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-14px fw-bold'>Departure Airport</div>
                <div className='col-md-6 text-14px my-auto'>
                    {flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureAirport.locationName}
                    {"(" + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureAirport.locationCode + ")"}
                    {", " + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureAirport.countryName}
                    {"(" + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureAirport.countryCode + ")"}
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 text-14px fw-bold'>Arrival Airport</div>
                <div className='col-md-6 text-14px my-auto'>
                    {flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalAirport.locationName}
                    {"(" + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalAirport.locationCode + ")"}
                    {", " + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalAirport.countryName}
                    {"(" + flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalAirport.countryCode + ")"}
                </div>
            </div>


            <div className='row'>


                <div className='col-md-6 text-14px fw-bold'>Validating Airline </div>
                <div className='col-md-6 text-14px my-auto'>{validatingCarrierName !== undefined ? validatingCarrierName : ''} ({validatingCarrier})</div>
            </div>

            <div className='row'>
                <div className='col-md-6 text-14px fw-bold'>Estimated Time of Departure</div>

                <div className='col-md-6 text-14px my-auto'>{getFormatDate(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureDate)}
                    {", " + getFormatTime(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].departureTime)}</div>
            </div>
            <div className='row'>

                <div className='col-md-6 text-14px fw-bold'>Estimated Time of Arrival</div>

                <div className='col-md-6 text-14px my-auto'>{getFormatDate(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalDate)}
                    {", " + getFormatTime(flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].arrivalTime)}</div>
            </div>
            {
                getConfigurationByBoolen("ALLOW_B2B") &&
                <div className='row mb-1'>
                    <div className='col-md-6 text-14px fw-bold'>RBD</div>
                    <div className='col-md-6 text-14px my-auto'>
                        {flightData.originDestinationOptions.originDestinationOption[props.rph - 1].flightSegment[0].resBookDesigCode}</div>
                </div>
            }
        </div>
    </div>)
}
export default FlightDetails