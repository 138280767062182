import { Fragment } from 'react';
import { breakTeaxtOnFullStop, parseHotelPolicy } from '../../../utils/CommonFunction';

const Policies = (props) => {
  let cancellationPolicies = "";
  let hotelPolicy = "";
  if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
    props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
      if (result.text.type === 'CancellationPolicy') {
        cancellationPolicies = result.text.value;
      }
    });

    props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
      if (result.text.type === 'Policies') {
        hotelPolicy = result.text.value;
      }
    });
  }

  return (<Fragment>
    <div className="tour_details_boxed mt-2" >
      <h3 className="heading_theme mb-0" >Hotel Policies</h3>
      <div className="tour_details_boxed_inner">
        <div className="tour_package_details_bar_list first_child_padding_none">
          <div className=''>
            {parseHotelPolicy(hotelPolicy)}
            {cancellationPolicies !== '' && < div className='fw-bold text-primary mt-1'> Cancellation Policy</div>}
            {breakTeaxtOnFullStop(cancellationPolicies)}
          </div>
        </div>

      </div>
    </div>
  </Fragment >)
}

export default Policies;