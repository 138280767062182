import { Fragment } from 'react';

const BookingRemarks = (props) => {
    return <Fragment>
       
        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                Booking Remarks
            </h5>
            <div 
                aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                <div className='row'>
                    <div className='col-md-12'>

                        <div className="row">

                            <div className="col-md-12">
                                <div className="h6 mb-2">{props.hotelBookingJSON.data.hotelMiscellaneousInfo.miscellaneousInfo[0].text}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default BookingRemarks;