import { Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import Dropdown from 'react-select';
const HotelBooking = () => {
    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Price' },
        { value: '2', label: 'Hotel Name' }
       
    ];
    let chainOptions = [];
    chainOptions = [
        { value: '1', label: 'lorem1' },
        { value: '2', label: 'lorem2' }
       
    ];
    let propertyOptions = [];
    propertyOptions = [
        { value: '1', label: 'lorem1' },
        { value: '2', label: 'lorem2' }
       
    ];
    const titles_name = [
   
        { value: 'Mr', label: 'Mr' },
        { value: 'Mrs', label: 'Mrs' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Master', label: 'Master' },
        { value: 'Miss', label: 'Miss' },
    
      ];
      const titles_name_child = [
        { value: 'Master', label: 'Master' },
        { value: 'Miss', label: 'Miss' }
    
      ];
      const gender_list = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ];
      const card_list = [
        { value: 'Credit', label: 'Credit' },
        { value: 'Debit', label: 'Debit' },
      ];
      const wheel_list = [
        { value: 'Required', label: 'Required' },
        { value: 'Not Required', label: 'Not Required' },
      ];
      const prefernce_list = [
        { value: 'No Preference', label: 'No Preference' }
      ];
      const airline_provider_list = [
        { value: 'Air Miles', label: 'Air Miles' },
        { value: 'Air New Zealand Air Points', label: 'Air New Zealand Air Points' },
        { value: 'Asiana Club', label: 'Asiana Club' },
        { value: 'Life Miles', label: 'Life Miles' },
        { value: 'Delta Skymiles', label: 'Delta Skymiles' },
    
      ];
    const priceList = [
        { value: 'Price', label: 'Price' },
        { value: 'Duration', label: 'Duration' },
        { value: 'Price + Duration ', label: 'Price + Duration ' },
        { value: 'Outbound Departure Time', label: 'Outbound Departure Time' },
        { value: 'Outbound Arrival Time', label: 'Outbound Arrival Time' },
        { value: 'Outbound Duration', label: 'Outbound Duration' }, 
        { value: 'Outbound Airline', label: 'Outbound Airline' },
        { value: 'Inbound Arrival Time', label: 'Inbound Arrival Time' },
        { value: 'Inbound Duration', label: 'Inbound Duration' },
      ];
    useDocumentTitle('Hotel Search')
    return <Fragment>

    
       
        <section  className="sub-banner">
            <div  className="bg-parallax bg-1"></div>
            <div  className="logo-banner text-center">
              
            </div>
        </section> 

        <section id="theme_search_form_tour" className='mb-3'>
            <div  className="container">
                <div  className="theme_search_form_area">
                    <div  className="row">
                        <div  className="col-lg-12">
                            <div class="col-md-12"><label for="" class="h5 text-primary mb-4"><i class="fas fa-globe-asia"></i> Hotel Summary</label></div>
                            <div  className="tour_search_form">
                                <form action="!#">
                                    <div  className="row">
                                      
                                        <div  className="col-lg-9 col-md-9 col-sm-12 col-12">
                                            <div  className="col-md-12 col-md-push-3 tour_details_boxed_inner">
                                                <section  className="hotel-list">
                                                    <div  className="hotel-list-cn clearfix">
                                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                                            <figure  className="hotel-img float-left gt-w-321">
                                                                <a href="hotel-detail.html" title="">
                                                                    <img src="\.\./\.\./\.\./assets/images/hotel/hotel-bg3.png" alt="" />
                                                                </a>
                                                            </figure>
                                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                                            <div  className="hotel-text booking-hotel-text">
                                                                <div  className="hotel-name">
                                                                    <a href="hotel-detail.html" title="">Hyatt Regency London Stratford
                                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                                    </a>
                                                                </div>
                                                                <div  className="hotel-star-address">
                                                                    <span  className="hotel-star">
                                                                        <i  className="glyphicon glyphicon-star"></i>
                                                                        <i  className="glyphicon glyphicon-star"></i>
                                                                        <i  className="glyphicon glyphicon-star"></i>
                                                                        <i  className="glyphicon glyphicon-star"></i>
                                                                        <i  className="glyphicon glyphicon-star"></i>
                                                                    </span>
                                                                
                                                                    <address  className="hotel-address">
                                                                    4-star hotel with restaurant, connected to a shopping center, near Westfield Stratford City
                                                                    </address>
                                                                    
                                                                </div>
                                                            
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-12 my-auto'>
                                                                    <div className='text-12px ms-3 text-muted'>10a Chestnut Plaza, Westfield Stratford, City, Montfichet Road, Olympic Park, London, England, E20 1GL</div>
                                                                    </div>
                                                                    {/* <div  className="col-md-4 hotel-service text-end">
                                                                        <div  className="price special-price text-primary fw-bold h3 mb-0">$345</div>
                                                                        <div className='text-muted text-10px'>Avg Per Night <br></br>Including Taxes</div>
                                                                    </div> */}
                                                                    <hr className='my-1'></hr>
                                                                    <div className='col-md-12 my-auto'>
                                                                        {/* <div className='text-12px mt-2 text-muted'>
                                                                            <span>1 Room,</span>
                                                                            <span className='ms-1'>2 Nights</span>
                                                                            <span> stay (Taxes & fees included)</span>
                                                                        </div> */}
                                                                        <div className="col-md-12 text-12px text-muted pb-3"> <span className="text-12px text-sky-blue"><i className="fas fa-money-check-alt"></i>  Refundable</span> Source: <span className="text-primary fw-bold">Sabre</span> | Commission:  <span className="text-primary fw-bold">$9.48</span> | Content:  <span className="text-primary fw-bold">Virtuoso</span></div>
                                                                        
                                                                    </div>
                                                                    {/* <div className='col-md-4 text-end'>  
                                                                        <button className="my-2 btn btn_theme btn_sm">Book Now</button>
                                                                    </div> */}
                                                                </div>
                                                                <div className='row'>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                        <h5>1</h5>
                                                        </div>
                                                        <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            Guests
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className=''>
                                                            <div className='row my-2'>
                                                                <div className='col-md-6 text-primary h6'><i className='fa fa-hotel text-12px'></i> Room 1(2 Guests)</div>
                                                                <div className='text-12px'>Please specify the name of one of the guests staying in this room.</div>
                                                             
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-md-2'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>
                                                                    <Dropdown 
                                                                    options={titles_name}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                                <div className='col-md-10'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Middle Name</label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='row'>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Gender<span className="error-icon"> *</span></label>
                                                                    <Dropdown 
                                                                    options={gender_list}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Nationality</label>
                                                                    <Dropdown 
                                                                    options={wheel_list}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Date of Birth <i title=
                                                                    "Date of Birth(Age of Adult should be 12 or above on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                </div>
                                                                </div>
                                                            </div> */}
                                                            
                                                            </div>
                                                            <hr className=''>
                                                            </hr>
                                                            <div className=''>
                                                            <div className='row my-2'>
                                                                <div className='col-md-6 text-primary h6'><i className='fa fa-hotel text-12px'></i> Room 2(2 Guests)</div>
                                                                <div className='col-md-6'>
                                                                    <div className='float-lg-end'>
                                                                        {/* <a className='btn btn-sm ms-1 text-12px btn-primary'><i className='fa fa-plus'></i> Add Adult</a> */}
                                                                        <a className='ms-1  text-12px text-sky-blue'><i className='fa fa-plus'></i> Add Room</a>
                                                                    </div>
                                                                </div>
                                                                <div className='text-12px'>Please specify the name of one of the guests staying in this room.</div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-md-2'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>
                                                                    <Dropdown 
                                                                    options={titles_name_child}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                                <div className='col-md-10'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Middle Name</label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                                                        <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='row'>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Gender<span className="error-icon"> *</span></label>
                                                                    <Dropdown 
                                                                    options={gender_list}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Date of Birth <i title=
                                                                    "Date of Birth(Age of Child should be 2 - 12yrs on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Nationality</label>
                                                                    <Dropdown 
                                                                    options={wheel_list}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    />
                                                                </div>
                                                                </div>
                                                            </div> */}
                                                          
                                                            </div>
                                                            <div className='row'>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="float-md-end float-lg-end">
                                                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                        <h5>2</h5>
                                                        </div>
                                                        <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                            aria-expanded="false" aria-controls="collapseTwo">Booking Contact Person
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse"
                                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Email<span className="error-icon"> *</span></label>
                                                                    <input  type='text' className="mb-0 form-control" id='dob'></input>
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone<span className="error-icon"> *</span></label>
                                                                    <input  type='number' className="mb-0 form-control" id='phone'></input>
                                                                </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone at Destination</label>
                                                                    <input  type='number' className="mb-0 form-control" id='phone'></input>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="float-md-end float-lg-end">
                                                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                        <h5>3</h5>
                                                        </div>
                                                        <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                            aria-expanded="false" aria-controls="collapseFour">
                                                            Seat Selection
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" className="accordion-collapse collapse"
                                                            aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                                            <div className='col-md-12'>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                <ul class="nav nav-pills" id="myTab" role="tablist">
                                                                    <li class="nav-item pb-0" role="presentation">
                                                                    <button class="tabs-btn nav-link active" id="departure" data-bs-toggle="tab"
                                                                        data-bs-target="#departure-tb" type="button" role="tab" aria-controls="departure-tb"
                                                                        aria-selected="true">Departure</button>
                                                                    </li>
                                                                    <li class="nav-item pb-0" role="presentation">
                                                                    <button class="tabs-btn nav-link " id="return" data-bs-toggle="tab"
                                                                        data-bs-target="#return-tb" type="button" role="tab" aria-controls="return-tb"
                                                                        aria-selected="true">Return</button>
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                                <div class="col-md-12 ">
                                                                <div class="px-3 tab-content include-exclude-tab" id="myTabContent">
                                                                    <div class="tab-pane fade show active" id="departure-tb"    role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-md-5 position relative'>
                                                                            <div className=''>
                                                                                <div className='col-md-12'>
                                                                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                                                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                                                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                                                                                </div>
                                                                                <div className='col-md-12'>
                                                                                <div className='card'>
                                                                                    <div className='card-body'>
                                                                                    <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                                                                    <hr>
                                                                                    </hr>
                                                                                    <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                                                                    <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div class="tab-pane fade show " id="return-tb" role="tabpanel" aria-labelledby="return">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-md-5 position relative'>
                                                                            <div className=''>
                                                                                <div className='col-md-12'>
                                                                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                                                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                                                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                                                                                </div>
                                                                                <div className='col-md-12 mt-3'>
                                                                                <div className='card'>
                                                                                    <div className='card-body'>
                                                                                    <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                                                                    <hr>
                                                                                    </hr>
                                                                                    <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                                                                    <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                            <div className='col-md-7 gt-h-400px'>
                                                                            <div className="plane-seats">
                                                                                <div className="cockpit">
                                                                                <h5 className='plane-text'>Please select a seat</h5>
                                                                                </div>
                                                                                <div className="exit exit--front fuselage">
                                                                                </div>
                                                                                <ol className="cabin fuselage">
                                                                                <li className="row row--1">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="1A" checked />
                                                                                        <label for="1A">1A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="1B" />
                                                                                        <label for="1B">1B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="1C" />
                                                                                        <label for="1C">1C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" disabled id="1D" />
                                                                                        <label for="1D">Occupied</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="1E" />
                                                                                        <label for="1E">1E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="1F" />
                                                                                        <label for="1F">1F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--2">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2A" />
                                                                                        <label for="2A">2A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2B" />
                                                                                        <label for="2B">2B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2C" />
                                                                                        <label for="2C">2C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2D" />
                                                                                        <label for="2D">2D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2E" />
                                                                                        <label for="2E">2E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="2F" />
                                                                                        <label for="2F">2F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--3">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3A" />
                                                                                        <label for="3A">3A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3B" />
                                                                                        <label for="3B">3B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3C" />
                                                                                        <label for="3C">3C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3D" />
                                                                                        <label for="3D">3D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3E" />
                                                                                        <label for="3E">3E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="3F" />
                                                                                        <label for="3F">3F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--4">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4A" />
                                                                                        <label for="4A">4A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4B" />
                                                                                        <label for="4B">4B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4C" />
                                                                                        <label for="4C">4C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4D" />
                                                                                        <label for="4D">4D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4E" />
                                                                                        <label for="4E">4E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="4F" />
                                                                                        <label for="4F">4F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--5">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5A" />
                                                                                        <label for="5A">5A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5B" />
                                                                                        <label for="5B">5B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5C" />
                                                                                        <label for="5C">5C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5D" />
                                                                                        <label for="5D">5D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5E" />
                                                                                        <label for="5E">5E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="5F" />
                                                                                        <label for="5F">5F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--6">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6A" />
                                                                                        <label for="6A">6A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6B" />
                                                                                        <label for="6B">6B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6C" />
                                                                                        <label for="6C">6C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6D" />
                                                                                        <label for="6D">6D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6E" />
                                                                                        <label for="6E">6E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="6F" />
                                                                                        <label for="6F">6F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--7">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7A" />
                                                                                        <label for="7A">7A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7B" />
                                                                                        <label for="7B">7B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7C" />
                                                                                        <label for="7C">7C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7D" />
                                                                                        <label for="7D">7D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7E" />
                                                                                        <label for="7E">7E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="7F" />
                                                                                        <label for="7F">7F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--8">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8A" />
                                                                                        <label for="8A">8A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8B" />
                                                                                        <label for="8B">8B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8C" />
                                                                                        <label for="8C">8C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8D" />
                                                                                        <label for="8D">8D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8E" />
                                                                                        <label for="8E">8E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="8F" />
                                                                                        <label for="8F">8F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--9">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9A" />
                                                                                        <label for="9A">9A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9B" />
                                                                                        <label for="9B">9B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9C" />
                                                                                        <label for="9C">9C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9D" />
                                                                                        <label for="9D">9D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9E" />
                                                                                        <label for="9E">9E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="9F" />
                                                                                        <label for="9F">9F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                <li className="row row--10">
                                                                                    <ol className="seats" type="A">
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10A" />
                                                                                        <label for="10A">10A</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10B" />
                                                                                        <label for="10B">10B</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10C" />
                                                                                        <label for="10C">10C</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10D" />
                                                                                        <label for="10D">10D</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10E" />
                                                                                        <label for="10E">10E</label>
                                                                                    </li>
                                                                                    <li className="seat">
                                                                                        <input type="checkbox" id="10F" />
                                                                                        <label for="10F">10F</label>
                                                                                    </li>
                                                                                    </ol>
                                                                                </li>
                                                                                </ol>
                                                                                <div className="exit exit--back fuselage">
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className='row my-4'>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="float-md-end float-lg-end">
                                                                    <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button>
                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                        <h5>4</h5>
                                                        </div>
                                                        <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                                            aria-expanded="false" aria-controls="collapseseven">
                                                            Additional Service - Meal preference, Frequent flyer and more
                                                            </button>
                                                        </h2>
                                                        <div id="collapseseven" className="accordion-collapse collapse"
                                                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <hr className=''>
                                                            </hr>
                                                            <div className='row'>
                                                            <div className='col-md-12'>
                                                                <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Additional Information -</label>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Frequent Flyer Airline</label>
                                                                <Dropdown 
                                                                options={airline_provider_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Frequent Flyer Number </label>
                                                                <input  type='text' className="mb-0 form-control" id='PN'></input>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Special Service Request</label>
                                                                <Dropdown 
                                                                options={wheel_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <hr className=''>
                                                            </hr>
                                                            <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Meal Preference -</label>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className="form-check float-lg-end">
                                                                <input className="form-check-input" type="checkbox" id="chkChainCodeSelectAll"></input>
                                                                <label className="form-check-label" for="chkChainCodeSelectAll">
                                                                Select All Flights
                                                                </label>
                                                                </div>
                                                            </div>
                                                          
                                                            </div>
                                                            <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight # EK1 (DXB > LHR)</label>
                                                                <Dropdown 
                                                                options={prefernce_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Meal Preference</label>
                                                                <Dropdown 
                                                                options={prefernce_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight # EK2 (LHR > DXB)</label>
                                                                <Dropdown 
                                                                options={prefernce_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='control'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Meal Preference</label>
                                                                <Dropdown 
                                                                options={prefernce_list}
                                                                textField=""
                                                                dataItemKey=""
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className='row'>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="float-md-end float-lg-end">
                                                                <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button>
                                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                    </div> */}
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                            <h5>3</h5>
                                                        </div>
                                                        <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                            aria-expanded="false" aria-controls="collapseThree">
                                                        Add Service Fee
                                                        </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse"
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className='row'>
                                                            {/* 
                                                            <div className='col-md-12'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Add Service Fee</label>
                                                            </div>
                                                            */}
                                                            <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <input  type='text' className="mb-0 form-control" id='markup'></input>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className='control'>
                                                                    <Dropdown 
                                                                    options={prefernce_list}
                                                                    textField=""
                                                                    dataItemKey=""
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 my-2">
                                                                <div className="">
                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion_left_side">
                                                            <h5>4</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button className="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                                aria-expanded="false" aria-controls="collapseFive">
                                                                Payment Information
                                                                </button>
                                                            </h2>
                                                            <div id="collapseFive" className="accordion-collapse collapse"
                                                                aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                                                <div className="payment_filed_wrapper">
                                                                <div className='row my-2'>
                                                                    {/* 
                                                                    <div className='col-lg-2 text-muted mb-1 fw-bold h6'>Fare Type </div>
                                                                    */}
                                                                    <div className="col-lg-6 col-md-6 col-sm-6  text-muted">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="white" checked />
                                                                        <label className="form-check-label" for="flexRadioDefault1">
                                                                        Specify Credit Card
                                                                        </label>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6  text-muted">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="white"/>
                                                                        <label className="form-check-label" for="flexRadioDefault2">
                                                                        Pay using Sabre profile Credit Card
                                                                        </label>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                    <div className='control'>
                                                                        <div className="form-group">
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'> Card Type</label>
                                                                        <Dropdown                
                                                                        options={card_list}
                                                                        textField=""
                                                                        dataItemKey=""
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"  />
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                    <div className='control'>
                                                                        <div className="form-group">
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'> Card number</label>
                                                                        <input type="text" className="form-control bg_input"
                                                                            placeholder=""/>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                    <div className='control'>
                                                                        <div className="form-group">
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'>Cardholder name</label>
                                                                        <input type="text" className="form-control bg_input"
                                                                            placeholder=""/>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                    <div className='control'>
                                                                        <div className="form-group">
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'> Date of expiry</label>
                                                                        <input type="date" className="form-control bg_input"
                                                                            placeholder=""/>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='control input-placeholder'>
                                                                    <label htmlFor='address' className='h6 text-primary mb-2'>Address<span className="error-icon"> *</span></label>
                                                                    <textarea id='address'
                                                                        className="form-control form-control-sm"
                                                                        rows={3} cols={100} maxLength={300}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-4'>
                                                                    <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'>Country<span className="error-icon"> *</span></label>
                                                                        <Dropdown 
                                                                        options={wheel_list}
                                                                        textField=""
                                                                        dataItemKey=""
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                        />
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>State<span className="error-icon"> </span></label>
                                                                        <Dropdown 
                                                                        options={wheel_list}
                                                                        textField=""
                                                                        dataItemKey=""
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                        />
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>City<span className="error-icon"> *</span></label>
                                                                        <Dropdown 
                                                                        options={wheel_list}
                                                                        textField=""
                                                                        dataItemKey=""
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                        />
                                                                    </div>
                                                                    </div>
                                                                    <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='postalCode' className='h6 text-primary mb-2'>Postal Code<span className="error-icon"> *</span></label>
                                                                        <input maxLength={12} className="form-control form-control-sm" type='text' 
                                                                        />
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-8 col-md-8 col-sm-8 my-auto">
                                                                    <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Make Payment</button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>            
                                        </div>
                                        <div  className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div className="left_side_search_area">
                                                <div className="left_side_search_boxed">
                                                    <div className="left_side_search_heading">
                                                        <h5 className='my-3'>Booking Details</h5>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <p className=''>Check-in</p>
                                                            <p className='fw-bold text-primary'>Thu 18 May 2023, 12:00 PM</p>
                                                            {/* <p className='text-muted'>12:00 PM</p> */}
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <p className=''>Check-out</p>
                                                            <p className='fw-bold text-primary'>Thu 19 May 2023, 10:00 AM</p>
                                                            {/* <div className='text-muted'>10:00 AM</div> */}
                                                        </div>
                                                        <div className='col-md-12 mt-3'>
                                                            <p className=''> Total length of stay</p>
                                                            <p className='fw-bold text-primary'>1 night</p>
                                                        </div>
                                                        <hr className='my-1'></hr>
                                                        <div className='col-md-12 my-1'>
                                                            <p className=''> You selected</p>
                                                            <p className='fw-bold text-primary'>Double Room</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="left_side_search_area">
                                                <div className="left_side_search_boxed">
                                                    <div className="left_side_search_heading mb-3">
                                                        <h5>Your price summary</h5>
                                                    </div>
                                                    <div className="tour_details_boxed_inner fare-summary">
                                                        <div className="accordion" id="accordionExampletwo">
                                                            <div className="accordion_flex_area ">
                                                                <div className="accordion-item fare-toggle-btn ">
                                                                    <h2 className="accordion-header" id="headingOne">
                                                                        <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">

                                                                            <div className='ms-4 col-md-7'>Base Rate</div>
                                                                            <div className='right-0 position-absolute'>$40,000</div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne1" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExampletwo">
                                                                        <div className="accordion-body">

                                                                            <div className="accordion_itinerary_list">

                                                                                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                    <span className="area_flex_one text-muted">
                                                                                        <span>Guest(4 * $10,000)</span>
                                                                                        <span>$40,000</span>
                                                                                    </span>
                                                                                </label>
                                                                                {/* <label className="form-check-label text-12px text-muted" for="flexCheckDefaultf1">
                                                                                    <span className="area_flex_one">
                                                                                        <span>Children(1 * $20,000)</span>
                                                                                        <span>$20,000</span>
                                                                                    </span>
                                                                                </label> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion_flex_area">
                                                                <div className="accordion-item fare-toggle-btn">
                                                                    <h2 className="accordion-header" id="headingtwo">
                                                                        <button class="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">

                                                                            <div className='ms-4 col-md-7'> Taxes and Fees</div>
                                                                            <div className='right-0 position-absolute'>$20,000</div>
                                                                        </button>
                                                                        {/* <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#faretype2" aria-expanded="true"
                                                                        aria-controls="faretype2">
                                                                    Taxes and Subcharges - $20,000
                                                                    </button> */}
                                                                    </h2>
                                                                    <div id="collapseTwo2" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExampletwo">
                                                                        <div className="accordion-body">
                                                                            <div className="accordion_itinerary_list">
                                                                                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                    <span className="area_flex_one text-muted">
                                                                                        <span>Hotel Taxes and Fees</span>
                                                                                        <span>$10,000</span>
                                                                                    </span>
                                                                                </label>
                                                                                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                    <span className="area_flex_one text-muted">
                                                                                        <span>Service Fee</span>
                                                                                        <span>$10,000</span>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion_flex_area">
                                                                <div className="accordion-item fare-toggle-btn">
                                                                    <h2 className="accordion-header" id="headingthreee">
                                                                        <button class="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree3">
                                                                            <div className='ms-4 col-md-7'> Others  </div>
                                                                            <div className='right-0 position-absolute'>$350</div>
                                                                        </button>
                                                                        {/* <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#faretype3" aria-expanded="true"
                                                                        aria-controls="faretype3">
                                                                    
                                                                    <div className='ms-4 col-md-7'> Other Services  </div>
                                                                    <div className='right-0 position-absolute'>$350</div>
                                                                    </button> */}
                                                                    </h2>
                                                                    <div id="collapsethree3" class="accordion-collapse collapse"
                                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExampletwo">
                                                                        <div className="accordion-body">
                                                                            <div className="accordion_itinerary_list">
                                                                                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                    <span className="area_flex_one text-muted">
                                                                                        <span>Seats</span>
                                                                                        <span>$350</span>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='my-2'></hr>
                                                    <div className="">
                                                        <label className="form-check-label" for="flexCheckDefaultf1">
                                                            <span className="area_flex_one">
                                                                <span className='fw-bold'>Total Amount</span>
                                                                <span className='fw-bold'>$60,350</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* <div className="tour_search_type">
                                                    <div className="">
                                                        <label className="form-check-label" for="flexCheckDefaultf1">
                                                            <span className="area_flex_one">
                                                                <span>Ecotourism</span>
                                                                <span>17</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              
        </section>

    </Fragment>

};

export default HotelBooking;