import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import { searchCommonList, SearchHotelChain } from '../../../../services/Fee-API';
import { searchAllAirline } from '../../../../services/Airline-API';
import { searchCurrency } from '../../../../services/Currency-API';
import { SearchRegionApi } from '../../../../services/Region-API';
import useHttp from '../../../../services/use-http';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import { serachContent } from '../../../../services/Content-API';
import { Tooltip as ReactTooltip } from "react-tooltip";

const FeeForm = (props) => {
    const { t } = useTranslation(['translation.Fee']);

    const bookingDateFromInputRef = useRef('');
    const bookingDateToInputRef = useRef('');
    const travelDateFromInputRef = useRef('');
    const travelDateToInputRef = useRef('');
    const feeCodeInputRef = useRef('');
    const fareBasisCodeValueInputRef = useRef('');
    const feeNameInputRef = useRef('');
    const specificTaxInputRef = useRef('');
    const specificAmountInputRef = useRef('');
    const minimumAmountInputRef = useRef('');

    const [serviceCodeInputHasError, setServiceCodeInputHasError] = useState(false);
    const [feeCodeInputHasError, setFeeCodeInputHasError] = useState(false);
    const [feeCodeInvalidInputError, setFeeCodeInvalidInputError] = useState(false);
    const [feeNameInputHasError, setFeeNameInputHasError] = useState(false);
    const [feeNameInvalidInputError, setFeeNameInvalidInputError] = useState(false);
    const [fareBasisCodeInputHasError, setFareBasisCodeInputHasError] = useState(false);
    const [fareBasisCodeInvalidInputError, setFareBasisCodeInvalidInputError] = useState(false);
    const [specificTaxInputHasError, setSpecificTaxInputHasError] = useState(false);
    const [specificTaxInvalidInputError, setSpecificTaxInvalidInputError] = useState(false);
    const [specificAmountInputHasError, setSpecificAmountInputHasError] = useState(false);
    const [specificAmountInvalidInputError, setSpecificAmountInvalidInputError] = useState(false);
    const [specificMinimumAmountInvalidInputError, setSpecificMinimumAmountInvalidInputError] = useState(false);
    const [feeCalculationCodeInputHasError, setFeeCalculationCodeInputHasError] = useState(false);
    const [bookingDateToInvalidInputError, setBookingDateToInvalidInputError] = useState(false);
    const [travelDateToInvalidInputError, setTravelDateToInvalidInputError] = useState(false);
    const [travelDateFromInvalidInputError, setTravelDateFromInvalidInputError] = useState(false);
    const [travelDateToInvalidInputError2, setTravelDateToInvalidInputError2] = useState(false);

    const [iSDisabled, setiSDisabledDDL] = useState('false');
    const [serviceCode, setServiceCode] = useState('');
    const [channelCode, setChannelCode] = useState('');
    const [cabinCode, setCabinCode] = useState('');
    const [starCategoryCode, setStarCategoryCode] = useState('');
    const [pccCode, setPccCode] = useState('');
    const [feeAppliedForCode, setFeeAppliedForCode] = useState('');
    const [feeCalculationCode, setFeeCalculationCode] = useState('');
    const [faresCode, setFaresCode] = useState('');
    const [displayCode, setDisplayCode] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [regionFromCode, setRegionFromCode] = useState('');
    const [regionToCode, setRegionToCode] = useState('');
    const [isAirlineOptionChanged, setIsAirlineOptionChanged] = useState(false);
    const [isRbdOptionChanged, setIsRbdOptionChanged] = useState(false);
    const [isProviderOptionChanged, setIsProviderOptionChanged] = useState(false);
    const [isChainCodeOptionChanged, setIsChainCodeOptionChanged] = useState(false);

    const [isTaxTypeOptionChanged, setIsTaxTypeOptionChanged] = useState(false);
    const [isFareBasisCodeOptionChanged, setIsFareBasisCodeOptionChanged] = useState(false);

    const serviceChangeHandler = (event) => {
        setServiceCode(event.value);
        setServiceCodeInputHasError(false);
        setIsProviderOptionChanged(false);
    };

    const channelChangeHandler = (event) => {
        setChannelCode(event.label);
    };

    const cabinChangeHandler = (event) => {
        setCabinCode(event.value);
    };

    const starCategoryChangeHandler = (event) => {
        setStarCategoryCode(event.value);
    };

    const pccChangeHandler = (event) => {
        setPccCode(event.value);
    };

    const feeAppliedForChangeHandler = (event) => {
        setFeeAppliedForCode(event.value);
        if (event.label === 'Taxes') {
            if (document.querySelector('#dvTaxType') !== null) {
                document.querySelector('#dvTaxType').style.display = "";
            }
        }
        else {
            if (document.querySelector('#dvTaxType') !== null) {
                document.querySelector('#dvTaxType').style.display = "none";
            }
        }
    };

    const feeCalculationChangeHandler = (event) => {
        setFeeCalculationCode(event.value);
        setFeeCalculationCodeInputHasError(false);
    };

    const faresChangeHandler = (event) => {
        setFaresCode(event.value);
    };

    const displayChangeHandler = (event) => {
        setDisplayCode(event.value);
    };

    const currencyChangeHandler = (event) => {
        setCurrencyCode(event.value);
    };

    const regionFromChangeHandler = (event) => {
        setRegionFromCode(event.value);
    };

    const regionToChangeHandler = (event) => {
        setRegionToCode(event.value);
    };

    const fareBasisCodeIncludeHandleClick = (event) => {
        setIsFareBasisCodeOptionChanged(true);
    }

    const fareBasisCodeExcludeHandleClick = (event) => {
        setIsFareBasisCodeOptionChanged(true);
    }

    const taxTypeAllHandleClick = (event) => {
        setIsTaxTypeOptionChanged(true);
        document.querySelector('#dvSpecificTax').style.display = "none";
    }

    const taxTypeSpecificHandleClick = (event) => {
        setIsTaxTypeOptionChanged(true);
        document.querySelector('#dvSpecificTax').style.display = "";
    }

    const calculationTypePercentage = (event) => {
        document.querySelector('#specificAmount').placeholder = t('specifypercentage');
        document.querySelector('#dvMinimumAmount').style.display = "";
        document.querySelector('#dvValue').className = "col-lg-6 col-sm-12 control mt-2";
    }

    const calculationTypeAmount = (event) => {
        document.querySelector('#specificAmount').placeholder = t('specifyamount');
        document.querySelector('#dvMinimumAmount').style.display = "none";
        document.querySelector('#dvValue').className = "col-lg-6 col-sm-12 control mt-2";
    }

    const { sendRequest: sendRequestCommonListService, statusService, data: loadedService } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListChannel, data: loadedChannel } = useHttp(searchCommonList);
    const { sendRequest: sendRequestAirline, statusAirline, data: loadedAirline } = useHttp(searchAllAirline);
    const { sendRequest: sendRequestCommonListRbd, data: loadedRbd } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListCabinClass, data: loadedCabinClass } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListProvider, data: loadedProvider } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListStarCategory, data: loadedStarCategory } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListFeeAppliedFor, data: loadedFeeAppliedFor } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListFeeCalculation, data: loadedFeeCalculation } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListFares, data: loadedFares } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListDisplay, data: loadedDisplay } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCurrency, data: loadedCurrency } = useHttp(searchCurrency);
    const { sendRequest: sendRequestRegion, data: loadedRegion } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestPcc, data: loadedPcc } = useHttp(serachContent);
    const { sendRequest: sendRequestChainCode, statusChainCode, data: loadedChainCode } = useHttp(SearchHotelChain);


    const history = useHistory();

    const [fareBasisCodeValue, setFareBasisCodeValue] = useState('');

    let airlineSelected = Object.keys(props.loadedFeeDetails).length > 0 ? props.loadedFeeDetails.airlineCode.map(a => a.airlineCode) : [];
    let airlineOptions = [];

    let rbdSelected = Object.keys(props.loadedFeeDetails).length > 0 ? props.loadedFeeDetails.rbdDetails.map(a => a.rbdName) : [];
    let rbdOptions = [];

    let providerSelected = Object.keys(props.loadedFeeDetails).length > 0 ? props.loadedFeeDetails.providers.map(a => a.providersID) : [];
    let providerOptions = [];

    let chainCodeSelected = Object.keys(props.loadedFeeDetails).length > 0 ? props.loadedFeeDetails.chainCode.map(a => a.hotelCode) : [];
    let chainCodeOptions = [];

    let serviceValue = '';
    let cabinClassValue = '';
    let feeAppliedForValue = '';
    let channelValue = '';
    let currencyOptions = [];
    let regionOptions = [];
    let regionValue = '';
    let faresValue = '';
    let displayValue = '';


    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedFeeDetails.length !== 0) {
            var bookingDateFromTemp = props.loadedFeeDetails.bookingDateFrom.toString();
            var bookingDateToTemp = props.loadedFeeDetails.bookingDateTo.toString();
            var travelDateFromTemp = props.loadedFeeDetails.travelDateFrom.toString();
            var travelDateToTemp = props.loadedFeeDetails.travelDateTo.toString();

            if (bookingDateFromTemp.length > 1) {
                bookingDateFromTemp = bookingDateFromTemp.slice(0, 4) + "-" + bookingDateFromTemp.slice(4, 6) + "-" + bookingDateFromTemp.slice(6, 8);
            }
            else {
                bookingDateFromTemp = "";
            }

            if (bookingDateToTemp.length > 1) {
                bookingDateToTemp = bookingDateToTemp.slice(0, 4) + "-" + bookingDateToTemp.slice(4, 6) + "-" + bookingDateToTemp.slice(6, 8);
            }
            else {
                bookingDateToTemp = "";
            }

            if (travelDateFromTemp.length > 1) {
                travelDateFromTemp = travelDateFromTemp.slice(0, 4) + "-" + travelDateFromTemp.slice(4, 6) + "-" + travelDateFromTemp.slice(6, 8);
            }
            else {
                travelDateFromTemp = "";
            }

            if (travelDateToTemp.length > 1) {
                travelDateToTemp = travelDateToTemp.slice(0, 4) + "-" + travelDateToTemp.slice(4, 6) + "-" + travelDateToTemp.slice(6, 8);
            }
            else {
                travelDateToTemp = "";
            }

            bookingDateFromInputRef.current.value = `${bookingDateFromInputRef.current.value === '' ? bookingDateFromTemp : bookingDateFromInputRef.current.value}`;
            bookingDateToInputRef.current.value = `${bookingDateToInputRef.current.value === '' ? bookingDateToTemp : bookingDateToInputRef.current.value}`;
            travelDateFromInputRef.current.value = `${travelDateFromInputRef.current.value === '' ? travelDateFromTemp : travelDateFromInputRef.current.value}`;
            travelDateToInputRef.current.value = `${travelDateToInputRef.current.value === '' ? travelDateToTemp : travelDateToInputRef.current.value}`;

            feeCodeInputRef.current.value = `${feeCodeInputRef.current.value === '' ? props.loadedFeeDetails.feeCode : feeCodeInputRef.current.value}`;
            feeNameInputRef.current.value = `${feeNameInputRef.current.value === '' ? props.loadedFeeDetails.feeName : feeNameInputRef.current.value}`;

            specificAmountInputRef.current.value = `${specificAmountInputRef.current.value === '' ? props.loadedFeeDetails.feeValue : specificAmountInputRef.current.value}`;
            minimumAmountInputRef.current.value = `${minimumAmountInputRef.current.value === '' ? props.loadedFeeDetails.minimumFeeAmount : minimumAmountInputRef.current.value}`;

            let specificTaxData = Object.keys(props.loadedFeeDetails).length > 0 ? props.loadedFeeDetails.specificTaxDetails.map(a => a.taxCode) : [];
            specificTaxInputRef.current.value = `${specificTaxInputRef.current.value === '' ? specificTaxData.toString() : specificTaxInputRef.current.value}`;

            if (isFareBasisCodeOptionChanged === false) {
                if (props.loadedFeeDetails.fareBasis) {
                    document.querySelector('#rdFareBasisCodeInclude').checked = true
                }
                else {
                    document.querySelector('#rdFareBasisCodeExclude').checked = true
                }
            }

            if (fareBasisCodeValue === '') {
                setFareBasisCodeValue(props.loadedFeeDetails.fareBasisCode);
            }

            if (serviceCode === '') {
                setServiceCode(props.loadedFeeDetails.serviceID);
            }

            if (channelCode === '' || channelCode === undefined) {
                if (props.loadedFeeDetails.channelID === 'B2B/B2C') {
                    setChannelCode('Both');
                }
                else {
                    setChannelCode(props.loadedFeeDetails.channelID);
                }
            }

            if (cabinCode === '') {
                setCabinCode(props.loadedFeeDetails.cabinClassID);
            }

            if (starCategoryCode === '') {
                setStarCategoryCode(props.loadedFeeDetails.starCategoryID);
            }

            if (pccCode === '') {
                setPccCode(props.loadedFeeDetails.pcc);
            }

            if (feeAppliedForCode === '') {
                setFeeAppliedForCode(props.loadedFeeDetails.feeAppliedID);
            }

            if (isTaxTypeOptionChanged === false) {
                if (props.loadedFeeDetails.taxType === 'All') {
                    document.querySelector('#rdTaxTypeAll').checked = true
                }
                else {
                    document.querySelector('#rdTaxTypeSpecific').checked = true
                }
            }

            if (feeCalculationCode === '') {
                setFeeCalculationCode(props.loadedFeeDetails.feeCalculationID);
            }

            if (faresCode === '') {
                setFaresCode(props.loadedFeeDetails.faresID);
            }

            if (displayCode === '') {
                setDisplayCode(props.loadedFeeDetails.displayID);
            }

            if (currencyCode === '') {
                setCurrencyCode(props.loadedFeeDetails.currencyCode);
            }

            if (regionFromCode === '') {
                setRegionFromCode(props.loadedFeeDetails.fromRegion);
            }

            if (regionToCode === '') {
                setRegionToCode(props.loadedFeeDetails.toRegion);
            }
        }

        const onSearchCommonListService = ({
            methodName: "service",
            methodCode: "SER",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListService(onSearchCommonListService);

        const onSearchCommonListChannel = ({
            methodName: "Channel",
            methodCode: "CHA",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListChannel(onSearchCommonListChannel);

        const onSearchAirline = ({
            airlineCode: "",
            airlineName: ""
        });

        sendRequestAirline(onSearchAirline);

        const onSearchCommonListRbd = ({
            methodName: "RBD",
            methodCode: "RBD",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListRbd(onSearchCommonListRbd);

        const onSearchCommonListCabinClass = ({
            methodName: "CabinClass",
            methodCode: "CABIN",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListCabinClass(onSearchCommonListCabinClass);

        const onSearchCommonListProvider = ({
            methodName: "Provider",
            methodCode: "PRO",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListProvider(onSearchCommonListProvider);

        const onSearchCommonListStarCategory = ({
            methodName: "StarCategory",
            methodCode: "STAR",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListStarCategory(onSearchCommonListStarCategory);

        const onSearchCommonListFeeAppliedFor = ({
            methodName: "FeeAppliedFor",
            methodCode: "FEE",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListFeeAppliedFor(onSearchCommonListFeeAppliedFor);

        const onSearchCommonListFeeCalculation = ({
            methodName: "FeeCalculation",
            methodCode: "FCAL",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListFeeCalculation(onSearchCommonListFeeCalculation);

        const onSearchCommonListFares = ({
            methodName: "Fares",
            methodCode: "FARE",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListFares(onSearchCommonListFares);

        const onSearchCommonListDisplay = ({
            methodName: "Display",
            methodCode: "DIS",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListDisplay(onSearchCommonListDisplay);

        const onSearchCurrency = ({
            currCode: "",
            currName: ""
        });

        sendRequestCurrency(onSearchCurrency);

        const onSearchRegion = ({
            regionID: "",
            serviceID: "",
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestRegion(onSearchRegion);

        const onSearchPcc = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "serviceID": "",
            "serviceName": "",
            "providerID": "",
            "providerName": "",
            "epr": "",
            "pcc": "",
            "password": "",
            "fromRegion": "-000",
            "fromRegionName": "",
            "toRegion": "-000",
            "toRegionName": "",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "isActive": true,
            "status": 1
        });

        sendRequestPcc(onSearchPcc);

        const onSearchChainCode = ({
            hotelCode: "",
            hotelName: ""
        });

        sendRequestChainCode(onSearchChainCode);


        setiSDisabledDDL('false');

        if (props.loadedFeeDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Fee';
            setiSDisabledDDL('true');
            document.querySelector("#calBookingDateFrom").readOnly = true;
            document.querySelector("#calBookingDateTo").readOnly = true;
            document.querySelector("#calTravelDateFrom").readOnly = true;
            document.querySelector("#calTravelDateTo").readOnly = true;
            document.querySelector("#feeCode").readOnly = true;
            document.querySelector("#feeName").readOnly = true;
            document.querySelector("#txtFareBasisCode").disabled = true;
            document.querySelector("#specificAmount").readOnly = true;

            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";

            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });

            document.getElementById("requiredIndicator").style.display = "none";
            document.getElementById("infoFareBasisCode").style.display = "none";
            document.getElementById("infoSpecificTax").style.display = "none";
            //window.scrollTo(0, 0);
            window.scrollTo({
                top: 100,
                behavior: "smooth",
            });
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Fee';
            document.querySelector("#feeCode").readOnly = true;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL('false');
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            document.getElementById("infoFareBasisCode").style.display = "";
            document.getElementById("infoSpecificTax").style.display = "";
        }

        if (props.loadedFeeDetails.length === 0) {
            document.getElementById("btnNew").style.display = "none";
            document.getElementById('pageTitle').innerHTML = ' New Fee';
            sessionStorage.setItem('isEditable', '100');
        }

    }, [sendRequestCommonListService, sendRequestCommonListChannel,
        sendRequestAirline, sendRequestCommonListRbd, sendRequestCommonListCabinClass,
        sendRequestCommonListProvider, sendRequestCommonListStarCategory,
        sendRequestCommonListFeeAppliedFor, sendRequestCommonListFeeCalculation,
        sendRequestCommonListFares, sendRequestCommonListDisplay, sendRequestCurrency,
        sendRequestRegion, sendRequestPcc, sendRequestChainCode, serviceCode
    ]);

    if (parseInt(sessionStorage.getItem('isEditable')) === 0) {
        // var inputs = document.querySelectorAll('.airlineCheckOption');
        // for (var i = 0; i < inputs.length; i++) {
        //     inputs[i].disabled = true;
        // }

        // var inputs = document.querySelectorAll('.rbdCheckOption');
        // for (var i = 0; i < inputs.length; i++) {
        //     inputs[i].disabled = true;
        // }

        // var inputs = document.querySelectorAll('.providerCheckOption');
        // for (var i = 0; i < inputs.length; i++) {
        //     inputs[i].disabled = true;
        // }

        // var inputs = document.querySelectorAll('.chaincodeCheckOption');
        // for (var i = 0; i < inputs.length; i++) {
        //     inputs[i].disabled = true;
        // }
        var inputs = document.querySelectorAll('input[type=checkbox]');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
        }
        var inputs = document.querySelectorAll('input[type=radio]');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
        }
    }

    let serviceOptions = [];
    let serviceArrayData = [];
    for (let i in loadedService) {
        if (loadedService['errors'].status === 'FALSE') {
            if (i === 'data') {
                serviceArrayData = loadedService[i];
            }
        }
    }

    serviceValue = serviceArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Flight')
    }).map(filteredService => filteredService.id)[0];

    for (let index = 0; index < serviceArrayData.length; index++) {
        serviceOptions = [...serviceOptions, { value: serviceArrayData[index].id, label: serviceArrayData[index].listValue }]
    }

    let channelOptions = [];
    let channelArrayData = [];
    for (let i in loadedChannel) {
        if (loadedChannel['errors'].status === 'FALSE') {
            if (i === 'data') {
                channelArrayData = loadedChannel[i];
            }
        }
    }

    channelValue = channelArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Both')
    }).map(filteredChannel => filteredChannel.listValue)[0];

    for (let index = 0; index < channelArrayData.length; index++) {
        channelOptions = [...channelOptions, { value: channelArrayData[index].id, label: channelArrayData[index].listValue }]
    }

    let airlineArrayData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                airlineArrayData = loadedAirline[i];
            }
        }
    }

    for (let index = 0; index < airlineArrayData.length; index++) {
        airlineOptions = [...airlineOptions, { value: airlineArrayData[index].airlineCode, label: airlineArrayData[index].airlineName }]
    }

    let rbdArrayData = [];
    for (let i in loadedRbd) {
        if (loadedRbd['errors'].status === 'FALSE') {
            if (i === 'data') {
                rbdArrayData = loadedRbd[i];
            }
        }
    }

    for (let index = 0; index < rbdArrayData.length; index++) {
        rbdOptions = [...rbdOptions, { value: rbdArrayData[index].listValue, label: rbdArrayData[index].listValue }]
    }

    let cabinClassOptions = [];
    let cabinClassArrayData = [];
    for (let i in loadedCabinClass) {
        if (loadedCabinClass['errors'].status === 'FALSE') {
            if (i === 'data') {
                cabinClassArrayData = loadedCabinClass[i];
            }
        }
    }

    cabinClassValue = cabinClassArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Economy')
    }).map(filteredCabinClass => filteredCabinClass.id)[0];

    for (let index = 0; index < cabinClassArrayData.length; index++) {
        cabinClassOptions = [...cabinClassOptions, { value: cabinClassArrayData[index].id, label: cabinClassArrayData[index].listValue }]
    }

    let providerArrayData = [];
    for (let i in loadedProvider) {
        if (loadedProvider['errors'].status === 'FALSE') {
            if (i === 'data') {
                providerArrayData = loadedProvider[i];
            }
        }
    }

    for (let index = 0; index < providerArrayData.length; index++) {
        providerOptions = [...providerOptions, { value: providerArrayData[index].id, label: providerArrayData[index].listValue }]
    }

    let starCategoryOptions = [];
    let starCategoryArrayData = [];
    for (let i in loadedStarCategory) {
        if (loadedStarCategory['errors'].status === 'FALSE') {
            if (i === 'data') {
                starCategoryArrayData = loadedStarCategory[i];
            }
        }
    }

    for (let index = 0; index < starCategoryArrayData.length; index++) {
        starCategoryOptions = [...starCategoryOptions, { value: starCategoryArrayData[index].id, label: starCategoryArrayData[index].listValue }]
    }

    let feeAppliedForOptions = [];
    let feeAppliedForArrayData = [];
    for (let i in loadedFeeAppliedFor) {
        if (loadedFeeAppliedFor['errors'].status === 'FALSE') {
            if (i === 'data') {
                feeAppliedForArrayData = loadedFeeAppliedFor[i];
            }
        }
    }

    feeAppliedForValue = feeAppliedForArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Taxes')
    }).map(filteredFeeAppliedFor => filteredFeeAppliedFor.id)[0];

    for (let index = 0; index < feeAppliedForArrayData.length; index++) {
        feeAppliedForOptions = [...feeAppliedForOptions, { value: feeAppliedForArrayData[index].id, label: feeAppliedForArrayData[index].listValue }]
    }

    let feeCalculationOptions = [];
    let feeCalculationArrayData = [];
    for (let i in loadedFeeCalculation) {
        if (loadedFeeCalculation['errors'].status === 'FALSE') {
            if (i === 'data') {
                feeCalculationArrayData = loadedFeeCalculation[i];
            }
        }
    }

    for (let index = 0; index < feeCalculationArrayData.length; index++) {
        feeCalculationOptions = [...feeCalculationOptions, { value: feeCalculationArrayData[index].id, label: feeCalculationArrayData[index].listValue }]
    }

    let faresOptions = [];
    let faresArrayData = [];
    for (let i in loadedFares) {
        if (loadedFares['errors'].status === 'FALSE') {
            if (i === 'data') {
                faresArrayData = loadedFares[i];
            }
        }
    }

    faresValue = faresArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Decimal')
    }).map(filteredFares => filteredFares.id)[0];

    for (let index = 0; index < faresArrayData.length; index++) {
        faresOptions = [...faresOptions, { value: faresArrayData[index].id, label: faresArrayData[index].listValue }]
    }

    let displayOptions = [];
    let displayArrayData = [];
    for (let i in loadedDisplay) {
        if (loadedDisplay['errors'].status === 'FALSE') {
            if (i === 'data') {
                displayArrayData = loadedDisplay[i];
            }
        }
    }

    displayValue = displayArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Show as Fee')
    }).map(filteredDisplay => filteredDisplay.id)[0];

    for (let index = 0; index < displayArrayData.length; index++) {
        displayOptions = [...displayOptions, { value: displayArrayData[index].id, label: displayArrayData[index].listValue }]
    }

    let currencyArrayData = [];
    for (let i in loadedCurrency) {
        if (loadedCurrency['errors'].status === 'FALSE') {
            if (i === 'data') {
                currencyArrayData = loadedCurrency[i];
            }
        }
    }

    for (let index = 0; index < currencyArrayData.length; index++) {
        currencyOptions = [...currencyOptions, { value: currencyArrayData[index].currCode, label: currencyArrayData[index].currName }]
    }

    let regionArrayData = [];
    for (let i in loadedRegion) {
        if (loadedRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                regionArrayData = loadedRegion[i];
            }
        }
    }

    regionValue = regionArrayData.filter(function (value, index, arr) {
        return value.regionName === String('All')
    }).map(filteredRegion => filteredRegion.id)[0];

    for (let index = 0; index < regionArrayData.length; index++) {
        regionOptions = [...regionOptions, { value: regionArrayData[index].id, label: regionArrayData[index].regionName }]
    }

    let pccOptions = [];
    let pccArrayData = [];
    for (let i in loadedPcc) {
        if (loadedPcc['errors'].status === 'FALSE') {
            if (i === 'data') {
                pccArrayData = loadedPcc[i];
            }
        }
    }

    for (let index = 0; index < pccArrayData.length; index++) {
        pccOptions = [...pccOptions, { value: pccArrayData[index].id, label: pccArrayData[index].pcc }]
    }

    let chainCodeArrayData = [];
    for (let i in loadedChainCode) {
        if (loadedChainCode['errors'].status === 'FALSE') {
            if (i === 'data') {
                chainCodeArrayData = loadedChainCode[i];
            }
        }
    }

    for (let index = 0; index < chainCodeArrayData.length; index++) {
        chainCodeOptions = [...chainCodeOptions, { value: chainCodeArrayData[index].hotelCode, label: chainCodeArrayData[index].hotelName }]
    }

    function onBlurCheck() {
        if (feeCodeInputRef.current.value.trim() === '') {
            setFeeCodeInputHasError(true);
            setFeeCodeInvalidInputError(false);
            return;
        }
        else {
            setFeeCodeInputHasError(false);
        }

        if (!feeCodeInputRef.current.value.match('^[0-9a-zA-Z]+$') || feeCodeInputRef.current.value.length < 2) {
            setFeeCodeInvalidInputError(true);
            setFeeCodeInputHasError(false);
            return;
        }
        else {
            setFeeCodeInvalidInputError(false);
        }

        if (feeNameInputRef.current.value.trim() === '') {
            setFeeNameInputHasError(true);
            setFeeNameInvalidInputError(false);
            return;
        }
        else {
            setFeeNameInputHasError(false);
        }

        if (!feeNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
            setFeeNameInvalidInputError(true);
            setFeeNameInputHasError(false);
            return;
        }
        else {
            setFeeNameInvalidInputError(false);
        }

        // if (fareBasisCodeValueInputRef.current.value.trim() === '') {
        //     setFareBasisCodeInputHasError(true);
        //     setFareBasisCodeInvalidInputError(false);
        //     return;
        // }
        // else {
        //     setFareBasisCodeInputHasError(false);
        // }

        if (fareBasisCodeValueInputRef.current.value.trim() !== '') {
            if (!fareBasisCodeValueInputRef.current.value.match('^[a-zA-Z0-9,?*]+$') || fareBasisCodeValueInputRef.current.value.length < 5 || fareBasisCodeValueInputRef.current.value.length > 100) {
                setFareBasisCodeInvalidInputError(true);
                setFareBasisCodeInputHasError(false);
                return;
            }
            else {
                setFareBasisCodeInvalidInputError(false);
            }
        }
        else {
            setFareBasisCodeInvalidInputError(false);
        }

        if (document.querySelector('#rdTaxTypeSpecific').checked) {
            if (specificTaxInputRef.current.value.trim() === '') {
                setSpecificTaxInputHasError(true);
                setSpecificTaxInvalidInputError(false);
                return;
            }
            else {
                setSpecificTaxInputHasError(false);
            }

            if (!specificTaxInputRef.current.value.match('^[a-zA-Z,]+$') || specificTaxInputRef.current.value.length > 200) {
                setSpecificTaxInvalidInputError(true);
                setSpecificTaxInputHasError(false);
                return;
            }
            else {
                setSpecificTaxInvalidInputError(false);
            }
        }

        if (specificAmountInputRef.current.value.trim() === '') {
            setSpecificAmountInputHasError(true);
            setSpecificAmountInvalidInputError(false);
            return;
        }
        else {
            setSpecificAmountInputHasError(false);
        }

        if (!specificAmountInputRef.current.value.match(/^\d+(\.\d+)?$/) || specificAmountInputRef.current.value.length > 10) {
            setSpecificAmountInvalidInputError(true);
            setSpecificAmountInputHasError(false);
            return;
        }
        else {
            setSpecificAmountInvalidInputError(false);
        }

        if (document.querySelector('#rdCalculationTypePercentage').checked) {
            if (minimumAmountInputRef.current.value.trim() !== '') {
                if (!minimumAmountInputRef.current.value.match(/^\d+(\.\d+)?$/) || minimumAmountInputRef.current.value.length > 10) {
                    setSpecificMinimumAmountInvalidInputError(true);
                    return;
                }
                else {
                    setSpecificMinimumAmountInvalidInputError(false);
                }
            }
        }

        if (serviceCode === '') {
            setServiceCodeInputHasError(true);
            return;
        }
        else {
            setServiceCodeInputHasError(false);
        }

        if (feeCalculationCode === '') {
            setFeeCalculationCodeInputHasError(true);
            return;
        }
        else {
            setFeeCalculationCodeInputHasError(false);
        }

        const bookingDateFrom = new Date(bookingDateFromInputRef.current.value);
        const bookingDateTo = new Date(bookingDateToInputRef.current.value);
        if (bookingDateFrom > bookingDateTo) {
            setBookingDateToInvalidInputError(true);
            return;
        }
        else {
            setBookingDateToInvalidInputError(false);
        }

        var travelDateFrom = new Date(travelDateFromInputRef.current.value);
        travelDateFrom.setHours(0, 0, 0, 0)
        var travelDateTo = new Date(travelDateToInputRef.current.value);
        travelDateTo.setHours(0, 0, 0, 0)
        var today = new Date();
        today.setHours(0, 0, 0, 0)

        if (travelDateFrom < today) {
            setTravelDateFromInvalidInputError(true);
            return;
        }
        else {
            setTravelDateFromInvalidInputError(false);
        }

        if (travelDateTo < today) {
            setTravelDateToInvalidInputError2(true);
            return;
        }
        else {
            setTravelDateToInvalidInputError2(false);
        }

        if (travelDateFrom > travelDateTo) {
            setTravelDateToInvalidInputError(true);
            return;
        }
        else {
            setTravelDateToInvalidInputError(false);
        }
    }

    function submitFormHandler(event) {
        event.preventDefault();

        if (feeCodeInputRef.current.value.trim() === '') {
            feeCodeInputRef.current.focus();
            setFeeCodeInputHasError(true);
            setFeeCodeInvalidInputError(false);
            return;
        }
        else {
            setFeeCodeInputHasError(false);
        }

        if (!feeCodeInputRef.current.value.match('^[0-9a-zA-Z]+$')) {
            feeCodeInputRef.current.focus();
            setFeeCodeInvalidInputError(true);
            setFeeCodeInputHasError(false);
            return;
        }
        else {
            setFeeCodeInvalidInputError(false);
        }

        if (feeNameInputRef.current.value.trim() === '') {
            feeNameInputRef.current.focus();
            setFeeNameInputHasError(true);
            setFeeNameInvalidInputError(false);
            return;
        }
        else {
            setFeeNameInputHasError(false);
        }

        if (!feeNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
            feeNameInputRef.current.focus();
            setFeeNameInvalidInputError(true);
            setFeeNameInputHasError(false);
            return;
        }
        else {
            setFeeNameInvalidInputError(false);
        }

        // if (fareBasisCodeValueInputRef.current.value.trim() === '') {
        //     fareBasisCodeValueInputRef.current.focus();
        //     setFareBasisCodeInputHasError(true);
        //     setFareBasisCodeInvalidInputError(false);
        //     return;
        // }
        // else {
        //     setFareBasisCodeInputHasError(false);
        // }

        if (fareBasisCodeValueInputRef.current.value.trim() !== '') {
            if (!fareBasisCodeValueInputRef.current.value.match('^[a-zA-Z0-9,?*]+$') || fareBasisCodeValueInputRef.current.value.length < 5 || fareBasisCodeValueInputRef.current.value.length > 100) {
                fareBasisCodeValueInputRef.current.focus();
                setFareBasisCodeInvalidInputError(true);
                setFareBasisCodeInputHasError(false);
                return;
            }
            else {
                setFareBasisCodeInvalidInputError(false);
            }
        }
        else {
            setFareBasisCodeInvalidInputError(false);
        }

        if (document.querySelector('#rdTaxTypeSpecific').checked) {
            if (specificTaxInputRef.current.value.trim() === '') {
                specificTaxInputRef.current.focus();
                setSpecificTaxInputHasError(true);
                setSpecificTaxInvalidInputError(false);
                return;
            }
            else {
                setSpecificTaxInputHasError(false);
            }

            if (!specificTaxInputRef.current.value.match('^[a-zA-Z,]+$') || specificTaxInputRef.current.value.length > 200) {
                specificTaxInputRef.current.focus();
                setSpecificTaxInvalidInputError(true);
                setSpecificTaxInputHasError(false);
                return;
            }
            else {
                setSpecificTaxInvalidInputError(false);
            }
        }

        if (specificAmountInputRef.current.value.trim() === '') {
            specificAmountInputRef.current.focus();
            setSpecificAmountInputHasError(true);
            setSpecificAmountInvalidInputError(false);
            return;
        }
        else {
            setSpecificAmountInputHasError(false);
        }

        if (!specificAmountInputRef.current.value.match(/^\d+(\.\d+)?$/) || specificAmountInputRef.current.value.length > 10) {
            specificAmountInputRef.current.focus();
            setSpecificAmountInvalidInputError(true);
            setSpecificAmountInputHasError(false);
            return;
        }
        else {
            setSpecificAmountInvalidInputError(false);
        }

        if (document.querySelector('#rdCalculationTypePercentage').checked) {
            if (minimumAmountInputRef.current.value.trim() !== '') {
                if (!minimumAmountInputRef.current.value.match(/^\d+(\.\d+)?$/) || minimumAmountInputRef.current.value.length > 10) {
                    minimumAmountInputRef.current.focus();
                    setSpecificMinimumAmountInvalidInputError(true);
                    return;
                }
                else {
                    setSpecificMinimumAmountInvalidInputError(false);
                }
            }
        }

        if (serviceCode === '') {
            setServiceCodeInputHasError(true);
            return;
        }
        else {
            setServiceCodeInputHasError(false);
        }

        if (feeCalculationCode === '') {
            if (document.querySelector('#rdCalculationTypeAmount').checked) {
                document.querySelector('#rdCalculationTypeAmount').focus();
            }
            else {
                document.querySelector('#rdCalculationTypePercentage').focus();
            }
            setFeeCalculationCodeInputHasError(true);
            return;
        }
        else {
            setFeeCalculationCodeInputHasError(false);
        }

        const bookingDateFrom = new Date(bookingDateFromInputRef.current.value);
        const bookingDateTo = new Date(bookingDateToInputRef.current.value);
        if (bookingDateFrom > bookingDateTo) {
            setBookingDateToInvalidInputError(true);
            document.querySelector('#calBookingDateTo').focus();
            return;
        }
        else {
            setBookingDateToInvalidInputError(false);
        }

        var travelDateFrom = new Date(travelDateFromInputRef.current.value);
        travelDateFrom.setHours(0, 0, 0, 0)
        var travelDateTo = new Date(travelDateToInputRef.current.value);
        travelDateTo.setHours(0, 0, 0, 0)
        var today = new Date();
        today.setHours(0, 0, 0, 0)

        if (travelDateFrom < today) {
            setTravelDateFromInvalidInputError(true);
            document.querySelector('#calTravelDateFrom').focus();
            return;
        }
        else {
            setTravelDateFromInvalidInputError(false);
        }

        if (travelDateTo < today) {
            setTravelDateToInvalidInputError2(true);
            document.querySelector('#calTravelDateTo').focus();
            return;
        }
        else {
            setTravelDateToInvalidInputError2(false);
        }

        if (travelDateFrom > travelDateTo) {
            setTravelDateToInvalidInputError(true);
            document.querySelector('#calTravelDateTo').focus();
            return;
        }
        else {
            setTravelDateToInvalidInputError(false);
        }

        let finalSelectedAirlineOptions = [];
        var inputs = document.querySelectorAll('.airlineCheckOption');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                finalSelectedAirlineOptions.push({
                    airlineCode: inputs[i].value
                });
            }
        }

        let finalSelectedRbdOptions = [];
        var inputs = document.querySelectorAll('.rbdCheckOption');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                finalSelectedRbdOptions.push({
                    rbdName: inputs[i].value
                });
            }
        }

        let finalSelectedProviderOptions = [];
        var inputs = document.querySelectorAll('.providerCheckOption');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                finalSelectedProviderOptions.push({
                    providersID: inputs[i].value
                });
            }
        }

        let finalSelectedChainCodeOptions = [];
        var inputs = document.querySelectorAll('.chainCodeCheckOption');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                finalSelectedChainCodeOptions.push({
                    hotelCode: inputs[i].value
                });
            }
        }

        let taxDetails = [];
        if (specificTaxInputRef.current.value.split(',').length > 0) {
            specificTaxInputRef.current.value.split(',').forEach(element => {
                taxDetails.push({
                    taxCode: element
                });
            });
        }

        let ID;
        if (props.loadedFeeDetails === null || props.loadedFeeDetails === undefined || props.loadedFeeDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedFeeDetails.id;
        }

        props.onAddFee({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            serviceID: serviceCode,
            channelID: channelCode === channelValue ? "B2B/B2C" : channelCode,
            airlineCode: serviceCode === serviceValue ? finalSelectedAirlineOptions : [],
            rbdDetails: serviceCode === serviceValue ? finalSelectedRbdOptions : [],
            providers: finalSelectedProviderOptions,
            chainCode: serviceCode === serviceValue ? [] : finalSelectedChainCodeOptions,
            starCategoryID: serviceCode === serviceValue ? '' : starCategoryCode,
            cabinClassID: serviceCode === serviceValue ? cabinCode : '',
            bookingDateFrom: bookingDateFromInputRef.current.value === '' ? 0 : parseInt(bookingDateFromInputRef.current.value.replaceAll('-', '')),
            bookingDateTo: bookingDateToInputRef.current.value === '' ? 0 : parseInt(bookingDateToInputRef.current.value.replaceAll('-', '')),
            travelDateFrom: travelDateFromInputRef.current.value === '' ? 0 : parseInt(travelDateFromInputRef.current.value.replaceAll('-', '')),
            travelDateTo: travelDateToInputRef.current.value === '' ? 0 : parseInt(travelDateToInputRef.current.value.replaceAll('-', '')),
            pcc: pccCode,
            feeName: feeNameInputRef.current.value,
            feeCode: feeCodeInputRef.current.value,
            fareBasisCode: fareBasisCodeValueInputRef.current.value,
            fareBasis: document.querySelector('#rdFareBasisCodeInclude').checked,
            feeAppliedID: feeAppliedForCode,
            taxType: document.querySelector('#rdTaxTypeAll').checked === true ? "All" : "Specific",
            specificTaxDetails: taxDetails,
            calculationType: document.querySelector('#rdCalculationTypePercentage').checked === true ? "Percentage" : "Amount",
            feeValue: specificAmountInputRef.current.value,
            minimumFeeAmount: minimumAmountInputRef.current.value,
            feeCalculationID: feeCalculationCode,
            faresID: faresCode,
            displayID: displayCode,
            currencyCode: currencyCode,
            fromRegion: regionFromCode,
            toRegion: serviceCode === serviceValue ? regionToCode : '',
            officeID: JSON.parse(localStorage.getItem('officeID'))
        });
    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            if (props.loadedFeeDetails.length === 0) {
                history.go('/UpdateFee')
            }
            else {
                history.push('/UpdateFee')
                history.go();
            }
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedFeeDetails.length === 0) {
                history.go('/UpdateFee')
            }
            else {
                history.go(`/UpdateFee/${props.loadedFeeDetails.id}`)
            }
        }
    }

    useEffect(() => {
        if (Object.keys(props.loadedFeeDetails).length === 0) {
            if (serviceCode === '' || serviceCode === undefined) {
                setServiceCode(serviceValue);
            }

            if ((cabinCode === '' || cabinCode === undefined)) {
                setCabinCode(cabinClassValue);
            }

            if ((feeAppliedForCode === '' || feeAppliedForCode === undefined)) {
                setFeeAppliedForCode(feeAppliedForValue);
            }

            if (channelCode === '' || channelCode === undefined) {
                setChannelCode(channelValue);
            }

            if ((regionFromCode === '' || regionFromCode === undefined)) {
                setRegionFromCode(regionValue);
            }

            if ((regionToCode === '' || regionToCode === undefined)) {
                setRegionToCode(regionValue);
            }

            if ((faresCode === '' || faresCode === undefined)) {
                setFaresCode(faresValue);
            }

            if ((displayCode === '' || displayCode === undefined)) {
                setDisplayCode(displayValue);
            }
        }

        if (serviceCode === serviceValue) {
            var inputs = document.querySelectorAll('.hotelarea');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].style.display = "none";
            }

            var inputs = document.querySelectorAll('.airlinearea');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].style.display = "";
            }

            document.querySelector('#lblFareBasisCode').innerHTML = t('farebasiscode');
            document.querySelector('#lblRegion').innerHTML = t('fromregion');
            document.querySelector('#dvFromregion').className = "col-lg-6 col-sm-12 control mt-2";
        }
        else {
            var inputs = document.querySelectorAll('.hotelarea');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].style.display = "";
            }

            var inputs = document.querySelectorAll('.airlinearea');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].style.display = "none";
            }

            document.querySelector('#lblFareBasisCode').innerHTML = t('ratebasiscode');
            document.querySelector('#lblRegion').innerHTML = t('region');
            document.querySelector('#dvFromregion').className = "col-lg-12 col-sm-12 control mt-2";
        }

        if (feeAppliedForCode === feeAppliedForValue) {
            if (document.querySelector('#dvTaxType') !== null) {
                document.querySelector('#dvTaxType').style.display = "";
                if (document.querySelector('#rdTaxTypeSpecific').checked === false) {
                    document.querySelector('#dvSpecificTax').style.display = "none";
                }
                document.querySelector('#dvFeeappliedfor').className = "col-lg-8 col-sm-12 control mt-2";
            }
        }
        else {
            if (document.querySelector('#dvTaxType') !== null) {
                document.querySelector('#dvTaxType').style.display = "none";
                if (document.querySelector('#rdTaxTypeSpecific').checked === false) {
                    document.querySelector('#dvSpecificTax').style.display = "none";
                }
            }
            document.querySelector('#dvFeeappliedfor').className = "col-lg-12 col-sm-12 control mt-2";
        }

        if (document.querySelector('#rdCalculationTypePercentage').checked === false) {
            document.querySelector('#rdCalculationTypeAmount').checked = true;
            document.querySelector('#dvMinimumAmount').style.display = "none";
            document.querySelector('#dvValue').className = "col-lg-6 col-sm-12 control mt-2";
        }
        else {
            document.querySelector('#dvValue').className = "col-lg-6 col-sm-12 control mt-2";
        }

        if (isAirlineOptionChanged === false) {
            airlineSelected.forEach(element => {
                if (document.querySelector('#chkAirline' + element) !== null) {
                    document.querySelector('#chkAirline' + element).checked = true;
                }
            });
        }

        document.querySelector('#chkAirlineSelectAll')
            .addEventListener('change', function () {
                if (this.checked) {
                    var inputs = document.querySelectorAll('.airlineCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = true;
                    }
                }
                else {
                    var inputs = document.querySelectorAll('.airlineCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                }
            });

        if (isRbdOptionChanged === false) {
            rbdSelected.forEach(element => {
                if (document.querySelector('#chkRbd' + element) !== null) {
                    document.querySelector('#chkRbd' + element).checked = true;
                }
            });
        }

        document.querySelector('#chkRbdSelectAll')
            .addEventListener('change', function () {
                if (this.checked) {
                    var inputs = document.querySelectorAll('.rbdCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = true;
                    }
                }
                else {
                    var inputs = document.querySelectorAll('.rbdCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                }
            });

        if (isProviderOptionChanged === false) {
            providerSelected.forEach(element => {
                if (document.querySelector('#chk' + element) !== null) {
                    document.querySelector('#chk' + element).checked = true;
                }
            });
        }

        document.querySelector('#chkProviderSelectAll')
            .addEventListener('change', function () {
                if (this.checked) {
                    var inputs = document.querySelectorAll('.providerCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = true;
                    }
                }
                else {
                    var inputs = document.querySelectorAll('.providerCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                }
            });

        if (isChainCodeOptionChanged === false) {
            chainCodeSelected.forEach(element => {
                if (document.querySelector('#chkChainCode' + element) !== null) {
                    document.querySelector('#chkChainCode' + element).checked = true;
                }
            });
        }

        document.querySelector('#chkChainCodeSelectAll')
            .addEventListener('change', function () {
                if (this.checked) {
                    var inputs = document.querySelectorAll('.chainCodeCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = true;
                    }
                }
                else {
                    var inputs = document.querySelectorAll('.chainCodeCheckOption');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                }
            });

        if (parseInt(sessionStorage.getItem('isEditable')) !== 0 && parseInt(sessionStorage.getItem('isEditable')) !== 1) {
            if (isAirlineOptionChanged === false) {
                document.querySelector('#chkAirlineSelectAll').checked = true;
                airlineOptions.forEach(element => {
                    if (document.querySelector('#chkAirline' + element.value) !== null) {
                        document.querySelector('#chkAirline' + element.value).checked = true;
                    }
                });
            }

            if (isRbdOptionChanged === false) {
                document.querySelector('#chkRbdSelectAll').checked = true;
                rbdOptions.forEach(element => {
                    if (document.querySelector('#chkRbd' + element.value) !== null) {
                        document.querySelector('#chkRbd' + element.value).checked = true;
                    }
                });
            }

            if (isProviderOptionChanged === false) {
                document.querySelector('#chkProviderSelectAll').checked = true;
                providerOptions.forEach(element => {
                    if (document.querySelector('#chk' + element.value) !== null) {
                        document.querySelector('#chk' + element.value).checked = true;
                    }
                });
            }

            if (isChainCodeOptionChanged === false) {
                document.querySelector('#chkChainCodeSelectAll').checked = true;
                chainCodeOptions.forEach(element => {
                    if (document.querySelector('#chkChainCode' + element.value) !== null) {
                        document.querySelector('#chkChainCode' + element.value).checked = true;
                    }
                });
            }

            if (document.querySelector('#rdFareBasisCodeExclude').checked === false) {
                document.querySelector('#rdFareBasisCodeInclude').checked = true;
            }

            if (document.querySelector('#rdTaxTypeSpecific').checked === false) {
                document.querySelector('#rdTaxTypeAll').checked = true;
                document.querySelector('#dvSpecificTax').style.display = "none";
            }
            else {
                document.querySelector('#dvSpecificTax').style.display = "";
            }
        }
        else {            
            var totalProviderChecks = document.querySelectorAll('.providerCheckOption').length;
            var checkedProviderChecks = [].slice.call(document.querySelectorAll('.providerCheckOption'))
                .filter(function (e) { return e.checked; }).length;

            if (totalProviderChecks > 0 && totalProviderChecks === checkedProviderChecks) {
                document.querySelector('#chkProviderSelectAll').checked = true;
            }

            var totalAirlineChecks = document.querySelectorAll('.airlineCheckOption').length;
            var checkedAirlineChecks = [].slice.call(document.querySelectorAll('.airlineCheckOption'))
                .filter(function (e) { return e.checked; }).length;

            if (totalAirlineChecks > 0 && totalAirlineChecks === checkedAirlineChecks) {
                document.querySelector('#chkAirlineSelectAll').checked = true;
            }

            var totalRbdChecks = document.querySelectorAll('.rbdCheckOption').length;
            var checkedRbdChecks = [].slice.call(document.querySelectorAll('.rbdCheckOption'))
                .filter(function (e) { return e.checked; }).length;

            if (totalRbdChecks > 0 && totalRbdChecks === checkedRbdChecks) {
                document.querySelector('#chkRbdSelectAll').checked = true;
            }

            var totalChainCodeChecks = document.querySelectorAll('.chainCodeCheckOption').length;
            var checkedChainCodeChecks = [].slice.call(document.querySelectorAll('.chainCodeCheckOption'))
                .filter(function (e) { return e.checked; }).length;

            if (totalChainCodeChecks > 0 && totalChainCodeChecks === checkedChainCodeChecks) {
                document.querySelector('#chkChainCodeSelectAll').checked = true;
            }
        }

    }, [serviceCode, cabinCode, feeAppliedForCode,
        channelCode, regionFromCode, regionToCode,
        feeAppliedForValue, isAirlineOptionChanged,
        airlineSelected, isRbdOptionChanged, rbdSelected,
        isProviderOptionChanged, providerSelected,
        isChainCodeOptionChanged, chainCodeSelected]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    const onAllAirlineChangeHandler = (event) => {
        setIsAirlineOptionChanged(true);
    }

    const onAirlineChangeHandler = (event, option, index) => {
        setIsAirlineOptionChanged(true);
        if (document.querySelector('#chkAirline' + option.value) !== null) {
            document.querySelector('#chkAirline' + option.value).checked = event.target.checked;
        }

        if (!event.target.checked) {
            document.querySelector('#chkAirlineSelectAll').checked = false;
        }

        var totalAirlineChecks = document.querySelectorAll('.airlineCheckOption').length;
        var checkedAirlineChecks = [].slice.call(document.querySelectorAll('.airlineCheckOption'))
            .filter(function (e) { return e.checked; }).length;

        if (totalAirlineChecks > 0 && totalAirlineChecks === checkedAirlineChecks) {
            document.querySelector('#chkAirlineSelectAll').checked = true;
        }
    }

    const onRbdChangeHandler = (event, option, index) => {
        setIsRbdOptionChanged(true);
        if (document.querySelector('#chkRbd' + option.value) !== null) {
            document.querySelector('#chkRbd' + option.value).checked = event.target.checked;
        }

        if (!event.target.checked) {
            document.querySelector('#chkRbdSelectAll').checked = false;
        }

        var totalRbdChecks = document.querySelectorAll('.rbdCheckOption').length;
        var checkedRbdChecks = [].slice.call(document.querySelectorAll('.rbdCheckOption'))
            .filter(function (e) { return e.checked; }).length;

        if (totalRbdChecks > 0 && totalRbdChecks === checkedRbdChecks) {
            document.querySelector('#chkRbdSelectAll').checked = true;
        }
    }

    const onAllProviderChangeHandler = (event) => {
        setIsProviderOptionChanged(true);
    }

    const onProviderChangeHandler = (event, option, index) => {
        setIsProviderOptionChanged(true);
        if (document.querySelector('#chk' + option.value) !== null) {
            document.querySelector('#chk' + option.value).checked = event.target.checked;
        }

        if (!event.target.checked) {
            document.querySelector('#chkProviderSelectAll').checked = false;
        }
        
        var totalProviderChecks = document.querySelectorAll('.providerCheckOption').length;
        var checkedProviderChecks = [].slice.call(document.querySelectorAll('.providerCheckOption'))
            .filter(function (e) { return e.checked; }).length;

        if (totalProviderChecks > 0 && totalProviderChecks === checkedProviderChecks) {
            document.querySelector('#chkProviderSelectAll').checked = true;
        }
    }

    const onChainCodeChangeHandler = (event, option, index) => {
        setIsChainCodeOptionChanged(true);
        if (document.querySelector('#chkChainCode' + option.value) !== null) {
            document.querySelector('#chkChainCode' + option.value).checked = event.target.checked;
        }

        if (!event.target.checked) {
            document.querySelector('#chkChainCodeSelectAll').checked = false;
        }

        var totalChainCodeChecks = document.querySelectorAll('.chainCodeCheckOption').length;
        var checkedChainCodeChecks = [].slice.call(document.querySelectorAll('.chainCodeCheckOption'))
            .filter(function (e) { return e.checked; }).length;

        if (totalChainCodeChecks > 0 && totalChainCodeChecks === checkedChainCodeChecks) {
            document.querySelector('#chkChainCodeSelectAll').checked = true;
        }
    }

    return (
        <Wrapper>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            {statusAirline === 'pending' || statusChainCode === 'pending' || statusService === 'pending' &&
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            }
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    <div className="form-group col-md-12">
                                                        <form className='form'>
                                                            <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>
                                                            <div className='row'>
                                                                <div className="col-lg-6 col-sm-12 control ">
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>{t('service')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown onChange={serviceChangeHandler}
                                                                        value={serviceOptions.filter(option => option.value === serviceCode)}
                                                                        options={serviceOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        autoFocus
                                                                    />
                                                                    {serviceCodeInputHasError &&
                                                                        <p className="error-text">{t('validationServiceCode')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control ">
                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='channel'>{t('channel')}</label>
                                                                    <Dropdown onChange={channelChangeHandler}
                                                                        value={channelOptions.filter(option => option.label === channelCode)}
                                                                        options={channelOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12 col-sm-12 control ">
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <label className="h6 text-primary mb-2" for="provider">{t('provider')}</label>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-check float-lg-end">
                                                                                <input className="form-check-input" type="checkbox" id="chkProviderSelectAll"
                                                                                    onChange={(e) => onAllProviderChangeHandler(e)}></input>
                                                                                <label className="form-check-label" for="chkProvidersSelectAll">
                                                                                    {t('selectall')}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-12'>
                                                                            <div className='col-lg-12 borde-box'>
                                                                                <div className='row'>
                                                                                    {
                                                                                        providerOptions?.map((item, index) => (
                                                                                            <div className="col-md-3">
                                                                                                <div className='form-check'>
                                                                                                    <label className="form-check-label" for={item.value}>{item.label}</label>
                                                                                                    <input className="form-check-input providerCheckOption" type="checkbox" value={item.value} id={'chk' + item.value}
                                                                                                        onChange={(e) => onProviderChangeHandler(e, item, index)} ></input></div>
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <dev className='airlinearea'>
                                                                    <div className='col-lg-12 mb-3'>
                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <label className="h6 text-primary mb-2" for="airline">{t('airline')}</label>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div className="form-check float-lg-end">
                                                                                    <input className="form-check-input" type="checkbox" id="chkAirlineSelectAll"
                                                                                        onChange={(e) => onAllAirlineChangeHandler(e)}></input>
                                                                                    <label className="form-check-label" for="chkAirlineSelectAll">
                                                                                        {t('selectall')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-12 borde-box'>
                                                                            <div className='row mt-1 gt-h-355px'>
                                                                                {
                                                                                    airlineOptions?.map((item, index) => (
                                                                                        <div className="col-md-3">
                                                                                            <div className='form-check'>
                                                                                                <label className="form-check-label" for={item.value}>{item.label}</label>
                                                                                                <input className="form-check-input airlineCheckOption" type="checkbox" value={item.value} id={'chkAirline' + item.value}
                                                                                                    onChange={(e) => onAirlineChangeHandler(e, item, index)} ></input></div>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <label className="h6 text-primary mb-2" for="rbd">{t('rbd')}</label>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div className="form-check float-lg-end">
                                                                                    <input className="form-check-input" type="checkbox" id="chkRbdSelectAll"></input>
                                                                                    <label className="form-check-label" for="chkRbdSelectAll">
                                                                                        {t('selectall')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-12 borde-box'>
                                                                            <div className='row mt-1'>
                                                                                {
                                                                                    rbdOptions?.map((item, index) => (
                                                                                        <div className="col-md-1">
                                                                                            <div className='form-check'>
                                                                                                <label className="form-check-label" for={item.value}>{item.label}</label>
                                                                                                <input className="form-check-input rbdCheckOption" type="checkbox" value={item.value} id={'chkRbd' + item.value}
                                                                                                    onChange={(e) => onRbdChangeHandler(e, item, index)} ></input></div>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12 control mt-2">
                                                                        <label className="h6 text-primary mb-2" for="cabinclass">{t('cabinclass')}</label>
                                                                        <Dropdown onChange={cabinChangeHandler}
                                                                            value={cabinClassOptions.filter(option => option.value === cabinCode)}
                                                                            options={cabinClassOptions}
                                                                            textField="listValue"
                                                                            dataItemKey="id"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                            isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        />
                                                                    </div>
                                                                </dev>
                                                                <div className='hotelarea'>
                                                                    <div className="col-lg-12 col-sm-12 control ">
                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <label className="h6 text-primary mb-2" for="chaincode">{t('chaincode')}</label>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div className="form-check float-lg-end">
                                                                                    <input className="form-check-input" type="checkbox" id="chkChainCodeSelectAll"></input>
                                                                                    <label className="form-check-label" for="chkChainCodeSelectAll">
                                                                                        {t('selectall')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-12'>
                                                                                <div className='col-lg-12 borde-box'>
                                                                                    <div className='row gt-h-355px'>
                                                                                        {
                                                                                            chainCodeOptions?.map((item, index) => (
                                                                                                <div className="col-md-3">
                                                                                                    <div className='form-check'>
                                                                                                        <label className="form-check-label" for={item.value}>{item.label}</label>
                                                                                                        <input className="form-check-input chainCodeCheckOption" type="checkbox" value={item.value} id={'chkChainCode' + item.value}
                                                                                                            onChange={(e) => onChainCodeChangeHandler(e, item, index)} ></input></div>
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12 control mt-2">
                                                                        <label className="h6 text-primary mb-2" for="starcategory">{t('starcategory')}</label>
                                                                        <Dropdown onChange={starCategoryChangeHandler}
                                                                            value={starCategoryOptions.filter(option => option.value === starCategoryCode)}
                                                                            options={starCategoryOptions}
                                                                            textField="listValue"
                                                                            dataItemKey="id"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                            isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="bookingdatefrom">{t('bookingdatefrom')}</label>
                                                                    <input type="date" className="form-control" id="calBookingDateFrom" ref={bookingDateFromInputRef} onBlur={onBlurCheck}></input>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="bookingdateto">{t('bookingdateto')}</label>
                                                                    <input type="date" className="form-control" id="calBookingDateTo" ref={bookingDateToInputRef} onBlur={onBlurCheck}></input>
                                                                    {bookingDateToInvalidInputError &&
                                                                        <p className="error-text">{t('validationBookingDateToInvalidData')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row airlinearea'>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="traveldatefrom">{t('traveldatefrom')}</label>
                                                                    <input type="date" className="form-control" id="calTravelDateFrom" ref={travelDateFromInputRef} onBlur={onBlurCheck}></input>
                                                                    {travelDateFromInvalidInputError &&
                                                                        <p className="error-text">{t('validationTravelDateFromInvalidData')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="traveldateto">{t('traveldateto')}</label>
                                                                    <input type="date" className="form-control" id="calTravelDateTo" ref={travelDateToInputRef} onBlur={onBlurCheck}></input>
                                                                    {travelDateToInvalidInputError &&
                                                                        <p className="error-text">{t('validationTravelDateToInvalidData')}</p>
                                                                    }
                                                                    {travelDateToInvalidInputError2 &&
                                                                        <p className="error-text">{t('validationTravelDateToInvalidData2')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-sm-12 control mt-2">
                                                                <label className="h6 text-primary mb-2" for="pcc">{t('pcc')}</label>
                                                                <Dropdown onChange={pccChangeHandler}
                                                                    value={pccOptions.filter(option => option.value === pccCode)}
                                                                    options={pccOptions}
                                                                    textField="airlineName"
                                                                    dataItemKey="airlineCode"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                    isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                />
                                                            </div>
                                                            <hr></hr>
                                                            <div className='row'>
                                                                <label className="h6 text-primary mb-2" for="feetype">{t('feetype')}</label>
                                                                <div className="col-lg-4 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="feecode">{t('feecode')}<span className="error-icon"> *</span></label>
                                                                    <input type="text" minLength={2} maxLength={2} className="form-control" id="feeCode" ref={feeCodeInputRef} onBlur={onBlurCheck}></input>
                                                                    {feeCodeInputHasError &&
                                                                        <p className="error-text">{t('validationFeeCode')}</p>
                                                                    }
                                                                    {feeCodeInvalidInputError &&
                                                                        <p className="error-text">{t('validationFeeCodeInvalidData')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-8 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="feename">{t('feename')}<span className="error-icon"> *</span></label>
                                                                    <input type="text" minLength={1} maxLength={100} className="form-control" id="feeName" ref={feeNameInputRef} onBlur={onBlurCheck}></input>
                                                                    {feeNameInputHasError &&
                                                                        <p className="error-text">{t('validationFeeName')}</p>
                                                                    }
                                                                    {feeNameInvalidInputError &&
                                                                        <p className="error-text">{t('validationFeeNameInvalidData')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-4 col-sm-12 control mt-2">
                                                                    <label id='lblFareBasisCode' className="h6 text-primary mb-2" for="farebasiscode">{t('farebasiscode')}<span className="error-icon"> *</span></label>
                                                                    <div className='col-lg-12 borde-box'>
                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="farebasiscode" id="rdFareBasisCodeInclude"
                                                                                        value="white" onChange={fareBasisCodeIncludeHandleClick} ></input>
                                                                                    <label className="form-check-label" for="rdFareBasisCodeInclude">
                                                                                        {t('include')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="farebasiscode" id="rdFareBasisCodeExclude"
                                                                                        value="white" onChange={fareBasisCodeExcludeHandleClick} ></input>
                                                                                    <label className="form-check-label" for="rdFareBasisCodeExclude">
                                                                                        {t('exclude')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-sm-12 control mt-2">
                                                                    <div className='col-lg-12'>
                                                                        <label className="h6 text-primary mb-2" for="value">{t('value')}<i id='infoFareBasisCode' className="fas fa-info-circle ms-2"></i></label>
                                                                        <textarea id='txtFareBasisCode'
                                                                            value={fareBasisCodeValue}
                                                                            className="form-control form-control-sm"
                                                                            onChange={(e) => setFareBasisCodeValue(e.target.value)}
                                                                            rows={2} cols={100} maxLength={100}
                                                                            onBlur={onBlurCheck}
                                                                            ref={fareBasisCodeValueInputRef}
                                                                        />
                                                                        {fareBasisCodeInputHasError &&
                                                                            <p className="error-text">{t('validationFareBasisCode')}</p>
                                                                        }
                                                                        {fareBasisCodeInvalidInputError &&
                                                                            <p className="error-text">{t('validationFareBasisCodeInvalidData')}</p>
                                                                        }
                                                                        <ReactTooltip
                                                                            anchorId="infoFareBasisCode"
                                                                            place="bottom"
                                                                            variant="info"
                                                                            className="tooltip"
                                                                            html={t('farebasiscodevaluehint')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div id='dvFeeappliedfor' className="col-lg-8 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="feeappliedfor">{t('feeappliedfor')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown onChange={feeAppliedForChangeHandler}
                                                                        value={feeAppliedForOptions.filter(option => option.value === feeAppliedForCode)}
                                                                        options={feeAppliedForOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                <div id='dvTaxType' className="col-lg-4 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="taxtype">{t('taxtype')}</label>
                                                                    <div className='col-lg-12 borde-box'>
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="rdTaxTypeAll"
                                                                                        value="All" onChange={taxTypeAllHandleClick}></input>
                                                                                    <label className="form-check-label" for="flexRadioDefault3">
                                                                                        {t('all')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-8'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="rdTaxTypeSpecific"
                                                                                        value="Specific" onChange={taxTypeSpecificHandleClick}></input>
                                                                                    <label className="form-check-label" for="flexRadioDefault3">{t('specific')}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id='dvSpecificTax' className="col-lg-12 col-sm-12 control mt-2">
                                                                <label className="h6 text-primary mb-2" for="specifictax">{t('specifictax')}<span className="error-icon"> *</span> <i id='infoSpecificTax' className="fas fa-info-circle ms-2"></i></label>
                                                                <input type="text" minLength={1} maxLength={200} className="form-control" id="specificTax" ref={specificTaxInputRef} onBlur={onBlurCheck}></input>
                                                                {specificTaxInputHasError &&
                                                                    <p className="error-text">{t('validationSpecificTax')}</p>
                                                                }
                                                                {specificTaxInvalidInputError &&
                                                                    <p className="error-text">{t('validationSpecificTaxInvalidData')}</p>
                                                                }
                                                                <ReactTooltip
                                                                    anchorId="infoSpecificTax"
                                                                    place="bottom"
                                                                    variant="info"
                                                                    className="tooltip"
                                                                    html={t('specifictaxhint')}
                                                                />
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="calculationtype">{t('calculationtype')}<span className="error-icon"> *</span></label>
                                                                    <div className='col-lg-12 borde-box'>
                                                                        <div className='row'>
                                                                            <div className='col-md-7'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="calculationtype" id="rdCalculationTypePercentage"
                                                                                        value="Percentage" onChange={calculationTypePercentage}></input>
                                                                                    <label className="form-check-label" for="rdCalculationTypePercentage">
                                                                                        {t('percentage')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-5'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="calculationtype" id="rdCalculationTypeAmount"
                                                                                        value="Amount" onChange={calculationTypeAmount}></input>
                                                                                    <label className="form-check-label" for="rdCalculationTypeAmount">
                                                                                        {t('amount')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id='dvValue' className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="value">{t('value')}<span className="error-icon"> *</span></label>
                                                                    <input type="text" minLength={1} maxLength={10} className="form-control" placeholder={t('specifyamount')} id="specificAmount" ref={specificAmountInputRef} onBlur={onBlurCheck}></input>
                                                                    {specificAmountInputHasError &&
                                                                        <p className="error-text">{t('validationSpecificAmount')}</p>
                                                                    }
                                                                    {specificAmountInvalidInputError &&
                                                                        <p className="error-text">{t('validationSpecificAmountInvalidData')}</p>
                                                                    }
                                                                </div>
                                                                <div id='dvMinimumAmount' className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="minimumamount">{t('minimumamount')}<span className="error-icon"> </span></label>
                                                                    <input type="text" className="form-control" placeholder={t('minimumamount')} id="minimumAmount" ref={minimumAmountInputRef} onBlur={onBlurCheck}></input>
                                                                    {specificMinimumAmountInvalidInputError &&
                                                                        <p className="error-text">{t('validationSpecificMinimumAmountInvalidData')}</p>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="feecalculation">{t('feecalculation')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown id='ddlFeecalculation' onChange={feeCalculationChangeHandler}
                                                                        value={feeCalculationOptions.filter(option => option.value === feeCalculationCode)}
                                                                        options={feeCalculationOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                    {feeCalculationCodeInputHasError &&
                                                                        <p className="error-text">{t('validationfeeCalculationCode')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="fares">{t('fares')}</label>
                                                                    <Dropdown onChange={faresChangeHandler}
                                                                        value={faresOptions.filter(option => option.value === faresCode)}
                                                                        options={faresOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="display">{t('display')}</label>
                                                                    <Dropdown onChange={displayChangeHandler}
                                                                        value={displayOptions.filter(option => option.value === displayCode)}
                                                                        options={displayOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label className="h6 text-primary mb-2" for="currency">{t('currency')}</label>
                                                                    <Dropdown onChange={currencyChangeHandler}
                                                                        value={currencyOptions.filter(option => option.value === currencyCode)}
                                                                        options={currencyOptions}
                                                                        textField="listValue"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div id='dvFromregion' className="col-lg-6 col-sm-12 control mt-2">
                                                                    <label id='lblRegion' className="h6 text-primary mb-2" for="fromregion">{t('fromregion')}</label>
                                                                    <Dropdown onChange={regionFromChangeHandler}
                                                                        value={regionOptions.filter(option => option.value === regionFromCode)}
                                                                        options={regionOptions}
                                                                        textField="regionName"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12 control mt-2 airlinearea">
                                                                    <label className="h6 text-primary mb-2" for="toregion">{t('toregion')}</label>
                                                                    <Dropdown onChange={regionToChangeHandler}
                                                                        value={regionOptions.filter(option => option.value === regionToCode)}
                                                                        options={regionOptions}
                                                                        textField="regionName"
                                                                        dataItemKey="id"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                    />
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/SearchFee" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href="/SearchFee" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                        {(checkSecurityDetails("Settings", "Fee", "Settings_Fee_Insert") || checkSecurityDetails("Settings", "Fee", "Settings_Fee_Update")) ?
                                                                            <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                                            : <span id="btnNew"></span>}
                                                                        {checkSecurityDetails("Settings", "Fee", "Settings_Fee_Insert") ?
                                                                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                                {t('new')}
                                                                            </button>
                                                                            : <span id="btnNew"></span>}
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default FeeForm