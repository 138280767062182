import {
    GET_ALL_FEE,
    GET_FEE,
    ADD_FEE,
    UPDATE_FEE,
    DELETE_FEE,
    SEARCH_FEE,
    SEARCH_COMMON_LIST,
    SEARCH_HOTELCHAIN_LIST,
    SEARCH_HOTEL_EXCLUSIVE_DEALS_LIST,
    SEARCH_HOTEL_NAME
} from './CONSTANTS'

export async function getAllFee() {

    const response = await fetch(GET_ALL_FEE(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch fee.');
    }
    return data;
}
export async function searchFeeApi(searchData) {
    const response = await fetch(SEARCH_FEE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch fee.');
    }
    return data;
}
export async function getSingleFee(ID) {
    const response = await fetch(GET_FEE(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch fee.');
    }
    return data;
}
export async function deleteSingleFee(deleteData) {
    const response = await fetch(DELETE_FEE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete fee.');
    }
    return data;
}
export async function addFee(addData) {
    const response = await fetch(ADD_FEE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create fee.');
    }
    return data;
}
export async function updateFeeApi(updateData) {
    const response = await fetch(UPDATE_FEE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update fee.');
    }
    return data;
}
export async function searchCommonList(searchData) {
    const response = await fetch(SEARCH_COMMON_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch category.');
    }

    return data;
}
export async function SearchHotelChain(searchData) {
    const response = await fetch(SEARCH_HOTELCHAIN_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch hotel chain');
    }
    return data;
}

export async function SearchHotelExclusiveDeals(searchData) {
    const response = await fetch(SEARCH_HOTEL_EXCLUSIVE_DEALS_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch hotel chain');
    }
    return data;
}
export async function SearchHotelName(searchData) {
    const response = await fetch(SEARCH_HOTEL_NAME(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch hotel chain');
    }
    return data;
}


