import { Fragment, useEffect, useState } from 'react';

const Approver = () => {

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="employeeName">Employee Name</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='employeeName' value={'Akhilesh Pathak'} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="employeeCode">Employee Code</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='employeeCode' value={'2302'} disabled />
                                </div>
                            </div>
                            <div className='row mb-4 d-none'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="mobileNumber">Mobile Number</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='mobileNumber' value={'9810688007'} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="emailID">Email ID</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='emailID' value={'akhilesh@fareladder.com'} disabled />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="firstApproverEmployeeName">First Approver Employee Name</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='firstApproverEmployeeName' value={'Rahul Srinivasan'} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="firstApproverEmployeeCode">First Approver Employee Code</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='firstApproverEmployeeCode' value={'2301'} disabled />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default Approver;

