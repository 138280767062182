import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from "react";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { checkSecurityDetails } from '../../../../utils/RoleUtils'

const CustomFieldsItem = (props) => {
    const { t } = useTranslation(['translation.CustomFields']);
    const [confirm, setConfirm] = useState();

    function deleteFormHandler(props) {
        props.onDelete({
            "id": props.id,
            "traceID": "",
            "officeID": "",
            "userID": "",
            "airlineCode": "",
            "airlineName": "",
            "airlineLogo": "",
            iPAddress: localStorage.getItem("IPAddress"),
        });

    }
    function onConfirm() {
        setConfirm(true);
    }

    function okHandler() {
        deleteFormHandler(props);
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };

    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}


        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.airlineName}
                                </h6>
                            </div>
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>

                                    {checkSecurityDetails("Master", "CustomFields", "Master_CustomFields_View") && <Link title="Show" className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" to={{
                                        pathname: `/newcustomfields/${props.id}/0`,
                                        edit: 0
                                    }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                    {checkSecurityDetails("Master", "CustomFields", "Master_CustomFields_Update") && <Link title="Edit" className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn" to={{
                                        pathname: `/newcustomfields/${props.id}/1`,
                                        edit: 1
                                    }}
                                    ><i className="fa-solid fa-pen"></i></Link>}
                                    {checkSecurityDetails("Master", "CustomFields", "Master_CustomFields_Delete") &&
                                        <button title="Delete" className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" ><i className="fas fa-trash-alt"></i></button>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Fragment>

};

export default CustomFieldsItem;