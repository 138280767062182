import { useState, useRef, useEffect, Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import HotelImagePopup from '../HotelDetails/Components/HotelImagePopup'
import { detailsHotel } from '../../services/Hotel-API'
import useHttp from '../../services/use-http'
import Header from './Components/Header';
import Overview from './Components/Overview';
import Services from './Components/Services';
import Room from './Components/Room';
import PropertyAmenities from './Components/PropertyAmenities';
import AboutArea from './Components/AboutArea';
import Policies from './Components/Policies';
import LoadingHotel from '../Hotels/Components/LoadingHotel';
import { toTitleCase, removeSpacesAndSpecialChars } from '../../utils/CommonFunction';
import ErrorModal from '../../components/Common/Error/ErrorModal';
import queryString from 'query-string';
import { useLocation } from 'react-router';

const HotelDetails = () => {
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-large-hotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    useDocumentTitle('Hotel Details')
    const [error1, setError] = useState();
    const [roomError, setRoomError] = useState();
    const location = useLocation();

    const { sendRequest, status, data: loadedHotelDetails, error } = useHttp(detailsHotel);
    const valueParam = queryString.parse(location.search);

    const errorHandler = () => {
        setError(null);
        setRoomError(null);
    };

    useEffect(() => {
        let RoomList = [];
        if (sessionStorage.getItem("RoomDetailJson") !== null) {
            RoomList = JSON.parse(sessionStorage.getItem("RoomDetailJson"));
            RoomList.length !== 0 && RoomList.forEach(element => {
                let el = document.getElementById('addToProposal' + element.roomID)
                if (el !== null) {
                    el.checked = true;
                }
            });
        }
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });


        const hotelDetails = JSON.parse(sessionStorage.getItem("HotelDetails"));
        sendRequest(hotelDetails);
        if (sessionStorage.getItem("RoomDetailJson") !== null) {
            sessionStorage.removeItem('RoomDetailJson')
        }
        if (sessionStorage.getItem("HotelRecommendationJson") !== null) {
            sessionStorage.removeItem('HotelRecommendationJson')
        }
    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedHotelDetails.data.hotelResults[0].roomRateDetails === undefined || loadedHotelDetails.data.hotelResults[0].roomRateDetails.length === 0) {
                setRoomError({
                    title: "Message",
                    message: "Sorry, this hotel has not provided room and rate details.",
                });
            }
        }
    }, [status])

    let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'));
    for (let index in loadedHotelDetails) {
        if (loadedHotelDetails.data.errors.status === 'FALSE') {
            let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

            loadedHotelDetails.data.hotelResults.forEach(hotelResultsElement => {
                if (hotelResultsElement.hotelCheckInPolicy !== undefined && hotelResultsElement.hotelCheckInPolicy !== null) {
                    hotelResultsElement.hotelCheckInPolicy[1].date = hotelSessionJson.calCheckInDateDB;
                    hotelResultsElement.hotelCheckInPolicy[0].date = hotelSessionJson.calCheckOutDateDB;
                }
                else if (hotelResultsElement.hotelCheckInPolicy === undefined || hotelResultsElement.hotelCheckInPolicy === null) {
                    let hotelCheckInPolicy = [];
                    hotelCheckInPolicy.push({
                        type: "CheckOut",
                        date: hotelSessionJson.calCheckOutDateDB
                    });
                    hotelCheckInPolicy.push({
                        type: "CheckIn",
                        date: hotelSessionJson.calCheckInDateDB
                    });
                    hotelResultsElement.hotelCheckInPolicy = hotelCheckInPolicy;
                }
                hotelResultsElement.noOfRooms = hotelSearchRequestJson.Data.NoOfRooms.toString();
                hotelResultsElement.NoOfAdults = hotelSearchRequestJson.Data.RoomGuests[0].NoOfAdults.toString();
            });

            sessionStorage.setItem("HotelDetailsResponse", JSON.stringify(loadedHotelDetails))
        }
    }

    // let Background = loadedHotelDetails !== null ?  loadedHotelDetails.data.hotelResults[0].images !== undefined && loadedHotelDetails.data.hotelResults[0].images !== null ? loadedHotelDetails.data.hotelResults[0].images[0].imagePath : hotelLogoLink : '';
    let Background = loadedHotelDetails !== null ? loadedHotelDetails.data !== undefined ? loadedHotelDetails.data.hotelResults !== undefined ? loadedHotelDetails.data.hotelResults[0].images !== undefined && loadedHotelDetails.data.hotelResults[0].images !== null ? loadedHotelDetails.data.hotelResults[0].images[0].imagePath : hotelLogoLink : "" : "" : "";
    let hotelImages = [];
    let imageCategory = new Map();
    if (loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined) {
        if (loadedHotelDetails.data.hotelResults[0].images !== undefined && loadedHotelDetails.data.hotelResults[0].images !== null) {
            loadedHotelDetails.data.hotelResults[0].images.forEach(element => {

                if (element.imageType === 'LARGE') {

                    hotelImages.push(element);
                    if (!imageCategory.has(element.category)) {
                        let temp = [];
                        temp.push(element);
                        imageCategory.set(element.category, temp);
                    }
                    else {
                        let temp = [];
                        temp = imageCategory.get(element.category)
                        temp.push(element)
                    }

                }

            });
        }
        hotelImages.sort((a, b) => a.category.localeCompare(b.category));

    }

    const duplicateImagePaths = [];

    const encounteredImagePaths = {};
    for (let i = 0; i < hotelImages.length; i++) {
        const imagePath = hotelImages[i].imagePath;
        if (encounteredImagePaths[imagePath]) {
            // This image path has already been encountered
            duplicateImagePaths.push(imagePath);
        } else {
            // This is the first time encountering this image path
            encounteredImagePaths[imagePath] = true;
        }
    }



    function showLablel(image) {
        let label = [];
        for (let i of image.keys()) {
            label.push(<button className="nav-link mt-1" id={"nav-tours-tab" + `${removeSpacesAndSpecialChars(i)}`} data-bs-toggle="tab"
                data-bs-target={"#nav-tours" + `${removeSpacesAndSpecialChars(i)}`} type="button" role="tab" aria-controls={"nav-tours" + `${removeSpacesAndSpecialChars(i)}`}
                aria-selected="false">{toTitleCase(i)}</button>)
        }

        return label;

    }
    function showInnerImage(image) {
        let innerimage = [];

        image.map(element => {
            innerimage.push(
                <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img" >
                            <a href={element.imagePath} className='imagee' data-fancybox="gallery">
                                <img src={element.imagePath} alt="img" />

                            </a>
                        </div>
                        <div className="theme_two_box_content">
                            <h6 className='text-black fw-bold'>{element.category}  {(element.text !== undefined && element.category.toUpperCase() !== element.text.toUpperCase()) ? ": " + element.text : ''}</h6>
                        </div>
                    </div>
                </div>
            )
        })

        return innerimage;
    }
    function showPopupImage(image) {
        let popupImage = [];
        for (let i of image.keys()) {
            popupImage.push(<div key={i} className="tab-pane fade" id={"nav-tours" + `${removeSpacesAndSpecialChars(i)}`} role="tabpanel" aria-labelledby={"nav-tours" + `${removeSpacesAndSpecialChars(i)}`}>
                <div className="row">
                    {showInnerImage(image.get(i))}
                </div>
            </div>);

        }

        return popupImage;


    }


    const HotelImage = () => {
        setError({
            title: "Hotel Images",
            message: <div className='imagemodalcard imageModal1 modal-top'>
                <header className='header'>
                    <div className="row">
                        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                            <h4 className="text-white">{loadedHotelDetails.data.hotelResults[0].hotelName !== undefined && loadedHotelDetails.data.hotelResults[0].hotelName}</h4>

                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={errorHandler}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
                    </div>

                </header>
                <div className=' gt-h-600px'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_nav_tab">
                                <nav className="theme_nav_tab_item">
                                    <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                        <button className="nav-link active mt-1" id="nav-hotels-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-hotels" type="button" role="tab" aria-controls="nav-hotels"

                                            aria-selected="true">All</button>
                                        {showLablel(imageCategory)}

                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-lg-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-hotels" role="tabpanel"
                                    aria-labelledby="nav-hotels-tab">
                                    <div className="row">
                                        {hotelImages.map(item => {
                                            return <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                <div className="theme_common_box_two img_hover">
                                                    <div className="theme_two_box_img" >
                                                        <a href={item.imagePath} className="imagee" data-fancybox="gallery">
                                                            <img src={item.imagePath} alt="img" />
                                                        </a>
                                                    </div>
                                                    <div className="theme_two_box_content">
                                                        <h6 className='text-black fw-bold'>{item.category}  {(item.text !== undefined && item.category.toUpperCase() !== item.text.toUpperCase()) ? " : " + item.text : ''}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        })}


                                    </div>
                                </div>
                                {showPopupImage(imageCategory)}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='actions float-end px-3 py-2'>

                </footer>
            </div>,



        });
    }
    function openHotelImage() {
        if (Background !== hotelLogoLink) {
            HotelImage();
        }

    }
    let showPropertyAmenities = false;
    if (loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined) {
        loadedHotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining' || result.text.type === 'SafetyInfo' || result.text.type === 'Recreation' || result.text.type === 'Services' || result.text.type === 'Facilities') {
                showPropertyAmenities = true;
            }
        })

    }
    return <Fragment>
        {roomError && (
            <ErrorModal
                title={roomError.title}
                message={roomError.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {error1 && (
            <HotelImagePopup
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}

            ></HotelImagePopup>
        )}



        <section className="sub-banner1">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">

            </div>
        </section>

        <section id="home_two_banner" className='gt_login_banner2' style={{ backgroundImage: "url(" + Background + ")" }} onClick={openHotelImage}>
            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].images !== undefined && loadedHotelDetails.data.hotelResults[0].images !== null && <span className="btn btn-md btn-primary images-btnslider">
                <i className="fas fa-images"></i>

            </span>}
            {status === 'pending' &&
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mt-5">
                                    <div className="row  position-relative gt-h-325">
                                        <LoadingHotel action='details' ></LoadingHotel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </section>
        <section id="tour_details_main" className="section_padding">
            <div className="container">
                <div className='row'>

                    {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && <Header hotelDetails={loadedHotelDetails}></Header>}
                    <div className='col-md-12'>
                        <div className="row">
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && <Overview hotelDetails={loadedHotelDetails}></Overview>}
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].hotelAmenities !== undefined && <Services hotelDetails={loadedHotelDetails}></Services>}
                        </div>

                        <div className='row mt-3 accordion aaaa' id="accordionExampleRoom">
                            {
                                loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions === undefined &&
                                <span id='room_section'></span>
                            }

                            <span id='room_section'></span>
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].roomRateDetails !== undefined && <div className='col-md-12 mt-2'>
                                <h3 className="heading_theme">Rooms</h3>
                            </div>}

                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].roomRateDetails !== undefined && loadedHotelDetails.data.hotelResults[0].roomRateDetails.filter(x => x.roomIndex === 1).map((roomDetails) => (<Room roomDetails={roomDetails} key={roomDetails.roomID} hotelDetails={loadedHotelDetails} travelReq={valueParam.travelreq}></Room>))}
                            <span id='amenities_section'></span>
                            {!showPropertyAmenities && <span id='location_section'></span>
                            }

                        </div>
                        {showPropertyAmenities && <PropertyAmenities hotelDetails={loadedHotelDetails}></PropertyAmenities>}
                        {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && <AboutArea hotelDetails={loadedHotelDetails}></AboutArea>}
                        {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && <Policies hotelDetails={loadedHotelDetails}></Policies>}
                    </div>
                </div>
            </div>

        </section>



    </Fragment>

};

export default HotelDetails;