
export const checkSecurityDetails = (moduleName, functinlitesName, ActionName) => {

  const loadedRolesDetails = JSON.parse(localStorage.getItem('roleDetails'))
  let moduleReturnValue = false;
  let funReturnValue = false;
  let loadedData = [];
  for (let i in loadedRolesDetails) {
    if (i === 'moduleDetail') {
      loadedData = loadedRolesDetails[i];
      for (let moduleDetails in loadedData) {
        if (loadedData[moduleDetails].value === moduleName) {
          moduleReturnValue = loadedData[moduleDetails].visible;
        }
        if (functinlitesName !== undefined) {
          for (let functinlitiesDetails in loadedData[moduleDetails].children) {
            if (loadedData[moduleDetails].children[functinlitiesDetails].value === functinlitesName) {
              funReturnValue = loadedData[moduleDetails].children[functinlitiesDetails].visible;
            }
            if (ActionName !== undefined) {
              for (let actionDetails in loadedData[moduleDetails].children[functinlitiesDetails].children) {
            
                if (loadedData[moduleDetails].children[functinlitiesDetails].children[actionDetails].value === ActionName) {
                                   
                  if (loadedData[moduleDetails].children[functinlitiesDetails].children[actionDetails].visible)
                    return loadedData[moduleDetails].children[functinlitiesDetails].children[actionDetails].visible;
                }
              }
            } else {
              if (funReturnValue)
                return funReturnValue;
            }
          }
        } else {
          if (moduleReturnValue)
            return moduleReturnValue;
        }
      }
    }

  }
}