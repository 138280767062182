import RoleForm from './Components/RoleForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addRole, getSingleRole, updateRole, getAllRoleDetails } from '../../../services/Role-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateRole = (props) => {
    useDocumentTitle('Role')
    const { t } = useTranslation(['translation.Role']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id,Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addRole
    } else {
        varHttp = updateRole
    }

    const { sendRequest: sendRequestRights, data: loadedRoleDetailsRights } = useHttpGet(getAllRoleDetails);
    const { sendRequest: sendRequest1, data: loadedRoleDetails, error } = useHttpGet(getSingleRole);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("User Management", "Role", "User Management_Role_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("User Management", "Role", "User Management_Role_View") && Action === "0")
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    useEffect(() => {
        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    useEffect(() => {
        sendRequestRights(JSON.parse(localStorage.getItem('officeID')))

    }, [sendRequestRights])

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }

    let loadedData = [];
    for (let index in loadedRoleDetails) {
        if (loadedRoleDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedRoleDetails[index];
            }
        }
    }

    let loadedDataRights = [];
    for (let index in loadedRoleDetailsRights) {
        if (loadedRoleDetailsRights['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedDataRights = [loadedRoleDetailsRights];
            }
        }
    }

    const addRoleHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchrole');
        }
    };

    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        //alert(1);
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    if ((!loadedDataRights || loadedDataRights.length === 0)) {
        //alert(2);
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    let RoleFormContent = '';
    if (Id !== undefined) {
        RoleFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
            <div className="text-center p-5 h4 text-primary"></div> :
            <RoleForm loadedProfileDetails={loadedData} loadedProfileDetailsRights={loadedDataRights} isLoading={status === 'pending'} onAddRole={addRoleHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />
    }

    if (Id === undefined) {
        RoleFormContent = (!loadedDataRights || loadedDataRights.length === 0) ?
            <div className="text-center p-5 h4 text-primary"></div> :
            <RoleForm loadedProfileDetails={loadedData} loadedProfileDetailsRights={loadedDataRights} isLoading={status === 'pending'} onAddRole={addRoleHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />
    }

    // const RoleFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0) && (!loadedDataRights || loadedDataRights.length === 0)) ?
    //     <div className="text-center p-5 h4 text-primary"></div> :
    //     <RoleForm loadedProfileDetails={loadedData} loadedProfileDetailsRights={loadedDataRights} isLoading={status === 'pending'} onAddRole={addRoleHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />   


    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {RoleFormContent}
    </Fragment>
};

export default UpdateRole;
