import { useState, Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Remarks = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [remarksGeneralInvalidInputError, setRemarksGeneralInvalidInputError] = useState(false);

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
    let remark = "";
    let remarkTextGeneral = "";
    let remarkTextInternal = "";

    if (bookingJSON !== null && bookingJSON !== undefined && bookingJSON.data !== undefined) {
        remark = bookingJSON.data.miscellaneousRemarkInfo !== undefined ? bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo : ""
    }

    useEffect(() => {
        if (remark.length !== 0 && remark !== '') {
            remark.forEach(elementRemark => {
                if (elementRemark.remarkType !== '' && elementRemark.remarkType.toLowerCase() === 'general' && elementRemark.remark !== '') {
                    remarkTextGeneral = remarkTextGeneral + elementRemark.text + "\n";
                }
                if (elementRemark.remarkType !== '' && elementRemark.remarkType.toLowerCase() === 'internalremarks' && elementRemark.remark !== '') {
                    remarkTextInternal = remarkTextInternal + elementRemark.text + "\n";
                }
            });

            document.querySelector('#remarksGeneral').value = remarkTextGeneral;
            document.querySelector('#remarksInternal').value = remarkTextInternal;
        }
    }, [])

    function onBlurCheck() {
        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/\n]+$')) {
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }
    }

    function assignRemarks(event) {
        event.preventDefault();
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/\n]+$')) {
                document.querySelector('#remarksGeneral').focus();
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }

        let remarkTypeGeneralFound = false;
        let remarkTypeInternalRemarksFound = false;

        if (bookingJSON.data.miscellaneousRemarkInfo !== undefined && bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo !== undefined) {
            bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.forEach(element => {
                if (element.remarkType === "General") {
                    element.text = document.querySelector('#remarksGeneral').value;
                    remarkTypeGeneralFound = true;
                }
                else if (element.remarkType === "InternalRemarks") {
                    element.text = document.querySelector('#remarksInternal').value;
                    remarkTypeInternalRemarksFound = true;
                }
            });

            if (remarkTypeGeneralFound === false) {
                bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.push(
                    {
                        "code": "",
                        "text": document.querySelector('#remarksGeneral').value,
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "remarkType": "General",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    }
                )
            }

            if (remarkTypeInternalRemarksFound === false) {
                bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.push(
                    {
                        "code": "",
                        "text": document.querySelector('#remarksInternal').value,
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "remarkType": "InternalRemarks",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    }
                )
            }
        }

        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

        props.onContinueButton("5");
        window.scrollTo({
            top: 390,
            behavior: "smooth",
        });
    }

    function skipRemarks() {
        props.onContinueButton("5");
        window.scrollTo({
            top: 390,
            behavior: "smooth",
        });
    }

    return (
        <Fragment>
            <div className="accordion_flex_area">
                <div className="accordion_left_side position-relative text-muted">
                    <h5>5</h5>
                    <span id="completeFive" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" >
                        <button id="headingFive" className="text-muted accordion-button collapsed" type="button"
                            data-bs-target="#collapseFive"
                            aria-expanded="false" aria-controls="collapseFive">
                            Remarks (Optional)
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse"
                        aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                        <div className='row'>
                            <div className='control input-placeholder'>
                                <label htmlFor='remarksGeneral' className='h6 text-primary mb-2'>Booking Remarks</label>
                                <textarea id='remarksGeneral'
                                    //value={remarksGeneral}
                                    className="form-control form-control-sm"
                                    //onChange={(e) => setRemarksGeneral(e.target.value)}
                                    rows={2} cols={100} maxLength={300}
                                    onBlur={onBlurCheck}
                                />
                            </div>
                            {remarksGeneralInvalidInputError &&
                                <span className="error-text col-lg-12 mt-3">{('Booking Remarks is not valid.')}</span>
                            }
                        </div>
                        <div className='row mt-4'>
                            <div className='control input-placeholder'>
                                <label htmlFor='remarksInternal' className='h6 text-primary mb-2'>Internal Notes</label>
                                <textarea id='remarksInternal'
                                    //value={remarksInternal}
                                    className="form-control form-control-sm"
                                    //onChange={(e) => setRemarksInternal(e.target.value)}
                                    rows={2} cols={100} maxLength={300}
                                />
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-lg-6 col-md-12 col-sm-12 my-auto">
                                <p><span className="error-icon"></span><span></span></p>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="float-md-end float-lg-end">
                                    <button type="button" onClick={skipRemarks} className="btn btn margin-top-15 text-white btn-secondary ms-1 btn-sm">Skip to Price</button>
                                    <button type="button" onClick={assignRemarks} className="btn margin-top-15   text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Remarks
