import React, { Fragment, useEffect, useState, useRef } from 'react';
import { searchState } from '../../../services/State-API'
import useHttp from '../../../services/use-http';
import Dropdown from 'react-select';

const StateDropDownList = ({ stateCodeValue, stateNameText, currentInputValue,currentInputText, iSDisabled, countryCode, exposedControlType }) => {

    //exposedControlType :- ddl, txt
    const { sendRequest, data: stateData, status } = useHttp(searchState);
    const [valueStateData, setValueStateData] = useState();

    const stateNameInputRef = useRef('');

    const handleChangeState = (e) => {
        if (status === 'completed') {
            setValueStateData(e.value);
            stateCodeValue(e.value);
            exposedControlType('ddl');
        }
    };

    const onBlurCheck = (e) => {
        stateNameText(stateNameInputRef.current.value.trim());
        exposedControlType('txt');
        // if(stateNameInputRef.current.value.trim()==='')
        // {
        //     //alert(11);
        // }
    }

    useEffect(() => {
        if (countryCode !== '') {
            const onSearchState = ({
                countryCode: countryCode,
                stateCode: "",
                stateName: ""
            });

            sendRequest(onSearchState);
        }
        setValueStateData(currentInputValue);
        if(stateNameInputRef.current!==null)
        {
            stateNameInputRef.current.value = currentInputText;
        }        
    }, [countryCode, sendRequest, currentInputValue,currentInputText])

    let stateOptions = []
    let stateArrayData = [];
    for (let i in stateData) {
        if (stateData['errors'].status === 'FALSE') {
            if (i === 'data') {
                stateArrayData = stateData[i];
            }
        }
    }

    for (let index = 0; index < stateArrayData.length; index++) {
        stateOptions = [...stateOptions, { value: stateArrayData[index].stateCode, label: stateArrayData[index].stateName }]
    }

    //const getStateLabelByValue = (stateOptions, value) => stateOptions.find(stateOptions => stateOptions.value === value)?.label || "";
    const getCurrentInputValue = stateOptions.filter(function (option) {
        return option.value === stateOptions;
    })
    const getValueStateData = stateOptions.filter(function (option) {
        return option.value === valueStateData;
    })

    if (stateOptions.length > 0) {
        return (
            <Fragment>
                <Dropdown onChange={handleChangeState}
                    value={getValueStateData !== undefined ? getValueStateData : getCurrentInputValue}
                    options={stateOptions}
                    textField="StateName"
                    dataItemKey="StateCode"
                    arrowClosed={<span className="arrow-closed" />}
                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                    //disabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false} 
                    isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                />
            </Fragment>
        )
    }
    else {
        return (
            <Fragment>
                <input maxLength={50} className="form-control form-control-sm" type='text' ref={stateNameInputRef} onBlur={onBlurCheck} readOnly={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false} />
            </Fragment>
        )
    }
}

export default StateDropDownList
