import { Fragment, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Notes from "../../../MyTrips/Components/Notes";
import CancelPNR from "../../../MyTrips/Components/CancelPNR";
import Rebooking from "../../../MyTrips/Components/Rebooking";
import HistoryDetails from "../../../MyTrips/Components/HistoryDetails";
import Logs from "../../../MyTrips/Components/Logs";
import MdModal from "../../../Flights/MdModal";
import PopupModal from "../../../../components/Common/Flight/PrintPopupModal";
import PrintShoppingCart from "./PrintShoppingCart";
import ShoppingCartMail from "./ShoppingCartMail";
import PrintComponent from "../../../HotelBookingConfirmation/Components/PrintComponent";
import FlightPrint from "../../../../components/Common/Flight/PrintConfirmation"
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const ShoppingCartBookingDetails = (props) => {
    const [isCanceled, setIsCanceled] = useState('');
    const [isHotelCancelled, setIsHotelCancelled] = useState('');
    const [error1, setError] = useState();
    const [errorMail, setErrorMail] = useState();
    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
    const location = useLocation();
    let pnr = bookingJSON.data.bookingReferenceID === undefined ? null : bookingJSON.data.bookingReferenceID[0].id;
    let issueTicket = pnr === null ? " Booking Details" : " Booking Confirmation";
    let isFailed = (bookingJSON.data.requestType === "ON" && isCanceled === false && pnr === null) ? true : false;


    const onSendEmail = () => {

        setErrorMail({
            title: "Send Itinerary",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <ShoppingCartMail mailData={bookingJSON} hideData="0" errorHandler={errorHandler}></ShoppingCartMail>
                </div></div>,
        })


    }
    const onPNRCancel = () => {
        setErrorMail({
            title: "Cancel Booking",
            message: <div className='col-md-12'>
                <div className='dummy-singlepnr'>
                    <CancelPNR mailData={bookingJSON} errorHandler={errorHandler} hideData="0" source='flightPlusHotel'></CancelPNR>
                </div></div>,
        })


    }
    const onRebooking = () => {
        setErrorMail({
            title: "Reschedule Request",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <Rebooking bookingJSON={bookingJSON} errorHandler={errorHandler} ></Rebooking>
                </div></div>,
        })


    }

    const onNotes = () => {
        setErrorMail({
            title: "Internal Notes",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <Notes bookingJSON={bookingJSON} errorHandler={errorHandler} hideData="0"></Notes>
                </div></div>,
        })
    }
    const onHistory = () => {
        setErrorMail({
            title: "History Details",
            message: <div className='col-md-12'>
                <div>
                    <HistoryDetails bookingJSON={bookingJSON} errorHandler={errorHandler} hideData="0"></HistoryDetails>
                </div></div>,
        })
    }
    const onLogs = () => {
        setErrorMail({
            title: "Logs",
            message: <div className='col-md-12'>
                <div>
                    <Logs bookingJSON={bookingJSON} errorHandler={errorHandler} ></Logs>
                </div></div>,
        })
    }
    const errorHandler = () => {
        setError(null);
        setErrorMail(null);
    };


    useEffect(() => {
        if (location.mytrip !== undefined) {
            setIsCanceled(location.mytrip.isCanceled);

        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsCanceled") === 'true' ? true : false)
        }
        if (location.hotelTrip !== undefined) {
            setIsHotelCancelled(location.hotelTrip.isCanceled);
        } else {
            setIsHotelCancelled(sessionStorage.getItem("myTripIsHotelCancelled") === 'true' ? true : false);
        }


        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])
    useEffect(() => {
        if (location.hotelTrip !== undefined) {
            setIsHotelCancelled(location.hotelTrip.isCanceled);
        } else {
            setIsHotelCancelled(sessionStorage.getItem("myTripIsHotelCancelled") === 'true' ? true : false);
        }


        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])



    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        <div className='row'>
            {props.hideData !== '1' && <div className='col-md-8'>
                <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket}

                </label>
            </div>}

            <div className='col-md-4'>
                {checkSecurityDetails("My Trips", "Email", "My Trips_Email_Allow Send Email") && (isCanceled === false || isHotelCancelled === false) && isFailed === false && < div className='float-lg-end'>
                    <span id="sendMail1" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end' onClick={() => onSendEmail()}><i className="fas fa-envelope"></i>
                    </span>
                    <ReactTooltip
                        anchorId={"sendMail1"}
                        place="bottom"
                        variant="info"
                        multiline={true}
                        className="tooltip"
                        html={"Email"}
                    />
                </div>}
                {/* Print Option for both flight and hotel*/}
                {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && (isCanceled === false && isHotelCancelled === false) && isFailed === false && <div className='float-lg-end'>
                    <PrintShoppingCart page="retrieve"></PrintShoppingCart>
                </div>}
                {/* Print option for only Hotel*/}
                {
                    checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && bookingJSON.data.requestType === "ON" && isCanceled === true && isHotelCancelled === false && isFailed === false && <div className='float-lg-end'>
                        <PrintComponent page="hotelRetrieve"></PrintComponent>
                    </div>
                }
                {/*Print option for only Flight*/}
                {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && bookingJSON.data.requestType === "ON" && isHotelCancelled === true && isCanceled === false && isFailed === false && 
                <div className='float-lg-end'>
                    <FlightPrint page="flightRetrieve"></FlightPrint>
                </div>
                }
                

                {
                    checkSecurityDetails("My Trips", "Cancellation", "My Trips_Cancellation_Allow Cancellation") && ((isCanceled === false || isHotelCancelled === false) && props.Confirmation === "2") &&
                    <div className='float-lg-end'>
                        <span id="pnrCancellation" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end' onClick={() => onPNRCancel()}><i className="fas fa-times px-1"></i>
                        </span>
                        <ReactTooltip
                            anchorId={"pnrCancellation"}
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={"Cancel Booking"}
                        />
                    </div>
                }
                {
                    checkSecurityDetails("My Trips", "Reschedule", "My Trips_Reschedule_Allow Reschedule") && ((isCanceled === false || isHotelCancelled === false) && props.Confirmation === "2") &&
                    <div className='float-lg-end'>
                        <span id="pnrRebooking" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-success float-end' onClick={() => onRebooking()}><i className="fas fa-retweet rotate-90"></i>
                        </span>
                        <ReactTooltip
                            anchorId={"pnrRebooking"}
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={"Reschedule Request"}
                        />
                    </div>
                }

                {checkSecurityDetails("My Trips", "Internal Note", "My Trips_InternalNote_Allow  Internal Note") && props.Confirmation === "2" && getConfigurationByBoolen("ALLOW_B2B") &&
                    <div className='float-lg-end'>
                        <span id="notes" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end' onClick={() => onNotes()}><i className="fas fa-file-alt"></i>
                        </span>
                    </div>}
                {checkSecurityDetails("My Trips", "History", "My Trips_History_Allow History") && props.Confirmation === "2" &&
                    <div className='float-lg-end'>
                        <span id="history" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end' onClick={() => onHistory()}><i className="fas fa-history"></i>
                        </span>
                    </div>}
                {checkSecurityDetails("My Trips", "Reschedule", "My Trips_Logs_Allow Logs") && (pnr === null && bookingJSON.data.requestType === "ON" && props.Confirmation === "2") && <div className='float-lg-end'>
                    <span id="Logs" className='btn btn-sm text-white mt-1 ms-1 btn-dark float-end' onClick={() => onLogs()}><i className="fas fa-hourglass-half"></i>
                    </span>
                </div>}



                <ReactTooltip
                    anchorId={"notes"}
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"Internal Notes"}
                />
                <ReactTooltip
                    anchorId={"history"}
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"History"}
                />
                <ReactTooltip
                    anchorId={"Logs"}
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"Logs"}
                />
            </div>
        </div>

        {props.hideData !== 1 && bookingJSON.data.requestType !== "OF" && pnr !== null && bookingJSON.data.travelerInfo.traveler[0].ticketInfo === undefined  && bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo !== undefined && bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket !== undefined && bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'Y' && <p className="error-text fw-bold" id="blankTicket">{bookingJSON.data.errors.error.description}</p>}

        

        <div className='row'>
            <div className='col-md-12'>

                {props.hideData !== '1' && <div className='col-md-12'>
                    <div className='text-muted h6 mb-2 mt-2'>
                        <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                        {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>}
                        {bookingJSON.data.bookingReferenceID !== undefined && bookingJSON.data.hotelResults[0].confirmationNumber !== undefined && <span> | <b className='text-primary'>Hotel Confirmation No. </b> <span className='text-black'>{bookingJSON.data.hotelResults[0].confirmationNumber}</span></span>}
                        <p className="card-text mb-0 mt-1 text-primary">  {props.hideData !== '1' && <div className='col-md-8'>
                            <label for="" className="h5 text-primary my-2"><span className="h6 "><i className="fas fa-plane-departure"></i> Flight </span><span class={bookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={bookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {bookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>
                        </div>}</p>


                    </div>
                </div>}
            </div>
        </div>
    </Fragment>

}
export default ShoppingCartBookingDetails;