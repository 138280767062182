import { useEffect, useState, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import useHttp from '../../../services/use-http';
import useHttpGet from '../../../services/use-http'
import { addApprover, getSingleApprover, updateApprover } from '../../../services/Approver-API'
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import DelegateForm from './Components/DelegateForm';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../../utils/RoleUtils';

const NewDelegate = (props) => {
    useDocumentTitle('Delegate Approver')
    const { t } = useTranslation(['translation.Approver']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addApprover
    } else {
        varHttp = updateApprover
    }
    const { sendRequest: sendRequest1, data: loadedProfileDetails, status: status1, error } = useHttpGet(getSingleApprover);

    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("Master", "Approver", "Master_Approver_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "Approver", "Master_Approver_View") && Action === "0")
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {

        if (isEdit || Id === undefined) {

            if (status === 'completed') {

                if (loadedQuote && loadedQuote['errors'].status === 'TRUE') {

                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecord')}`,
                        });
                        //return;


                    }
                    else {

                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }

                }

                else {

                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });

                }

                return;

            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])


    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])
    if (status1 === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }
    let loadedData = [];
    for (let i in loadedProfileDetails) {
        if (loadedProfileDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedProfileDetails[i];
            }
        }
    }


    const addApproverHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);

        if (!loadedQuote || (loadedQuote['errors'].error['code'] === '1001')) {
            history.push('/allapprover');
        }
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/allapprover');
        }

    };
    const approverFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p className='text-center text-primary h5 p-4'>{t('approverDetailsError')}</p> :
        <DelegateForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddApprover={addApproverHandler}
            IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />


    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {approverFormContent}
    </Fragment>
};

export default NewDelegate;