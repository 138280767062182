import { Fragment } from 'react';

const ContactPerson = (props) => {
    return <Fragment>
        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                Booking Contact
            </h5>
            <div id="collapsesix" className="accordion-collapse collapse show">
                <div className='row my-2'>
                    <div className='col-md-12'>
                        {props.print === undefined ? <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12"><div className='h6 mb-2 text-primary'> Phone </div> </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                <div className='h6 mb-2'>{props.hotelBookingJSON.data.travelerInfo.guestInfo[0].telephone[0].phoneNumber} </div> </div>
                        </div> : <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div style={{ display: 'flex' }}>
                                <div className='h6 mb-2 text-primary'> Phone </div>
                                <div className='h6 mb-2 ms-3'>{props.hotelBookingJSON.data.travelerInfo.guestInfo[0].telephone[0].phoneNumber} </div> </div>
                        </div>}
                    </div>
                    <div className='col-md-12'>
                        {props.print === undefined ? <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className='h6 mb-2 text-primary'> Email</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                <div className='h6 mb-2'>{props.hotelBookingJSON.data.travelerInfo.guestInfo[0].email[0].emailId} </div>
                            </div>
                        </div> : <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div style={{ display: 'flex' }}>
                                <span className='h6 mb-2 text-primary'> Email</span>
                                <span className='h6 mb-2 ms-3'>{props.hotelBookingJSON.data.travelerInfo.guestInfo[0].email[0].emailId} </span>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default ContactPerson;