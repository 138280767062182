import { Fragment, useState, useRef, useEffect } from "react";
import Dropdown from 'react-select';
const statusOptions = [
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
];

const ApproverDetails = (props) => {
    const [selectedStatus, setselectedStatus] = useState('');
    const stausChangeHandler = (event) => {
        setselectedStatus(event.value);
    };
    const submitFormHandler = () => {

    }
    function functionExit() {
        props.errorHandler();
    }
    return <Fragment>
        <div className="row">
            <div className="col-md-12 gt-h-340px">
                <div className="control">
                    <div className='col-md-12 col-sm-12 control'>
                        <label className="h6 text-primary mb-2 col-lg-12" for="category"> Status <span className="error-icon">*</span>  </label>
                        <Dropdown
                            onChange={stausChangeHandler}
                            value={statusOptions.filter(option => option.value === selectedStatus)}
                            options={statusOptions}
                            textField="listValue"
                            dataItemKey="listID"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                            isDisabled={false}

                        />
                    </div>
                    <div className='col-md-12 col-sm-12 control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Remarks</label>
                        <div>
                            <textarea id="thresholdAlertEmail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12"

                            >
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-12 my-auto align-right">
            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler} >
                Confirm
            </button>
            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit} >
                Cancel
            </button>
        </div>


    </Fragment>
};
export default ApproverDetails;