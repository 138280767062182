import { Fragment } from 'react';
import ChangePasswordForm from '../Components/ChangePasswordForm'

const ChangePassword = () => {
  return (
  <Fragment>
    {/*     <!-- Common Banner Area --> */}

    <section id="common_banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="common_bannner_text">
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ChangePasswordForm />
  </Fragment>)

};

export default ChangePassword;
