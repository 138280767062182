import React from "react";
import ReactToPrint from "react-to-print";
import { Tooltip as ReactTooltip } from "react-tooltip";
import HotelPrintConfrimation from './../HotelPrintConfrimation'

class ComponentToPrint extends React.Component {

    render() {
        return (
            
            <div style={{ getPageMargins }}>
                <HotelPrintConfrimation page={this.props.page}></HotelPrintConfrimation> 
            </div>
        );
    }
}
// const pageStyle ="
//   @page {
//     size: A4 landscape;
//   }
// ";
const marginTop = "50px"
const marginRight = "0px"
const marginBottom = "50px"
const marginLeft = "0px"
const getPageMargins = () => {
    // return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    return `@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }`
};

class PrintComponent extends React.Component {
    render() {

        return (
            <div >
                <style >{getPageMargins()}

                </style>
                <ReactToPrint
                   
                    trigger={() => <span id="Print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i></span>}
                    content={() => this.componentRef}
                />
                <ReactTooltip
                    anchorId="Print"
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"Print"}
                />
                <div style={{ display: "none" }}>
                    <ComponentToPrint ref={(el) => (this.componentRef = el)} page={this.props.page}/>

                </div>

            </div>
        );
    }
}

export default PrintComponent;
