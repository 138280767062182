import { Fragment } from 'react';
const LoadingHotel = (props) => {

  return (
    <Fragment>
      <div className='loading'>

        <div className="loader flight-loader-width">
          <div className="plane">
            <img src="assets/img/loader5.gif" className="plane-img" />
          </div>
          <div className='postion-relative flight-loaders'>
            <div className="earth-wrapper">
              <div className="earth"></div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {
            props.action === 'search' &&
            <div className='position-absolute bottom-0 text-center w-100'>
              {/* <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div> */}
              <div >
                We are searching for <i>live</i> hotels and rates<i> in real-time.</i>
                <br></br>
                Please wait for a few seconds…
              </div>
            </div>
          }
          {
            props.action === 'details' &&
            <div className='position-absolute gt-bottom-15 text-bottom w-100'>
              Please wait while we look for the hotel details.
            </div>
          }
          {
            props.action === 'booking' &&
            <div className='position-absolute bottom-0 text-center w-100'>
              Please wait while the hotel reservation system confirms your booking.
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          }
          {
            props.action === 'requestTypeOf' &&
            <div className='position-absolute bottom-0 text-center w-100'>
              Please wait while your booking request is submitted.              
            </div>
          }
        </div>
      </div>
      {/* <div className='loading'>
        <div className='flight-loader2'>
        </div>
        <div className='position-absolute bottom-0 text-center'>
            <div >
              We are looking for the best available flights for your search.
              <br></br>
              This might take a few seconds. Please do not close this window.
            </div>
            <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div>
          </div>
      </div> */}

    </Fragment>)
}
export default LoadingHotel;
