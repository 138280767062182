
import { Fragment } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'


/* import FlightBookingList from './Components/FlightBookingList' */

const PrintSales = (props) => {
    useDocumentTitle('Dashboard')

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));  

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Flight Booking Report </label>
                                                </div>
                                               
                                                <div className="col-lg-12">
                                                    <div
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="">
                                                            <div className='row'>
                                                                <div className='col-md-6'>

                                                                </div>
                                                                {/* <div className='col-md-12'> */}
                                                                <div className="table-responsive my-2">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope='col'>Date From</th>
                                                                                <th scope="col">01 Apr 2023</th>
                                                                                <th scope='col'>Date From</th>
                                                                                <th scope="col">30 Apr 2023</th>
                                                                                {/* <th scope="col">Pax Type</th> */}
                                                                                {/* <th scope="col">Age(yrs)</th> */}

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12" >
                                                  {/*   <FlightBookingList currentItems={reportJSON.data}></FlightBookingList> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default PrintSales;