import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CountryItem from './CountryItem'
import useHttp from '../../../../services/use-http'
import { deleteSingleCountry } from '../../../../services/Country-API'
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";

const sortCountry = (country, ascending) => {
  return country.sort((A, B) => {
    if (ascending) {
      return A.countryName > B.countryName ? 1 : -1;
    } else {
      return A.countryName < B.countryName ? 1 : -1;
    }
  });
};

const CountryList = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [error1, setError] = useState();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';

  const sortedCountry = sortCountry(props.country, isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };
  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleCountry, true);
  const sucsssMessage = 'Please note that your record deleted sucessfully.'
  useEffect(() => {
    document.querySelector("#sortBy").selectedIndex = 1;
    history.push({
      pathname: '/AllCountry',
      search: `?sort=${('asc')}`
    })
    if (status === 'pending') {
      <Fragment>
          <section id='common_banner'><div className='container'></div></section>
          <div className='centered'>
              <LoadingSpinner></LoadingSpinner>
          </div>
      </Fragment>
    }
    if (status === 'completed') {
      setError({
        title: "Message",
        message: `${sucsssMessage}`,
      });
      return;
    }
  }, [status, history])

  const deleteItemsHandler = (ID) => {
    sendRequest(ID);

    if (error) {
      return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>Items not deleted!!</p>
    }
  };
  //End code for delete
  const errorHandler = () => {
    setError(null);
    history.go('/allcountry');
  };
  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      
     
      <div className="container-fluid mb-3 list-view">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>

            <div className='row mb-3'>
                <div className="col-md-6"></div>
                <div className='col-md-2 my-auto text-muted text-left'>
                  <div className="float-lg-end float-md-end">Sort By</div>
                </div>
                <div className="col-md-4 form-group">
                  <select id='sortBy' onChange={changeSortingHandler} className="form-control">
                    <option>Country Name (Z to A)</option>
                    <option>Country Name (A to Z)</option>
                  </select>
                </div>
            </div>
          </div>
        </div>
        {sortedCountry.map((country) => (
          <CountryItem onDelete={deleteItemsHandler}
            key={country.id}
            id={country.id}
            countryCode={country.countryCode}
            continetID={country.continetID}
            countryName={country.countryName}
            isoCountryCode={country.isoCountryCode}
            currencyCode={country.currencyCode}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default CountryList;