import ContinentForm from '../Continent/Components/ContinentForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addContinent, getSingleContinent, updateContinent } from '../../../services/Continent-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
const NewContinent = () => {
   
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id } = parms;
    let varHttp;
    useDocumentTitle(Id===undefined?'New Continent':'Update Continent')
    if (Id === undefined) {
        varHttp = addContinent
    } else {
        varHttp = updateContinent
    }
    const { sendRequest: sendRequest1, data: loadedContinent, error } = useHttpGet(getSingleContinent);
    const { sendRequest, status } = useHttp(varHttp);
    const history = useHistory();
    
    const sucsssMessage=(Id !== undefined)?'Please note record updated sucessfully.': 'Please note record inserted sucessfully.'
    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                setError({
                    title: "Message",
                    message: `${sucsssMessage}`,
                  });
                  return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit,sucsssMessage])

    useEffect(() => {
        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
    }
    if ((!Id === undefined && (!loadedContinent || loadedContinent.length === 0))) {
        return <p>No quote found!</p>
    }

    const addContinentHandler = (requestData) => {

        sendRequest(requestData);
        //history.push('/quotes')
    };
    const errorHandler = () => {
        setError(null);
        history.push('/allcontinent');
      };
    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner"></section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-12">
                        
                    </div>
                </div>
            </div>
        </section>
        <ContinentForm loadedContinent={loadedContinent} isLoading={status === 'pending'} onAddContinent={addContinentHandler} />
    </Fragment>
};

export default NewContinent;