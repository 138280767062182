import {
    GET_CUSTOMIZESETTING,
    ADD_CUSTOMIZE,
    UPDATE_CUSTOMIZE,
    GET_CUSTOMIZE,

} from './CONSTANTS'

export async function getCustomizeSetting(ID){
    console.log(ID);
    const response =await fetch(GET_CUSTOMIZESETTING(ID),{
        method: "GET",
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
   // console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Customize Setting');
    }
    console.log(data);
    return data;

}
export async function getCustomize(ID){

    const response =await fetch(GET_CUSTOMIZE(ID),{
        method: "GET",
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
   
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Customize Setting');
    }
     console.log(data);
    return data;

}


export async function addCustomizeSetting(addData){
    //console.log(addData);
    const response = await fetch(ADD_CUSTOMIZE(addData), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });
    const data = await response.json();
   // console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not create customize Setting');
    }
    return data;

}
export async function updateCustomizeSetting(updateData){
    console.log(updateData);
    const response = await fetch(UPDATE_CUSTOMIZE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }

    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update customize setting');
    }
    console.log(data);
    return data;

}