import { Fragment, useEffect } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import BookingDetails from '../HotelBookingConfirmation/Components//BookingDetails';
import HotelDetails from '../HotelBookingConfirmation/Components/HotelDetails';
import RateDetails from '../HotelBookingConfirmation/Components//RateDetails';
import ContactPerson from '../HotelBookingConfirmation/Components//ContactPerson';
import GuestInformation from '../HotelBookingConfirmation/Components//GuestInformation';
import BillingDetails from '../HotelBookingConfirmation/Components//BillingDetails';
import BookingRemarks from '../HotelBookingConfirmation/Components//BookingRemarks';
import Booking from '../HotelBookingConfirmation/Components/Booking';
import CancellationPolicy from '../HotelBookingConfirmation/Components/CancellationPolicy';
import HotelPolicy from '../HotelBookingConfirmation/Components/HotelPolicy';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const RetrieveHotel = (props) => {
    useDocumentTitle('Trip Details')
    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-confirm-hotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    const location = useLocation();
    const valueParam = queryString.parse(location.search);
    let hotelFirstImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length > 0 ? hotelBookingJSON.data.hotelResults[0].images[0].imagePath : hotelLogoLink;
    let hotelSecondImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length > 0 ? hotelBookingJSON.data.hotelResults[0].images[1].imagePath : "";

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="">
                                <BookingDetails hotelBookingJSON={hotelBookingJSON} report={valueParam.report !== undefined ? valueParam.report : 'false'}></BookingDetails>
                                {hotelSecondImage !== '' &&
                                    <div className="hotel-list row">
                                        <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12'>
                                            <img src={hotelFirstImage} className='w-100' loading='lazy' />
                                        </div>
                                        <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12'>
                                            <img src={hotelSecondImage} className='w-100' loading='lazy' />
                                        </div>
                                        <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                    </div>}
                                {hotelSecondImage === '' &&
                                    <div className="hotel-list row">
                                        <div className='hotel-confirmation-single-img col md-12'>
                                            <img src={hotelFirstImage} className='w-100' loading='lazy' />
                                        </div>

                                        <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                    </div>}

                                <div className="tour_details_boxed_inner">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion_flex_area">
                                            <Booking hotelBookingJSON={hotelBookingJSON}></Booking>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <RateDetails hotelBookingJSON={hotelBookingJSON} show=""></RateDetails>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <ContactPerson hotelBookingJSON={hotelBookingJSON}></ContactPerson>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <GuestInformation hotelBookingJSON={hotelBookingJSON}></GuestInformation>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <BillingDetails hotelBookingJSON={hotelBookingJSON}></BillingDetails>
                                        </div>
                                        {hotelBookingJSON.data.hotelMiscellaneousInfo.miscellaneousInfo[0].text !== "" ? <Fragment><hr></hr>
                                            <div className="accordion_flex_area">
                                                <BookingRemarks hotelBookingJSON={hotelBookingJSON}></BookingRemarks>
                                            </div></Fragment> : ''}
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <HotelPolicy hotelBookingJSON={hotelBookingJSON}></HotelPolicy>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default RetrieveHotel;