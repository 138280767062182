import React from "react";



const Button = (props) => {
  return (
    <button
      className='btn gt-btn_md text-white gt-main-btn'
      type={props.type || "button"}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
