import { Fragment } from 'react';

const Ameneties = (props) => {
    let service = props.hotelDetails.data.hotelResults[0].hotelAmenities;
    return (<Fragment>
        <div className="col-lg-12 margin-top-15 mt-2 ">
            <div className="tour_detail_right_sidebar">
                <div className="tour_details_right_boxed" style={{ background: "transparent", boxShadow: "none" }}>
                    <div className="tour_details_right_box_heading">
                        <h3>Amenities</h3>
                    </div>
                    <div className="tour_package_details_bar_list first_child_padding_none">
                        <div className='row'>
                            {service !== '' && service.map((item,index) => {
                                return <div className='py-1 col-md-6' id={"service"+index}><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{item.name}</div>

                            })}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>)
}

export default Ameneties;