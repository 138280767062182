// import { Fragment } from 'react';
import { Fragment, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { Tooltip as ReactTooltip } from "react-tooltip";
import PrintHotelBooking from './PrintHotelBooking'
import PaginatedItems from './Components/PaginatedItems'
import ReactDOM from 'react-dom';
import ReactToPrint from "react-to-print";
import * as React from "react";
import { getFormatDate } from './../../../utils/CommonFunction';
import ExportExcel from './Components/ExportExcel';

const DisplayHotelBooking = (props) => {
    useDocumentTitle('Hotel Booking Report')

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));
    const componentRef = useRef();
    console.log(reportJSON);
    const marginTop = "50px"
    const marginRight = "0px"
    const marginBottom = "100px"
    const marginLeft = "0px"
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };
    const ComponentToPrint = React.forwardRef((props, ref) => {
        const { value } = props;


        return (
            <div style={{ display: "none" }}>
                <style>{getPageMargins()}</style>
                <div className="print-source" ref={ref}>
                    <PrintHotelBooking>

                    </PrintHotelBooking>

                </div> </div>
        );
    });

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Hotel Booking Report </label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='float-lg-end d-none'>

                                                        <ReactToPrint
                                                            trigger={() => <div id="print" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end' title='Take a Print'><i className="fa-solid fa-print"></i></div>}
                                                            content={() => componentRef.current}
                                                        />
                                                        <ComponentToPrint ref={componentRef}
                                                        /*    key={props.key}
                                                               id={props.id}
                                                               airItinerary={props.airItinerary}
                                                               searchHotel={props.searchHotel} */
                                                        />
                                                    </div>
                                                    <div className='float-lg-end'>
                                                        <span id="excel" className='float-end'>
                                                            <ExportExcel reportJSON={reportJSON.data} ></ExportExcel>
                                                        </span>
                                                    </div>
                                                    <div className='float-lg-end d-none'>
                                                        <span id="pdf" className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end' ><i className="fas fa-file-pdf"></i>
                                                        </span>
                                                    </div>
                                                    <div className='float-lg-end'>
                                                        <a id='back' href="/SearchHotelBooking" className='btn text-white gt-main-btn btn-sm ms-1 mt-1 float-lg-end'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>

                                                    <ReactTooltip
                                                        anchorId={"print"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Take a print"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"pdf"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export PDF"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"excel"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export Excel"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"back"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Back to Search"}
                                                    />

                                                </div>
                                                <div className="col-lg-12">
                                                    <div
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="">
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                </div>
                                                                <div className="table-responsive my-2">
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td scope='col' className='fw-bold'>Date From</td>
                                                                                <td scope="col">{getFormatDate(reportJSON.data[0].dateFrom.replaceAll('-', ''))}</td>
                                                                                <td scope='col' className='fw-bold'>Date To</td>
                                                                                <td scope="col">{getFormatDate(reportJSON.data[0].dateTo.replaceAll('-', ''))}</td>
                                                                                {/* <td scope="col">Pax Type</td> */}
                                                                                {/* <td scope="col">Age(yrs)</td> */}

                                                                            </tr>
                                                                            <tr>
                                                                                <td className='w-25 fw-bold'>Company Name</td>
                                                                                <td className='w-25'>{reportJSON.data[0].companyNameInput}</td>
                                                                                <td className='w-25 fw-bold'>Status</td>
                                                                                <td className='w-25'>{reportJSON.data[0].statusInput}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12" >
                                                        <PaginatedItems itemsPerPage={10} />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default DisplayHotelBooking;