import { useState, Fragment, useEffect, useCallback } from "react";
import { getAllAirline, searchairline } from '../../../services/Airline-API'
import { NEWAIRLINE } from "../../../navigation/CONSTANTS";
import useHttp from "../../../services/use-http";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { NavLink, useHistory } from "react-router-dom";
import AirlineList from "./Components/AirlineList";
import { useTranslation } from "react-i18next";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'

const AllAirline = () => {
    useDocumentTitle('Search Airline')
    const [enteredName, setEnterName] = useState("");
    const [enteredCode, setEnteredCode] = useState("");
    const [isFirtTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);
    const history = useHistory();

    const { t } = useTranslation(['translation.Airline']);


    let varHttp;
    if (enteredName.trim().length === 0 && enteredCode.trim().length === 0) {
        varHttp = getAllAirline;
    } else {
        varHttp = searchairline;
    }
    const namechangehandler = (event) => {
        setEnterName(event.target.value);
    };
    const codechangehandler = (event) => {
        setEnteredCode(event.target.value);
    }
    const { sendRequest, status, data: loadedAirline, error } = useHttp(varHttp);



    const onResetHandler = () => {
        // event.preventDefault();
        // setEnterName('');
        // setEnteredCode('');
        history.replace('/allAirline');
        // history.go();

    }


    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        setFirstTime(true);
        // sendRequest(enteredName);
        if (enteredName.trim().length === 0 && enteredCode.trim().length === 0) {
            sendRequest(enteredName, enteredCode);
        } else {
            const searchAirlineParameter = { airlineCode: enteredCode, airlineName: enteredName };
            sendRequest(searchAirlineParameter);
        }

    }, [sendRequest, enteredName, enteredCode])
    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }
    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>

    }




    let loadedData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedAirline[i];
            }
        }
    }

    function okayHandler() {
        setError1(false);
        history.go('/allairline');

    }
    const errorHandler = () => {

        history.go('/allAirline');
    };

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }
    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''
    const airlineList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <AirlineList airline={loadedData} /> : ErrorShow

    return <Fragment>
        {error1 && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('searchAirline')}</label>
                                            <form>
                                                <div className="row">
                                                    <div className='control col-lg-6'>
                                                        <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">{t('airlinename')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' value={enteredName} maxLength={100} onChange={namechangehandler} autoFocus />
                                                    </div>
                                                    <div className='control col-lg-6'>
                                                        <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">{t('airlinecode')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' value={enteredCode} maxLength={2} onChange={codechangehandler}
                                                            onInput={changeToUpperCase} />

                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "Airline", "Master_Airline_Insert") && <NavLink className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' to={NEWAIRLINE}>
                                                        {t('new')}
                                                    </NavLink>}
                                                    {checkSecurityDetails("Master", "Airline", "Master_Airline_Search") && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {airlineList}
    </Fragment>
};

export default AllAirline;