import { Fragment, useEffect, useState, React } from 'react';
import Dropdown from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';

const ServiceRequest = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [serviceRequestValue, setServiceRequestValue] = useState('');
    const [value, setValue] = useState('');

    let serviceRequestOptions = [
        { value: 'Accommodation', label: 'Accommodation' },
        { value: 'Car (chauffeur-driven)', label: 'Car (chauffeur-driven)' },
        { value: 'Car (self-driven)', label: 'Car (self-driven)' },
        { value: 'Coach', label: 'Coach' },
        { value: 'Flight', label: 'Flight' },
        { value: 'Meet and greet', label: 'Meet and greet' },
        { value: 'Passport Assistance', label: 'Passport Assistance' },
        { value: 'Rail', label: 'Rail' },
        { value: 'Travel Insurance', label: 'Travel Insurance' },
        { value: 'Visa', label: 'Visa' },
        { value: 'Other Service', label: 'Other Service' }
    ];

    const serviceRequestChangeHandler = (event) => {
        setServiceRequestValue(event.value);
    };

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service">Service<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown onChange={serviceRequestChangeHandler}
                                            value={serviceRequestOptions.filter(option => option.value === serviceRequestValue)}
                                            options={serviceRequestOptions}
                                            textField="value"
                                            dataItemKey="label"
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Remarks<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        {/* <textarea id='remarks'                                            
                                            className="form-control form-control-sm"                                            
                                            rows={2} cols={100} maxLength={300}
                                            onBlur={onBlurCheck}
                                        /> */}
                                        <div style={{ width: 1000, height: 200 }}>
                                            <ReactQuill theme="snow" value={value} onChange={setValue} style={{ width: 1000, height: 150 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="existingConfirmationNumber">Existing Confirmation Number</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='existingConfirmationNumber' />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="totalRateOfService">Total Rate of service (if available)</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='totalRateOfService' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)

};

export default ServiceRequest;

