/* global google */
import { Fragment, useState } from 'react';
import { InfoWindow, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
function Map(props) {
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const { hotelName, longitude, latitude, address } = props;
  //console.log(longitude, latitude)
  // const mapUrl = `https://www.google.com/maps/embed/v1/view?key=AIzaSyCUXDdH1mkfCdWlmL7iLb0vTDvMsf-9OyA&center=${latitude},${longitude}&zoom=15`;
  // const mapUrl=`https://www.google.com/maps/embed/v1/place?key=AIzaSyCUXDdH1mkfCdWlmL7iLb0vTDvMsf-9OyA&q=${latitude},${longitude}&zoom=15`
  const mapUrl = (`https://www.google.com/maps/embed/v1/place?key=AIzaSyCUXDdH1mkfCdWlmL7iLb0vTDvMsf-9OyA&q=${hotelName}&center=${latitude},${longitude}&zoom=15`);
  // if HTML DOM Element that contains the map is found...

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCUXDdH1mkfCdWlmL7iLb0vTDvMsf-9OyA&q=",
  });

  const markers = [
    { address: props.hotelName + " " + props.address, lat: parseFloat(props.latitude), lng: parseFloat(props.longitude) },

  ];

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ address, lat, lng }) => bounds.extend({ address, lat, lng }));


   // var originalMaxZoom = map.maxZoom;
    map.setOptions({maxZoom: 15});

    map.fitBounds(bounds);
    //map.setOptions({maxZoom: originalMaxZoom});
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ address, lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };


  return (
    <Fragment>

      {/*  <iframe
          title="Google Map"
          width="100%"
          height="450"
          src={mapUrl}
          frameborder="0" style={{border:"0"}} allowfullscreen
        ></iframe> */}


      {!isLoaded ? (
        <h4>Loading...</h4>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          onLoad={onMapLoad}


          onClick={() => setIsOpen(false)}
        >
          {markers.map(({ address, lat, lng }, ind) => (
            <Marker
              key={ind}
              position={{ address, lat, lng }}
              icon={"assets/logos/hotels/HotelMarker.png"}
              style={{width: 26, height: 28}}
              onClick={() => {
                handleMarkerClick(ind, lat, lng, address);
              }}
            >
              {isOpen && infoWindowData?.id === ind && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <h5>{infoWindowData.address}</h5>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      )}


    </Fragment>
  );
}

export default Map;
