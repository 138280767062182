import React, { Fragment, useState, useCallback } from 'react';
import Dropdown from 'react-select';
import { RULESFORM, UPDATERULES } from '../../../navigation/CONSTANTS';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RulesItem from './Components/RulesItem';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
const postionOptions = [
    { value: 1, label: "Manager" },
    { value: 2, label: "Product Manager" },
    { value: 3, label: "Team Leader" },
    { value: 4, label: "Manager-Social Media" },
    { value: 5, label: "Manager-Marketing" },
    { value: 6, label: "Manager-Operations" },
    { value: 7, label: "Manager-Sales" },
    { value: 8, label: "Manager-Human Resources" },
    { value: 9, label: "Manager-Customer Success" },
    { value: 10, label: "Manager-Training" },
    { value: 11, label: "Vice President-Sales" },
    { value: 12, label: "Vice President-Marketing" },
    { value: 13, label: "Vice President-Operations" },
    { value: 14, label: "Vice President-Business Development" },
    { value: 15, label: "Chief Executive Officer" },
    { value: 16, label: "Chief Financial Officer" },
    { value: 17, label: "Executive - Marketing" },
    { value: 18, label: "Executive-Social Media" },
    { value: 19, label: "Executive - Operations" },
    { value: 20, label: "Executive - Training" },
    { value: 21, label: "Executive - Sales" },


];
const tripReasonOptions = [
    { value: 1, label: "All" },
    { value: 2, label: "Business" },
    { value: 5, label: "Personal" },
];
const SearchRules = () => {
    postionOptions.sort((a, b) => a.label.localeCompare(b.label));
    useDocumentTitle("Search Policy")
    const [postion, setPostion] = useState('');
    const [tripReason, setTripReason] = useState('');
    const { t } = useTranslation(['translation.Rules']);

    const history = useHistory();
    const positionChangeChangeHandler = (event) => {
        setPostion(event.value);
    };

    const tripReasonChangeHandler = (event) => {
        setTripReason(event.value);
    };
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();


    }, [])
    const onResetHandler = (event) => {
        history.push('/searchrules');
        history.go();
    }
    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {/* {status === 'pending' &&
                                    // <div className=''>
                                    <LoadingSpinner></LoadingSpinner>
                                    // </div>
                                } */}

                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Policy</label>
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('position')}</label>
                                                    <Dropdown
                                                        onChange={positionChangeChangeHandler}
                                                        value={postionOptions.filter(option => option.value === postion)}
                                                        options={postionOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('tripReason')}</label>
                                                    <Dropdown onChange={tripReasonChangeHandler}
                                                        value={tripReasonOptions.filter(option => option.value === tripReason)}
                                                        options={tripReasonOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 col-md-12">
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>

                                                <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATERULES}>
                                                    {t('new')}
                                                </NavLink>

                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                    <RulesItem></RulesItem>
                </div>
            </section>


        </Fragment>)
}

export default SearchRules;