import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http';
import { searchFeeApi, searchCommonList } from '../../../services/Fee-API';
import FeeList from './Components/FeeList';
import { NavLink } from 'react-router-dom';
import { UPDATEFEE } from '../../../navigation/CONSTANTS'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import { useHistory } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { SearchRegionApi } from '../../../services/Region-API';

const SearchFee = () => {
    useDocumentTitle('Search Fee');

    const [enteredFeeName, setEnteredFeeName] = useState("");
    const [channelCode, setChannelCode] = useState('');
    const [serviceCode, setServiceCode] = useState('');
    const [regionFromCode, setRegionFromCode] = useState('');
    const [regionToCode, setRegionToCode] = useState('');

    const [isFirtTime, setFirstTime] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const { t } = useTranslation(['translation.Fee']);
    const history = useHistory();

    const feeNameChangeHandler = (event) => {
        setEnteredFeeName(event.target.value);
    };

    const channelChangeHandler = (event) => {
        setChannelCode(event.label);
    };

    const serviceChangeHandler = (event) => {
        setServiceCode(event.value);
    };

    const regionFromChangeHandler = (event) => {
        setRegionFromCode(event.value);
    };

    const regionToChangeHandler = (event) => {
        setRegionToCode(event.value);
    };

    const { sendRequest: sendRequestCommonListChannel, data: loadedChannel } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListService, data: loadedService } = useHttp(searchCommonList);
    const { sendRequest: sendRequestRegion, data: loadedRegion } = useHttp(SearchRegionApi);

    let channelValue = '';
    let regionValue = '';

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        const onSearchCommonListChannel = ({
            methodName: "Channel",
            methodCode: "CHA",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListChannel(onSearchCommonListChannel);

        const onSearchCommonListService = ({
            methodName: "service",
            methodCode: "SER",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListService(onSearchCommonListService);

        const onSearchRegion = ({
            regionID: "",
            serviceID: "",
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestRegion(onSearchRegion);

    }, [sendRequestCommonListChannel, sendRequestCommonListService,
        sendRequestRegion
    ])

    let channelOptions = []
    let channelArrayData = [];
    for (let i in loadedChannel) {
        if (loadedChannel['errors'].status === 'FALSE') {
            if (i === 'data') {
                channelArrayData = loadedChannel[i];
            }
        }
    }

    channelValue = channelArrayData.filter(function (value, index, arr) {
        return value.listValue === String('Both')
    }).map(filteredChannel => filteredChannel.listValue)[0];

    for (let index = 0; index < channelArrayData.length; index++) {
        channelOptions = [...channelOptions, { value: channelArrayData[index].id, label: channelArrayData[index].listValue }]
    }

    let serviceOptions = []
    let serviceArrayData = [];
    for (let i in loadedService) {
        if (loadedService['errors'].status === 'FALSE') {
            if (i === 'data') {
                serviceArrayData = loadedService[i];
            }
        }
    }

    for (let index = 0; index < serviceArrayData.length; index++) {
        serviceOptions = [...serviceOptions, { value: serviceArrayData[index].id, label: serviceArrayData[index].listValue }]
    }

    let regionOptions = []
    let regionArrayData = [];
    for (let i in loadedRegion) {
        if (loadedRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                regionArrayData = loadedRegion[i];
            }
        }
    }

    regionValue = regionArrayData.filter(function (value, index, arr) {
        return value.regionName === String('All')
    }).map(filteredRegion => filteredRegion.id)[0];

    for (let index = 0; index < regionArrayData.length; index++) {
        regionOptions = [...regionOptions, { value: regionArrayData[index].id, label: regionArrayData[index].regionName }]
    }    

    if (regionValue !== undefined && regionFromCode === '') {
        setRegionFromCode(regionValue);
    }

    if (regionValue !== undefined && regionToCode === '') {
        setRegionToCode(regionValue);
    }

    const { sendRequest, status, data: loadedFee, error } = useHttp(searchFeeApi);
    const onResetHandler = (event) => {
        history.push('/SearchFee')
    }

    const enteredFeeNameInputRef = useRef('');
    const [enteredFeeNameInvalidInputError, setEnteredFeeNameInvalidInputError] = useState(false);

    function onBlurCheck() {
        if (enteredFeeName.trim().length !== 0) {
            if (!enteredFeeName.match('^[a-zA-Z ]+$')) {
                setEnteredFeeNameInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredFeeNameInvalidInputError(false);
            }
        }
        else {
            setEnteredFeeNameInvalidInputError(false);
        }


    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);

        if (enteredFeeName.length !== 0) {
            if (!enteredFeeName.match('^[a-zA-Z ]+$')) {
                enteredFeeNameInputRef.current.focus();
                setEnteredFeeNameInvalidInputError(true);
                return;
            }
            else {
                setEnteredFeeNameInvalidInputError(false);
            }
        }
        else {
            setEnteredFeeNameInvalidInputError(false);
        }

        const onSearchFee = ({
            feeName: enteredFeeName,
            channelID: channelCode === channelValue ? "B2B/B2C" : channelCode,
            serviceID: serviceCode,
            fromRegion: regionFromCode === regionValue ? "" : regionFromCode,
            toRegion: regionToCode === regionValue ? "" : regionToCode,
            officeID: JSON.parse(localStorage.getItem('officeID'))
        });

        if (enteredFeeNameInvalidInputError === false) {
            setIsSubmitClicked(true);
            sendRequest(onSearchFee);
        }
    }, [sendRequest, enteredFeeNameInvalidInputError,
        channelCode, enteredFeeName, serviceCode, regionFromCode, regionToCode])

    useEffect((sendRequest) => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }

    const errorHandler = () => {
        history.go('/searchFee');
    };

    if (error) {
        return <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal>
    }

    let loadedData = [];
    for (let i in loadedFee) {
        if (loadedFee['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedFee[i];
            }
        }
    }

    const ErrorShow = (!enteredFeeNameInvalidInputError && isSubmitClicked) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const feeList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <FeeList fee={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Fee</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-md-12 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='feename'>{t('feename')}</label>
                                                            <input minLength={1} maxLength={100} type='text' className="mb-0 form-control form-control-sm col-lg-12" id='feename' value={enteredFeeName} ref={enteredFeeNameInputRef} onChange={feeNameChangeHandler} onBlur={onBlurCheck} autoFocus />
                                                            {enteredFeeNameInvalidInputError &&
                                                                <p className="error-text">{t('validationFeeNameInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='channel'>{t('channel')}</label>
                                                            <Dropdown onChange={channelChangeHandler}
                                                                value={channelOptions.filter(option => option.label === channelCode)}
                                                                options={channelOptions}
                                                                textField="listValue"
                                                                dataItemKey="id"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>{t('service')}</label>
                                                            <Dropdown onChange={serviceChangeHandler}
                                                                value={serviceOptions.filter(option => option.value === serviceCode)}
                                                                options={serviceOptions}
                                                                textField="listValue"
                                                                dataItemKey="id"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='fromregion'>{t('fromregion')}</label>
                                                            <Dropdown onChange={regionFromChangeHandler}
                                                                value={regionOptions.filter(option => option.value === regionFromCode)}
                                                                options={regionOptions}
                                                                textField="regionName"
                                                                dataItemKey="id"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='toregion'>{t('toregion')}</label>
                                                            <Dropdown onChange={regionToChangeHandler}
                                                                value={regionOptions.filter(option => option.value === regionToCode)}
                                                                options={regionOptions}
                                                                textField="regionName"
                                                                dataItemKey="id"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Settings", "Fee", "Settings_Fee_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={UPDATEFEE}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Settings", "Fee", "Settings_Fee_Search") &&
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {feeList}
        </Fragment>)
};

export default SearchFee