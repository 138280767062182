import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from 'react-color';
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { getBase64, generateUUID, maxSelectFile, checkMimeType } from '../../../../utils';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { regEmailID } from '../../../../config/RegularExpression'

const CustomizationForm = (props) => {
    localStorage.setItem("defaultSet", "0");

    const bottomTextInputRef = useRef('');
    const bottomRightTextInputRef = useRef('');
    const contactUsPhoneInputRef = useRef('');
    const contactUsEmailInputRef = useRef('');
    const linkName1InputRef = useRef('');
    const linkNameURL1InputRef = useRef('');
    const linkName2InputRef = useRef('');
    const linkNameURL2InputRef = useRef('');
    const linkName3InputRef = useRef('');
    const linkNameURL3InputRef = useRef('');
    const linkName4InputRef = useRef('');
    const linkNameURL4InputRef = useRef('');
    const linkName5InputRef = useRef('');
    const linkNameURL5InputRef = useRef('');
    const linkName6InputRef = useRef('');
    const linkNameURL6InputRef = useRef('');
    const linkName7InputRef = useRef('');
    const linkNameURL7InputRef = useRef('');
    const linkName8InputRef = useRef('');
    const linkNameURL8InputRef = useRef('');
    const linkName9InputRef = useRef('');
    const linkNameURL9InputRef = useRef('');
    const linkName10InputRef = useRef('');
    const linkNameURL10InputRef = useRef('');

    const rightLinkName1InputRef = useRef('');
    const rightLinkNameURL1InputRef = useRef('');
    const rightLinkName2InputRef = useRef('');
    const rightLinkNameURL2InputRef = useRef('');
    const rightLinkName3InputRef = useRef('');
    const rightLinkNameURL3InputRef = useRef('');
    const rightLinkName4InputRef = useRef('');
    const rightLinkNameURL4InputRef = useRef('');
    const rightLinkName5InputRef = useRef('');
    const rightLinkNameURL5InputRef = useRef('');
    const activePartnerInputRef = useRef('');
    const activeGDPRInputRef = useRef('');


    const [activePartner, setActivePartner] = useState(false);
    const [activeGDPR, setActiveGDPR] = useState(false);

    const { t } = useTranslation(['translation.Customize']);
    const companyLogoInputRef = useRef('');
    const bannerColorInputRef = useRef('');
    //const [content, setContent] = useState('');
    const buttonColorInputRef = useRef('');
    const textBorderColorInputRef = useRef('');
    const buttonGradientColorInputRef = useRef('');
    const textBackGroundColorInputRef = useRef('');
    const backGroundColorInputRef = useRef('');
    const footerBackGroundColorInputRef = useRef('');
    const [isFirtTime, setFirstTime] = useState(true);

    const [companyLogoImage, uploadCompanyLogo] = useState([]);
    const [companyLogoImageBinaryFile, setCompanyLogoImageBinaryFile] = useState('');
    const [buttonColor, setButtonColor] = useState("");
    const [buttonGradientColor, setButtonGradientColor] = useState('');
    const [textBackGroundColor, setTextBackGroundColor] = useState('');
    const [textBorderColor, setTextBorderColor] = useState('');
    const [bannerColor, setBannerColor] = useState("");
    const [backGroundColor, setBackGroundColor] = useState("");
    const [footerBackGroundColor, setFooterBackGroundColor] = useState("");
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayButtonGradientColorPicker, setDisplayButtonGradientColorPicker] = useState(false);
    const [displayTextBackgroundColorPicker, setDisplayTextBackGroundColorPicker] = useState(false);
    const [displayTextBorderColorPicker, setDisplayTextBorderColorPicker] = useState(false);
    const [pageTitle, setPageTitle] = useState(' Customize');

    const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
    const [displayFooterBackgroundColorPicker, setDisplayFooterBackgroundColorPicker] = useState(false);
    const [displayBannerColorPicker, setDisplayBannerColorPicker] = useState(false);



    function handleClick(event) {
        event.preventDefault();
        setDisplayColorPicker({ displayColorPicker: !displayColorPicker });

    };
    function handleButtonGradientClick(event) {
        event.preventDefault();
        setDisplayButtonGradientColorPicker({ displayColorPicker: !displayColorPicker });
    };
    function handleTextBackgroundColorClick(event) {
        event.preventDefault();
        setDisplayTextBackGroundColorPicker({ displayColorPicker: !displayColorPicker });
    };
    function handleTextBorderColorClick(event) {
        event.preventDefault();
        setDisplayTextBorderColorPicker({ displayColorPicker: !displayColorPicker });
    };
    function handleClose(event) {
        event.preventDefault();
        setDisplayColorPicker(false);

    };
    function handleButtonGradientClose(event) {
        event.preventDefault();
        setDisplayButtonGradientColorPicker(false);
    };
    function handleTextBackgroundColorClose(event) {
        event.preventDefault();
        setDisplayTextBackGroundColorPicker(false);
    };
    function handleTextBorderColorClose(event) {
        event.preventDefault();
        setDisplayTextBorderColorPicker(false);
    };
    function handleBackgroundClick(event) {
        event.preventDefault();
        setDisplayBackgroundColorPicker({ displayColorPicker: !displayColorPicker });
    };
    function handleFooterBackgroundClick(event) {
        event.preventDefault();
        setDisplayFooterBackgroundColorPicker({ displayColorPicker: !displayColorPicker });
    };
    function handleBackgroundClose(event) {
        event.preventDefault();
        setDisplayBackgroundColorPicker(false);
    };
    function handleFooterBackgroundClose(event) {
        event.preventDefault();
        setDisplayFooterBackgroundColorPicker(false);
    };

    function handleBannerClick(event) {
        event.preventDefault();
        setDisplayBannerColorPicker({ displayColorPicker: !displayColorPicker });
    };

    function handleBannerClose(event) {
        event.preventDefault();
        setDisplayBannerColorPicker(false);
    };
    const activePartnerChangeHandler = () => {
        setActivePartner(!activePartner);

    }
    const activeGDPRChangeHandler = () => {
        setActiveGDPR(!activeGDPR);

    }
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }


    //Error State
    const [companyLogoInvalidImage, setCompanyLogoInvalidImage] = useState(false);
    const [blankCompanyLogoError, setBlankCompanyLogoError] = useState(false)
    const [contactUsPhoneInputError, setContactUsPhoneInputError] = useState(false);
    const [blankBannerColorError, setBlankBannerColorError] = useState(false);
    const [blankTextBorderColorError, setBlankTextBorderColorError] = useState(false);
    const [invalidContactUsEmailError, setInvalidContactUEmailError] = useState(false);
    const [isCompanyLogoUploading, setIsCompanyLogoUploading] = useState(false);
    const [blankButtonColorError, setBlankButtonColorError] = useState(false);
    const [blankBackGroundColorError, setBlankBackGroundColorError] = useState(false);
    const [blankContactUsPhoneError, setBlankContactUsPhoneError] = useState(false);
    const [blankBContactUsEmailError, setBlankBContactUsEmailError] = useState(false);
    const [blankLinkName1Error, setBlankLinkName1Error] = useState(false);
    const [blankLinkNameURL1Error, setBlankLinkNameURL1Error] = useState(false);
    const [phoneLengthError, setPhoneLengthError] = useState(false);
    const [blankButtonGradientColorError, setBlankButtonGradientColorError] = useState(false);
    const [blankTextBackGroundColorError, setBlankTextBackGroundColorError] = useState(false);
    const [blankFooterBackgroundColorError, setBlankFooterBackgroundColorError] = useState(false);
    const [blankSocialMediaLinkNameError1, setBlankSocialMediaLinkNameError1] = useState(false);
    const [blankSocialMediaLinkUrlError1, setBlankSocialMediaLinkUrlError1] = useState(false);
    const [blankSocialMediaLinkNameError2, setBlankSocialMediaLinkNameError2] = useState(false);
    const [blankSocialMediaLinkUrlError2, setBlankSocialMediaLinkUrlError2] = useState(false);
    const [blankSocialMediaLinkNameError3, setBlankSocialMediaLinkNameError3] = useState(false);
    const [blankSocialMediaLinkUrlError3, setBlankSocialMediaLinkUrlError3] = useState(false);
    const [blankSocialMediaLinkNameError4, setBlankSocialMediaLinkNameError4] = useState(false);
    const [blankSocialMediaLinkUrlError4, setBlankSocialMediaLinkUrlError4] = useState(false);
    const [blankSocialMediaLinkNameError5, setBlankSocialMediaLinkNameError5] = useState(false);
    const [blankSocialMediaLinkUrlError5, setBlankSocialMediaLinkUrlError5] = useState(false);
    const [blankLinkName2Error, setBlankLinkName2Error] = useState(false);
    const [blankLinkNameURL2Error, setBlankLinkNameURL2Error] = useState(false);
    const [blankLinkName3Error, setBlankLinkName3Error] = useState(false);
    const [blankLinkNameURL3Error, setBlankLinkNameURL3Error] = useState(false);
    const [blankLinkName4Error, setBlankLinkName4Error] = useState(false);
    const [blankLinkNameURL4Error, setBlankLinkNameURL4Error] = useState(false);
    const [blankLinkName5Error, setBlankLinkName5Error] = useState(false);
    const [blankLinkNameURL5Error, setBlankLinkNameURL5Error] = useState(false);
    const [blankLinkName6Error, setBlankLinkName6Error] = useState(false);
    const [blankLinkNameURL6Error, setBlankLinkNameURL6Error] = useState(false);
    const [blankLinkName7Error, setBlankLinkName7Error] = useState(false);
    const [blankLinkNameURL7Error, setBlankLinkNameURL7Error] = useState(false);
    const [blankLinkName8Error, setBlankLinkName8Error] = useState(false);
    const [blankLinkNameURL8Error, setBlankLinkNameURL8Error] = useState(false);
    const [blankLinkName9Error, setBlankLinkName9Error] = useState(false);
    const [blankLinkNameURL9Error, setBlankLinkNameURL9Error] = useState(false);
    const [blankLinkName10Error, setBlankLinkName10Error] = useState(false);
    const [blankLinkNameURL10Error, setBlankLinkNameURL10Error] = useState(false);



    function emailValidation(email) {

        if (email.match(regEmailID)) {
            return true;
        }
        return false;
    }

    const companyLogoHandler = (e) => {
        setIsCompanyLogoUploading(true);
        let validImage = checkMimeType(e);

        if (validImage === false) {
            setCompanyLogoImageBinaryFile('');
            uploadCompanyLogo([]);
            setCompanyLogoInvalidImage(true);
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {
            // if return true allow to setState
            uploadCompanyLogo([]);
            uploadCompanyLogo(imgfile => [...imgfile, URL.createObjectURL(e.target.files[0])])
            setCompanyLogoImageBinaryFile({
                selectedFile: e.target.files[0],
                loaded: 0,
            })


            let { file } = companyLogoImageBinaryFile;

            file = e.target.files[0];

            getBase64(file)
                .then(result => {
                    file["base64"] = result;

                    setCompanyLogoImageBinaryFile({
                        base64URL: result,
                        file
                    });
                })
                .catch(err => {
                    //   console.log(err);
                });

            setCompanyLogoImageBinaryFile({
                file: e.target.files[0]
            });
            setBlankCompanyLogoError(false);
            setCompanyLogoInvalidImage(false);
        }
    }

    function bannerColorChangeHandler(color) {
        setBannerColor(color.hex);
        setBlankBannerColorError(false);
    }
    function buttonColorChangeHandler(color) {
        setButtonColor(color.hex);
        setBlankButtonColorError(false);
    }
    function buttonColorGradientChangeHandler(color) {
        setButtonGradientColor(color.hex);
    }
    function textBorderColorChangeHandler(color) {
        setTextBorderColor(color.hex);
    }
    function textBackGroundColorChangeHandler(color) {
        setTextBackGroundColor(color.hex);
    }
    function backGroundColorChangeHandler(color) {
        setBackGroundColor(color.hex);
        setBlankBackGroundColorError(false);
    }
    function footerBackGroundColorChangeHandler(color) {
        setFooterBackGroundColor(color.hex);

    };

    function setToDefault(event) {
        localStorage.setItem("defaultSet", "1");
        event.preventDefault();
        const officeID = JSON.parse(localStorage.getItem('officeID')) + "@@DEFAULT@@" + JSON.parse(localStorage.getItem('parentOfficeID'));

        props.updateForm(officeID);
    }
    function onBlurCheck(event) {
        //alert("A");
        event.preventDefault();
        const bottomTextInput = bottomTextInputRef.current.value;
        const contactUsPhoneInput = contactUsPhoneInputRef.current.value;
        const contactUsEmailInput = contactUsEmailInputRef.current.value;
        const bottomRightTextInput = bottomRightTextInputRef.current.value;
        const rightLinkName1Input = rightLinkName1InputRef.current.value;
        const rightLinkNameURL1Input = rightLinkNameURL1InputRef.current.value;
        const rightLinkName2Input = rightLinkName2InputRef.current.value;
        const rightLinkNameURL2Input = rightLinkNameURL2InputRef.current.value;
        const rightLinkName3Input = rightLinkName3InputRef.current.value;
        const rightLinkNameURL3Input = rightLinkNameURL3InputRef.current.value;
        const rightLinkName4Input = rightLinkName4InputRef.current.value;
        const rightLinkNameURL4Input = rightLinkNameURL4InputRef.current.value;
        const rightLinkName5Input = rightLinkName5InputRef.current.value;
        const rightLinkNameURL5Input = rightLinkNameURL5InputRef.current.value;
        const linkName2Input = linkName2InputRef.current.value;
        const linkNameURL2Input = linkNameURL2InputRef.current.value;
        const linkName3Input = linkName3InputRef.current.value;
        const linkNameURL3Input = linkNameURL3InputRef.current.value;
        const linkName4Input = linkName4InputRef.current.value;
        const linkNameURL4Input = linkNameURL4InputRef.current.value;
        const linkName5Input = linkName5InputRef.current.value;
        const linkNameURL5Input = linkNameURL5InputRef.current.value;
        const linkName6Input = linkName6InputRef.current.value;
        const linkNameURL6Input = linkNameURL6InputRef.current.value;
        const linkName7Input = linkName7InputRef.current.value;
        const linkNameURL7Input = linkNameURL7InputRef.current.value;
        const linkName8Input = linkName8InputRef.current.value;
        const linkNameURL8Input = linkNameURL8InputRef.current.value;
        const linkName9Input = linkName9InputRef.current.value;
        const linkNameURL9Input = linkNameURL9InputRef.current.value;
        const linkName10Input = linkName10InputRef.current.value;
        const linkNameURL10Input = linkNameURL10InputRef.current.value;
        const linkName1Input = linkName1InputRef.current.value;
        const linkNameURL1Input = linkNameURL1InputRef.current.value;
        if (companyLogoImageBinaryFile === '') {

            setBlankCompanyLogoError(true);

            return;
        } else {
            setBlankCompanyLogoError(false);
        }
        if (bannerColor === '') {

            setBlankBannerColorError(true);
            return;
        }
        else {
            setBlankBannerColorError(false);
        }
        if (buttonColor === '') {
            setBlankButtonColorError(true);
            return;
        } else {
            setBlankButtonColorError(false);
        }
        if (backGroundColor === '') {
            setBlankBackGroundColorError(true);
            return;
        } else {
            setBlankBackGroundColorError(false);
        }
        if (textBorderColor === '') {
            setBlankTextBorderColorError(true);
            return;
        } else {
            setBlankTextBorderColorError(false);
        }
        if (buttonGradientColor === '') {
            setBlankButtonGradientColorError(true);
            return;
        } else {
            setBlankButtonGradientColorError(false);
        }
        if (textBackGroundColor === '') {
            setBlankTextBackGroundColorError(true);
            return;
        } else {
            setBlankTextBackGroundColorError(false);
        }

        if (footerBackGroundColor === '') {
            setBlankFooterBackgroundColorError(true);
            return;
        } else {
            setBlankFooterBackgroundColorError(false);
        }


        if (contactUsPhoneInput.trim() === '') {
            setBlankContactUsPhoneError(true);
            setContactUsPhoneInputError(false);
            setPhoneLengthError(false);
            return;
        }
        else {
            setBlankContactUsPhoneError(false);
        }
        if (!contactUsPhoneInput.match('^[0-9+ ]*$')) {
            setContactUsPhoneInputError(true);
            return;
        } else {
            setContactUsPhoneInputError(false);
        }
        if (contactUsPhoneInput.length < 8) {
            setPhoneLengthError(true);
            setBlankContactUsPhoneError(false);
            setContactUsPhoneInputError(false);
            return;
        } else {
            setPhoneLengthError(false);
        }

        if (contactUsEmailInput.trim() === '') {
            setBlankBContactUsEmailError(true);
            setInvalidContactUEmailError(false);
            return;
        }
        else {
            setBlankBContactUsEmailError(false);
        }
        if (!emailValidation(contactUsEmailInput)) {
            setInvalidContactUEmailError(true);
            setBlankBContactUsEmailError(false);
            return;
        } else {
            setInvalidContactUEmailError(false);
        }
        //Link1
        if (linkName1Input.trim().length !== 0) {
            if (linkNameURL1Input.trim().length === 0) {
                //linkNameURL1InputRef.current.focus();
                setBlankLinkNameURL1Error(true);
                return;
            }
            else {
                setBlankLinkNameURL1Error(false);
            }

        }
        else {
            setBlankLinkNameURL1Error(false);
        }
        if (linkNameURL1Input.trim().length !== 0) {
            if (linkName1Input.trim().length === 0) {
                // linkName1InputRef.current.focus();
                setBlankLinkName1Error(true);
                return;
            }
            else {
                setBlankLinkName1Error(false);
            }

        } else {
            setBlankLinkName1Error(false);
        }

        //Link2
        if (linkName2Input.trim().length !== 0) {
            if (linkNameURL2Input.trim().length === 0) {
                setBlankLinkNameURL2Error(true);
                return;
            }
            else {
                setBlankLinkNameURL2Error(false);
            }

        } else {
            setBlankLinkNameURL2Error(false);
        }
        if (linkNameURL2Input.trim().length !== 0) {
            if (linkName2Input.trim().length === 0) {
                setBlankLinkName2Error(true);
                return;
            }
            else {
                setBlankLinkName2Error(false);
            }

        } else {
            setBlankLinkName2Error(false);
        }
        //link3
        if (linkName3Input.trim().length !== 0) {
            if (linkNameURL3Input.trim().length === 0) {
                setBlankLinkNameURL3Error(true);
                return;
            }
            else {
                setBlankLinkNameURL3Error(false);
            }

        } else {
            setBlankLinkNameURL3Error(false);
        }
        if (linkNameURL3Input.trim().length !== 0) {
            if (linkName3Input.trim().length === 0) {
                setBlankLinkName3Error(true);
                return;
            }
            else {
                setBlankLinkName3Error(false);
            }

        } else {
            setBlankLinkName3Error(false);
        }
        //link4
        if (linkName4Input.trim().length !== 0) {
            if (linkNameURL4Input.trim().length === 0) {
                setBlankLinkNameURL4Error(true);
                return;
            }
            else {
                setBlankLinkNameURL4Error(false);
            }

        } else {
            setBlankLinkNameURL4Error(false);
        }
        if (linkNameURL4Input.trim().length !== 0) {
            if (linkName4Input.trim().length === 0) {
                setBlankLinkName4Error(true);
                return;
            }
            else {
                setBlankLinkName4Error(false);
            }

        } else {
            setBlankLinkName4Error(false);
        }
        //link5
        if (linkName5Input.trim().length !== 0) {
            if (linkNameURL5Input.trim().length === 0) {
                setBlankLinkNameURL5Error(true);
                return;
            }
            else {
                setBlankLinkNameURL5Error(false);
            }

        } else {
            setBlankLinkNameURL5Error(false);
        }
        if (linkNameURL5Input.trim().length !== 0) {
            if (linkName5Input.trim().length === 0) {
                setBlankLinkName5Error(true);
                return;
            }
            else {
                setBlankLinkName5Error(false);
            }

        } else {
            setBlankLinkName5Error(false);
        }
        //link6
        if (linkName6Input.trim().length !== 0) {
            if (linkNameURL6Input.trim().length === 0) {
                setBlankLinkNameURL6Error(true);
                return;
            }
            else {
                setBlankLinkNameURL6Error(false);
            }

        } else {
            setBlankLinkNameURL6Error(false);
        }
        if (linkNameURL6Input.trim().length !== 0) {
            if (linkName6Input.trim().length === 0) {
                setBlankLinkName6Error(true);
                return;
            }
            else {
                setBlankLinkName6Error(false);
            }

        } else {
            setBlankLinkName6Error(false);
        }
        //link7
        if (linkName7Input.trim().length !== 0) {
            if (linkNameURL7Input.trim().length === 0) {
                setBlankLinkNameURL7Error(true);
                return;
            }
            else {
                setBlankLinkNameURL7Error(false);
            }

        } else {
            setBlankLinkNameURL7Error(false);
        }
        if (linkNameURL7Input.trim().length !== 0) {
            if (linkName7Input.trim().length === 0) {
                setBlankLinkName7Error(true);
                return;
            }
            else {
                setBlankLinkName7Error(false);
            }

        } else {
            setBlankLinkName7Error(false);
        }
        //link8
        if (linkName8Input.trim().length !== 0) {
            if (linkNameURL8Input.trim().length === 0) {
                setBlankLinkNameURL8Error(true);
                return;
            }
            else {
                setBlankLinkNameURL8Error(false);
            }

        } else {
            setBlankLinkNameURL8Error(false);
        }
        if (linkNameURL8Input.trim().length !== 0) {
            if (linkName8Input.trim().length === 0) {
                setBlankLinkName8Error(true);
                return;
            }
            else {
                setBlankLinkName8Error(false);
            }

        } else {
            setBlankLinkName8Error(false);
        }
        //link9
        if (linkName9Input.trim().length !== 0) {
            if (linkNameURL9Input.trim().length === 0) {
                setBlankLinkNameURL9Error(true);
                return;
            }
            else {
                setBlankLinkNameURL9Error(false);
            }

        } else {
            setBlankLinkNameURL9Error(false);
        }
        if (linkNameURL9Input.trim().length !== 0) {
            if (linkName9Input.trim().length === 0) {
                setBlankLinkName9Error(true);
                return;
            }
            else {
                setBlankLinkName9Error(false);
            }

        } else {
            setBlankLinkName9Error(false);
        }
        //link10
        if (linkName10Input.trim().length !== 0) {
            if (linkNameURL10Input.trim().length === 0) {
                setBlankLinkNameURL10Error(true);
                return;
            }
            else {
                setBlankLinkNameURL10Error(false);
            }

        } else {
            setBlankLinkNameURL10Error(false);
        }
        if (linkNameURL10Input.trim().length !== 0) {
            if (linkName10Input.trim().length === 0) {
                setBlankLinkName10Error(true);
                return;
            }
            else {
                setBlankLinkName10Error(false);
            }

        } else {
            setBlankLinkName10Error(false);
        }

        //socialMediaLink1
        if (rightLinkName1Input.trim().length !== 0) {
            if (rightLinkNameURL1Input.trim() === '') {
                setBlankSocialMediaLinkUrlError1(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError1(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError1(false);
        }
        if (rightLinkNameURL1Input.trim().length !== 0) {
            if (rightLinkName1Input.trim() === '') {
                setBlankSocialMediaLinkNameError1(true);
                return;
            } else {
                setBlankSocialMediaLinkNameError1(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError1(false);
        }

        //socialMediaLink2
        if (rightLinkName2Input.trim().length !== 0) {
            if (rightLinkNameURL2Input.trim() === '') {
                setBlankSocialMediaLinkUrlError2(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError2(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError2(false);
        }
        if (rightLinkNameURL2Input.trim().length !== 0) {
            if (rightLinkName2Input.trim() === '') {
                setBlankSocialMediaLinkNameError2(true);
                return;
            } else {
                setBlankSocialMediaLinkNameError2(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError2(false);
        }
        //SocialMediaLink3
        if (rightLinkName3Input.trim().length !== 0) {
            if (rightLinkNameURL3Input.trim() === '') {
                setBlankSocialMediaLinkUrlError3(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError3(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError3(false);
        }
        if (rightLinkNameURL3Input.trim().length !== 0) {
            if (rightLinkName3Input.trim() === '') {
                setBlankSocialMediaLinkNameError3(true);
                return;
            } else {
                setBlankSocialMediaLinkNameError3(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError3(false);
        }
        //SocialMediaLink4
        if (rightLinkName4Input.trim().length !== 0) {
            if (rightLinkNameURL4Input.trim() === '') {
                setBlankSocialMediaLinkUrlError4(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError4(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError4(false);
        }
        if (rightLinkNameURL4Input.trim().length !== 0) {
            if (rightLinkName4Input.trim() === '') {
                setBlankSocialMediaLinkNameError4(true);
                return;
            } else {
                setBlankSocialMediaLinkNameError4(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError4(false);
        }
        //SocialMediaLink5
        if (rightLinkName5Input.trim().length !== 0) {
            if (rightLinkNameURL5Input.trim() === '') {
                setBlankSocialMediaLinkUrlError5(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError5(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError5(false);
        }
        if (rightLinkNameURL5Input.trim().length !== 0) {
            if (rightLinkName5Input.trim() === '') {
                setBlankSocialMediaLinkNameError5(true);
                return;
            } else {
                setBlankSocialMediaLinkNameError5(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError5(false);
        }


    }
    function submitFormHandler(event) {
        event.preventDefault();
        const bottomTextInput = bottomTextInputRef.current.value;
        const bottomRightTextInput = bottomRightTextInputRef.current.value;
        const contactUsPhoneInput = contactUsPhoneInputRef.current.value;
        const contactUsEmailInput = contactUsEmailInputRef.current.value;
        const linkName1Input = linkName1InputRef.current.value;
        const linkNameURL1Input = linkNameURL1InputRef.current.value;
        const linkName2Input = linkName2InputRef.current.value;
        const linkNameURL2Input = linkNameURL2InputRef.current.value;
        const linkName3Input = linkName3InputRef.current.value;
        const linkNameURL3Input = linkNameURL3InputRef.current.value;
        const linkName4Input = linkName4InputRef.current.value;
        const linkNameURL4Input = linkNameURL4InputRef.current.value;
        const linkName5Input = linkName5InputRef.current.value;
        const linkNameURL5Input = linkNameURL5InputRef.current.value;
        const linkName6Input = linkName6InputRef.current.value;
        const linkNameURL6Input = linkNameURL6InputRef.current.value;
        const linkName7Input = linkName7InputRef.current.value;
        const linkNameURL7Input = linkNameURL7InputRef.current.value;
        const linkName8Input = linkName8InputRef.current.value;
        const linkNameURL8Input = linkNameURL8InputRef.current.value;
        const linkName9Input = linkName9InputRef.current.value;
        const linkNameURL9Input = linkNameURL9InputRef.current.value;
        const linkName10Input = linkName10InputRef.current.value;
        const linkNameURL10Input = linkNameURL10InputRef.current.value;

        const rightLinkName1Input = rightLinkName1InputRef.current.value;
        const rightLinkNameURL1Input = rightLinkNameURL1InputRef.current.value;
        const rightLinkName2Input = rightLinkName2InputRef.current.value;
        const rightLinkNameURL2Input = rightLinkNameURL2InputRef.current.value;
        const rightLinkName3Input = rightLinkName3InputRef.current.value;
        const rightLinkNameURL3Input = rightLinkNameURL3InputRef.current.value;
        const rightLinkName4Input = rightLinkName4InputRef.current.value;
        const rightLinkNameURL4Input = rightLinkNameURL4InputRef.current.value;
        const rightLinkName5Input = rightLinkName5InputRef.current.value;
        const rightLinkNameURL5Input = rightLinkNameURL5InputRef.current.value;

        if (companyLogoImageBinaryFile === '') {
            setBlankCompanyLogoError(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        } else {
            setBlankCompanyLogoError(false);
        }
        if (bannerColor === '') {
            bannerColorInputRef.current.focus();
            setBlankBannerColorError(true);
            return;
        }
        else {
            setBlankBannerColorError(false);
        }
        if (buttonColor === '') {
            buttonColorInputRef.current.focus();
            setBlankButtonColorError(true);
            return;
        } else {
            setBlankButtonColorError(false);
        }
        if (backGroundColor === '') {
            backGroundColorInputRef.current.focus();
            setBlankBackGroundColorError(true);
            return;
        } else {
            setBlankBackGroundColorError(false);
        }
        if (textBorderColor === '') {
            textBorderColorInputRef.current.focus();
            setBlankTextBorderColorError(true);
            return;
        } else {
            setBlankTextBorderColorError(false);
        }
        if (buttonGradientColor === '') {
            buttonGradientColorInputRef.current.focus();
            setBlankButtonGradientColorError(true);
            return;
        } else {
            setBlankButtonGradientColorError(false);
        }
        if (textBackGroundColor === '') {
            textBackGroundColorInputRef.current.focus();
            setBlankTextBackGroundColorError(true);
            return;
        } else {
            setBlankTextBackGroundColorError(false);
        }
        if (footerBackGroundColor === '') {
            footerBackGroundColorInputRef.current.focus();
            setBlankFooterBackgroundColorError(true);
            return;
        } else {
            setBlankFooterBackgroundColorError(false);
        }

        if (contactUsPhoneInput.trim() === '') {
            contactUsPhoneInputRef.current.focus();
            setBlankContactUsPhoneError(true);
            return;
        }
        else {
            setBlankContactUsPhoneError(false);
        }
        if (!contactUsPhoneInput.match('^[0-9+ ]*$')) {
            contactUsPhoneInputRef.current.focus();
            setContactUsPhoneInputError(true);
            return;
        } else {
            setContactUsPhoneInputError(false);
        }
        if (contactUsPhoneInput.length < 8) {

            contactUsPhoneInputRef.current.focus();
            setPhoneLengthError(true);
            setBlankContactUsPhoneError(false);
            setContactUsPhoneInputError(false);
            return;
        } else {
            setPhoneLengthError(false);
        }



        if (contactUsEmailInput.trim() === '') {
            contactUsEmailInputRef.current.focus();
            setBlankBContactUsEmailError(true);
            return;
        }
        else {
            setBlankBContactUsEmailError(false);
        }
        if (!emailValidation(contactUsEmailInput)) {
            contactUsEmailInputRef.current.focus();
            setInvalidContactUEmailError(true);
            setBlankBContactUsEmailError(false);
            return;
        } else {
            setInvalidContactUEmailError(false);
        }

        //Link1
        if (linkName1Input.trim().length !== 0) {
            if (linkNameURL1Input.trim().length === 0) {
                linkNameURL1InputRef.current.focus();
                setBlankLinkNameURL1Error(true);
                return;
            }
            else {
                setBlankLinkNameURL1Error(false);
            }

        } else {
            setBlankLinkNameURL1Error(false);
        }
        if (linkNameURL1Input.trim().length !== 0) {
            if (linkName1Input.trim().length === 0) {
                linkName1InputRef.current.focus();
                setBlankLinkName1Error(true);
                return;
            }
            else {
                setBlankLinkName1Error(false);
            }

        } else {
            setBlankLinkName1Error(false);
        }
        //Link2
        if (linkName2Input.trim().length !== 0) {
            if (linkNameURL2Input.trim().length === 0) {
                linkNameURL2InputRef.current.focus();
                setBlankLinkNameURL2Error(true);
                return;
            }
            else {
                setBlankLinkNameURL2Error(false);
            }

        } else {
            setBlankLinkNameURL2Error(false);
        }
        if (linkNameURL2Input.trim().length !== 0) {
            if (linkName2Input.trim().length === 0) {
                linkName2InputRef.current.focus();
                setBlankLinkName2Error(true);
                return;
            }
            else {
                setBlankLinkName2Error(false);
            }

        } else {
            setBlankLinkName2Error(false);
        }
        //Link3
        if (linkName3Input.trim().length !== 0) {
            if (linkNameURL3Input.trim().length === 0) {
                linkNameURL3InputRef.current.focus();
                setBlankLinkNameURL3Error(true);
                return;
            }
            else {
                setBlankLinkNameURL3Error(false);
            }

        } else {
            setBlankLinkNameURL3Error(false);
        }
        if (linkNameURL3Input.trim().length !== 0) {
            if (linkName3Input.trim().length === 0) {
                linkName3InputRef.current.focus();
                setBlankLinkName3Error(true);
                return;
            }
            else {
                setBlankLinkName3Error(false);
            }

        } else {
            setBlankLinkName3Error(false);
        }
        //link4
        if (linkName4Input.trim().length !== 0) {
            if (linkNameURL4Input.trim().length === 0) {
                linkNameURL4InputRef.current.focus();
                setBlankLinkNameURL4Error(true);
                return;
            }
            else {
                setBlankLinkNameURL4Error(false);
            }

        } else {
            setBlankLinkNameURL4Error(false);
        }
        if (linkNameURL4Input.trim().length !== 0) {
            if (linkName4Input.trim().length === 0) {
                linkName4InputRef.current.focus();
                setBlankLinkName4Error(true);
                return;
            }
            else {
                setBlankLinkName4Error(false);
            }

        } else {
            setBlankLinkName4Error(false);
        }
        //link5
        if (linkName5Input.trim().length !== 0) {
            if (linkNameURL5Input.trim().length === 0) {
                linkNameURL5InputRef.current.focus();
                setBlankLinkNameURL5Error(true);
                return;
            }
            else {
                setBlankLinkNameURL5Error(false);
            }

        } else {
            setBlankLinkNameURL5Error(false);
        }
        if (linkNameURL5Input.trim().length !== 0) {
            if (linkName5Input.trim().length === 0) {
                linkName5InputRef.current.focus();
                setBlankLinkName5Error(true);
                return;
            }
            else {
                setBlankLinkName5Error(false);
            }

        } else {
            setBlankLinkName5Error(false);
        }
        //link6
        if (linkName6Input.trim().length !== 0) {
            if (linkNameURL6Input.trim().length === 0) {
                linkNameURL6InputRef.current.focus();
                setBlankLinkNameURL6Error(true);
                return;
            }
            else {
                setBlankLinkNameURL6Error(false);
            }

        } else {
            setBlankLinkNameURL6Error(false);
        }
        if (linkNameURL6Input.trim().length !== 0) {
            if (linkName6Input.trim().length === 0) {
                linkName6InputRef.current.focus();
                setBlankLinkName6Error(true);
                return;
            }
            else {
                setBlankLinkName6Error(false);
            }

        } else {
            setBlankLinkName6Error(false);
        }
        //link7
        if (linkName7Input.trim().length !== 0) {
            if (linkNameURL7Input.trim().length === 0) {
                linkNameURL7InputRef.current.focus();
                setBlankLinkNameURL7Error(true);
                return;
            }
            else {
                setBlankLinkNameURL7Error(false);
            }

        } else {
            setBlankLinkNameURL7Error(false);
        }
        if (linkNameURL7Input.trim().length !== 0) {
            if (linkName7Input.trim().length === 0) {
                linkName7InputRef.current.focus();
                setBlankLinkName7Error(true);
                return;
            }
            else {
                setBlankLinkName7Error(false);
            }

        } else {
            setBlankLinkName7Error(false);
        }
        //link8
        if (linkName8Input.trim().length !== 0) {
            if (linkNameURL8Input.trim().length === 0) {
                linkNameURL8InputRef.current.focus();
                setBlankLinkNameURL8Error(true);
                return;
            }
            else {
                setBlankLinkNameURL8Error(false);
            }

        } else {
            setBlankLinkNameURL8Error(false);
        }
        if (linkNameURL8Input.trim().length !== 0) {
            if (linkName8Input.trim().length === 0) {
                linkName8InputRef.current.focus();
                setBlankLinkName8Error(true);
                return;
            }
            else {
                setBlankLinkName8Error(false);
            }

        } else {
            setBlankLinkName8Error(false);
        }
        //link9
        if (linkName9Input.trim().length !== 0) {
            if (linkNameURL9Input.trim().length === 0) {
                linkNameURL9InputRef.current.focus();
                setBlankLinkNameURL9Error(true);
                return;
            }
            else {
                setBlankLinkNameURL9Error(false);
            }

        } else {
            setBlankLinkNameURL9Error(false);
        }
        if (linkNameURL9Input.trim().length !== 0) {
            if (linkName9Input.trim().length === 0) {
                linkName9InputRef.current.focus();
                setBlankLinkName9Error(true);
                return;
            }
            else {
                setBlankLinkName9Error(false);
            }

        } else {
            setBlankLinkName9Error(false);
        }
        //link10
        if (linkName10Input.trim().length !== 0) {
            if (linkNameURL10Input.trim().length === 0) {
                linkNameURL10InputRef.current.focus();
                setBlankLinkNameURL10Error(true);
                return;
            }
            else {
                setBlankLinkNameURL10Error(false);
            }

        } else {
            setBlankLinkNameURL10Error(false);
        }
        if (linkNameURL10Input.trim().length !== 0) {
            if (linkName10Input.trim().length === 0) {
                linkName10InputRef.current.focus();
                setBlankLinkName10Error(true);
                return;
            }
            else {
                setBlankLinkName10Error(false);
            }

        } else {
            setBlankLinkName10Error(false);
        }

        //socialMediaLink1
        if (rightLinkName1Input.trim().length !== 0) {
            if (rightLinkNameURL1Input.trim() === '') {
                rightLinkNameURL1InputRef.current.focus();
                setBlankSocialMediaLinkUrlError1(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError1(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError1(false);
        }
        if (rightLinkNameURL1Input.trim().length !== 0) {
            if (rightLinkName1Input.trim() === '') {
                setBlankSocialMediaLinkNameError1(true);
                rightLinkName1InputRef.current.focus();
                return;
            } else {
                setBlankSocialMediaLinkNameError1(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError1(false);
        }
        //socialMediaLink2
        if (rightLinkName2Input.trim().length !== 0) {
            if (rightLinkNameURL2Input.trim() === '') {
                rightLinkNameURL2InputRef.current.focus();
                setBlankSocialMediaLinkUrlError2(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError2(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError2(false);
        }
        if (rightLinkNameURL2Input.trim().length !== 0) {
            if (rightLinkName2Input.trim() === '') {
                setBlankSocialMediaLinkNameError2(true);
                rightLinkName2InputRef.current.focus();
                return;
            } else {
                setBlankSocialMediaLinkNameError2(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError2(false);
        }
        //SocialMediaLink3
        if (rightLinkName3Input.trim().length !== 0) {
            if (rightLinkNameURL3Input.trim() === '') {
                rightLinkNameURL3InputRef.current.focus();
                setBlankSocialMediaLinkUrlError3(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError3(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError3(false);
        }
        if (rightLinkNameURL3Input.trim().length !== 0) {
            if (rightLinkName3Input.trim() === '') {
                setBlankSocialMediaLinkNameError3(true);
                rightLinkName3InputRef.current.focus();
                return;
            } else {
                setBlankSocialMediaLinkNameError3(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError3(false);
        }
        //SocialMediaLink4
        if (rightLinkName4Input.trim().length !== 0) {
            if (rightLinkNameURL4Input.trim() === '') {
                rightLinkNameURL4InputRef.current.focus();
                setBlankSocialMediaLinkUrlError4(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError4(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError4(false);
        }
        if (rightLinkNameURL4Input.trim().length !== 0) {
            if (rightLinkName4Input.trim() === '') {
                setBlankSocialMediaLinkNameError4(true);
                rightLinkName4InputRef.current.focus();
                return;
            } else {
                setBlankSocialMediaLinkNameError4(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError4(false);
        }
        //SocialMediaLink5
        if (rightLinkName5Input.trim().length !== 0) {
            if (rightLinkNameURL5Input.trim() === '') {
                rightLinkNameURL5InputRef.current.focus();
                setBlankSocialMediaLinkUrlError5(true);
                return;
            }
            else {
                setBlankSocialMediaLinkUrlError5(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError5(false);
        }
        if (rightLinkNameURL5Input.trim().length !== 0) {
            if (rightLinkName5Input.trim() === '') {
                setBlankSocialMediaLinkNameError5(true);
                rightLinkName5InputRef.current.focus();
                return;
            } else {
                setBlankSocialMediaLinkNameError5(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError5(false);
        }


        let ID;
        if (localStorage.getItem('CUSTOMIZE_ID') !== undefined && localStorage.getItem('CUSTOMIZE_ID') !== null && localStorage.getItem('CUSTOMIZE_ID') !== '') {

            ID = localStorage.getItem('CUSTOMIZE_ID');
        }
        else {
            ID = '';
        }

        const addData = ({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            companyLogo: companyLogoImageBinaryFile.base64URL,
            headerBannerColor: bannerColor,
            buttonColor: buttonColor,
            borderColor: textBorderColor,
            buttonGradientColor2: buttonGradientColor,
            textBackgroundColor: textBackGroundColor,
            backgroundColor: backGroundColor,
            footerBackgroundColor: footerBackGroundColor,
            bottomLeftText: bottomTextInput,
            bottomRightText: bottomRightTextInput,

            //links value
            contactUsPhone: contactUsPhoneInput,
            contactUsEmail: contactUsEmailInput,
            linkName1: linkName1Input,
            linkNameURL1: linkNameURL1Input,
            linkName2: linkName2Input,
            linkNameURL2: linkNameURL2Input,
            linkName3: linkName3Input,
            linkNameURL3: linkNameURL3Input,
            linkName4: linkName4Input,
            linkNameURL4: linkNameURL4Input,
            linkName5: linkName5Input,
            linkNameURL5: linkNameURL5Input,
            linkName6: linkName6Input,
            linkNameURL6: linkNameURL6Input,
            linkName7: linkName7Input,
            linkNameURL7: linkNameURL7Input,
            linkName8: linkName8Input,
            linkNameURL8: linkNameURL8Input,
            linkName9: linkName9Input,
            linkNameURL9: linkNameURL9Input,
            linkName10: linkName10Input,
            linkNameURL10: linkNameURL10Input,

            rightlinkName1: rightLinkName1Input,
            rightLinkNameURL1: rightLinkNameURL1Input,
            rightlinkName2: rightLinkName2Input,
            rightLinkNameURL2: rightLinkNameURL2Input,
            rightlinkName3: rightLinkName3Input,
            rightLinkNameURL3: rightLinkNameURL3Input,
            rightlinkName4: rightLinkName4Input,
            rightLinkNameURL4: rightLinkNameURL4Input,
            rightlinkName5: rightLinkName5Input,
            rightLinkNameURL5: rightLinkNameURL5Input,

            partnerSlider: activePartner,
            certificate: activeGDPR,
            isDefault: true
        });
        console.log(addData);
        props.onAddCustomize(
            addData
        );

    }


    useEffect(() => {
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });


        if (isFirtTime && props.loadedCustomizeDetails.length !== 0) {
            if (props.loadedCustomizeDetails.bottomLeftText !== '') {
                bottomTextInputRef.current.value = props.loadedCustomizeDetails.bottomLeftText;
            }
            if (props.loadedCustomizeDetails.bottomRightText !== '') {
                bottomRightTextInputRef.current.value = props.loadedCustomizeDetails.bottomRightText;
            }
            if (props.loadedCustomizeDetails.headerBannerColor !== null) {
                setBannerColor(props.loadedCustomizeDetails.headerBannerColor);
            }

            setCompanyLogoImageBinaryFile({
                base64URL: props.loadedCustomizeDetails.companyLogo
            });
            if (props.loadedCustomizeDetails.buttonColor !== null) {
                setButtonColor(props.loadedCustomizeDetails.buttonColor);
            }
            if (props.loadedCustomizeDetails.borderColor !== null) {
                setTextBorderColor(props.loadedCustomizeDetails.borderColor);
            }
            if (backGroundColor === '' && props.loadedCustomizeDetails.backgroundColor !== null) {
                setBackGroundColor(props.loadedCustomizeDetails.backgroundColor);
            }
            if (props.loadedCustomizeDetails.buttonGradientColor2 !== null) {
                setButtonGradientColor(props.loadedCustomizeDetails.buttonGradientColor2);
            }
            if (backGroundColor === '' && props.loadedCustomizeDetails.footerBackgroundColor !== null) {
                setFooterBackGroundColor(props.loadedCustomizeDetails.footerBackgroundColor);
            }

            if (textBackGroundColor === '' && props.loadedCustomizeDetails.textBackgroundColor !== null) {
                setTextBackGroundColor(props.loadedCustomizeDetails.textBackgroundColor);
            }

            //links value
            contactUsPhoneInputRef.current.value = props.loadedCustomizeDetails.contactUsPhone;
            contactUsEmailInputRef.current.value = props.loadedCustomizeDetails.contactUsEmail;
            linkName1InputRef.current.value = props.loadedCustomizeDetails.linkName1;
            linkNameURL1InputRef.current.value = props.loadedCustomizeDetails.linkNameURL1;
            linkName2InputRef.current.value = props.loadedCustomizeDetails.linkName2;
            linkNameURL2InputRef.current.value = props.loadedCustomizeDetails.linkNameURL2;
            linkName3InputRef.current.value = props.loadedCustomizeDetails.linkName3;
            linkNameURL3InputRef.current.value = props.loadedCustomizeDetails.linkNameURL3;
            linkName4InputRef.current.value = props.loadedCustomizeDetails.linkName4;
            linkNameURL4InputRef.current.value = props.loadedCustomizeDetails.linkNameURL4;
            linkName5InputRef.current.value = props.loadedCustomizeDetails.linkName5;
            linkNameURL5InputRef.current.value = props.loadedCustomizeDetails.linkNameURL5;
            linkName6InputRef.current.value = props.loadedCustomizeDetails.linkName6;
            linkNameURL6InputRef.current.value = props.loadedCustomizeDetails.linkNameURL6;
            linkName7InputRef.current.value = props.loadedCustomizeDetails.linkName7;
            linkNameURL7InputRef.current.value = props.loadedCustomizeDetails.linkNameURL7;
            linkName8InputRef.current.value = props.loadedCustomizeDetails.linkName8;
            linkNameURL8InputRef.current.value = props.loadedCustomizeDetails.linkNameURL8;
            linkName9InputRef.current.value = props.loadedCustomizeDetails.linkName9;
            linkNameURL9InputRef.current.value = props.loadedCustomizeDetails.linkNameURL9;
            linkName10InputRef.current.value = props.loadedCustomizeDetails.linkName10;
            linkNameURL10InputRef.current.value = props.loadedCustomizeDetails.linkNameURL10;
            rightLinkName1InputRef.current.value = props.loadedCustomizeDetails.rightlinkName1;
            rightLinkNameURL1InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL1;
            rightLinkName2InputRef.current.value = props.loadedCustomizeDetails.rightlinkName2;
            rightLinkNameURL2InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL2;
            rightLinkName3InputRef.current.value = props.loadedCustomizeDetails.rightlinkName3;
            rightLinkNameURL3InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL3;
            rightLinkName4InputRef.current.value = props.loadedCustomizeDetails.rightlinkName4;
            rightLinkNameURL4InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL4;
            rightLinkName5InputRef.current.value = props.loadedCustomizeDetails.rightlinkName5;
            rightLinkNameURL5InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL5;


            setActivePartner(props.loadedCustomizeDetails.partnerSlider);
            setActiveGDPR(props.loadedCustomizeDetails.certificate);

            setPageTitle(" Customize");
        }
        if (props.loadedCustomizeDetails.length === 0) {
            setPageTitle(" Customize");
        }
        setFirstTime(false)

    },
        [props.loadedCustomizeDetails.length,
        props.loadedCustomizeDetails.headerBannerImage,
        props.loadedCustomizeDetails.companyLogo,
        props.loadedCustomizeDetails.footer,
        props.loadedCustomizeDetails.buttonColor,
        props.loadedCustomizeDetails.textBackGroundColor,
        props.loadedCustomizeDetails.buttonGradientColor2,
        props.loadedCustomizeDetails.footerBackgroundColor,
        props.loadedCustomizeDetails.headerBannerColor,
        props.loadedCustomizeDetails.backgroundColor,
        props.loadedCustomizeDetails.bottomLeftText,
        props.loadedCustomizeDetails.bottomRightText,
        props.loadedCustomizeDetails.contactUsPhone,
        props.loadedCustomizeDetails.contactUsEmail,
        props.loadedCustomizeDetails.linkName1,
        props.loadedCustomizeDetails.linkNameURL1,
        props.loadedCustomizeDetails.linkName2,
        props.loadedCustomizeDetails.linkNameURL2,
        props.loadedCustomizeDetails.linkName3,
        props.loadedCustomizeDetails.linkNameURL3,
        props.loadedCustomizeDetails.linkName4,
        props.loadedCustomizeDetails.linkNameURL4,
        props.loadedCustomizeDetails.linkName5,
        props.loadedCustomizeDetails.linkNameURL5,
        props.loadedCustomizeDetails.linkName6,
        props.loadedCustomizeDetails.linkNameURL6,
        props.loadedCustomizeDetails.linkName7,
        props.loadedCustomizeDetails.linkNameURL7,
        props.loadedCustomizeDetails.linkName8,
        props.loadedCustomizeDetails.linkNameURL8,
        props.loadedCustomizeDetails.linkName9,
        props.loadedCustomizeDetails.linkNameURL9,
        props.loadedCustomizeDetails.linkName10,
        props.loadedCustomizeDetails.linkNameURL10,
        props.loadedCustomizeDetails.rightlinkName1,
        props.loadedCustomizeDetails.rightLinkNameURL1,
        props.loadedCustomizeDetails.rightlinkName2,
        props.loadedCustomizeDetails.rightLinkNameURL2,
        props.loadedCustomizeDetails.rightlinkName3,
        props.loadedCustomizeDetails.rightLinkNameURL3,
        props.loadedCustomizeDetails.rightlinkName4,
        props.loadedCustomizeDetails.rightLinkNameURL4,
        props.loadedCustomizeDetails.rightlinkName5,
        props.loadedCustomizeDetails.rightLinkNameURL5,
        props.loadedCustomizeDetails.partnerSlider,
        props.loadedCustomizeDetails.certificate,
            isFirtTime, textBackGroundColor,
            backGroundColor, buttonColor,

        ])

    const companyImage = props.loadedCustomizeDetails.companyLogo === undefined ? ('') :
        (<img className="border-blue-img mt-2 head-logo-bg" height="250" width="55" src={props.loadedCustomizeDetails.companyLogo} alt="">
        </img>)



    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>

                                                    <form className='form'>
                                                        <div className="row">
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="companyLogo" className="mb-2 h6 text-primary col-lg-12">{t('companyLogo')}<span className="error-icon"> *</span></label>
                                                                <br></br>
                                                                <span className="note">{t('logoImageFormat')}</span>
                                                                <input type="file"
                                                                    id="companyLogo"
                                                                    className="form-control form-control-sm"
                                                                    onChange={companyLogoHandler}
                                                                    autoFocus
                                                                // onBlur={onBlurCheck}
                                                                >
                                                                </input>
                                                                {companyLogoImage.map((elem) => {
                                                                    return < span key={generateUUID(10)}>
                                                                        <div className="mt-2">
                                                                            <img className="border-blue-img head-logo-bg" src={elem} ref={companyLogoInputRef} alt="" />

                                                                        </div>
                                                                    </span>
                                                                })}
                                                                {!isCompanyLogoUploading && companyImage}
                                                                {blankCompanyLogoError && <p className="error-text">{t('blankCompanyLogoValidation')}</p>}
                                                                {!blankCompanyLogoError && companyLogoInvalidImage && <p className="error-text">{t('invalidCompanyLogo')}</p>}
                                                            </div>
                                                            <div className='control col-md-6'>
                                                                <label htmlFor="bannerImage" className="mb-2 h6 text-primary col-lg-12">{t('bannerImage')}<span className="error-icon"> *</span></label>
                                                                <br></br>
                                                                <span className="note">{t('bannerImageFormat')}</span>
                                                                <div className="position-relative">
                                                                    <input value={bannerColor} readOnly className="form-control form-control-sm" onChange={bannerColorChangeHandler} onBlur={onBlurCheck} ref={bannerColorInputRef} ></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleBannerClick}>{t('pickColor')}</button>
                                                                </div>
                                                                {displayBannerColorPicker ? <div style={popover}>
                                                                    <div style={cover} onClick={handleBannerClose} />
                                                                    <SketchPicker color={bannerColor}
                                                                        onChange={bannerColorChangeHandler} />
                                                                </div> : null}
                                                                {blankBannerColorError && <p className="error-text">{t('blankBannerImageValidation')}</p>}




                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-6">
                                                                <label htmlFor="buttonColor" className="mb-2 h6 text-primary col-lg-12">{t('buttonColor')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={buttonColor} readOnly className="form-control form-control-sm" onChange={buttonColorChangeHandler} onBlur={onBlurCheck} ref={buttonColorInputRef} ></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleClose} />

                                                                    <SketchPicker color={buttonColor}

                                                                        onChange={buttonColorChangeHandler} />

                                                                </div> : null}

                                                                {blankButtonColorError && <p className="error-text">{t('blankButtonColorValidation')}</p>}

                                                            </div>
                                                            <div className="control col-md-6">
                                                                <label htmlFor="backgroundColor" className="mb-2 h6 text-primary col-lg-12">{t('backgroundColor')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={backGroundColor} className="form-control form-control-sm" onChange={backGroundColorChangeHandler}
                                                                        onBlur={onBlurCheck} readOnly ref={backGroundColorInputRef}></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleBackgroundClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayBackgroundColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleBackgroundClose} />

                                                                    <SketchPicker

                                                                        color={backGroundColor}

                                                                        onChange={backGroundColorChangeHandler} />

                                                                </div> : null}

                                                                {blankBackGroundColorError && <p className="error-text">{t('blankBackGroundColorValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-6">
                                                                <label htmlFor="buttonColor2" className="mb-2 h6 text-primary col-lg-12">{t('buttonColorGradient')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={buttonGradientColor} readOnly className="form-control form-control-sm" onChange={buttonColorGradientChangeHandler} onBlur={onBlurCheck} ref={buttonGradientColorInputRef} ></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleButtonGradientClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayButtonGradientColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleButtonGradientClose} />

                                                                    <SketchPicker color={buttonGradientColor}

                                                                        onChange={buttonColorGradientChangeHandler} />

                                                                </div> : null}

                                                                {blankButtonGradientColorError && <p className="error-text">{t('blankButtonGradientColorValidation')}</p>}

                                                            </div>
                                                            <div className="control col-md-6">
                                                                <label htmlFor="footerBackGroundColor" className="mb-2 h6 text-primary col-lg-12">{t('footerBackGroundColor')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={footerBackGroundColor} className="form-control form-control-sm" onChange={footerBackGroundColorChangeHandler}
                                                                        onBlur={onBlurCheck} readOnly ref={footerBackGroundColorInputRef}></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleFooterBackgroundClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayFooterBackgroundColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleFooterBackgroundClose} />

                                                                    <SketchPicker

                                                                        color={footerBackGroundColor}

                                                                        onChange={footerBackGroundColorChangeHandler} />

                                                                </div> : null}

                                                                {blankFooterBackgroundColorError && <p className="error-text">{t('blankFooterBackGroundColorValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-6">
                                                                <label htmlFor="textBackGroundColor" className="mb-2 h6 text-primary col-lg-12">{t('textBackGroundColor')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={textBackGroundColor} readOnly className="form-control form-control-sm" onChange={textBackGroundColorChangeHandler} onBlur={onBlurCheck} ref={textBackGroundColorInputRef} ></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleTextBackgroundColorClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayTextBackgroundColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleTextBackgroundColorClose} />

                                                                    <SketchPicker color={textBackGroundColor}

                                                                        onChange={textBackGroundColorChangeHandler} />

                                                                </div> : null}

                                                                {blankTextBackGroundColorError && <p className="error-text">{t('blankTextBackGroundColorValidation')}</p>}

                                                            </div>
                                                            <div className="control col-md-6">
                                                                <label htmlFor="textBorderColor" className="mb-2 h6 text-primary col-lg-12">{t('borderColor')}<span className="error-icon"> *</span></label>
                                                                <div className="position-relative">
                                                                    <input value={textBorderColor} readOnly className="form-control form-control-sm" onChange={textBorderColorChangeHandler} onBlur={onBlurCheck} ref={textBorderColorInputRef} ></input>
                                                                    <button className="color-picker-btn btn gt-main-btn text-white" onClick={handleTextBorderColorClick}>{t('pickColor')}</button>
                                                                </div>

                                                                {displayTextBorderColorPicker ? <div style={popover}>

                                                                    <div style={cover} onClick={handleTextBorderColorClose} />

                                                                    <SketchPicker color={textBorderColor}

                                                                        onChange={textBorderColorChangeHandler} />

                                                                </div> : null}

                                                                {blankTextBorderColorError && <p className="error-text">{t('blankTextBorderColorValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-6">
                                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">{t('bottomLeftText')}</label>
                                                                <input
                                                                    type="text"
                                                                    maxLength={100}
                                                                    ref={bottomTextInputRef}
                                                                    id="bottomText"

                                                                    className="form-control form-control-sm" />

                                                            </div>
                                                            <div className="control col-md-6">
                                                                <label htmlFor="bottomRightText" className="mb-2 h6 text-primary col-lg-12">{t('bottomRightText')}</label>
                                                                <input
                                                                    type="text"
                                                                    maxLength={100}
                                                                    ref={bottomRightTextInputRef}
                                                                    id="bottomRightText"
                                                                    className="form-control form-control-sm" />


                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-6">
                                                                <label htmlFor="phone" className="mb-2 h6 text-primary col-lg-12">{t('contactUsPhone')}<span className="error-icon"> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    minLength={8}
                                                                    maxLength={16}
                                                                    ref={contactUsPhoneInputRef}
                                                                    id="phone"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm" />
                                                                {blankContactUsPhoneError && <p className="error-text">{t('blankPhoneError')}</p>}
                                                                {contactUsPhoneInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                                {phoneLengthError && <p className="error-text">{t('invalidPhoneLength')}</p>}


                                                            </div>
                                                            <div className="control col-md-6">
                                                                <label htmlFor="mail" className="mb-2 h6 text-primary col-lg-12"> {t('contactUsEmail')}<span className="error-icon">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={contactUsEmailInputRef}
                                                                    id="mail"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm" />
                                                                {blankBContactUsEmailError && <p className="error-text">{t('blankEmailError')}</p>}
                                                                {invalidContactUsEmailError && <p className="error-text">{t('invalidEmailId')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">
                                                                <label htmlFor="LinkName1" className="mb-2 h6 text-primary col-lg-12">{t('Links')}<span className="error-icon"> </span></label>
                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName1InputRef}
                                                                    id="LinkName1"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName1Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">
                                                                <label htmlFor="URL1" className="mb-2 h6 text-primary col-lg-12 d-none d-sm-block">&nbsp;</label>
                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL1InputRef}
                                                                    id="URL1"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL1Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName2InputRef}
                                                                    id="LinkName2"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />

                                                                {blankLinkName2Error && <p className="error-text">{t('blankLink')}</p>}
                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL2InputRef}
                                                                    id="URL2"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL2Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName3InputRef}
                                                                    id="LinkName3"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName3Error && <p className="error-text">{t('blankLink')}</p>}


                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL3InputRef}
                                                                    id="URL3"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL3Error && <p className="error-text">{t('blankLinkValidation')}</p>}


                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName4InputRef}
                                                                    id="LinkName4"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName4Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL4InputRef}
                                                                    id="URL4"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL4Error && <p className="error-text">{t('blankLinkValidation')}</p>}


                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName5InputRef}
                                                                    id="LinkName5"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName5Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL5InputRef}
                                                                    id="URL5"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL5Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName6InputRef}
                                                                    id="LinkName6"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName6Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL6InputRef}
                                                                    id="URL6"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL6Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName7InputRef}
                                                                    id="LinkName7"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName7Error && <p className="error-text">{t('blankLink')}</p>}


                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL7InputRef}
                                                                    id="URL7"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL7Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName8InputRef}
                                                                    id="LinkName8"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName8Error && <p className="error-text">{t('blankLink')}</p>}


                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL8InputRef}
                                                                    id="URL8"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />

                                                                {blankLinkNameURL8Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName9InputRef}
                                                                    id="LinkName9"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName9Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL9InputRef}
                                                                    id="URL9"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL9Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={linkName10InputRef}
                                                                    id="LinkName10"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankLinkName10Error && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={linkNameURL10InputRef}
                                                                    id="URL10"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankLinkNameURL10Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">
                                                                <label htmlFor="socialMediaLink1" className="mb-2 h6 text-primary col-lg-12">{t('socialMediaLinks')}<span className="error-icon"> </span></label>
                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={rightLinkName1InputRef}
                                                                    id="socialMediaLink1"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />

                                                                {blankSocialMediaLinkNameError1 && <p className="error-text">{t('blankLink')}</p>}
                                                            </div>
                                                            <div className="control col-md-9">
                                                                <label htmlFor="socialMediaURL1" className="mb-2 h6 text-primary col-lg-12 d-none d-sm-block">&nbsp;</label>
                                                                <input
                                                                    type="text"
                                                                    ref={rightLinkNameURL1InputRef}
                                                                    id="socialMediaURL1"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankSocialMediaLinkUrlError1 && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={rightLinkName2InputRef}
                                                                    id="socialMediaLink2"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankSocialMediaLinkNameError2 && <p className="error-text">{t('blankLink')}</p>}
                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={rightLinkNameURL2InputRef}
                                                                    id="SocialMediaURL2"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankSocialMediaLinkUrlError2 && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={rightLinkName3InputRef}
                                                                    id="socialMediaLink3"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankSocialMediaLinkNameError3 && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={rightLinkNameURL3InputRef}
                                                                    id="SocialMediaURL3"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankSocialMediaLinkUrlError3 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={rightLinkName4InputRef}
                                                                    id="socialMediaLink4"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankSocialMediaLinkNameError4 && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={rightLinkNameURL4InputRef}
                                                                    id="SocialMediaURL4"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankSocialMediaLinkUrlError4 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={20}
                                                                    ref={rightLinkName5InputRef}
                                                                    id="socialMediaLink5"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Link Name" />
                                                                {blankSocialMediaLinkNameError5 && <p className="error-text">{t('blankLink')}</p>}

                                                            </div>
                                                            <div className="control col-md-9">

                                                                <input
                                                                    type="text"
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    ref={rightLinkNameURL5InputRef}
                                                                    id="SocialMediaURL5"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="URL" />
                                                                {blankSocialMediaLinkUrlError5 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="control col-md-3">
                                                                <div className="form-switch form-switch-sm padding-left-0">
                                                                    <label htmlFor="activePartner" className="mb-2 h6 text-primary col-lg-12">Show Partner Slider<span className="error-icon"> </span></label>
                                                                    <input id="activePartner"
                                                                        className="form-check-input ms-1"
                                                                        type="checkbox"
                                                                        onChange={activePartnerChangeHandler}
                                                                        checked={activePartner}
                                                                        value={activePartner}
                                                                        ref={activePartnerInputRef}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="control col-md-9 d-none">
                                                                <div className="form-switch form-switch-sm padding-left-0">
                                                                    <label htmlFor="activeGDPR" className="mb-2 h6 text-primary col-lg-12">Show PCI DSS</label>
                                                                    <input id="activeGDPR"
                                                                        className="form-check-input ms-1"
                                                                        type="checkbox"
                                                                        onChange={activeGDPRChangeHandler}
                                                                        checked={activeGDPR}
                                                                        value={activeGDPR}
                                                                        ref={activeGDPRInputRef}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        <div className="row my-2">
                                                            <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold">Note : </span>  After saving the record, please login again to see the changes.</span>
                                                        </div>
                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto"></div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    {(checkSecurityDetails("Settings", "Customize", "Settings_Customize_Update")) && <div>
                                                                        <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>

                                                                        <button id='btnReset' onClick={setToDefault} className='btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1'>{t('setToDefault')}</button>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>


};
export default CustomizationForm;