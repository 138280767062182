import Dropdown from 'react-select';
import TravellerDetail from './SubComonents/TravellerDetail';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import moment from 'moment';

const TravellerDetails = (props) => {

    window.scrollTo({
        top: 400,
        behavior: "smooth",
    });

    function assignTravelDetails(event) {
        event.preventDefault();
        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        let setValidationStaus = true;
        bookingJSON.data.travelerInfo.traveler.forEach(element => {

            if (element.PersonName.NamePrefix === '') {
                if (setValidationStaus) {
                    document.getElementById("msgtitle" + element.TravelerRefNumber).innerHTML = "Title is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("title" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                document.getElementById("msgtitle" + element.TravelerRefNumber).innerHTML = '';
            }

            if (document.getElementById("fName" + element.TravelerRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = "First Name is required.";
                    setValidationStaus = false;
                    document.getElementById("fName" + element.TravelerRefNumber).focus();
                }
            } else {
                document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = '';
            }
            if (document.getElementById("fName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("fName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = "First Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("fName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("mName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("mName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgmName" + element.TravelerRefNumber).innerHTML = "Middle Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("mName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgmName" + element.TravelerRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("lName" + element.TravelerRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = "Last Name is required.";
                    setValidationStaus = false;
                    document.getElementById("lName" + element.TravelerRefNumber).focus();
                }
            } else {
                document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = '';
            }
            if (document.getElementById("lName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("lName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = "Last Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("lName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            let nameLength = parseInt(document.getElementById("fName" + element.TravelerRefNumber).value.length) + parseInt(document.getElementById("mName" + element.TravelerRefNumber).value.length) + parseInt(document.getElementById("lName" + element.TravelerRefNumber).value.length);
            if (setValidationStaus) {
                if (nameLength > 59) {
                    document.getElementById("totalNameLength" + element.TravelerRefNumber).innerHTML = 'First Name, Middle Name and Last Name cannot exceed 59 characters in total.';
                    setValidationStaus = false;
                }
                else {
                    document.getElementById("totalNameLength" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.Gender === '') {
                if (setValidationStaus) {
                    document.getElementById("genderMsg" + element.TravelerRefNumber).innerHTML = "Gender is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("gender" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                document.getElementById("genderMsg" + element.TravelerRefNumber).innerHTML = '';
            }

            if (element.dateOfBirth.date.substring(8, 6) === '00') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Date" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.dateOfBirth.date.substring(6, 4) === '00') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Month" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.dateOfBirth.date.substring(0, 4) === '0000') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Year" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            var travelingDate = sessionStorage.getItem("calFromDateDB1");
            var travelingYear = travelingDate.substring(0, 4);
            var travelingMonth = travelingDate.substring(4, 6);
            var travelingDay = travelingDate.substring(6, 8);
            var formattedTravelingDate = new Date(travelingYear, travelingMonth - 1, travelingDay);

            var dobDate = element.dateOfBirth.date;
            var dobYear = dobDate.substring(0, 4);
            var dobMonth = dobDate.substring(4, 6);
            var dobDay = dobDate.substring(6, 8);
            var formattedDobDate = new Date(dobYear, dobMonth - 1, dobDay);

            if (dobYear !== '0000' && dobMonth !== '00' && dobDay !== '00') {
                var dateDiffValue = dateDiff(formattedTravelingDate, formattedDobDate);

                if (element.PassengerTypeCode === "ADT") {
                    if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years >= 12 && (dateDiffValue.months >= 0 || dateDiffValue.days > 0)) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else {
                        if (setValidationStaus) {
                            if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                                document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is not valid.";
                            }
                            setValidationStaus = false;
                        }
                    }
                }
                else if (element.PassengerTypeCode === "CNN") {
                    if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years > 2 && dateDiffValue.years < 12) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years === 2 && (dateDiffValue.months >= 0 || dateDiffValue.days >= 0)) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years === 12 && dateDiffValue.months === 0 && dateDiffValue.days === 0) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else {
                        if (setValidationStaus) {
                            if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                                document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is not valid.";
                            }
                            setValidationStaus = false;
                        }
                    }
                }
                else if (element.PassengerTypeCode === "INF") {
                    if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years > 0 && dateDiffValue.years < 2) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years === 0 && (dateDiffValue.months > 0 || dateDiffValue.days >= 15)) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else {
                        if (setValidationStaus) {
                            if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                                document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is not valid.";
                            }
                            setValidationStaus = false;
                        }
                    }
                }
                //Changes to fix issue of DOB validation for the seat infant
                else if (element.PassengerTypeCode === "INS") {
                    if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years > 0 && dateDiffValue.years < 2) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years === 0 && (dateDiffValue.months > 0 || dateDiffValue.days >= 15)) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    else {
                        if (setValidationStaus) {
                            if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                                document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is not valid.";
                            }
                            setValidationStaus = false;
                        }
                    }
                }
            }

            let passportIsRequired = false;
            //check passport mandatory for international flight

            if (getConfigurationByBoolen('PASSPORT_MANDATORY')) {
                let base_country = getConfigurationByValue("BASE_COUNTRY_CODE")
                bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(element => {
                    if (element.flightSegment[0].departureAirport.countryCode.trim() !== base_country || element.flightSegment[0].arrivalAirport.countryCode !== base_country) {
                        passportIsRequired = true;
                        return;
                    }

                });

            }
            if (getConfigurationByValue("PASSPORT_REQUIRED") !== null && getConfigurationByValue("PASSPORT_REQUIRED") !== '') {
                let validatingCarrierArray = getConfigurationByValue("PASSPORT_REQUIRED").split(',');
                if (validatingCarrierArray.length > 0) {
                    bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                        elementAirItinerary.originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                if (validatingCarrierArray.indexOf(elementFlightSegment.marketingAirline.code) !== -1) {
                                    passportIsRequired = true;
                                }
                            });
                        });
                    });
                }
            }
            if (document.getElementById("passportNumber" + element.TravelerRefNumber) !== null && document.getElementById("passportNumber" + element.TravelerRefNumber).value !== '') {
                passportIsRequired = true;
            }
            // if (element.PassengerTypeCode !== "INF" && element.PassengerTypeCode !== "INS" && document.getElementById("Number" + element.TravelerRefNumber) !== null && document.getElementById("Number" + element.TravelerRefNumber).value !== '') {
            //     passportIsRequired = true;
            // }

            if (passportIsRequired) {
                if (element.Document[0].DocHolderNationality === '') {
                    if (setValidationStaus) {
                        document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "Nationality is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("nationality" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }

                if (document.getElementById("passportNumber" + element.TravelerRefNumber).value === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "Passport No. is required.";
                        setValidationStaus = false;
                        document.getElementById("passportNumber" + element.TravelerRefNumber).focus();
                    }
                    else {
                        document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }

                if (element.Document[0].ExpireDate === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
                else {
                    if (element.Document[0].ExpireDate.length === 8) {
                        if (element.Document[0].ExpireDate.substring(0, 4) === '0000' || element.Document[0].ExpireDate.substring(4, 6) === '00' || element.Document[0].ExpireDate.substring(6, 8) === '00') {
                            if (setValidationStaus) {
                                document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
                                setValidationStaus = false;
                                const yOffset = -150;
                                const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                            }
                            else {
                                document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                            }
                        }
                        else {
                            document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                }

                if (element.Document[0].DocIssueCountry === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("issueCountry" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
            }
            if (element.PassengerTypeCode !== "INF" && element.PassengerTypeCode !== "INS") {
                let identificationTypeIsRequired = false;
                let identificationNumberIsRequired = false;
                let issueCountryIsRequired = false;

                if (element.Document[1].DocType !== '') {
                    identificationNumberIsRequired = true;

                } else if (element.Document[1].DocIssueCountry !== '') {
                    issueCountryIsRequired = true;
                }
                else {
                    if (document.getElementById("Number" + element.TravelerRefNumber) !== null && document.getElementById("Number" + element.TravelerRefNumber).value !== '') {
                        identificationTypeIsRequired = true;

                    }

                }
                if (identificationNumberIsRequired) {

                    if (document.getElementById("Number" + element.TravelerRefNumber).value === '') {

                        if (setValidationStaus) {

                            if (element.Document[1].DocType === '') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Number is required.";
                            } else if (element.Document[1].DocType === 'RedressNumber') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is required.";
                            }
                            else {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is required.";
                            }

                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {

                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'RedressNumber') {

                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;


                        if (setValidationStaus && !redressNo.match('^[0-9]+$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 7) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }



                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'KnownTravelerNumber') {
                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;


                        if (setValidationStaus && !redressNo.match('^[a-zA-Z0-9 ]*$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 9) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }

                    }


                    if (element.Document[1].DocIssueCountry === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("IdentificationIssueCountry" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }

                }
                if (identificationTypeIsRequired) {
                    if (element.Document[1].DocType === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "Additional is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Identification" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (element.Document[1].DocIssueCountry === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("IdentificationIssueCountry" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }

                }
                if (issueCountryIsRequired) {
                    if (element.Document[1].DocType === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "Additional is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Identification" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value === '') {

                        if (setValidationStaus) {
                            if (element.Document[1].DocType === '') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Number is required.";
                            } else if (element.Document[1].DocType === 'RedressNumber') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is required.";
                            }
                            else {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is required.";
                            }
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {

                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'RedressNumber') {

                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;


                        if (setValidationStaus && !redressNo.match('^[0-9]+$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 7) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";
                            setValidationStaus = true;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }



                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'KnownTravelerNumber') {
                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;


                        if (setValidationStaus && !redressNo.match('^[a-zA-Z0-9 ]*$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 9) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }

                    }


                }
                if (document.getElementById("Number" + element.TravelerRefNumber) !== null) {
                    element.Document[1].DocID = document.getElementById("Number" + element.TravelerRefNumber).value;
                }


            }
            // let validatePassort=false;
            // if(element.Document[1].DocIssueCountry !== '')
            // {
            //     validatePassort=true;
            // }
            // if (validatePassort) {
            //     if (element.Document[0].DocHolderNationality === '') {
            //         if (setValidationStaus) {
            //             document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "Nationality is required.";
            //             setValidationStaus = false;
            //             const yOffset = -150;
            //             const element2 = document.getElementById("nationality" + element.TravelerRefNumber);
            //             const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //             window.scrollTo({ top: y, behavior: 'smooth' });
            //         }
            //         else {
            //             document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "";
            //         }
            //     }

            //     if (document.getElementById("passportNumber" + element.TravelerRefNumber).value === '') {
            //         if (setValidationStaus) {
            //             document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "Passport No. is required.";
            //             setValidationStaus = false;
            //             document.getElementById("passportNumber" + element.TravelerRefNumber).focus();
            //         }
            //         else {
            //             document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "";
            //         }
            //     }
            //     console.log(element.Document[0].ExpireDate);

            //     if (element.Document[0].ExpireDate === '') {

            //         if (setValidationStaus) {
            //             document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
            //             setValidationStaus = false;
            //             const yOffset = -150;
            //             const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
            //             const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //             window.scrollTo({ top: y, behavior: 'smooth' });
            //         }
            //         else {
            //             document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
            //         }
            //     }
            //     else {
            //         if (element.Document[0].ExpireDate.length === 8) {
            //             if (element.Document[0].ExpireDate.substring(0, 4) === '0000' || element.Document[0].ExpireDate.substring(4, 6) === '00' || element.Document[0].ExpireDate.substring(6, 8) === '00') {
            //                 if (setValidationStaus) {
            //                     document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
            //                     setValidationStaus = false;
            //                     const yOffset = -150;
            //                     const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
            //                     const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //                     window.scrollTo({ top: y, behavior: 'smooth' });
            //                 }
            //                 else {
            //                     document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
            //                 }
            //             }
            //             else {
            //                 document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
            //             }
            //         }
            //     }

            //     if (element.Document[0].DocIssueCountry === '') {
            //         if (setValidationStaus) {
            //             document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
            //             setValidationStaus = false;
            //             const yOffset = -150;
            //             const element2 = document.getElementById("issueCountry" + element.TravelerRefNumber);
            //             const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //             window.scrollTo({ top: y, behavior: 'smooth' });
            //         }
            //         else {
            //             document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
            //         }
            //     }
            // }















            element.PersonName.GivenName = document.getElementById("fName" + element.TravelerRefNumber).value;
            element.PersonName.Surname = document.getElementById("lName" + element.TravelerRefNumber).value;
            element.PersonName.MiddleName = document.getElementById("mName" + element.TravelerRefNumber).value;
            // element.DateOfBirth.Date = document.getElementById("dob" + element.TravelerRefNumber).value;
            if (document.getElementById("passportNumber" + element.TravelerRefNumber) !== null) {
                element.Document[0].DocID = document.getElementById("passportNumber" + element.TravelerRefNumber).value;
            }

            // element.Document[0].ExpireDate = document.getElementById("passportExpiry" + element.TravelerRefNumber).value;


            document.querySelector('#passengerName' + element.TravelerRefNumber).innerHTML = element.PersonName.NamePrefix + " " + element.PersonName.GivenName + " " + element.PersonName.Surname;
        });


        localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        if (setValidationStaus) {

            props.onContinueButton("1");
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
        }

    }

    function dateDiff(targerDate, sourceDate) {
        var year = targerDate.getFullYear();
        var month = targerDate.getMonth() + 1;
        var day = targerDate.getDate();

        var yy = sourceDate.getFullYear();
        var mm = sourceDate.getMonth() + 1;
        var dd = sourceDate.getDate();
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
            months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
            years = years - 1;
            months = months + 12;
        }
        // days
        days = Math.floor((targerDate.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
        //
        return { years: years, months: months, days: days };
    }

    return (<div className="accordion_flex_area">

        <div className="accordion_left_side position-relative text-muted">
            <h5>1</h5>
            <span id="completeOne" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" >
                <button id="headingOne" className="accordion-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne">
                    Travelers
                </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show"
                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                {props.traveler.travelerInfo.traveler.map((traveler) =>
                    <TravellerDetail
                        key={traveler.TravelerRefNumber}
                        id={traveler.TravelerRefNumber}
                        paxType={traveler.PassengerTypeCode}
                        traveler={props.traveler}>
                    </TravellerDetail>)}
                <div className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-6 my-auto">

                        <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="float-md-end float-lg-end ">
                            <button type="button" onClick={assignTravelDetails} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>)
}
export default TravellerDetails