import { Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import Dropdown from 'react-select';
const HotelSearch = () => {
    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Price' },
        { value: '2', label: 'Hotel Name' }
       
    ];
    let chainOptions = [];
    chainOptions = [
        { value: '1', label: 'lorem1' },
        { value: '2', label: 'lorem2' }
       
    ];
    let propertyOptions = [];
    propertyOptions = [
        { value: '1', label: 'lorem1' },
        { value: '2', label: 'lorem2' }
       
    ];
    useDocumentTitle('Hotel Search')
    return <Fragment>

    
       
        <section  className="sub-banner">
            <div  className="bg-parallax bg-1"></div>
            <div  className="logo-banner text-center">
              
            </div>
        </section> 

        <section id="theme_search_form_tour" className='mb-3'>
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-12">
                        <div  className="theme_search_form_area">
                            <div  className="row">
                                <div  className="col-lg-12">
                                    <div  className="tour_search_form">
                                        <form action="!#">
                                            <div  className="row">
                                                <div  className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                    <div  className="flight_Search_boxed py-2">
                                                        <p>Destination</p>
                                                        <div  className='d-inline-flex pb-1 h5 mb-0'>London<span className='h6 text-muted w-55 mt-1 ms-2'> The City of London, London, England, United Kingdom</span> </div>
                                                        
                                                    </div>
                                                </div>
                                                <div  className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                    <div  className="form_search_date">
                                                        <div  className="flight_Search_boxed date_flex_area py-2">
                                                            <div  className="Journey_date">
                                                                <p>Check-in</p>
                                                                <input type="text" value="Thur, 30 Jun 2023" />
                                                                {/* <span>Thursday</span> */}
                                                            </div>
                                                            <div  className="Journey_date">
                                                                <p>Check-out</p>
                                                                <input type="text" value="Fri, 31 Jun 2023" />
                                                                {/* <span>Friday</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                    <div className='col-md-12'>
                                                        <div  className="flight_Search_boxed dropdown_passenger_area py-1 ">
                                                            <p>Guests </p>
                                                            <div  className="dropdown pb-2">
                                                                <div className="col-lg-6 col-sm-6 dropdown-search-text"><div className="Dropdown-root"><div className="Dropdown-control form-control control dropdown-search form-select bg_input mb-0" aria-haspopup="listbox"><div className="Dropdown-placeholder">1</div><div className="Dropdown-arrow-wrapper"><span className="arrow-closed"></span></div></div></div></div>
                                                                {/* <button  className="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <h6 className='mt-1'>1 Guests</h6>
                                                                </button>
                                                                <div  className="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div  className="traveller-calulate-persons">
                                                                        <div  className="passengers">
                                                                            <h6>Guests</h6>
                                                                            <div  className="passengers-types">
                                                                                <div  className="passengers-type">
                                                                                    <div  className="text"><span
                                                                                        className="count pcount">1</span>
                                                                                        <div  className="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div  className="button-set">
                                                                                        <button type="button"  className="btn-add">
                                                                                            <i  className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract">
                                                                                            <i  className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div  className="passengers-type">
                                                                                    <div  className="text"><span
                                                                                        className="count ccount">0</span>
                                                                                        <div  className="type-label">
                                                                                            <p  className="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>0
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div  className="button-set">
                                                                                        <button type="button"  className="btn-add-c">
                                                                                            <i  className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract-c">
                                                                                            <i  className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                    <div className='col-md-12'>
                                                        <div  className="flight_Search_boxed dropdown_passenger_area py-1">
                                                            <p>Rooms</p>
                                                            <div  className="dropdown pb-2">
                                                                <div className="col-lg-6 col-sm-6 dropdown-search-text"><div className="Dropdown-root"><div className="Dropdown-control form-control control dropdown-search form-select bg_input mb-0" aria-haspopup="listbox"><div className="Dropdown-placeholder">1</div><div className="Dropdown-arrow-wrapper"><span className="arrow-closed"></span></div></div></div></div>
                                                                {/* <button  className="dropdown-toggle  final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <h6 className='mt-1'>1 Room</h6>
                                                                </button>
                                                                <div  className="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div  className="traveller-calulate-persons">
                                                                        <div  className="passengers">
                                                                            <h6>Room</h6>
                                                                            <div  className="passengers-types">
                                                                                <div  className="passengers-type">
                                                                                    <div  className="text"><span
                                                                                        className="count pcount">1</span>
                                                                                        <div  className="type-label">
                                                                                            <p>Room</p>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                    <div  className="button-set">
                                                                                        <button type="button"  className="btn-add">
                                                                                            <i  className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract">
                                                                                            <i  className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 mt-2'>
                                                    <div className='row'>
                                                        <div className='col-lg-12 col-sm-12 dropdown-search-text dropdown-mt-5px'>
                                                            <label  className="text-12px fw-bold text-primary col-lg-12">Brands</label>
                                                            <Dropdown
                                                                
                                                                options={chainOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                
                                                            />
                                                        </div>
                                                        {/* <div className='col-lg-4 col-sm-12 dropdown-search-text dropdown-mt-5px'>
                                                            <label  className="text-12px fw-bold text-primary col-lg-12">Property Type</label>
                                                            <Dropdown
                                                                
                                                                options={propertyOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                               
                                                            />
                                                        </div>
                                                        <div className='col-lg-4 col-sm-12 input-text-height dropdown-mt-5px '>
                                                            <label className="text-12px fw-bold text-primary col-lg-12"> Maximum Price </label>
                                                            <input type="text" className="form-control bg_input"
                                                                                            placeholder=""/>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 my-auto'>
                                                    <div className='row'>
                                                        <div className='d-lg-flex d-md-flex  search-flight-checkbox'>
                                                            <span className="text-12px text-muted">
                                                                <div className='form-check'>
                                                                    <input className="form-check-input preference-options" type="checkbox" id="directFlights"></input>
                                                                    <label className="form-check-label mb-0" for="directFlights">
                                                                    Luxury Hotels Only
                                                                    </label>
                                                                </div>
                                                            </span>
                                                        
                                                            <span className="ms-3 text-12px text-muted">
                                                                <div className='form-check'>
                                                                    <input className="form-check-input preference-options" type="checkbox" id="includeBagOnlyFares"></input>
                                                                    <label className="form-check-label mb-0" for="includeBagOnlyFares">
                                                                    Refundable Only
                                                                    </label>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  className="top_form_search_button mt-3">
                                                    <button  className="btn btn_theme btn_md" type="button">Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div  className="main mt-5">
            <div  className="container">
                
                <div className='row'>
                    <div className='col-lg-1 my-auto'>
                        <button className="btn text-white mt-3 gt-main-btn sort-filter1" title='Show Filter'><i className="fa-solid fa-filter"></i></button>
                    </div>
                    
                    <div className='col-lg-8 my-auto text-center'>
                        {/* <div className='h5 text-muted'>We found <span className='text-primary'>640 properties</span> availability.</div> */}
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-3">
                       
                        <div className='row'>
                            <div className='col-md-9 text-end'>
                                <label className="h6 text-primary mb-2" for="city">Sort By</label>
                            </div>
                            <div className='col-md-3'>
                                <div className='text-primary float-lg-end text-end'>
                                    <span style={{ cursor: 'pointer' }}><i className="fas fa-arrow-circle-down h5"></i></span>
                                  
                                    <span style={{ cursor: 'pointer' }} ><i className="fas fa-arrow-circle-up ms-1 h5"></i></span>
                                
                                </div>
                            </div>
                        </div>
                        <Dropdown   
                            options={sortOptions}
                            textField="value"
                            dataItemKey="label"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                            placeholder="Price"   
                        />
                    </div>
                    <div  className="col-md-3" id='sort_Box_Div' style={{ display: 'none' }}>
                        <div className="left_side_search_area">
                    
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5 className='my-3'>Price</h5>
                                </div>
                                <div className="filter-price">
                                    <div id="price-slider"></div>
                                </div>
                                <button className="apply mt-5 text-end" type="button">Apply</button>
                            </div>
                            {/* <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Facilities</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultf1"/>
                                        <label className="form-check-label" for="flexCheckDefaultf1">
                                            <span className="area_flex_one">
                                                <span>Wake-up call</span>
                                                <span>20</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultf2"/>
                                        <label className="form-check-label" for="flexCheckDefaultf2">
                                            <span className="area_flex_one">
                                                <span>Flat TV</span>
                                                <span>14</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultaf3"/>
                                        <label className="form-check-label" for="flexCheckDefaultaf3">
                                            <span className="area_flex_one">
                                                <span>Vehicle service</span>
                                                <span>30</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultaf4"/>
                                        <label className="form-check-label" for="flexCheckDefaultaf4">
                                            <span className="area_flex_one">
                                                <span>Guide service</span>
                                                <span>22</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultaf5"/>
                                        <label className="form-check-label" for="flexCheckDefaultaf5">
                                            <span className="area_flex_one">
                                                <span>Internet, Wi-fi</span>
                                                <span>41</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Hotel service</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt1"/>
                                        <label className="form-check-label" for="flexCheckDefaultt1">
                                            <span className="area_flex_one">
                                                <span>Gymnasium</span>
                                                <span>20</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt2"/>
                                        <label className="form-check-label" for="flexCheckDefaultt2">
                                            <span className="area_flex_one">
                                                <span>Mountain Bike</span>
                                                <span>14</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt3"/>
                                        <label className="form-check-label" for="flexCheckDefaultt3">
                                            <span className="area_flex_one">
                                                <span>Wifi</span>
                                                <span>62</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt4"/>
                                        <label className="form-check-label" for="flexCheckDefaultt4">
                                            <span className="area_flex_one">
                                                <span>Aerobics Room</span>
                                                <span>08</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt5"/>
                                        <label className="form-check-label" for="flexCheckDefaultt5">
                                            <span className="area_flex_one">
                                                <span>Golf Cages</span>
                                                <span>12</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Hotel Name</h5>
                                </div>
                                <input type="text" className="form-control bg_input mt-3" placeholder=""/>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading mb-3">
                                    <h5>Brands</h5>
                                </div>
                                <Dropdown
                                                                
                                    options={chainOptions}
                                    textField="label"
                                    dataItemKey="value"
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control dropdown-search form-select bg_input mb-0'
                                    
                                />
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Property Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt1"/>
                                        <label className="form-check-label" for="flexCheckDefaultt1">
                                            <span className="area_flex_one">
                                                <span>Hotels</span>
                                               
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt2"/>
                                        <label className="form-check-label" for="flexCheckDefaultt2">
                                            <span className="area_flex_one">
                                                <span>Villas</span>
                                              
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt3"/>
                                        <label className="form-check-label" for="flexCheckDefaultt3">
                                            <span className="area_flex_one">
                                                <span>Chalets</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt3"/>
                                        <label className="form-check-label" for="flexCheckDefaultt3">
                                            <span className="area_flex_one">
                                                <span>Apartments</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 full-width'>
                        <div  className="main-cn hotel-page clearfix">
                            <div  className="row">
                            
                                <div  className="col-md-12 col-md-push-3">
                                    <section  className="hotel-list">
                                        <div  className="sort-view clearfix">
                                        </div>
                                     
                                        <div  className="hotel-list-cn clearfix">

                                            {/*         <!-- Hotel Item --> */}
                                            {/* <div  className="hotel-list-item position-relative ">
                                                <figure  className="hotel-img float-left">
                                                    <a href="hotel-detail.html" title="">
                                                        <img src="\.\./\.\./\.\./assets/images/hotel/img-1.jpg" alt="" />
                                                    </a>
                                                </figure>
                                                <span className="hotel-flag badge">GT Blue</span>
                                                <div  className="hotel-text">
                                                    <div  className="hotel-name">
                                                        <a href="hotel-detail.html" title="">Copley Square<span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span></a>
                                                    </div>
                                                    <div  className="hotel-star-address">
                                                        <span  className="hotel-star">
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                        </span>
                                                       
                                                        <address  className="hotel-address">
                                                            42 Princes Square, London W2 4AD
                                                        </address>
                                                        <p>
                                                            My stay at cumberland hotel was amazing, loved the location, was close to all the major attractions allthough there rooms seemed...
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <div  className="price-box col-md-6">
                                                            
                                                            // <p> <a href="1" title=""><span className="badge rounded-pill bg-info text-white text-12px">PERKS</span></a><span className='ms-1'>Includes 7 perks worth over $345</span></p>
                                                            <div className="text-success text-12px mt-2"><i className="fas fa-square"></i> Free Cancellation before March 14, 2024</div>
                                                            <div className="text-success text-12px mt-2"><i className="fas fa-square"></i> Pay Later</div>
                                                            
                                                        </div>
                                                        <div  className="hotel-service col-md-6 float-right">
                                                            <div  className="price special-price text-primary fw-bold h3 mb-0">$345<small className='h6'>/night</small></div>
                                                            <button className=" mt-2 btn btn_theme btn_sm">Book Now</button>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                         <div className='hotel-star-address text-12px'>1 Room, 3 Night Stay(Taxes and Fees Included)</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                           <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                                <figure  className="hotel-img float-left">
                                                    <a href="hotel-detail.html" title="">
                                                        <img src="\.\./\.\./\.\./assets/images/hotel/hotel-bg3.png" alt="" />
                                                    </a>
                                                </figure>
                                                <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                                <div  className="hotel-text ">
                                                    <div  className="hotel-name">
                                                        <a href="hotel-detail.html" title="">Hyatt Regency London Stratford
                                                        {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                        </a>
                                                    </div>
                                                    <div  className="hotel-star-address">
                                                        <span  className="hotel-star">
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                        </span>
                                                       
                                                        <address  className="hotel-address">
                                                        4-star hotel with restaurant, connected to a shopping center, near Westfield Stratford City
                                                        </address>
                                                        
                                                    </div>
                                                  
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 my-auto'>
                                                        <div className='text-12px ms-3 text-muted'>10a Chestnut Plaza, Westfield Stratford, City, Montfichet Road, Olympic Park, London, England, E20 1GL</div>
                                                        </div>
                                                        <div  className="col-md-4 hotel-service text-end">
                                                            <div  className="price special-price text-primary fw-bold h3 mb-0">$345</div>
                                                            <div className='text-muted text-10px'>Avg Per Night <br></br>Including Taxes</div>
                                                        </div>
                                                        <hr className='my-1'></hr>
                                                        <div className='col-md-9 my-auto'>
                                                            {/* <div className='text-12px mt-2 text-muted'>
                                                                <span>1 Room,</span>
                                                                <span className='ms-1'>2 Nights</span>
                                                                <span> stay (Taxes & fees included)</span>
                                                            </div> */}
                                                            <div className="col-md-12 text-12px text-muted pb-3"> <span className="text-12px text-sky-blue"><i className="fas fa-money-check-alt"></i>  Refundable</span> Source: <span className="text-primary fw-bold">Sabre</span> | Commission:  <span className="text-primary fw-bold">$9.48</span> | Content:  <span className="text-primary fw-bold">Virtuoso</span></div>
                                                            
                                                        </div>
                                                        <div className='col-md-3 text-end'>  
                                                            <button className="my-2 btn btn_theme btn_sm">Book Now</button>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='row'>
                                                        
                                                </div>
                                                   
                                               
                                            </div>
                                            
                                            
                                            <div  className="hotel-list-item position-relative">
                                                <figure  className="hotel-img float-left">
                                                    <a href="hotel-detail.html" title="">
                                                        <img src="\.\./\.\./\.\./assets/images/hotel/hotel-bg1.png" alt="" />
                                                    </a>
                                                </figure>
                                                {/* <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span> */}
                                                <div  className="hotel-text ">
                                                    <div  className="hotel-name">
                                                        <a href="hotel-detail.html" title="">Club Quarters Hotel London City
                                                            {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                        </a>
                                                    </div>
                                                    <div  className="hotel-star-address">
                                                        <span  className="hotel-star">
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                        </span>
                                                       
                                                        <address  className="hotel-address">
                                                        4-star hotel with restaurant, near Tower of London
                                                        </address>
                                                    </div>
                                                  
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 h5'></div>
                                                    <div  className="col-md-6 hotel-service text-end">
                                                        <div  className="price special-price text-primary fw-bold h3 mb-0">$ 1,235</div>
                                                        <div className='text-muted text-10px'>Avg Per Night <br></br>Including Taxes</div>
                                                    </div>
                                                    <hr className='my-1'></hr>
                                                    <div className='col-md-8 my-auto'>
                                                    
                                                        <div className="col-md-12 text-12px text-muted pb-3"> <span className="text-12px text-danger"><i className="fas fa-money-check-alt"></i> Non Refundable</span> Source: <span className="text-primary fw-bold">Sabre</span> | Commission:  <span className="text-primary fw-bold">$9.48</span></div>
                                                        
                                                    </div>
                                                    <div className='col-md-4 text-end'>  
                                                        <button className="my-2 btn btn_theme btn_sm">Book Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div  className="hotel-list-item position-relative">
                                                <figure  className="hotel-img float-left">
                                                    <a href="hotel-detail.html" title="">
                                                        <img src="\.\./\.\./\.\./assets/images/hotel/hotel-bg2.png" alt="" />
                                                    </a>
                                                </figure>
                                                {/* <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span> */}
                                                <div  className="hotel-text ">
                                                    <div  className="hotel-name">
                                                        <a href="hotel-detail.html" title="">Yotel London City 
                                                        {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                        </a>
                                                    </div>
                                                    <div  className="hotel-star-address">
                                                        <span  className="hotel-star">
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                            <i  className="glyphicon glyphicon-star"></i>
                                                        </span>
                                                       
                                                        <address  className="hotel-address">
                                                            4-star hotel with restaurant, near St. Paul's Cathedral
                                                        </address>
                                                    </div>
                                                  
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8'>
                                                            <div className='text-12px ms-3 text-muted'>96-100 Clerkenwell Road, London, England, EC1M 5RJ</div>
                                                            
                                                        </div>
                                                        <div  className="col-md-4 hotel-service text-end">
                                                            <div  className="price special-price text-primary fw-bold h3 mb-0">$1,345</div>
                                                            <div className='text-muted text-10px'>Avg Per Night <br></br>Including Taxes</div>
                                                        
                                                        </div>
                                                    </div>
                                                    <hr className='my-1'></hr>
                                                    <div className='row'>
                                                        <div className='col-md-8 my-auto'>
                                                            {/* <div className='text-12px mt-2 text-muted'>
                                                                <span>1 Room,</span>
                                                                <span className='ms-1'>2 Nights</span>
                                                                <span> stay (Taxes & fees included)</span>
                                                            </div> */}
                                                            <div className="col-md-12 text-12px text-muted pb-3"> <span className="text-12px text-sky-blue"><i className="fas fa-money-check-alt"></i>  Refundable</span> Source: <span className="text-primary fw-bold">Sabre</span> | Commission:  <span className="text-primary fw-bold">$9.48</span></div>
                                                        </div>
                                                    <div className='col-md-4 text-end'>  
                                                        <button className="my-2 btn btn_theme btn_sm">Book Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="load_more_flight">
                                                <button className="btn btn-sm btn_theme"><i className="fas fa-spinner fa-spin"></i> Load more..</button>
                                            </div>
                                        </div>
                                       
                                    </section>
                                    {/*   <!-- End Hotel List --> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        {/*    <!-- End Main --> */}

    </Fragment>

};

export default HotelSearch;