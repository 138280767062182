import { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import Dropdown from 'react-select';
import { searchCommonList } from '../../services/Fee-API';
import useHttp from '../../services/use-http';
import { searchMyTrip } from '../../services/Booking-API';
import MyTripsList from './Components/MyTripsList';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "../../components/Common/Loading/LoadingSpinner";
import { useHistory, useLocation } from 'react-router-dom';
import { each } from 'jquery';
import { getConfigurationByBoolen } from '../../utils/ConfigurationsFunction';
import secureLocalStorage from 'react-secure-storage';

const MyTrips = () => {
    useDocumentTitle('My Trips');
    const statusOption = getConfigurationByBoolen("ALLOW_B2B") ? [
        { value: "1", label: "All" },
        { value: "2", label: "Booked" },
        { value: "3", label: "Booking Request" },
        { value: "4", label: "Canceled" },
        { value: "6", label: "Failed" },
        { value: "7", label: "Modified" },
        { value: "5", label: "On Hold" }
    ] : [
        { value: "1", label: "All" },
        { value: "2", label: "Booked" },
        { value: "4", label: "Canceled" },
        { value: "6", label: "Failed" }
    ];

    const { t } = useTranslation(['translation.MyTrips']);
    const history = useHistory();
    const location = useLocation();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isFirtTime, setFirstTime] = useState("");

    const enteredConfirmationNoInputRef = useRef('');
    const enteredPnrNoInputRef = useRef('');
    const enteredLastNameInputRef = useRef('');
    const enteredBookingDateFromInputRef = useRef('');
    const enteredBookingDateToInputRef = useRef('');
    const enteredTravelDateFromInputRef = useRef('');
    const enteredTravelDateToInputRef = useRef('');

    const [enteredConfirmationNo, setEnteredConfirmationNo] = useState("");
    const [enteredPnrNo, setEnteredPnrNo] = useState("");
    const [enteredLastName, setEnteredLastName] = useState("");
    const [serviceCode, setServiceCode] = useState('');
    const [enteredBookingDateFrom, setEnteredBookingDateFrom] = useState("");
    const [enteredBookingDateTo, setEnteredBookingDateTo] = useState("");
    const [enteredTravelDateFrom, setEnteredTravelDateFrom] = useState("");
    const [enteredTravelDateTo, setEnteredTravelDateTo] = useState("");

    const { sendRequest: sendRequestCommonListService, data: loadedService } = useHttp(searchCommonList);
    const { sendRequest, status, data: loadedMyTrip, error } = useHttp(searchMyTrip);
    const [error1, setError] = useState();
    const [UserStatus, setUserStatus] = useState(statusOption[0].value);
    const [UserStatusLabel, setUserStatusLabel] = useState(statusOption[0].label);
    const [bookingDateFromInvalidInputError, setBookingDateFromInvalidInputError] = useState(false);
    const [bookingDateToInvalidInputError, setBookingDateToInvalidInputError] = useState(false);


    const errorHandler = () => {
        //history.go('/mytrips');
        setError(null);
    };

    useEffect(() => {
        var x = document.getElementById("myDIV");
        x.style.display = "none";;
    }, [])

    function toggleHandler() {
        var x = document.getElementById("myDIV");
        if (x.style.display === "none") {
            x.style.display = "";
        } else {
            x.style.display = "none";
        }
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);
        setIsSubmitClicked(true);

        var today = new Date().toISOString().split('T')[0];
        if (enteredBookingDateFromInputRef.current.value !== '') {
            if (enteredBookingDateFromInputRef.current.value > today) {
                setBookingDateFromInvalidInputError(true);
                return;
            }
            else {
                setBookingDateFromInvalidInputError(false);
            }
        }

        if (enteredBookingDateToInputRef.current.value !== '') {
            if (enteredBookingDateToInputRef.current.value > today) {
                setBookingDateToInvalidInputError(true);
                return;
            }
            else {
                setBookingDateToInvalidInputError(false);
            }
        }


        if (enteredBookingDateFromInputRef.current.value !== '' && enteredBookingDateToInputRef.current.value !== '') {
            if (parseInt(enteredBookingDateToInputRef.current.value.replaceAll('-', '')) < parseInt(enteredBookingDateFromInputRef.current.value.replaceAll('-', ''))) {
                setError({
                    title: "Message",
                    message: `${t('Booking Date To cannot be less than Booking Date From.')}`,
                });
                return;
            }
        }

        if (enteredTravelDateFromInputRef.current.value !== '' && enteredTravelDateToInputRef.current.value !== '') {
            if (parseInt(enteredTravelDateToInputRef.current.value.replaceAll('-', '')) < parseInt(enteredTravelDateFromInputRef.current.value.replaceAll('-', ''))) {
                setError({
                    title: "Message",
                    message: `${t('Travel Date To cannot be less than Travel Date From.')}`,
                });
                return;
            }
        }

        const onSearchMyTrip = ({
            "confirmationNo": enteredConfirmationNoInputRef.current.value,
            "pnrNo": enteredPnrNoInputRef.current.value,
            "lastName": enteredLastNameInputRef.current.value,
            "service": serviceCode !== '' ? serviceCode === 'Flight + Hotel' ? 'FLHT' : serviceCode === 'Flight' ? 'FL' : 'HT' : '',
            "bookingDateFrom": enteredBookingDateFromInputRef.current.value !== '' ? enteredBookingDateFromInputRef.current.value.replaceAll('-', '') : '',
            "bookingDateTo": enteredBookingDateToInputRef.current.value !== '' ? enteredBookingDateToInputRef.current.value.replaceAll('-', '') : '',
            "travelDateFrom": enteredTravelDateFromInputRef.current.value !== '' ? enteredTravelDateFromInputRef.current.value.replaceAll('-', '') : '',
            "travelDateTo": enteredTravelDateToInputRef.current.value !== '' ? enteredTravelDateToInputRef.current.value.replaceAll('-', '') : '',
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "officeName": JSON.parse(localStorage.getItem('companyName')),
            "userID": JSON.parse(localStorage.getItem('userID')),
            "status": UserStatusLabel,
            "ModuleType": getConfigurationByBoolen("ALLOW_LOGIN") ? '' : 'B2C',

            "CustomerLoginID": secureLocalStorage.getItem('CustomerLoginEmailID'),
        });

        if (1 === 1) {
            sendRequest(onSearchMyTrip);
        }
    }, [sendRequest, serviceCode, UserStatus])

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        sessionStorage.setItem('isHotelFailed', '');
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        var today = new Date().toISOString().split('T')[0];

        document.getElementById("bookingDateFrom").setAttribute('max', today);
        document.getElementById("bookingDateTo").setAttribute('max', today);



        const onSearchCommonListService = ({
            methodName: "ServiceBoth",
            methodCode: "SERB",
            listID: "",
            listValue: "",
            subListID: "",
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });

        sendRequestCommonListService(onSearchCommonListService);

        const keyDownHandler = event => {

            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇︝ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, [sendRequestCommonListService, onSearchHandler])



    useEffect(() => {

        const onSearchMyTrip = ({
            "confirmationNo": enteredConfirmationNoInputRef.current.value,
            "pnrNo": enteredPnrNoInputRef.current.value,
            "lastName": enteredLastNameInputRef.current.value,
            "service": serviceCode !== '' ? serviceCode === 'Flight' ? 'FL' : 'HT' : '',
            "bookingDateFrom": enteredBookingDateFromInputRef.current.value !== '' ? enteredBookingDateFromInputRef.current.value.replaceAll('-', '') : '',
            "bookingDateTo": enteredBookingDateToInputRef.current.value !== '' ? enteredBookingDateToInputRef.current.value.replaceAll('-', '') : '',
            "travelDateFrom": enteredTravelDateFromInputRef.current.value !== '' ? enteredTravelDateFromInputRef.current.value.replaceAll('-', '') : '',
            "travelDateTo": enteredTravelDateToInputRef.current.value !== '' ? enteredTravelDateToInputRef.current.value.replaceAll('-', '') : '',
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "officeName": JSON.parse(localStorage.getItem('companyName')),
            "userID": JSON.parse(localStorage.getItem('userID')),
            "status": UserStatusLabel,
            "ModuleType": getConfigurationByBoolen("ALLOW_LOGIN") ? '' : 'B2C',
            "CustomerLoginID": secureLocalStorage.getItem("CustomerLoginEmailID"),
        });


        sendRequest(onSearchMyTrip);

    }, [])
    let serviceOptions = []
    let serviceArrayData = [];
    for (let i in loadedService) {
        if (loadedService['errors'].status === 'FALSE') {
            if (i === 'data') {
                serviceArrayData = loadedService[i];
            }
        }
    }
    function sortByProperty(property) {
        return function (a, b) {
            if (a[property] > b[property])
                return 1;
            else if (a[property] < b[property])
                return -1;

            return 0;
        }
    }


    for (let index = 0; index < serviceArrayData.length; index++) {
        serviceOptions = [...serviceOptions, { value: serviceArrayData[index].listValue, label: serviceArrayData[index].listValue, id: serviceArrayData[index].listID }]
    }
    serviceOptions.sort(sortByProperty('id'));
    // serviceOptions.sort()

    const confirmationNoChangeHandler = (event) => {
        setEnteredConfirmationNo(event.target.value);
    };
    const pnrNoChangeHandler = (event) => {
        setEnteredPnrNo(event.target.value);
    };
    const lastNameChangeHandler = (event) => {
        setEnteredLastName(event.target.value);
    };
    const serviceChangeHandler = (event) => {
        if (event !== null) {
            setServiceCode(event.value);
        }
        else {
            setServiceCode('');
        }

    };
    const bookingDateFromChangeHandler = (event) => {
        setEnteredBookingDateFrom(event.target.value);
    };
    const bookingDateToChangeHandler = (event) => {
        setEnteredBookingDateTo(event.target.value);
    };
    const travelDateFromChangeHandler = (event) => {
        setEnteredTravelDateFrom(event.target.value);
    };
    const travelDateToChangeHandler = (event) => {
        setEnteredTravelDateTo(event.target.value);
    };

    function statusChangeHandler(event) {
        setUserStatus(event.value);
        setUserStatusLabel(event.label);
    }

    function changeLocation(event) {
        history.go('/mytrips');
    }

    let loadedData = [];
    for (let index in loadedMyTrip) {
        if (loadedMyTrip['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedMyTrip[index];
            }
        }
    }

    useEffect(() => {
        if (status === 'completed') {
            if (loadedMyTrip === null) {
                setIsSubmitClicked(true);
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
            else if (loadedMyTrip['errors'].status === 'TRUE') {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
        }

    }, [status, loadedMyTrip])

    const ErrorShow = (isSubmitClicked && error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const MyTripsListItems = (loadedData && loadedData.length !== 0) ?
        <MyTripsList MyTrips={loadedData} /> : (status !== 'pending' && ErrorShow)


    return <Fragment>
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>
        {/*   <!-- Form Area --> */}
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative mb-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row position-relative">
                                        {status === 'pending' &&
                                            <LoadingSpinner></LoadingSpinner>
                                        }
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> My Trips</label>
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <div className='float-lg-end'><span title="Send a Mail" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fas fa-envelope"></i></span><span title="Take a Print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i></span></div> */}
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'>Confirmation No.</label>
                                                            <input type="text" className="form-control bg_input" ref={enteredConfirmationNoInputRef} value={enteredConfirmationNo} onChange={confirmationNoChangeHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'>PNR</label>
                                                            <input type="text" className="form-control bg_input" ref={enteredPnrNoInputRef} value={enteredPnrNo} onChange={pnrNoChangeHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'>Last Name</label>
                                                            <input type="text" className="form-control bg_input" ref={enteredLastNameInputRef} value={enteredLastName} onChange={lastNameChangeHandler} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="control col-md-3 mb-0 mt-2">
                                                    <div className="form-switch form-switch-sm padding-left-0">
                                                        <label for="advance" className="mb-2 h6 text-primary me-2">Advanced</label>
                                                        <input id="mytrip-advance-toggle" className="form-check-input ms-1" type="checkbox" onChange={() => toggleHandler()} />
                                                    </div>
                                                </div>

                                            </div>
                                            {<div className='mt-2 ' id='myDIV' >
                                                <div className='row '>
                                                    <div className='col-md-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>Service</label>
                                                            <Dropdown onChange={serviceChangeHandler}
                                                                value={serviceOptions.filter(option => option.value === serviceCode)}
                                                                options={serviceOptions}
                                                                textField="listValue"
                                                                dataItemKey="id"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select"
                                                                isClearable={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                            <Dropdown onChange={statusChangeHandler}
                                                                value={statusOption.filter(option => option.value === UserStatus)}
                                                                options={statusOption}
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className='control'>
                                                            <div className="form-group">
                                                                <label className='h6 text-primary mb-2'>Booking Date From</label>
                                                                <input type="date" className="form-control bg_input" ref={enteredBookingDateFromInputRef} value={enteredBookingDateFrom} onChange={bookingDateFromChangeHandler} id='bookingDateFrom' />
                                                                {bookingDateFromInvalidInputError && <p className="error-text">{'Booking Date From cannot be greater than current date.'}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className='control'>
                                                            <div className="form-group">
                                                                <label className='h6 text-primary mb-2'>Booking Date To</label>
                                                                <input type="date" className="form-control bg_input" ref={enteredBookingDateToInputRef} value={enteredBookingDateTo} onChange={bookingDateToChangeHandler} id='bookingDateTo' />
                                                                {bookingDateToInvalidInputError && <p className="error-text">{'Booking Date To cannot be greater than current date.'}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className='control'>
                                                            <div className="form-group">
                                                                <label className='h6 text-primary mb-2'>Travel Date From</label>
                                                                <input type="date" className="form-control bg_input" ref={enteredTravelDateFromInputRef} value={enteredTravelDateFrom} onChange={travelDateFromChangeHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className='control'>
                                                            <div className="form-group">
                                                                <label className='h6 text-primary mb-2'>Travel Date To</label>
                                                                <input type="date" className="form-control bg_input" ref={enteredTravelDateToInputRef} value={enteredTravelDateTo} onChange={travelDateToChangeHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                            <div className="col-md-12 top_form_search_button mt-3">
                                                <div className=''>
                                                    <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={changeLocation}>Reset</button>
                                                    <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {MyTripsListItems}
                </div>
            </div>
        </section>

    </Fragment>




};

export default MyTrips;