import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { getHistory } from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";

const HistoryDetails = (props) => {
   
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedData } = useHttp(getHistory);
    const [description, setDescription] = useState([]);
    const [users, setUsers] = useState([]);
    const [dateAndTime, setDateAndTime] = useState([]);

    function functionExit() {
        props.errorHandler();
    }

    const errorHandler1 = () => {
        setError1(null);
    };

    useEffect(() => {
        sendRequest(props.bookingJSON.data.shoppingID);
    }, [])
    console.log(loadedData);
    useEffect(() => {
        if (status === 'completed') {
            if (loadedData.errors.status === 'FALSE' && loadedData.data.length > 0) {
                let descriptionTemp = [];
                loadedData.data.forEach(element => {
                    descriptionTemp.push(element.description !== undefined ? element.description : 'N/A');
                });
                setDescription(descriptionTemp);

                let usersTemp = [];
                loadedData.data.forEach(element => {
                    usersTemp.push(element.userName !== undefined ? element.userName : 'N/A');
                });
                setUsers(usersTemp);

                let dateAndTimeTemp = [];
                loadedData.data.forEach(element => {
                    dateAndTimeTemp.push(element.createdDate !== undefined ? element.createdDate : 'N/A');
                });
                setDateAndTime(dateAndTimeTemp);

            } else {
                document.querySelector('#dvHistoryMsg').innerHTML = "History not found.";
            }
            return;
        }

    }, [status])

    function showHistoryInfo() {
        let historyInfo = [];
        for (let index = 0; index < description.length; index++) {
            historyInfo.push(
                <tbody>
                    <tr>
                        <td className="col-md-5 gt-word-break">{description[index]}</td>
                        <td className="col-md-3">{users[index]}</td>
                        <td className="col-md-4">{dateAndTime[index]}</td>
                    </tr>
                </tbody>
            );
        }

        return historyInfo;
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div id="dvHistoryMsg" className=""></div>
                <div className="container gt-h-340px">
                    <div className="row">
                        {
                            (description.length > 0) &&
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="col-md-5" scope="col">Action</th>
                                            <th className="col-md-3" scope="col">Done By</th>
                                            <th className="col-md-4" scope="col">Done On</th>
                                        </tr>
                                    </thead>
                                    {
                                        showHistoryInfo()
                                    }
                                </table>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-12 my-auto">
                    <button className='btn gt-btn_md text-white my-2 gt-main-btn float-end' id='btnSend' onClick={functionExit}>
                        Close
                    </button>
                </div>
            </section>
        </div>
    </Fragment>
};
export default HistoryDetails;