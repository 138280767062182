import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';

const OutPolicy = () => {
    const [reasonValue, setReasonValue] = useState('');
    const [value, setValue] = useState('');
    const [remarksInvalidInputError, setRemarksInvalidInputError] = useState(false);

    let reasonOptions = [
        { value: 'As per meeting timings', label: 'As per meeting timings' },
        { value: 'Client has requested to book this option', label: 'Client has requested to book this option' },
        { value: 'Co-travelers have selected the same option', label: 'Co-travelers have selected the same option' },
        { value: 'No other suitable option available', label: 'No other suitable option available' }
    ];

    const reasonChangeHandler = (event) => {
        setReasonValue(event.value);
    };

    function onBlurCheck() {
        if (document.querySelector('#remarks').value !== '') {
            if (!document.querySelector('#remarks').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                setRemarksInvalidInputError(true);
                return;
            }
            else {
                setRemarksInvalidInputError(false);
            }
        }
    }

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="reason">Reason<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown onChange={reasonChangeHandler}
                                            value={reasonOptions.filter(option => option.value === reasonValue)}
                                            options={reasonOptions}
                                            textField="value"
                                            dataItemKey="label"
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Remarks</label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='remarks'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            onBlur={onBlurCheck}
                                        />
                                    </div>
                                    {remarksInvalidInputError &&
                                        <span className="error-text col-lg-12 mt-3">{('Remarks is not valid.')}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 accordion-header text-black fw-bold mb-4">Note: <span className="text-primary fw-500" >Please advise the reason you have selected an out-of-policy option. This reason will be shown to everyone who needs to approve your travel request.</span></div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default OutPolicy;

