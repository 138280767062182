import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import FlightSearchControlMainPanel from '../../Flights/Components/FlightSearchControlMainPanel';
import FlightSearchControlAdditionalPanel from '../../Flights/Components/FlightSearchControlAdditionalPanel';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../../services/CONSTANTS';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import SearchHotelPartial from '../Home/SearchHotelPartial';
import SearchSightseeingPartial from '../Home/SearchSightseeingPartial';
import SearchHolidaysPartial from '../Home/SearchHolidaysPartial';

const SearchFlightPartial = () => {
    useDocumentTitle('Flight Search');
    const { t } = useTranslation(['translation.SearchFlight']);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isFirtTime, setFirstTime] = useState("");
    const [tripType, setTripType] = useState('');
    const [isAddedThree, setIsAddedThree] = useState(false);
    const [isAddedFour, setIsAddedFour] = useState(false);
    const [isAddedFive, setIsAddedFive] = useState(false);
    const [error1, setError] = useState();
    const [pageFocus, setPageFocus] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const valueParam = queryString.parse(location.search);
    const [loadComponent, setLoadComponent] = useState('');

    localStorage.setItem("tabActiveStatusSet", JSON.stringify([{ "Traveller": true },
    { "ContactPerson": false },
    { "SeatMap": false },
    { "AdditionalService": false },
    { "AddMarkUp": false },
    { "PaymentInformation": false }]))

    //User Story 1070: Change in Search for Single PNR
    const onAddhotel = (event) => {
        if (event.target.checked) {
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);
        }
        else {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");
        }
    }

    function onSearchHandler() {
        //window.open('http://localhost:3000/FlightSearch?flight=partial');
        //window.location.pathname = 'FlightSearch?flight=partial';
        history.push('FlightSearch?flight=partial');
    }

    const errorHandler = () => {
        setError(null);
        setPageFocus(false);
        document.querySelector('#explore_area').classList.add('d-none');

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const tripTypeHandler = (event) => {
        setTripType(event.target.id.split('-')[0]);
        sessionStorage.setItem('tripType', event.target.id.split('-')[0]);
    }

    const showMultiCityRow = (event) => {
        if (isAddedThree === false) {
            setIsAddedThree(true);
            if (isAddedFour && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFour === false) {
            setIsAddedFour(true);
            if (isAddedThree && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFive === false) {
            setIsAddedFive(true);
            if (isAddedThree && isAddedFour) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }

        if (document.querySelector('#removeMultiCityRowThree') !== null && document.querySelector('#removeMultiCityRowFour') !== null && document.querySelector('#removeMultiCityRowFive') !== null) {
            document.querySelector('#showMultiCityRow').style.display = "none";
        }
    }

    const hideMultiCityRow = (event) => {
        if (event.target.id === 'removeMultiCityRowThree') {
            setIsAddedThree(false);
            sessionStorage.removeItem('txtFrom3');
            sessionStorage.removeItem('spnFromAirportDetails3');
        }
        else if (event.target.id === 'removeMultiCityRowFour') {
            setIsAddedFour(false);
            sessionStorage.removeItem('txtFrom4');
            sessionStorage.removeItem('spnFromAirportDetails4');
        }
        else if (event.target.id === 'removeMultiCityRowFive') {
            setIsAddedFive(false);
            sessionStorage.removeItem('txtFrom5');
            sessionStorage.removeItem('spnFromAirportDetails5');
        }
        document.querySelector('#showMultiCityRow').style.display = "";
    }

    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

        if (sessionStorage.getItem('serviceType') === null || sessionStorage.getItem('serviceType') === '') {
            if (checkSecurityDetails("Book", "Flight") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
            else if (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR') && getConfigurationByBoolen("ALLOW_FLIGHT")) {
                sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            }
            else if (checkSecurityDetails("Book", "Hotel") && getConfigurationByBoolen("ALLOW_HOTEL") === true) {
                sessionStorage.setItem("serviceType", "lblHotel");
            }
            else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                sessionStorage.setItem("serviceType", "lblSightseeing");
            }
            else {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
        }

    }, [])

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        if (sessionStorage.getItem("serviceType") !== null && sessionStorage.getItem("serviceType") !== '') {
            if (sessionStorage.getItem("tripType") !== null && sessionStorage.getItem("tripType") !== '') {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
                else if (sessionStorage.getItem("AddHotelCheck") === null && sessionStorage.getItem("tripType") === 'roundtrip') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
                else if (sessionStorage.getItem("AddHotelCheck") !== null && sessionStorage.getItem("tripType") === 'roundtrip') {
                    sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
            }
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (valueParam.Flights === "1") {
            document.querySelector("#lblFlights_flight").classList.remove("text-primary");
            document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
            document.querySelector('#lblFlights_flight').click();

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
            }

            if (document.querySelector('#divTripType') !== null) {
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }
        }
        else if (valueParam.FlightPlusHotel === "1") {
            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights_flight").classList.add("text-primary");
            }

            document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
            document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");
            document.querySelector('#lblFlightPlusHotel_flight').click();

            document.querySelector('#divTripType').classList.add("d-none");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);
        }
        else {
            if (document.querySelector("#lblFlights_flight") !== null) {
                if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                    document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
                    document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                }

                document.querySelector("#lblFlights_flight").classList.remove("text-primary");
                document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");

                if (document.querySelector("#lblHotel_flight") !== null) {
                    document.querySelector("#lblHotel_flight").classList.add("text-primary");
                    document.querySelector("#lblHotel_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblSightseeing_flight") !== null) {
                    document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                    document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblHolidays_flight") !== null) {
                    document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                    document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector('#divTripType') !== null) {
                    document.querySelector('#divTripType').classList.remove("d-none");
                }

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = false;
                }
                localStorage.removeItem("AddHotel");
                sessionStorage.removeItem("AddHotelCheck");
            }
            else if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                if (document.querySelector("#lblFlights_flight") !== null) {
                    document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
                    document.querySelector("#lblFlights_flight").classList.add("text-primary");
                }

                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");

                if (document.querySelector("#lblHotel_flight") !== null) {
                    document.querySelector("#lblHotel_flight").classList.add("text-primary");
                    document.querySelector("#lblHotel_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblSightseeing_flight") !== null) {
                    document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                    document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblHolidays_flight") !== null) {
                    document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                    document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                }

                document.querySelector('#divTripType').classList.add("d-none");

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = true;
                }
                localStorage.setItem("AddHotel", "1");
                sessionStorage.setItem("AddHotelCheck", 1);
            }
            else {
                if (document.querySelector("#lblFlights_flight") !== null) {
                    document.querySelector("#lblFlights_flight").classList.remove("text-primary");
                    document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
                }

                if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                    document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
                    document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                }

                if (document.querySelector("#lblHotel_flight") !== null) {
                    document.querySelector("#lblHotel_flight").classList.add("text-primary");
                    document.querySelector("#lblHotel_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblSightseeing_flight") !== null) {
                    document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                    document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector("#lblHolidays_flight") !== null) {
                    document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                    document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                }

                if (document.querySelector('#divTripType') !== null) {
                    document.querySelector('#divTripType').classList.remove("d-none");
                }

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = false;
                }
                localStorage.removeItem("AddHotel");
                sessionStorage.removeItem("AddHotelCheck");
            }
        }

    }, [])

    useEffect(() => {

        if (sessionStorage.getItem('txtFrom3') !== null) {
            setIsAddedThree(true)
        }
        if (sessionStorage.getItem('txtFrom4') !== null) {
            setIsAddedFour(true)
        }
        if (sessionStorage.getItem('txtFrom5') !== null) {
            setIsAddedFive(true)
        }

        if (tripType === "") {
            if (sessionStorage.getItem("tripType") !== null && sessionStorage.getItem("tripType") !== '') {
                setTripType(sessionStorage.getItem("tripType"));

                if (sessionStorage.getItem("serviceType") !== null && sessionStorage.getItem("serviceType") !== '') {
                    if (sessionStorage.getItem("serviceType") === 'lblFlights') {
                        if (document.querySelector("#lblFlights_flight") !== null) {
                            document.querySelector("#lblFlights_flight").classList.add("text-primary");
                            document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
                            document.querySelector('#lblFlights_flight').click();

                            if (sessionStorage.getItem("tripType") === 'oneway') {
                                document.querySelector('#oneway_flight').classList.add("active");
                                document.querySelector('#oneway_flight').classList.add("show");
                                document.querySelector('#oneway-tab').click();
                            }
                            else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                                document.querySelector('#roundtrip').classList.add("active");
                                document.querySelector('#roundtrip').classList.add("show");
                                document.querySelector('#roundtrip-tab').click();
                            }
                            else if (sessionStorage.getItem("tripType") === 'multi_city') {
                                document.querySelector('#multi_city').classList.add("active");
                                document.querySelector('#multi_city').classList.add("show");
                                document.querySelector('#multi_city-tab').click();
                            }
                            else {
                                document.querySelector('#roundtrip').classList.add("active");
                                document.querySelector('#roundtrip').classList.add("show");
                                document.querySelector('#roundtrip-tab').click();
                            }
                        }
                    }
                    else if (sessionStorage.getItem("serviceType") === 'lblFlightPlusHotel') {
                        if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                            document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                            document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
                            document.querySelector('#lblFlightPlusHotel_flight').click();

                            document.querySelector('#roundtrip').classList.add("active");
                            document.querySelector('#roundtrip').classList.add("show");
                            document.querySelector('#roundtrip-tab').click();
                        }
                    }
                }
                else {
                    // if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                    //     document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                    //     document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
                    //     document.querySelector('#lblFlightPlusHotel_flight').click();

                    //     document.querySelector('#roundtrip').classList.add("active");
                    //     document.querySelector('#roundtrip').classList.add("show");
                    //     document.querySelector('#roundtrip-tab').click();
                    // }

                    if (document.querySelector("#lblFlights_flight") !== null) {
                        if (document.querySelector("#divAddHotel") !== null) {
                            document.querySelector("#divAddHotel").style.display = "none";
                        }
                        document.querySelector("#lblFlights_flight").classList.add("text-primary");
                        document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
                        document.querySelector('#lblFlights_flight').click();

                        document.querySelector('#roundtrip').classList.add("active");
                        document.querySelector('#roundtrip').classList.add("show");
                        document.querySelector('#roundtrip-tab').click();
                    }
                }
            }
            else {
                if (document.querySelector("#divAddHotel") !== null) {
                    document.querySelector("#divAddHotel").style.display = "none";
                }
                setTripType("roundtrip");
                sessionStorage.setItem('tripType', "roundtrip");

                if (document.querySelector('#roundtrip') !== null) {
                    document.querySelector('#roundtrip').classList.add("active");
                    document.querySelector('#roundtrip').classList.add("show");
                }
                document.querySelector('#roundtrip-tab').click();

                // setTripType("oneway");
                // sessionStorage.setItem('tripType', "oneway");
                // document.querySelector('#oneway_flight').classList.add("active");
                // document.querySelector('#oneway_flight').classList.add("show");
                // document.querySelector('#oneway-tab').click();
            }
        }

    }, [])

    const controlHandlerFightSearch = (event) => {
        console.log(1)
        console.log(event.target.id)
        console.log(sessionStorage.getItem('serviceType'))
        if (event.target.id === 'lblFlights_flight') {
            sessionStorage.setItem("serviceType", "lblFlights");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "none";
            }

            document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
            document.querySelector("#lblFlights_flight").classList.remove("text-primary");
            document.querySelector('#divTripType').classList.remove("d-none");

            if (document.querySelector("#lblHotel_flight") !== null) {
                document.querySelector("#lblHotel_flight").classList.add("text-primary");
                document.querySelector("#lblHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
            }

            document.querySelector('#oneway-tab').classList.remove("disabled");
            document.querySelector('#multi_city-tab').classList.remove("disabled");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel_flight') {
            sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "";
            }

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("text-primary");
                document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_flight") !== null) {
                document.querySelector("#lblHotel_flight").classList.add("text-primary");
                document.querySelector("#lblHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
            }

            document.querySelector('#divTripType').classList.add("d-none");
            document.querySelector('#roundtrip-tab').click();

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHotel_flight') {
            sessionStorage.setItem("serviceType", "lblHotel");
            localStorage.removeItem("AddHotel");
            setLoadComponent('Hotel');

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("text-primary");
                document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
                document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
            }
            if (document.querySelector("#lblHotel_flight") !== null) {
                document.querySelector("#lblHotel_flight").classList.remove("text-primary");
                document.querySelector("#lblHotel_flight").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_hotel") !== null) {
                document.querySelector("#lblSightseeing_hotel").classList.add("text-primary");
                document.querySelector("#lblSightseeing_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_hotel") !== null) {
                document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
                document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.remove('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblSightseeing_flight') {
            sessionStorage.setItem("serviceType", "lblSightseeing");
            setLoadComponent('Sightseeing');

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("text-primary");
                document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("text-primary");
                document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.remove("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_sightseeing") !== null) {
                document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.remove('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHolidays_flight') {
            // sessionStorage.setItem("serviceType", "lblHolidays");
            // setLoadComponent('Holidays');

            // if (document.querySelector("#lblFlights_flight") !== null) {
            //     document.querySelector("#lblFlights_flight").classList.add("text-primary");
            //     document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_hotel") !== null) {
            //     document.querySelector("#lblHotel_hotel").classList.add("text-primary");
            //     document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
            //     document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing_flight") !== null) {
            //     document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
            //     document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            // }            

            // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
            //     document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_sightseeing") !== null) {
            //     document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblFlights_sightseeing") !== null) {
            //     document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_flight") !== null) {
            //     document.querySelector("#lblHolidays_flight").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_flight").classList.add("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_sightseeing") !== null) {
            //     document.querySelector("#lblHolidays_sightseeing").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_sightseeing").classList.add("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_holidays") !== null) {
            //     document.querySelector("#lblHolidays_holidays").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_holidays").classList.add("flight-active-tab");
            // }

            // if (document.querySelector('.show_flight') !== null) {
            //     document.querySelector('.show_flight').classList.add('d-none');
            // }
            // if (document.querySelector('.show_hotel') !== null) {
            //     document.querySelector('.show_hotel').classList.add('d-none');
            // }
            // if (document.querySelector('.show_sightseeing') !== null) {
            //     document.querySelector('.show_sightseeing').classList.add('d-none');
            // }
            // if (document.querySelector('.show_holidays') !== null) {
            //     document.querySelector('.show_holidays').classList.remove('d-none');
            // }

            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
    }

    if (sessionStorage.getItem('serviceType') === 'lblFlights') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblFlightPlusHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.remove('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblSightseeing') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.remove('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHolidays') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.remove('d-none');
        }
    }

    return (<Fragment>
        <div className="row position-relative show_flight">
            <label for="" className="h5 mb-3 me-3 text-primary" ><i className="fas fa-globe-asia"></i> Search</label>
            <div className="col-lg-12 flight-search-tabs">
                <div className='text-sm-center1'>
                    <span>
                        {
                            (checkSecurityDetails("Book", "Flight") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                            <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights_flight' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                        }
                        {
                            (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                && getConfigurationByBoolen("ALLOW_FLIGHT")) ?
                                <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel_flight' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                <label id='lblFlightPlusHotel_flight' ></label>
                        }
                        {
                            (checkSecurityDetails("Book", "Hotel") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel_flight' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                        }
                        {
                            (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing_flight' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                        }
                        {
                            getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays_flight' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                        }
                    </span>
                </div>
                <div className="flight_categories_search" id='divTripType'>
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="oneway-tab" data-bs-toggle="tab"
                                data-bs-target="#oneway_flight" type="button" role="tab"
                                aria-controls="oneway_flight" aria-selected="false" onClick={tripTypeHandler}>
                                {t('oneway')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="roundtrip-tab" data-bs-toggle="tab"
                                data-bs-target="#roundtrip" type="button" role="tab"
                                aria-controls="roundtrip"
                                aria-selected="true" onClick={tripTypeHandler}>{t('roundtrip')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                data-bs-target="#multi_city" type="button" role="tab"
                                aria-controls="multi_city" aria-selected="false" onClick={tripTypeHandler}>
                                {t('multicity')}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="myTabContent1">
                <div className="tab-pane fade " id="oneway_flight" role="tabpanel"
                    aria-labelledby="oneway-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="row">
                                    {
                                        (tripType === 'oneway') &&
                                        <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                    }

                                </div>
                                {
                                    (tripType === 'oneway') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} />
                                }

                                <div className="top_form_search_button mt-3">
                                    <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                        {t('search')}
                                    </button>
                                    {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">New</button> */}
                                    {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">Reset</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="roundtrip" role="tabpanel"
                    aria-labelledby="roundtrip-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="row">
                                    {
                                        (tripType === 'roundtrip') &&
                                        <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                    }

                                </div>
                                {
                                    (tripType === 'roundtrip') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} />
                                }
                                {/* User Story 1070: Change in Search for Single PNR */}
                                <div className="top_form_search_butto mx-auto text-center pt-1">
                                    <div className='row mt-2'>
                                        <div className=' mx-auto col-lg-2 col-md-4 col-sm-8 col-8'>
                                            <div className='row mx-auto'>
                                                {
                                                    getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen('ALLOW_SINGLE_PNR') === true &&
                                                    <div id='divAddHotel' className='text-start me-1 col align-self-center search-add-hotel-text mt-2'>
                                                        {
                                                            (tripType === 'roundtrip') &&
                                                            <span>
                                                                {/* <span className='me-2'><i className="fas fa-certificate"></i></span> */}
                                                                <a href='javascript:void(0)' className='form-check green-check-opacity'>
                                                                    <span>
                                                                        <input className="form-check-input" type="checkbox" id="addHotel"
                                                                            onChange={(e) => onAddhotel(e)} disabled="true"></input>
                                                                        <label className="form-check-label mb-0 text-success fw-bold" for="addHotel">
                                                                            {t('addhotel')}
                                                                        </label>
                                                                    </span>
                                                                </a>
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-sm-12'></div>
                                        {
                                            getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen('ALLOW_SINGLE_PNR') === true &&
                                            <div className='col-lg-6 col-md-12 col-sm-12 col-12 mx-auto'>
                                                <button className='btn text-white mt-2 gt-main-btn btn_md aaaa' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>
                                            </div>
                                        }
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12'></div>

                                        {
                                            getConfigurationByBoolen('ALLOW_SINGLE_PNR') === null || getConfigurationByBoolen('ALLOW_SINGLE_PNR') === false &&
                                            <div className='mx-auto'>
                                                <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade " id="multi_city" role="tabpanel"
                    aria-labelledby="multi_city-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="multi_city_form_wrapper">
                                    <div className="multi_city_form">
                                        <div className="row">
                                            {
                                                (tripType === 'multi_city') &&
                                                <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                            }
                                        </div>
                                    </div>
                                    <div className="multi_city_form">
                                        <div className="row">
                                            {
                                                (tripType === 'multi_city') &&
                                                <FlightSearchControlMainPanel controlId={2} tripType={tripType} />
                                            }
                                            {
                                                (isAddedThree) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowThree'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedThree === true) &&
                                                <FlightSearchControlMainPanel controlId={3} tripType={tripType} />
                                            }
                                            {
                                                (isAddedFour) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowFour'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedFour === true) &&
                                                <FlightSearchControlMainPanel controlId={4} tripType={tripType} />
                                            }
                                            {
                                                (isAddedFive) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowFive'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedFive === true) &&
                                                <FlightSearchControlMainPanel controlId={5} tripType={tripType} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="add_multy_form">
                                            <button id='showMultiCityRow' type="button" onClick={showMultiCityRow}>{t('addanotherflight')}</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (tripType === 'multi_city') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} />
                                }
                                <div className="top_form_search_button">
                                    <button className='btn text-white mt-4 gt-main-btn btn_md bbb' onClick={onSearchHandler}>
                                        {t('search')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            loadComponent === 'Hotel' &&
            <SearchHotelPartial></SearchHotelPartial>
        }
        {
            loadComponent === 'Sightseeing' &&
            <SearchSightseeingPartial></SearchSightseeingPartial>
        }
        {/* {
            loadComponent === 'Holidays' &&
            <SearchHolidaysPartial></SearchHolidaysPartial>
        } */}
    </Fragment>)
};

export default SearchFlightPartial;
