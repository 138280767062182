import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Dropdown from 'react-select';
import { useTranslation } from 'react-i18next';
import { sortData, sortFlightResult, filterFlightResults } from '../../../utils';
import FlightItem from './../../../components/Common/Flight/FlightItem';
import { configureStore } from '@reduxjs/toolkit';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import sorter from "sort-nested-json";
import TimeRangeSlider from 'react-time-range-slider';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { compress } from '@amoutonbrady/lz-string';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const FlightList = (props) => {
    const [sortValue, setSortValue] = useState('');
    const { t } = useTranslation(['translation.SearchFlight']);
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [perPersonFare, setPerPersonFare] = useState([]);
    const [minPerPersonPrice, setMinPerPersonPrice] = useState();
    const [maxPerPersonPrice, setMaxPerPersonPrice] = useState();
    const [fareTypeList, setFareTypeList] = useState([]);
    const [refundableTypeList, setRefundableTypeList] = useState([]);
    const [lastFromControlId, setLastFromControlId] = useState();
    const [minOutboundTakeOffTime, setMinOutboundTakeOffTime] = useState();
    const [maxOutboundTakeOffTime, setMaxOutboundTakeOffTime] = useState();
    const [outboundTakeOffTime, setOutboundTakeOffTime] = useState(['', '']);
    const [outboundTakeOffTimeSliderValue, setOutboundTakeOffTimeSliderValue] = useState({
        start: "00:00",
        end: "23:59"
    });
    const [minOutboundLandingTime, setMinOutboundLandingTime] = useState();
    const [maxOutboundLandingTime, setMaxOutboundLandingTime] = useState();
    const [outboundLandingTime, setOutboundLandingTime] = useState(['', '']);
    const [outboundLandingTimeSliderValue, setOutboundLandingTimeSliderValue] = useState({
        start: "00:00",
        end: "23:59"
    });
    const [outboundDuration, setOutboundDuration] = useState([]);
    const [minOutboundDuration, setMinOutboundDuration] = useState();
    const [maxOutboundDuration, setMaxOutboundDuration] = useState();
    const [outboundStopoverDuration, setOutboundStopoverDuration] = useState([]);
    const [minOutboundStopoverDuration, setMinOutboundStopoverDuration] = useState();
    const [maxOutboundStopoverDuration, setMaxOutboundStopoverDuration] = useState();

    const [minInboundTakeOffTime, setMinInboundTakeOffTime] = useState();
    const [maxInboundTakeOffTime, setMaxInboundTakeOffTime] = useState();
    const [inboundTakeOffTime, setInboundTakeOffTime] = useState(['', '']);
    const [inboundTakeOffTimeSliderValue, setInboundTakeOffTimeSliderValue] = useState({
        start: "00:00",
        end: "23:59"
    });
    const [minInboundLandingTime, setMinInboundLandingTime] = useState();
    const [maxInboundLandingTime, setMaxInboundLandingTime] = useState();
    const [inboundLandingTime, setInboundLandingTime] = useState(['', '']);
    const [inboundLandingTimeSliderValue, setInboundLandingTimeSliderValue] = useState({
        start: "00:00",
        end: "23:59"
    });
    const [inboundDuration, setInboundDuration] = useState([]);
    const [minInboundDuration, setMinInboundDuration] = useState();
    const [maxInboundDuration, setMaxInboundDuration] = useState();
    const [inboundStopoverDuration, setInboundStopoverDuration] = useState([]);
    const [minInboundStopoverDuration, setMinInboundStopoverDuration] = useState();
    const [maxInboundStopoverDuration, setMaxInboundStopoverDuration] = useState();
    const [isOutboundSegment, setIsOutboundSegment] = useState(true);
    const [segmentList, setSegmentList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [airlineList, setAirlineList] = useState([]);
    const [currencyCode, setCurrencyCode] = useState();
    const [allianceList, setAllianceList] = useState([]);
    //const [sourceList, setSourceList] = useState([]);
    //const [officeList, setOfficeList] = useState([]);
    const [numberOfStopsList, setNumberOfStopsList] = useState([]);
    const [noBaggageList, setNoBaggageList] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [commissionList, setCommissionList] = useState([]);

    let sortOptions = [];
    if (sessionStorage.getItem('tripType') === 'roundtrip') {
        sortOptions = [
            { value: '1', label: 'Price' },
            { value: '2', label: 'Number of Stops' },
            { value: '3', label: 'Outbound Departure Time' },
            { value: '4', label: 'Outbound Arrival Time' },
            { value: '5', label: 'Outbound Duration' },
            { value: '6', label: 'Outbound Airline' },
            { value: '7', label: 'Inbound Arrival Time' },
            { value: '8', label: 'Inbound Duration' }
        ];
    }
    else {
        sortOptions = [
            { value: '1', label: 'Price' },
            { value: '2', label: 'Number of Stops' },
            { value: '3', label: 'Outbound Departure Time' },
            { value: '4', label: 'Outbound Arrival Time' },
            { value: '5', label: 'Outbound Duration' },
            { value: '6', label: 'Outbound Airline' }
        ];
    }

    const sortChangeHandler = (event) => {
        setSortValue(event.value);
        setSortBy('asc');
        let pricedItineraryTemp = sortFlightResult("1", props.searchFlight.pricedItinerary, 'asc');
        pricedItineraryTemp = sortFlightResult(event.value, pricedItineraryTemp, 'asc');
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const sortAscChangeHandler = (event) => {
        ////setSortValue(event.value);
        setSortBy('asc');
        let pricedItineraryTemp = sortFlightResult("1", props.searchFlight.pricedItinerary, 'asc');
        pricedItineraryTemp = sortFlightResult(sortValue, pricedItineraryTemp, 'asc');
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const sortDescChangeHandler = (event) => {
        //setSortValue(event.value);
        setSortBy('desc');
        let pricedItineraryTemp = sortFlightResult("1", props.searchFlight.pricedItinerary, 'desc');
        pricedItineraryTemp = sortFlightResult(sortValue, pricedItineraryTemp, 'desc');
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem("tripType") !== null) {
            if (sessionStorage.getItem("tripType") === "multi_city") {
                window.scrollTo({
                    top: 800,
                    behavior: "smooth",
                });
            }
            else {
                window.scrollTo({
                    top: 400,
                    behavior: "smooth",
                });
            }
        }
        else {
            window.scrollTo({
                top: 300,
                behavior: "smooth",
            });
        }

        sessionStorage.setItem("initialFlightResults", compress(JSON.stringify(props.searchFlight.pricedItinerary)));
        //sessionStorage.setItem("modifiedFlightResults", props.searchFlight);
        if (sessionStorage.getItem('tripType') === 'multi_city') {
            for (let i = 1; i <= 5; i++) {
                if (sessionStorage.getItem('txtFrom' + i) !== null) {
                    setLastFromControlId(i);
                }
            }
        }
        else {
            setLastFromControlId(1);
        }

        var numberOfStopsListTemp = [];
        var nonStopCounter = 0
        var oneStopCounter = 0
        var multiStopCounter = 0
        props.searchFlight.pricedItinerary.forEach(element1 => {
            if (element1.directionInd === "O") {
                let techStopsGroup1 = 0;

                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });


                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 0) {
                    nonStopCounter = nonStopCounter + 1;
                    if (nonStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 0);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkNonStop",
                                name: "Non-stop",
                                additionalName: "0 stops",
                                order: 0
                            });
                        }
                    }
                }
                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 1) {
                    oneStopCounter = oneStopCounter + 1;
                    if (oneStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 1);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkOneStop",
                                name: "1 stop",
                                additionalName: "1 stop",
                                order: 1
                            });
                        }
                    }
                }
                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 > 1) {
                    multiStopCounter = multiStopCounter + 1;
                    if (multiStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 2);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkMultiStop",
                                name: "Multi stop",
                                additionalName: "1+ stop",
                                order: 2
                            });
                        }
                    }
                }
            }
            //Bug 893: Flight Result- Filters- max stop calculation logic implemented
            else if (element1.directionInd === "R") {
                let techStopsGroup1 = 0;
                let techStopsGroup2 = 0;

                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                    if (originDestinationOptionElement.group === "2") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup2 = techStopsGroup2 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });

                let depStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1;
                let arrivalStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1;
                let higherStopValue = 0;
                if (parseInt(depStopsCount) > parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(depStopsCount);
                }
                else if (parseInt(depStopsCount) < parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(arrivalStopsCount);
                }
                else {
                    higherStopValue = parseInt(depStopsCount);
                }

                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 0 && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 === 0) {
                    nonStopCounter = nonStopCounter + 1;
                    if (nonStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 0);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkNonStop",
                                name: "Non-stop",
                                additionalName: "0 stops",
                                order: 0
                            });
                        }
                    }
                }
                if ((element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 > 0 || element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 > 0) && (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 <= higherStopValue && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 <= higherStopValue && higherStopValue <= 1)) {
                    oneStopCounter = oneStopCounter + 1;
                    if (oneStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 1);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkOneStop",
                                name: "1 stop",
                                additionalName: "1 stop",
                                order: 1
                            });
                        }
                    }
                }
                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 <= higherStopValue && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 <= higherStopValue && higherStopValue > 1) {
                    multiStopCounter = multiStopCounter + 1;
                    if (multiStopCounter === 1) {
                        const found = numberOfStopsListTemp.find(el => el.order === 2);
                        if (!found) {
                            numberOfStopsListTemp.push({
                                id: "chkMultiStop",
                                name: "Multi stop",
                                additionalName: "1+ stop",
                                order: 2
                            });
                        }
                    }
                }
            }
            else if (element1.directionInd === "M") {
                let segmentCounter = 0;
                let isSameNumberOfStops_NonStop = 0;
                let isSameNumberOfStops_OneStop = 0;
                let isSameNumberOfStops_MultiStop = 0;
                let higherStopValue = 0;
                let depStopsCount = [];

                for (let index = 1; index < 6; index++) {
                    let techStopsGroup1 = 0;
                    element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                        if (originDestinationOptionElement.group === index.toString()) {
                            originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                                if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                    techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                                }
                            });
                        }
                    });

                    if (sessionStorage.getItem("txtFrom" + index.toString()) !== null) {
                        depStopsCount.push(parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1));
                    }
                }

                higherStopValue = depStopsCount.reduce((a, b) => Math.max(a, b), -Infinity);

                for (let index = 1; index < 6; index++) {
                    if (sessionStorage.getItem("txtFrom" + index.toString()) !== null) {
                        segmentCounter = index;
                        let techStopsGroup1 = 0;

                        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                            if (originDestinationOptionElement.group === index.toString()) {
                                originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                                    if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                        techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                                    }
                                });
                            }
                        });

                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 === 0) {
                            isSameNumberOfStops_NonStop = isSameNumberOfStops_NonStop + 1;
                        }
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 <= higherStopValue && higherStopValue <= 1) {
                            isSameNumberOfStops_OneStop = isSameNumberOfStops_OneStop + 1;
                        }
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 <= higherStopValue && higherStopValue > 1) {
                            isSameNumberOfStops_MultiStop = isSameNumberOfStops_MultiStop + 1;
                        }
                    }
                }

                if (isSameNumberOfStops_NonStop === segmentCounter) {
                    const found = numberOfStopsListTemp.find(el => el.order === 0);
                    if (!found) {
                        numberOfStopsListTemp.push({
                            id: "chkNonStop",
                            name: "Non-stop",
                            additionalName: "0 stops",
                            order: 0
                        });
                    }
                }

                if (isSameNumberOfStops_OneStop === segmentCounter) {
                    const found = numberOfStopsListTemp.find(el => el.order === 1);
                    if (!found) {
                        numberOfStopsListTemp.push({
                            id: "chkOneStop",
                            name: "1 stop",
                            additionalName: "1 stop",
                            order: 1
                        });
                    }
                }

                if (isSameNumberOfStops_MultiStop === segmentCounter) {
                    const found = numberOfStopsListTemp.find(el => el.order === 2);
                    if (!found) {
                        numberOfStopsListTemp.push({
                            id: "chkMultiStop",
                            name: "Multi stop",
                            additionalName: "1+ stop",
                            order: 2
                        });
                    }
                }
            }

        });
        numberOfStopsListTemp = sorter.sort(numberOfStopsListTemp).asc("order");
        setNumberOfStopsList(numberOfStopsListTemp);

        var fareTypeListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
                element2.fareInfos.fareInfo.forEach(element3 => {
                    const found = fareTypeListTemp.find(el => el.fareType === element3.fareType);
                    if (!found) {
                        fareTypeListTemp.push({
                            id: `chk${element3.fareType.replace(' ', '')}`,
                            fareType: element3.fareType
                        });
                    }
                });
            });
        });
        setFareTypeList(fareTypeListTemp);

        var refundableTypeListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'ADT').forEach(element2 => {
                element2.fareInfos.fareInfo.forEach(element3 => {
                    if (element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0] === undefined) {
                        const found = refundableTypeListTemp.find(el => el.refundableType === 'Non Refundable');
                        if (!found) {
                            refundableTypeListTemp.push({
                                id: `chkNonRefundable`,
                                refundableType: 'Non Refundable'
                            });
                        }
                    }
                    else if (parseFloat(element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0]) === 0) {
                        const found = refundableTypeListTemp.find(el => el.refundableType === 'Fully Refundable');
                        if (!found) {
                            refundableTypeListTemp.push({
                                id: `chkFullyRefundable`,
                                refundableType: 'Fully Refundable'
                            });
                        }
                    }
                    else if (parseFloat(element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0]) > 0) {
                        const found = refundableTypeListTemp.find(el => el.refundableType === 'Partially Refundable');
                        if (!found) {
                            refundableTypeListTemp.push({
                                id: `chkPartiallyRefundable`,
                                refundableType: 'Partially Refundable'
                            });
                        }
                    }

                });
            });
        });
        setRefundableTypeList(refundableTypeListTemp);

        // var noBaggageListTemp = [];
        // props.searchFlight.pricedItinerary.forEach(element1 => {
        //     element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
        //         element2.segmentRefDetail.forEach(element3 => {
        //             element3.pax.forEach(element4 => {
        //                 if (element4.baggageAllowance !== undefined) {
        //                     element4.baggageAllowance.forEach(element5 => {
        //                         if (element5.numberOfPieces !== undefined) {
        //                             if (element5.numberOfPieces === "0") {
        //                                 const found = noBaggageListTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
        //                                 if (!found) {
        //                                     noBaggageListTemp.push({
        //                                         sequenceNumber: element1.sequenceNumber
        //                                     });
        //                                 }
        //                             }
        //                         }
        //                         else if (element5.maxWeight !== undefined) {
        //                             if (element5.maxWeight === "0") {
        //                                 const found = noBaggageListTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
        //                                 if (!found) {
        //                                     noBaggageListTemp.push({
        //                                         sequenceNumber: element1.sequenceNumber
        //                                     });
        //                                 }
        //                             }
        //                         }
        //                     });
        //                 }
        //             });


        //         });
        //     });
        // });
        // setNoBaggageList(noBaggageListTemp);

        var perPersonPriceList = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            const found = perPersonPriceList.find(el => el.perPersonPrice === element1.airItineraryPricingInfo.totalFare.amount);
            if (!found) {
                perPersonPriceList.push({
                    perPersonPrice: parseFloat(element1.airItineraryPricingInfo.totalFare.amount)
                });
                setCurrencyCode(element1.airItineraryPricingInfo.totalFare.currencyCode);
            }
        });

        let perPersonPriceListSorted = sorter.sort(perPersonPriceList).asc("perPersonPrice");
        setMinPerPersonPrice(parseFloat(perPersonPriceListSorted[0].perPersonPrice));
        setMaxPerPersonPrice(Math.ceil(perPersonPriceListSorted[perPersonPriceListSorted.length - 1].perPersonPrice));

        document.querySelector('#dvOutboundTimeSliders').style.display = "";
        document.querySelector('#dvInboundTimeSliders').style.display = "none";

        var outboundTakeOffTimeList = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O" || element2.directionInd === "M") {
                    element2.flightSegment.forEach(element3 => {
                        const found = outboundTakeOffTimeList.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            outboundTakeOffTimeList.push({
                                departureTime: element3.departureTime,
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    });
                }
            });
        });

        let outboundTakeOffTimeListSorted = sorter.sort(outboundTakeOffTimeList).asc("departureTime");
        setMinOutboundTakeOffTime(outboundTakeOffTimeListSorted[0].departureTime.toString().substring(0, 2) + ":" + outboundTakeOffTimeListSorted[0].departureTime.toString().substring(2, 4));
        setMaxOutboundTakeOffTime(outboundTakeOffTimeListSorted[outboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(0, 2) + ":" + outboundTakeOffTimeListSorted[outboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(2, 4));
        setOutboundTakeOffTimeSliderValue({
            start: outboundTakeOffTimeListSorted[0].departureTime.toString().substring(0, 2) + ":" + outboundTakeOffTimeListSorted[0].departureTime.toString().substring(2, 4),
            end: outboundTakeOffTimeListSorted[outboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(0, 2) + ":" + outboundTakeOffTimeListSorted[outboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(2, 4)
        });

        var outboundLandingTimeList = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            let rowCounter = 1;
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O") {
                    element2.flightSegment.forEach(element3 => {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "O").length === rowCounter) {
                            const found = outboundLandingTimeList.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                outboundLandingTimeList.push({
                                    arrivalTime: element3.arrivalTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                    rowCounter = rowCounter + 1;
                }
                else if (element2.directionInd === "M") {
                    element2.flightSegment.forEach(element3 => {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "M").length === rowCounter) {
                            const found = outboundLandingTimeList.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                outboundLandingTimeList.push({
                                    arrivalTime: element3.arrivalTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                    rowCounter = rowCounter + 1;
                }
            });
        });

        let outboundLandingTimeListSorted = sorter.sort(outboundLandingTimeList).asc("arrivalTime");
        setMinOutboundLandingTime(outboundLandingTimeListSorted[0].arrivalTime.toString().substring(0, 2) + ":" + outboundLandingTimeListSorted[0].arrivalTime.toString().substring(2, 4));
        setMaxOutboundLandingTime(outboundLandingTimeListSorted[outboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(0, 2) + ":" + outboundLandingTimeListSorted[outboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(2, 4));
        setOutboundLandingTimeSliderValue({
            start: outboundLandingTimeListSorted[0].arrivalTime.toString().substring(0, 2) + ":" + outboundLandingTimeListSorted[0].arrivalTime.toString().substring(2, 4),
            end: outboundLandingTimeListSorted[outboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(0, 2) + ":" + outboundLandingTimeListSorted[outboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(2, 4)
        });

        var outboundDurationList = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O") {
                    const found = outboundDurationList.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        outboundDurationList.push({
                            totalOutBoundDuration: parseInt(element1.airItinerary.totalOutBoundDuration.trim()),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
                else if (element2.directionInd === "M") {
                    const found = outboundDurationList.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        outboundDurationList.push({
                            totalOutBoundDuration: parseInt(element1.airItinerary.totalItineraryDuration.trim()),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });

        let outboundDurationListSorted = sorter.sort(outboundDurationList).asc("totalOutBoundDuration");
        setMinOutboundDuration(Math.round(outboundDurationListSorted[0].totalOutBoundDuration));
        setMaxOutboundDuration(Math.round(outboundDurationListSorted[outboundDurationListSorted.length - 1].totalOutBoundDuration));

        var outboundStopoverDurationList = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O" || element2.directionInd === "M") {
                    const found = outboundStopoverDurationList.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        outboundStopoverDurationList.push({
                            totalOutBoundDuration: parseInt(element1.airItinerary.totalOutBoundDuration.trim()),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });

        let outboundStopoverDurationListSorted = sorter.sort(outboundStopoverDurationList).asc("totalOutBoundDuration");
        setMinOutboundStopoverDuration(Math.round(outboundStopoverDurationListSorted[0].totalOutBoundDuration));
        setMaxOutboundStopoverDuration(Math.round(outboundStopoverDurationListSorted[outboundStopoverDurationListSorted.length - 1].totalOutBoundDuration));

        if (sessionStorage.getItem('tripType') === 'roundtrip') {
            var inboundTakeOffTimeList = [];
            props.searchFlight.pricedItinerary.forEach(element1 => {
                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                    if (element2.directionInd === "I") {
                        element2.flightSegment.forEach(element3 => {
                            const found = inboundTakeOffTimeList.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                inboundTakeOffTimeList.push({
                                    departureTime: element3.departureTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        });
                    }
                });
            });

            let inboundTakeOffTimeListSorted = sorter.sort(inboundTakeOffTimeList).asc("departureTime");
            if (inboundTakeOffTimeListSorted.length > 0) {
                setMinInboundTakeOffTime(inboundTakeOffTimeListSorted[0].departureTime.toString().substring(0, 2) + ":" + inboundTakeOffTimeListSorted[0].departureTime.toString().substring(2, 4));
                setMaxInboundTakeOffTime(inboundTakeOffTimeListSorted[inboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(0, 2) + ":" + inboundTakeOffTimeListSorted[inboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(2, 4));
                setInboundTakeOffTimeSliderValue({
                    start: inboundTakeOffTimeListSorted[0].departureTime.toString().substring(0, 2) + ":" + inboundTakeOffTimeListSorted[0].departureTime.toString().substring(2, 4),
                    end: inboundTakeOffTimeListSorted[inboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(0, 2) + ":" + inboundTakeOffTimeListSorted[inboundTakeOffTimeListSorted.length - 1].departureTime.toString().substring(2, 4)
                });
            }

            var inboundLandingTimeList = [];
            props.searchFlight.pricedItinerary.forEach(element1 => {
                let rowCounter = 1;
                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                    if (element2.directionInd === "I") {
                        element2.flightSegment.forEach(element3 => {
                            if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "I").length === rowCounter) {
                                const found = inboundLandingTimeList.find(el => el.sequenceNumber === element1.sequenceNumber);
                                if (!found) {
                                    inboundLandingTimeList.push({
                                        arrivalTime: element3.arrivalTime,
                                        sequenceNumber: element1.sequenceNumber
                                    });
                                }
                            }
                        });
                        rowCounter = rowCounter + 1;
                    }
                });
            });

            let inboundLandingTimeListSorted = sorter.sort(inboundLandingTimeList).asc("arrivalTime");
            if (inboundLandingTimeListSorted.length > 0) {
                setMinInboundLandingTime(inboundLandingTimeListSorted[0].arrivalTime.toString().substring(0, 2) + ":" + inboundLandingTimeListSorted[0].arrivalTime.toString().substring(2, 4));
                setMaxInboundLandingTime(inboundLandingTimeListSorted[inboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(0, 2) + ":" + inboundLandingTimeListSorted[inboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(2, 4));
                setInboundLandingTimeSliderValue({
                    start: inboundLandingTimeListSorted[0].arrivalTime.toString().substring(0, 2) + ":" + inboundLandingTimeListSorted[0].arrivalTime.toString().substring(2, 4),
                    end: inboundLandingTimeListSorted[inboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(0, 2) + ":" + inboundLandingTimeListSorted[inboundLandingTimeListSorted.length - 1].arrivalTime.toString().substring(2, 4)
                });
            }

            var inboundDurationList = [];
            props.searchFlight.pricedItinerary.forEach(element1 => {
                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                    if (element2.directionInd === "I") {
                        const found = inboundDurationList.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            inboundDurationList.push({
                                totalInBoundDuration: parseInt(element1.airItinerary.totalInBoundDuration.trim()),
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                });
            });

            let inboundDurationListSorted = sorter.sort(inboundDurationList).asc("totalInBoundDuration");
            if (inboundDurationListSorted.length > 0) {
                setMinInboundDuration(Math.round(inboundDurationListSorted[0].totalInBoundDuration));
                setMaxInboundDuration(Math.round(inboundDurationListSorted[inboundDurationListSorted.length - 1].totalInBoundDuration));
            }

            var inboundStopoverDurationList = [];
            props.searchFlight.pricedItinerary.forEach(element1 => {
                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                    if (element2.directionInd === "I") {
                        const found = inboundStopoverDurationList.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            inboundStopoverDurationList.push({
                                totalInBoundDuration: parseInt(element1.airItinerary.totalInBoundDuration.trim()),
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                });
            });

            let inboundStopoverDurationListSorted = sorter.sort(inboundStopoverDurationList).asc("totalInBoundDuration");
            if (inboundStopoverDurationListSorted.length > 0) {
                setMinInboundStopoverDuration(Math.round(inboundStopoverDurationListSorted[0].totalInBoundDuration));
                setMaxInboundStopoverDuration(Math.round(inboundStopoverDurationListSorted[inboundStopoverDurationListSorted.length - 1].totalInBoundDuration));
            }
        }

        var segmentListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    element3.equipment.forEach(element4 => {
                        if (element4.airEquipType === 'BUS') {
                            const found = segmentListTemp.find(el => el.airEquipType === "Bus");
                            if (!found) {
                                segmentListTemp.push({
                                    id: "chkSegmentBus",
                                    airEquipType: "Bus"
                                });
                            }
                        }
                        else {
                            const found = segmentListTemp.find(el => el.airEquipType === "Air");
                            if (!found) {
                                segmentListTemp.push({
                                    id: "chkSegmentAir",
                                    airEquipType: "Air"
                                });
                            }
                        }
                    });
                });
            });
        });
        setSegmentList(segmentListTemp);

        var classListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    element3.bookingClassAvails.forEach(element4 => {
                        const found = classListTemp.find(el => el.cabinName === element4.cabinName);
                        if (!found) {
                            var orderTemp = 0;
                            if (element4.cabinName.toString().toLowerCase().replace(' ', '') === "economy") {
                                orderTemp = 0;
                            }
                            else if (element4.cabinName.toString().toLowerCase().replace(' ', '') === "premiumeconomy") {
                                orderTemp = 1;
                            }
                            else if (element4.cabinName.toString().toLowerCase().replace(' ', '') === "businessclass") {
                                orderTemp = 2;
                            }
                            else if (element4.cabinName.toString().toLowerCase().replace(' ', '') === "firstclass") {
                                orderTemp = 3;
                            }

                            classListTemp.push({
                                id: `chkCabin${element4.cabinName}`,
                                cabinName: element4.cabinName,
                                order: orderTemp
                            });
                        }
                    });
                });
            });
        });
        classListTemp = sorter.sort(classListTemp).asc("order");
        setClassList(classListTemp);

        var airlineListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    const found = airlineListTemp.find(el => el.name === element3.marketingAirline.name);
                    if (!found) {
                        var airlineCounterTemp = [];
                        props.searchFlight.pricedItinerary.forEach(elementNested1 => {
                            elementNested1.airItinerary.originDestinationOptions.originDestinationOption.forEach(elementNested2 => {
                                elementNested2.flightSegment.forEach(elementNested3 => {
                                    const found = airlineCounterTemp.find(el => el.sequenceNumber === elementNested1.sequenceNumber);
                                    if (!found) {
                                        if (elementNested3.marketingAirline.name === element3.marketingAirline.name) {
                                            airlineCounterTemp.push({
                                                sequenceNumber: elementNested1.sequenceNumber
                                            });
                                        }
                                    }
                                });
                            });
                        });
                        if (element3.marketingAirline.name !== undefined) {
                            airlineListTemp.push({
                                id: `chkAirline${element3.marketingAirline.name.replace(' ', '')}`,
                                name: element3.marketingAirline.name,
                                count: airlineCounterTemp.length
                            });
                        }
                    }
                });
            });
        });
        airlineListTemp = sorter.sort(airlineListTemp).asc("name");
        setAirlineList(airlineListTemp);

        var allianceListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    if (element3.alliances !== undefined && element3.alliances !== null) {
                        const found = allianceListTemp.find(el => el.name === element3.alliances);
                        if (!found) {
                            allianceListTemp.push({
                                id: `chkAlliance${element3.alliances.replace(' ', '')}`,
                                name: element3.alliances,
                                data: element3.alliances.trim() === "OW" ? "One World" : element3.alliances.trim() === "*S" ? "Sky Team" : element3.alliances.trim() === "*A" ? "Star" : element3.alliances
                            });
                        }
                    }
                });
            });
        });
        setAllianceList(allianceListTemp);

        // var sourceListTemp = [];
        // props.searchFlight.pricedItinerary.forEach(element1 => {
        //     const found = sourceListTemp.find(el => el.data === element1.sourceId);
        //     if (!found) {
        //         sourceListTemp.push({
        //             id: `chkSource${element1.sourceId}`,
        //             name: element1.sourceId === "1W" ? "Sabre" : element1.sourceId === "1A" ? "Amadeus" : element1.sourceId,
        //             data: element1.sourceId
        //         });
        //     }
        // });
        // setSourceList(sourceListTemp);

        // var officeListTemp = [];
        // props.searchFlight.pricedItinerary.forEach(element1 => {
        //     element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
        //         if (element2.commisionInfo !== undefined) {
        //             element2.commisionInfo.forEach(element3 => {
        //                 const found = officeListTemp.find(el => el.name === element3.sourcePCC);
        //                 if (!found) {
        //                     officeListTemp.push({
        //                         id: `chkOffice${element3.sourcePCC}`,
        //                         name: element3.sourcePCC
        //                     });
        //                 }
        //             });
        //         }
        //     });
        // });
        // setOfficeList(officeListTemp);

        var commissionListTemp = [];
        props.searchFlight.pricedItinerary.forEach(element1 => {
            element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
                if (element2.commisionInfo !== undefined) {
                    element2.commisionInfo.forEach(element3 => {
                        if (element3.earnedCommissionAmount !== undefined && element3.earnedCommissionAmount !== '' && element3.earnedCommissionAmount !== '0.00') {
                            const found = commissionListTemp.find(el => el.id === 'chkAgentCommission');
                            if (!found) {
                                commissionListTemp.push({
                                    id: `chkAgentCommission`
                                });
                            }
                        }
                    });
                }
            });
        });
        setCommissionList(commissionListTemp);

        setSortBy('asc');
        setSortValue("1");

        let pricedItineraryTemp = sortFlightResult("1", props.searchFlight.pricedItinerary, 'asc');
        props.searchFlight.pricedItinerary = pricedItineraryTemp;
        // history.push({
        //     pathname: location.pathname
        // })
    }, [])

    const onNumberOfStopsChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onFaresTypeChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onRefundableTypeChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onNoBaggageChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onPerPersonPriceChangeHandler = (event) => {
        setPerPersonFare(event);
    }

    const onApplyPerPersonPriceChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onOutboundTimeSlidersChangeHandler = (event) => {
        setIsOutboundSegment(true);
        document.querySelector('#dvOutboundTimeSliders').style.display = "";
        document.querySelector('#dvInboundTimeSliders').style.display = "none";
        document.querySelector('#ancOutboundTimeSliders').className = "btn btn-primary btn_sm mt-2";
        document.querySelector('#ancInboundTimeSliders').className = "btn btn-secondary btn_sm mt-2 ms-1";
    }

    const onInboundTimeSlidersChangeHandler = (event) => {
        setIsOutboundSegment(false);
        document.querySelector('#dvOutboundTimeSliders').style.display = "none";
        document.querySelector('#dvInboundTimeSliders').style.display = "";
        document.querySelector('#ancOutboundTimeSliders').className = "btn btn-secondary btn_sm mt-2";
        document.querySelector('#ancInboundTimeSliders').className = "btn btn-primary btn_sm mt-2 ms-1";
    }

    const outboundTakeOffTimeChangeHandler = (event) => {
        setOutboundTakeOffTimeSliderValue(event);
        setOutboundTakeOffTime(event);
    }

    const outboundLandingTimeChangeHandler = (event) => {
        setOutboundLandingTimeSliderValue(event);
        setOutboundLandingTime(event);
    }

    const onOutboundDurationChangeHandler = (event) => {
        setOutboundDuration(event);
    }

    const onOutboundStopoverDurationChangeHandler = (event) => {
        setOutboundStopoverDuration(event);
    }

    const onApplyOutboundTakeOffTimeChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const inboundTakeOffTimeChangeHandler = (event) => {
        setInboundTakeOffTimeSliderValue(event);
        setInboundTakeOffTime(event);
    }

    const inboundLandingTimeChangeHandler = (event) => {
        setInboundLandingTimeSliderValue(event);
        setInboundLandingTime(event);
    }

    const onInboundDurationChangeHandler = (event) => {
        setInboundDuration(event);
    }

    const onInboundStopoverDurationChangeHandler = (event) => {
        setInboundStopoverDuration(event);
    }

    const onApplyInboundTakeOffTimeChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onSegmentChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onClassChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onAirlineChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onAllianceChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onSourceChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    const onCommissionChangeHandler = (event) => {
        let pricedItineraryTemp = filterFlightResults('', perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy);
        props.searchFlight.pricedItinerary = pricedItineraryTemp;

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            history.push({
                pathname: location.pathname,
                search: '?flight=partial&travelreq=true&src=flight'
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    }

    function timeConvert(time) {
        if (time === undefined) {
            // time = {
            //     start: "00:00",
            //     end: "23:59"
            // }
            time = "00:00";
        }

        if (time.toString().split(':')[0].length < 2) {
            time = "0" + time.toString().split(':')[0] + ":" + time.toString().split(':')[1];
        }
        if (time.toString().split(':')[1].length < 2) {
            time = time.toString().split(':')[0] + ":0" + time.toString().split(':')[1];
        }

        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string        
    }

    function durationConvert(num) {
        var hours = Math.floor(num / 60);
        var minutes = num % 60;
        return hours + "h " + minutes + "m";
    }

    //User Story 1070: Change in Search for Single PNR
    const toggleFilter = () => {
        if (showFilter === false) {
            setShowFilter(true);
            document.querySelector('#filterBoxDivFlight').style.display = "";
            document.querySelector('#itemCardDivFlight').classList.add('col-lg-9');
            document.querySelector('#itemCardDivFlight').classList.remove('col-lg-12');
        }
        else {
            setShowFilter(false);
            document.querySelector('#filterBoxDivFlight').style.display = "none";
            document.querySelector('#itemCardDivFlight').classList.add('col-lg-12');
            document.querySelector('#itemCardDivFlight').classList.remove('col-lg-9');
        }
    }

    return (
        <Fragment>

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-2'>
                        <div><label className="h6 text-primary" for="city">Filter On</label></div>
                        <button id='btnShowFilterFlight' onClick={toggleFilter} className="btn gt-filter-btn-padding text-white gt-main-btn" ><i className="fa-solid fa-filter"></i></button>
                        <ReactTooltip
                            anchorId="btnShowFilterFlight"
                            place="bottom"
                            variant="info"
                            className="tooltip"
                            html={t('Show Filter')}
                        />
                        {/* <span className="h6 text-primary ms-1">View Filter</span> */}
                    </div>

                    <div className='col-lg-7 my-auto text-end'>
                        {/* <div><label className="h6 text-primary" for="city">Filter On</label></div> */}
                        <button id='btnShowFilterFlight' className="btn gt-filter-btn-padding  text-white gt-main-btn mt-12px d-none" ><i className="fas fa-envelope"
                        ></i> Send Proposal</button>
                        {/* <div className='h5 text-muted'>We found <span className='text-primary'>640 properties</span> availability.</div> */}
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-3">

                        <div className='row'>
                            <div className='col-md-9 col-sm-9 text-end'>
                                <label className="h6 text-primary mb-2" for="city">Sort By</label>
                            </div>
                            <div className='col-md-3 col-sm-3'>
                                <div className='text-primary float-lg-end text-end'>
                                    <span style={{ cursor: 'pointer' }} id='spnSortAsc' onClick={sortAscChangeHandler}><i className="fas fa-arrow-circle-down h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortAsc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('Low to High')}
                                    // content={t('Low to High')}
                                    />
                                    <span style={{ cursor: 'pointer' }} id='spnSortDesc' onClick={sortDescChangeHandler}><i className="fas fa-arrow-circle-up ms-1 h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortDesc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('High to Low')}
                                    // content={t('High to Low')}
                                    />
                                </div>
                            </div>
                        </div>
                        <Dropdown onChange={sortChangeHandler}
                            value={sortOptions.filter(option => option.value === sortValue)}
                            options={sortOptions}
                            textField="value"
                            dataItemKey="label"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                            placeholder=""
                        //autoFocus={props.pageFocus}
                        />
                    </div>
                </div>
                <div className="row">
                    {/* User Story 1070: Change in Search for Single PNR */}
                    <div className="col-lg-3" id='filterBoxDivFlight' style={{ display: 'none' }}>
                        <div className="left_side_search_area">
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Number of stops</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        numberOfStopsList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input numberofstopsoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onNumberOfStopsChangeHandler(e)} data={element.name} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.name} </span>
                                                        {/* <span>{element.additionalName} </span> */}
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Fare Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        fareTypeList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input faretypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onFaresTypeChangeHandler(e)} data={element.fareType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.fareType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Refundable</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        refundableTypeList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input refundabletypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onRefundableTypeChangeHandler(e)} data={element.refundableType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.refundableType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* {
                                (noBaggageList.length > 0) &&
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Baggage</h5>
                                    </div>
                                    <div className="tour_search_type">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="chkNoBaggage"
                                                onChange={(e) => onNoBaggageChangeHandler(e)} />
                                            <label className="form-check-label" htmlFor="chkNoBaggage">
                                                <span className="area_flex_one">
                                                    <span>Include flights with no bags</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Segments</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        segmentList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input segmentoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onSegmentChangeHandler(e)} data={element.airEquipType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.airEquipType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Price</h5>
                                </div>
                                <br></br>
                                {
                                    (perPersonFare[0] !== undefined) &&
                                    <span id='selectedPerPersonPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(perPersonFare[0]).split('.')[0]}-{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(perPersonFare[1]).split('.')[0]}</span>
                                }
                                <div className="filter-price">
                                    <div id="price-slider">
                                        {
                                            (minPerPersonPrice !== undefined && maxPerPersonPrice !== undefined) &&
                                            <RangeSlider id="sliderPerPersonPrice"
                                                rangeSlideDisabled={false}
                                                thumbsDisabled={[false, false]}
                                                min={minPerPersonPrice} max={maxPerPersonPrice}
                                                step={1}
                                                defaultValue={[minPerPersonPrice, maxPerPersonPrice]}
                                                //value={[perPersonFare[0], perPersonFare[1]]}
                                                disabled={false}
                                                onInput={onPerPersonPriceChangeHandler}
                                            />
                                        }
                                        <br></br>
                                        {
                                            (minPerPersonPrice !== undefined) &&
                                            <span id='minPerPersonPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(minPerPersonPrice).split('.')[0]}</span>
                                        }
                                        {
                                            (maxPerPersonPrice !== undefined) &&
                                            <span id='maxPerPersonPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(maxPerPersonPrice).split('.')[0]}</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-12 text-end'>
                                    <button className="apply text-end" type="button" id='applyPerPersonPrice'
                                        onClick={onApplyPerPersonPriceChangeHandler}>Apply</button>
                                </div>
                            </div>


                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Classes</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        classList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input classoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onClassChangeHandler(e)} data={element.cabinName} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.cabinName} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Times/Duration</h5>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {
                                            (sessionStorage.getItem('tripType') === 'roundtrip') &&
                                            <>
                                                <span><a id='ancOutboundTimeSliders' onClick={onOutboundTimeSlidersChangeHandler} className="btn btn-primary btn_sm mt-2">Outbound</a></span>
                                                <span><a id='ancInboundTimeSliders' onClick={onInboundTimeSlidersChangeHandler} className="btn btn-secondary btn_sm mt-2 ms-1">Inbound</a></span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div id='dvOutboundTimeSliders' className='row'>
                                    <div className='col-md-12'>
                                        <h6 className='text-primary fw-bold mt-2 text-16px'>Times</h6>
                                        <div className='text-14px fw-bold mt-2 text-black'>
                                            {
                                                `Take Off: ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[sessionStorage.getItem("spnFromAirportDetails1").split('-').length - 1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()}), ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[sessionStorage.getItem("spnFromAirportDetails1").split('-').length - 1].split(',')[1].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[sessionStorage.getItem("spnFromAirportDetails1").split('-').length - 1].split(',')[2].trim()})`
                                            }
                                        </div>
                                        {/* <div className='text-8px text-muted'>Fri 06:30 am - Fri 7:15</div> */}
                                        <div className='text-12px text-muted'>{`${timeConvert(outboundTakeOffTimeSliderValue.start) + " - " + timeConvert(outboundTakeOffTimeSliderValue.end)}`}</div>
                                        <div className="filter-price">
                                            <div id="time-slider">
                                                {
                                                    (minOutboundTakeOffTime !== undefined && maxOutboundTakeOffTime !== undefined) &&
                                                    <TimeRangeSlider
                                                        disabled={false}
                                                        draggableTrack={true}
                                                        maxValue={maxOutboundTakeOffTime}
                                                        minValue={minOutboundTakeOffTime}
                                                        format={24}
                                                        name={"time-range"}
                                                        onChange={outboundTakeOffTimeChangeHandler}
                                                        step={5}
                                                        value={outboundTakeOffTimeSliderValue}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minOutboundTakeOffTime'>{timeConvert(minOutboundTakeOffTime)}</span>
                                                <span id='maxOutboundTakeOffTime'>{timeConvert(maxOutboundTakeOffTime)}</span>
                                            </div>
                                        </div>
                                        <div className='text-14px fw-bold aaa text-black'>
                                            {
                                                (lastFromControlId !== undefined) &&
                                                `Landing: ${sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-')[0].trim()}), ${sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[1].trim()}(${sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnToAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[2].trim()})`
                                            }
                                        </div>
                                        <div className='text-12px text-muted'>{`${timeConvert(outboundLandingTimeSliderValue.start) + " - " + timeConvert(outboundLandingTimeSliderValue.end)}`}</div>
                                        <div className="gt-duration-slider">
                                            <div id="landing-slider">
                                                {
                                                    (minOutboundLandingTime !== undefined && maxOutboundLandingTime !== undefined) &&
                                                    <TimeRangeSlider
                                                        disabled={false}
                                                        draggableTrack={true}
                                                        maxValue={maxOutboundLandingTime}
                                                        minValue={minOutboundLandingTime}
                                                        format={24}
                                                        name={"time-range"}
                                                        onChange={outboundLandingTimeChangeHandler}
                                                        step={5}
                                                        value={outboundLandingTimeSliderValue}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minOutboundLandingTime'>{timeConvert(minOutboundLandingTime)}</span>
                                                <span id='maxOutboundLandingTime'>{timeConvert(maxOutboundLandingTime)}</span>
                                            </div>
                                        </div>
                                        <h6 className='text-primary text-16px fw-bold mt-1'>Duration</h6>
                                        <div className='text-14px fw-bold mt-2 text-black'>Flight Duration</div>
                                        {
                                            (outboundDuration[0] !== undefined) &&
                                            <div className='text-8px text-muted'>{durationConvert(outboundDuration[0])} - {durationConvert(outboundDuration[1])}</div>
                                        }
                                        <div className="gt-duration-slider">
                                            <div id="flight-slider">
                                                {
                                                    (minOutboundDuration !== undefined && maxOutboundDuration !== undefined) &&
                                                    <RangeSlider id="sliderOutboundDuration"
                                                        rangeSlideDisabled={false}
                                                        thumbsDisabled={[false, false]}
                                                        min={minOutboundDuration} max={maxOutboundDuration}
                                                        step={1}
                                                        defaultValue={[minOutboundDuration, maxOutboundDuration]}
                                                        //value={[perPersonFare[0], perPersonFare[1]]}
                                                        disabled={false}
                                                        onInput={onOutboundDurationChangeHandler}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minOutboundDuration'>{durationConvert(minOutboundDuration)}</span>
                                                <span id='maxOutboundDuration'>{durationConvert(maxOutboundDuration)}</span>
                                            </div>
                                        </div>
                                        {/* <div className='text-12px fw-bold  text-black'>Stopover Duration</div>
                                        {
                                            (outboundStopoverDuration[0] !== undefined) &&
                                            <div className='text-8px text-muted'>{durationConvert(outboundStopoverDuration[0])} - {durationConvert(outboundStopoverDuration[1])}</div>
                                        } */}
                                        {/* <div className="gt-duration-slider">
                                            <div id="stopover-slider">
                                                {
                                                    (minOutboundStopoverDuration !== undefined && maxOutboundStopoverDuration !== undefined) &&
                                                    <RangeSlider id="sliderOutboundStopoverDuration"
                                                        rangeSlideDisabled={false}
                                                        thumbsDisabled={[false, false]}
                                                        min={minOutboundStopoverDuration} max={maxOutboundStopoverDuration}
                                                        step={1}
                                                        defaultValue={[minOutboundStopoverDuration, maxOutboundStopoverDuration]}
                                                        //value={[perPersonFare[0], perPersonFare[1]]}
                                                        disabled={false}
                                                        onInput={onOutboundStopoverDurationChangeHandler}
                                                    />
                                                }
                                                // <br></br>
                                                <span id='minOutboundStopoverDuration'>{durationConvert(minOutboundStopoverDuration)}</span>                                            
                                                <span id='maxOutboundStopoverDuration'>{durationConvert(maxOutboundStopoverDuration)}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-md-12 text-end"> <button className="apply text-end" type="button" id='outboundTakeOffTime'
                                        onClick={onApplyOutboundTakeOffTimeChangeHandler}>Apply</button></div>
                                </div>
                                <div id='dvInboundTimeSliders' className='row'>
                                    <div className='col-md-12'>
                                        <h6 className='text-primary fw-bold mt-2 text-16px'>Times</h6>
                                        <div className='text-14px fw-bold mt-2 text-black'>
                                            {
                                                `Take Off: ${sessionStorage.getItem("spnToAirportDetails1").split('-')[sessionStorage.getItem("spnToAirportDetails1").split('-').length - 1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()}), ${sessionStorage.getItem("spnToAirportDetails1").split('-')[sessionStorage.getItem("spnToAirportDetails1").split('-').length - 1].split(',')[1].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[sessionStorage.getItem("spnToAirportDetails1").split('-').length - 1].split(',')[2].trim()})`
                                            }
                                        </div>
                                        {/* <div className='text-8px text-muted'>Fri 06:30 am - Fri 7:15</div> */}
                                        <div className='text-12px text-muted'>{`${timeConvert(inboundTakeOffTimeSliderValue.start) + " - " + timeConvert(inboundTakeOffTimeSliderValue.end)}`}</div>
                                        <div className="filter-price">
                                            <div id="time-slider">
                                                {
                                                    (minInboundTakeOffTime !== undefined && maxInboundTakeOffTime !== undefined) &&
                                                    <TimeRangeSlider
                                                        disabled={false}
                                                        draggableTrack={true}
                                                        maxValue={maxInboundTakeOffTime}
                                                        minValue={minInboundTakeOffTime}
                                                        format={24}
                                                        name={"time-range"}
                                                        onChange={inboundTakeOffTimeChangeHandler}
                                                        step={5}
                                                        value={inboundTakeOffTimeSliderValue}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minInboundTakeOffTime'>{timeConvert(minInboundTakeOffTime)}</span>
                                                <span id='maxInboundTakeOffTime'>{timeConvert(maxInboundTakeOffTime)}</span>
                                            </div>
                                        </div>
                                        <div className='text-14px fw-bold  text-black'>
                                            {
                                                (lastFromControlId !== undefined && sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId) !== null) &&
                                                `Landing: ${sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-')[0].trim()}), ${sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[1].trim()}(${sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-')[sessionStorage.getItem("spnFromAirportDetails" + lastFromControlId).split('-').length - 1].split(',')[2].trim()})`
                                            }
                                        </div>
                                        <div className='text-12px text-muted'>{`${timeConvert(inboundLandingTimeSliderValue.start) + " - " + timeConvert(inboundLandingTimeSliderValue.end)}`}</div>
                                        <div className="gt-duration-slider">
                                            <div id="landing-slider">
                                                {
                                                    (minInboundLandingTime !== undefined && maxInboundLandingTime !== undefined) &&
                                                    <TimeRangeSlider
                                                        disabled={false}
                                                        draggableTrack={true}
                                                        maxValue={maxInboundLandingTime}
                                                        minValue={minInboundLandingTime}
                                                        format={24}
                                                        name={"time-range"}
                                                        onChange={inboundLandingTimeChangeHandler}
                                                        step={5}
                                                        value={inboundLandingTimeSliderValue}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minInboundLandingTime'>{timeConvert(minInboundLandingTime)}</span>
                                                <span id='maxInboundLandingTime'>{timeConvert(maxInboundLandingTime)}</span>
                                            </div>
                                        </div>
                                        <h6 className='text-primary fw-bold mt-1'>Duration</h6>
                                        <div className='text-12px fw-bold mt-2 text-black'>Flight Duration</div>
                                        {
                                            (inboundDuration[0] !== undefined) &&
                                            <div className='text-8px text-muted'>{durationConvert(inboundDuration[0])} - {durationConvert(inboundDuration[1])}</div>
                                        }
                                        <div className="gt-duration-slider">
                                            <div id="flight-slider">
                                                {
                                                    (minInboundDuration !== undefined && maxInboundDuration !== undefined) &&
                                                    <RangeSlider id="sliderInboundDuration"
                                                        rangeSlideDisabled={false}
                                                        thumbsDisabled={[false, false]}
                                                        min={minInboundDuration} max={maxInboundDuration}
                                                        step={1}
                                                        defaultValue={[minInboundDuration, maxInboundDuration]}
                                                        //value={[perPersonFare[0], perPersonFare[1]]}
                                                        disabled={false}
                                                        onInput={onInboundDurationChangeHandler}
                                                    />
                                                }
                                                {/* <br></br> */}
                                                <span id='minInboundDuration'>{durationConvert(minInboundDuration)}</span>
                                                <span id='maxInboundDuration'>{durationConvert(maxInboundDuration)}</span>
                                            </div>
                                        </div>
                                        {/* <div className='text-12px fw-bold  text-black'>Stopover Duration</div>
                                        {
                                            (inboundStopoverDuration[0] !== undefined) &&
                                            <div className='text-8px text-muted'>{durationConvert(inboundStopoverDuration[0])} - {durationConvert(inboundStopoverDuration[1])}</div>
                                        } */}
                                        {/* <div className="gt-duration-slider">
                                            <div id="stopover-slider">
                                                {
                                                    (minInboundStopoverDuration !== undefined && maxInboundStopoverDuration !== undefined) &&
                                                    <RangeSlider id="sliderInboundStopoverDuration"
                                                        rangeSlideDisabled={false}
                                                        thumbsDisabled={[false, false]}
                                                        min={minInboundStopoverDuration} max={maxInboundStopoverDuration}
                                                        step={1}
                                                        defaultValue={[minInboundStopoverDuration, maxInboundStopoverDuration]}
                                                        //value={[perPersonFare[0], perPersonFare[1]]}
                                                        disabled={false}
                                                        onInput={onInboundStopoverDurationChangeHandler}
                                                    />
                                                }
                                                // <br></br>
                                                <span id='minInboundStopoverDuration'>{durationConvert(minInboundStopoverDuration)}</span>                                            
                                                <span id='maxInboundStopoverDuration'>{durationConvert(maxInboundStopoverDuration)}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='col-md-12 text-end'>
                                        <button className="apply text-end" type="button" id='inboundTakeOffTime'
                                            onClick={onApplyInboundTakeOffTimeChangeHandler}>Apply</button>
                                    </div>
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Airlines</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        airlineList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input airlineoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onAirlineChangeHandler(e)} data={element.name} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.name} </span>
                                                        <span>({element.count})</span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                (allianceList.length > 0) &&
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Alliances</h5>
                                    </div>
                                    <div className="tour_search_type">
                                        {
                                            allianceList.map(element => (
                                                <div className="form-check">
                                                    <input className="form-check-input allianceoption" type="checkbox" id={element.id}
                                                        onChange={(e) => onAllianceChangeHandler(e)} data={element.name} />
                                                    <label className="form-check-label" htmlFor={element.id}>
                                                        <span className="area_flex_one">
                                                            <span>{element.data} </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            {/* <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Stopover Airports</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp1" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultp1">
                                            <span className="area_flex_one">
                                                <span>Bahrain</span>                                            
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check-label fw-bold mt-1" >India</div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp2" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultp2">
                                            <span className="area_flex_one">
                                                <span>Delhi</span>                                            
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp3" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultp3">
                                            <span className="area_flex_one">
                                                <span>Mumbai</span>                                            
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Source</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        sourceList.map(element => (
                                            <div className="form-check">
                                                <input className="form-check-input sourceoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onSourceChangeHandler(e)} data={element.data} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.name} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                (officeList.length > 0) &&
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Office</h5>
                                    </div>
                                    <div className="tour_search_type">
                                        {
                                            officeList.map(element => (
                                                <div className="form-check">
                                                    <input className="form-check-input officeoption" type="checkbox" id={element.id}
                                                        onChange={(e) => onSourceChangeHandler(e)} data={element.name} />
                                                    <label className="form-check-label" htmlFor={element.id}>
                                                        <span className="area_flex_one">
                                                            <span>{element.name} </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            } */}

                            {/* {
                                commissionList.length > 0 &&
                                <div className="left_side_search_boxed">                                
                                    <div className="tour_search_type">
                                        {
                                            commissionList.map(element => (
                                                <div className="form-check">
                                                    <input className="form-check-input commissionoption" type="checkbox" id={element.id}
                                                        onChange={(e) => onCommissionChangeHandler(e)} data={'agentCommission'} />
                                                    <label className="form-check-label" htmlFor={element.id}>
                                                        <span className="area_flex_one">
                                                            <span>Commissionable </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            } */}

                            {
                                getConfigurationByBoolen("ALLOW_COMISSION") &&
                                <div className="left_side_search_boxed">
                                    <div className="tour_search_type">
                                        <div className="form-check">
                                            <input className="form-check-input commissionoption" type="checkbox" id={'chkAgentCommission'}
                                                onChange={(e) => onCommissionChangeHandler(e)} data={'agentCommission'} />
                                            <label className="form-check-label" htmlFor={'chkAgentCommission'}>
                                                <span className="area_flex_one">
                                                    <span>Commissionable </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    {/* User Story 1070: Change in Search for Single PNR */}
                    <div id='itemCardDivFlight'>
                        {props.searchFlight.pricedItinerary.map((searchFlight) => (
                            <FlightItem
                                key={searchFlight.sequenceNumber}
                                id={searchFlight.sequenceNumber}
                                airItinerary={searchFlight.airItinerary}
                                searchFlight={searchFlight}
                                fareRuleData={searchFlight.airItinerary}
                                showData="1"
                                hideCommision="5"
                                inPolicy={searchFlight.inPolicy}
                                travelReq={props.travelReq}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FlightList