import { Fragment, useEffect, useState, useCallback } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
//import useHttpGet from '../../../services/use-http'
import { SearchRegionApi } from '../../../services/Region-API'
import RegionList from './Components/RegionList'
import { NavLink, useHistory } from 'react-router-dom';
import { UPDATEREGION } from '../../../navigation/CONSTANTS'
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { searchCommonList } from '../../../services/Office-API';
import useHttp from '../../../services/use-http';
import Dropdown from 'react-select';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const SearchRegion = () => {
    useDocumentTitle('Search Region');

    const [isFirtTime, setFirstTime] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [serviceCode, setServiceCode] = useState('');
    const [regionCode, setRegionCode] = useState('');

    const { t } = useTranslation(['translation.Region']);

    const history = useHistory();

    const { sendRequest: sendRequestCommonListService, data: loadedService } = useHttp(searchCommonList);
    const { sendRequest: sendRequestRegionList, data: regionData } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestRegion, status, data: loadedRegion, error } = useHttp(SearchRegionApi);

    const onResetHandler = (event) => {
        // event.preventDefault();
        // setEnterCode('');
        // setEnterName('');
        // setCountry_Code('');        
        history.push('/searchregion');
        history.go();
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);

        setIsSubmitClicked(true);

        const onSearchRegion = ({
            regionId: regionCode,
            serviceId: serviceCode,
            officeId: JSON.parse(localStorage.getItem('officeID'))
        });

        sessionStorage.removeItem('selectedRegionsTemp');
        sendRequestRegion(onSearchRegion);

    }, [sendRequestRegion, regionCode, serviceCode])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        const onSearchCommonListService = ({
            methodName: "Service",
            methodCode: "SER",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListService(onSearchCommonListService);

        const onSearchRegionList = ({
            regionId: "",
            serviceId: "",
            officeId: JSON.parse(localStorage.getItem('officeID'))
        });

        sendRequestRegionList(onSearchRegionList);

        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler, sendRequestCommonListService, sendRequestRegionList]);

    let serviceOptions = []
    let serviceArrayData = [];
    for (let i in loadedService) {
        if (loadedService['errors'].status === 'FALSE') {
            if (i === 'data') {
                serviceArrayData = loadedService[i];
            }
        }
    }

    for (let index = 0; index < serviceArrayData.length; index++) {
        serviceOptions = [...serviceOptions, { value: serviceArrayData[index].listID, label: serviceArrayData[index].listValue }]
    }

    let regionOptions = []
    let regionArrayData = [];
    for (let i in regionData) {
        if (regionData['errors'].status === 'FALSE') {
            if (i === 'data') {
                regionArrayData = regionData[i];
            }
        }
    }

    for (let index = 0; index < regionArrayData.length; index++) {
        regionOptions = [...regionOptions, { value: regionArrayData[index].id, label: regionArrayData[index].regionName }]
    }

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }
    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    for (let index in loadedRegion) {
        if (loadedRegion['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedRegion[index];
            }
        }
    }

    const errorHandler = () => {
        history.go('/SearchRegion');
    };

    const ErrorShow = (isSubmitClicked && status !== 'pending') ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const RegionListTemp = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <RegionList region={loadedData} /> : ErrorShow

    const serviceChangeHandler = (event) => {
        setServiceCode(event.value);
    };

    const regionChangeHandler = (event) => {
        setRegionCode(event.value);
    };

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    // <div className=''>
                                    <LoadingSpinner></LoadingSpinner>
                                    // </div>
                                }

                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Region</label>
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>{t('service')}</label>
                                                    <Dropdown onChange={serviceChangeHandler}
                                                        value={serviceOptions.filter(option => option.value === serviceCode)}
                                                        options={serviceOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('region')}</label>
                                                    <Dropdown onChange={regionChangeHandler}
                                                        value={regionOptions.filter(option => option.value === regionCode)}
                                                        options={regionOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 col-md-12">
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>
                                                {checkSecurityDetails("Master", "Region", "Master_Region_Insert") &&
                                                    <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATEREGION}>
                                                        {t('new')}
                                                    </NavLink>}
                                                {checkSecurityDetails("Master", "Region", "Master_Region_Search") &&
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

            {RegionListTemp}
        </Fragment>)
};

export default SearchRegion;
