import { Fragment } from "react";
const RetrieveSabreGDSPNR = (props) => {
    return <Fragment>
        <div id='scrolling_div' className="popuptextBox mt-1">
            <div className="display-linebreak">
                {props.output.replace(/Â|/g, '')}
            </div>
        </div>
    </Fragment>
};
export default RetrieveSabreGDSPNR;