import {
    SEARCH_WALLET, ADD_WALLET, UPDATE_WALLET
}
    from './CONSTANTS'
export async function searchhWallet(searchData) {
    //console.log(searchData)
    const response = await fetch(SEARCH_WALLET(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Wallet information');
    }


    return data;


}
export async function addWallet(addData) {
    const response = await fetch(ADD_WALLET(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create credit Limit.');
    }
    return data;
}
export async function updateWallet(updateData) {
    const response = await fetch(UPDATE_WALLET(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update credit Limit.');
    }
    return data;
}
