import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const CityItem = (props) => {
  const { t } = useTranslation(['translation.City']);
  const [confirm, setConfirm] = useState();

  function deleteFormHandler(props) {
    // optional: Could validate here     
    props.onDelete({
      id: props.id, 
      countryCode: '',
      cityCode: '',
      cityName: '',
      cityGMTTimeDiff: "",
      tboCityID: '',
      officeID: "",
      traceID: JSON.parse(localStorage.getItem('traceID')),
      userID: JSON.parse(localStorage.getItem('userID')),
      iPAddress:localStorage.getItem("IPAddress"),
    });
  }

  function onConfirm() {
    setConfirm(true);
  }

  function okHandler() {
    deleteFormHandler(props)
    setConfirm(false);
  };

  function cancelHandler() {
    setConfirm(false);
  };
  return (
    <Fragment>
      {confirm && (
        <ConfirmationModal
          title={t('confirmationMessageTitle')}
          message={t('confirmationMessage')}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}



      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark card-bg-blue mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    {props.cityName} ({props.cityCode})
                  </h6>
                  <hr className="my-1" />
                </div>
                <div className="col-md-8">
                  <p className="card-text mb-0"><strong>Country: </strong>{props.countryName}</p>
                </div>
                <div className="col-md-4 my-auto">
                  <div className='float-lg-end float-md-end'>
                  {checkSecurityDetails("Master", "City", "Master_City_View") &&
                    <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data bs-toggle="tooltip"
                     data-bs-placement="top" title="Show" to={{ pathname: `/NewCity/${props.id}/0`, edit: 0 }}>
                      <i className="fa-sharp fa-solid fa-tv"></i>
                    </Link>}
                    {checkSecurityDetails("Master", "City", "Master_City_Update") && 
                    <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/NewCity/${props.id}/1`, edit: 1 }}>
                      <i className="fa-solid fa-pen"></i>
                    </Link>}
                    {checkSecurityDetails("Master", "City", "Master_City_Delete") &&
                    <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title='Delete'>
                      <i className="fas fa-trash-alt"></i>
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CityItem;