import { Fragment } from 'react';
import { parseHotelPolicy, removeHtmlTags, breakTeaxtOnFullStop } from '../../../utils/CommonFunction';

const HotelPolicy = (props) => {
    let cancellationPolicies = "";
    props.hotelBookingJSON.data.hotelResults[0].descriptions.filter(result => {
        if (result.text.type === 'CancellationPolicy') {
            cancellationPolicies = result.text.value;
        }
    });
    let hotelPolicy = "";
    props.hotelBookingJSON.data.hotelResults[0].descriptions.filter(result => {
        if (result.text.type === 'Policies') {
            hotelPolicy = result.text.value;
        }
    });
    return <Fragment>

        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                Hotel Policies
            </h5>
            <div className="tour_details_boxed_inner">
                <div className="tour_package_details_bar_list first_child_padding_none pt-0">
                    <div className=''>

                        {parseHotelPolicy(hotelPolicy)}
                        {cancellationPolicies !== '' && < div className='fw-bold text-primary mt-2'> Cancellation Policy</div>}
                        {breakTeaxtOnFullStop(cancellationPolicies)}
                    </div>
                </div>

            </div>
        </div>
    </Fragment>

};

export default HotelPolicy;