import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { getHistoryImportPnr } from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";
import { getFormatDate, getFormatTime } from '../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../services/Booking-API';
import { useHistory } from 'react-router-dom';

const HistoryDetailsImportPnr = (props) => {

    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedData } = useHttp(getHistoryImportPnr);
    const { sendRequest: oldConfirmationRetrieveTripRequest, data: oldConfirmationLoadedRetrieveTripDetails, error: oldConfirmationError, status: oldConfirmationRetrieveTripStatus } = useHttp(getRetrieveTrip);
    const { sendRequest: confirmationRetrieveTripRequest, data: confirmationLoadedRetrieveTripDetails, error: confirmationError, status: confirmationRetrieveTripStatus } = useHttp(getRetrieveTrip);

    const [oldConfirmation, setOldConfirmation] = useState([]);
    const [confirmation, setConfirmation] = useState([]);
    const [createdDate, setCreatedDate] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [confirmationNumber, setConfirmationNumber] = useState();
    const history = useHistory();

    function openOldConfirmationRetrieveBooking(id) {
        setConfirmationNumber(id);
        oldConfirmationRetrieveTripRequest(id);
    }

    function openConfirmationRetrieveBooking(id) {
        setConfirmationNumber(id);
        confirmationRetrieveTripRequest(id);
    }

    useEffect(() => {
        if (oldConfirmationRetrieveTripStatus === 'completed') {
            if (oldConfirmationLoadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                if (sessionStorage.getItem("sourcePNR") !== null) {
                    sessionStorage.setItem("BookingData", JSON.stringify(oldConfirmationLoadedRetrieveTripDetails));
                }
                else {
                    localStorage.setItem("BookingData", JSON.stringify(oldConfirmationLoadedRetrieveTripDetails));
                }
                //sessionStorage.setItem("myTripIsCanceled", props.reportJSON.status === 'Canceled' ? true : false);

                history.push({
                    pathname: '/RetrieveTrip',
                    mytrip: { isCanceled: oldConfirmationLoadedRetrieveTripDetails.data.airItinerary[0].isCancelled !== undefined ? oldConfirmationLoadedRetrieveTripDetails.data.airItinerary[0].isCancelled : false }
                });
                history.go();

                //window.open('/RetrieveTrip', 'ViewTrip' + confirmationNumber);
            }
        }
    }, [oldConfirmationRetrieveTripStatus, oldConfirmationLoadedRetrieveTripDetails])

    useEffect(() => {
        if (confirmationRetrieveTripStatus === 'completed') {
            if (confirmationLoadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                if (sessionStorage.getItem("sourcePNR") !== null) {
                    sessionStorage.setItem("BookingData", JSON.stringify(confirmationLoadedRetrieveTripDetails));
                }
                else {
                    localStorage.setItem("BookingData", JSON.stringify(confirmationLoadedRetrieveTripDetails));
                }
                //sessionStorage.setItem("myTripIsCanceled", props.reportJSON.status === 'Canceled' ? true : false);

                history.push({
                    pathname: '/RetrieveTrip',
                    mytrip: { isCanceled: confirmationLoadedRetrieveTripDetails.data.airItinerary[0].isCancelled !== undefined ? confirmationLoadedRetrieveTripDetails.data.airItinerary[0].isCancelled : false }
                });
                history.go();

                //window.open('/RetrieveTrip', 'ViewTrip' + confirmationNumber);
            }
        }
    }, [confirmationRetrieveTripStatus, confirmationLoadedRetrieveTripDetails])

    function functionExit() {
        props.errorHandler();
    }

    const errorHandler1 = () => {
        setError1(null);
    };

    useEffect(() => {
        //sendRequest('0001RW');
        sendRequest(props.bookingJSON.data.rootBookingReferenceNumber);
    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedData.errors.status === 'FALSE' && loadedData.data.length > 0) {
                let oldConfirmationTemp = [];
                loadedData.data.forEach(element => {
                    oldConfirmationTemp.push(element.previousBookingReferenceNumber !== undefined ? element.previousBookingReferenceNumber : 'N/A');
                });
                setOldConfirmation(oldConfirmationTemp);

                let confirmationTemp = [];
                loadedData.data.forEach(element => {
                    confirmationTemp.push(element.bookingReferenceNumber !== undefined ? element.bookingReferenceNumber : 'N/A');
                });
                setConfirmation(confirmationTemp);

                let createdDateTemp = [];
                loadedData.data.forEach(element => {
                    createdDateTemp.push(element.serverCreatedDate !== undefined && element.serverCreatedDate.split('T')[0] !== undefined && element.serverCreatedDate.split('T')[1] !== undefined ? getFormatDate(element.serverCreatedDate.split('T')[0].replaceAll('-', '')) + " " + getFormatTime(element.serverCreatedDate.split('T')[1].replaceAll(':', '').substring(0, 4)) : 'N/A');
                });
                setCreatedDate(createdDateTemp);

                let createdByTemp = [];
                loadedData.data.forEach(element => {
                    createdByTemp.push(element.userName !== undefined ? element.userName : 'N/A');
                });
                setCreatedBy(createdByTemp);

            } else {
                document.querySelector('#dvHistoryMsg').innerHTML = "Flight modify history not found.";
            }
            return;
        }

    }, [status])

    function showHistoryInfo() {
        let historyInfo = [];
        for (let index = 0; index < oldConfirmation.length; index++) {
            historyInfo.push(
                <tbody>
                    <tr>
                        <td className="col-md-2"><a className='cursor-pointer d-flex' onClick={() => openOldConfirmationRetrieveBooking(oldConfirmation[index])}><div>{oldConfirmation[index]}   {(oldConfirmationRetrieveTripStatus === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : ''}</div></a></td>
                        <td className="col-md-2"><a className='cursor-pointer d-flex' onClick={() => openConfirmationRetrieveBooking(confirmation[index])}><div>{confirmation[index]}   {(confirmationRetrieveTripStatus === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : ''}</div></a></td>
                        <td className="col-md-4">{createdDate[index]}</td>
                        <td className="col-md-3">{createdBy[index]}</td>
                    </tr>
                </tbody>
            );
        }

        return historyInfo;
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div id="dvHistoryMsg" className=""></div>
                <div className="container gt-h-340px">
                    <div className="row">
                        {
                            (oldConfirmation.length > 0) &&
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="col-md-2" scope="col">Old Confirmation</th>
                                            <th className="col-md-2" scope="col">Confirmation</th>
                                            <th className="col-md-4" scope="col">Done On</th>
                                            <th className="col-md-3" scope="col">Done By</th>
                                        </tr>
                                    </thead>
                                    {
                                        showHistoryInfo()
                                    }
                                </table>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-12 my-auto">
                    <button className='btn gt-btn_md text-white my-2 gt-main-btn float-end' id='btnSend' onClick={functionExit}>
                        Close
                    </button>
                </div>
            </section>
        </div>
    </Fragment>
};
export default HistoryDetailsImportPnr;