import { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory  } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { AUTHORIZETWOFACTOR, ENABLETWOFACTOR, FORGOTPASSWORD } from '../../../navigation/CONSTANTS';
import { getAuthentication } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http'

import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import { regEmailID } from '../../../config/RegularExpression'
import { encrypted } from '../../../utils'
const LoginForm = () => {
  //alert("login")
  const { t } = useTranslation(['translation.LoginForm']);
  const history = useHistory();
  const loginIDInputRef = useRef();
  const passwordInputRef = useRef();
  const [errors, setErrors] = useState();
  const [loginIDInputHasError, setLoginIDInputHasError] = useState(false);
  const [loginIDValidInputHasError, setLoginIDValidInputHasError] = useState(false);
  const [passwordInputHasError, setPasswordInputHasError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const errorHandler = () => {
    setErrors(null);
  };

  useEffect(() => {    
  
    loginIDInputRef.current.focus();
  }, [])



  const { sendRequest, status, data: loadedAuthenticationDetails, error } = useHttp(getAuthentication);


  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in loadedAuthenticationDetails) {
        if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            loadedData = loadedAuthenticationDetails[i];
            if (loadedData.twoFactorEnabled) {
              localStorage.setItem('enable2FA', true);
              localStorage.setItem('idToken', loadedData.idToken);
              localStorage.setItem('userIDLogin', loadedData.userID);
              localStorage.setItem('loginEmailIDLogin', loadedData.loginEmailID);
              window.location.href=`${AUTHORIZETWOFACTOR}`;           
            }
            else {
              localStorage.setItem('enable2FA', false);
              localStorage.setItem('idToken', loadedData.idToken);
              localStorage.setItem('userIDLogin', loadedData.userID);
              localStorage.setItem('loginEmailIDLogin', loadedData.loginEmailID);             
              window.location.href=`${ENABLETWOFACTOR}`;  
            }
          }
        }
        else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
          localStorage.removeItem('token');
          localStorage.removeItem('expirationTime');
          localStorage.removeItem('idToken');
          localStorage.removeItem('userIDLogin');
          localStorage.removeItem('loginEmailID');
          localStorage.removeItem('userID');  
          localStorage.removeItem('officeID');
          localStorage.removeItem('displayName');
          localStorage.removeItem('roleDetails');
          localStorage.removeItem('customizeDetails');
          localStorage.removeItem('enable2FA');
          localStorage.removeItem('loginEmailIDLogin');
          sessionStorage.clear();
          setErrors({
            title: "Message",
            message: 'Please check the Email ID and Password.',
          });
        }
      }
    }
    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, loadedAuthenticationDetails, history])
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  
  function onloginIDChangeCheck() {
    // optional: Could validate here   
    if (loginIDInputRef.current.value.trim() === '' && !loginIDInputHasError) {
      setTimeout(setLoginIDInputHasError(true), 200)
      setTimeout(setLoginIDValidInputHasError(false), 200)
      return;
    }
    else if (loginIDInputHasError) {
      setTimeout(setLoginIDInputHasError(false), 200)
    }
  if (!loginIDValidInputHasError && !loginIDInputRef.current.value.trim().match(regEmailID)) {
    setTimeout(setLoginIDInputHasError(false), 200)
    setTimeout(setLoginIDValidInputHasError(true), 200)

      return;
    } else if (loginIDValidInputHasError) {
      setTimeout(setLoginIDValidInputHasError(false), 200)
    } 


  }
  function onPaswordChangeCheck() {
    if (passwordInputRef.current.value.trim() === '') {
      setPasswordInputHasError(true); return;
    }
    else {
      setPasswordInputHasError(false);
    }
  }


  const  submitHandler = useCallback(async (event) => {
    event.preventDefault();
  
    const enteredLoginID = loginIDInputRef.current.value.trim();
    let enteredPassword =passwordInputRef.current.value.trim();

    // optional: Could  validate here
    if (enteredLoginID === '') {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(true);
      setLoginIDValidInputHasError(false);
      return;
    }

    if (!enteredLoginID.match(regEmailID)) {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(false);
      setLoginIDValidInputHasError(true);
      return;
    } 
    if (enteredPassword === '') {
      passwordInputRef.current.focus();
      setPasswordInputHasError(true);
      return;
    }
    setIsLoader(true);
    enteredPassword = await encrypted(enteredPassword);
    sendRequest({ userName: enteredLoginID, password: enteredPassword });
    setIsLoader(false);
    // optional: Add validation 
  }, [sendRequest]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);




  return (
    <Fragment>
      {errors && (
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 mb-1 position-relative">

          <div className="gt-common_author_boxed position-absolute width-100 gt-login-banner-div">
            <div className="card-body">
              <div className="col-md-10 col-sm-12 mx-auto signin-log">
                {/* <label className="gt-heading_theme h4 text-white mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Globetrotter Login </label> */}
                <div className="form-group  col-lg-12 mx-auto">
                  <div className='control'>
                    <div className='input-group'>
                      <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-envelope text-primary"></i></span>
                      {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                      <input type='text'
                        className="form-control form-control-sm mb-0 text-login-placeholder" tabIndex={1} id='email'
                        placeholder='Email ID'
                        maxLength={100}
                        onBlur={onloginIDChangeCheck}
                        ref={loginIDInputRef}
                      />
                    </div>
                    <div>
                      {loginIDInputHasError && <p className="error-text">{t('validationLoginid')}</p>}
                      {loginIDValidInputHasError && <p className="error-text">{t('validationValidLoginid')}</p>}

                    </div>
                  </div>
                  <div className='control'>
                    <div className='input-group position-relative'>
                      {/* <label className="h6 text-primary mb-2 col-lg-12">{t('password')}</label> */}
                      <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                      <input
                        type='password'
                        tabIndex={2}
                        maxLength={50}
                        className="form-control form-control-sm mb-0 text-login-placeholder"
                        id='password'
                        
                        placeholder='Password'
                        onBlur={onPaswordChangeCheck}
                        ref={passwordInputRef}
                      />
                      <span className="input-group-text w-50px">
                      <i className="fa fa-eye-slash text-primary" id="togglePassword"></i>
                      </span>
                     

                    </div>
                    <div>{passwordInputHasError && <p className="error-text">{t('validationpassword')}</p>}</div>


                  </div>
                  <div className="row my-3">
                    <div className="col-md-6 my-auto col-sm-12 float-sm-end">
                      <Link className="fw-bold text-white forgot-pass-bg h5" to={`${FORGOTPASSWORD}`}><span className='badge bg-primary'>Forgot Password ?</span></Link>
                    </div>
                    <div className=" col-md-6 col-sm-12 mt-2">
                      <button type="button" className="btn gt-btn_md text-white gt-main-btn-WithoutAuth float-lg-end float-md-end" onClick={submitHandler} tabIndex={3}>
                        {(status === 'pending' || isLoader) ?
                          <i className="fa fa-spinner fa-spin" ></i>
                          : 'Login'}
                      </button>
                    </div>
                  </div>

                  <div className='row'>
                     {/* <p>
                          <label>Password:</label>
                          <input type="password" name="password" id="password" />
                          <i className="fa fa-eye-slash" id="togglePassword"></i>
                      </p> */}
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12 mx-auto">
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>

        </div>
      </div>


    </Fragment>
  );
};

export default LoginForm;