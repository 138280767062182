import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useHttp from "../../../services/use-http"
import { updateConfiguration, getSingleConfiguration, addConfiguration } from "../../../services/Configuration-API";
import ConfigurationForm from "./Components/ConfigurationForm";
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { todoReducer } from './Components/todoReducer'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../../utils/RoleUtils'

const UpdateConfiguration = (props) => {
    useDocumentTitle(" Configuration");
    const store = configureStore({ reducer: todoReducer });
    const [error, setError] = useState();
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const history = useHistory();
    const { t } = useTranslation(['translation.Configuration']);
    const { Id, OfficeID, Action } = parms;

    let varHttp;

    if (Id === undefined) {
        varHttp = addConfiguration;
    } else {
        varHttp = updateConfiguration;
    }

    const { sendRequest: sendConfigurationData, data: loadedConfiguration, status: loadingStatus } = useHttp(getSingleConfiguration);
    const { sendRequest: SendRequestUpdate, status, data: loadedQuote } = useHttp(varHttp);
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("Settings", "Configuration", "Settings_Configuration_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Settings", "Configuration", "Settings_Configuration_View") && Action === "0")
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {

        sendConfigurationData(OfficeID);
    }, [sendConfigurationData]);

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {

                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecord')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    // console.log(status);
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    if (loadingStatus === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    let loadedData = [];
    for (let i in loadedConfiguration) {
        if (loadedConfiguration['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedConfiguration[i];
            }
        }
    }

    const addConfigurationHandler = () => {
        let newConfigurationMenuDetails = [];
        const data = store.getState();
        for (let i in loadedData) {
            for (let j in loadedData[i].configurationsMenuDetails) {
                let updateData = data[loadedData[i].configurationsMenuDetails[j].keyCode][loadedData[i].configurationsMenuDetails[j].keyCode]
                    === undefined ? '' : data[loadedData[i].configurationsMenuDetails[j].keyCode][loadedData[i].configurationsMenuDetails[j].keyCode];

                newConfigurationMenuDetails.push({
                    menuID: loadedData[i].configurationsMenuDetails[j].menuID,
                    keyCode: loadedData[i].configurationsMenuDetails[j].keyCode,
                    boolenValue: loadedData[i].configurationsMenuDetails[j].controlType === 1
                        ? updateData === '' ? loadedData[i].configurationsMenuDetails[j].defaultBoolenValue : updateData : false,
                    stringValue: loadedData[i].configurationsMenuDetails[j].controlType !== 1
                        ? updateData === '' ? loadedData[i].configurationsMenuDetails[j].defaultStringValue : updateData : '',
                    isEditable: loadedData[i].configurationsMenuDetails[j].isEditable,
                });
            }
        }
        var strRequest = {
            id: Id === undefined ? '' : Id,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            officeID: OfficeID,
            companyName: "",
            configurationsMenuDetails: newConfigurationMenuDetails,
        }
        SendRequestUpdate(strRequest);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchconfiguration');
        }
    };
    //console.log(loadedData);
    const ConfigurationFormContent = (loadedData.length === 0) ?
        <p className="text-primary text-center h5">{t('configurationDetailsError')}</p> :
        <Provider store={store}><ConfigurationForm loadedConfigurationDetails={loadedData} isLoading={status === 'pending'} onAddConFiguration={addConfigurationHandler}
            OfficeID1={OfficeID}
        /> </Provider>

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {ConfigurationFormContent}
    </Fragment>

};
export default UpdateConfiguration;