import { Fragment, useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { regNumber } from '../../../../config/RegularExpression'
import {
    LOGIN
} from '../../../../navigation/CONSTANTS.js'

const WebStatusForm = (props) => {
    const InputsecuritycodeRef = useRef('');
    const authCtx = useContext(AuthContext);
    const history = useHistory();
 
    let CONTRACT_PATH = process.env.REACT_APP_URL

    CONTRACT_PATH=CONTRACT_PATH+"webstatus/healthchecks-ui#/healthchecks";


    return (
        <Fragment>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='col-md-12'>
                                <iframe className='w-100' height="700" src={CONTRACT_PATH}  ></iframe>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    );
};

export default WebStatusForm;