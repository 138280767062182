import { Fragment, useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner'
import { regNumber } from '../../../config/RegularExpression'
import {
    LOGIN
} from '../../../navigation/CONSTANTS.js'
const EnableTwoFactorForm = (props) => {
    const InputsecuritycodeRef = useRef('');
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const [securityCodeInputHasError, setSecurityCodeInputHasError] = useState(false);
    useEffect(() => {
        InputsecuritycodeRef.current.focus();
    }, [])
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredInputsecuritycode = InputsecuritycodeRef.current.value;

        // optional: Could validate here
        if (enteredInputsecuritycode === '') {
            InputsecuritycodeRef.current.focus();
            setSecurityCodeInputHasError(true);
            return;
        }

        props.onValidated2FA({
            inputCode: enteredInputsecuritycode,
            loginEmailID: localStorage.getItem('loginEmailIDLogin'),
            userID: localStorage.getItem('userIDLogin'),
            twoFactorEnabled: true
        });
    }
    function onBlurCheck(event) {
        const enteredInputsecuritycode = InputsecuritycodeRef.current.value;
        // optional: Could validate here
        if (enteredInputsecuritycode === '') {
            setSecurityCodeInputHasError(true); return;
        }
        else {
            setSecurityCodeInputHasError(false);
        }
        // if value is not blank, then test the regex        
        if (!event.target.value.match(regNumber)) {
            event.target.value = '';
            return;
        }
        if (enteredInputsecuritycode.length === 6)
            submitFormHandler(event);
    }
    const logoutHandler = () => {
        setSecurityCodeInputHasError(false);
        authCtx.logout();
        history.go(LOGIN);
    };
    return (
        <Fragment>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">

                                        <div className="col-md-9 mx-auto gt-country-details-form my-3 signin-log">
                                            <label className="mb-2  h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Set up your authenticator app</label>
                                            <div className="form-group col-md-12 position-relative">
                                                {props.isLoading && (
                                                    <div className='loading'>
                                                        <LoadingSpinner />
                                                    </div>
                                                )}
                                                <div className="control">
                                                    Please install your preferred MFA app such as Okta, Google Authenticator, or Microsoft Authenticator app on your mobile device or computer.
                                                </div>
                                                <div className='row'>
                                                    <div className="control">
                                                        Open your authenticator app and scan the <b>QR code</b>.
                                                    </div>
                                                    <div className='col-md-12'>
                                                        {/* <div className="control">
                                                            Open your authenticator app and scan the <b>QR code</b>.
                                                        </div> */}
                                                        <div className="control">

                                                            <img src={props.loadedProfileDetails.barcodeImageUrl} alt="" />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>Setup code</label>
                                                    {props.loadedProfileDetails.setupCode}
                                                </div>

                                                <div className='my-3'>
                                                    <label className="mb-2 h6 col-lg-12" htmlFor='country'><i className="fa fa-info-circle text-primary"></i> New to Multi Factor Authentication? Please see the below video:
                                                        </label>
                                                    <div className=''>
                                                        <a className='text-sky-blue-without-auth' href='https://www.youtube.com/watch?v=2ZLnBx8Mm40' target='_blank'>https://www.youtube.com/watch?v=2ZLnBx8Mm40</a>
                                                    </div>
                                                </div>
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>Please specify your authenticator code to complete the log in.
                                                    </label>
                                                    <input
                                                        maxLength="6" type='password'
                                                        id='Inputsecuritycode'
                                                        ref={InputsecuritycodeRef}
                                                        onChange={onBlurCheck}
                                                        className="mb-2 form-control form-control-sm col-lg-12"
                                                    />
                                                    {securityCodeInputHasError && <p className="error-text">Please enter authenticator code.</p>}

                                                </div>
                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                        <a href="/Login" onClick={logoutHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn-WithoutAuth-MFA d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">
                                                            <a href="/Login" onClick={logoutHandler} className='btn back-btn-sm text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                            <button type="button" className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1" tabIndex={3} onClick={submitFormHandler}>Authenticate</button>

                                                        </div>
                                                    </div>
                                                </div>

{/* 
                                                <div className='col-md-12 mt-5'>
                                                    <label className="mb-2 h6 col-lg-12" htmlFor='country'><i className="fa fa-info-circle text-primary"></i> New to Multi Factor Authentication? Please see the below video:
                                                    </label>
                                                    <div className=''>
                                                        <a className='text-sky-blue-without-auth' href='https://www.youtube.com/watch?v=2ZLnBx8Mm40' target='_blank'>https://www.youtube.com/watch?v=2ZLnBx8Mm40</a>
                                                    </div> */}
                                                    {/* <div className='col-md-12'>
                                                        
                                                        <iframe className='w-100' height="215" src="https://www.youtube-nocookie.com/embed/2ZLnBx8Mm40" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                        <label className="mb-2 h6 text-dark col-lg-12" for="country"><i className="fa fa-info-circle text-primary"></i> <span>New to Multi Factor Authentication? Please see the video.</span></label>
                                                    </div> */}
                                                {/* </div> */}

                                                <div className="mt-3 col-md-12">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment >
    );
};
export default EnableTwoFactorForm;