import Dropdown from 'react-select';
import { getFormatDate } from '.././../../../utils/CommonFunction';
import { useState, Fragment, useEffect } from 'react';
import { dateList, monthList, expiryYearList, dobYearList, gender_list, titles_name_child, titles_name, dobInfantYearList, dobChildYearList } from '../../../../utils/DropdownData';
import { useHistory } from 'react-router-dom';
import { CreateImportBookingJSON } from '../../../../utils/BookingFunction';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { toPascalCase } from '.././../../../utils/CommonFunction';
import { getAllCountry } from '../../../../services/Country-API';
import useHttp from '../../../../services/use-http';
import { Tooltip as ReactTooltip } from "react-tooltip";

const TravelerDetails = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [titleInputHasError, setTitleInputHasError] = useState(false);
    const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
    const [firstNameInvalidInputHasError, setFirstNameInvalidInputHasError] = useState(false);
    const [middleNameInvalidInputHasError, setMiddleNameInvalidInputHasError] = useState(false);
    const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
    const [lastNameInvalidInputHasError, setLastNameInvalidInputHasError] = useState(false);
    const [paxTypeInputHasError, setPaxTypeInputHasError] = useState(false);
    const [genderInputHasError, setGenderInputHasError] = useState(false);
    const [dateInputHasError, setDateInputHasError] = useState(false);
    const [monthInputHasError, setMonthInputHasError] = useState(false);
    const [yearInputHasError, setYearInputHasError] = useState(false);
    const [countryInputHasError, setCountryInputHasError] = useState(false);
    const [passportNumberInputHasError, setPassportNumberInputHasError] = useState(false);
    const [passportNumberInvalidInputHasError, setPassportNumberInvalidInputHasError] = useState(false);
    const [pptDateInputHasError, setPptDateInputHasError] = useState(false);
    const [pptMonthInputHasError, setPptMonthInputHasError] = useState(false);
    const [pptYearInputHasError, setPptYearInputHasError] = useState(false);
    const [issuingCountryInputHasError, setIssuingCountryInputHasError] = useState(false);
    const [additionalInputHasError, setAdditionalInputHasError] = useState(false);
    const [numberInputHasError, setNumberInputHasError] = useState(false);
    const [numberInvalidInputHasError, setNumberInvalidInputHasError] = useState(false);
    const [additionalIssuingCountryInputHasError, setAdditionalIssuingCountryInputHasError] = useState(false);
    const [ticketNumberInvalidInputHasError, setTicketNumberInvalidInputHasError] = useState(false);
    const [dobInvalidInputHasError, setDobInvalidInputHasError] = useState(false);

    const [titleValue, SetTitleValue] = useState();
    const [dateValue, SetDateValue] = useState();
    const [monthValue, SetMonthValue] = useState();
    const [yearValue, SetYearValue] = useState();
    const [paxTypeValue, SetPaxTypeValue] = useState();
    const [genderValue, SetGenderValue] = useState();
    const [selectedTraveler, SetSelectedTraveler] = useState();
    const history = useHistory();
    const [confirm, setConfirm] = useState();
    const [travelerRefNumberForDelete, SetTravelerRefNumberForDelete] = useState();
    const [countryValue, SetCountryValue] = useState();
    const [pptDateValue, SetPptDateValue] = useState();
    const [pptMonthValue, SetPptMonthValue] = useState();
    const [pptYearValue, SetPptYearValue] = useState();
    const [issuingCountryValue, SetIssuingCountryValue] = useState();
    const [additionalValue, SetAdditionalValue] = useState();
    const [additionalIssuingCountryValue, SetAdditionalIssuingCountryValue] = useState();
    const [paxPricingConfirm, setPaxPricingConfirm] = useState();
    const [paxTypeForDelete, SetPaxTypeForDelete] = useState();
    const [paxNameForDelete, SetPaxNameForDelete] = useState();

    const [error1, setError] = useState();
    const [disabledControl, setDisabledControl] = useState(true);

    const pax_type_list = [
        { value: 'ADT', label: 'Adult' },
        { value: 'CNN', label: 'Child' },
        { value: 'INF', label: 'Lap Infant' },
        { value: 'INS', label: 'Seat Infant' }
    ];

    const IdentificationOptions = [{ value: "", label: "Select" }, { value: "KnownTravelerNumber", label: 'Known Traveler' }, { value: "RedressNumber", label: "Redress" }];
    const { sendRequest: sendRequestCountryList, data: loadedCountry } = useHttp(getAllCountry);

    function toggleHandler(controlId1, controlId2) {
        var control1 = document.getElementById(controlId1);
        var control2 = document.getElementById(controlId2);
        if (control1.style.display === "none") {
            control1.style.display = "";
            control2.classList.remove("fa-plus-square");
            control2.classList.add("fa-minus-square");
        } else {
            control1.style.display = "none";
            control2.classList.add("fa-plus-square");
            control2.classList.remove("fa-minus-square");
        }
    }

    useEffect(() => {
        sendRequestCountryList();
        var control1 = document.getElementById("travelerDetailsDiv");
        control1.style.display = "none";

    }, [])

    if (props.traveler !== undefined && props.traveler.travelerInfo !== undefined && props.traveler.travelerInfo.traveler !== undefined && (paxTypeValue === undefined || paxTypeValue === '')) {
        window.scrollTo({
            top: 520,
            behavior: "smooth",
        });
    }

    let countryOptions = []
    let countryArraydata = [];
    for (let i in loadedCountry) {
        if (loadedCountry['errors'].status === 'FALSE') {
            if (i === 'data') {
                countryArraydata = loadedCountry[i];
            }
        }
    }
    for (let index = 0; index < countryArraydata.length; index++) {
        countryOptions = [...countryOptions, { value: countryArraydata[index].countryCode, label: countryArraydata[index].countryName }]
    }

    const handleChangeTitle = (e) => {
        SetTitleValue(e.value);
    };

    const handleChangeDobDate = (e) => {
        SetDateValue(e.value);
    };

    const handleChangeDobMonth = (e) => {
        SetMonthValue(e.value);
    };

    const handleChangeDobYear = (e) => {
        SetYearValue(e.value);
    };

    const handleChangePaxType = (e) => {
        SetPaxTypeValue(e.value);
        setDisabledControl(false);

        if (document.querySelector('#btnAddTraveler').innerHTML !== "Update") {
            SetTitleValue('');
            document.querySelector('#firstName').value = "";
            document.querySelector('#middleName').value = "";
            document.querySelector('#lastName').value = "";
            SetGenderValue('');
            SetDateValue('');
            SetMonthValue('');
            SetYearValue('');
            document.querySelector('#ticketNumber').value = "";
            SetSelectedTraveler('');
        }
    };

    const handleChangeGender = (e) => {
        SetGenderValue(e.value);
    };

    const handleChangeCountry = (e) => {
        SetCountryValue(e.value);
    };

    const handleChangePptDate = (e) => {
        SetPptDateValue(e.value);
    };

    const handleChangePptMonth = (e) => {
        SetPptMonthValue(e.value);
    };

    const handleChangePptYear = (e) => {
        SetPptYearValue(e.value);
    };

    const handleChangeIssuingCountry = (e) => {
        SetIssuingCountryValue(e.value);
    };

    const handleChangeAdditional = (e) => {
        SetAdditionalValue(e.value);
    };

    const handleChangeAdditionalIssuingCountry = (e) => {
        SetAdditionalIssuingCountryValue(e.value);
    };

    function onEditTraveler(travelerRefNumber) {
        setDisabledControl(false);

        if (document.querySelector('#btnAddTraveler') !== undefined && document.querySelector('#btnAddTraveler') !== null) {
            document.querySelector('#btnAddTraveler').innerHTML = "Update";
        }

        const yOffset = -250;
        const element2 = document.getElementById("firstName");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

        let traveler = props.traveler.travelerInfo.traveler.filter(a => a.travelerRefNumber === travelerRefNumber);
        document.querySelector('#firstName').value = toPascalCase(traveler[0].personName.givenName);
        document.querySelector('#middleName').value = toPascalCase(traveler[0].personName.middleName !== undefined && traveler[0].personName.middleName !== 'Undefined' ? traveler[0].personName.middleName : '');
        document.querySelector('#lastName').value = toPascalCase(traveler[0].personName.surname);
        document.querySelector('#ticketNumber').value = traveler[0].ticketInfo !== undefined ? traveler[0].ticketInfo[0].ticketNumber : '';
        SetTitleValue(traveler[0].personName.namePrefix);
        SetPaxTypeValue(traveler[0].passengerTypeCode);
        SetGenderValue(traveler[0] !== undefined && traveler[0].gender !== undefined ? traveler[0].gender.length > 1 ? traveler[0].gender.substring(0, 1) : traveler[0].gender : '');
        SetDateValue(traveler[0].dateOfBirth.date.slice(6));
        SetMonthValue(traveler[0].dateOfBirth.date.slice(4, 6));
        SetYearValue(traveler[0].dateOfBirth.date.slice(0, 4));

        SetCountryValue(traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docHolderNationality !== undefined && traveler[0].document[0].docHolderNationality !== null && traveler[0].document[0].docHolderNationality !== '' ? traveler[0].document[0].docHolderNationality : '');
        if (document.querySelector('#passportNumber') !== null) {
            document.querySelector('#passportNumber').value = traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docID !== undefined && traveler[0].document[0].docID !== null && traveler[0].document[0].docID !== '' ? traveler[0].document[0].docID : '';
        }
        SetPptDateValue(traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].expireDate !== undefined && traveler[0].document[0].expireDate !== null && traveler[0].document[0].expireDate !== '' ? traveler[0].document[0].expireDate.slice(6) : '');
        SetPptMonthValue(traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].expireDate !== undefined && traveler[0].document[0].expireDate !== null && traveler[0].document[0].expireDate !== '' ? traveler[0].document[0].expireDate.slice(4, 6) : '');
        SetPptYearValue(traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].expireDate !== undefined && traveler[0].document[0].expireDate !== null && traveler[0].document[0].expireDate !== '' ? traveler[0].document[0].expireDate.slice(0, 4) : '');
        SetIssuingCountryValue(traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docIssueCountry !== undefined && traveler[0].document[0].docIssueCountry !== null && traveler[0].document[0].docIssueCountry !== '' ? traveler[0].document[0].docIssueCountry : '');
        SetAdditionalValue(traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docType !== undefined && traveler[0].document[1].docType !== null && traveler[0].document[1].docType !== '' ? traveler[0].document[1].docType : '');
        if (document.querySelector('#number') !== null) {
            document.querySelector('#number').value = traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docID !== undefined && traveler[0].document[1].docID !== null && traveler[0].document[1].docID !== '' ? traveler[0].document[1].docID : '';
        }
        SetAdditionalIssuingCountryValue(traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docIssueCountry !== undefined && traveler[0].document[1].docIssueCountry !== null && traveler[0].document[1].docIssueCountry !== '' ? traveler[0].document[1].docIssueCountry : '');
        SetSelectedTraveler(travelerRefNumber);

        if (document.querySelector('#advance') !== undefined && document.querySelector('#advance') !== null) {
            if ((traveler[0] !== undefined) && ((traveler[0].gender !== undefined && traveler[0].gender !== '') || (traveler[0].dateOfBirth !== undefined && traveler[0].dateOfBirth.date !== undefined && traveler[0].dateOfBirth.date !== '') || (traveler[0].ticketInfo !== undefined && traveler[0].ticketInfo[0].ticketNumber !== '') || (traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docHolderNationality !== undefined && traveler[0].document[0].docHolderNationality !== null && traveler[0].document[0].docHolderNationality !== '') || (traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docID !== undefined && traveler[0].document[0].docID !== null && traveler[0].document[0].docID !== '') || (traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].expireDate !== undefined && traveler[0].document[0].expireDate !== null && traveler[0].document[0].expireDate !== '') || (traveler[0].document !== undefined && traveler[0].document[0] !== undefined && traveler[0].document[0].docIssueCountry !== undefined && traveler[0].document[0].docIssueCountry !== null && traveler[0].document[0].docIssueCountry !== '') || (traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docType !== undefined && traveler[0].document[1].docType !== null && traveler[0].document[1].docType !== '') || (traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docID !== undefined && traveler[0].document[1].docID !== null && traveler[0].document[1].docID !== '') || (traveler[0].document !== undefined && traveler[0].document[1] !== undefined && traveler[0].document[1].docIssueCountry !== undefined && traveler[0].document[1].docIssueCountry !== null && traveler[0].document[1].docIssueCountry !== '')))
                document.querySelector('#advance').click();
        }
    }

    function clearTravelersFields() {
        SetTitleValue('');
        document.querySelector('#firstName').value = "";
        document.querySelector('#middleName').value = "";
        document.querySelector('#lastName').value = "";
        SetPaxTypeValue('');
        SetGenderValue('');
        SetDateValue('');
        SetMonthValue('');
        SetYearValue('');
        document.querySelector('#ticketNumber').value = "";
        SetCountryValue('')
        if (document.querySelector('#passportNumber') !== null) {
            document.querySelector('#passportNumber').value = "";
        }
        SetPptDateValue('');
        SetPptMonthValue('');
        SetPptYearValue('');
        SetIssuingCountryValue('');
        SetAdditionalValue('');
        if (document.querySelector('#number') !== null) {
            document.querySelector('#number').value = "";
        }
        SetAdditionalIssuingCountryValue('');
        SetSelectedTraveler('');

        const yOffset = -100;
        const element2 = document.getElementById("number") !== undefined && document.getElementById("number") !== null ? document.getElementById("number") : document.getElementById("passportNumber");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    function dateDiff(targerDate, sourceDate) {
        var year = targerDate.getFullYear();
        var month = targerDate.getMonth() + 1;
        var day = targerDate.getDate();

        var yy = sourceDate.getFullYear();
        var mm = sourceDate.getMonth() + 1;
        var dd = sourceDate.getDate();
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
            months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
            years = years - 1;
            months = months + 12;
        }
        // days
        days = Math.floor((targerDate.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
        //
        return { years: years, months: months, days: days };
    }

    function onAddTraveler(event) {
        event.preventDefault();

        if (document.querySelector('#advance') !== undefined && document.querySelector('#advance') !== null) {
            var control1 = document.getElementById("travelerDetailsDiv");
            if (control1.style.display === "") {
                document.querySelector('#advance').click();
            }
        }

        const yOffset = -230;
        const element2 = document.getElementById("firstName");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

        if (paxTypeValue === undefined || paxTypeValue === '') {
            setPaxTypeInputHasError(true);
            return;
        }
        else {
            setPaxTypeInputHasError(false);
        }

        // if (titleValue === undefined || titleValue === '') {
        //     setTitleInputHasError(true);
        //     return;
        // }
        // else {
        //     setTitleInputHasError(false);
        // }

        if (document.querySelector('#firstName').value === '') {
            setFirstNameInputHasError(true);
            setFirstNameInvalidInputHasError(false);
            return;
        }
        else {
            setFirstNameInputHasError(false);
        }

        if (document.querySelector('#firstName').value !== '' && !document.querySelector('#firstName').value.match('^[a-zA-Z ]+$')) {
            setFirstNameInputHasError(false);
            setFirstNameInvalidInputHasError(true);
            return;
        }
        else {
            setFirstNameInvalidInputHasError(false);
        }

        if (document.querySelector('#middleName').value !== '' && !document.querySelector('#middleName').value.match('^[a-zA-Z ]+$')) {
            setMiddleNameInvalidInputHasError(true);
            return;
        }
        else {
            setMiddleNameInvalidInputHasError(false);
        }

        if (document.querySelector('#lastName').value === '') {
            setLastNameInputHasError(true);
            setLastNameInvalidInputHasError(false);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }

        if (document.querySelector('#lastName').value !== '' && !document.querySelector('#lastName').value.match('^[a-zA-Z ]+$')) {
            setLastNameInputHasError(false);
            setLastNameInvalidInputHasError(true);
            return;
        }
        else {
            setLastNameInvalidInputHasError(false);
        }

        // if (genderValue === undefined || genderValue === '') {
        //     setGenderInputHasError(true);
        //     return;
        // }
        // else {
        //     setGenderInputHasError(false);
        // }

        // if (dateValue === undefined || dateValue === '') {
        //     setDateInputHasError(true);
        //     return;
        // }
        // else {
        //     setDateInputHasError(false);
        // }

        // if (monthValue === undefined || monthValue === '') {
        //     setMonthInputHasError(true);
        //     return;
        // }
        // else {
        //     setMonthInputHasError(false);
        // }

        // if (yearValue === undefined || yearValue === '') {
        //     setYearInputHasError(true);
        //     return;
        // }
        // else {
        //     setYearInputHasError(false);
        // }

        if (document.querySelector('#ticketNumber').value !== '' && !document.querySelector('#ticketNumber').value.match('^[0-9]+$')) {
            setTicketNumberInvalidInputHasError(true);
            return;
        }
        else {
            setTicketNumberInvalidInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (countryValue === undefined || countryValue === '')) {
            setCountryInputHasError(true);
            return;
        }
        else {
            setCountryInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (document.querySelector('#passportNumber').value === '')) {
            setPassportNumberInputHasError(true);
            setPassportNumberInvalidInputHasError(false);
            return;
        }
        else {
            setPassportNumberInputHasError(false);
        }

        if (document.querySelector('#passportNumber').value !== '' && !document.querySelector('#passportNumber').value.match('^[a-zA-Z0-9]+$')) {
            setPassportNumberInputHasError(false);
            setPassportNumberInvalidInputHasError(true);
            return;
        }
        else {
            setPassportNumberInvalidInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (pptDateValue === undefined || pptDateValue === '')) {
            setPptDateInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setPptDateInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (pptMonthValue === undefined || pptMonthValue === '')) {
            setPptMonthInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setPptMonthInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (pptYearValue === undefined || pptYearValue === '')) {
            setPptYearInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setPptYearInputHasError(false);
        }

        if (pptDateValue !== undefined) {
            if (pptDateValue !== '') {
                if (pptMonthValue === undefined || pptMonthValue === '') {
                    setPptMonthInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptMonthInputHasError(false);
                }

                if (pptYearValue === undefined || pptYearValue === '') {
                    setPptYearInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptYearInputHasError(false);
                }
            }
        }

        if (pptMonthValue !== undefined) {
            if (pptMonthValue !== '') {
                if (pptDateValue === undefined || pptDateValue === '') {
                    setPptDateInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptDateInputHasError(false);
                }

                if (pptYearValue === undefined || pptYearValue === '') {
                    setPptYearInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptYearInputHasError(false);
                }
            }
        }

        if (pptYearValue !== undefined) {
            if (pptYearValue !== '') {
                if (pptDateValue === undefined || pptDateValue === '') {
                    setPptDateInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptDateInputHasError(false);
                }

                if (pptMonthValue === undefined || pptMonthValue === '') {
                    setPptMonthInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("number");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setPptMonthInputHasError(false);
                }
            }
        }

        if (additionalValue !== undefined && additionalValue !== '' && (issuingCountryValue === undefined || issuingCountryValue === '')) {
            setIssuingCountryInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setIssuingCountryInputHasError(false);
        }

        if (additionalValue === undefined || additionalValue === '') {
            // setAdditionalInputHasError(true);
            // return;
        }
        else {
            // setAdditionalInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (document.querySelector('#number').value === '')) {
            setNumberInputHasError(true);
            setNumberInvalidInputHasError(false);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setNumberInputHasError(false);
        }

        if (document.querySelector('#number') !== null && document.querySelector('#number').value !== '' && !document.querySelector('#number').value.match('^[a-zA-Z0-9 ]*$')) {
            setNumberInputHasError(false);
            setNumberInvalidInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setNumberInvalidInputHasError(false);
        }

        if (additionalValue !== undefined && additionalValue !== '' && (additionalIssuingCountryValue === undefined || additionalIssuingCountryValue === '')) {
            setAdditionalIssuingCountryInputHasError(true);
            const yOffset = -300;
            const element2 = document.getElementById("number");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setAdditionalIssuingCountryInputHasError(false);
        }

        if (dateValue !== undefined && dateValue !== '' && monthValue !== undefined && monthValue !== '' && yearValue !== undefined && yearValue !== '') {
            const currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var currentMonth = currentDate.getMonth() + 1;
            var currentDay = currentDate.getDate();
            var formattedCurrentDate = new Date(currentYear, currentMonth - 1, currentDay);

            var dobDate = yearValue + monthValue + dateValue;
            var dobYear = dobDate.substring(0, 4);
            var dobMonth = dobDate.substring(4, 6);
            var dobDay = dobDate.substring(6, 8);
            var formattedDobDate = new Date(dobYear, dobMonth - 1, dobDay);

            if (dobYear !== '0000' && dobMonth !== '00' && dobDay !== '00') {
                //var dateDiffValue = dateDiff(formattedCurrentDate, formattedDobDate);
                //console.log(dateDiffValue)
                //dateDiffValue.years <= 0 || dateDiffValue.months <= 0 || dateDiffValue.days <= 0

                if (formattedDobDate > formattedCurrentDate) {
                    toggleHandler("travelerDetailsDiv", "lblAdvance");
                    setDobInvalidInputHasError(true);
                    const yOffset = -300;
                    const element2 = document.getElementById("ticketNumber");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                } else {
                    setDobInvalidInputHasError(false);
                }
            }
        }

        if (selectedTraveler !== undefined && selectedTraveler !== '') {
            let traveler = props.traveler.travelerInfo.traveler.filter(a => a.travelerRefNumber === selectedTraveler);
            if (traveler.length > 0) {
                traveler[0].personName.namePrefix = titleValue;
                traveler[0].personName.givenName = toPascalCase(document.querySelector('#firstName').value);
                traveler[0].personName.middleName = toPascalCase(document.querySelector('#middleName').value);
                traveler[0].personName.surname = toPascalCase(document.querySelector('#lastName').value);
                traveler[0].passengerTypeCode = paxTypeValue;
                traveler[0].gender = genderValue;
                traveler[0].dateOfBirth.date = yearValue + monthValue + dateValue;
                if (traveler[0].ticketInfo !== undefined) {
                    traveler[0].ticketInfo[0].ticketNumber = document.querySelector('#ticketNumber').value;
                }
                if (traveler[0].document === undefined) {
                    let documentTemp = [];
                    documentTemp = [
                        {
                            docID: document.querySelector('#passportNumber') !== null ? document.querySelector('#passportNumber').value : '',
                            docType: "Passport",
                            docHolderName: "",
                            docIssueAuthority: "",
                            docIssueLocation: "",
                            gender: "",
                            birthDate: "",
                            effectiveDate: "",
                            expireDate: pptYearValue + pptMonthValue + pptDateValue,
                            docIssueCountry: issuingCountryValue,
                            docHolderNationality: countryValue

                        },
                        {
                            docID: document.querySelector('#number') !== null ? document.querySelector('#number').value : '',
                            docType: additionalValue,
                            docIssueCountry: additionalIssuingCountryValue

                        }
                    ]

                    traveler[0].document = documentTemp;
                }
                else {
                    traveler[0].document[0].docID = document.querySelector('#passportNumber') !== null ? document.querySelector('#passportNumber').value : '';
                    traveler[0].document[0].docType = "Passport";
                    traveler[0].document[0].expireDate = pptYearValue + pptMonthValue + pptDateValue;
                    traveler[0].document[0].docIssueCountry = issuingCountryValue;
                    traveler[0].document[0].docHolderNationality = countryValue;
                    traveler[0].document[1].docID = document.querySelector('#number') !== null ? document.querySelector('#number').value : '';
                    traveler[0].document[1].docType = additionalValue;
                    traveler[0].document[1].docIssueCountry = additionalIssuingCountryValue;
                }
            }

            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (props.pnrType !== undefined) {
                bookingJSON.data.importPNR = props.pnrType
            }
            bookingJSON.data.travelerInfo.traveler = props.traveler.travelerInfo.traveler;
            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            history.push(`/ImportPNR`);
            clearTravelersFields();
        }
        else {
            let travelerRefNumber = 0;
            if (props.traveler.travelerInfo !== undefined) {
                props.traveler.travelerInfo.traveler.forEach(element => {
                    travelerRefNumber = element.travelerRefNumber
                });
            }

            travelerRefNumber = parseInt(travelerRefNumber) + 1;
            let airTravelerDetails = {
                personName: {
                    namePrefix: titleValue,
                    givenName: toPascalCase(document.querySelector('#firstName').value),
                    middleName: toPascalCase(document.querySelector('#middleName').value),
                    surname: toPascalCase(document.querySelector('#lastName').value)
                },
                gender: genderValue !== undefined && genderValue !== '' ? genderValue : '',
                telephone: [{
                    phoneNumber: '',
                    mobile: ''
                }],
                email: [{
                    TitleId: ''
                }],
                dateOfBirth: {
                    date: yearValue + monthValue + dateValue
                },
                document: [
                    {
                        docID: document.querySelector('#passportNumber') !== null ? document.querySelector('#passportNumber').value : '',
                        docType: "Passport",
                        docHolderName: "",
                        docIssueAuthority: "",
                        docIssueLocation: "",
                        gender: "",
                        birthDate: "",
                        effectiveDate: "",
                        expireDate: pptYearValue + pptMonthValue + pptDateValue,
                        docIssueCountry: issuingCountryValue,
                        docHolderNationality: countryValue

                    },
                    {
                        docID: document.querySelector('#number') !== null ? document.querySelector('#number').value : '',
                        docType: additionalValue,
                        docIssueCountry: additionalIssuingCountryValue

                    }
                ],
                passengerTypeCode: paxTypeValue !== undefined && paxTypeValue !== '' ? paxTypeValue : '',
                travelerRefNumber: travelerRefNumber.toString(),
                ticketInfo: [{
                    ticketNumber: document.querySelector('#ticketNumber').value
                }]
            }

            if (props.traveler.travelerInfo !== undefined) {
                props.traveler.travelerInfo.traveler.push(airTravelerDetails);

                let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                if (props.pnrType !== undefined) {
                    bookingJSON.data.importPNR = props.pnrType;
                }
                //bookingJSON.data.OfficeID = JSON.parse(localStorage.getItem('officeID'));
                bookingJSON.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                bookingJSON.data.UserID = JSON.parse(localStorage.getItem('userID'));
                bookingJSON.data.travelerInfo.traveler = props.traveler.travelerInfo.traveler;
                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                history.push(`/ImportPNR`);
                clearTravelersFields();
            }
            else {
                let bookingJSON = CreateImportBookingJSON();
                if (props.pnrType !== undefined) {
                    bookingJSON.data.importPNR = props.pnrType;
                }
                //bookingJSON.data.OfficeID = JSON.parse(localStorage.getItem('officeID'));
                bookingJSON.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                bookingJSON.data.UserID = JSON.parse(localStorage.getItem('userID'));
                bookingJSON.data.travelerInfo.traveler.push(airTravelerDetails);
                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                history.push(`/ImportPNR`);
                clearTravelersFields();
            }
        }

        if (document.querySelector('#btnAddTraveler') !== undefined && document.querySelector('#btnAddTraveler') !== null) {
            document.querySelector('#btnAddTraveler').innerHTML = "&nbsp; &nbsp;&nbsp; Add &nbsp; &nbsp;&nbsp;";
        }
    }

    function onDeleteTraveler(travelerRefNumber) {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let bookingJSONModified = bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber !== travelerRefNumber);

        let newTravelerRefNumber = 1;
        if (bookingJSONModified !== undefined) {
            bookingJSONModified.forEach(element => {
                element.travelerRefNumber = newTravelerRefNumber.toString();
                newTravelerRefNumber = parseInt(newTravelerRefNumber) + 1;
            });
        }

        bookingJSON.data.travelerInfo.traveler = bookingJSONModified;
        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        onDeleteAddOns(bookingJSON, travelerRefNumber);
        history.push(`/ImportPNR`);
    }

    function onDeleteAddOns(bookingJSON, travelerRefNumber) {
        if (bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0] !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn !== undefined) {
            let addOn = bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.filter(a => !(a.travelerRefNumber == travelerRefNumber));
            bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn = addOn;
        }
        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
    }

    function onConfirm(travelerRefNumber) {
        SetTravelerRefNumberForDelete(travelerRefNumber);
        setConfirm(true);
    }
    function okHandler() {
        onDeleteTraveler(travelerRefNumberForDelete);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function onConfirmPaxPricing(paxType, travelerName) {
        SetPaxTypeForDelete(paxType)
        SetPaxNameForDelete(travelerName)
        setPaxPricingConfirm(true);
    }
    function okHandlerPaxPricing() {
        setPaxPricingConfirm(false);
    };
    function cancelHandlerPaxPricing() {
        onDeletePrice(paxTypeForDelete);
        let paxTypesAdded = [];
        paxTypesAdded.push(paxTypeForDelete);
        reCalculateFare(paxTypesAdded);
        setPaxPricingConfirm(false);
    };

    function onDeletePrice(paxType) {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let bookingJSONModified = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code !== paxType);

        if (bookingJSONModified.length > 0) {
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity = bookingJSONModified;
        }
        else {
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo = "";
        }

        sortAirItineraryPassenger(bookingJSON);
        history.push(`/ImportPNR`);
        //history.go();
    }

    function sortAirItineraryPassenger(bookingJSON) {
        let passengerTypeQuantityTemp = [];
        let adtPassengerType = '';
        let cnnPassengerType = '';
        let infPassengerType = '';
        let insPassengerType = '';

        if (bookingJSON !== '' && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
            adtPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'ADT');
            cnnPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'CNN');
            infPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'INF');
            insPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'INS');

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity = passengerTypeQuantityTemp;

            if (adtPassengerType.length > 0) {
                adtPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (cnnPassengerType.length > 0) {
                cnnPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (infPassengerType.length > 0) {
                infPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (insPassengerType.length > 0) {
                insPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }
        }

        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        //return bookingJSON;
    }

    function reCalculateFare(paxTypesAdded) {
        if (paxTypesAdded.length > 0) {
            let totalBaseFare = 0;
            let totalTax = 0;
            let totalFare = 0;
            let totalCommission = 0;
            let totalTransaction = 0;

            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    elementPassengerTypeQuantity.quantity = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPassengerTypeQuantity.code).length.toString();
                    totalBaseFare = (parseFloat(elementPassengerTypeQuantity.baseFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalBaseFare;
                    totalTax = (parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTax;
                    totalTransaction = (parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTransaction;
                    totalCommission = (parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalCommission;
                    totalFare = ((parseFloat(elementPassengerTypeQuantity.totalFare.amount)) * parseInt(elementPassengerTypeQuantity.quantity)) + totalFare;
                });

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = totalFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = totalBaseFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = totalTax;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = totalTransaction;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = totalCommission;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount.toString();
            }

            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            //sessionStorage.setItem("finalTotalFare", bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
        }
    }

    function assignTravelDetails(event) {
        event.preventDefault();
        let setValidationStaus = true;

        if (sessionStorage.getItem("BookingData") === null) {
            setValidationStaus = false;
            setError({
                title: "Message",
                message: `${t('ErrorMessage')}`,
            });
        }

        if (sessionStorage.getItem("BookingData") !== null) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            // bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
            //     if (elementTraveler.personName.namePrefix === undefined || elementTraveler.personName.namePrefix === '') {
            //         setValidationStaus = false;
            //         setError({
            //             title: "Message",
            //             message: `${t('validationTitle1')}`,
            //         });
            //     }
            // });

            // bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
            //     if (elementTraveler.gender === '') {
            //         setValidationStaus = false;
            //         setError({
            //             title: "Message",
            //             message: `${t('validationGender1')}`,
            //         });
            //     }
            // });

            let travelersAll = bookingJSON.data.travelerInfo.traveler;
            let travelersAdult = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'ADT');
            let travelersChild = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'CNN');
            let travelersInfant = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'INF' || a.passengerTypeCode === 'INS');

            if (travelersAll.length === 0) {
                setValidationStaus = false;
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
            else if (travelersAdult.length === 0 && travelersInfant.length > 0) {
                setValidationStaus = false;
                setError({
                    title: "Message",
                    message: `${t('ErrorMessageAdultMandatory')}`,
                });
            }
            else if (travelersInfant.length > 0 && travelersInfant.length > travelersAdult.length) {
                setValidationStaus = false;
                setError({
                    title: "Message",
                    message: `${t('ErrorMessageAdultInfantValidation')}`,
                });
            }

            if (travelersAll.length > 9) {
                setValidationStaus = false;
                setError({
                    title: "Message",
                    message: `${t('ErrorMessageTotalPaxLimitValidation')}`,
                });
            }

            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
                let travelersCount = 0;
                let travelersPriceCount = 0;
                let paxTypesAdded = [];
                let travelersPriceData = '';
                let travelersData = '';

                if (bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.length > 0 && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.length > 0) {
                    pax_type_list.forEach(elementPaxTypeList => {
                        travelersCount = bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPaxTypeList.value);
                        travelersPriceCount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0].quantity !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0].quantity : 0;

                        if (travelersPriceCount > 0 && travelersCount.length === 0 && travelersCount.length < travelersPriceCount) {
                            travelersPriceData = bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0].quantity !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0] : '';

                            if (travelersPriceData.travelerRefNumbers !== undefined && travelersPriceData.travelerRefNumbers[0] !== undefined) {
                                setValidationStaus = false;
                                travelersData = bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === travelersPriceData.travelerRefNumbers[0].toString());
                                if (travelersData[0] !== undefined) {
                                    onConfirmPaxPricing(elementPaxTypeList.value, travelersData[0].personName.givenName + " " + travelersData[0].personName.middleName + " " + travelersData[0].personName.surname);
                                }
                                else {
                                    //setValidationStaus = true;
                                    onDeletePrice(elementPaxTypeList.value);
                                    paxTypesAdded.push(elementPaxTypeList.value);
                                }
                            }
                            else {
                                setValidationStaus = true;
                                onDeletePrice(elementPaxTypeList.value);
                                paxTypesAdded.push(elementPaxTypeList.value);
                            }
                        }
                        else if (travelersPriceCount > 0 && travelersCount.length > 0 && travelersCount.length !== travelersPriceCount) {
                            if (setValidationStaus) {
                                paxTypesAdded.push(elementPaxTypeList.value);
                            }
                        }
                    });
                }
                if (setValidationStaus) {
                    reCalculateFare(paxTypesAdded);
                }
            }
        }

        if (setValidationStaus) {
            props.onContinueButton("1");
            window.scrollTo({
                top: 250,
                behavior: "smooth",
            });
        }
    }

    const errorHandler = () => {
        setError(null);
    };

    return (
        <Fragment>
            {error1 && (
                <ErrorModal
                    title={error1.title}
                    message={t(error1.message)}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            {confirm && (
                <ConfirmationModal
                    title={t('confirmationMessageTitle')}
                    message={t('confirmationMessage')}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            {paxPricingConfirm && (
                <ConfirmationModal
                    title={t('confirmationMessageTitle')}
                    message={t('paxInPricingNotInTravelerSuffix') + paxNameForDelete + t('paxInPricingNotInTravelerPrefix')}
                    //message={'Data is incorrect for ELLIE WESLEY MISS  PEARSON because pax type mismatch between travler and pricing.\nOn click of "OK" you can update pax details or on "Cancel" this pax type will remove from pricing?'}
                    onOk={okHandlerPaxPricing}
                    onCancel={cancelHandlerPaxPricing}
                ></ConfirmationModal>
            )}
            <div className="accordion_flex_area">
                <div className="accordion_left_side position-relative text-muted">
                    <h5>1</h5>
                    <span id="completeOne" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button id="headingOne" className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                            Travelers
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show"
                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className="control col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Pax Type <span className="error-icon"> * </span></label>
                                    <Dropdown
                                        options={pax_type_list} onChange={handleChangePaxType}
                                        value={pax_type_list.filter(option => option.value === paxTypeValue)}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    />
                                    {paxTypeInputHasError &&
                                        <p className="error-text">{t('validationPaxType')}</p>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-2 control">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Title <span className="error-icon"> </span></label>
                                    <Dropdown
                                        options={paxTypeValue === 'ADT' ? titles_name : titles_name_child} onChange={handleChangeTitle}
                                        value={paxTypeValue === 'ADT' ? titles_name.filter(option => option.value === titleValue) : titles_name_child.filter(option => option.value === titleValue)}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                    />
                                    {titleInputHasError &&
                                        <p className="error-text">{t('validationTitle')}</p>
                                    }
                                </div>
                                <div className="col-md-10">
                                    <div className='row'>
                                        <div className="control col-lg-4 col-md-4 col-sm-12">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">First Name <span className="error-icon"> *</span></label>
                                            <input type="text" id='firstName' className="form-control form-control-sm" disabled={disabledControl} />
                                            {firstNameInputHasError &&
                                                <p className="error-text">{t('validationFirstName')}</p>
                                            }
                                            {firstNameInvalidInputHasError &&
                                                <p className="error-text">{t('validationFirstNameInvalidData')}</p>
                                            }
                                        </div>
                                        <div className="control col-lg-4 col-md-4 col-sm-12">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Middle Name</label>
                                            <input type="text" id='middleName' className="form-control form-control-sm" disabled={disabledControl} />
                                            {middleNameInvalidInputHasError &&
                                                <p className="error-text">{t('validationMiddleNameInvalidData')}</p>
                                            }
                                        </div>
                                        <div className="control col-lg-4 col-md-4 col-sm-12">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Last Name <span className="error-icon"> *</span></label>
                                            <input type="text" id='lastName' className="form-control form-control-sm" disabled={disabledControl} />
                                            {lastNameInputHasError &&
                                                <p className="error-text">{t('validationLastName')}</p>
                                            }
                                            {lastNameInvalidInputHasError &&
                                                <p className="error-text">{t('validationLastNameInvalidData')}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="control col-md-3 mb-0 mt-2">
                                    <div className="form-switch form-switch-sm padding-left-0 mb-2">
                                        <label for="advance" className="mb-2 h6 text-primary me-2 cursor-pointer"><i id="lblAdvance" className="fas fa-plus-square"></i> Additional</label>
                                        <input id="advance" className="form-check-input ms-1 d-none" type="checkbox" onChange={() => toggleHandler("travelerDetailsDiv", "lblAdvance")} />
                                    </div>
                                </div>
                            </div>
                            <div className='    ' id='travelerDetailsDiv'>
                                <div className='row'>
                                    <div className="control col-lg-9 col-md-12 col-sm-12">
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-3 col-sm-12 control">
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Gender <span className="error-icon"> </span></label>
                                                <Dropdown
                                                    options={gender_list} onChange={handleChangeGender}
                                                    value={gender_list.filter(option => option.value === genderValue)}
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                    isDisabled={disabledControl}
                                                />
                                                {genderInputHasError &&
                                                    <p className="error-text">{t('validationGender')}</p>
                                                }
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Date of Birth <span className="error-icon">  </span> <i title="Date of Birth(Age of Adult should be 12 or above on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                <Dropdown
                                                    options={dateList} onChange={handleChangeDobDate}
                                                    value={dateList.filter(option => option.value === dateValue)}
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                    isDisabled={disabledControl}
                                                />
                                                {dateInputHasError &&
                                                    <p className="error-text">{t('validationDOB')}</p>
                                                }
                                                {dobInvalidInputHasError &&
                                                    <p className="error-text">{t('validationDOBInvalidData')}</p>
                                                }
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                                <Dropdown
                                                    options={monthList} onChange={handleChangeDobMonth}
                                                    value={monthList.filter(option => option.value === monthValue)}
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                    isDisabled={disabledControl}
                                                />
                                                {monthInputHasError &&
                                                    <p className="error-text">{t('validationDOB')}</p>
                                                }
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                                <Dropdown
                                                    options={paxTypeValue === 'ADT' ? dobYearList : paxTypeValue === 'CNN' ? dobChildYearList : dobInfantYearList} onChange={handleChangeDobYear}
                                                    value={paxTypeValue === 'ADT' ? dobYearList.filter(option => option.value === yearValue) : paxTypeValue === 'CNN' ? dobChildYearList.filter(option => option.value === yearValue) : dobInfantYearList.filter(option => option.value === yearValue)}
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                    isDisabled={disabledControl}
                                                />
                                                {yearInputHasError &&
                                                    <p className="error-text">{t('validationDOB')}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="control col-md-3">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Ticket Number </label>
                                        <input type="text" id='ticketNumber' className="form-control form-control-sm" maxLength={13} disabled={disabledControl} />
                                        {ticketNumberInvalidInputHasError &&
                                            <p className="error-text">{t('validationTicketNumberInvalidData')}</p>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6 control">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Nationality <span className="error-icon"> </span></label>
                                        <Dropdown
                                            options={countryOptions} onChange={handleChangeCountry}
                                            value={countryOptions.filter(option => option.value === countryValue)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                        />
                                        {countryInputHasError &&
                                            <p className="error-text">{t('validationCountry')}</p>
                                        }
                                    </div>
                                    <div className="col-md-6 control">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Passport No. <span className="error-icon"> </span></label>
                                        <input type="text" id='passportNumber' className="form-control form-control-sm" disabled={disabledControl} maxLength={14} />
                                        {passportNumberInputHasError &&
                                            <p className="error-text">{t('validationPassportNumber')}</p>
                                        }
                                        {passportNumberInvalidInputHasError &&
                                            <p className="error-text">{t('validationPassportNumberInvalidData')}</p>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-3 ">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Passport Expiry<i id={"tooltipsPassportExpiry"} className="fa-solid fa-circle-info text-sky-blue ms-1"></i></label>
                                        <Dropdown
                                            options={dateList} onChange={handleChangePptDate}
                                            value={dateList.filter(option => option.value === pptDateValue)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            isDisabled={disabledControl}
                                        />
                                        {pptDateInputHasError &&
                                            <p className="error-text">{t('validationPassport')}</p>
                                        }
                                    </div>
                                    <div className="col-md-3 ">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <Dropdown
                                            options={monthList} onChange={handleChangePptMonth}
                                            value={monthList.filter(option => option.value === pptMonthValue)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            isDisabled={disabledControl}
                                        />
                                        {pptMonthInputHasError &&
                                            <p className="error-text">{t('validationPassport')}</p>
                                        }
                                    </div>
                                    <div className="col-md-3 control">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <Dropdown
                                            options={expiryYearList} onChange={handleChangePptYear}
                                            value={expiryYearList.filter(option => option.value === pptYearValue)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            isDisabled={disabledControl}
                                        />
                                        {pptYearInputHasError &&
                                            <p className="error-text">{t('validationPassport')}</p>
                                        }
                                    </div>
                                    <ReactTooltip
                                        anchorId={"tooltipsPassportExpiry"}
                                        place="bottom"
                                        variant="info"
                                        multiline={true}
                                        className="tooltip"
                                        html={"Passport Expiry should be more than <br>6 months from date of travel."}
                                    />
                                    <div className="col-md-3 control">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Issuing Country <span className="error-icon"> </span></label>
                                        <Dropdown
                                            options={countryOptions} onChange={handleChangeIssuingCountry}
                                            value={countryOptions.filter(option => option.value === issuingCountryValue)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                        />
                                        {issuingCountryInputHasError &&
                                            <p className="error-text">{t('validationIssuingCountry')}</p>
                                        }
                                    </div>
                                </div>
                                {
                                    paxTypeValue !== 'INF' && paxTypeValue !== 'INS' &&
                                    <div className='row'>
                                        <div className="col-md-4 control">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Additional <span className="error-icon"> </span></label>
                                            <Dropdown
                                                options={IdentificationOptions} onChange={handleChangeAdditional}
                                                value={IdentificationOptions.filter(option => option.value === additionalValue)}
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                            />
                                            {additionalInputHasError &&
                                                <p className="error-text">{t('validationAdditional')}</p>
                                            }
                                        </div>
                                        <div className="col-md-4 control">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Number <span className="error-icon"> </span></label>
                                            <input type="text" id='number' className="form-control form-control-sm" disabled={disabledControl} maxLength={9} />
                                            {numberInputHasError &&
                                                <p className="error-text">{t('validationNumber')}</p>
                                            }
                                            {numberInvalidInputHasError &&
                                                <p className="error-text">{t('validationNumberInvalidData')}</p>
                                            }
                                        </div>
                                        <div className="col-md-4 control">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Issuing Country <span className="error-icon"> </span></label>
                                            <Dropdown
                                                options={countryOptions} onChange={handleChangeAdditionalIssuingCountry}
                                                value={countryOptions.filter(option => option.value === additionalIssuingCountryValue)}
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                            />
                                            {additionalIssuingCountryInputHasError &&
                                                <p className="error-text">{t('validationAdditionalIssuingCountry')}</p>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='row'>
                                <div className="control col-md-12 my-auto">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                    <button id='btnAddTraveler' className="btn text-white gt-main-btn ms-1 btn-sm float-lg-end float-md-end" onClick={onAddTraveler}>&nbsp; &nbsp;&nbsp; Add &nbsp; &nbsp;&nbsp;</button>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className=' table-responsive mt-2 table-wrapper width-900px'>
                                        <hr></hr>
                                        {
                                            props.traveler.travelerInfo !== undefined &&

                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr >
                                                        <th scope='col'>Pax Type</th>
                                                        <th className="min-width-185" scope='col'>Name</th>
                                                        <th scope="col">Gender</th>
                                                        <th scope="col">Date of Birth</th>
                                                        <th scope="col">Ticket Number</th>
                                                        {/* <th scope="col">Nationality</th>
                                                        <th scope="col">Passport No.</th>
                                                        <th scope="col">Passport Expiry</th>
                                                        <th scope="col">Issuing Country</th>
                                                        <th scope="col">Additional</th>
                                                        <th scope="col">Number</th>
                                                        <th scope="col">Issuing Country</th> */}
                                                        <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.traveler.travelerInfo.traveler.map((traveler) => (
                                                            <tr>
                                                                <td>{traveler.passengerTypeCode === 'ADT' ? 'Adult' : traveler.passengerTypeCode === 'CNN' ? 'Child' : 'Infant'}</td>
                                                                <td>{traveler.personName.namePrefix !== undefined ? traveler.personName.namePrefix + " " + toPascalCase(traveler.personName.givenName) + " " + toPascalCase(traveler.personName.middleName) + " " + toPascalCase(traveler.personName.surname) : toPascalCase(traveler.personName.givenName) + " " + toPascalCase(traveler.personName.middleName) + " " + toPascalCase(traveler.personName.surname)}</td>
                                                                <td>{traveler.gender === 'M' || traveler.gender === 'MALE' ? 'Male' : traveler.gender === 'F' || traveler.gender === 'FEMALE' ? 'Female' : ''}</td>
                                                                <td>{getFormatDate(traveler.dateOfBirth.date)}</td>
                                                                <td>{traveler.ticketInfo !== undefined ? traveler.ticketInfo[0].ticketNumber : 'N/A'}</td>
                                                                {/* <td>{traveler.document !== undefined && traveler.document[0] !== undefined && traveler.document[0].docHolderNationality !== undefined && traveler.document[0].docHolderNationality !== null ? countryOptions.filter(option => option.value === traveler.document[0].docHolderNationality) !== undefined && countryOptions.filter(option => option.value === traveler.document[0].docHolderNationality)[0] !== undefined ? countryOptions.filter(option => option.value === traveler.document[0].docHolderNationality)[0].label : '' : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[0] !== undefined && traveler.document[0].docID !== undefined && traveler.document[0].docID !== null && traveler.document[0].docID !== '' ? traveler.document[0].docID : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[0] !== undefined && traveler.document[0].expireDate !== undefined && traveler.document[0].expireDate !== null && traveler.document[0].expireDate !== '' && traveler.document[0].expireDate.length === 8 ? getFormatDate(traveler.document[0].expireDate) : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[0] !== undefined && traveler.document[0].docIssueCountry !== undefined && traveler.document[0].docIssueCountry !== null ? countryOptions.filter(option => option.value === traveler.document[0].docIssueCountry) !== undefined && countryOptions.filter(option => option.value === traveler.document[0].docIssueCountry)[0] !== undefined ? countryOptions.filter(option => option.value === traveler.document[0].docIssueCountry)[0].label : '' : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[1] !== undefined && traveler.document[1].docType !== undefined && traveler.document[1].docType !== null && traveler.document[1].docType !== '' ? IdentificationOptions.filter(option => option.value === traveler.document[1].docType) !== undefined && IdentificationOptions.filter(option => option.value === traveler.document[1].docType)[0] !== undefined ? IdentificationOptions.filter(option => option.value === traveler.document[1].docType)[0].label : '' : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[1] !== undefined && traveler.document[1].docID !== undefined && traveler.document[1].docID !== null && traveler.document[1].docID != '' ? traveler.document[1].docID : ''}</td>
                                                                <td>{traveler.document !== undefined && traveler.document[1] !== undefined && traveler.document[1].docIssueCountry !== undefined && traveler.document[1].docIssueCountry !== null ? countryOptions.filter(option => option.value === traveler.document[1].docIssueCountry) !== undefined && countryOptions.filter(option => option.value === traveler.document[1].docIssueCountry)[0] !== undefined ? countryOptions.filter(option => option.value === traveler.document[1].docIssueCountry)[0].label : '' : ''}</td> */}

                                                                <td className='text-center sticky-col last-col bg-light'>
                                                                    <div className="text-center">
                                                                        <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" onClick={() => onEditTraveler(traveler.travelerRefNumber)}><i className="fa-solid fa-pen"></i></a>
                                                                        <button className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" onClick={() => onConfirm(traveler.travelerRefNumber)}><i className="fas fa-trash-alt"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row d-none'>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                    <span className="h6 text-primary" > <span className="text-black fw-bold">Note : </span>  Passport details are mandatory if <em>Known Traveler Number</em> or <em>Redress Number</em> is specified.</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                    <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="float-md-end float-lg-end">
                                        {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                        <button type="button" onClick={assignTravelDetails} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>)
}
export default TravelerDetails
