import { useState, useRef, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import Dropdown from 'react-dropdown';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'

const CityForm = (props) => {
  const { t } = useTranslation(['translation.City']);
  /*  */
  const cityCodeInputRef = useRef('');
  const cityNameInputRef = useRef('');
  const cityGmtTimeDifferenceInputRef = useRef('');

  const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
  const [cityCodeLengthInputHasError, setCityCodeLengthInputHasError] = useState(false);
  const [cityNameInputHasError, setCityNameInputHasError] = useState(false);
  const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
  const [cityGmtTimeDifferenceInputHasError, setCityGmtTimeInputHasError] = useState(false);
  const [gmtIndicatorInputHasError, setGmtIndicatorInputHasError] = useState(false);

  const [country_Code, setCountry_Code] = useState('');
  /* const [country_Name,setCountry_Name]=useState('');    */
  const [iSDisabled, setiSDisabledDDL] = useState('false');
  const [gmtIndicator, setGmtIndicator] = useState();

  const [cityCodeInvalidInputError, setCityCodeInvalidInputError] = useState(false);
  const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);
  const [cityGmtInvalidInputError, setCityGmtInvalidInputError] = useState(false);
  const history = useHistory();
  const countryCodeValue = (country_Code) => {
    setCountry_Code(country_Code);
    setCountryCodeInputHasError(false);
  };
  const countryNameText = (country_Name) => {
    /*  setCountry_Name(country_Name); */
  };

  useEffect(() => {
    // const areaFocus = document.querySelector('#pageTitle');
    // areaFocus.scrollIntoView( { behavior: 'smooth', block: 'end' } );
    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    if (props.loadedProfileDetails.length !== 0) {
      cityCodeInputRef.current.value = `${props.loadedProfileDetails.cityCode !== undefined ? props.loadedProfileDetails.cityCode : ''}`;
      cityNameInputRef.current.value = `${cityNameInputRef.current.value === '' ? props.loadedProfileDetails.cityName : cityNameInputRef.current.value}`;
      //countryCodeInputRef.current.value = props.loadedProfileDetails.countryCode; //country_Code;
      if (country_Code === undefined || country_Code === '') {
        setCountry_Code(props.loadedProfileDetails.countryCode);
      }

      if (cityGmtTimeDifferenceInputRef.current.value === '') {
        cityGmtTimeDifferenceInputRef.current.value = props.loadedProfileDetails.cityGMTTimeDiff.substring(1, props.loadedProfileDetails.cityGMTTimeDiff.length);
      }

      if (gmtIndicator === undefined || gmtIndicator === '') {
        setGmtIndicator(props.loadedProfileDetails.cityGMTTimeDiff.substring(0, 1));
      }
    }

    setiSDisabledDDL('false');

    if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
    
      isEditMode = parseInt(sessionStorage.getItem('isEditable'));
    }

    if (isEditMode === 0) {
      document.getElementById('pageTitle').innerHTML = ' View City';
      document.getElementById("cityCode").readOnly = true;
      document.getElementById("cityName").readOnly = true;
      document.getElementById("gmtTimeDifference").readOnly = true;
      document.getElementById("btnNew").style.display = "none";
      document.getElementById("btnSave").style.display = "none";
      document.getElementById("btnReset").style.display = "none";
      setiSDisabledDDL('true');
      [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
        el.style.visibility = 'hidden';
      });

      document.getElementById("requiredIndicator").style.display = "none";
    }
    else if (isEditMode === 1) {
      document.getElementById('pageTitle').innerHTML = ' Edit City';
      document.getElementById("cityName").focus();
      document.getElementById("cityCode").readOnly = true;
      document.getElementById("cityName").readOnly = false;
      document.getElementById("gmtTimeDifference").readOnly = false;
      document.getElementById("btnNew").style.display = "";
      document.getElementById("btnSave").style.display = "";
      document.getElementById("btnReset").style.display = "";
      setiSDisabledDDL('false');
      document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
      document.getElementById("requiredIndicator").style.display = "";
    }

    if (props.loadedProfileDetails.length === 0) {
      //document.getElementById("cityCode").focus();
      // document.getElementById("btnSave").click();
      document.getElementById("btnNew").style.display = "none";
      document.getElementById('pageTitle').innerHTML = ' New City';

      if (gmtIndicator === undefined || gmtIndicator === '') {
        setGmtIndicator('+');
      }
    
    }

  }, [props.loadedProfileDetails.length,
  props.loadedProfileDetails.cityName,
  props.loadedProfileDetails.cityCode,
  props.loadedProfileDetails.countryCode,
  props.loadedProfileDetails.cityGMTTimeDiff,
    gmtIndicator, country_Code
  ]);

  useEffect(() => {
    if (props.loadedProfileDetails.length === 0) {
      document.getElementById("btnSave").click();
    }
  }, [props.loadedProfileDetails.length]);

  function onBlurCheck() {
    const enteredCityCode = cityCodeInputRef.current.value;
    const enteredCityName = cityNameInputRef.current.value;
    const countryCode = country_Code;
    const enteredCityGmtTimeDifference = cityGmtTimeDifferenceInputRef.current.value.substring(0, cityGmtTimeDifferenceInputRef.current.value.length);

    // optional: Could validate here
    if (enteredCityCode.trim() === '') {
      setCityCodeInputHasError(true);
      setCityCodeInvalidInputError(false);
      setCityCodeLengthInputHasError(false);
      return;
    }
    else {
      setCityCodeInputHasError(false);
    }

    if (enteredCityCode.length !== 3) {
      setCityCodeLengthInputHasError(true);
      setCityCodeInvalidInputError(false);
      setCityCodeInputHasError(false);
      return;
    }
    else {
      setCityCodeLengthInputHasError(false);
    }

    if (!enteredCityCode.match('^[a-zA-Z]+$')) {
      setCityCodeInvalidInputError(true);
      setCityCodeInputHasError(false);
      return;
    }
    else {
      setCityCodeInvalidInputError(false);
    }

    if (enteredCityName.trim() === '') {
      setCityNameInputHasError(true);
      setCityNameInvalidInputError(false);
      return;
    }
    else {
      setCityNameInputHasError(false);
    }

    if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
      setCityNameInvalidInputError(true);
      setCityNameInputHasError(false);
      return;
    }
    else {
      setCityNameInvalidInputError(false);
    }

    if (countryCode === '') {
      //countryCodeInputRef.current.focus();
      setCountryCodeInputHasError(true);
      return;
    }
    else {
      //countryCodeInputRef.current.focus();
      setCountryCodeInputHasError(false);
    }

    if (enteredCityGmtTimeDifference === '') {
      setCityGmtTimeInputHasError(true);
      setCityGmtInvalidInputError(false);
      return;
    }
    else {
      setCityGmtTimeInputHasError(false);
    }

    if (parseInt(enteredCityGmtTimeDifference) >= 0 && parseInt(enteredCityGmtTimeDifference) <= 1560) {
      setCityGmtInvalidInputError(false);
    }
    else {
      setCityGmtInvalidInputError(true)
      setCityGmtTimeInputHasError(false);
      return;
    }
  }
  function submitFormHandler(event) {
    event.preventDefault();

    const enteredCityCode = cityCodeInputRef.current.value;
    const enteredCityName = cityNameInputRef.current.value;
    const countryCode = country_Code;
    const enteredCityGmtTimeDifference = cityGmtTimeDifferenceInputRef.current.value;

    // optional: Could validate here
    if (enteredCityCode.trim() === '') {
      cityCodeInputRef.current.focus();
      setCityCodeInputHasError(true);
      return;
    }
    else {
      setCityCodeInputHasError(false);
    }

    if (enteredCityCode.length !== 3) {
      cityCodeInputRef.current.focus();
      setCityCodeLengthInputHasError(true);
      return;
    }
    else {
      setCityCodeLengthInputHasError(false);
    }

    if (!enteredCityCode.match('^[a-zA-Z]+$')) {
      cityCodeInputRef.current.focus();
      setCityCodeInvalidInputError(true);
      return;
    }
    else {
      setCityCodeInvalidInputError(false);
    }

    if (enteredCityName.trim() === '') {
      cityNameInputRef.current.focus();
      setCityNameInputHasError(true);
      return;
    }
    else {
      setCityNameInputHasError(false);
    }

    if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
      cityNameInputRef.current.focus();
      setCityNameInvalidInputError(true);
      return;
    }
    else {
      setCityNameInvalidInputError(false);
    }

    if (countryCode === '') {
      //countryCodeInputRef.current.focus();
      setCountryCodeInputHasError(true);
      return;
    }
    else {
      //countryCodeInputRef.current.focus();
      setCountryCodeInputHasError(false);
    }

    if (enteredCityGmtTimeDifference === '') {
      cityGmtTimeDifferenceInputRef.current.focus();
      setCityGmtTimeInputHasError(true);
      return;
    }
    else {
      setCityGmtTimeInputHasError(false);
    }

    if (parseInt(enteredCityGmtTimeDifference) >= 0 && parseInt(enteredCityGmtTimeDifference) <= 1560) {
      setCityGmtInvalidInputError(false);
    }
    else {
      cityGmtTimeDifferenceInputRef.current.focus();
      setCityGmtInvalidInputError(true);
      return;
    }

    if (gmtIndicator === undefined || gmtIndicator === '') {
      //countryCodeInputRef.current.focus();
      setGmtIndicatorInputHasError(true);
      return;
    }
    else {
      setGmtIndicatorInputHasError(false);
    }

    let ID;
    if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
      ID = '';
    } else {
      ID = props.loadedProfileDetails.id;
    }

    props.onAddCity({
      id: ID,
      countryCode: countryCode,
      cityCode: enteredCityCode,
      cityName: enteredCityName,
      cityGMTTimeDiff: gmtIndicator + enteredCityGmtTimeDifference,
      tboCityID: '',
      traceID: JSON.parse(localStorage.getItem('traceID')),
      userID: JSON.parse(localStorage.getItem('userID')),
      iPAddress:localStorage.getItem("IPAddress"),
    });
  }

  function changeLocation(event) {
    if (event.target.id === 'btnNew') {
      history.push('/NewCity')
      history.go()
    }

    if (event.target.id === 'btnReset') {
      if (props.loadedProfileDetails.length === 0) {
        history.go('/NewCity')
      }
      else {
        history.go(`/NewCity/${props.loadedProfileDetails.id}`)
      }
    }
  }

  const handleChangeGmtIndicator = (e) => {
    setGmtIndicator(e.value);
    if (gmtIndicator !== undefined || gmtIndicator !== '') {
      //countryCodeInputRef.current.focus();
      setGmtIndicatorInputHasError(false);
    }
  };

  const gmtIndicatorOptions = [
    { value: '+', label: '+' },
    { value: '-', label: '-' }
  ];

  function changeToUpperCase(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  function allowNumbersOnly(event) {
    var theEvent = event || window.event;
    var key;
    // Handle paste
    if (theEvent.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <Fragment>
      <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-30">
              <div className="card card-primary card-outline">
                <div className="card-body">
                  <div className="col-md-12 ">
                    <div className="form-group col-md-12">
                      <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>
                      <form className='form'>
                        {props.isLoading && (
                          <div className='loading'>
                            <LoadingSpinner />
                          </div>
                        )}
                        <div className='row'>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control input-placeholder'>
                              <label htmlFor='cityCode' className='h6 text-primary mb-2'>{t('cityCode')}<span className="error-icon"> *</span></label>
                              <input maxLength={3} className="form-control form-control-sm" type='text' required
                                id='cityCode'
                                ref={cityCodeInputRef}
                                onInput={changeToUpperCase}
                                onBlur={onBlurCheck}
                              />
                              {cityCodeInputHasError &&
                                <p className="error-text">{t('validationCityCode')}</p>
                              }
                              {cityCodeLengthInputHasError &&
                                <p className="error-text">{t('validationCityCodeLength')}</p>
                              }
                              {cityCodeInvalidInputError &&
                                <p className="error-text">{t('validationCityCodeInvalidData')}</p>
                              }
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control input-placeholder'>
                              <label className='h6 text-primary mb-2' htmlFor='cityName'>{t('cityName')}<span className="error-icon"> *</span></label>
                              <input maxLength="100" className='form-control form-control-sm ' required type='text'
                                id='cityName'
                                ref={cityNameInputRef}
                                onBlur={onBlurCheck}
                              />

                              {cityNameInputHasError &&
                                <p className="error-text">{t('validationCityName')}</p>
                              }
                              {cityNameInvalidInputError &&
                                <p className="error-text">{t('validationCityNameInvalidData')}</p>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='control input-placeholder'>
                          <label className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}<span className="error-icon"> *</span></label>
                          <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={props.loadedProfileDetails.countryCode} iSDisabled={iSDisabled} />
                          {countryCodeInputHasError &&
                            <p className="error-text">{t('validationCountryCode')}</p>
                          }
                        </div>
                        <div className='row'>
                          <label className='h6 text-primary mb-2' htmlFor='gmtTimeDifference'>{t('gmtTimeDifference')}<span className="error-icon"> *</span></label>
                          <div className='col-md-2 col-sm-12'>
                            <div className='control '>
                              {/* <label className='h6 text-primary mb-2' htmlFor='gmtSign'>GMT (Sign) <span className="error-icon"> *</span></label> */}

                              <Dropdown onChange={handleChangeGmtIndicator}
                                value={props.loadedProfileDetails.cityGMTTimeDiff !== undefined ? props.loadedProfileDetails.cityGMTTimeDiff.substring(0, 1) : gmtIndicator}
                                //value={gmtIndicator}
                                options={gmtIndicatorOptions}
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />}
                                controlClassName='form-control control form-select bg_input mb-0'
                                placeholder=""
                                disabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false} />
                              {gmtIndicatorInputHasError &&
                                <p className="error-text">{t('validationGmtIndicator')}</p>
                              }
                            </div>
                          </div>
                          <div className='col-md-10 col-sm-12'>
                            <div className='input-placeholder'>

                              <input maxLength="4" className='form-control form-control-sm ' required type='text'
                                id='gmtTimeDifference'
                                ref={cityGmtTimeDifferenceInputRef}
                                onBlur={onBlurCheck}
                                onKeyPress={allowNumbersOnly}
                              />
                              {/* 
                              <div className="placeholder">
                                Enter Time Difference<span>*</span>
                              </div>
                              */}
                            </div>
                            {cityGmtTimeDifferenceInputHasError &&
                              <p className="error-text">{t('validationCityGmtDifference')}</p>
                            }
                            {cityGmtInvalidInputError &&
                              <p className="error-text">{t('validationCityGmtDifferenceInvalidData')}</p>
                            }
                          </div>
                        </div>
                        <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                      </form>

                      <div className='mt-3 actions row'>
                        <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                          <a href="/AllCity" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                          <div className="float-md-end float-lg-end">
                            <a href="/AllCity" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                            {(checkSecurityDetails("Master", "City", "Master_City_Insert") || checkSecurityDetails("Master", "City", "Master_City_Update")) ?
                              <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                {t('save')}
                              </button>
                              : <span id="btnNew"></span>
                            }
                            {checkSecurityDetails("Master", "City","Master_City_Insert") ? 
                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                              {t('new')}
                            </button>
                            :<span id="btnNew"></span>}
                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                              {t('reset')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default CityForm;