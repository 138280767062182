import { Fragment } from "react";
import { Link } from "react-router-dom";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { useTranslation } from "react-i18next";
const RatePlanItem = (props) => {
    const { t } = useTranslation(['translation.RatePlanAllowed']);
    return <Fragment>

        <div className="row" key={props.id}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.companyName}
                                </h6>
                                <hr className="my-2" />
                            </div>
                            <div className="col-md-10">
                                <p className="card-text mb-0"><strong>Login Email ID: </strong>{props.loginEmailID}</p>
                            </div>
                            <div className="col-md-2 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    
                                    {checkSecurityDetails("Settings", "RatePlanAllowed", "Settings_RatePlanAllowed_View") && 
                                    <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateRatePlanAllowed/${props.officeID}/${props.companyName}/0/${props.id}`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                    {checkSecurityDetails("Settings", "RatePlanAllowed", "Settings_RatePlanAllowed_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateRatePlanAllowed/${props.officeID}/${props.companyName}/1/${props.id}`, edit: 1, }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};
export default RatePlanItem;