import CityForm from './Components/CityForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addCity, getSingleCity, updateCity } from '../../../services/City-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const NewCity = (props) => {
    useDocumentTitle('City')
    const { t } = useTranslation(['translation.City']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addCity
    } else {
        varHttp = updateCity
    }

    const { sendRequest: sendRequest1, data: loadedCityDetails, error } = useHttpGet(getSingleCity);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("Master", "City", "Master_City_Update") && Action === '1')
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "City", "Master_City_View") && Action === '0')
        history.push('/Unauthorized')

    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }

    let loadedData = [];
    for (let index in loadedCityDetails) {
        if (loadedCityDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedCityDetails[index];
            }
        }
    }

    const addCityHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/allcity');
        }
    };



    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    const CityFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <CityForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddCity={addCityHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {CityFormContent}
    </Fragment>
};

export default NewCity;
