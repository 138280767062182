import { useEffect, Fragment, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import { emailValidation } from "../../../../utils/Validation";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import Dropdown from 'react-select';

import { useDispatch, useSelector } from "react-redux";
import { exists } from "i18next";

const ConfigurationForm = (props) => {
    const { t } = useTranslation(['translation.Configuration']);
    const companyNameInputRef = useRef('');
    const history = useHistory();
    const [pageTitle, setPageTitle] = useState(' Configuration');
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [todoList, setTodoList] = useState([]);
    const [leftMenu, setleftMenu] = useState([]);


    const dispatch = useDispatch();

    let loadedData = props.loadedConfigurationDetails;
    let data = [];
    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    useEffect(() => {
        data = props.loadedConfigurationDetails[0].configurationsMenuDetails;//.filter(a => a.isEditable === true);
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });
        if (isEditMode === 0) {
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
        }

        async function getTodoList() {

            // for (let i in props.loadedConfigurationDetails) {
            //  data = props.loadedConfigurationDetails[i];



            // }
            dispatch({
                type: "UPDATE_TODO_LIST",
                value: data,
                menuID: "63ba8b6adc735aaed0390142",
            })
            setTodoList(data); //.filter(a => a.isEditable === true)
            setleftMenu(props.loadedConfigurationDetails);

        }
        getTodoList();
    }, [props.loadedConfigurationDetails, data])

    let allowFlightBooking = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_FLIGHT')[0].isEditable ? '' : 'Flight Booking';
    let allowHotelBooking = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOTEL')[0].isEditable ? '' : 'Hotel Booking';
    let allowFlightAvail = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_FLIGHT')[0].isEditable ? '' : 'Flight Availability';
    let allowHotelAvail = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOTEL')[0].isEditable ? '' : 'Hotel Availability';
    let allowBackGroundService = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOTEL')[0].isEditable ? '' : 'Background Service';
    let allowSinglePNR = todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_SINGLE_PNR')[0].isEditable ? '' : 'Single PNR';
    let allowSightseeing =  todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_SIGHTSEEING')[0].isEditable ? '' : 'Sightseeing';
    let allowNonB2b =  todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOLIDAYS')[0].isEditable ? '' : 'Non B2B';
    let allowHolidays =  todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOLIDAYS')[0].isEditable ? '' : 'Holidays';
    let allowMyTrip =  todoList.length > 0 && todoList.filter(a => a.keyCode === 'ALLOW_HOLIDAYS')[0].isEditable ? '' : 'My Trip';

    function showMenu(event) {
        event.preventDefault();

        setIsFirstTime(false);
        for (let i = 0; i < todoList.length; i++) {
            if (todoList[i].menuName !== allowFlightBooking
                && todoList[i].menuName !== allowHotelBooking
                && todoList[i].menuName !== allowFlightAvail
                && todoList[i].menuName !== allowHotelAvail
                && todoList[i].menuName !== allowBackGroundService
                && todoList[i].menuName !== allowSinglePNR
                && todoList[i].menuName !== allowSightseeing
                && todoList[i].menuName !== allowNonB2b
                && todoList[i].menuName !== allowHolidays
                && todoList[i].menuName !== allowMyTrip
            ) {

                const clickedElement = event.target;
                const clickedButton = clickedElement.closest('button');

                if (clickedButton.id === "Menu" + todoList[i].menuID) {
                    document.getElementById("Menu" + todoList[i].menuID).className = 'tabs-btn nav-link active';

                    document.getElementById(todoList[i].keyCode + todoList[i].menuID).style.display = 'block';
                } else {

                    document.getElementById("Menu" + todoList[i].menuID).className = 'tabs-btn nav-link';
                    document.getElementById(todoList[i].keyCode + todoList[i].menuID).style.display = 'none';
                }
            }
        }
    }
    function getIcons(menuName) {
        ///["MenuName",'IconClassname']
        let IconHashMap = new Map([
            ["Sabre Queue", 'far fa-dot-circle'],
            ["Hotel Booking", 'fas fa-hotel'],
            ["Hotel Availability", 'fas fa-hotel'],
            ["Flight Booking", 'fas fa-plane-departure'],
            ["Flight Availability", 'fas fa-plane-departure'],
            ["Main", 'far fa-dot-circle'],
            ["Single PNR", 'far fa-dot-circle'],
            ["Sightseeing", 'fas fa-hiking'],
            ["Menu", 'fas fa-bars'],
            ["Non B2B", 'fas fa-ban'],

        ]);

        if (IconHashMap.has(menuName)) {
            return IconHashMap.get(menuName);
        }
        return "far fa-dot-circle"
    }

    function LeftMenuShow() {

        var show = [];
        let menuName;
        let menuNameArray = [];
        for (let i = 0; i < todoList.length; i++) {
            companyNameInputRef.current.value = leftMenu[0].companyName;
            if (menuName !== todoList[i].menuName
                && todoList[i].menuName !== allowFlightBooking
                && todoList[i].menuName !== allowHotelBooking
                && todoList[i].menuName !== allowFlightAvail
                && todoList[i].menuName !== allowHotelAvail
                && todoList[i].menuName !== allowBackGroundService
                && todoList[i].menuName !== allowSinglePNR
                && todoList[i].menuName !== allowSightseeing
                && todoList[i].menuName !== allowNonB2b
                && todoList[i].menuName !== allowHolidays
                && todoList[i].menuName !== allowMyTrip) {
                let classMenu = todoList[i].menuID === '1' ? 'tabs-btn nav-link active' : 'tabs-btn nav-link ';
                let icon = getIcons(todoList[i].menuName)

                show.push(<li key={todoList[i].menuID} className="nav-item " role="presentation">

                    <button className={classMenu} type="button" role="tab"
                        onClick={showMenu}
                        id={"Menu" + todoList[i].menuID}
                        aria-controls="master-tb" aria-selected="true">
                        <i className={icon} aria-hidden="true"  ></i> {todoList[i].menuName}
                    </button>
                </li>)
            }
            menuNameArray.push(todoList[i].menuName);
            menuName = todoList[i].menuName;

        }


        return show;
    };


    function OnBlurCheck({ keyCode }) {
        const value = useSelector((state) => state[keyCode]);


    };

    function onResetHandler(event) {
        history.go('/serachConfiguration');
    }

    function bindDropDown(data) {
        let dropdownOptions = [];
        for (let index = 0; index < data.dropDownBindingValue.length; index++) {
            dropdownOptions = [...dropdownOptions, { value: data.dropDownBindingValue[index].ddID, label: data.dropDownBindingValue[index].ddName }]
        }
        return dropdownOptions;
    }

    const TextBox = ({ keyCode }) => {

        const value = useSelector((state) => state[keyCode]);
        const dispatch = useDispatch();
        let textBoxValue;
        function changeHandler(e) {
            textBoxValue = e.target.value;
            dispatch({
                type: 'UPDATE_TODO_FIELD',
                value: e.target.value,
                keyCode
            })
            if (e.target.value === '') { //|| !emailValidation(e.target.value)

                document.getElementById(keyCode).innerHTML = t('blank');

            } else {

                document.getElementById(keyCode).innerHTML = '';

            }
        }

        return (<div><input type="text" id={"text" + keyCode}
            readOnly={isEditMode === 0 ? true : false}
            className="mb-2 form-control form-control-sm col-lg-12"
            defaultValue={typeof (value) === 'object' ? value[keyCode] : value} onChange={changeHandler} maxLength={300} />
            {textBoxValue === '' && <p>error</p>}
            <span id={keyCode} className="error-text"></span>
        </div>

        );
    };

    const CheckBox = ({ keyCode }) => {
        let value = useSelector((state) => state[keyCode]);
        const dispatch = useDispatch();
        function changeHandler(e) {
            dispatch({
                type: "UPDATE_TODO_FIELD",
                keyCode,
                value: e.target.checked,
            });
        }
        if (value === undefined) {
            return <div className="form-switch form-switch-sm padding-left-0"><input type="checkbox" className="form-check-input ms-0" /></div>;
        } else {
            if (value[keyCode] === undefined) {
                value = value;
            } else {
                value = value[keyCode];
            }

            return <div className="form-switch form-switch-sm padding-left-0"><input type="checkbox" disabled={isEditMode === 0 ? true : false} className="form-check-input ms-0" checked={value}
                onChange={changeHandler} /></div>;
        }
    };
    const DropDowndata = ({ keyCode, data }) => {

        let value = useSelector((state) => state[keyCode]);
        const dispatch = useDispatch();
        function changeHandler(e) {
            dispatch({
                type: "UPDATE_TODO_FIELD",
                keyCode,
                value: e.value,
            });
        }
        if (value === undefined) {
            return <Dropdown textField="ddName" isDisabled={isEditMode === 0 ? true : false}
                dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
                arrowOpen={<span className="arrow-open" />}
                controlClassName='form-control control form-select bg_input mb-0'
                placeholder="Please Select"></Dropdown>
        } else {
            if (value[keyCode] === undefined) {
                value = value;
            } else {
                value = value[keyCode];
            }
            return <Dropdown textField="ddName" isDisabled={isEditMode === 0 ? true : false}
                dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
                arrowOpen={<span className="arrow-open" />}
                controlClassName='form-control control form-select bg_input mb-0'
                placeholder="Please Select"
                value={bindDropDown(data).filter(option => option.value === value)}
                onChange={changeHandler}
                options={bindDropDown(data)}
                id={keyCode} />;
        }
    };
    function SubmitFormHandler(event) {
        event.preventDefault();

        for (let i = 0; i < todoList.length; i++) {
            if (todoList[i].controlType == 2 && todoList[i].isEditTable == true) {

                if (document.getElementById("text" + todoList[i].keyCode).value === '') { //|| !document.getElementById("text" + todoList[i].keyCode).value.match('^[a-zA-Z0-9]+$')
                    document.getElementById(todoList[i].keyCode).innerHTML = t('blank');
                    return;
                } else {
                    document.getElementById(todoList[i].keyCode).innerHTML = '';
                }
            }
        }
        props.onAddConFiguration(
            {

            },)
    }
    return (
        <Fragment>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">

                                <div className="card-body">
                                    <div className="col-md-12">
                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className='col-md-12 col-sm-12 control'>
                                            <label htmlFor='companyName' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}</label>
                                            <input type="text" id="companyName" readOnly={true} className="form-control form-control-sm" ref={companyNameInputRef} />
                                        </div>
                                        <hr className=""></hr>
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-4 mb-3 vertical-tab-border-parent">
                                                    <ul className="nav nav-pills flex-column vertical-tab-border" id="myTab" role="tablist">

                                                        {LeftMenuShow()}
                                                    </ul>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade show active" >
                                                            {todoList.map((index) => {
                                                                return <div key={index.keyCode + index.menuID} id={index.keyCode + index.menuID} style={{ display: index.menuID !== '1' ? 'none' : 'block' }}>
                                                                    {index.isEditable === true && <div className="fw-bold h6 text-black mt-3">{index.keyName} </div>}
                                                                    {index.isEditable === true && <div ><span className="fw-bold text-muted"></span> <span>{index.keyDescription}</span>
                                                                    </div>}
                                                                    <div>
                                                                        <div className=''>
                                                                            {index.controlType === 1 && index.isEditable === true &&
                                                                                <CheckBox keyCode={index.keyCode}></CheckBox>}
                                                                            {index.controlType === 2 && index.isEditable === true &&
                                                                                <TextBox keyCode={index.keyCode}></TextBox>

                                                                            }
                                                                            {index.controlType == 3 && index.isEditable === true &&
                                                                                <DropDowndata keyCode={index.keyCode} data={index}></DropDowndata>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            })

                                                            }
                                                            {/* <hr className="my-2"></hr> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3 actions row'>
                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                <a href="/SearchConfiguration" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                            </div>
                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                <div className="float-md-end float-lg-end">
                                                    <a href="/SearchConfiguration" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={SubmitFormHandler}>
                                                        {t('save')}
                                                    </button>

                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>)
};
export default ConfigurationForm;