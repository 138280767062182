import { Fragment, useState,useEffect} from "react";

//import logo from '../../../assets/images/Globetrotterdark.png';
const WithoutAuthFooterNavigation = () => {
    const [date , setDate] = useState();
    const getYear = () =>  setDate(new Date().getFullYear())
    useEffect(() => {
        getYear();
    }, [])
    return (
        <Fragment>
           <footer id="footer_area" className="footer-bg-blue">
                <div  className="container">
                    <div  className="row">
                        <div  className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>Contact Us</h5>
                            </div>
                            <div  className="footer_first_area">
                                <div  className="footer_inquery_area">
                                    {/* <h5>Call 24/7 for any help</h5> */}
                                    <h3 className="pt-0"> <a href="tel:516 822 8000"><i className="fas fa-mobile-alt text-primary"></i> +1 516 822 8000</a></h3>
                                </div>
                                <div  className="footer_inquery_area">
                                    {/* <h5>Mail to our support team</h5> */}
                                    <h3 className="pt-0"> <a href="mailto:globetrotter@fareladder.com"><i className="fas fa-envelope text-primary"></i> globetrotter@fareladder.com</a></h3>
                                </div>
                                {/* <div  className="footer_inquery_area">
                                    <h5 className="">Follow us on <a target="_blank" href="https://www.linkedin.com/company/fareladder/">linkedin <i  className="fab fa-linkedin"></i></a></h5>
                                </div> */}
                                
                                {/* <div  className="footer_inquery_area">
                                    <h5>Follow us on</h5>
                                    <ul  className="soical_icon_footer">
                                        <li><a href="javascript:void(0);"><i  className="fab fa-facebook"></i></a></li>
                                        <li><a href="javascript:void(0);"><i  className="fab fa-twitter-square"></i></a></li>
                                        <li><a href="javascript:void(0);"><i  className="fab fa-instagram"></i></a></li>
                                        <li><a target="_blank" href="https://www.linkedin.com/company/fareladder/"><i  className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div  className="col-lg-2 offset-lg-1 col-md-3 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>Links</h5>
                            </div>
                            <div  className="footer_link_area padding-top-mobile-view">
                                <ul>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="https://www.fareladder.com/services">Services</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="https://www.fareladder.com/about-us">About Us</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="https://www.fareladder.com/careers">Careers</a></li>
                                    {/* <li><a target="_blank" className="text-sky-blue-without-auth" href="/Home">Blog</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="/Home">Privacy Statement</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="/Home">Terms of Use</a></li>
                                  */}
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div  className="footer_heading_area1">
                              
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                  
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="https://www.fareladder.com/blog">Blog</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="/PrivacyPolicy">Privacy Statement</a></li>
                                    <li><a target="_blank" className="text-sky-blue-without-auth" href="/TERMSCONDITIONS">Terms of Use</a></li>
                                 
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-2 col-md-4 col-sm-12 col-12">
                            <div  className="footer_heading_area mb-4">
                            <h5 className="mb-3"> Follow us on</h5><br></br>
                                <div className="mb-3"> <a target="_blank" className="text-sky-blue-without-auth" rel="noreferrer" href="https://www.linkedin.com/company/fareladder/"> linkedin <i className="fab fa-linkedin"></i></a></div>

                            </div>
                               
                            <div className="row">
                                {/* <div className="col-lg-4 col-md-3 col-sm-8 col-8 mt-2">
                                    <img src="assets/img/footer-logo2.png" alt="img" className="bg-main-logo h-70" />
                                </div>
                                <div className="col-lg-4 col-md-3 col-sm-8 col-8 mt-2">
                                    <img src="assets/img/footer-logo1.png" alt="img"  className="h-70"/>
                                </div>
                                <div className="col-lg-4 col-md-3 col-sm-8 col-8 mt-2">
                                    <img src="assets/img/footer-logo3.png" alt="img" className="h-70" />
                                </div> */}

                                <div className="col-lg-8 col-md-12 col-sm-12 col-12 mt-2">
                                    {/* <img src="assets/img/footer-logo4.png" alt="img"  className="h-70"/> */}
                                </div>
                            </div>
                        </div>
                        {/* <div  className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>New York</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>666 Third Avenue, 4th Floor, New York, NY 10017</li>
                                </ul>
                            </div>
                            <div  className="footer_heading_area">
                                <h5>Dubai</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>Office 103, Al Khaleej Building, Abu Baker Al Siddique Street, Deira, Clock Tower, Dubai</li>
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>London</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>15 Little Portland Street, London W1W 0BW</li>
                                </ul>
                            </div>
                            <div  className="footer_heading_area">
                                <h5>Pune</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>A 4030, Solitaire Business Hub, Sr. No.207 Opp. NECO Garden Society, Viman Nagar, Pune, Maharashtra 411014</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            <div className="container-fluid slider_area-withoutAuth">
               <div className="container pt-3 pb-2">
                    <div className="col-lg-12">
                        <div className="partner_slider_area owl-theme owl-carousel">
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/1.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/2.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/3.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/4.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/5.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/6.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/7.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/8.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/9.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/10.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/11.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/12.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/13.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/14.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/15.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/16.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/17.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/18.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/19.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/20.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/21.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/22.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/23.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/24.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/25.png" alt="logo"></img></a>
                            </div>
                            <div className="partner_logo">
                                <a href="javascript:void(0);"><img src="assets/img/partner/26.png" alt="logo"></img></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  className="copyright_area-WithoutAuth ">
                <div  className="container">
                    <div  className="row align-items-center">
                        <div  className="co-lg-5 col-md-5 col-sm-12 col-12">
                            <div  className="copyright_left">
                                <p className="text-white">Copyright © {date} Fareladder Consultancy Services.</p>
                            </div>
                        </div>
                        <div  className="co-lg-7 col-md-7 col-sm-12 col-12">
                            <div  className="copyright_right row">
                               <div className="col-md-12 my-auto copyright_right">
                                    <p className="text-white">Powered by Fareladder</p>
                               </div>
                               {/* <div className="col-md-2">
                                    <img src="assets/img/bottom-footer-logo.png" alt="img"  className="h-40"/>
                               </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  className="go-top">
                <i  className="fas fa-chevron-up"></i>
                <i  className="fas fa-chevron-up"></i>
            </div>
        </Fragment>
    )
}

export default WithoutAuthFooterNavigation;