import { Fragment, useState, useEffect } from 'react';
import UserItem from './UserItem';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useHttp from '../../../services/use-http';
import { deleteSingleUser } from '../../../services/User-API';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import { sortData } from '../../../utils';


const UserList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.User'])

    const [error1, setError] = useState();
    const queryParams = new URLSearchParams(location.search);

    const isSortingAscending = queryParams.get('sort') === 'asc';

   
    const sortedUser = sortData(props.user,'firstName', isSortingAscending);
    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };

    const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleUser, true);
    const [isErrorOnDelete,setIsErrorOnDelete] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 200,
            behavior: "smooth",
        });       
        history.push({
            pathname: '/AllUser',
            search: `?sort=${('asc')}`
        })


        if (status === 'pending') {
            <Fragment>
                <section id='common_banner'><div className='container'></div></section>
                <div className='centered'>
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </Fragment>
        }
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'TRUE') {
                if (loadedQuote['errors'].error['code'] === '1010') {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordInUseDelete')}`,
                    });

                }
                else {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordDelete')}`,
                    });
                }
            } else {
                setError({
                    title: "Message",
                    message: `${t('recordDelete')}`,
                });
                setIsErrorOnDelete(true);
            }
        }

    }, [status, history, t, loadedQuote,isErrorOnDelete])

    const deleteItemsHandler = (props) => {
        const onDeleteUser = ({
            "id": props.id,
            "traceID": "",
            "userID": "",
            "firstName": "",
            "middleName": "",
            "lastName": "",
            "loginEmailID": "",
            "email": "",
            "password": "",
            "phone": "",
            "roleID": "",
            "officeID": "",
            "isActive": true
        });

        sendRequest(onDeleteUser);

        if (error) {
            return <p className='centered focused'>{error}</p>
        }
        if ((!loadedQuote || loadedQuote.length === 0)) {
            return <p>{t('errorRecordDelete')}</p>
        }
    };

    const errorHandler = () => {
        setError(null);

        if (!isErrorOnDelete) {
            history.go({
                pathname: '/AllUser'
            })
        }
        else {
            
            history.push({
                pathname: '/AllUser'
            })
            history.go();
        }
    };




    return (<Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}

        <div className="container-fluid mb-3 list-view">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                    <div className="col-md-6"></div>
                    <div className='col-md-2 my-auto text-muted text-left'>
                        <div className="float-lg-end float-md-end">Sort By</div>
                    </div>
                    <div className="col-md-4 form-group">
                        <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                            <option>{t('firstNameAsc')}</option>
                            <option>{t('firstNameDsc')}</option>
                        </select>
                    </div>
                    </div>
                </div>
            </div>
          
            {sortedUser.map((user) => (
                <UserItem onDelete={deleteItemsHandler}
                    key={user.id}
                    id={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    middleName={user.middleName}
                    isActive={user.isActive}
                    roleID={user.roleName}
                    loginEmailID={user.loginEmailID}
                    employeeCode={user.employeeCode}
                />
            ))}

        </div>
    </Fragment>
    );
};

export default UserList;