import { Fragment, useState, useEffect } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import MdModal from '../../Flights/MdModal';
import Dropdown from 'react-dropdown';
import TravelerDetails from './Components/TravelerDetails';
import ContactPerson from './Components/ContactPerson';
import FlightDetails from './Components/FlightDetails';
import AddOns from './Components/AddOns';
import PriceDetails from './Components/PriceDetails';
import AddMarkup from './Components/AddMarkup';
import AddPayment from './Components/PaymentDetails';
import BookingLoadingSpinner from '../../Booking/Components/BookingLoadingSpinner';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import Remarks from './Components/Remarks';

const ImportPNR = () => {
    useDocumentTitle('Import Flight');
    const [error1, setError] = useState();
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

    const errorHandler = () => {
        setError(null);
    };

    function onContinueButton(tabID) {

        if (tabID === "1") {
            document.getElementById("completeOne").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button");
            document.getElementById("headingTwo").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingThree").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "2") {
            document.getElementById("completeTwo").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button");
            document.getElementById("headingThree").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "3") {
            document.getElementById("completeThree").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button ");
            document.getElementById("headingFour").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "4") {
            document.getElementById("completeFour").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "accordion-button ");
            document.getElementById("headingFive").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "5") {
            document.getElementById("completeFive").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "accordion-button ");
            document.getElementById("headingSix").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingSeven").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "6") {
            document.getElementById("completeSix").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "accordion-button ");
            document.getElementById("headingSeven").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingEight").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "7") {
            document.getElementById("completeSeven").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFive").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSix").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingSeven").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSeven").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingEight").setAttribute("class", "accordion-button ");
            document.getElementById("headingEight").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseEight").setAttribute("class", "accordion-collapse collapse show");
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
    }, [])

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}

        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row">
                                <div className='col-md-12'>
                                    <label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Import Flight</label>
                                    {
                                        sessionStorage.getItem("sourcePNR") !== null && sessionStorage.getItem("sourcePNR") !== '' &&
                                        <Fragment>
                                            <div className='mb-3'><b className='text-primary'>PNR </b> <span className='text-black'>{sessionStorage.getItem("sourcePNR")}</span>

                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                <div className='col-md-12'>
                                    <div className="tour_details_boxed_inner">
                                        <div className="accordion" id="accordionExample">
                                            <TravelerDetails traveler={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton} pnrType={valueParam.pnr}></TravelerDetails>
                                            <ContactPerson traveler={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></ContactPerson>
                                            <FlightDetails airItinerary={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></FlightDetails>
                                            <AddOns traveler={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></AddOns>
                                            <Remarks traveler={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></Remarks>
                                            <PriceDetails airItinerary={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></PriceDetails>
                                            <AddMarkup airItinerary={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></AddMarkup>
                                            <AddPayment airItinerary={bookingJSON !== null ? bookingJSON.data : ''} onContinueButton={onContinueButton}></AddPayment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
};

export default ImportPNR;