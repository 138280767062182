import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchSalesBooking } from '../../../services/Report-API'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import SalesList from '../Sales/Components/SalesList'

const DashBoard = () => {
    useDocumentTitle('Dashboard')
    const history = useHistory();
    const statusOption = [
        { value: "1", label: "All" },
        { value: "2", label: "On Hold" },
        { value: "3", label: "Cancelled" },
        { value: "4", label: "Ticketed" },
        { value: "5", label: "Send Request" },
    ];
    const [companyNameInput, setCompanyNameInput] = useState(JSON.parse(localStorage.getItem('officeID')));
    const [companyNameLabelInput, setCompanyNameLabelInput] = useState(JSON.parse(localStorage.getItem('companyName')));



    const { t } = useTranslation(['translation.CreditLimit']);


    const [UserStatusLabel, setUserStatusLabel] = useState(statusOption[0].label);


    const [error1, setError] = useState('');

    const { sendRequest, status, data: loadedFlightBooking, error } = useHttp(searchSalesBooking);

    const onSearchHandler = useCallback((event) => {

        var today = new Date().toISOString().split('T')[0];

        const onSearchFlightBooking = ({
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "officeName": "Fareladder",
            "userID": "",
            "dateFrom": today,
            "dateTo": today,
            "status": ""
        });
        sendRequest(onSearchFlightBooking);

    }, [sendRequest, companyNameInput]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        onSearchHandler();
    }, [onSearchHandler])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedFlightBooking['errors'].status === 'TRUE' || (loadedFlightBooking.data[0].segments.length <= 0 && loadedFlightBooking.data[0].revenue.length <= 0)) {
                //setError(`${t('ErrorMessage')}`);
                history.push('SearchSales')
            }
        }

    }, [loadedFlightBooking])

    if (error) {
        return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    let reportJSON =[];
    for (let i in loadedFlightBooking) {
        if (loadedFlightBooking['errors'].status === 'FALSE' && loadedFlightBooking.data[0].segments.length > 0 && loadedFlightBooking.data[0].revenue.length > 0) {
            if (i === 'data') {
                loadedData = loadedFlightBooking[i];
                loadedFlightBooking.data[0].companyNameInput = companyNameLabelInput;
                loadedFlightBooking.data[0].statusInput = UserStatusLabel;
                sessionStorage.setItem("reportsData", JSON.stringify(loadedFlightBooking));
                reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));
           
            
                if (reportJSON.data[0].segments.length > 0) {
                    reportJSON.data[0].segments.push({
                        "service": "Total",
                        "booked": reportJSON.data[0].segments.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "FL").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0,
                        "sendRequest": reportJSON.data[0].segments.map(a => a.sendRequest).reduce((sendRequest1, sendRequest2) => sendRequest1 + sendRequest2),
                        "ticketed": parseInt(reportJSON.data[0].segments.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "FL").map(a => a.ticketed).reduce((ticketed1, ticketed2) => ticketed1 + ticketed2) : 0) + parseInt(reportJSON.data[0].segments.filter(x => x.service === "HT").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "HT").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0),
                        "cancelled": reportJSON.data[0].segments.map(a => a.cancelled).reduce((cancelled1, cancelled2) => cancelled1 + cancelled2),
                        "modified": reportJSON.data[0].segments.map(a => a.modified).reduce((modified1, modified2) => modified1 + modified2),
                        "total": reportJSON.data[0].segments.map(a => a.total).reduce((total1, total2) => total1 + total2)
                    })
                }
            
                if (reportJSON.data[0].revenue.length > 0) {
                    reportJSON.data[0].revenue.push({
                        "service": "Total",
                        "currencyCode": "",
                        "booked": reportJSON.data[0].revenue.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "FL").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0,
                        "sendRequest": reportJSON.data[0].revenue.map(a => a.sendRequest).reduce((sendRequest1, sendRequest2) => sendRequest1 + sendRequest2).toFixed(2),
                        "ticketed": parseFloat(reportJSON.data[0].revenue.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "FL").map(a => a.ticketed).reduce((ticketed1, ticketed2) => ticketed1 + ticketed2) : 0) + parseFloat(reportJSON.data[0].revenue.filter(x => x.service === "HT").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "HT").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0),
                        "cancelled": reportJSON.data[0].revenue.map(a => a.cancelled).reduce((cancelled1, cancelled2) => cancelled1 + cancelled2).toFixed(2),
                        "modified": reportJSON.data[0].revenue.map(a => a.modified).reduce((modified1, modified2) => modified1 + modified2).toFixed(2),
                        "net": reportJSON.data[0].revenue.map(a => a.net).reduce((net1, net2) => net1 + net2).toFixed(2),
                        "equivalentNet": reportJSON.data[0].revenue.map(a => a.equivalentNet).reduce((equivalentNet1, equivalentNet2) => equivalentNet1 + equivalentNet2).toFixed(2),
                        "markup": reportJSON.data[0].revenue.map(a => a.markup).reduce((markup1, markup2) => markup1 + markup2).toFixed(2),
                        "airlineTransactionAmount": reportJSON.data[0].revenue.map(a => a.airlineTransactionAmount).reduce((airlineTransactionAmount1, airlineTransactionAmount2) => airlineTransactionAmount1 + airlineTransactionAmount2).toFixed(2),
                        "equivalentMarkup": reportJSON.data[0].revenue.map(a => a.equivalentMarkup).reduce((equivalentMarkup1, equivalentMarkup2) => equivalentMarkup1 + equivalentMarkup2).toFixed(2),
                        "commission": '',
                        "equivalentCommission": ''
                    })
                }

               
            }
        }
    }





    const ErrorShow = (error1 !== '') ?
        'No Record Found.' : ''
    console.log(ErrorShow);
    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
           


            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Dashboard </label>
                                        </div>

                                        <div className='row position-relative'>
                                            {status === 'pending' &&
                                                <div className='gt-mh-257px'>
                                                    <div className=''>
                                                        <LoadingSpinner></LoadingSpinner>

                                                    </div>
                                                </div>
                                            }
                                            <p className="error-text"> {ErrorShow}</p>
                                            <div className="col-lg-12" >
                                                
                                                <div
                                                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="">
                                                        <div className='row'>
                                                            {/* <div className="my-2 position-relative" >
                                                                
                                                            </div> */}
                                                            {/* <div className='col-md-12'>
                                                            <a href="/SearchSales" className='btn gt-btn_md text-white my-2 gt-main-btn d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                        </div> */}
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                {(reportJSON !== null && reportJSON.length !==0) &&
                                                    <div>
                                                        <div className="col-md-9 pop-ups-tab-border-parent">
                                                            <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link active" data-bs-toggle="tab"
                                                                        data-bs-target="#salesChartTab" type="button" role="tab" aria-controls="salesChartTab"
                                                                        id='btnSalesChart' aria-selected="true">Visual</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" data-bs-toggle="tab"
                                                                        data-bs-target="#salesTableTab" type="button" role="tab" aria-controls="salesTableTab"
                                                                        id='btnSalesTable' aria-selected="true">Data</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-12" >
                                                            <div className="p-2 tab-content include-exclude-tab" id="myTabContent">
                                                                <div className="tab-pane show active" id="salesChartTab" role="tabpanel" aria-labelledby="salesChart">
                                                                    {loadedFlightBooking !== null &&
                                                                        <SalesList reportType={'chart'} reportJSON={reportJSON.data} />}
                                                                </div>
                                                                <div className="tab-pane show " id="salesTableTab" role="tabpanel" aria-labelledby="salesTable">
                                                                    {loadedFlightBooking !== null &&
                                                                        <SalesList reportType={'table'} reportJSON={reportJSON.data} />}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                             
                                                }
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>)
};

export default DashBoard
