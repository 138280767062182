export const insertItem = (key, item) => {
    let flightList = [];
    if (sessionStorage.getItem("flightListJson") !== null) {
        flightList = JSON.parse(sessionStorage.getItem("flightListJson"));
        flightList.push(item);

    } else {
        flightList.push(item);

    }
    if (flightList.length !== 0) {
        document.getElementById('emailRecommendation').style.display = 'block'
        document.getElementById('flightCount').innerHTML = parseInt(flightList.length);
    }
    sessionStorage.setItem("flightListJson", JSON.stringify(flightList));
}
export const removeItem = (key, item) => {
    let flightList = [];
    let newFlightList = [];
    if (sessionStorage.getItem("flightListJson") !== null) {
        flightList = JSON.parse(sessionStorage.getItem("flightListJson"));
        if (flightList.length !== 0) {

            for (let i = 0; i < flightList.length; i++) {
                if (flightList[i].sequenceNumber !== key) {
                    newFlightList.push(flightList[i]);
                }
            }
            sessionStorage.setItem("flightListJson", JSON.stringify(newFlightList));
        }


    }

    if (newFlightList.length === 0 && sessionStorage.getItem("flightListJson") !== null) {
        sessionStorage.removeItem("flightListJson");
        document.getElementById('emailRecommendation').style.display = 'none'
    }
    if (newFlightList.length !== 0) {
        document.getElementById('flightCount').innerHTML = parseInt(newFlightList.length);
    }
}
export const getAirlineName = (flight) => {
    let name = flight[0].flightSegment[0].marketingAirline.name
    flight.forEach(element => {
        if (name !== element.flightSegment[0].marketingAirline.name) {
            name = "Multi Airlines"
        }

    });
    return name;

}
export const getBaggageAllowance = (baggage) => {
    let bagText = "";
    let adultBag;
    let adultBagUnit;
    if (baggage === undefined) {
        return bagText;
    }
    baggage.forEach(element => {
        if (element.type === 'ADT') {
            element.baggageAllowance.forEach(element1 => {
                if (element1.type === 'Check-in Allowance') {
                    adultBag = element1.maxWeight === undefined ? element1.numberOfPieces : element1.maxWeight
                    adultBagUnit = element1.unit === undefined ? adultBag === '1' ? " piece " : ' pieces' : element1.unit
                    bagText = "Per Adult " + adultBag + adultBagUnit
                }

            });
        }

    });
    return bagText;

}
export const getStops = (Arr) => {
    const techstops = Arr[0].flightSegment[0].technicalStops !== undefined && Arr[0].flightSegment[0].technicalStops.noOfStops !== undefined ? parseInt(Arr[0].flightSegment[0].technicalStops.noOfStops) : 0
    const totalStops = Arr.length - 1 + techstops
    const stop = totalStops === 1 ? totalStops + " stop" : totalStops + " stops"
    return stop;
}
//function to clear alll flight data
export function clearAll() {
    if (sessionStorage.getItem("flightListJson") !== null) {
        sessionStorage.removeItem("flightListJson");
        document.getElementById('emailRecommendation').style.display = 'none'

    }
    [].forEach.call(document.querySelectorAll('.hideproposal'), function (el) {
        el.checked = false;

    });
}
export const insertRoom = (hotel, room) => {
    let roomList = [];
    if (sessionStorage.getItem("RoomDetailJson") !== null) {
        roomList = JSON.parse(sessionStorage.getItem("RoomDetailJson"));
        roomList.push(room);

    } else {
        roomList.push(room);

    }
    if (roomList.length !== 0) {
        document.getElementById('HotelEmailRecommendation').style.display = 'block'
       // document.getElementById('hotelCount').innerHTML = parseInt(roomList.length);
    }
    sessionStorage.setItem("RoomDetailJson", JSON.stringify(roomList));
    sessionStorage.setItem("HotelRecommendationJson", JSON.stringify(hotel));
}
export const removeRoom = (hotel, room) => {
    let RoomList = [];
    let newRoomList = [];
    if (sessionStorage.getItem("RoomDetailJson") !== null) {
        RoomList = JSON.parse(sessionStorage.getItem("RoomDetailJson"));
        if (RoomList.length !== 0) {
            for (let i = 0; i < RoomList.length; i++) {
                if (RoomList[i].roomID !== room.roomID) {
                    newRoomList.push(RoomList[i])
                }
            }
            sessionStorage.setItem("RoomDetailJson", JSON.stringify(newRoomList));
        }


    }

    if (newRoomList.length === 0 && sessionStorage.getItem("RoomDetailJson") !== null) {
        sessionStorage.removeItem("RoomDetailJson");
        document.getElementById('HotelEmailRecommendation').style.display = 'none'
    }
    // if (newRoomList.length !== 0) {
    //     document.getElementById('hotelCount').innerHTML = parseInt(newRoomList.length);
    // }
}
export function clearHotelRecommendation() {
    let RoomList = [];
    if (sessionStorage.getItem('RoomDetailJson') !== null) {
        RoomList = JSON.parse(sessionStorage.getItem("RoomDetailJson"));
        RoomList.length !== 0 && RoomList.forEach(element => {
            let el = document.getElementById('addToProposal' + element.roomID)
            if (el !== null) {
                el.checked = false;
            }
        });
        sessionStorage.removeItem('RoomDetailJson');
        if (sessionStorage.getItem('HotelRecommendationJson') !== null) {
            sessionStorage.removeItem('HotelRecommendationJson');
        }
        document.getElementById('HotelEmailRecommendation').style.display = 'none'
    }
}

