import React from "react";
import ReactDOM from "react-dom";
import Button from "./../Button/Button";
import { useLocation } from 'react-router';
import queryString from 'query-string';


const Backdrop = (props) => {
  return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {
  const location = useLocation();
  const valueParam = queryString.parse(location.search);

  return (
    <div className='modalcard modal modal-top'>
      <header className='header'>
        {/* <h4>{props.title}</h4> */}
        <div className="row">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11">
            <h4 className="text-white">{props.title}</h4>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={valueParam !== undefined && valueParam.travelreq !== undefined && valueParam.travelreq !== '' && valueParam.travelreq === 'true' ? props.onClose : props.onConfirm}><h4 className="text-white cursor-pointer" onClick={props.onConfirm}><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
        </div>
      </header>
      <div className='content gt-h-400px'>
        <p>{props.message}</p>
      </div>
      <footer className='actions float-end px-3 py-2'>
        <Button onClick={props.onConfirm}>OK</Button>
      </footer>
    </div>
  );
};

const PopupModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClick={props.onConfirm} ></Backdrop>,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          message={props.message}
          onConfirm={props.onConfirm}
          onClose={props.onClose}
        ></ModalOverlay>,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};
export default PopupModal;
