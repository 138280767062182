
import { Fragment } from 'react';

const LogsData = (props) => {

    return (
        <Fragment>

            <tbody>
                <tr>
                <td className="col-md-2 gt-word-break">{props.errorType}</td>
                    <td className="col-md-10 gt-word-break">{props.description}</td>
                  
                </tr>
            </tbody>
        </Fragment>
    );
};

export default LogsData
