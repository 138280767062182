import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GET_ALL_SIGHTSEEING_LIST } from '../../../services/CONSTANTS';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { useHistory } from 'react-router-dom';
import SearchFlightPartial from '../Home/SearchFlightPartial';
import SearchHotelPartial from '../Home/SearchHotelPartial';
import SearchHolidaysPartial from '../Home/SearchHolidaysPartial';

const SearchSightseeingPartial = () => {
    const { t } = useTranslation(['translation.Sightseeing']);
    const history = useHistory();

    useDocumentTitle('Sightseeing Search');
    const [error1, setError] = useState('');
    const [items, setItems] = useState([]);
    const { detect } = require('detect-browser');
    const browser = detect();
    const [loadComponent, setLoadComponent] = useState('');

    const refDestination = useRef(null);
    useOutsideAlerter(refDestination);

    if (sessionStorage.getItem("sightseeingSessionDataJson") === null) {
        const sightseeingSessionDataJson = ({
            "txtDestination": {},
            "spnDestinationCityDetails": {}
        });
        sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionDataJson));
    }

    useEffect(() => {
        if (localStorage.getItem("sightseeingSuggestion") === null || localStorage.getItem("sightseeingSuggestion").length < 3) {
            let sightseeingSuggestionResutTemp = [];
            fetch(GET_ALL_SIGHTSEEING_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            sightseeingSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
                });
        }
        localStorage.removeItem("sightseeingData");

        if (sessionStorage.getItem('serviceType') === null || sessionStorage.getItem('serviceType') === '') {
            if (checkSecurityDetails("Book", "Flight") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
            else if (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR') && getConfigurationByBoolen("ALLOW_FLIGHT")) {
                sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            }
            else if (checkSecurityDetails("Book", "Hotel") && getConfigurationByBoolen("ALLOW_HOTEL") === true) {
                sessionStorage.setItem("serviceType", "lblHotel");
            }
            else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                sessionStorage.setItem("serviceType", "lblSightseeing");
            }
            else {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
        }

    }, [])

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (document.querySelector("#lblFlights_sightseeing") !== null) {
            document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
        }

        if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
            document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
            }

            document?.querySelector("#lblHotel_sightseeing")?.classList?.remove("text-primary");
            document?.querySelector("#lblHotel_sightseeing")?.classList?.add("flight-active-tab");
        }

        if (document.querySelector("#lblHotel_sightseeing") !== null) {
            document?.querySelector("#lblHotel_sightseeing")?.classList?.remove("flight-active-tab");
            document?.querySelector("#lblHotel_sightseeing")?.classList?.add("text-primary");
        }

        if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
            document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
            document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
        }

        if (document.querySelector("#lblHolidays_sightseeing") !== null) {
            document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
            document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
        }

    }, [])

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref.current.innerText === "Destination") {
                        document.querySelector('#dvDestination_sightseeing').style.display = "none";
                        document.querySelector('#txtDestination_sightseeing').style.display = "";
                        document.querySelector('#spnDestinationCityDetails_sightseeing').style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleOnClickDestination = () => {
        document.querySelector("#txtDestination_sightseeing").style.display = "none";
        document.querySelector("#dvDestination_sightseeing").style.display = "";
        document.querySelector('#spnDestinationCityDetails_sightseeing').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[0] !== undefined) {
                controlCount[0].focus();
            }
        }
    }

    const handleOnDestinationSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("sightseeingSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("sightseeingSuggestion")));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.cityCode} - {item.countryCode}</span>
            </>
        )
    }

    const handleOnSelectDestination = (item) => {
        let sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
        sightseeingSessionJson.txtDestination = item.cityName;
        sightseeingSessionJson.spnDestinationCityDetails = item.cityCode + " - " + item.cityName + ", " + item.countryName + ", " + item.countryCode;

        sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));


        document.querySelector("#txtDestination_sightseeing").style.display = "";
        document.querySelector('#txtDestination_sightseeing').value = item.cityName;
        document.querySelector("#dvDestination_sightseeing").style.display = "none";
        document.querySelector('#spnDestinationCityDetails_sightseeing').style.display = "";
        document.querySelector('#spnDestinationCityDetails_sightseeing').innerHTML = item.cityCode + " - " + item.cityName + ", " + item.countryName + ", " + item.countryCode;

    }

    function onSearchHandler() {
        //window.open('http://localhost:3000/FlightSearch?flight=partial');        
        //history.push('SearchSightseeing?sightseeing=partial&req=' + document.querySelector('#spnDestinationCityDetails_sightseeing').innerHTML.split('-')[0].trim());
        //window.location.pathname = '/SearchSightseeing'
        window.location.href = '/SearchSightseeing?sightseeing=partial&req=' + document.querySelector('#spnDestinationCityDetails_sightseeing').innerHTML.split('-')[0].trim();
    }

    const errorHandler = () => {
        setError(false);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    let sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));

    const ErrorShow = (error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    useEffect(() => {

        document.querySelector('#dvDestinationSpinner_sightseeing').classList.add("d-none");
        if (localStorage.getItem("sightseeingSuggestion") != null) {
            setItems(JSON.parse(localStorage.getItem("sightseeingSuggestion")));
        }
        document.querySelector("#dvDestination_sightseeing").style.display = "none";


        if (Object.keys(sightseeingSessionJson.txtDestination).length > 0) {
            document.querySelector("#txtDestination_sightseeing").value = sightseeingSessionJson.txtDestination;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight.fromToListResponse.find(x => x.service == "SS");
            document.querySelector("#txtDestination_sightseeing").value = strSightData.cityName;//"New York City";
            sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
            sightseeingSessionJson.txtDestination = strSightData.cityName;//"New York City";
            sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));
        }
        var strSight = JSON.parse(localStorage.getItem("fromToListResponse"))

        if (Object.keys(sightseeingSessionJson.spnDestinationCityDetails).length > 0) {
            document.querySelector('#spnDestinationCityDetails_sightseeing').innerHTML = sightseeingSessionJson.spnDestinationCityDetails;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight.fromToListResponse.find(x => x.service == "SS");
            document.querySelector('#spnDestinationCityDetails_sightseeing').innerHTML = strSightData.cityCode + " - " + strSightData.cityName + ", " + strSightData.countryName + ", " + strSightData.countryCode; // "NYC - New York City, United States of America, US";

            sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
            sightseeingSessionJson.spnDestinationCityDetails = strSightData.cityCode + " - " + strSightData.cityName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));
        }

    }, [])

    const controlHandlerFightSearch = (event) => {
        console.log(3)
        console.log(event.target.id)
        console.log(sessionStorage.getItem('serviceType'))
        if (event.target.id === 'lblFlights_sightseeing') {
            sessionStorage.setItem("serviceType", "lblFlights");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "none";
            }

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlights_flight").classList.remove("text-primary");
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblFlights_sightseeing").classList.remove("text-primary");
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
            }

            document.querySelector('#oneway-tab').classList.remove("disabled");
            document.querySelector('#multi_city-tab').classList.remove("disabled");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHotel_sightseeing') {
            sessionStorage.setItem("serviceType", "lblHotel");
            setLoadComponent('Hotel');

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
                document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblHotel_sightseeing").classList.remove("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_hotel") !== null) {
                document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
                document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.remove('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel_sightseeing') {
            sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "";
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("text-primary");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
            }

            if (document.querySelector("#lblHolidays_sightseeing") !== null) {
                document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector('#divTripType') !== null) {
                document.querySelector('#divTripType').classList.add("d-none");
            }
            document.querySelector('#roundtrip-tab').click();

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblSightseeing_sightseeing') {
            sessionStorage.setItem("serviceType", "lblSightseeing");
            setLoadComponent('Sightseeing');

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.remove('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHolidays_sightseeing') {
            // sessionStorage.setItem("serviceType", "lblHolidays");
            // setLoadComponent('Holidays');

            // if (document.querySelector("#lblFlights_sightseeing") !== null) {
            //     document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_sightseeing") !== null) {
            //     document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            //     document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            // }

            // if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
            //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
            //     document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_holidays") !== null) {
            //     document.querySelector("#lblHolidays_holidays").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_holidays").classList.add("flight-active-tab");
            // }

            // if (document.querySelector('.show_flight') !== null) {
            //     document.querySelector('.show_flight').classList.add('d-none');
            // }
            // if (document.querySelector('.show_hotel') !== null) {
            //     document.querySelector('.show_hotel').classList.add('d-none');
            // }
            // if (document.querySelector('.show_sightseeing') !== null) {
            //     document.querySelector('.show_sightseeing').classList.add('d-none');
            // }
            // if (document.querySelector('.show_holidays') !== null) {
            //     document.querySelector('.show_holidays').classList.remove('d-none');
            // }

            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
    }

    if (sessionStorage.getItem('serviceType') === 'lblFlights') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblFlightPlusHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.remove('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblSightseeing') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.remove('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHolidays') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.remove('d-none');
        }
    }

    // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
    //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
    //     document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
    // }

    return <Fragment>
        {
            error1 && <ErrorModal
                title={error1.title}
                message={t(error1.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }

        <div className="row position-relative show_sightseeing">
            <div className="col-lg-12 tour_search_form">
                <form action="!#">
                    <div className="row">
                        <label for="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                        <div className='text-sm-center1 mb-2'>
                            <span>
                                {
                                    (checkSecurityDetails("Book", "Flight") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                    <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights_sightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                }
                                {
                                    (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                        getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                        && getConfigurationByBoolen("ALLOW_FLIGHT")) ?
                                        <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel_sightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                        <label id='lblFlightPlusHotel_sightseeing' ></label>
                                }
                                {
                                    (checkSecurityDetails("Book", "Hotel") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel_sightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                }
                                {
                                    (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing_sightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                }
                                {
                                    getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays_sightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="flight_Search_boxed py-2">
                                <div className='pb-1 h5 mb-0' id='dvFrom5'>
                                    <div ref={refDestination} className="position-relative" >
                                        <p onClick={handleOnClickDestination}>{t('Destination')} </p>
                                        <div className=''>
                                            <input id='txtDestination_sightseeing' type="text" onClick={handleOnClickDestination} />
                                            <div id='spnDestinationCityDetails_sightseeing' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                        </div>
                                        <div id='dvDestinationSpinner_sightseeing' className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                        <div id='dvDestination_sightseeing' >
                                            <ReactSearchAutocomplete
                                                onSearch={handleOnDestinationSearch}
                                                items={items}
                                                formatResult={formatResult}
                                                autoFocus
                                                onSelect={handleOnSelectDestination}
                                                showNoResults={false}
                                                maxResults={15}
                                                fuseOptions={{ keys: ["cityCode", "cityName"] }}
                                                resultStringKeyName="cityName"

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="top_form_search_button my-3">
                            {/* {status === 'pending' ? <span className="btn btn_theme btn_md mt-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-spinner fa-spin" ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>} */}
                            <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        {/* {
            loadComponent === 'Flight' &&
            <SearchFlightPartial></SearchFlightPartial>
        } */}
        {/* {
            loadComponent === 'Hotel' &&
            <SearchHotelPartial></SearchHotelPartial>
        } */}
        {/* {
            loadComponent === 'Holidays' &&
            <SearchHolidaysPartial></SearchHolidaysPartial>
        } */}
    </Fragment>
};

export default SearchSightseeingPartial;