import { Fragment, useState,useCallback,useEffect } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { geteContinentByName, getAllcontinent } from '../../../services/Continent-API'
import ContinentList from '../Continent/Components/ContinentList'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { NavLink } from 'react-router-dom';
import { NEWCONTINENT } from '../../../navigation/CONSTANTS'

const AllContinent = () => {
  const [enteredName, setEnterName] = useState("");
  const [isFirtTime, setFirstTime] = useState("");
  useDocumentTitle('Search Continent');
  useEffect(() => {
    document.getElementById('name').focus();
  }, [enteredName])


  let varHttp;
  if (enteredName.trim().length === 0) {
    varHttp = getAllcontinent
  } else {
    varHttp = geteContinentByName
  }
  const namechangehandler = (event) => {
    setEnterName(event.target.value);
  };
  const { sendRequest, status, data: loadedContinents, error } = useHttp(varHttp);
  const onResetHandler = (event) => {
    event.preventDefault();
    setEnterName('');
  }
  console.log("TootltipCode", document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const onSearchHandler =useCallback((event) => {
    event.preventDefault();
    setFirstTime(true);
    sendRequest(enteredName);

  },[sendRequest,enteredName]);
  
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        onSearchHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onSearchHandler]);
  if (status === 'pending') {
    return (
      <Fragment>
          <section id='common_banner'><div className='container'></div></section>
          <div className='centered'>
              <LoadingSpinner></LoadingSpinner>
          </div>
      </Fragment>
    )
  }
  if (error) {
    return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
  }

  const continentList = !isFirtTime ? '' : (loadedContinents && loadedContinents.length !== 0) ?
    <ContinentList continents={loadedContinents} /> : <div className='centered focused col-lg-5 col-md-8 col-sm-12 col-12'>No Continents found.</div>
  return (

    <Fragment>
     <section id="common_banner">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                   
                </div>
            </div>
        </div>
    </section>

    <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
      <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-30">
                <div className="card card-primary card-outline">
                    <div className="card-body">
                        <div className="col-md-12 gt-country-details-form">
                            <div className="form-group col-md-12">
                                <label htmlFor="exampleInputEmail1" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Continent</label>

                                <input type='text' id='name' className="mb-2 form-control form-control-sm col-lg-12" value={enteredName} onChange={namechangehandler} placeholder='Continent Name'/>

                                <div className="mt-3 col-md-12">

                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                        Reset
                                    </button>
                                    <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={NEWCONTINENT}>
                                        New
                                    </NavLink>
                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2"></div>
          </div> 
      </div>
    </section>
    <div className="container-fluid my-3">
    </div>
     
      {continentList}
    </Fragment>)
};
export default AllContinent;