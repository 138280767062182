import { useState, Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import useHttp from "../../../../services/use-http";
import { deleteSingleAirline } from '../../../../services/Airline-API';
import CustomFieldsItem from "./CustomFieldsItem";
import { useTranslation } from "react-i18next";
import { sortData } from '../../../../utils';


const CustomFieldsList = (props) => {
  const { t } = useTranslation(['translation.CustomFields']);
  const history = useHistory();
  const location = useLocation();

  const [error1, setError] = useState();

  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';
  
  const sortedApprover = sortData(props.approver,'airlineName', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };

  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleAirline, true);
  const [isErrorOnDelete,setIsErrorOnDelete] =useState(false);

  useEffect(() => {

    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
 
    history.push({
      pathname: '/AllCustomFields',
      search: `?sort=${('asc')}`
    })

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }
    if (status === 'completed') {
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });

        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
        setIsErrorOnDelete(true);
      } else {
        setError({
          title: "Message",
          message: `${t('recordDelete')}`,
        });
      
      
      }
    }

  }, [status, history, t, loadedQuote])

  const deleteItemsHandler = (props) => {
    const onDeleteAirline = ({
      "id": props.id,
      "traceID": "",
      "officeID": "",
      "userID": "",
      "airlineCode": "",
      "airlineName": "",
      "airlineLogo": ""
    });
    sendRequest(onDeleteAirline);

    if (error) {
      return <p className='centered focused'>{error}</p>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  //End code for delete


  const errorHandler = () => {
    setError(null);
    if (!isErrorOnDelete) {
      console.log("deleted");
      history.go({
        pathname: '/AllCustomFields'
      })
    }
    else {
      history.push({
        pathname: '/AllCustomFields'
      })
    }
  };

  return <Fragment>
    {error1 && (
      <ErrorModal
        title={error1.title}
        message={error1.message}
        onConfirm={errorHandler}
      ></ErrorModal>
    )}

    <div className="container-fluid mb-3 list-view">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
          <div className='row mb-3'>
            <div className="col-md-6"></div>
            <div className='col-md-2 my-auto text-muted text-left'>
              <div className="float-lg-end float-md-end">Sort By</div>
            </div>
            <div className="col-md-4 form-group">
              {/* <span className="text-muted">Sort By</span> */}
              <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
              <option>{t('sortAsc')}</option>
                <option>{t('sortDsc')}</option>
              
              </select>
            </div>
          </div>
        </div>
      </div>
      {sortedApprover.map((airline) => (
        <CustomFieldsItem
          onDelete={deleteItemsHandler}
          key={airline.id}
          id={airline.id}
          airlineCode={airline.airlineCode}
          airlineName={airline.airlineName}
          airlineLogo={airline.airlineLogo}
          airlineBinaryImage={airline.airlineBinaryImage}
        />
      ))}
    </div>
  </Fragment>
};

export default CustomFieldsList;