import {
    SHOPPING_CART_SEND_EMAIL
} from './CONSTANTS'
export async function SendMailShoppingCart(mailData) {
    console.log(mailData);
    const response = await fetch(SHOPPING_CART_SEND_EMAIL(), {
        method: 'POST',
        body: JSON.stringify(mailData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not send email.');
    }
    console.log(data);
    return data;

}