import {
    SEARCH_RATE_PLAN, UPDATE_RATE_PLAN, GET_RATE_PLAN, ADD_RATE_PLAN,DELETE_RATE_PLAN
} from './CONSTANTS'
export async function searchRatePlan(searchData) {
    const response = await fetch(SEARCH_RATE_PLAN(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Rate Plan.');
    }

    return data;


}
export async function updateRatePlan(updateData) {
    const response = await fetch(UPDATE_RATE_PLAN(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update Rate Plan.');
    }
    return data;
}
export async function getSingleRatePlan(ID) {
    const response = await fetch(GET_RATE_PLAN(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch rate plan Allowed.');
    }

    return data;
}
export async function addRatePlan(addData) {
    const response = await fetch(ADD_RATE_PLAN(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add Rate Plan');
    }
    return data;

}
export async function deleteSingleRatePlan(deleteData) {
    const response = await fetch(DELETE_RATE_PLAN(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not delete rate plan.');
    }
    
    return data;
}
