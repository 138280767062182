import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'

import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'

import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import Dropdown from 'react-select';
import { SEARCH_CITY } from '../../../../services/CONSTANTS'
import { checkSecurityDetails } from '../../../../utils/RoleUtils'

const AirportForm = (props) => {
    const { t } = useTranslation(['translation.Airport']);

    const airportCodeInputRef = useRef('');
    const airportNameInputRef = useRef('');

    const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [airportCodeInputHasError, setAirportCodeInputHasError] = useState(false);
    const [airportNameInputHasError, setAirportNameInputHasError] = useState(false);
    const [airportCodeLengthInputHasError, setAirportCodeLengthInputHasError] = useState(false);

    const [country_Code, setCountry_Code] = useState('');
    const [iSDisabled, setiSDisabledDDL] = useState('false');

    const [airportCodeInvalidInputError, setAirportCodeInvalidInputError] = useState(false);
    const [airportNameInvalidInputError, setAirportNameInvalidInputError] = useState(false);

    const [cityCode, setCityCode] = useState('');
    const [cityList, setCityList] = useState([]);
    let cityOptionList = [];
    const countryCodeValue = (country_Code) => {
        setCountry_Code(country_Code);
        setCityCode('');
        setCityList(cityOptionList);
        setCountryCodeInputHasError(false);

        const onSearchCity = ({
            countryCode: country_Code,
            cityCode: "",
            cityName: ""
        });

        fetch(SEARCH_CITY(), {
            method: 'POST',
            body: JSON.stringify(onSearchCity),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.data.length > 0) {
                    setCityCode('');
                    setCityList(cityOptionList);
                    let loadedDataCity = [];
                    for (let i in responseJson) {
                        if (responseJson['errors'].status === 'FALSE') {
                            if (i === 'data') {
                                loadedDataCity = responseJson[i];
                            }
                        }
                    }

                    for (let index = 0; index < loadedDataCity.length; index++) {
                        cityOptionList = [...cityOptionList, { value: loadedDataCity[index].cityCode, label: loadedDataCity[index].cityName + "  (" + loadedDataCity[index].cityCode + ")" }];
                    }
                    setCityList(cityOptionList);
                }
                else {
                    setCityCode('');
                    setCityList(cityOptionList);
                }
            });

    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };


    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        if (props.loadedProfileDetails.length !== 0) {
            let cityOptionListEdit = [];
            setCityCode('');
            setCityList(cityOptionListEdit);

            airportCodeInputRef.current.value = `${props.loadedProfileDetails.airportCode !== undefined ? props.loadedProfileDetails.airportCode : ''}`;
            airportNameInputRef.current.value = props.loadedProfileDetails.airportName;
            if (country_Code === undefined || country_Code === '') {
                setCountry_Code(props.loadedProfileDetails.countryCode);
            }
            setCityCode(props.loadedProfileDetails.cityCode);
            //cityOptions.current.value=props.loadedProfileDetails.cityCode;

            const onSearchCity = ({
                countryCode: country_Code === '' ? props.loadedProfileDetails.countryCode : country_Code,
                cityCode: "",
                cityName: ""
            });

            fetch(SEARCH_CITY(), {
                method: 'POST',
                body: JSON.stringify(onSearchCity),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.data.length > 0) {
                        let loadedDataCity = [];
                        for (let i in responseJson) {
                            if (responseJson['errors'].status === 'FALSE') {
                                if (i === 'data') {
                                    loadedDataCity = responseJson[i];
                                }
                            }
                        }

                        for (let index = 0; index < loadedDataCity.length; index++) {
                            cityOptionListEdit = [...cityOptionListEdit, { value: loadedDataCity[index].cityCode, label: loadedDataCity[index].cityName + "  (" + loadedDataCity[index].cityCode + ")" }];
                        }

                        setCityList(cityOptionListEdit);
                    }
                    else {
                        setCityCode('');
                        setCityList(cityOptionListEdit);
                    }
                });
        }

        setiSDisabledDDL('false');

        if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {

            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Airport';
            document.getElementById("airportCode").readOnly = true;
            document.getElementById("airportName").readOnly = true;
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            setiSDisabledDDL('true');
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });

            document.getElementById("requiredIndicator").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Airport';
            document.getElementById("airportCode").readOnly = true;
            document.getElementById("airportName").readOnly = false;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL('false');
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
        }

        if (props.loadedProfileDetails.length === 0) {
            //document.getElementById("btnSave").click();
            document.getElementById("btnNew").style.display = "none";
            document.getElementById('pageTitle').innerHTML = ' New Airport';

            document.getElementById("airportCode").readOnly = false;
        }

    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails.airportName,
    props.loadedProfileDetails.airportCode,
    props.loadedProfileDetails.cityCode,
    props.loadedProfileDetails.countryCode,
        country_Code
    ]);



    function onBlurCheck() {
        const enteredAirportCode = airportCodeInputRef.current.value;
        const enteredAirportName = airportNameInputRef.current.value;
        const countryCode = country_Code;
        const city_Code = cityCode;

        // optional: Could validate here
        if (enteredAirportCode.trim() === '') {
            setAirportCodeInputHasError(true);
            setAirportCodeInvalidInputError(false);
            setAirportCodeLengthInputHasError(false);
            return;
        }
        else {
            setAirportCodeInputHasError(false);
        }

        if (enteredAirportCode.length !== 3) {
            setAirportCodeLengthInputHasError(true);
            setAirportCodeInvalidInputError(false);
            setAirportCodeInputHasError(false);
            return;
        }
        else {
            setAirportCodeLengthInputHasError(false);
        }

        if (!enteredAirportCode.match('^[a-zA-Z]+$')) {
            setAirportCodeInvalidInputError(true);
            setAirportCodeInputHasError(false);
            return;
        }
        else {
            setAirportCodeInvalidInputError(false);
        }

        if (enteredAirportName.trim() === '') {
            setAirportNameInputHasError(true);
            setAirportNameInvalidInputError(false);
            return;
        }
        else {
            setAirportNameInputHasError(false);
        }

        if (!enteredAirportName.match(/^[a-zA-Z-\-\\ .0-9']+$/)) {
            setAirportNameInvalidInputError(true);
            setAirportNameInputHasError(false);
            return;
        }
        else {
            setAirportNameInvalidInputError(false);
        }

        if (countryCode === '') {
            //countryCodeInputRef.current.focus();
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            //countryCodeInputRef.current.focus();
            setCountryCodeInputHasError(false);
        }

        if (city_Code === '') {
            //cityCodeInputRef.current.focus();
            setCityCodeInputHasError(true);
            return;
        }
        else {
            //cityCodeInputRef.current.focus();
            setCityCodeInputHasError(false);
        }
    }

    function submitFormHandler(event) {
        event.preventDefault();

        const enteredAirportCode = airportCodeInputRef.current.value;
        const enteredAirportName = airportNameInputRef.current.value;
        const countryCode = country_Code;

        // optional: Could validate here
        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (cityCode === '') {
            setCityCodeInputHasError(true);
            return;
        }
        else {
            setCityCodeInputHasError(false);
        }

        if (enteredAirportCode.trim() === '') {
            airportCodeInputRef.current.focus();
            setAirportCodeInputHasError(true);
            return;
        }
        else {
            setAirportCodeInputHasError(false);
        }

        if (enteredAirportCode.length !== 3) {
            airportCodeInputRef.current.focus();
            setAirportCodeLengthInputHasError(true);
            return;
        }
        else {
            setAirportCodeLengthInputHasError(false);
        }

        if (!enteredAirportCode.match('^[a-zA-Z]+$')) {
            airportCodeInputRef.current.focus();
            setAirportCodeInvalidInputError(true);
            return;
        }
        else {
            setAirportCodeInvalidInputError(false);
        }

        if (enteredAirportName.trim() === '') {
            airportNameInputRef.current.focus();
            setAirportNameInputHasError(true);
            return;
        }
        else {
            setAirportNameInputHasError(false);
        }

        if (!enteredAirportName.match(/^[a-zA-Z-\-\\ .0-9']+$/)) {
            airportNameInputRef.current.focus();
            setAirportNameInvalidInputError(true);
            return;
        }
        else {
            setAirportNameInvalidInputError(false);
        }

        let ID;
        if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedProfileDetails.id;
        }

        props.onAddAirport({
            id: ID,
            countryCode: countryCode,
            cityCode: cityCode,
            airportCode: enteredAirportCode,
            airportName: enteredAirportName,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
        });
    }


    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/NewAirport')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedProfileDetails.length === 0) {
                history.go('/NewAirport')
            }
            else {
                history.go(`/NewAirport/${props.loadedProfileDetails.id}`)
            }
        }
    }

    const history = useHistory();

    const cityChangeHandler = (e) => {
        setCityCode(e.value);
        setCityCodeInputHasError(false);
    };

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }

    //const getCityLabelByValue = (cityOptions, value) => cityOptions.find(cityOptions => cityOptions.value === value)?.label || "";

    const getCityLabelByValue = cityList.filter(function (option) {
        return option.value === cityCode;
    })

    return (
        <Wrapper>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>

                                                        <form className='form'>
                                                            <div className='control'>
                                                                <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('country')}<span className="error-icon"> *</span></label>
                                                                <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={props.loadedProfileDetails.countryCode} iSDisabled={iSDisabled} focus={true} />
                                                                {countryCodeInputHasError && <p className="error-text">{t('validationCountryCode')}</p>}
                                                            </div>
                                                            <div className='control'>
                                                                <label className="mb-2 h6 text-primary col-lg-12" htmlFor='city'>City<span className="error-icon"> *</span></label>
                                                                <Dropdown onChange={cityChangeHandler}
                                                                    //value={cityCode !== undefined ? getCityLabelByValue(cityList, cityCode) : ''}
                                                                    value={cityCode !== undefined ? getCityLabelByValue : ''}
                                                                    options={cityList}
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                    isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false} />
                                                                {cityCodeInputHasError && <p className="error-text">{t('validationCityCode')}</p>}
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='airportCode'>{t('airportCode')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength="3" type='text' className="form-control form-control-sm" id='airportCode' ref={airportCodeInputRef}
                                                                            onInput={changeToUpperCase} onBlur={onBlurCheck} />
                                                                        {airportCodeInputHasError && <p className="error-text">{t('validationAirportCode')}</p>}
                                                                        {airportCodeInvalidInputError && <p className="error-text">{t('validationAirportCodeInvalidData')}</p>}
                                                                        {airportCodeLengthInputHasError && <p className="error-text">{t('validationAirportCodeLength')}</p>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-9 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='airportName'>{t('airportName')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength="100" type='text' className="form-control form-control-sm" id='airportName' ref={airportNameInputRef}
                                                                            onBlur={onBlurCheck} />
                                                                        {airportNameInputHasError && <p className="error-text">{t('validationAirportName')}</p>}
                                                                        {airportNameInvalidInputError && <p className="error-text">{t('validationAirportNameInvalidData')}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/AllAirport" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href="/AllAirport" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                        {(checkSecurityDetails("Master", "Airport", "Master_Airport_Insert") || checkSecurityDetails("Master", "Airport", "Master_Airport_Update")) ?
                                                                            <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                                            : <span id="btnNew"></span>}
                                                                        {checkSecurityDetails("Master", "Airport", "Master_Airport_Insert") ? <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>{t('new')}</button>
                                                                            : <span id="btnNew"></span>}
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-3 col-md-12 actions'>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default AirportForm
