import { Fragment, useEffect, useState, React } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import Files from 'react-files';
import { Tooltip as ReactTooltip } from "react-tooltip";

const Document = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [value, setValue] = useState('');
    const [files, setFiles] = useState([]);
    const [remarksInvalidInputError, setRemarksInvalidInputError] = useState(false);

    const handleChange = (newFiles) => {
        setFiles(prevFiles => [...prevFiles, ...newFiles])
    }

    const handleFileRemove = (fileId) => {
        setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.id !== fileId))
    }

    const handleClearFiles = () => {
        setFiles([])
    }

    const handleUploadFiles = () => {
        const formData = new FormData()
        files.forEach((file) => {
            formData.append(file.id, new Blob([file], { type: file.type }), file.name || 'file')
        })

        console.log(formData)
        // axios.post('/files', formData).then(() => {
        //     window.alert(`${files.length} files uploaded succesfully!`)
        //     setFiles([])
        // }).catch((err) => {
        //     window.alert(`Error uploading files: ${err.message}`)
        // })
    }

    function onBlurCheck() {
        if (document.querySelector('#remarks').value !== '') {
            if (!document.querySelector('#remarks').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                setRemarksInvalidInputError(true);
                return;
            }
            else {
                setRemarksInvalidInputError(false);
            }
        }
    }

    return (
        <Fragment>
            <ReactTooltip
                anchorId='Upload'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Upload File"}
            />
            <ReactTooltip
                anchorId='Remove'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Remove File"}
            />
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service">Documents<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <div className="files">
                                            <Files
                                                className='files-dropzone'
                                                onChange={handleChange}
                                                //onError={handleError}
                                                accepts={['image/png', '.pdf', 'audio/*', '.csv', '.txt', '.doc', '.docx', '.xls', '.xlsx']}
                                                multiple
                                                maxFileSize={10000000}
                                                minFileSize={0}
                                                clickable>
                                                <span id='Upload' className='file-upload text-primary'><i class="fas fa-file-upload"></i> Drop files here or click to upload</span>
                                            </Files>
                                            {files.length > 0 && (
                                                <div className="files-list mt-4">
                                                    <ul>
                                                        {files.map(file => (
                                                            <li key={file.id} className="files-list-item">
                                                                {/* <div className="files-list-item-preview">
                                                                    {file.preview.type === 'image'
                                                                        ? <img className="files-list-item-preview-image" src={file.preview.url} />
                                                                        : <div className="files-list-item-preview-extension">{file.extension}</div>}
                                                                </div> */}
                                                                <div className="files-list-item-content">
                                                                    <span className="files-list-item-content-item files-list-item-content-item-1">{file.name}</span>
                                                                    {/* <span className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</span> */}
                                                                    <span id='Remove' className="files-list-item-remove mx-4 cursor-pointer" onClick={() => handleFileRemove(file.id)}>
                                                                        <i class="fas fa-times-circle h5 text-danger"></i>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {/* <button onClick={handleClearFiles}>Remove All Files</button> */}
                                            {/* <button id='btnSubmit' onClick={handleUploadFiles} className="btn btn_theme btn_md mt-2 mx-2 d-none" type="button" >Upload</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Remarks<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='remarks'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            onBlur={onBlurCheck}
                                        />
                                    </div>
                                    {remarksInvalidInputError &&
                                        <span className="error-text col-lg-12 mt-3">{('Remarks is not valid.')}</span>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)

};

export default Document;

