import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';
import { useHistory } from "react-router-dom";
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const FlightBookingItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    function openRetrieveBooking(id) {
        sendRequest(id);
    }

    useEffect(() => {
        if (status === 'completed') {
            let bookingJSON = [];


            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", props.reportJSON.status === 'Canceled' ? true : false);

                // history.push({
                //     pathname: '/RetrieveTrip',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])

    return <Fragment>
        {
            (props.reportJSON.sequenceNo !== "0") &&
            <tr>
                <td align={'right'}>{props.reportJSON.segmentNo}</td>
                <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking(props.reportJSON.confirmationNumber)}><div className='min-width-75'>{props.reportJSON.confirmationNumber} &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
                <td scope="row">{getFormatDate(props.reportJSON.bookingData.split('T')[0].replaceAll('-', ''))} {props.reportJSON.bookingData.split('T')[1] !== undefined ? getFormatTime(props.reportJSON.bookingData.split('T')[1].replaceAll(':', '').substring(0, 4)) : ''}</td>
                <td scope="row">{props.reportJSON.companyName}</td>
                <td scope="row">{props.reportJSON.passengerName}</td>
                <td scope="row" align={'center'}>{props.reportJSON.employeeCode}</td>
                <td scope="row" align={'center'}>{props.reportJSON.passengerCount}</td>
                <td scope="row">{props.reportJSON.bookingType}</td>
                <td scope="row">{getFormatDate(props.reportJSON.departureDate)}</td>
                <td scope="row">{props.reportJSON.from + "-" + props.reportJSON.to}</td>
                <td scope="row" align={'center'}>{props.reportJSON.validating}</td>
                <td scope="row">{props.reportJSON.airline}</td>
                <td scope="row" align={'center'}>{props.reportJSON.rbd}</td>
                <td scope="row" align={'center'}>{props.reportJSON.cabin}</td>
                <td scope="row">{props.reportJSON.source}</td>
                <td scope="row">{props.reportJSON.pnr}</td>
                <td scope="row">{props.reportJSON.airlinePNR}</td>
                <td scope="row">{props.reportJSON.status}</td>
                <td scope="row" align={'center'}>{props.reportJSON.currencyCode}</td>
                <td scope="row" align={'right'}>{props.reportJSON.baseFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.baseFare).toFixed(2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.taxes !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.taxes).toFixed(2)) : ''}</td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row" align={'right'}>{props.reportJSON.serviceFee !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.serviceFee).toFixed(2)) : ''}</td>}
                <td scope="row" align={'right'}>{props.reportJSON.markup !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.markup).toFixed(2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.addOns !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.addOns).toFixed(2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.airlineTxnChrg !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.airlineTxnChrg).toFixed(2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.totalFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.totalFare).toFixed(2)) : ''}</td>
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row" align={'right'}>{props.reportJSON.commission !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.commission).toFixed(2)) : ''}</td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                <td scope="row">{props.reportJSON.formOfPayment}</td>
                <td scope="row">{props.reportJSON.bookedBy}</td>
            </tr>
        }
        {
            (props.reportJSON.sequenceNo === "0" && props.reportJSON.equivTotalFare !== '') &&
            <tr>
                <td></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row"></td>}
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                {/* <td scope="row">Total</td> */}
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row"></td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                <td scope="row"></td>
                <td scope="row"></td>
            </tr>
        }

    </Fragment>

};

export default FlightBookingItem;