const locaterNumber = localStorage.getItem("profileLocaterNumber") !== 'undefined' ? localStorage.getItem("profileLocaterNumber") : ''
const dkNumber = localStorage.getItem("dkNumber") !== 'undefined' ? localStorage.getItem("dkNumber") : "\"\""

let paxInformation = JSON.stringify({
    "PersonName": {
        "NamePrefix": "",
        "NameTitle": "",
        "GivenName": "",
        "Surname": "",
        "MiddleName": "",
        "NameSuffix": null,
        "NameType": null
    },
    "Gender": "M",
    "Telephone": [
        {
            "PhoneNumber": "",
            "Mobile": ""
        }
    ],
    "Email": [
        {
            "EmailId": ""
        }
    ],
    "GuestTypeCode": "ADT",
    "GuestRefNumber": "1",
    "RoomIndex": "1",
    "Age": null,
    "IsHolder": false,
    "CustomerIdentifierNumber": JSON.parse(dkNumber)
})


export const CreateBookingJSON = (selectHotel) => {
    let hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
    let hotelDetailsResponsePrice = hotelDetailsResponse.data.hotelResults[0].price.filter(a => a.rateKey === selectHotel.rateKey);
    hotelDetailsResponse.data.hotelResults[0].price = hotelDetailsResponsePrice;
    let hotelDetailsResponseRoomRateDetails = hotelDetailsResponse.data.hotelResults[0].roomRateDetails.filter(a => a.rateKey === selectHotel.rateKey);
    hotelDetailsResponse.data.hotelResults[0].roomRateDetails = hotelDetailsResponseRoomRateDetails;

    let createPaxInformation = [];
    let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem("hotelSearchRequestJson"));
    hotelSearchRequestJson.Data.RoomGuests.forEach(element => {
        let paxInfromationDetails = JSON.parse(paxInformation);
        paxInfromationDetails.GuestRefNumber = element.RoomIndex.toString()
        paxInfromationDetails.RoomIndex = element.RoomIndex.toString()
        paxInfromationDetails.NoOfAdults = element.NoOfAdults.toString()
        createPaxInformation.push(paxInfromationDetails);
    });

    const BookingJSON = ({
        "officeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "BookingType": "",
            "bookingKey": "",
            "travelAgentName": localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null ? JSON.parse(localStorage.getItem('displayName')) : "",
            "POS": null,
            "RequestDetails": {
                "Environment": "PRODUCTION",
                "echoToken": JSON.parse(sessionStorage.getItem('HotelEchoToken')),
                "traceId": JSON.parse(localStorage.getItem('traceID')),
                "UserID": JSON.parse(localStorage.getItem('userID')),
                "SwitchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
                "IPAddress": localStorage.getItem("IPAddress"),
                "primaryLanguageId": "EN"
            },
            "TravelerInfo": {
                "GuestInfo": createPaxInformation
            },
            "hotelResults": hotelDetailsResponse.data.hotelResults,
            "PaymentInfo": {
                "PaymentMode": [
                    {
                        "Type": null,
                        "Name": "CREDITCARD",
                        "PaymentID": null,
                        "Amount": "0",
                        "CardIsEncrypt": true,
                        "PaymentModeInfo": [
                            {
                                "FieldName": "CARDTYPE",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "CARDNO",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "FIRSTNAME",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "LASTNAME",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "EXPIRYEAR",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "EXPIRMONTH",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "ADDRESS",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "COUNTRY",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "STATE",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "CITY",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "POSTALCODE",
                                "FieldValue": ""
                            },
                        ],
                        "PaymentAssociatedPax": null,
                        "AdditionalPaymentInfo": null
                    }
                ]
            },
            "RateKey": selectHotel.rateKey,
            "TPAExtensions": null,
            "HotelMiscellaneousInfo": {
                "MiscellaneousInfo": [
                    {
                        "Code": "RC",
                        "Text": "",
                        "Category": null,
                        "RemarkType": "General",
                        "GuestRefNumber": "1",
                        "TPAExtensions": null
                    },
                    {
                        "Code": "RC",
                        "Text": "",
                        "Category": null,
                        "RemarkType": "InternalRemarks",
                        "GuestRefNumber": "1",
                        "TPAExtensions": null
                    }
                ]
            }
        }
    })
    return BookingJSON;
}