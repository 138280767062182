import React, { useEffect } from "react";
import { Fragment,Redirect } from "react";
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../components/Common/Loading/LoadingSpinner';
import { FLIGHTSEARCH ,TRAVELREQUEST} from './CONSTANTS';
import { getConfigurationByBoolen } from '../utils/ConfigurationsFunction'
const Loading = () => {
    const history = useHistory();
    useEffect(() => {
      
        setTimeout(() => {
            sessionStorage.setItem("LoaderHeader","0");
            !getConfigurationByBoolen("ALLOW_TRAVEL_REQUEST")?history.push(FLIGHTSEARCH):JSON.parse(localStorage.getItem('companyName')) !== "FL Travel Agent"?history.push(TRAVELREQUEST):history.push("/SearchPNR");
            //history.go();
            //return <Redirect to={FLIGHTSEARCH}></Redirect>
        }, 1000);

    }, []);
    return (
        <>
            {/* Page Not Found! */}
            <Fragment>
                {/*     <!-- Common Banner Area --> */}
                <section id="common_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 full-page-loader">
                            <LoadingSpinner></LoadingSpinner>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <div className="preloader">
                    <div className="d-table">
                        <div >
                            <div >
                                
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                </div> */}


            </Fragment>
        </>
    );
};
export default Loading;
