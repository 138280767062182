import {
    GET_ALL_OFFICE,
    GET_OFFICE,
    ADD_OFFICE,
    UPDATE_OFFICE,
    DELETE_OFFICE,
    SEARCH_OFFICE,
    SEARCH_COMMON_LIST,
    SEARCH_OFFICE_LIST
} from './CONSTANTS'

export async function getAllOffice() {

    const response = await fetch(GET_ALL_OFFICE(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch office.');
    }
    return data;
}
export async function searchOfficeApi(searchData) {    
    const response = await fetch(SEARCH_OFFICE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch office.');
    }    
    return data;
}
export async function searchOfficeList(searchData) {    
    const response = await fetch(SEARCH_OFFICE_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch office.');
    }    
    return data;
}
export async function getSingleOffice(ID) {    
    const response = await fetch(GET_OFFICE(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch office.');
    }    
    return data;
}
export async function deleteSingleOffice(deleteData) {
    const response = await fetch(DELETE_OFFICE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete office.');
    }
    return data;
}
export async function addOffice(addData) {
    const response = await fetch(ADD_OFFICE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create office.');
    }
    return data;
}
export async function updateOfficeApi(updateData) { 
    const response = await fetch(UPDATE_OFFICE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update office.');
    }    
    return data;
}
export async function searchCommonList(searchData) {    
    const response = await fetch(SEARCH_COMMON_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch category.');
    }
    
    return data;
}

