import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttpGet from '../../../services/use-http';
import RoleList from './Components/RoleList';
import { NavLink, useHistory } from 'react-router-dom';
import { UPDATEROLE } from '../../../navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { RoleOfficeWiseList } from "../../../services/User-API";

const SearchRole = () => {
    useDocumentTitle('Search Role');

    const [enteredRole, setEnterRole] = useState("");
    const [isFirtTime, setFirstTime] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const { t } = useTranslation(['translation.Role']);

    const roleChangeHandler = (event) => {
        setEnterRole(event.target.value);
    };

    const history = useHistory();

    const { sendRequest, status, data: loadedRole, error } = useHttpGet(RoleOfficeWiseList);
    const onResetHandler = (event) => {
        // event.preventDefault();
        // setEnterCode('');
        // setEnterName('');
        // setCountry_Code('');
        history.push('/searchrole');
        history.go();
    }

    const enteredRoleInputRef = useRef('');
    const [enteredRoleInvalidInputError, setEnteredRoleInvalidInputError] = useState(false);

    function onBlurCheck() {
        if (enteredRole.length !== 0) {
            if (!enteredRole.match('^[a-zA-Z ]+$')) {
                setEnteredRoleInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredRoleInvalidInputError(false);
            }
        }
        else {
            setEnteredRoleInvalidInputError(false);
        }
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);

        if (enteredRole.trim().length !== 0) {
            if (!enteredRole.match('^[a-zA-Z ]+$')) {
                enteredRoleInputRef.current.focus();
                setEnteredRoleInvalidInputError(true);
                return;
            }
            else {
                setEnteredRoleInvalidInputError(false);
            }
        }
        else {
            setEnteredRoleInvalidInputError(false);
        }

        if (enteredRoleInvalidInputError === false) {
            setIsSubmitClicked(true);

            const searchRoleList = ({
                roleName: enteredRole.trim(),
                officeID: JSON.parse(localStorage.getItem('officeID')),
            });
            sendRequest(searchRoleList);
        }
    }, [sendRequest, enteredRole, enteredRoleInvalidInputError])
    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }
    if (error) {
        history.push('/Error')
       // return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    for (let index in loadedRole) {
        if (loadedRole['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedRole[index];
            }
        }
    }

    const errorHandler = () => {
        history.go('/SearchRole');
    };

    const ErrorShow = (isSubmitClicked && enteredRoleInvalidInputError === false) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const RoleListTemp = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <RoleList role={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        {/* <section id='common_banner'><div className='container'></div></section> */}
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Role</label>
                                            <div className='row'>
                                                <div className='col-md-12 col-sm-12 control'>
                                                    <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('role')}</label>
                                                    <input maxLength="50" type='text' className="mb-0 form-control form-control-sm col-lg-12" id='role' value={enteredRole} onChange={roleChangeHandler} ref={enteredRoleInputRef} onBlur={onBlurCheck} autoFocus />
                                                    {enteredRoleInvalidInputError &&
                                                        <p className="error-text">{t('validationRoleInvalidData')}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-3 col-md-12">
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>
                                                {checkSecurityDetails("User Management", "Role", "User Management_Role_Insert") &&
                                                    <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATEROLE}>
                                                        {t('new')}
                                                    </NavLink>}
                                                {checkSecurityDetails("User Management", "Role", "User Management_Role_Search") &&
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

            {RoleListTemp}
        </Fragment>)
};

export default SearchRole;
