import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import AuthorizeTwoFactorForm from '../Components/AuthorizeTwoFactorForm'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { checkAuthentication } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
import { useHistory } from 'react-router-dom';

import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import AuthContext from '../../../redux/contexts/auth-context'
import { FLIGHTSEARCH, LOADING } from '../../../navigation/CONSTANTS';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST, GET_ALL_SIGHTSEEING_LIST } from '../../../services/CONSTANTS';


const AuthorizeTwoFactor = () => {
    useDocumentTitle('MFA')
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(checkAuthentication);
    const [errors, setErrors] = useState();
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        let airportSuggestionResutTemp = [];
        fetch(GET_ALL_AIRPORT_LIST(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson['data'] !== null) {
                    responseJson['data'].forEach(element2 => {
                        airportSuggestionResutTemp.push(element2);
                    });
                }
                localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
            });

        let blockedCountryCityListTemp = [];
        fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson['data'] !== null) {
                    responseJson['data'].forEach(element2 => {
                        blockedCountryCityListTemp.push(element2);
                    });
                }
                localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
            });

        let sightseeingSuggestionResutTemp = [];
        fetch(GET_ALL_SIGHTSEEING_LIST(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson['data'] !== null) {
                    responseJson['data'].forEach(element2 => {
                        sightseeingSuggestionResutTemp.push(element2);
                    });
                }
                localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
            });
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];

                        const expirationTime = new Date(
                            new Date().getTime() + +loadedData.expireIn * 1000
                        );
                        console.log(loadedData);

                        authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                            loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                            loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                            loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                            loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                            loadedData.defaultCity);
                        sessionStorage.setItem("LoaderHeader", "1");
                        window.location.pathname = `${LOADING}`;

                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct authenticator code.',
                    });
                    document.getElementById('Inputsecuritycode').value = '';
                }
            }
        }



    }, [status, loadedAuthenticationDetails, authCtx, history])

    const onValidated2FAHandler = (requestData) => {
        sendRequest(requestData);
    };

    const errorHandler = () => {
        setErrors(null);
    };
    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            <div className='position-relative bg-sky-blue'>

                <img src="assets/img/globetrotterdark.png" alt="" className='log-in-head-logo-bg position-absolute' />

            </div>
            <section id="common_banner-WithoutAuth1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <AuthorizeTwoFactorForm onValidated2FA={onValidated2FAHandler} isLoading={status === 'pending'} />

        </Fragment>)

};



export default AuthorizeTwoFactor;
