import { Fragment } from "react";
const RulesItem = () => {
    return <Fragment>
        <div className="">
            <div className="row" key={'1'}>
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-1">
                    <div className="card text-dark card-bg-blue mb-2">
                        <div className="card-body">
                            {/* <div className="row"> */}
                            <div className="col-md-12 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    Manager
                                </h6>

                            </div>
                            <hr className="my-1" />
                            {/* </div> */}
                            <div className="row">
                                <div className="col-md-10">
                                    <p className="card-text mb-0"><strong>Trip Reason: </strong>All</p>
                                    <p className="card-text mb-0"><strong>From Region: </strong>ALL</p>
                                    <p className="card-text mb-0"><strong>To Region: </strong>ALL</p>
                                </div>
                                <div class="col-md-2 my-auto">
                                    <div class="float-lg-end float-md-end">
                                        <a class="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show"
                                        ><i class="fa-sharp fa-solid fa-tv"></i></a>
                                        <a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit"><i class="fa-solid fa-pen"></i></a>
                                        <button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete"><i class="fas fa-trash-alt"></i></button>
                                    </div>
                                </div>
                                {/* <div className="col-md-2 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_View") && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateWallet/${props.officeID}/0/${props.id}`, edit: 0, officeID: props.officeID }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}


                                    {checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateWallet/${props.officeID}/1/${props.id}`, edit: 1, officeID: props.officeID }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" key={'2'}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    Product Manager
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="col-md-10">
                                <p className="card-text mb-0"><strong>Trip Reason: </strong>Business</p>
                                <p className="card-text mb-0"><strong>From Region: </strong> ALL</p>
                                <p className="card-text mb-0"><strong>To Region: </strong> ALL</p>
                            </div>
                            <div class="col-md-2 my-auto">
                                <div class="float-lg-end float-md-end">
                                    <a class="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" ><i class="fa-sharp fa-solid fa-tv"></i></a>
                                    <a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" ><i class="fa-solid fa-pen"></i></a>
                                    <button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete"><i class="fas fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Fragment>
}

export default RulesItem;