import React, { Fragment, useEffect, useState } from 'react';
import { getAllCountry, SearchCountry } from '../../../services/Country-API'
import useHttp from '../../../services/use-http';
/* import { useTranslation } from 'react-i18next'; */
import Dropdown from 'react-select';

const CountryDropDownList = ({ countryCodeValue, countryNameText, currentInputValue, iSDisabled, continentCode = '', focus = false }) => {
    /*  const { t } = useTranslation(['translation.ProfileDetails']); */

    let varHttp;

    if (continentCode === '') {
        varHttp = getAllCountry;
    }
    else {
        varHttp = SearchCountry;
    }

    const { sendRequest, data: countryData, status } = useHttp(varHttp);

    const [valueCountryData, setValueCountryData] = useState();

    const handleChangeCountry = (e) => {
        if (status === 'completed') {
            setValueCountryData(e.value);
            countryCodeValue(e.value);
            countryNameText(e.label);
        }
    };

    useEffect(() => {
        if (continentCode !== '') {
            const onSearchCountry = ({
                continentId: continentCode,
                countryCode: "",
                countryName: ""
            });

            sendRequest(onSearchCountry);
        }
        else {
            sendRequest();
        }

        setValueCountryData(currentInputValue);
    }, [currentInputValue, sendRequest, continentCode])

    let countryOptions = []
    let countryArrayData = [];
    for (let i in countryData) {
        if (countryData['errors'].status === 'FALSE') {
            if (i === 'data') {
                countryArrayData = countryData[i];
            }
        }
    }

    for (let index = 0; index < countryArrayData.length; index++) {
        countryOptions = [...countryOptions, { value: countryArrayData[index].countryCode, label: countryArrayData[index].countryName }]
    }

    // const countryOptions = [
    //     { value: 'IN', label: 'India' },
    //     { value: 'ZA', label: 'South Africa' }
    // ];

    //const getCountryLabelByValue = (countryOptions, value) => countryOptions.find(countryOptions => countryOptions.value === value)?.label || "";
    const getCurrentInputValue = countryOptions.filter(function (option) {
        return option.value === currentInputValue;
    })
    const getValueCountryData = countryOptions.filter(function (option) {
        return option.value === valueCountryData;
    })

    return (
        <Fragment>
            <Dropdown onChange={handleChangeCountry}
                //value={currentInputValue !== undefined?getCurrentInputValue:getValueCountryData}
                value={getValueCountryData !== undefined ? getValueCountryData : getCurrentInputValue}
                options={countryOptions}
                textField="CountryName"
                dataItemKey="CountryCode"
                arrowClosed={<span className="arrow-closed" />}
                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                //disabled={iSDisabled!==undefined?iSDisabled==="true"?true:false:false} 
                isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                autoFocus={focus}
            />
        </Fragment>
    )
}

export default CountryDropDownList
