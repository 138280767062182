export const dateList = [
    { value: "01", label: "1" },
    { value: "02", label: "2" },
    { value: "03", label: "3" },
    { value: "04", label: "4" },
    { value: "05", label: "5" },
    { value: "06", label: "6" },
    { value: "07", label: "7" },
    { value: "08", label: "8" },
    { value: "09", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
];
export const monthList = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
    
];
export const expiryYearList = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
    { value: "2031", label: "2031" },
    { value: "2032", label: "2032" },
    { value: "2033", label: "2033" },
    { value: "2034", label: "2034" },
    { value: "2035", label: "2035" },
    { value: "2036", label: "2036" },
    { value: "2037", label: "2037" },
    { value: "2038", label: "2038" },
    { value: "2039", label: "2039" },
    { value: "2040", label: "2040" },
];

export const dobYearList=[];
for (let index = 0; index < 112; index++) {
    dobYearList.push({ value: (new Date().getFullYear() - (12 + index)).toString(), label: (new Date().getFullYear() - (12 + index)).toString() });
}

export const dobYearListTemp = [
    { value: "2012", label: "2012" },
    { value: "2011", label: "2011" },
    { value: "2010", label: "2010" },
    { value: "2009", label: "2009" },
    { value: "2008", label: "2008" },
    { value: "2007", label: "2007" },
    { value: "2006", label: "2006" },
    { value: "2005", label: "2005" },
    { value: "2004", label: "2004" },
    { value: "2003", label: "2003" },
    { value: "2002", label: "2002" },
    { value: "2001", label: "2001" },
    { value: "2000", label: "2000" },
    { value: "1999", label: "1999" },
    { value: "1998", label: "1998" },
    { value: "1997", label: "1997" },
    { value: "1996", label: "1996" },
    { value: "1995", label: "1995" },
    { value: "1994", label: "1994" },
    { value: "1993", label: "1993" },
    { value: "1992", label: "1992" },
    { value: "1991", label: "1991" },
    { value: "1990", label: "1990" },
    { value: "1989", label: "1989" },
    { value: "1988", label: "1988" },
    { value: "1987", label: "1987" },
    { value: "1986", label: "1986" },
    { value: "1985", label: "1985" },
    { value: "1984", label: "1984" },
    { value: "1983", label: "1983" },
    { value: "1982", label: "1982" },
    { value: "1981", label: "1981" },
    { value: "1980", label: "1980" },
    { value: "1979", label: "1979" },
    { value: "1978", label: "1978" },
    { value: "1977", label: "1977" },
    { value: "1976", label: "1976" },
    { value: "1975", label: "1975" },
    { value: "1974", label: "1974" },
    { value: "1973", label: "1973" },
    { value: "1972", label: "1972" },
    { value: "1971", label: "1971" },
    { value: "1970", label: "1970" },
    { value: "1969", label: "1969" },
    { value: "1968", label: "1968" },
    { value: "1967", label: "1967" },
    { value: "1966", label: "1966" },
    { value: "1965", label: "1965" },
    { value: "1964", label: "1964" },
    { value: "1963", label: "1963" },
    { value: "1962", label: "1962" },
    { value: "1961", label: "1961" },
    { value: "1960", label: "1960" },
    { value: "1959", label: "1959" },
    { value: "1958", label: "1958" },
    { value: "1957", label: "1957" },
    { value: "1956", label: "1956" },
    { value: "1955", label: "1955" },
    { value: "1954", label: "1954" },
    { value: "1953", label: "1953" },
    { value: "1952", label: "1952" },
    { value: "1951", label: "1951" },
    { value: "1950", label: "1950" },
    { value: "1949", label: "1949" },
    { value: "1948", label: "1948" },
    { value: "1947", label: "1947" },
    { value: "1946", label: "1946" },
    { value: "1945", label: "1945" },
    { value: "1944", label: "1944" },
    { value: "1943", label: "1943" },
    { value: "1942", label: "1942" },
    { value: "1941", label: "1941" },
    { value: "1940", label: "1940" },
    { value: "1939", label: "1939" },
    { value: "1938", label: "1938" },
    { value: "1937", label: "1937" },
    { value: "1936", label: "1936" },
    { value: "1935", label: "1935" },
    { value: "1934", label: "1934" },
    { value: "1933", label: "1933" },
    { value: "1932", label: "1932" },
    { value: "1931", label: "1931" },
    { value: "1930", label: "1930" },
    { value: "1929", label: "1929" },
    { value: "1928", label: "1928" },
    { value: "1927", label: "1927" },
    { value: "1926", label: "1926" },
    { value: "1925", label: "1925" },
    { value: "1924", label: "1924" },
    { value: "1923", label: "1923" },
];

export const dobChildYearList=[];
for (let index = 0; index < 13; index++) {
    dobChildYearList.push({ value: (new Date().getFullYear() - (2 + index)).toString(), label: (new Date().getFullYear() - (2 + index)).toString() });
}

export const dobChildYearListTemp = [
    { value: "2011", label: "2011" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" }, 
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },

   
];

export const dobInfantYearList=[];
for (let index = 0; index < 3; index++) {
    dobInfantYearList.push({ value: (new Date().getFullYear() - (0 + index)).toString(), label: (new Date().getFullYear() - (0 + index)).toString() });
}

export const dobInfantYearListTemp = [
    { value: "2021", label: "2021" }, 
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  
    
];
export const titles_name = [

    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Dr.", label: "Dr." }    
    // { value: "Master", label: "Master" },
    // { value: "Miss", label: "Miss" }

];
export const titles_name_child = [
    { value: "Master", label: "Master" },
    { value: "Miss", label: "Miss" }

];
export const gender_list = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
];

export const journey_list_oneway = [
    { value: "1", label: "Outbound" }
];

export const journey_list_roundtrip = [
    { value: "1", label: "Outbound" },
    { value: "2", label: "Inbound" }
];

export const journey_list_multicity = [
    { value: "1", label: "Segment 1" },
    { value: "2", label: "Segment 2" },
    { value: "3", label: "Segment 3" },
    { value: "4", label: "Segment 4" },
    { value: "5", label: "Segment 5" }
];

export const hoursList = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" }
];

export const minutesList = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" }
];