// import { Fragment } from 'react';
import { Fragment } from 'react';
import FlightBookingItem from './FlightBookingItem';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';


const FlightBookingList = (props) => {
    

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    return <Fragment>

        <div className="col-lg-12" >{/* className="accordion-item"> */}
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>                            
                        </div>
                       <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='text-end w-15'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='min-width-185'>Transaction Date</th>
                                            <th scope="col" className='w-15'>Company Name</th>
                                            <th scope="col" className='min-width-210' align={'left'}>Employee Name</th>
                                            <th scope="col" className='min-width-125' align={'left'}>Employee Code</th>
                                            <th scope="col" className='w-15 text-center'>Service</th>
                                            <th scope="col" className='min-width-125'>Remarks</th>
                                            <th scope="col" className='min-width-125'>Reference Conf. No.</th>
                                            <th scope="col" className='w-15'>Currency</th>  
                                            <th scope="col" className='w-15'>Total Rate</th>                                           

                                        </tr>
                                    </thead>
                                    <tbody>
                                      
                                            <FlightBookingItem ></FlightBookingItem>
                                    </tbody>
                                </table>
                            </div>
                       </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default FlightBookingList;