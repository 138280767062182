import React from "react";
import { Fragment } from "react";

const Error = () => {
  return (   

     <Fragment>
        {/* <section className="gt_error_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        <div className=" text-end gt_error_banner-text">
                            <div className="title text-center">
                                <h1 className="text-white h1 my-3"><i className="fas fa- fa-times-circle border-radius50 bg-white text-danger"></i> Opps</h1>
                                <div className="h6 text-white">Something went wrong, try again.</div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>       */}

        <section className="bg-navy-primary">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-6 py-3">
                        <div className=" mt-5">
                            <img src="assets/img/error-bg.png" alt="img"  className="img-responsive py-5"/>
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto py-3">
                        <div className=" text-end ">
                            <div className="title text-center">
                                <div className="">
                                    <h1 className="text-white h1 my-3"><i className="fas fa- fa-times-circle border-radius50 bg-white text-danger"></i> Oops!</h1>
                                    <div className="h6 text-white">Something went wrong, try again.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        
    </Fragment>

  );
};
export default Error;
