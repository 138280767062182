import {
    GET_ALL_AIRPORT,
    GET_AIRPORT,
    ADD_AIRPORT,
    UPDATE_AIRPORT,
    DELETE_AIRPORT,
    SEARCH_AIRPORT
} from './CONSTANTS'

export async function getAllAirport() {

    const response = await fetch(GET_ALL_AIRPORT(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch airport.');
    }
    return data;
}
export async function searchAirport(searchData) {
    const response = await fetch(SEARCH_AIRPORT(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch airport.');
    }
    
    return data;
}
export async function getSingleAirport(ID) {
    const response = await fetch(GET_AIRPORT(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch airport.');
    }

    return data;
}

export async function deleteSingleAirport(deleteData) {
    const response = await fetch(DELETE_AIRPORT(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete airport.');
    }
    return data;
}

export async function addAirport(addData) {
    const response = await fetch(ADD_AIRPORT(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create airport.');
    }

    return data;
}
export async function updateAirport(updateData) {    
    const response = await fetch(UPDATE_AIRPORT(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update airport.');
    }
    return data;
}

