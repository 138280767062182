import {
    GET_ALL_AIRLINE,
    GET_AIRLINE,
    ADD_AIRLINE,
    UPDATE_AIRLINE,
    DELETE_AIRLINE,
    SEARCH_AIRLINE,
    SEARCH_ALL_AIRLINE
} from './CONSTANTS';

//function to get all custom fields
export async function getAllCustomFields() {

    // const response = await fetch(GET_ALL_AIRLINE(), {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //     },

    // });
    // const data = await response.json();
    const data = {"data":[{"airlineCode":"Microsoft","airlineName":"Microsoft","airlineLogo":"","airlineBinaryImage":null,"id":"63e0faad7768a5a3ddebafcd","traceID":"650c38e8374b40e2d9c74a37","userID":"639c09a45f75319494ad6316","createdDate":"2022-12-12T10:16:58.484Z","ipAddress":null}],"errors":{"error":{"code":"1007","description":"Record found."},"status":"FALSE"}};
    
    // if (!response.ok) {
    //     throw new Error(data.message || 'could not fetch custom fields');
    // }
    return data;


}

//function to get single custom fields
export async function getSingleCustomFields(ID) {
    const response = await fetch(GET_AIRLINE(ID), {
        method: "GET",
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch custom fields');
    }
    return data;

}


//function to add custom fields
export async function addCustomFields(addData) {
    const response = await fetch(ADD_AIRLINE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not create custom fields');
    }
    return data;

}


//function to update custom fields
export async function updateCustomFields(updateData) {
    const response = await fetch(UPDATE_AIRLINE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {

            'Content-Type': 'application/json',

            'Authorization': 'Bearer ' + localStorage.getItem('token'),

        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update custom fields');
    }
    return data;
}

export async function deleteSingleCustomFields(deleteData) {
    const response = await fetch(DELETE_AIRLINE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete custom fields');
    }
    return data;
}

export async function searchCustomFields(searchData) {
    const response = await fetch(SEARCH_AIRLINE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch custom fields');
    }
    return data;
}

export async function searchAllCustomFields(searchData) {
    // const response = await fetch(SEARCH_ALL_AIRLINE(), {
    //     method: 'POST',
    //     body: JSON.stringify(searchData),
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //     },
    // });

    // const data = await response.json()
    const data = {"data":[{"airlineCode":"Microsoft","airlineName":"Microsoft","airlineLogo":"","airlineBinaryImage":null,"id":"63e0faad7768a5a3ddebafcd","traceID":"650c38e8374b40e2d9c74a37","userID":"639c09a45f75319494ad6316","createdDate":"2022-12-12T10:16:58.484Z","ipAddress":null}],"errors":{"error":{"code":"1007","description":"Record found."},"status":"FALSE"}};
    
    // if (!response.ok) {
    //     throw new Error(data.message || 'could not fetch custom fields');
    // }
    return data;
}