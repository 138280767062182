import Dropdown from 'react-select';
import { useState, Fragment, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateImportBookingJSON } from '../../../../utils/BookingFunction';
import useHttp from '../../../../services/use-http';
import { getAirProgramList, getSpecialService, getMealDetails } from '../../../../services/CommonListData-API';
import AddOn from './SubComonents/AddOn';
import { toPascalCase } from '.././../../../utils/CommonFunction';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { searchCurrency } from '../../../../services/Currency-API';
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';
import { searchCommonList } from '../../../../services/Fee-API';

const AddOns = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [passengerInputHasError, setPassengerInputHasError] = useState(false);
    const [sectorInputHasError, setSectorInputHasError] = useState(false);
    const [addOnTypeInputHasError, setAddOnTypeInputHasError] = useState(false);
    const [addOnSubTypeBaggageInputHasError, setAddOnSubTypeBaggageInputHasError] = useState(false);
    const [addOnSubTypeBaggageInvalidInputHasError, setAddOnSubTypeBaggageInvalidInputHasError] = useState(false);
    const [addOnSubTypeMealInputHasError, setAddOnSubTypeMealInputHasError] = useState(false);
    const [addOnSubTypeSeatInputHasError, setAddOnSubTypeSeatInputHasError] = useState(false);
    const [addOnSubTypeSeatInvalidInputHasError, setAddOnSubTypeSeatInvalidInputHasError] = useState(false);
    //const [amountInputHasError, setAmountInputHasError] = useState(false);
    const [amountInvalidInputHasError, setAmountInvalidInputHasError] = useState(false);
    const [currencyCodeInputHasError, setCurrencyCodeInputHasError] = useState(false);
    const [addOnSubTypSpecialServiceInputHasError, setAddOnSubTypSpecialServiceInputHasError] = useState(false);
    const [addOnSubTypFrequentFlyerInputHasError, setAddOnSubTypFrequentFlyerInputHasError] = useState(false);
    const [addOnSubTypeFrequentFlyerNoInputHasError, setAddOnSubTypeFrequentFlyerNoInputHasError] = useState(false);
    const [addOnSubTypeFrequentFlyerNoInvalidInputHasError, setAddOnSubTypeFrequentFlyerNoInvalidInputHasError] = useState(false);
    const [addOnSubTypStatusInputHasError, setAddOnSubTypStatusInputHasError] = useState(false);
    const [addOnDescriptionInputHasError, setAddOnDescriptionInputHasError] = useState(false);
    const [addOnDescriptionInvalidInputHasError, setAddOnDescriptionInvalidInputHasError] = useState(false);

    const [addOnTypeValue, SetAddOnTypeValue] = useState();
    const [mealValue, SetMealValue] = useState();
    const [mealName, SetMealName] = useState();
    const [passengerValue, SetPassengerValue] = useState();
    const [sectorValue, SetSectorValue] = useState();
    const [sectorName, SetSectorName] = useState();
    const history = useHistory();
    const [confirm, setConfirm] = useState();
    const [currencyCode, setCurrencyCode] = useState('');
    const [specialServiceValue, SetSpecialServiceValue] = useState();
    const [specialServiceName, SetSpecialServiceName] = useState();
    const [frequentFlyerValue, SetFrequentFlyerValue] = useState();
    const [frequentFlyerName, SetFrequentFlyerName] = useState();
    const [addOnStatusValue, SetAddOnStatusValue] = useState();
    const [addOnStatusName, SetAddOnStatusName] = useState();
    const [disabledControl, setDisabledControl] = useState(false);


    const { sendRequest: sendRequestMealDetailsData, data: loadedMealDetails } = useHttp(getMealDetails);
    const { sendRequest: sendRequestCurrencyList, data: loadedCurrency } = useHttp(searchCurrency);
    const { sendRequest: sendRequestSpecialServiceData, data: loadedSpecialServiceData } = useHttp(getSpecialService);
    const { sendRequest: sendRequestAirlineProgramData, data: loadedAirProgramData } = useHttp(getAirProgramList);
    const { sendRequest: sendRequestCommonListAddOnStatus, data: loadedAddOnStatus } = useHttp(searchCommonList);

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

    useEffect(() => {

        if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {

            if (bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.length > 0 && bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0] !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn[0] !== undefined) {
                bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.forEach(elementAddOn => {
                    let travelerCount = bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === elementAddOn.travelerRefNumber);

                    if (travelerCount.length <= 0) {
                        let addOn = props.traveler.travelerInfo.specialReqDetails[0].addOns.addOn.filter(a => !(a.travelerRefNumber == elementAddOn.travelerRefNumber));

                        bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn = addOn;
                        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                    }
                });
            }
        }
    })

    useEffect(() => {
        sendRequestMealDetailsData();
        sendRequestSpecialServiceData();
        sendRequestAirlineProgramData();
        const onSearchCommonListAddOnStatus = ({
            methodName: "SSRActionList",
            methodCode: "SSR",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListAddOnStatus(onSearchCommonListAddOnStatus);
    }, [])

    useEffect(() => {
        const onSearchCurrencyList = ({
            currCode: "",
            currName: ""
        });

        sendRequestCurrencyList(onSearchCurrencyList);

    }, [sendRequestCurrencyList])

    useEffect(() => {
        if (getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE") !== null && getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE") !== '') {
            let FareDisplayCurrencyCode = getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE");
            if (FareDisplayCurrencyCode.length > 0) {
                setCurrencyCode(FareDisplayCurrencyCode);
            }
        }
    }, [])

    let mealDetailsOptions = []
    let mealDetailsArrayData = [];

    if (loadedMealDetails !== null && loadedMealDetails.errors.status === 'FALSE') {
        mealDetailsArrayData = loadedMealDetails.data;
        mealDetailsOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < mealDetailsArrayData.length; index++) {
            mealDetailsOptions = [...mealDetailsOptions, { value: mealDetailsArrayData[index].code, label: mealDetailsArrayData[index].name }]
        }
    }

    let specialServiceOptions = []
    let specialServiceArrayData = [];

    if (loadedSpecialServiceData !== null && loadedSpecialServiceData.errors.status === 'FALSE') {
        specialServiceArrayData = loadedSpecialServiceData.data;
        specialServiceOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < specialServiceArrayData.length; index++) {
            specialServiceOptions = [...specialServiceOptions, { value: specialServiceArrayData[index].code, label: specialServiceArrayData[index].name }]
        }
    }

    let arilineProgramOptions = []
    let arilineProgramArrayData = [];

    if (loadedAirProgramData !== null && loadedAirProgramData.errors.status === 'FALSE') {
        arilineProgramArrayData = loadedAirProgramData.data;
        arilineProgramOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < arilineProgramArrayData.length; index++) {
            arilineProgramOptions = [...arilineProgramOptions, { value: arilineProgramArrayData[index].code, label: arilineProgramArrayData[index].name }]
        }
    }

    let currencyOptions = []
    let currencyArrayData = [];
    for (let i in loadedCurrency) {
        if (loadedCurrency['errors'].status === 'FALSE') {
            if (i === 'data') {
                currencyArrayData = loadedCurrency[i];
            }
        }
    }
    for (let index = 0; index < currencyArrayData.length; index++) {
        currencyOptions = [...currencyOptions, { value: currencyArrayData[index].currCode, label: currencyArrayData[index].currName }]
    }

    let passengerDetailsOptions = [];
    let sectorDetailsOptions = [];

    if (bookingJSON !== null && bookingJSON.data !== undefined) {
        bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
            passengerDetailsOptions.push({ value: elementTraveler.travelerRefNumber, label: elementTraveler.personName.namePrefix !== undefined ? elementTraveler.personName.namePrefix + " " + elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname : elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname })
        });
    }

    if (bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== '') {
        bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
            sectorDetailsOptions.push({ value: elementOriginDestinationOption.flightSegment[0].flightRefNumber, label: elementOriginDestinationOption.flightSegment[0].departureAirport.locationCode + "-" + elementOriginDestinationOption.flightSegment[0].arrivalAirport.locationCode })
        });
    }

    let addOnStatusArrayData = [];
    for (let i in loadedAddOnStatus) {
        if (loadedAddOnStatus['errors'].status === 'FALSE') {
            if (i === 'data') {
                addOnStatusArrayData = loadedAddOnStatus[i];
            }
        }
    }

    let addOnStatusOptions = [];
    for (let index = 0; index < addOnStatusArrayData.length; index++) {
        addOnStatusOptions = [...addOnStatusOptions, { value: addOnStatusArrayData[index].listID, label: addOnStatusArrayData[index].listValue + " (" + addOnStatusArrayData[index].listID + ")" }]
    }

    function assignAddOns(event) {
        event.preventDefault();

        // let additionalServicesJSON = JSON.parse(localStorage.getItem("AdditionalServicesData"));
        // let additionalServicesSeatJSON = JSON.parse(localStorage.getItem("AdditionalServicesSeatData"));
        let setValidationStaus = true;

        // let addOn = [];
        // if (additionalServicesJSON !== null && additionalServicesJSON !== undefined && additionalServicesJSON.length > 0) {
        //     additionalServicesJSON.filter(element => element.ServiceType.split('_')[0] === "MealDetails").forEach(element => {
        //         addOn.push({
        //             "ssrCode": element.ServiceCode,
        //             "ssrDescription": mealDetailsOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
        //             "travelerRefNumber": element.PassengerNo,
        //             "flightRefNumber": element.ServiceType.split('_')[1],
        //             "SSRCategory": "",
        //             "ssrCharge": "0",
        //             "ssrType": "MEAL",
        //             "serviceQuantity": document.querySelector('#chkSelectAllFlights' + element.PassengerNo) !== null ? document.querySelector('#chkSelectAllFlights' + element.PassengerNo).checked ? element.ItineraryCount.toString() : "1" : "1",
        //             "birthDate": "",
        //             "remarks": mealDetailsOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
        //             "wayType": document.querySelector('#chkSelectAllFlights' + element.PassengerNo) !== null ? document.querySelector('#chkSelectAllFlights' + element.PassengerNo).checked ? "0" : "1" : "1",
        //             "isBundleService": null
        //         });
        //     });

        //     if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
        //         bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
        //             element.addOns = { addOn };
        //         });
        //     }

        // }
        // else {
        //     if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
        //         bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
        //             element.addOns = null;
        //         });
        //     }

        // }

        // let SeatRequest = [];
        // if (additionalServicesSeatJSON !== null && additionalServicesSeatJSON !== undefined && additionalServicesSeatJSON.length > 0) {
        //     additionalServicesSeatJSON.filter(element => element.ServiceType.split('_')[0] === "SeatNo").forEach(element => {
        //         SeatRequest.push({
        //             "SeatPreference": "",
        //             "RowNumber": element.ServiceCode,
        //             "SeatNumber": element.ServiceCode,
        //             "travelerRefNumber": element.PassengerNo.toString(),
        //             "flightRefNumber": element.ServiceType.split('_')[1],
        //             "Amount": "0", //element.getAttribute("data").split('_')[2].length > 1 ? element.getAttribute("data").split('_')[2].substring(1, element.getAttribute("data").split('_')[2].length) : element.getAttribute("data").split('_')[2],
        //             "PartialSeatingInd": "",
        //             "DeckLevel": "",
        //             "SmokingAllowed": "",
        //             "Status": "",
        //             "SeatKey": null,
        //             "Sector": element.Source + element.Destination
        //         });
        //     });

        //     if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
        //         bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
        //             element.SeatRequests = { SeatRequest };
        //         });
        //     }

        // }
        // else {
        //     if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
        //         bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
        //             element.SeatRequests = null;
        //         });
        //     }

        // }

        // //localStorage.removeItem("AdditionalServicesData");
        // //localStorage.removeItem("AdditionalServicesSeatData");
        // sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        // //console.log(bookingJSON);        

        if (setValidationStaus) {
            props.onContinueButton("4");
            window.scrollTo({
                top: 390,
                behavior: "smooth",
            });
        }
    }

    function skipAddOns() {
        props.onContinueButton("4");
        window.scrollTo({
            top: 390,
            behavior: "smooth",
        });
    }

    const addOnTypeList = [
        { value: 'BAGGAGE', label: 'Baggage' },
        { value: 'MEAL', label: 'Meal' },
        { value: 'SEAT', label: 'Seat' },
        { value: 'SPECIAL', label: 'Special Service' },
        { value: 'FFN', label: 'Frequent Flyer' }
    ];

    const handleChangeAddOnType = (e) => {
        SetAddOnTypeValue(e.value);

        if (e.value === 'FFN') {
            document.querySelector('#dvFrequentFlyerNumber').classList.remove("col-md-3");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("col-md-6");
            //document.querySelector('#dvStatus').classList.remove("d-none");
        }
        else {
            document.querySelector('#dvFrequentFlyerNumber').classList.remove("col-md-6");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("col-md-3");
            //document.querySelector('#dvStatus').classList.remove("d-none");

            document.querySelector('#dvStatus').classList.remove("col-md-3");
            document.querySelector('#dvStatus').classList.add("col-md-6");
            document.querySelector('#dvStatus').classList.remove("d-none");
        }

        if (e.value === 'BAGGAGE' || e.value === 'SEAT') {
            document.querySelector('#dvType').classList.remove("col-md-3");
            document.querySelector('#dvType').classList.add("col-md-6");
            if (document.querySelector('#descriptionErrorIcon') !== null) {
                document.querySelector('#descriptionErrorIcon').classList.remove("d-none");
                document.querySelector('#descriptionErrorIcon').classList.add("error-icon");
            }
        }
        else {
            document.querySelector('#dvType').classList.remove("col-md-6");
            document.querySelector('#dvType').classList.add("col-md-3");
            if (document.querySelector('#descriptionErrorIcon') !== null) {
                document.querySelector('#descriptionErrorIcon').classList.remove("error-icon");
                document.querySelector('#descriptionErrorIcon').classList.add("d-none");
            }

            setAddOnDescriptionInputHasError(false);
            setAddOnDescriptionInvalidInputHasError(false);
        }

        if (e.value === 'BAGGAGE') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            setAddOnSubTypeBaggageInputHasError(false);
            setAddOnSubTypeBaggageInvalidInputHasError(false);
        }
        else if (e.value === 'MEAL') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.remove("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            if (mealDetailsOptions.length === 0) {
                sendRequestMealDetailsData();
            }
        }
        else if (e.value === 'SEAT') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            setAddOnSubTypeSeatInputHasError(false);
            setAddOnSubTypeSeatInvalidInputHasError(false);
        }
        else if (e.value === 'SPECIAL') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.remove("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            if (specialServiceOptions.length === 0) {
                sendRequestSpecialServiceData();
            }
        }
        else if (e.value === 'FFN') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.remove("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.remove("d-none");

            document.querySelector('#dvDescription').classList.add("d-none");
            document.querySelector('#dvAmount').classList.add("d-none");
            document.querySelector('#dvCurrency').classList.add("d-none");

            if (arilineProgramOptions.length === 0) {
                sendRequestAirlineProgramData();
            }
        }
    };

    const handleChangeMeal = (e) => {
        SetMealValue(e.value);
        SetMealName(e.label);
    };

    const handleChangePassenger = (e) => {
        SetPassengerValue(e.value);
    };

    const handleChangeSector = (e) => {
        SetSectorValue(e.value);
        SetSectorName(e.label);
    };

    const handleChangeSpecialService = (e) => {
        SetSpecialServiceValue(e.value);
        SetSpecialServiceName(e.label);
    };

    const handleChangeFrequentFlyer = (e) => {
        SetFrequentFlyerValue(e.value);
        SetFrequentFlyerName(e.label);
    };

    const handleChangeAddOnStatus = (e) => {
        SetAddOnStatusValue(e.value);
        SetAddOnStatusName(e.label);
    };

    function clearAddOnsFields() {
        SetPassengerValue('');
        SetSectorValue('');
        SetSectorName('');
        SetAddOnTypeValue('');
        //document.querySelector('#baggage').value = "";
        SetMealValue('');
        SetMealName('');
        //document.querySelector('#seat').value = "";
        document.querySelector('#amount').value = "";
        document.querySelector('#description').value = "";
        //setCurrencyCode('');
        SetSpecialServiceValue('');
        SetSpecialServiceName('');
        SetFrequentFlyerValue('');
        SetFrequentFlyerName('');
        document.querySelector('#frequentFlyerNo').value = "";
        SetAddOnStatusValue('');
        SetAddOnStatusName('');
    }

    const currencyChangeHandler = (event) => {
        if (event.value !== '') {
            setCurrencyCode(event.value);
        }
        else {
            setCurrencyCode('');
        }
    };

    function onAddSsr(event) {
        event.preventDefault();

        if (passengerValue === undefined || passengerValue === '') {
            setPassengerInputHasError(true);
            return;
        }
        else {
            setPassengerInputHasError(false);
        }

        if (sectorValue === undefined || sectorValue === '') {
            setSectorInputHasError(true);
            return;
        }
        else {
            setSectorInputHasError(false);
        }

        if (addOnTypeValue === undefined || addOnTypeValue === '') {
            setAddOnTypeInputHasError(true);
            return;
        }
        else {
            setAddOnTypeInputHasError(false);
        }

        // if (addOnTypeValue === "BAGGAGE" && document.querySelector('#baggage').value === '') {
        //     setAddOnSubTypeBaggageInputHasError(true);
        //     setAddOnSubTypeBaggageInvalidInputHasError(false);
        //     return;
        // }
        // else {
        //     setAddOnSubTypeBaggageInputHasError(false);
        // }

        // if (addOnTypeValue === "BAGGAGE" && document.querySelector('#baggage').value !== '' && !document.querySelector('#baggage').value.match('^[a-zA-Z0-9 ]+$')) {
        //     setAddOnSubTypeBaggageInputHasError(false);
        //     setAddOnSubTypeBaggageInvalidInputHasError(true);
        //     return;
        // }
        // else {
        //     setAddOnSubTypeBaggageInvalidInputHasError(false);
        // }

        if (addOnTypeValue === "MEAL" && (mealValue === undefined || mealValue === '')) {
            setAddOnSubTypeMealInputHasError(true);
            return;
        }
        else {
            setAddOnSubTypeMealInputHasError(false);
        }

        // if (addOnTypeValue === "SEAT" && document.querySelector('#seat').value === '') {
        //     setAddOnSubTypeSeatInputHasError(true);
        //     setAddOnSubTypeSeatInvalidInputHasError(false);
        //     return;
        // }
        // else {
        //     setAddOnSubTypeSeatInputHasError(false);
        // }

        // if (addOnTypeValue === "SEAT" && document.querySelector('#seat').value !== '' && !document.querySelector('#seat').value.match('^[a-zA-Z0-9 ]+$')) {
        //     setAddOnSubTypeSeatInputHasError(false);
        //     setAddOnSubTypeSeatInvalidInputHasError(true);
        //     return;
        // }
        // else {
        //     setAddOnSubTypeSeatInvalidInputHasError(false);
        // }

        if (addOnTypeValue === "SPECIAL" && (specialServiceValue === undefined || specialServiceValue === '')) {
            setAddOnSubTypSpecialServiceInputHasError(true);
            return;
        }
        else {
            setAddOnSubTypSpecialServiceInputHasError(false);
        }

        if (addOnTypeValue === "FFN" && (frequentFlyerValue === undefined || frequentFlyerValue === '')) {
            setAddOnSubTypFrequentFlyerInputHasError(true);
            return;
        }
        else {
            setAddOnSubTypFrequentFlyerInputHasError(false);
        }

        if (addOnTypeValue === "FFN" && document.querySelector('#frequentFlyerNo').value === '') {
            setAddOnSubTypeFrequentFlyerNoInputHasError(true);
            setAddOnSubTypeFrequentFlyerNoInvalidInputHasError(false);
            return;
        }
        else {
            setAddOnSubTypeFrequentFlyerNoInputHasError(false);
        }

        if (addOnTypeValue === "FFN" && document.querySelector('#frequentFlyerNo').value !== '' && !document.querySelector('#frequentFlyerNo').value.match('^[a-zA-Z0-9]+$')) {
            setAddOnSubTypeFrequentFlyerNoInputHasError(false);
            setAddOnSubTypeFrequentFlyerNoInvalidInputHasError(true);
            return;
        }
        else {
            setAddOnSubTypeFrequentFlyerNoInvalidInputHasError(false);
        }

        if (addOnTypeValue !== "FFN" && addOnStatusValue === undefined || addOnStatusValue === '') {
            setAddOnSubTypStatusInputHasError(true);
            return;
        }
        else {
            setAddOnSubTypStatusInputHasError(false);
        }

        // if (addOnTypeValue !== undefined && addOnTypeValue !== "" && document.querySelector('#amount').value === '') {
        //     setAmountInputHasError(true);
        //     setAmountInvalidInputHasError(false);
        //     return;
        // }
        // else {
        //     setAmountInputHasError(false);
        // }

        if (addOnTypeValue !== undefined && addOnTypeValue !== "" && document.querySelector('#amount').value !== '' && !document.querySelector('#amount').value.match('^[0-9.]+$')) {
            //setAmountInputHasError(false);
            setAmountInvalidInputHasError(true);
            return;
        }
        else {
            setAmountInvalidInputHasError(false);
        }

        if (document.querySelector('#amount').value !== '' && document.querySelector('#amount').value !== '0' && (currencyCode === undefined || currencyCode === '')) {
            setCurrencyCodeInputHasError(true);
            return;
        }
        else {
            setCurrencyCodeInputHasError(false);
        }

        if ((addOnTypeValue === "BAGGAGE" || addOnTypeValue === "SEAT") && document.querySelector('#description').value === '') {
            setAddOnDescriptionInputHasError(true);
            setAddOnDescriptionInvalidInputHasError(false);
            return;
        }
        else {
            setAddOnDescriptionInputHasError(false);
        }

        if ((addOnTypeValue === "BAGGAGE" || addOnTypeValue === "SEAT") && document.querySelector('#description').value !== '' && (document.querySelector('#description').value.length > 50 || !document.querySelector('#description').value.match('^[a-zA-Z0-9 ]+$'))) {
            setAddOnDescriptionInputHasError(false);
            setAddOnDescriptionInvalidInputHasError(true);
            return;
        }
        else {
            setAddOnDescriptionInvalidInputHasError(false);
        }


        let addOn = props.traveler.travelerInfo.specialReqDetails[0].addOns !== undefined && props.traveler.travelerInfo.specialReqDetails[0].addOns !== null ? props.traveler.travelerInfo.specialReqDetails[0].addOns.addOn.filter(a => a.ssrType === addOnTypeValue && a.travelerRefNumber === passengerValue && a.flightRefNumber === sectorValue) : [];

        if (addOn.length > 0) {

            if (addOnTypeValue === 'BAGGAGE') {
                addOn[0].ssrType = addOnTypeValue;
                addOn[0].travelerRefNumber = passengerValue;
                addOn[0].flightRefNumber = sectorValue;
                addOn[0].ssrCode = document.querySelector('#description').value;
                addOn[0].ssrDescription = '';
                addOn[0].ssrCharge = document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0';
                addOn[0].remarks = document.querySelector('#description').value;
                addOn[0].ssrCurrency = currencyCode;
                addOn[0].status = addOnStatusValue;
            }
            else if (addOnTypeValue === 'MEAL') {
                addOn[0].ssrType = addOnTypeValue;
                addOn[0].travelerRefNumber = passengerValue;
                addOn[0].flightRefNumber = sectorValue;
                addOn[0].ssrCode = mealValue;
                addOn[0].ssrDescription = mealName;
                addOn[0].ssrCharge = document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0';
                addOn[0].remarks = document.querySelector('#description').value;
                addOn[0].ssrCurrency = currencyCode;
                addOn[0].status = addOnStatusValue;
            }
            else if (addOnTypeValue === 'SEAT') {
                addOn[0].ssrType = addOnTypeValue;
                addOn[0].travelerRefNumber = passengerValue;
                addOn[0].flightRefNumber = sectorValue;
                addOn[0].ssrCode = document.querySelector('#description').value;
                addOn[0].ssrDescription = '';
                addOn[0].ssrCharge = document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0';
                addOn[0].remarks = document.querySelector('#description').value;
                addOn[0].ssrCurrency = currencyCode;
                addOn[0].status = addOnStatusValue;
            }
            else if (addOnTypeValue === 'SPECIAL') {
                addOn[0].ssrType = addOnTypeValue;
                addOn[0].travelerRefNumber = passengerValue;
                addOn[0].flightRefNumber = sectorValue;
                addOn[0].ssrCode = specialServiceValue;
                addOn[0].ssrDescription = specialServiceName;
                addOn[0].ssrCharge = document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0';
                addOn[0].remarks = document.querySelector('#description').value;
                addOn[0].ssrCurrency = currencyCode;
                addOn[0].status = addOnStatusValue;
            }
            else if (addOnTypeValue === 'FFN') {
                addOn[0].ssrType = addOnTypeValue;
                addOn[0].travelerRefNumber = passengerValue;
                addOn[0].flightRefNumber = sectorValue;
                addOn[0].ssrCode = frequentFlyerValue;
                addOn[0].ssrDescription = frequentFlyerName;
                addOn[0].ssrCharge = '0';
                addOn[0].remarks = document.querySelector('#frequentFlyerNo').value;
                addOn[0].ssrCurrency = '';
                addOn[0].status = addOnStatusValue;
            }

            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn = props.traveler.travelerInfo.specialReqDetails[0].addOns.addOn;
            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            history.push(`/ImportPNR`);
            clearAddOnsFields();
        }
        else {
            if (addOnTypeValue === 'BAGGAGE') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
                        if (element.addOns !== undefined && element.addOns !== null) {
                            element.addOns.addOn.push({
                                "ssrCode": document.querySelector('#description').value,
                                "ssrDescription": '',
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "BAGGAGE",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                        }
                        else {
                            let addOn = [];
                            addOn.push({
                                "ssrCode": document.querySelector('#description').value,
                                "ssrDescription": '',
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "BAGGAGE",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                            element.addOns = { addOn };
                        }
                    });
                }
            }
            else if (addOnTypeValue === 'MEAL') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
                        if (element.addOns !== undefined && element.addOns !== null) {
                            element.addOns.addOn.push({
                                "ssrCode": mealValue,
                                "ssrDescription": mealName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "MEAL",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                        }
                        else {
                            let addOn = [];
                            addOn.push({
                                "ssrCode": mealValue,
                                "ssrDescription": mealName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "MEAL",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                            element.addOns = { addOn };
                        }
                    });
                }
            }
            else if (addOnTypeValue === 'SEAT') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
                        if (element.addOns !== undefined && element.addOns !== null) {
                            element.addOns.addOn.push({
                                "ssrCode": document.querySelector('#description').value,
                                "ssrDescription": '',
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "SEAT",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                        }
                        else {
                            let addOn = [];
                            addOn.push({
                                "ssrCode": document.querySelector('#description').value,
                                "ssrDescription": '',
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "SEAT",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                            element.addOns = { addOn };
                        }
                    });
                }
            }
            else if (addOnTypeValue === 'SPECIAL') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
                        if (element.addOns !== undefined && element.addOns !== null) {
                            element.addOns.addOn.push({
                                "ssrCode": specialServiceValue,
                                "ssrDescription": specialServiceName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "SPECIAL",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                        }
                        else {
                            let addOn = [];
                            addOn.push({
                                "ssrCode": specialServiceValue,
                                "ssrDescription": specialServiceName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": document.querySelector('#amount').value !== '' ? document.querySelector('#amount').value : '0',
                                "ssrType": "SPECIAL",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#description').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": currencyCode,
                                "status": addOnStatusValue
                            });
                            element.addOns = { addOn };
                        }
                    });
                }
            }
            else if (addOnTypeValue === 'FFN') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    bookingJSON.data.travelerInfo.specialReqDetails.forEach(element => {
                        if (element.addOns !== undefined && element.addOns !== null) {
                            element.addOns.addOn.push({
                                "ssrCode": frequentFlyerValue,
                                "ssrDescription": frequentFlyerName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": '0',
                                "ssrType": "FFN",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#frequentFlyerNo').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": '',
                                "status": addOnStatusValue
                            });
                        }
                        else {
                            let addOn = [];
                            addOn.push({
                                "ssrCode": frequentFlyerValue,
                                "ssrDescription": frequentFlyerName,
                                "travelerRefNumber": passengerValue,
                                "flightRefNumber": sectorValue,
                                "SSRCategory": "",
                                "ssrCharge": '0',
                                "ssrType": "FFN",
                                "serviceQuantity": "1",
                                "birthDate": "",
                                "remarks": document.querySelector('#frequentFlyerNo').value,
                                "wayType": "1",
                                "isBundleService": null,
                                "ssrCurrency": '',
                                "status": addOnStatusValue
                            });
                            element.addOns = { addOn };
                        }
                    });
                }
            }

            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            history.push(`/ImportPNR`);
            clearAddOnsFields();
        }

        if (document.querySelector('#btnAddAddOns') !== undefined && document.querySelector('#btnAddAddOns') !== null) {
            document.querySelector('#btnAddAddOns').innerHTML = "&nbsp; &nbsp;&nbsp; Add &nbsp; &nbsp;&nbsp;";
            setDisabledControl(false);
        }
    }

    function onEditAddOns(ssrType, travelerRefNumber, flightRefNumber) {
        if (document.querySelector('#btnAddAddOns') !== undefined && document.querySelector('#btnAddAddOns') !== null) {
            document.querySelector('#btnAddAddOns').innerHTML = "Update";
            setDisabledControl(true);
        }

        const yOffset = -250;
        const element2 = document.getElementById("frequentFlyerNo");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

        let addOn = props.traveler.travelerInfo.specialReqDetails[0].addOns.addOn.filter(a => a.ssrType === ssrType && a.travelerRefNumber === travelerRefNumber && a.flightRefNumber === flightRefNumber)[0];
        SetPassengerValue(travelerRefNumber);
        SetSectorValue(flightRefNumber);
        SetAddOnTypeValue(ssrType);

        if (addOn.status !== undefined && addOn.status !== null && addOn.status !== '') {
            SetAddOnStatusValue(addOn.status);
            SetAddOnStatusName(addOnStatusOptions.filter(option => option.value === addOn.status)[0] !== undefined ? addOnStatusOptions.filter(option => option.value === addOn.status)[0].label : '');
        }
        else {
            SetAddOnStatusValue('');
            SetAddOnStatusName('');
        }

        if (ssrType === 'FFN') {
            document.querySelector('#dvStatus').classList.remove("col-md-6");
            document.querySelector('#dvStatus').classList.add("col-md-3");
            document.querySelector('#dvStatus').classList.remove("d-none");
        }
        else {
            document.querySelector('#dvStatus').classList.remove("col-md-3");
            document.querySelector('#dvStatus').classList.add("col-md-6");
            document.querySelector('#dvStatus').classList.remove("d-none");
        }

        if (ssrType === 'BAGGAGE' || ssrType === 'SEAT') {
            document.querySelector('#dvType').classList.remove("col-md-3");
            document.querySelector('#dvType').classList.add("col-md-6");
            if (document.querySelector('#descriptionErrorIcon') !== null) {
                document.querySelector('#descriptionErrorIcon').classList.remove("d-none");
                document.querySelector('#descriptionErrorIcon').classList.add("error-icon");
            }
        }
        else {
            document.querySelector('#dvType').classList.remove("col-md-6");
            document.querySelector('#dvType').classList.add("col-md-3");
            if (document.querySelector('#descriptionErrorIcon') !== null) {
                document.querySelector('#descriptionErrorIcon').classList.remove("error-icon");
                document.querySelector('#descriptionErrorIcon').classList.add("d-none");
            }

            setAddOnDescriptionInputHasError(false);
            setAddOnDescriptionInvalidInputHasError(false);
        }

        if (ssrType === 'BAGGAGE') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            setAddOnSubTypeBaggageInputHasError(false);
            setAddOnSubTypeBaggageInvalidInputHasError(false);

            //document.querySelector('#description').value = addOn.ssrCode;
            document.querySelector('#amount').value = addOn.ssrCharge;
            document.querySelector('#description').value = addOn.remarks;

            if (addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '') {
                setCurrencyCode(addOn.ssrCurrency);
            }
            else {
                if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
                    setCurrencyCode(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode);
                }
            }
        }
        else if (ssrType === 'MEAL') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.remove("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            SetMealValue(addOn.ssrCode);
            SetMealName(addOn.ssrDescription)
            document.querySelector('#amount').value = addOn.ssrCharge;
            document.querySelector('#description').value = addOn.remarks;

            if (addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '') {
                setCurrencyCode(addOn.ssrCurrency);
            }
            else {
                if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
                    setCurrencyCode(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode);
                }
            }
        }
        if (ssrType === 'SEAT') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            setAddOnSubTypeSeatInputHasError(false);
            setAddOnSubTypeSeatInvalidInputHasError(false);

            //document.querySelector('#description').value = addOn.ssrCode;
            document.querySelector('#amount').value = addOn.ssrCharge;
            document.querySelector('#description').value = addOn.remarks;

            if (addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '') {
                setCurrencyCode(addOn.ssrCurrency);
            }
            else {
                if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
                    setCurrencyCode(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode);
                }
            }
        }
        else if (ssrType === 'SPECIAL') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.remove("d-none");
            document.querySelector('#dvFrequentFlyer').classList.add("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.add("d-none");

            document.querySelector('#dvDescription').classList.remove("d-none");
            document.querySelector('#dvAmount').classList.remove("d-none");
            document.querySelector('#dvCurrency').classList.remove("d-none");

            SetSpecialServiceValue(addOn.ssrCode);
            SetSpecialServiceName(addOn.ssrDescription)
            document.querySelector('#amount').value = addOn.ssrCharge;
            document.querySelector('#description').value = addOn.remarks;

            if (addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '') {
                setCurrencyCode(addOn.ssrCurrency);
            }
            else {
                if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
                    setCurrencyCode(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode);
                }
            }
        }
        else if (ssrType === 'FFN') {
            document.querySelector('#dvBaggage').classList.add("d-none");
            document.querySelector('#dvMeal').classList.add("d-none");
            document.querySelector('#dvSeat').classList.add("d-none");
            document.querySelector('#dvSpecialService').classList.add("d-none");
            document.querySelector('#dvFrequentFlyer').classList.remove("d-none");
            document.querySelector('#dvFrequentFlyerNumber').classList.remove("d-none");

            document.querySelector('#dvDescription').classList.add("d-none");
            document.querySelector('#dvAmount').classList.add("d-none");
            document.querySelector('#dvCurrency').classList.add("d-none");

            SetFrequentFlyerValue(addOn.ssrCode);
            SetFrequentFlyerName(addOn.ssrDescription)
            document.querySelector('#frequentFlyerNo').value = addOn.remarks;
            //document.querySelector('#amount').value = addOn.ssrCharge;
            //document.querySelector('#description').value = addOn.remarks;

            // if (addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '') {
            //     setCurrencyCode(addOn.ssrCurrency);
            // }
            // else {
            //     if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
            //         setCurrencyCode(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode);
            //     }
            // }
        }
    }

    function onDeleteAddOns(ssrType, travelerRefNumber, flightRefNumber) {
        let addOn = props.traveler.travelerInfo.specialReqDetails[0].addOns.addOn.filter(a => !(a.ssrType == ssrType && a.travelerRefNumber == travelerRefNumber && a.flightRefNumber == flightRefNumber));

        bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn = addOn;
        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        history.push(`/ImportPNR`);
    }

    function onConfirm(ssrType, travelerRefNumber, flightRefNumber) {
        SetAddOnTypeValue(ssrType);
        SetPassengerValue(travelerRefNumber);
        SetSectorValue(flightRefNumber);
        setConfirm(true);
    }

    function okHandler() {
        onDeleteAddOns(addOnTypeValue, passengerValue, sectorValue);
        setConfirm(false);
    }

    function cancelHandler() {
        setConfirm(false);
    }

    return (
        <Fragment>
            {confirm && (
                <ConfirmationModal
                    title={t('confirmationMessageTitle')}
                    message={t('confirmationMessage')}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            <div className="accordion_flex_area">
                <div className="accordion_left_side position-relative text-muted">
                    <h5>4</h5>
                    <span id="completeFour" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" >
                        <button id="headingFour" className="text-muted accordion-button collapsed" type="button"
                            data-bs-target="#collapseFour"
                            aria-expanded="false" aria-controls="collapseFour">
                            Add-ons (Optional)
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse"
                        aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                        <div className='row'>
                            <div id='dvPassenger' className="control col-md-6">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Passenger<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={passengerDetailsOptions} onChange={handleChangePassenger}
                                    value={passengerDetailsOptions.filter(option => option.value === passengerValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                />
                                {passengerInputHasError &&
                                    <p className="error-text">{t('validationPassenger')}</p>
                                }
                            </div>
                            <div id='dvSector' className="control col-md-6">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sector<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={sectorDetailsOptions} onChange={handleChangeSector}
                                    value={sectorDetailsOptions.filter(option => option.value === sectorValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                />
                                {sectorInputHasError &&
                                    <p className="error-text">{t('validationSector')}</p>
                                }
                            </div>
                            <div id='dvType' className="control col-md-6">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Type<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={addOnTypeList} onChange={handleChangeAddOnType}
                                    value={addOnTypeList.filter(option => option.value === addOnTypeValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select" isDisabled={disabledControl}
                                />
                                {addOnTypeInputHasError &&
                                    <p className="error-text">{t('validationAddOnType')}</p>
                                }
                            </div>
                            <div id='dvBaggage' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sub Type <span className="error-icon"> * </span></label>
                                <input type="text" id='baggage' className="form-control form-control-sm" maxLength={10} />
                                {addOnSubTypeBaggageInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                                {addOnSubTypeBaggageInvalidInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubTypeInvalidData')}</p>
                                }
                            </div>
                            <div id='dvMeal' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sub Type<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={mealDetailsOptions} onChange={handleChangeMeal}
                                    value={mealDetailsOptions.filter(option => option.value === mealValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                />
                                {addOnSubTypeMealInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                            </div>
                            <div id='dvSeat' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sub Type <span className="error-icon"> * </span></label>
                                <input type="text" id='seat' className="form-control form-control-sm" />
                                {addOnSubTypeSeatInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                                {addOnSubTypeSeatInvalidInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubTypeInvalidData')}</p>
                                }
                            </div>
                            <div id='dvSpecialService' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sub Type<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={specialServiceOptions} onChange={handleChangeSpecialService}
                                    value={specialServiceOptions.filter(option => option.value === specialServiceValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                />
                                {addOnSubTypSpecialServiceInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                            </div>
                            <div id='dvFrequentFlyer' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Sub Type<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={arilineProgramOptions} onChange={handleChangeFrequentFlyer}
                                    value={arilineProgramOptions.filter(option => option.value === frequentFlyerValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                />
                                {addOnSubTypFrequentFlyerInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                            </div>
                            <div id='dvFrequentFlyerNumber' className="control col-md-3 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Frequent Flyer No.<span className="error-icon"> * </span></label>
                                <input type="text" id='frequentFlyerNo' className="form-control form-control-sm" maxLength="10" />
                                {addOnSubTypeFrequentFlyerNoInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubType')}</p>
                                }
                                {addOnSubTypeFrequentFlyerNoInvalidInputHasError &&
                                    <p className="error-text">{t('validationAddOnSubTypeFrequentFlyerNoInvalidData')}</p>
                                }
                            </div>
                            <div id='dvStatus' className="control d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Status<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={addOnStatusOptions} onChange={handleChangeAddOnStatus}
                                    value={addOnStatusOptions.filter(option => option.value === addOnStatusValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                />
                                {addOnSubTypStatusInputHasError &&
                                    <p className="error-text">{t('validationAddOnStatus')}</p>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div id='dvCurrency' className="control col-md-4 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Currency Code <span className="error-icon">  </span></label>
                                <Dropdown onChange={currencyChangeHandler}
                                    value={currencyOptions.filter(option => option.value === currencyCode)}
                                    options={currencyOptions}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                    placeholder="Select"
                                    isDisabled={currencyCode !== '' ? true : false}
                                />
                                {currencyCodeInputHasError &&
                                    <p className="error-text">{t('validationCurrencyCode')}</p>
                                }
                            </div>
                            <div id='dvAmount' className="control col-md-2 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Price <span className="error-icon">  </span></label>
                                <input type="text" id='amount' maxLength={10} className="form-control form-control-sm" />
                                {/* {amountInputHasError &&
                                    <p className="error-text">{t('validationAmount')}</p>
                                } */}
                                {amountInvalidInputHasError &&
                                    <p className="error-text">{t('validationAmountInvalidData')}</p>
                                }
                            </div>
                            <div id='dvDescription' className="control col-md-6 d-none">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Description<span id='descriptionErrorIcon' className="d-none"> * </span></label>
                                <input type="text" id='description' className="form-control form-control-sm" />
                                {addOnDescriptionInputHasError &&
                                    <p className="error-text">{t('validationAddOnDescription')}</p>
                                }
                                {addOnDescriptionInvalidInputHasError &&
                                    <p className="error-text">{t('validationAddOnDescriptionInvalidData')}</p>
                                }
                            </div>
                            <div className="control col-md-12 my-2">
                                {/* <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label> */}
                                <button id='btnAddAddOns' className="btn text-white gt-main-btn ms-1 btn-sm float-lg-end float-md-end" onClick={onAddSsr}>&nbsp; &nbsp; Add &nbsp; &nbsp;</button>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='table-responsive mt-2 table-wrapper width-900px'>
                                    {
                                        bookingJSON !== null && bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.length > 0 &&
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th scope='col'>Passenger</th>
                                                    <th scope='col'>Sector</th>
                                                    <th scope='col'>Type</th>
                                                    <th scope="col">Sub Type</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Currency</th>
                                                    <th scope="col" align="right" class="text-end">Price</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bookingJSON !== null && bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.map((addOn) => (
                                                        passengerDetailsOptions.length > 0 && passengerDetailsOptions.filter(a => a.value === addOn.travelerRefNumber) !== undefined && passengerDetailsOptions.filter(a => a.value === addOn.travelerRefNumber)[0] !== undefined &&
                                                        <tr>
                                                            <td>{passengerDetailsOptions.length > 0 ? passengerDetailsOptions.filter(a => a.value === addOn.travelerRefNumber)[0].label : ''}</td>
                                                            <td>{bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.flightSegment[0].flightRefNumber === addOn.flightRefNumber)[0] !== undefined ? bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.flightSegment[0].flightRefNumber === addOn.flightRefNumber)[0].flightSegment[0].departureAirport.locationName + "(" + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.flightSegment[0].flightRefNumber === addOn.flightRefNumber)[0].flightSegment[0].departureAirport.locationCode + ")" + " - " + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.flightSegment[0].flightRefNumber === addOn.flightRefNumber)[0].flightSegment[0].arrivalAirport.locationName + "(" + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.flightSegment[0].flightRefNumber === addOn.flightRefNumber)[0].flightSegment[0].arrivalAirport.locationCode + ")" : ''}</td>
                                                            <td>{addOn.ssrType === 'SPECIAL' ? 'Special Service' : addOn.ssrType === 'FFN' ? 'Frequent Flyer' : toPascalCase(addOn.ssrType)}</td>
                                                            <td>{addOn.ssrDescription}</td>
                                                            <td>{addOn.status !== undefined && addOn.status !== null && addOn.status !== '' ? addOnStatusOptions.filter(option => option.value === addOn.status)[0] !== undefined ? addOnStatusOptions.filter(option => option.value === addOn.status)[0].label : '' : ''}</td>
                                                            <td>{addOn.ssrType === 'FFN' ? '' : addOn.ssrCharge !== '0' && addOn.ssrCharge !== '' && addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '' ? addOn.ssrCurrency : addOn.ssrCharge !== '0' && addOn.ssrCharge !== '' && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare.currencyCode : ''}</td>
                                                            <td align={'right'}>{addOn.ssrCharge !== '0' && addOn.ssrCharge !== '' ?
                                                                new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: addOn.ssrCurrency !== undefined && addOn.ssrCurrency !== '' ? addOn.ssrCurrency : bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare.currencyCode : 'USD'
                                                                }).format(addOn.ssrCharge)
                                                                : ''}</td>
                                                            <td>{addOn.remarks}</td>

                                                            <td className='text-center sticky-col last-col bg-light'>
                                                                <div className="text-center">
                                                                    <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" onClick={() => onEditAddOns(addOn.ssrType, addOn.travelerRefNumber, addOn.flightRefNumber)}><i className="fa-solid fa-pen"></i></a>
                                                                    <button className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" onClick={() => onConfirm(addOn.ssrType, addOn.travelerRefNumber, addOn.flightRefNumber)}><i className="fas fa-trash-alt"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                {/* <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="float-md-end float-lg-end">
                                    <button type="button" onClick={skipAddOns} className="btn btn margin-top-15 text-white btn-secondary ms-1 btn-sm mt-2">Skip to Remarks</button>
                                    <button type="button" onClick={assignAddOns} className="btn  text-white gt-main-btn ms-1 btn-sm mt-2">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddOns
