import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
const RatePlanItem = (props) => {
    const { t } = useTranslation(['translation.RatePlan']);
    const [confirm, setConfirm] = useState();
    function deleteFormHandler(props) {
        // optional: Could validate here     
        props.onDelete({
            id: props.id,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            code: "",
            gtCode: "",
            gtName: "",
            default: true,
        });

    }

    function onConfirm() {
        setConfirm(true);
    }

    function okHandler() {
        deleteFormHandler(props)
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };

    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        <div className="row" key={props.id}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.ratePlanCode}
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="col-md-8">
                                <p className="card-text mb-0"><strong>Rate Plan Name :</strong>{" " + props.ratePlanName}
                                </p>
                                <p className="card-text mb-0"><strong>GT Code:</strong>{" " + props.gtCode} </p>
                                <p className="card-text mb-0"><strong>Default Rate Plan:</strong>{props.default === true ? " Yes" : " No"} </p>
                            </div>
                           
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_View") && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateRatePlan/0/${props.id}`, edit: 0, }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateRatePlan/1/${props.id}`, edit: 1, }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}
                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Delete") &&
                                        <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title='Delete'>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default RatePlanItem;