import { useState, useCallback, Fragment, useEffect } from "react";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { searchOfficeList } from '../../../services/Office-API'
import { searchhWallet } from "../../../services/Wallet-Api";
import useHttp from "../../../services/use-http";
import Dropdown from 'react-select';
import WalletList from './Components/WalletList'
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";

const SearchWallet = () => {
    useDocumentTitle('Search Wallet');

    //Constants
    const { t } = useTranslation(['translation.Wallet']);
    const history = useHistory();
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    const { sendRequest: sendWallet, status, data: loadedWalletData, error: walletError } = useHttp(searchhWallet);
    const [companyNameInput, setCompanyNameInput] = useState();
    const [error, setError] = useState(false);
    const [isFirstTime, setFirstTime] = useState("");
    

    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);
        const onSerachWallet = ({
            officeID: companyNameInput,
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
        });
        sendWallet(onSerachWallet);


    }, [sendWallet, companyNameInput])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedWalletData['errors'].error['code'] === '1008' || loadedWalletData['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedWalletData])

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    if (walletError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }
    let loadedData = [];
    for (let i in loadedWalletData) {
        if (loadedWalletData['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedWalletData[i];
            }
        }
    }
   

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }

    function onResetHandler(event) {
        history.go('/SearchWallet');
    }
    function okayHandler() {
        setError(false);
        history.go('/SearchWallet');

    }
    const walletList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <WalletList wallet={loadedData} /> : ""
    return (
        <Fragment>
            {error && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i>{t('searchWallet')}</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}</label>
                                                        <Dropdown onChange={comapnyNameChaneHandler}
                                                            value={companyOptions.filter(option => option.value === companyNameInput)}
                                                            options={companyOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            autoFocus
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_Search") && <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {walletList}
        </Fragment>
    );


};
export default SearchWallet;