import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { getLogs } from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";
import LogsData from "./SubComponents/LogsData";

const Logs = (props) => {

    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedData } = useHttp(getLogs);


    function functionExit() {
        props.errorHandler();
    }

    const errorHandler1 = () => {
        setError1(null);
    };

    useEffect(() => {
        const requestJONS = ({
            "shoppingID": props.bookingJSON.data.shoppingID
        });

        sendRequest(requestJONS);
    }, [])

    let historyInfo = [];
    let logData;
    useEffect(() => {
        if (status === 'completed') {

            if (loadedData.errors.status === "FALSE") {

                for (let index = 0; index < loadedData.data.length; index++) {

                }
                logData = historyInfo;
            } else {
                document.querySelector('#dvHistoryMsg').innerHTML = "Logs not found.";
            }
        }

    }, [status])
    console.log(loadedData);



    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div id="dvHistoryMsg" ></div>
                {loadedData !== null && loadedData.errors.status === "FALSE" && <div className="container gt-h-340px">
                    <div className="row">

                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th className="col-md-3" scope="col">Error Type</th>
                                        <th className="col-md-9" scope="col">Description</th>

                                    </tr>
                                </thead>
                                {loadedData != null && loadedData.errors.status === "FALSE" && loadedData.data.map((loadedLogsData) => (
                                    <LogsData
                                        key={loadedLogsData.errorID}
                                        id={loadedLogsData.errorID}
                                        errorType={loadedLogsData.errorType}
                                        description={loadedLogsData.description}
                                    />
                                ))}
                            </table>
                        </div>

                    </div>

                </div>}

                <div className="col-md-12 my-auto">
                    <button className='btn gt-btn_md text-white my-2 gt-main-btn float-end' id='btnSend' onClick={functionExit}>
                        Close
                    </button>
                </div>
            </section>
        </div>
    </Fragment>
};
export default Logs;