import {
    GET_ALL_SIGHTSEEING,
    GET_SIGHTSEEING

} from './CONSTANTS'

export async function getAllSightseeing() {

    const response = await fetch(GET_ALL_SIGHTSEEING(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch sightseeing.');
    }
    return data;
}
export async function getSingleSightseeing(Name) {
    const response = await fetch(GET_SIGHTSEEING(Name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch sightseeing.');
    }

    return data;
}

