
import { Fragment,useContext } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import AuthContext from '../../redux/contexts/auth-context';
import TermsCondition from './Components/TermsCondition';

const TermsConditions = () => {
    useDocumentTitle('Terms and Conditions')
    const authCtx = useContext(AuthContext);
    return <Fragment>
        {/* <section  className="sub-banner">
            <div  className="bg-parallax bg-1"></div>
            <div  className="logo-banner text-center">
            </div>
        </section>  */}
      {!authCtx.isLoggedIn && <div className='position-relative bg-sky-blue'>
         
                <img src="assets/img/globetrotterdark.png" alt="" className='log-in-head-logo-bg position-absolute' />
         
        </div>}
        <section id="common_banner-WithoutAuth">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>

        </section>
       <TermsCondition></TermsCondition>
    </Fragment>
};

export default TermsConditions;