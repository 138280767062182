import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import AuthenticationForm from './Components/AuthenticationForm'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { authenticationOTP } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
import { useHistory } from 'react-router-dom';

import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import AuthContext from '../../../redux/contexts/auth-context'
import { FLIGHTSEARCH, VALIDATE } from '../../../navigation/CONSTANTS';
import secureLocalStorage from 'react-secure-storage';

const Authentication = () => {
    useDocumentTitle('Authentication')
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(authenticationOTP);
    const [errors, setErrors] = useState();
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    secureLocalStorage.setItem("CustomerLoginEmailID",loadedAuthenticationDetails['data'].emailLoginID)
                    localStorage.setItem('displayName', loadedAuthenticationDetails['data'].customerDisplayName);
                    
                    history.push(VALIDATE);
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct email ID.',
                    });
                    document.getElementById('loginEmailID').value = '';
                }
            }
        }



    }, [status, loadedAuthenticationDetails, authCtx, history])

    const onSendOTP = (requestData) => {
        sendRequest(requestData);
    };

    const errorHandler = () => {
        setErrors(null);
    };
    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}

            <section id="common_banner-WithoutAuth1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <AuthenticationForm onSendOTP={onSendOTP} isLoading={status === 'pending'} />

        </Fragment>)

};



export default Authentication;
