import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import OfficeItem from './OfficeItem'
import useHttp from '../../../../services/use-http'
import { deleteSingleOffice } from '../../../../services/Office-API'
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import { sortData } from '../../../../utils';

const OfficeList = (props) => {
  const { t } = useTranslation(['translation.Office']);
  const history = useHistory();
  const location = useLocation();

  const [error1, setError] = useState();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc'; 
  const sortedOffice = sortData(props.office,'companyName', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };
  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleOffice, true);

  useEffect(() => {
    //document.querySelector("#sortBy").selectedIndex = 0;
    //const areaFocus = document.querySelector('#sortByText');
    //areaFocus.scrollIntoView( { behavior: 'smooth', block: 'nearest' } );
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });

    history.push({
      pathname: '/SearchOffice',
      search: `?sort=${('asc')}`
    })

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }

    if (status === 'completed') {
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
      }
      else {
        history.go('/searchoffice');
      }
    }
  }, [status, history, loadedQuote, t])

  const deleteItemsHandler = (props) => {
    const onDeleteOffice = ({
      id: props.id,
      traceID: "",
      userID: "",
      companyName: "",
      address: "",
      countryCode: "",
      stateCode: "",
      stateName: "",
      cityCode: "",
      postalCode: "",
      faxNumber: "",
      iataNumber: "",
      arcNumber: "",
      taxID: "",
      categoryID: "",
      subCategoryID: "",
      isActive: true
    });

    sendRequest(onDeleteOffice);

    if (error) {
      return <p className='centered focused'>{t('errorRecordDelete')}</p>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  //End code for delete
  const errorHandler = () => {
    setError(null);
    history.push('/searchoffice');
  };
  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}

      <div id="sortByText" className="container-fluid mb-3 list-view">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>            
            <div className='row mb-3'>
              <div className="col-md-6"></div>
              <div className='col-md-2 my-auto text-muted text-left'>
                <div className="float-lg-end float-md-end">Sort By</div>
              </div>
              <div className="col-md-4 form-group">
                <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                  <option>{t('sortOfficeOptionAsc')}</option>
                  <option>{t('sortOfficeOptionDesc')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {sortedOffice.map((office) => (
          <OfficeItem onDelete={deleteItemsHandler}
            key={office.id}
            id={office.id}
            companyName={office.companyName}
            categoryName={office.categoryName}
            loginEmailId={office.loginEmailID}
            contactPersonEmailId={office.email}
            firstName={office.firstName}
            middleName={office.middleName}
            lastName={office.lastName}
          />
        ))}

      </div>
    </Fragment>
  );
};

export default OfficeList