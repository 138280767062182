import { Fragment } from 'react';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const Pricesummary = (props) => {

    let totalTax = 0;
    props.guestInfo.hotelResults[0].roomRateDetails.forEach(item => {
        if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
            totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
        }
        else {
            totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
        }
    });

    let tax = 0;
    if (props.guestInfo.hotelResults[0].roomRateDetails[0].price !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].price !== null && props.guestInfo.hotelResults[0].roomRateDetails[0].price.fees !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].price.fees !== null) {
        tax = props.guestInfo.hotelResults[0].roomRateDetails[0].price.taxes.totalAmount + props.guestInfo.hotelResults[0].roomRateDetails[0].price.fees[0].amount;
    }
    else {
        tax = props.guestInfo.hotelResults[0].roomRateDetails[0].price !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].price !== null && props.guestInfo.hotelResults[0].roomRateDetails[0].price.taxes !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].price.taxes !== null ? props.guestInfo.hotelResults[0].roomRateDetails[0].price.taxes.totalAmount : 0
    }

    return (<Fragment>
        <div className="left_side_search_area mt-2">
            <div className="left_side_search_boxed">
                {/* User Story 1073: Booking Summary for Single PNR */}
                <div className="left_side_search_heading mb-3">
                    {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" &&
                        <h5>Hotel Price Summary</h5>
                    }
                    {
                        localStorage.getItem("AddHotel") === null &&
                        <h5>Your Price Summary</h5>
                    }
                </div>
                <div className="tour_details_boxed_inner fare-summary">
                    <div className="accordion" id="accordionExample1">
                        <div className="accordion_flex_area ">
                            <div className="accordion-item fare-toggle-btn ">
                                <h2 className="accordion-header" id="headingOne1">
                                    <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">

                                        <div className='ms-4 col-md-4 xyz'>Base Rate</div>
                                        <div className='text-end col-md-7'>
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                            }).format(props.guestInfo.hotelResults[0].price[0].publishedPrice.pricePublished)}
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseOne1" className="accordion-collapse collapse show" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">

                                        <div className="accordion_itinerary_list">

                                            <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted">
                                                    <span>Room ({props.guestInfo.hotelResults[0].roomRateDetails.length} * {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                                    }).format(props.guestInfo.hotelResults[0].roomRateDetails[0].price !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].price !== null ? props.guestInfo.hotelResults[0].roomRateDetails[0].price.publishedPrice.pricePublished : 0)})</span>
                                                    <span>{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                                    }).format(props.guestInfo.hotelResults[0].price !== undefined && props.guestInfo.hotelResults[0].price !== null ? props.guestInfo.hotelResults[0].price[0].publishedPrice.pricePublished : 0)}</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion_flex_area">
                            <div className="accordion-item fare-toggle-btn">
                                <h2 className="accordion-header" id="headingtwo2">
                                    <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">

                                        <div className='ms-4 col-md-7'> Taxes & Fees</div>
                                        <div className='right-0 position-absolute'>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                        }).format(totalTax)}</div>
                                    </button>
                                </h2>
                                <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <div className="accordion_itinerary_list">
                                            <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted">
                                                    <span>Taxes <br></br> ({props.guestInfo.hotelResults[0].roomRateDetails.length} * {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                                    }).format(tax)})</span>
                                                    <span>{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                                    }).format(totalTax)}</span>
                                                </span>
                                            </label>
                                            {/* <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted">
                                                    <span>Service Fee</span>
                                                    <span>$10,000</span>
                                                </span>
                                            </label> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion_flex_area">
                            <div className="accordion-item fare-toggle-btn">
                                <h2 className="accordion-header" id="headingthree3">
                                    <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree3">
                                        {/* User Story 1073: Booking Summary for Single PNR */}
                                        <div className='ms-4 col-md-7'> Others  </div>
                                        <div className='right-0 position-absolute' id="dvOtherServicesAmountHotel">{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                        }).format(0.00)}</div>
                                    </button>
                                </h2>
                                <div id="collapsethree3" className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo2" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <div className="accordion_itinerary_list">
                                            <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <span className="area_flex_one text-muted">
                                                    <span>Service Fee</span>
                                                    {/* User Story 1073: Booking Summary for Single PNR */}
                                                    <span id="spnMarkupAmountHotel">{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                                                    }).format(0.00)}</span>
                                                </span>}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='my-2'></hr>
                <div className="">
                    <label className="form-check-label" for="flexCheckDefaultf1">
                        <span className="area_flex_one">
                            <span className='fw-bold'>Total Rate</span>
                            {/* User Story 1073: Booking Summary for Single PNR */}
                            <span id="spnTotalAmountHotel" className='fw-bold ms-2 text-break'> {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.guestInfo.hotelResults[0].price[0].currencyCode
                            }).format(props.guestInfo.hotelResults[0].price[0].offeredPrice.priceOffered)}</span>
                        </span>
                    </label>
                </div>

            </div>
        </div>
    </Fragment>)
}

export default Pricesummary;