import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RegionItem from './RegionItem'
import useHttp from '../../../../services/use-http'
import { deleteSingleRegion } from '../../../../services/Region-API'
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import { sortData } from '../../../../utils';

const RegionList = (props) => {
  const { t } = useTranslation(['translation.Region']);
  const history = useHistory();
  const location = useLocation();

  const [error1, setError] = useState();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';

  const sortRegion = sortData(props.region,'regionName', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };
  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleRegion, true);
  let isErrorOnDelete = false;

  useEffect(() => {

    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });

    history.push({
      pathname: '/SearchRegion',
      search: `?sort=${('asc')}`
    })

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }

    if (status === 'completed') {      
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });
        }
        else if (loadedQuote['errors'].error['code'] === '1020') {
          setError({
            title: "Message",
            message: `${t('errorParameterInUseDelete')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
      }
      else {
        setError({
          title: "Message",
          message: `${t('recordDeletedMessage')}`,
        });
      }
    }

  }, [status, history, loadedQuote, t])

  const deleteItemsHandler = (props) => {
    const onDeleteRegion = ({
      "id": props.id,
      "traceID": "",
      "userID": JSON.parse(localStorage.getItem('userID')),
      "serviceID": "",
      "regionName": "",
      "applicableTo": [
        {
          "applicableID": ""
        }
      ],
      "includeExcludeRegion": {
        "continentlst": [
          {
            "value": "",
            "label": "",
            "status": true,
            "allCity": false,
            "citylst": [
              {
                "code": "",
                "name": ""
              }
            ],
            "children": [
              {
                "value": "",
                "label": "",
                "status": true,
                "allCity": false,
                "citylst": [
                  {
                    "code": "",
                    "name": ""
                  }
                ],
                "children": [
                  {
                    "value": "",
                    "label": "",
                    "status": true
                  }
                ]
              }
            ]
          }
        ]
      },
      "officeID": JSON.parse(localStorage.getItem('officeID'))
    });

    sendRequest(onDeleteRegion);

    if (error) {
      return <p className='centered focused'>{t('errorRecordDelete')}</p>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  //End code for delete
  const errorHandler = () => {
    setError(null);
    if (!isErrorOnDelete) {
      history.go({
        pathname: '/SearchRegion'
      })
    }
    else {
      history.push({
        pathname: '/SearchRegion'
      })
    }
  };
  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}

      <div id="sortByText" className="container-fluid mb-3 list-view">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
            <div className='row mb-3'>
              <div className="col-md-6"></div>
              <div className='col-md-2 my-auto text-muted text-left'>
                <div className="float-lg-end float-md-end">Sort By</div>
              </div>
              <div className="col-md-4 form-group">
                <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                  <option>{t('sortRegionOptionAsc')}</option>
                  <option>{t('sortRegionOptionDesc')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {sortRegion.map((region) => (
          <RegionItem onDelete={deleteItemsHandler}
            key={region.id}
            id={region.id}
            serviceName={region.serviceName}
            regionName={region.regionName}            
            continentlst={[]}
          />
        ))}

      </div>
    </Fragment>
  );
};

export default RegionList;
