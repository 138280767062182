import { Fragment } from 'react';
import { HotelDetailsJSON } from './../../../utils/HotelDetails'
import { useHistory } from 'react-router-dom';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const HotelItem = (props) => {
    console.log(props)
    const hotelLogoLink = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_HOTEL_LOG_DEVELOPMENT_URL + "defaultHotel.png" : process.env.REACT_APP_HOTEL_LOG_PRODUCTION_URL + "defaultHotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    const history = useHistory();
    let hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
    console.log(props.hotelResults);
    return <Fragment>
        <div className="hotel-list-cn clearfix hotel-booking-mt-0">
            <div className="hotel-list-item position-relative perk-border light-yellow-bg hotel-list-item2 ">
                <figure className="hotel-img float-left gt-h-227">
                    <a title="">
                        {
                            (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length > 0) &&
                            <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room')[0].imagePath} className="card-img-top" alt="..." />
                        }
                        {
                            (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length > 0) &&
                            <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite')[0].imagePath} className="card-img-top" alt="..." />
                        }
                        {
                            (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length <= 0) &&
                            <img src={hotelDetailsResponse.data.hotelResults[0].images[0].imagePath} className="card-img-top" alt="..." />
                        }
                        {
                            (hotelDetailsResponse.data.hotelResults[0].images === undefined) &&
                            <img src={hotelLogoLink} className="card-img-top" alt="..." />
                        }
                    </a>
                </figure>
                {/* <span className='hotel-flag' title="GT Blue">
                    <img src="assets/img/gt-blue.png" alt="logo" />                    
                </span> */}
                <div className="hotel-text ">
                    <div className="hotel-name1">
                        <a title="">{props.hotelResults.hotelName}
                            {/*  <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                        </a>
                    </div>
                    <div className="hotel-star-address">
                        <span className="hotel-star">
                            <i className="glyphicon glyphicon-star"></i>
                            <i className="glyphicon glyphicon-star"></i>
                            <i className="glyphicon glyphicon-star"></i>
                            <i className="glyphicon glyphicon-star"></i>
                            <i className="glyphicon glyphicon-star"></i>
                        </span>

                        <address className="hotel-address">
                            {props.hotelResults.address.addressLine}<br></br>
                            {props.hotelResults.address.city.cityName},&nbsp;
                            {props.hotelResults.address.country.countryName}
                        </address>

                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-12 col-sm-12 my-auto'>
                            {props.hotelResults.propertyTypeInfo !== undefined && props.hotelResults.propertyTypeInfo !== null ? <span className='text-muted'>Property Type</span> : ''}
                            <br></br><span className='text-primary fw-bold'>{props.hotelResults.propertyTypeInfo !== undefined && props.hotelResults.propertyTypeInfo !== null ? props.hotelResults.propertyTypeInfo.map(a => a.description).join(", ") : ''}</span>
                        </div>

                        <hr className='my-1'></hr>
                        <div className='col-md-12 col-sm-12 my-auto'>

                            <div className="col-md-12 col-sm-12 text-14px text-muted pb-1">
                                {getConfigurationByBoolen("ALLOW_B2B") && <span>Source: <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'CGN' : 'Sabre'} </span> | PCC: <span className="text-primary fw-bold">{props.hotelResults.price[0].pcc}</span></span>}
                                {getConfigurationByBoolen("ALLOW_COMISSION") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && props.hotelResults.price[0].agentCommissionType !== undefined && (props.hotelResults.price[0].agentCommissionType === "Variable" || props.hotelResults.price[0].agentCommissionType === "Amount") ? '| Comm.:  ' : props.hotelResults.price[0].agentCommission !== undefined && props.hotelResults.price[0].agentCommission !== '' ? '| Comm.:  ' : ''}<span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && props.hotelResults.price[0].agentCommissionType !== undefined ? props.hotelResults.price[0].agentCommissionType === 'Percentage' ? props.hotelResults.price[0].agentCommission !== '' && props.hotelResults.price[0].agentCommission !== undefined ? Math.abs(props.hotelResults.price[0].agentCommission) + "% " : '' : props.hotelResults.price[0].agentCommissionType === 'Variable' ? 'Variable' :
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: props.hotelResults.price[0].currencyCode
                                    }).format(props.hotelResults.price[0].agentCommissionAmount !== undefined ? props.hotelResults.price[0].agentCommissionAmount : props.hotelResults.price[0].agentCommission) : ''}</span></span>}
                                {getConfigurationByBoolen("ALLOW_B2B") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? '| Rate Plan:' : props.hotelResults.roomRateDetails[0].infoSource !== undefined ? '| Rate Plan:' : ''} <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'Negotiated Net' : props.hotelResults.roomRateDetails[0].infoSource !== undefined ? props.hotelResults.roomRateDetails[0].ratePlanName : ''}</span></span>}
                                {getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {getConfigurationByBoolen("ALLOW_B2B") && !getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {!getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {props.hotelResults.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true' ? <div className=" text-14px text-danger mt-2"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="text-14px text-sky-blue mt-2"><i className="fas fa-money-check-alt"></i>  Refundable</div>}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>

    </Fragment>
};
export default HotelItem;
