import { useState, Fragment, useEffect } from "react";
import { useLocation } from 'react-router-dom';
const HotelStatus = (props) => {
    const [isCanceled, setIsCanceled] = useState(false);
    const location = useLocation();
    let pnr = props.bookingJSON.data.bookingReferenceID !== undefined ?
        props.bookingJSON.data.bookingReferenceID[0].id : null;
    let isFailed = (props.bookingJSON.data.bookingType === "ON" && isCanceled === false && pnr === null) ? true : false
    useEffect(() => {
        if (location.hotelTrip !== undefined) {
            setIsCanceled(location.hotelTrip.isCanceled);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsHotelCancelled") === 'true' ? true : false)
        }
    }, [])
    return <Fragment>
        <div className='col-md-12 mb-2 mt-5'>
            <label for="" className="h5 text-primary"><i className="fas fa-hotel"></i> <span className="h6">Hotel</span> <span class={props.bookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null && props.bookingJSON.data.hotelResults[0].confirmationNumber !== undefined ? "badge h6 rounded-pill mb-0 bg-success" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={props.bookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null && props.bookingJSON.data.hotelResults[0].confirmationNumber !== undefined ? "fa-solid fa-check-circle" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {props.bookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : pnr !== null && props.bookingJSON.data.hotelResults[0].confirmationNumber !== undefined ? "Booked" : "Failed"}</span></label>
        </div>
    </Fragment>
}
export default HotelStatus;