import { Fragment, useState, useEffect, useRef } from 'react';
import PopupModal from '../../components/Common/Flight/PrintPopupModal';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import FlightItem from '../../components/Common/Flight/FlightItem';
import FareSummary from '../../components/Common/Flight/FareSummary';
import PrintConfirmation from '../../components/Common/Flight/PrintConfirmation';
import SendEmail from '../BookingConfirmation/SendEmail';
import { Tooltip as ReactTooltip } from "react-tooltip";
import CancelPNR from './Components/CancelPNR';
import Notes from './Components/Notes';
import TravellerInfo from '../BookingConfirmation/Components/TravellerInfo'
import HistoryDetails from './Components/HistoryDetails';
import { useLocation } from 'react-router-dom';
import Logs from './Components/Logs';
import Rebooking from './Components/Rebooking';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { useHistory } from 'react-router-dom';
import { importPNRBooking, getGdsRetrieveTrip, importTicket, getRetrieveTrip } from '../../services/Booking-API';
import useHttp from '../../services/use-http';
import { RETRIEVETRIP } from '../../navigation/CONSTANTS';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import BookingLoadingSpinner from '../Booking/Components/BookingLoadingSpinner';
import HistoryDetailsImportPnr from './Components/HistoryDetailsImportPnr';
import queryString from 'query-string';
import { getConfigurationByBoolen } from '../../utils/ConfigurationsFunction';
import { CreateImportAirItineraryBookingJSON } from '../../utils/BookingFunction';
import { v4 as uuidv4 } from 'uuid';

const RetrieveTrip = (props) => {
    useDocumentTitle(sessionStorage.getItem("sourcePNR") !== null ? 'Flight Review' : 'Trip Details');

    let bookingJSON = sessionStorage.getItem("sourcePNR") !== null ? JSON.parse(sessionStorage.getItem("BookingData")) : JSON.parse(localStorage.getItem("BookingData"));

    let pnr = bookingJSON.data.bookingReferenceID !== undefined ? bookingJSON.data.bookingReferenceID[0].id : null;
    let issueTicket = pnr === null ? " Booking Details" : " Booking Confirmation";
    let isModified = bookingJSON.data.isModified !== undefined ? bookingJSON.data.isModified === true ? true : false : false
    let hotelConfirmationNo = bookingJSON.data.hotelResults === undefined ? "" : bookingJSON.data.hotelResults[0].confirmationNumber !== undefined ? bookingJSON.data.hotelResults[0].confirmationNumber : null;
    const [error1, setError] = useState();
    const [errorMail, setErrorMail] = useState();
    const [isCanceled, setIsCanceled] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [confirm, setConfirm] = useState('');
    const valueParam = queryString.parse(location.search);

    const { sendRequest, status, data: loadedBookingResponse } = useHttp(importPNRBooking);
    const { sendRequest: gdsRetrieveTrip, data: loadedGdsRetrieveTripDetails, status: gdsRetrieveTripStatus } = useHttp(getGdsRetrieveTrip);
    const { sendRequest: importTicketRequest, data: loadedImportTicketDetails, status: importTicketStatus } = useHttp(importTicket);
    const { sendRequest: retrieveTrip, data: loadedRetrieveTripDetails, status: retrieveTripStatus } = useHttp(getRetrieveTrip);

    let isFailed = (bookingJSON.data.requestType === "ON" && isCanceled === false && pnr === null) ? true : false;
    let flightSegmentStatus = "";

    if (bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined) {
        bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                if (elementFlightSegment.status !== undefined) {
                    if (elementFlightSegment.status.toUpperCase() == 'YK') {
                        flightSegmentStatus = elementFlightSegment.status;
                    }
                }
            });
        });
    }

    const onSendEmail = () => {
        setErrorMail({
            title: "Send Itinerary",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <SendEmail mailData={bookingJSON} hideData="0" errorHandler={errorHandler}></SendEmail>
                </div></div>,
        })
    }

    const onPNRCancel = () => {
        setErrorMail({
            title: "Cancel Booking",
            message: <div className='col-md-12'>
                <div className='dummy-flight'>
                    <CancelPNR mailData={bookingJSON} errorHandler={errorHandler} hideData="0" source='flight'></CancelPNR>
                </div></div>,
        })
    }

    const onRebooking = () => {
        setErrorMail({
            title: "Reschedule Request",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <Rebooking bookingJSON={bookingJSON} errorHandler={errorHandler} ></Rebooking>
                </div></div>,
        })
    }

    const onNotes = () => {
        setErrorMail({
            title: "Internal Notes",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <Notes bookingJSON={bookingJSON} errorHandler={errorHandler} hideData="0"></Notes>
                </div></div>,
        })
    }

    const onHistory = () => {
        setErrorMail({
            title: "History Details",
            message: <div className='col-md-12'>
                <div>
                    <HistoryDetails bookingJSON={bookingJSON} errorHandler={errorHandler} hideData="0"></HistoryDetails>
                </div></div>,
        })
    }

    const onLogs = () => {
        setErrorMail({
            title: "Logs",
            message: <div className='col-md-12'>
                <div>
                    <Logs bookingJSON={bookingJSON} errorHandler={errorHandler} ></Logs>
                </div></div>,
        })
    }

    const onHistoryImportFlight = () => {
        setErrorMail({
            title: "Flight Modify History Details",
            message: <div className='col-md-12'>
                <div>
                    <HistoryDetailsImportPnr bookingJSON={bookingJSON} errorHandler={errorHandler} hideData="0"></HistoryDetailsImportPnr>
                </div></div>,
        })
    }

    useEffect(() => {
        if (location.mytrip !== undefined) {
            setIsCanceled(location.mytrip.isCanceled);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsCanceled") === 'true' ? true : false)
        }

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])

    useEffect(() => {
        // window.scrollTo({
        //     top: 50,
        //     behavior: "smooth",
        // });
        if (status === 'completed') {
            if (loadedBookingResponse !== null && loadedBookingResponse.data !== null) {

                if (loadedBookingResponse.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSave') !== null) {
                        document.querySelector('#btnSave').style.display = "";
                    }
                    if (document.querySelector('#btnSaveGdsPnr') !== null) {
                        document.querySelector('#btnSaveGdsPnr').style.display = "";
                    }

                    setConfirm({
                        title: "Message",
                        message: loadedBookingResponse.data.errors.error.description,
                        //details: loadedBookingResponse.data.errors.error.description
                    });
                }
                else {
                    localStorage.setItem("BookingData", JSON.stringify(loadedBookingResponse));
                    localStorage.removeItem("AdditionalServicesSeatData");
                    localStorage.removeItem("AdditionalServicesData");
                    sessionStorage.removeItem("FlightIntialTotalAmountImport");
                    sessionStorage.removeItem("sourcePNR");
                    sessionStorage.removeItem("finalTotalFare");

                    history.push('/RetrieveTrip?pnr=1');
                    history.go();
                }
            }
            else {
                if (document.querySelector('#btnSave') !== null) {
                    document.querySelector('#btnSave').style.display = "";
                }
                if (document.querySelector('#btnSaveGdsPnr') !== null) {
                    document.querySelector('#btnSaveGdsPnr').style.display = "";
                }

                setConfirm({
                    title: "Message",
                    message: 'Some thing went wrong.',
                });
            }
            return;
        }
    }, [status, history, loadedBookingResponse])

    useEffect(() => {
        if (valueParam.pnr !== undefined && valueParam.pnr === '1') {
            var menuToHideList = document.querySelectorAll('.hidemenu');
            menuToHideList.forEach(element => {
                element.classList.add('d-none');
            });
        }
        if (valueParam.report !== undefined && valueParam.report === 'true') {
            var menuToHideList = document.querySelectorAll('.hidereportmenu');
            menuToHideList.forEach(element => {
                element.classList.add('d-none');
            });
        }
    })

    const errorHandler = () => {
        setError(null);
        setErrorMail(null);
    };

    function onBack() {
        history.push('/ImportPNR');
        //history.go('/ImportPNR');
    }

    function onSave() {

        if (document.querySelector('#btnSave') !== null) {
            document.querySelector('#btnSave').style.display = "none";
        }

        if (document.querySelector('#btnSaveGdsPnr') !== null) {
            document.querySelector('#btnSaveGdsPnr').style.display = "none";
        }

        if (document.querySelector('#chkCreateGhostPnr:checked')) {
            if (bookingJSON !== undefined && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined) {
                bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                    elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                        if (elementFlightSegment.status !== undefined) {
                            elementFlightSegment.status = "YK";
                        }
                    });
                });
            }
            bookingJSON.data.requestType = "YK";
            bookingJSON.data.travelAgentName = localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null ? JSON.parse(localStorage.getItem('displayName')) : "";
            sendRequest(bookingJSON);
        }
        else {
            bookingJSON.data.travelAgentName = localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null ? JSON.parse(localStorage.getItem('displayName')) : "";
            sendRequest(bookingJSON);
        }

        localStorage.removeItem("AdditionalServicesSeatData");
        localStorage.removeItem("AdditionalServicesData");
        sessionStorage.removeItem("FlightIntialTotalAmountImport");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function onSaveGdsPnr() {

        if (document.querySelector('#btnSave') !== null) {
            document.querySelector('#btnSave').style.display = "none";
        }

        if (document.querySelector('#btnSaveGdsPnr') !== null) {
            document.querySelector('#btnSaveGdsPnr').style.display = "none";
        }

        bookingJSON.data.requestType = "YK";
        sendRequest(bookingJSON);

        localStorage.removeItem("AdditionalServicesSeatData");
        localStorage.removeItem("AdditionalServicesData");
        sessionStorage.removeItem("FlightIntialTotalAmountImport");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function cancelHandler() {
        setConfirm(false);
    };

    function okHandler() {
        setConfirm('');
    };

    const onPnrModify = () => {
        if (bookingJSON.data.importPNR === "M") {
            retrieveTrip(bookingJSON.data.bookingReferenceNumber);
        }
        else {
            localStorage.setItem('traceID', JSON.stringify(uuidv4()));
            gdsRetrieveTrip(
                {
                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "data": {
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),
                        "UserID": JSON.parse(localStorage.getItem('userID')),
                        "bookingReferenceID": {
                            "type": "PNR",
                            "id": bookingJSON.data.bookingReferenceID[0].id
                        }
                    }
                }
            );
        }
    }

    const onPnrTicket = () => {
        importTicketRequest(bookingJSON);
    }

    useEffect(() => {
        if (gdsRetrieveTripStatus === 'completed') {
            if (loadedGdsRetrieveTripDetails !== null) {
                if (loadedGdsRetrieveTripDetails.data.errors.status === 'FALSE') {

                    loadedGdsRetrieveTripDetails.data.bookingReferenceNumber = bookingJSON.data.rootBookingReferenceNumber;
                    loadedGdsRetrieveTripDetails.data.shoppingID = bookingJSON.data.rootShoppingID;
                    loadedGdsRetrieveTripDetails.data.previousBookingReferenceNumber = bookingJSON.data.previousBookingReferenceNumber;
                    loadedGdsRetrieveTripDetails.data.importPNR = bookingJSON.data.importPNR;

                    loadedGdsRetrieveTripDetails.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                    loadedGdsRetrieveTripDetails.data.UserID = JSON.parse(localStorage.getItem('userID'));

                    sessionStorage.setItem("BookingData", JSON.stringify(loadedGdsRetrieveTripDetails));
                    sessionStorage.removeItem("FlightIntialTotalAmountImport");
                    sessionStorage.setItem("sourcePNR", pnr);
                    sessionStorage.removeItem("finalTotalFare");

                    history.push(`/ImportPNR?pnr=I`)
                    history.go();
                }
                //user friendly message for View Sabre PNR-if segment is not found 
                else if (loadedGdsRetrieveTripDetails.data.errors.status === 'TRUE' && loadedGdsRetrieveTripDetails.data.errors.error.description === 'As per Sabre, this PNR does not contain any flights.') {
                    if (loadedGdsRetrieveTripDetails.data.airItinerary === undefined) {
                        loadedGdsRetrieveTripDetails.data.airItinerary = CreateImportAirItineraryBookingJSON();
                    }
                    loadedGdsRetrieveTripDetails.data.bookingReferenceNumber = bookingJSON.data.rootBookingReferenceNumber;
                    loadedGdsRetrieveTripDetails.data.shoppingID = bookingJSON.data.rootShoppingID;
                    loadedGdsRetrieveTripDetails.data.previousBookingReferenceNumber = bookingJSON.data.previousBookingReferenceNumber;
                    loadedGdsRetrieveTripDetails.data.importPNR = bookingJSON.data.importPNR;

                    loadedGdsRetrieveTripDetails.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                    loadedGdsRetrieveTripDetails.data.UserID = JSON.parse(localStorage.getItem('userID'));

                    sessionStorage.setItem("BookingData", JSON.stringify(loadedGdsRetrieveTripDetails));
                    sessionStorage.removeItem("FlightIntialTotalAmountImport");
                    sessionStorage.setItem("sourcePNR", pnr);
                    sessionStorage.removeItem("finalTotalFare");

                    history.push(`/ImportPNR?pnr=I`)
                    history.go();
                }
                else {
                    setError({
                        title: "Message",
                        message: loadedGdsRetrieveTripDetails.data.errors.error.description,
                    });
                    return;
                }
            }
            else {
                setError({
                    title: "Message",
                    message: "Something went wrong.",
                });
                return;
            }
        }
    }, [gdsRetrieveTripStatus, loadedGdsRetrieveTripDetails])

    useEffect(() => {
        if (importTicketStatus === 'completed') {
            if (loadedImportTicketDetails !== null) {
                if (loadedImportTicketDetails.data.errors.status === 'FALSE') {

                    localStorage.setItem("BookingData", JSON.stringify(loadedImportTicketDetails));
                    sessionStorage.removeItem("FlightIntialTotalAmountImport");
                    sessionStorage.removeItem("sourcePNR");
                    sessionStorage.removeItem("finalTotalFare");

                    history.push(RETRIEVETRIP);
                    history.go();

                    if (document.querySelector('#pnrTicket') !== null) {
                        document.querySelector('#pnrTicket').classList.add("d-none");
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: loadedImportTicketDetails.data.errors.error.description,
                    });
                    return;
                }
            }
            else {
                setError({
                    title: "Message",
                    message: "Something went wrong.",
                });
                return;
            }
        }
    }, [importTicketStatus, loadedImportTicketDetails])

    useEffect(() => {
        if (retrieveTripStatus === 'completed') {
            let bookingJSON = [];
            bookingJSON = loadedRetrieveTripDetails;
            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                loadedRetrieveTripDetails.data.bookingReferenceNumber = bookingJSON.data.rootBookingReferenceNumber;
                loadedRetrieveTripDetails.data.shoppingID = bookingJSON.data.rootShoppingID;
                loadedRetrieveTripDetails.data.previousBookingReferenceNumber = bookingJSON.data.previousBookingReferenceNumber;
                loadedRetrieveTripDetails.data.importPNR = bookingJSON.data.importPNR;

                loadedRetrieveTripDetails.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                loadedRetrieveTripDetails.data.UserID = JSON.parse(localStorage.getItem('userID'));

                sessionStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.removeItem("FlightIntialTotalAmountImport");
                sessionStorage.setItem("sourcePNR", pnr);
                sessionStorage.removeItem("finalTotalFare");

                history.push(`/ImportPNR?pnr=M`)
                //history.go();
            }
            else {
                setError({
                    title: "Message",
                    message: loadedRetrieveTripDetails.data.errors.error.description,
                });
                return;
            }
        }
    }, [retrieveTripStatus, loadedRetrieveTripDetails])


    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        <div >

            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>
            {(status === 'pending') &&
                <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mt-5">
                                    <div className="row  position-relative gt-h-255 my-auto">
                                        <BookingLoadingSpinner action='import'></BookingLoadingSpinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {
                (status !== 'pending') &&
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mb-3">
                                    <div className="col-lg-12">
                                        {
                                            <div className='row'>
                                                {sessionStorage.getItem("sourcePNR") === null && props.hideData !== '1' && <div className='col-md-7'>
                                                    <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket} <span class={bookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : isModified === true ? 'badge h6 rounded-pill mb-0 bg-orange' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={bookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : isModified === true ? "fa-solid fa-pen-square" : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {bookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : isModified === true ? "Modified" : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>
                                                </div>}
                                                {sessionStorage.getItem("sourcePNR") !== null && props.hideData !== '1' && <div className='col-md-7'>
                                                    <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {'Flight Review'} </label>
                                                </div>}
                                                {
                                                    sessionStorage.getItem("sourcePNR") === null &&
                                                    <div className='col-md-5'>
                                                        {checkSecurityDetails("My Trips", "Email", "My Trips_Email_Allow Send Email") && isCanceled === false && isFailed === false && isModified === false && < div className='float-lg-end'>
                                                            <span id="sendMail1" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end hidereportmenu' onClick={() => onSendEmail()}><i className="fas fa-envelope"></i>
                                                            </span>
                                                            <ReactTooltip
                                                                anchorId={"sendMail1"}
                                                                place="bottom"
                                                                variant="info"
                                                                multiline={true}
                                                                className="tooltip"
                                                                html={"Email"}
                                                            />
                                                        </div>}

                                                        {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && isCanceled === false && isFailed === false && isModified === false && <div className='float-lg-end hidereportmenu'>
                                                            <PrintConfirmation page="retrieve"></PrintConfirmation>
                                                        </div>}

                                                        {
                                                            checkSecurityDetails("My Trips", "Cancellation", "My Trips_Cancellation_Allow Cancellation") && (isCanceled === false) && isModified === false &&
                                                            <div className='float-lg-end'>
                                                                <span id="pnrCancellation" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end hidemenu hidereportmenu' onClick={() => onPNRCancel()}><i className="fas fa-times px-1"></i>
                                                                </span>
                                                                <ReactTooltip
                                                                    anchorId={"pnrCancellation"}
                                                                    place="bottom"
                                                                    variant="info"
                                                                    multiline={true}
                                                                    className="tooltip"
                                                                    html={"Cancel Booking"}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            checkSecurityDetails("My Trips", "Reschedule", "My Trips_Reschedule_Allow Reschedule") && (isCanceled === false) && isModified === false &&
                                                            <div className='float-lg-end'>
                                                                <span id="pnrRebooking" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-success float-end hidemenu hidereportmenu' onClick={() => onRebooking()}><i className="fas fa-retweet rotate-90"></i>
                                                                </span>
                                                                <ReactTooltip
                                                                    anchorId={"pnrRebooking"}
                                                                    place="bottom"
                                                                    variant="info"
                                                                    multiline={true}
                                                                    className="tooltip"
                                                                    html={"Reschedule Request"}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            checkSecurityDetails("My Trips", "Internal Note", "My Trips_InternalNote_Allow  Internal Note") && getConfigurationByBoolen("ALLOW_B2B") && <div className='float-lg-end'>
                                                                <span id="notes" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end hidemenu hidereportmenu' onClick={() => onNotes()}><i className="fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                        }
                                                        {
                                                            checkSecurityDetails("My Trips", "History", "My Trips_History_Allow History") && <div className='float-lg-end'>
                                                                <span id="history" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end hidemenu hidereportmenu' onClick={() => onHistory()}><i className="fas fa-history"></i>
                                                                </span>
                                                            </div>
                                                        }


                                                        {checkSecurityDetails("My Trips", "Reschedule", "My Trips_Logs_Allow Logs") && ((pnr === null || hotelConfirmationNo === null) && bookingJSON.data.requestType === "ON") && isModified === false && <div className='float-lg-end'>
                                                            <span id="Logs" className='btn btn-sm text-white mt-1 ms-1 btn-dark float-end hidemenu hidereportmenu' onClick={() => onLogs()}><i class="fas fa-hourglass-half"></i>



                                                            </span>
                                                        </div>}


                                                        {
                                                            checkSecurityDetails("My Trips", "ModifyPNR", "My Trips_ModifyPNR_Allow Modify PNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true && bookingJSON.data.requestType !== 'OF' && isCanceled === false && isFailed === false && isModified === false &&
                                                            <div className='float-lg-end'>
                                                                <span id="pnrModify" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 gt-green-btn float-end hidemenu hidereportmenu' onClick={() => onPnrModify()}> {(gdsRetrieveTripStatus === 'pending' || retrieveTripStatus === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : <i className="fas fa-pen"></i>}
                                                                </span>
                                                                <ReactTooltip
                                                                    anchorId={"pnrModify"}
                                                                    place="bottom"
                                                                    variant="info"
                                                                    multiline={true}
                                                                    className="tooltip"
                                                                    html={"Modify Flight"}
                                                                />
                                                            </div>
                                                        }

                                                        {
                                                            checkSecurityDetails("My Trips", "RetrieveTicket", "My Trips_RetrieveTicket_Allow Retrieve Ticket") && getConfigurationByBoolen("ALLOW_IMPORT_TICKET") === true && bookingJSON.data.requestType === 'ON' && bookingJSON.data.importPNR !== "M" && isCanceled === false && isFailed === false && (bookingJSON.data.travelerInfo.traveler[0].ticketInfo === undefined || bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber === undefined || bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber === '') && isModified === false &&
                                                            <div className='float-lg-end'>
                                                                <span id="pnrTicket" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 gt-yellow-btn float-end hidemenu hidereportmenu' onClick={() => onPnrTicket()}> {(importTicketStatus === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : <i className="fas fa-ticket-alt"></i>}
                                                                </span>
                                                                <ReactTooltip
                                                                    anchorId={"pnrTicket"}
                                                                    place="bottom"
                                                                    variant="info"
                                                                    multiline={true}
                                                                    className="tooltip"
                                                                    html={"Retrieve Ticket"}
                                                                />
                                                            </div>
                                                        }

                                                        {
                                                            checkSecurityDetails("My Trips", "FlightModifyHistory", "My Trips_FlightModifyHistory_Allow Flight Modify History") && bookingJSON.data.rootShoppingID !== undefined && bookingJSON.data.rootShoppingID !== null && bookingJSON.data.rootShoppingID !== '' && bookingJSON.data.rootShoppingID !== '0' && bookingJSON.data.rootShoppingID !== bookingJSON.data.shoppingID &&
                                                            <div className='float-lg-end'>
                                                                <span id="historyPnrImport" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end hidemenu hidereportmenu' onClick={() => onHistoryImportFlight()}><i className="fas fa-clock"></i>
                                                                </span>
                                                                <ReactTooltip
                                                                    anchorId={"historyPnrImport"}
                                                                    place="bottom"
                                                                    variant="info"
                                                                    multiline={true}
                                                                    className="tooltip"
                                                                    html={"Flight Modify History"}
                                                                />
                                                            </div>
                                                        }

                                                        <ReactTooltip
                                                            anchorId={"notes"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Internal Notes"}
                                                        />
                                                        <ReactTooltip
                                                            anchorId={"history"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"History"}
                                                        />
                                                        <ReactTooltip
                                                            anchorId={"Logs"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Logs"}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <div className='row'>
                                            <div className='col-md-12'>
                                                {sessionStorage.getItem("sourcePNR") === null && props.hideData !== '1' && <div className='col-md-12'>
                                                    <div className='text-muted h6 mb-2 mt-2'>
                                                        <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                                                        {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                                </div>}
                                                {sessionStorage.getItem("sourcePNR") !== null && sessionStorage.getItem("sourcePNR") !== '' && props.hideData !== '1' && <div className='col-md-12'>
                                                    <div className='text-muted h6 mb-2 mt-2'>
                                                        {pnr !== null && <span> <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <FlightItem
                                                key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                airItinerary={bookingJSON.data.airItinerary[0]}
                                                searchFlight={bookingJSON.data.airItinerary[0]}
                                                bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                                fareRuleData={bookingJSON.data.airItinerary[0]}
                                                showData="3"
                                                hideIcon="2"
                                                hideCommision="4"
                                            />

                                            <div className="tour_details_boxed_inner">
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion-item xyz pb-0">
                                                            <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                                Price
                                                            </h5>

                                                            <div id="collapseOne" className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                                                <div className='row my-2'>
                                                                    <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    {props.hideData !== '1' && <TravellerInfo bookingJSON={bookingJSON}></TravellerInfo>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            sessionStorage.getItem("sourcePNR") !== null && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data.importPNR !== undefined && bookingJSON.data.importPNR === "M" &&
                                            <>
                                                <div className='text-black my-4'>
                                                    <input className="form-check-input tandc-options" type="checkbox" id="chkCreateGhostPnr" ></input>&nbsp;
                                                    Create Ghost PNR in Sabre GDS</div>
                                                <div className="col-md-12 accordion-header text-black fw-bold mb-4">Note: <span className="text-primary fw-500" >If you select this option, the status of all segments in this booking will be changed to "YK" and a PNR will be created in Sabre GDS.</span></div>
                                            </>
                                        }
                                        {
                                            sessionStorage.getItem("sourcePNR") !== null &&
                                            <div className='row mt-2'>
                                                {/* <div className="col-lg-12 col-md-12 col-sm-12 vvvv"> */}
                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                    <button type="button" id='btnBack' className="btn gt-btn_md text-white mt-1 ms-1 gt-main-btn ggg d-none d-sm-block d-sm-none d-md-block" onClick={() => onBack()}>Edit</button>


                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                    <div className="float-md-end float-lg-end">
                                                        <button type="button" id='btnBack' className="btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none" onClick={() => onBack()}>Edit</button>
                                                        {
                                                            flightSegmentStatus.toUpperCase() == 'YK' &&
                                                            <button type="button" id='btnSaveGdsPnr' className="btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1 ntnt d-none" onClick={() => onSaveGdsPnr()}>Save GDS PNR</button>
                                                        }
                                                        <button type="button" id='btnSave' className="btn gt-btn_md text-white mt-1 gt-main-btn ms-1 ntnt" onClick={() => onSave()}>Save</button>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </div>
    </Fragment>
};

export default RetrieveTrip;