import {
   ADD_EMAIL_SERVER,
   UPDATE_EMAIL_SERVER,
   GET_EMAIL_SERVER,
}
from './CONSTANTS'
export async function updateEmailServer(updateData) {
    // console.log(updateData);
    const response = await fetch(UPDATE_EMAIL_SERVER(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update email server');
    }
    // console.log(data);
    return data;

}
export async function addEmailServer(addData){
    //  console.log(addData);
    const response =await fetch(ADD_EMAIL_SERVER(),{
        method:"POST",
        body:JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add email server');
    }
    //  console.log(data);
    return data;

}
export async function getSingleEmailServer(ID) {
    // console.log(ID);
    const response = await fetch(GET_EMAIL_SERVER(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch email server');
    }
    // console.log(data);
    return data;
}