import Dropdown from 'react-select';
import { getFormatDate, getFormatTime, getMinutetoHrsWithoutFormat, getHrstoMinuteWithoutFormat } from '.././../../../utils/CommonFunction';
import { useState, Fragment, useEffect } from 'react';
import { journey_list_oneway, journey_list_roundtrip, journey_list_multicity, hoursList, minutesList } from '../../../../utils/DropdownData';
import { useHistory } from 'react-router-dom';
import { CreateImportBookingJSON } from '../../../../utils/BookingFunction';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BaggageAllowedCheckin from './BaggageAllowedCheckin';
import BaggageAllowedCabin from './BaggageAllowedCabin';
import MdModal from '../../../Flights/MdModal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { searchAllAirline } from '../../../../services/Airline-API';
import useHttp from '../../../../services/use-http';
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { searchCommonList } from '../../../../services/Fee-API';
import { searchAPIKeys } from '../../../../services/CommonListData-API';
import sorter from "sort-nested-json";
import { toPascalCase } from '.././../../../utils/CommonFunction';

const FlightDetails = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [tripTypeInputHasError, setTripTypeInputHasError] = useState(false);
    const [tripTypeInvalidInputHasError, setTripTypeInvalidInputHasError] = useState(false);
    const [groupInputHasError, setGroupInputHasError] = useState(false);
    const [groupInvalidInputHasError, setGroupInvalidInputHasError] = useState(false);
    const [equipmentInputHasError, setEquipmentInputHasError] = useState(false);
    const [airlinePnrInputHasError, setAirlinePnrInputHasError] = useState(false);
    const [airlinePnrInvalidInputHasError, setAirlinePnrInvalidInputHasError] = useState(false);
    const [marketingAirlineCodeInputHasError, setMarketingAirlineCodeInputHasError] = useState(false);
    const [validatingCarrierInputHasError, setValidatingCarrierInputHasError] = useState(false);
    const [flightNumberInputHasError, setFlightNumberInputHasError] = useState(false);
    const [flightNumberInvalidInputHasError, setFlightNumberInvalidInputHasError] = useState(false);
    const [fromInputHasError, setFromInputHasError] = useState(false);
    const [toInputHasError, setToInputHasError] = useState(false);
    const [calFromInputHasError, setCalFromInputHasError] = useState(false);
    const [calToInputHasError, setCalToInputHasError] = useState(false);
    const [calToInvalidInputHasError, setCalToInvalidInputHasError] = useState(false);
    const [departureTimeInputHasError, setDepartureTimeInputHasError] = useState(false);
    const [arrivalTimeInputHasError, setArrivalTimeInputHasError] = useState(false);
    const [arrivalTimeInvalidInputHasError, setArrivalTimeInvalidInputHasError] = useState(false);
    const [cabinCodeInputHasError, setCabinCodeInputHasError] = useState(false);
    const [hoursInputHasError, setHoursInputHasError] = useState(false);
    const [minutesInputHasError, setMinutesInputHasError] = useState(false);
    const [flightStatusInputHasError, setFlightStatusInputHasError] = useState(false);
    const [rbdInputHasError, setRbdInputHasError] = useState(false);
    const [airMilesInvalidInputHasError, setAirMilesInvalidInputHasError] = useState(false);
    const [calFromInvalidInputHasError, setCalFromInvalidInputHasError] = useState(false);
    const [groupSequenceInvalidInputHasError, setGroupSequenceInvalidInputHasError] = useState(false);

    const [cabinValue, SetCabinValue] = useState();
    const [tripType, SetTripType] = useState();
    const [group, SetGroup] = useState();
    const [equipment, SetEquipment] = useState();
    const [equipmentLabel, SetEquipmentLabel] = useState();
    const [selectedRph, SetSelectedRph] = useState();
    const history = useHistory();
    const [confirm, setConfirm] = useState();
    const [rphForDelete, SetRphForDelete] = useState();
    const [checkin, setCheckin] = useState();
    const [cabin, setCabin] = useState();
    const [error2, setError2] = useState();
    const [items, setItems] = useState([]);

    const [departureLocationName, SetDepartureLocationName] = useState();
    const [departureLocationCode, SetDepartureLocationCode] = useState();
    const [departureCountryName, SetDepartureCountryName] = useState();
    const [departureCountryCode, SetDepartureCountryCode] = useState();
    const [departureCityName, SetDepartureCityName] = useState();
    const [departureCityCode, SetDepartureCityCode] = useState();

    const [arrivalLocationName, SetArrivalLocationName] = useState();
    const [arrivalLocationCode, SetArrivalLocationCode] = useState();
    const [arrivalCountryName, SetArrivalCountryName] = useState();
    const [arrivalCountryCode, SetArrivalCountryCode] = useState();
    const [arrivalCityName, SetArrivalCityName] = useState();
    const [arrivalCityCode, SetArrivalCityCode] = useState();

    const [departureDate, SetDepartureDate] = useState();
    const [arrivalDate, SetArrivalDate] = useState();

    const [adultBaggageCheckin, SetAdultBaggageCheckin] = useState('');
    const [childBaggageCheckin, SetChildBaggageCheckin] = useState('');
    const [lapInfantBaggageCheckin, SetLapInfantBaggageCheckin] = useState('');
    const [seatInfantBaggageCheckin, SetSeatInfantBaggageCheckin] = useState('');

    const [adultBaggageCabin, SetAdultBaggageCabin] = useState('');
    const [childBaggageCabin, SetChildBaggageCabin] = useState('');
    const [lapInfantBaggageCabin, SetLapInfantBaggageCabin] = useState('');
    const [seatInfantBaggageCabin, SetSeatInfantBaggageCabin] = useState('');

    const [marketingAirlineCode, setMarketingAirlineCode] = useState('');
    const [marketingAirlineName, setMarketingAirlineName] = useState('');
    const [validatingCarrier, setValidatingCarrier] = useState('');
    const [rbd, setRbd] = useState('');
    const [flightStatus, setFlightStatus] = useState('');
    const [hoursValue, setHoursValue] = useState('');
    const [minutesValue, setMinutesValue] = useState('');
    const [disabledControl, setDisabledControl] = useState(true);
    const [disabledValidatingAirline, setDisabledValidatingAirline] = useState(true);

    const { sendRequest: sendRequestAirline, statusAirline, data: loadedAirline } = useHttp(searchAllAirline);
    const { sendRequest: sendRequestCommonListRbd, data: loadedRbd } = useHttp(searchCommonList);
    const { sendRequest: sendRequestEquipment, statusEquipment, data: loadedEquipment } = useHttp(searchAPIKeys);
    const { sendRequest: sendRequestCommonListFlightStatus, data: loadedFlightStatus } = useHttp(searchCommonList);

    var fromTodayDate = new Date();
    fromTodayDate.setDate(fromTodayDate.getDate() + 14);

    var toTodayDate = new Date();
    toTodayDate.setDate(toTodayDate.getDate() + 15);

    const cabin_list = [
        { value: 'Economy', label: 'Economy' },
        { value: 'Premium Economy', label: 'Premium Economy' },
        { value: 'Business', label: 'Business' },
        { value: 'First', label: 'First' }
    ];

    const trip_list = [
        { value: 'O', label: 'One Way' },
        { value: 'R', label: 'Round Trip' },
        { value: 'M', label: 'Multi City' }
    ];

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

    useEffect(() => {

        if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {

            if (bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.length > 0 && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0] !== undefined) {
                bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                    elementOriginDestinationOption.segmentRefDetail.forEach(elementSegmentRefDetail => {
                        elementSegmentRefDetail.pax.forEach(elementPax => {
                            let travelerCount = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPax.type);

                            if (travelerCount.length <= 0) {
                                let pax = elementSegmentRefDetail.pax.filter(a => !(a.type == elementPax.type));
                                elementSegmentRefDetail.pax = pax;
                                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                            }
                        });
                    });
                });
            }
        }
    })

    let airlineArrayData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                airlineArrayData = loadedAirline[i];
            }
        }
    }

    let airlineOptions = [];
    for (let index = 0; index < airlineArrayData.length; index++) {
        airlineOptions = [...airlineOptions, { value: airlineArrayData[index].airlineCode, label: airlineArrayData[index].airlineName + " (" + airlineArrayData[index].airlineCode + ")" }]
    }

    //airlineOptions = [{ value: '', label: 'Select' }, ...airlineOptions]

    let rbdArrayData = [];
    for (let i in loadedRbd) {
        if (loadedRbd['errors'].status === 'FALSE') {
            if (i === 'data') {
                rbdArrayData = loadedRbd[i];
            }
        }
    }

    let rbdOptions = [];
    for (let index = 0; index < rbdArrayData.length; index++) {
        rbdOptions = [...rbdOptions, { value: rbdArrayData[index].listValue, label: rbdArrayData[index].listValue }]
    }

    let equipmentArrayData = [];
    for (let i in loadedEquipment) {
        if (loadedEquipment['errors'].status === 'TRUE') {
            if (i === 'data') {
                if (loadedEquipment["data"] !== null) {
                    let formattedData = loadedEquipment["data"][0]["data"].replace("{'Data':", "");
                    formattedData = formattedData.substring(0, formattedData.length - 1);
                    formattedData = formattedData.replaceAll("'", '"');
                    equipmentArrayData = JSON.parse(formattedData);
                    equipmentArrayData = sorter.sort(equipmentArrayData).asc("value");
                }
            }
        }
    }

    let equipmentOptions = [];
    for (let index = 0; index < equipmentArrayData.length; index++) {
        equipmentOptions = [...equipmentOptions, { value: equipmentArrayData[index].name, label: equipmentArrayData[index].value + " (" + equipmentArrayData[index].name + ") " }]
    }

    let flightStatusArrayData = [];
    for (let i in loadedFlightStatus) {
        if (loadedFlightStatus['errors'].status === 'FALSE') {
            if (i === 'data') {
                flightStatusArrayData = loadedFlightStatus[i];
            }
        }
    }

    let flightStatusOptions = [];
    for (let index = 0; index < flightStatusArrayData.length; index++) {
        flightStatusOptions = [...flightStatusOptions, { value: flightStatusArrayData[index].listID, label: flightStatusArrayData[index].listValue + " (" + flightStatusArrayData[index].listID + ")" }]
    }

    const handleChangeCabin = (e) => {
        SetCabinValue(e.value);
    };

    const handleChangeTripType = (e) => {
        SetTripType(e.value);
        setDisabledControl(false);

        if (validatingCarrier === undefined || validatingCarrier === '') {
            setDisabledValidatingAirline(false);
        }
        else {
            setDisabledValidatingAirline(true);
        }

        // SetSelectedRph('');
        // SetGroup('');
        // SetEquipment('');
        // SetEquipmentLabel('');
        // document.querySelector('#airlinePnr').value = "";
        // setMarketingAirlineCode('');
        // setMarketingAirlineName('');
        // setValidatingCarrier('');
        // document.querySelector('#flightNumber').value = "";
        // document.querySelector('#from').value = "";
        // document.querySelector('#to').value = "";
        // document.querySelector('#calFrom').value = "";
        // document.querySelector('#departureTime').value = "";
        // document.querySelector('#calTo').value = "";
        // document.querySelector('#arrivalTime').value = "";
        // SetCabinValue('');
        // document.querySelector('#airMiles').value = "";
        // SetAdultBaggageCheckin('');
        // SetChildBaggageCheckin('');
        // SetLapInfantBaggageCheckin('');
        // SetSeatInfantBaggageCheckin('');
        // SetAdultBaggageCabin('');
        // SetChildBaggageCabin('');
        // SetLapInfantBaggageCabin('');
        // SetSeatInfantBaggageCabin('');
        // setFlightStatus('');
        // setRbd('');
        // setHoursValue('');
        // setMinutesValue('');

        // if (document.querySelector('.clear-icon') !== null) {
        //     var controlCount = document.querySelectorAll('.clear-icon');
        //     controlCount.forEach(element => {
        //         element.click();
        //     });
        // }

        // if (document.querySelector('#react-select-8-input') !== null) {
        //     document.querySelector('#react-select-8-input').focus();
        // }
    };

    const handleChangeGroup = (e) => {
        SetGroup(e.value);

        let controlIdTemp = [];
        controlIdTemp.push("switchFlightDetails", "switchSector", "switchClassOfService", "switchAdditional");

        controlIdTemp.forEach(element => {
            var control = document.getElementById(element);
            control.style.display = "";
        });
    };

    const handleChangeEquipment = (e) => {
        SetEquipment(e.value);
        SetEquipmentLabel(e.label);
    };

    function clearFlightDetailsFields() {
        SetSelectedRph('');
        SetTripType('');
        SetGroup('');
        SetEquipment('');
        SetEquipmentLabel('');
        document.querySelector('#airlinePnr').value = "";
        setMarketingAirlineCode('');
        setMarketingAirlineName('');
        //setValidatingCarrier('');
        document.querySelector('#flightNumber').value = "";
        document.querySelector('#from').value = "";
        document.querySelector('#to').value = "";
        document.querySelector('#calFrom').value = "";
        document.querySelector('#departureTime').value = "";
        document.querySelector('#calTo').value = "";
        document.querySelector('#arrivalTime').value = "";
        SetCabinValue('');
        document.querySelector('#airMiles').value = "";
        SetAdultBaggageCheckin('');
        SetChildBaggageCheckin('');
        SetLapInfantBaggageCheckin('');
        SetSeatInfantBaggageCheckin('');
        SetAdultBaggageCabin('');
        SetChildBaggageCabin('');
        SetLapInfantBaggageCabin('');
        SetSeatInfantBaggageCabin('');
        setFlightStatus('');
        setRbd('');
        setHoursValue('');
        setMinutesValue('');

        if (document.querySelector('.clear-icon') !== null) {
            var controlCount = document.querySelectorAll('.clear-icon');
            controlCount.forEach(element => {
                element.click();
            });

            // if (document.querySelector('#react-select-8-input') !== null) {
            //     document.querySelector('#react-select-8-input').focus();
            // }
        }
    }

    function onEditFlight(rph) {
        //document.querySelector('#airlinePnr').focus();
        SetAdultBaggageCheckin('');
        SetChildBaggageCheckin('');
        SetLapInfantBaggageCheckin('');
        SetSeatInfantBaggageCheckin('');
        SetAdultBaggageCabin('');
        SetChildBaggageCabin('');
        SetLapInfantBaggageCabin('');
        SetSeatInfantBaggageCabin('');

        // if (rph === 1) {
        //     setDisabledValidatingAirline(false);
        // }
        // else {
        //     setDisabledValidatingAirline(true);
        // }

        let showControlIdTemp = [];
        showControlIdTemp.push("switchFlightDetails", "switchSector", "switchClassOfService", "switchAdditional");

        showControlIdTemp.forEach(element => {
            var control = document.getElementById(element);
            control.style.display = "";
        });

        if ((equipment === undefined || equipment === '') && (document.querySelector('#airMiles').value === '')) {
            if (document.getElementById("advanceAdditional").checked) {
                document.getElementById("advanceAdditional").click();
                document.getElementById("divAdditional").style.display = "none";
            }
        }

        setDisabledControl(false);

        if (document.querySelector('#btnAddFlight') !== undefined && document.querySelector('#btnAddFlight') !== null) {
            document.querySelector('#btnAddFlight').innerHTML = "Update";
        }

        const yOffset = -250;
        const element2 = document.getElementById("airlinePnr");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

        let originDestinationOption = props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === rph);

        if (props.airItinerary.airItinerary[0].validatingCarrier !== undefined && props.airItinerary.airItinerary[0].validatingCarrier !== '') {
            setValidatingCarrier(props.airItinerary.airItinerary[0].validatingCarrier);
        }

        SetTripType(originDestinationOption[0].tripType !== undefined && originDestinationOption[0].tripType !== '' ? originDestinationOption[0].tripType : props.airItinerary !== undefined && props.airItinerary.airItinerary[0] !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption[0] !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption[0].tripType !== undefined ? props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption[0].tripType : '');
        SetGroup(originDestinationOption[0].group);
        SetEquipment(originDestinationOption[0].flightSegment[0].equipment[0].airEquipType);
        SetEquipmentLabel(originDestinationOption[0].flightSegment[0].equipment[0].name);
        document.querySelector('#airlinePnr').value = originDestinationOption[0].flightSegment[0].flightPnrNumber !== undefined ? originDestinationOption[0].flightSegment[0].flightPnrNumber : '';
        if (originDestinationOption[0].flightSegment[0].duration !== undefined && originDestinationOption[0].flightSegment[0].duration !== '') {
            let durationTemp = getMinutetoHrsWithoutFormat(originDestinationOption[0].flightSegment[0].duration);
            setHoursValue(durationTemp.split(' ')[0]);
            setMinutesValue(durationTemp.split(' ')[1]);
        }
        setMarketingAirlineCode(originDestinationOption[0].flightSegment[0].marketingAirline.code);
        setMarketingAirlineName(originDestinationOption[0].flightSegment[0].marketingAirline.name !== undefined && originDestinationOption[0].flightSegment[0].marketingAirline.name !== null ? originDestinationOption[0].flightSegment[0].marketingAirline.name.split("(")[0].trim() : '');

        document.querySelector('#from').value = originDestinationOption[0].flightSegment[0].departureAirport.locationName + "(" + originDestinationOption[0].flightSegment[0].departureAirport.locationCode + "), " + originDestinationOption[0].flightSegment[0].departureAirport.countryName + "(" + originDestinationOption[0].flightSegment[0].departureAirport.countryCode + ")";
        document.querySelector('#dvFrom').style.display = "none";
        document.querySelector('#from').style.display = "";
        document.querySelector('#to').value = originDestinationOption[0].flightSegment[0].arrivalAirport.locationName + "(" + originDestinationOption[0].flightSegment[0].arrivalAirport.locationCode + "), " + originDestinationOption[0].flightSegment[0].arrivalAirport.countryName + "(" + originDestinationOption[0].flightSegment[0].arrivalAirport.countryCode + ")";
        document.querySelector('#dvTo').style.display = "none";
        document.querySelector('#to').style.display = "";

        SetDepartureLocationName(originDestinationOption[0].flightSegment[0].departureAirport.locationName);
        SetDepartureLocationCode(originDestinationOption[0].flightSegment[0].departureAirport.locationCode);
        SetDepartureCountryName(originDestinationOption[0].flightSegment[0].departureAirport.countryName);
        SetDepartureCountryCode(originDestinationOption[0].flightSegment[0].departureAirport.countryCode);
        SetDepartureCityName(originDestinationOption[0].flightSegment[0].departureAirport.cityName);
        SetDepartureCityCode(originDestinationOption[0].flightSegment[0].departureAirport.cityCode);

        SetArrivalLocationName(originDestinationOption[0].flightSegment[0].arrivalAirport.locationName);
        SetArrivalLocationCode(originDestinationOption[0].flightSegment[0].arrivalAirport.locationCode);
        SetArrivalCountryName(originDestinationOption[0].flightSegment[0].arrivalAirport.countryName);
        SetArrivalCountryCode(originDestinationOption[0].flightSegment[0].arrivalAirport.countryCode);
        SetArrivalCityName(originDestinationOption[0].flightSegment[0].arrivalAirport.cityName);
        SetArrivalCityCode(originDestinationOption[0].flightSegment[0].arrivalAirport.cityCode);

        document.querySelector('#calFrom').value = getFormatDate(originDestinationOption[0].flightSegment[0].departureDate);
        var todayDate = new Date(getFormatDate(originDestinationOption[0].flightSegment[0].departureDate));
        var currentDate = todayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
        var currentDateTemp = currentDate.split(',')[2];
        document.querySelector('#calFrom').value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
        const fromDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetDepartureDate(fromDateDB);
        document.querySelector('#departureTime').value = originDestinationOption[0].flightSegment[0].departureTime.substring(0, 2) + ":" + originDestinationOption[0].flightSegment[0].departureTime.substring(2, 4);

        document.querySelector('#calTo').value = getFormatDate(originDestinationOption[0].flightSegment[0].arrivalDate);
        var todayDate = new Date(getFormatDate(originDestinationOption[0].flightSegment[0].arrivalDate));
        var currentDate = todayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
        var currentDateTemp = currentDate.split(',')[2];
        document.querySelector('#calTo').value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
        const toDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetArrivalDate(toDateDB);
        document.querySelector('#arrivalTime').value = originDestinationOption[0].flightSegment[0].arrivalTime.substring(0, 2) + ":" + originDestinationOption[0].flightSegment[0].arrivalTime.substring(2, 4);

        document.querySelector('#flightNumber').value = originDestinationOption[0].flightSegment[0].flightNumber;
        document.querySelector('#airMiles').value = originDestinationOption[0].flightSegment[0].flownMileageQty;

        SetCabinValue(originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName);
        SetSelectedRph(rph);
        setFlightStatus(originDestinationOption[0].flightSegment[0].status);
        setRbd(originDestinationOption[0].flightSegment[0].resBookDesigCode);
        // SetBaggageUnitValue(traveler[0].gender);

        let clickControlIdTemp = [];
        clickControlIdTemp.push("advanceFlightDetails", "advanceSector", "advanceClassOfService");

        if ((equipment !== undefined && equipment !== '') || (document.querySelector('#airMiles').value !== '')) {
            clickControlIdTemp.push("advanceAdditional");
        }

        clickControlIdTemp.forEach(element => {
            document.getElementById(element).click();
        });
    }

    function onAddFlight(event) {
        event.preventDefault();

        if (tripType === undefined || tripType === '') {
            setTripTypeInputHasError(true);
            const yOffset = -230;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTripTypeInputHasError(false);
        }

        if (group === undefined || group === '') {
            setGroupInputHasError(true);
            const yOffset = -220;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setGroupInputHasError(false);
        }


        let bookingJSON = sessionStorage.getItem("BookingData") !== null ? JSON.parse(sessionStorage.getItem("BookingData")) : [];
        if (Object.keys(bookingJSON).length > 0 && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined) {
            if (group !== undefined && group !== '' && tripType === 'R' && group === '2' && bookingJSON.data.airItinerary[0].originDestinationOptions === '') {
                setGroupInputHasError(false);
                setGroupInvalidInputHasError(false);
                setGroupSequenceInvalidInputHasError(true);
                const yOffset = -220;
                const element2 = document.getElementById("airlinePnr");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            }
            else if (group !== undefined && group !== '' && tripType === 'R' && group === '2' && bookingJSON.data.airItinerary[0].originDestinationOptions !== '' && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length === 0) {
                setGroupInputHasError(false);
                setGroupInvalidInputHasError(false);
                setGroupSequenceInvalidInputHasError(true);
                const yOffset = -220;
                const element2 = document.getElementById("airlinePnr");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            }
            else if (group !== undefined && group !== '' && tripType === 'R' && group === '2' && bookingJSON.data.airItinerary[0].originDestinationOptions !== '' && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length === 1 && document.querySelector('#btnAddFlight') !== null && document.querySelector('#btnAddFlight').innerHTML === "Update") {
                setGroupInputHasError(false);
                setGroupInvalidInputHasError(false);
                setGroupSequenceInvalidInputHasError(true);
                const yOffset = -220;
                const element2 = document.getElementById("airlinePnr");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            }
            else {
                setGroupSequenceInvalidInputHasError(false);
            }
        }
        else {
            setGroupSequenceInvalidInputHasError(false);
        }

        if (document.querySelector('#airlinePnr').value === '') {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setAirlinePnrInputHasError(true);
            setAirlinePnrInvalidInputHasError(false);
            const yOffset = -170;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setAirlinePnrInputHasError(false);
        }

        if (document.querySelector('#airlinePnr').value !== '' && !document.querySelector('#airlinePnr').value.match('^[a-zA-Z0-9]+$')) {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setAirlinePnrInputHasError(false);
            setAirlinePnrInvalidInputHasError(true);
            const yOffset = -170;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setAirlinePnrInvalidInputHasError(false);
        }

        if (marketingAirlineCode === undefined || marketingAirlineCode === '') {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setMarketingAirlineCodeInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setMarketingAirlineCodeInputHasError(false);
        }

        if (validatingCarrier === undefined || validatingCarrier === '') {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setValidatingCarrierInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setValidatingCarrierInputHasError(false);
        }

        if (document.querySelector('#flightNumber').value === '') {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setFlightNumberInputHasError(true);
            setFlightNumberInvalidInputHasError(false);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFlightNumberInputHasError(false);
        }

        if (document.querySelector('#flightNumber').value !== '' && !document.querySelector('#flightNumber').value.match('^[0-9]+$')) {
            openHandler("divFlightDetails", "lblAdvanceFlightDetails");
            setFlightNumberInputHasError(false);
            setFlightNumberInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFlightNumberInvalidInputHasError(false);
        }

        if (document.querySelector('#from').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setFromInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFromInputHasError(false);
        }

        if (document.querySelector('#to').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setToInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setToInputHasError(false);
        }

        if (document.querySelector('#calFrom').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setCalFromInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setCalFromInputHasError(false);
        }

        if (document.querySelector('#calTo').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setCalToInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setCalToInputHasError(false);
        }

        if (document.querySelector('#calFrom').value !== '' && document.querySelector('#calTo').value !== '') {
            let fromDate = new Date(document.querySelector('#calFrom').value.replace("'", ""));
            let toDate = new Date(document.querySelector('#calTo').value.replace("'", ""));
            if (fromDate > toDate) {
                openHandler("divSector", "lblAdvanceSector");
                setCalToInvalidInputHasError(true);
                const yOffset = -250;
                const element2 = document.getElementById("airMiles");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            }
            else {
                setCalToInvalidInputHasError(false);
            }
        }

        if (document.querySelector('#departureTime').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setDepartureTimeInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setDepartureTimeInputHasError(false);
        }

        if (document.querySelector('#arrivalTime').value === '') {
            openHandler("divSector", "lblAdvanceSector");
            setArrivalTimeInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setArrivalTimeInputHasError(false);
        }

        if (document.querySelector('#calFrom').value !== '' && document.querySelector('#calTo').value !== '' && document.querySelector('#departureTime').value !== '' && document.querySelector('#arrivalTime').value !== '') {
            let fromDate = new Date(document.querySelector('#calFrom').value.replace("'", ""));
            let toDate = new Date(document.querySelector('#calTo').value.replace("'", ""));

            let departureTime = parseInt(document.querySelector('#departureTime').value.replace(':', ''));
            let arrivalTime = parseInt(document.querySelector('#arrivalTime').value.replace(':', ''));

            // if (fromDate.toString() === toDate.toString() && departureTime > arrivalTime) {
            //     openHandler("divSector", "lblAdvanceSector");
            //     setArrivalTimeInvalidInputHasError(true);
            //     const yOffset = -250;
            //     const element2 = document.getElementById("airMiles");
            //     const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //     window.scrollTo({ top: y, behavior: 'smooth' });
            //     return;
            // }
            // else {
            //     setArrivalTimeInvalidInputHasError(false);
            // }
        }

        if (cabinValue === undefined || cabinValue === '') {
            openHandler("divClassOfService", "lblAdvanceClassOfService");
            setCabinCodeInputHasError(true);
            const yOffset = -40;
            const element2 = document.getElementById("airlinePnr");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setCabinCodeInputHasError(false);
        }

        if (rbd === undefined || rbd === '') {
            openHandler("divClassOfService", "lblAdvanceClassOfService");
            setRbdInputHasError(true);
            const yOffset = -40;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setRbdInputHasError(false);
        }

        if (flightStatus === undefined || flightStatus === '' || document.querySelector('#react-select-21-placeholder') !== null) {
            openHandler("divClassOfService", "lblAdvanceClassOfService");
            setFlightStatusInputHasError(true);
            const yOffset = -40;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFlightStatusInputHasError(false);
        }

        // if (equipment === undefined || equipment === '') {
        //     openHandler("divAdditional","lblAdvanceAdditional");
        //     setEquipmentInputHasError(true);
        //     const yOffset = -270;
        //     const element2 = document.getElementById("airMiles");
        //     const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({ top: y, behavior: 'smooth' });
        //     return;
        // }
        // else {
        //     setEquipmentInputHasError(false);
        // }

        if (document.querySelector('#airMiles').value !== '' && !document.querySelector('#airMiles').value.match('^[0-9]+$')) {
            openHandler("divAdditional", "lblAdvanceAdditional");
            setAirMilesInvalidInputHasError(true);
            const yOffset = -270;
            const element2 = document.getElementById("airMiles");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setAirMilesInvalidInputHasError(false);
        }

        // if (hoursValue === undefined || hoursValue === '') {
        //     openHandler("divAdditional", "lblAdvanceAdditional");
        //     setHoursInputHasError(true);
        //     const yOffset = -250;
        //     const element2 = document.getElementById("airMiles");
        //     const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({ top: y, behavior: 'smooth' });
        //     return;
        // }
        // else {
        //     setHoursInputHasError(false);
        // }

        // if (minutesValue === undefined || minutesValue === '') {
        //     openHandler("divAdditional", "lblAdvanceAdditional");
        //     setMinutesInputHasError(true);
        //     const yOffset = -250;
        //     const element2 = document.getElementById("airMiles");
        //     const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({ top: y, behavior: 'smooth' });
        //     return;
        // }
        // else {
        //     setMinutesInputHasError(false);
        // }

        let setValidationStaus = true;

        if (sessionStorage.getItem("BookingData") !== null) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== "") {
                bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                    if (elementOriginDestinationOption.tripType !== undefined && elementOriginDestinationOption.tripType !== '') {
                        if (elementOriginDestinationOption.tripType !== tripType) {
                            setValidationStaus = false;
                            setTripTypeInvalidInputHasError(true);
                            const yOffset = -230;
                            const element2 = document.getElementById("airlinePnr");
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                            throw false;
                        }
                        else {
                            setTripTypeInvalidInputHasError(false);
                        }
                    }

                    if (elementOriginDestinationOption.group === undefined || elementOriginDestinationOption.group === '') {
                        if (group > 1 && elementOriginDestinationOption.rph <= 1) {
                            setValidationStaus = false;
                            setGroupInvalidInputHasError(true);
                            const yOffset = -230;
                            const element2 = document.getElementById("airlinePnr");
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                            throw false;
                        }
                        else {
                            setGroupInvalidInputHasError(false);
                        }
                    }

                    if (elementOriginDestinationOption.group !== undefined && elementOriginDestinationOption.group !== '') {
                        if (tripType === 'O') {
                            if (group > 1) {
                                setValidationStaus = false;
                                setGroupInvalidInputHasError(true);
                                const yOffset = -230;
                                const element2 = document.getElementById("airlinePnr");
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                                throw false;
                            }
                            else {
                                setGroupInvalidInputHasError(false);
                            }
                        }
                        else if (tripType === 'R') {
                            if (group > 2) {
                                setValidationStaus = false;
                                setGroupInvalidInputHasError(true);
                                const yOffset = -230;
                                const element2 = document.getElementById("airlinePnr");
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                                throw false;
                            }
                            else {
                                setGroupInvalidInputHasError(false);
                            }
                        }
                        else {
                            let groupTemp = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length - 1].group; //elementOriginDestinationOption.group;
                            //alert(groupTemp)
                            groupTemp = parseInt(groupTemp) + 1;

                            if (parseInt(elementOriginDestinationOption.group) === parseInt(group)) {
                                setGroupInvalidInputHasError(false);
                            }
                            else if (parseInt(groupTemp) < parseInt(group)) {
                                setValidationStaus = false;
                                setGroupInvalidInputHasError(true);
                                const yOffset = -230;
                                const element2 = document.getElementById("airlinePnr");
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                                throw false;
                            }
                            else {
                                setValidationStaus = true;
                                setGroupInvalidInputHasError(false);
                            }
                        }
                    }

                    // if (elementOriginDestinationOption.tripType !== undefined && elementOriginDestinationOption.tripType !== '' && elementOriginDestinationOption.group !== undefined && elementOriginDestinationOption.group !== '') {
                    //     if (elementOriginDestinationOption.tripType === tripType && elementOriginDestinationOption.group === group) {
                    //         setGroupInvalidInputHasError(true);
                    //         const yOffset = -230;
                    //         const element2 = document.getElementById("airlinePnr");
                    //         const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    //         window.scrollTo({ top: y, behavior: 'smooth' });
                    //         throw false;
                    //     }
                    //     else {
                    //         setGroupInvalidInputHasError(false);
                    //     }
                    // }
                });

                if (document.querySelector('#calFrom').value !== '' && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length > 0 && document.querySelector('#btnAddFlight').innerHTML !== "Update") {
                    let currentFromDate = new Date(document.querySelector('#calFrom').value.replace("'", ""));
                    var dateString = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length - 1].flightSegment[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length - 1].flightSegment.length - 1].arrivalDate;
                    var year = dateString.substring(0, 4);
                    var month = dateString.substring(4, 6);
                    var day = dateString.substring(6, 8);
                    let earlierToDate = new Date(year, month - 1, day);
                    var dateDiffValue = dateDiff(earlierToDate, currentFromDate);

                    if (currentFromDate < earlierToDate) {
                        setCalFromInvalidInputHasError(true);
                        const yOffset = -250;
                        const element2 = document.getElementById("calFrom");
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                        return;
                    }
                    else if (dateDiffValue.years === 0 && dateDiffValue.months === 0 && dateDiffValue.days === 0 && parseInt(document.querySelector('#departureTime').value.replace(':', '')) <= parseInt(bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length - 1].flightSegment[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length - 1].flightSegment.length - 1].arrivalTime)) {
                        setCalFromInvalidInputHasError(true);
                        const yOffset = -250;
                        const element2 = document.getElementById("calFrom");
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                        return;
                    }
                    else {
                        setCalFromInvalidInputHasError(false);
                    }
                }
            }
            else {
                if (group > 1) {
                    setValidationStaus = false;
                    setGroupInvalidInputHasError(true);
                    const yOffset = -230;
                    const element2 = document.getElementById("airlinePnr");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setGroupInvalidInputHasError(false);
                }
            }
        }

        if (setValidationStaus === true) {
            //total duration calculation for imported flight pnr
            let totalOutBoundDuration = 0;
            let totalInBoundDuration = 0;
            let totalItineraryDuration = 0;
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== '') {
                if (tripType === 'O') {
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.group === '1').forEach(elementOriginDestinationOption => {
                        elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                            totalOutBoundDuration = totalOutBoundDuration + parseInt(elementFlightSegment.duration !== undefined && elementFlightSegment.duration !== null && elementFlightSegment.duration !== '' ? elementFlightSegment.duration : 0) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                        });
                    });

                    totalItineraryDuration = parseInt(totalOutBoundDuration);

                    if (bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== null) {
                        if (bookingJSON.data.airItinerary[0].totalOutBoundDuration === '') {
                            bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                        }
                    }
                    else {
                        bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                    }

                    if (bookingJSON.data.airItinerary[0].totalItineraryDuration !== undefined && bookingJSON.data.airItinerary[0].totalItineraryDuration !== null) {
                        if (bookingJSON.data.airItinerary[0].totalItineraryDuration === '') {
                            bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                        }
                    }
                    else {
                        bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                    }
                }
                else if (tripType === 'R') {
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.group === '1').forEach(elementOriginDestinationOption => {
                        elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                            totalOutBoundDuration = totalOutBoundDuration + parseInt(elementFlightSegment.duration !== undefined && elementFlightSegment.duration !== null && elementFlightSegment.duration !== '' ? elementFlightSegment.duration : 0) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                        });
                    });

                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.group === '2').forEach(elementOriginDestinationOption => {
                        elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                            totalInBoundDuration = totalInBoundDuration + parseInt(elementFlightSegment.duration !== undefined && elementFlightSegment.duration !== null && elementFlightSegment.duration !== '' ? elementFlightSegment.duration : 0) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                        });
                    });

                    totalItineraryDuration = parseInt(totalOutBoundDuration) + parseInt(totalInBoundDuration);

                    if (bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== null) {
                        if (bookingJSON.data.airItinerary[0].totalOutBoundDuration === '') {
                            bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                        }
                    }
                    else {
                        bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                    }

                    if (bookingJSON.data.airItinerary[0].totalInBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalInBoundDuration !== null) {
                        if (bookingJSON.data.airItinerary[0].totalInBoundDuration === '') {
                            bookingJSON.data.airItinerary[0].totalInBoundDuration = totalInBoundDuration.toString();
                        }
                    }
                    else {
                        bookingJSON.data.airItinerary[0].totalInBoundDuration = totalInBoundDuration.toString();
                    }

                    if (bookingJSON.data.airItinerary[0].totalItineraryDuration !== undefined && bookingJSON.data.airItinerary[0].totalItineraryDuration !== null) {
                        if (bookingJSON.data.airItinerary[0].totalItineraryDuration === '') {
                            bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                        }
                    }
                    else {
                        bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                    }
                }
                else if (tripType === 'M') {
                    if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== '') {
                        bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                totalOutBoundDuration = totalOutBoundDuration + parseInt(elementFlightSegment.duration !== undefined && elementFlightSegment.duration !== null && elementFlightSegment.duration !== '' ? elementFlightSegment.duration : 0) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                            });
                        });

                        totalItineraryDuration = parseInt(totalOutBoundDuration);

                        if (bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== null) {
                            if (bookingJSON.data.airItinerary[0].totalOutBoundDuration === '') {
                                bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                            }
                        }
                        else {
                            bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                        }

                        if (bookingJSON.data.airItinerary[0].totalItineraryDuration !== undefined && bookingJSON.data.airItinerary[0].totalItineraryDuration !== null) {
                            if (bookingJSON.data.airItinerary[0].totalItineraryDuration === '') {
                                bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                            }
                        }
                        else {
                            bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                        }
                    }
                }

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            }

            if (selectedRph !== undefined && selectedRph !== '') {
                let originDestinationOption = props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === selectedRph.toString());

                if (originDestinationOption.length > 0) {
                    originDestinationOption[0].flightSegment[0].flightRefNumber = selectedRph.toString();
                    originDestinationOption[0].flightSegment[0].flightPnrNumber = document.querySelector('#airlinePnr').value;
                    originDestinationOption[0].flightSegment[0].duration = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                    originDestinationOption[0].flightSegment[0].resBookDesigCode = rbd;

                    let totalOutBoundDuration = 0;
                    let totalInBoundDuration = 0;
                    let totalItineraryDuration = 0;

                    let totalOutBoundDurationTemp = 0;
                    let totalInBoundDurationTemp = 0;

                    let elapsedLayoverTimeOutBoundTemp = 0;
                    let elapsedLayoverTimeInBoundTemp = 0;

                    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

                    if (tripType === "O") {
                        let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                        totalOutBoundDuration = parseInt(durationTemp);

                        props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.rph !== selectedRph.toString()).forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                totalOutBoundDurationTemp = totalOutBoundDurationTemp + parseInt(elementFlightSegment.duration) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                            });
                        });

                        props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.rph === selectedRph.toString()).forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                elapsedLayoverTimeOutBoundTemp = elapsedLayoverTimeOutBoundTemp + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                            });
                        });

                        totalOutBoundDuration = parseInt(totalOutBoundDurationTemp) + parseInt(totalOutBoundDuration) + parseInt(elapsedLayoverTimeOutBoundTemp);
                        bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                    }
                    else if (tripType === "R") {
                        if (group === '1') {
                            let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                            totalOutBoundDuration = parseInt(durationTemp);

                            props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.group === "1" && a.rph !== selectedRph.toString()).forEach(elementOriginDestinationOption => {
                                elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                    totalOutBoundDurationTemp = totalOutBoundDurationTemp + parseInt(elementFlightSegment.duration) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                                });
                            });

                            props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.group === "1" && a.rph === selectedRph.toString()).forEach(elementOriginDestinationOption => {
                                elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                    elapsedLayoverTimeOutBoundTemp = elapsedLayoverTimeOutBoundTemp + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                                });
                            });

                            totalOutBoundDuration = parseInt(totalOutBoundDurationTemp) + parseInt(totalOutBoundDuration) + parseInt(elapsedLayoverTimeOutBoundTemp);
                            bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                        }
                        else {
                            let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                            totalInBoundDuration = parseInt(durationTemp);

                            props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.group === "2" && a.rph !== selectedRph.toString()).forEach(elementOriginDestinationOption => {
                                elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                    totalInBoundDurationTemp = totalInBoundDurationTemp + parseInt(elementFlightSegment.duration) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                                });
                            });

                            props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.group === "2" && a.rph === selectedRph.toString()).forEach(elementOriginDestinationOption => {
                                elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                    elapsedLayoverTimeInBoundTemp = elapsedLayoverTimeInBoundTemp + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                                });
                            });

                            totalInBoundDuration = parseInt(totalInBoundDurationTemp) + parseInt(totalInBoundDuration) + parseInt(elapsedLayoverTimeInBoundTemp);
                            bookingJSON.data.airItinerary[0].totalInBoundDuration = totalInBoundDuration.toString();

                        }
                    }
                    if (tripType === "M") {
                        let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                        totalOutBoundDuration = parseInt(durationTemp);

                        props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.rph !== selectedRph.toString()).forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                totalOutBoundDurationTemp = totalOutBoundDurationTemp + parseInt(elementFlightSegment.duration) + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                            });
                        });

                        props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.tripType === tripType && a.rph === selectedRph.toString()).forEach(elementOriginDestinationOption => {
                            elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                elapsedLayoverTimeOutBoundTemp = elapsedLayoverTimeOutBoundTemp + parseInt(elementFlightSegment.elapsedLayoverTime !== undefined && elementFlightSegment.elapsedLayoverTime !== null && elementFlightSegment.elapsedLayoverTime !== '' ? elementFlightSegment.elapsedLayoverTime : 0);
                            });
                        });

                        totalOutBoundDuration = parseInt(totalOutBoundDurationTemp) + parseInt(totalOutBoundDuration) + parseInt(elapsedLayoverTimeOutBoundTemp);
                        bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();

                    }

                    sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                    totalOutBoundDuration = bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalOutBoundDuration) : 0;
                    totalInBoundDuration = bookingJSON.data.airItinerary[0].totalInBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalInBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalInBoundDuration) : 0;

                    totalItineraryDuration = parseInt(totalOutBoundDuration) + parseInt(totalInBoundDuration);
                    bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();
                    sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

                    props.airItinerary.bookingReferenceNumber = props.airItinerary.bookingReferenceNumber !== undefined && props.airItinerary.bookingReferenceNumber !== null && props.airItinerary.bookingReferenceNumber !== '' ? props.airItinerary.bookingReferenceNumber : '';
                    if (props.airItinerary.bookingReferenceID !== undefined) {
                        props.airItinerary.bookingReferenceID.filter(a => a.type === 'PNRNO')[0].id = sessionStorage.getItem("sourcePNR") !== null ? sessionStorage.getItem("sourcePNR") : '';
                    }
                    originDestinationOption[0].flightSegment[0].marketingAirline.name = marketingAirlineName;
                    originDestinationOption[0].flightSegment[0].marketingAirline.code = marketingAirlineCode;
                    originDestinationOption[0].flightSegment[0].marketingAirline.airlineLogo = marketingAirlineCode + ".gif";
                    originDestinationOption[0].flightSegment[0].operatingAirline.name = marketingAirlineName;
                    originDestinationOption[0].flightSegment[0].operatingAirline.code = marketingAirlineCode;
                    originDestinationOption[0].flightSegment[0].operatingAirline.airlineLogo = marketingAirlineCode + ".gif";

                    originDestinationOption[0].flightSegment[0].departureAirport.locationName = departureLocationName;
                    originDestinationOption[0].flightSegment[0].departureAirport.locationCode = departureLocationCode;
                    originDestinationOption[0].flightSegment[0].departureAirport.cityName = departureCityName;
                    originDestinationOption[0].flightSegment[0].departureAirport.cityCode = departureCityCode;
                    originDestinationOption[0].flightSegment[0].departureAirport.countryName = departureCountryName;
                    originDestinationOption[0].flightSegment[0].departureAirport.countryCode = departureCountryCode;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.locationName = arrivalLocationName;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.locationCode = arrivalLocationCode;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.cityName = arrivalCityName;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.cityCode = arrivalCityCode;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.countryName = arrivalCountryName;
                    originDestinationOption[0].flightSegment[0].arrivalAirport.countryCode = arrivalCountryCode;
                    originDestinationOption[0].flightSegment[0].equipment[0].airEquipType = equipment;
                    originDestinationOption[0].flightSegment[0].equipment[0].name = equipmentLabel;
                    originDestinationOption[0].flightSegment[0].departureDate = departureDate;
                    originDestinationOption[0].flightSegment[0].departureTime = document.querySelector('#departureTime').value.replace(':', '');
                    originDestinationOption[0].flightSegment[0].arrivalDate = arrivalDate;
                    originDestinationOption[0].flightSegment[0].arrivalTime = document.querySelector('#arrivalTime').value.replace(':', '');
                    originDestinationOption[0].flightSegment[0].flightNumber = document.querySelector('#flightNumber').value;
                    originDestinationOption[0].flightSegment[0].flownMileageQty = document.querySelector('#airMiles').value;
                    originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName = cabinValue;
                    originDestinationOption[0].flightSegment[0].status = flightStatus;

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(b => b.type === 'Check-in Allowance')[0] === undefined) && adultBaggageCheckin !== '') {
                        let adultBaggageCheckinUnit = adultBaggageCheckin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance === undefined) {
                            if (adultBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        maxWeight: adultBaggageCheckin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'ADT'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        numberOfPieces: adultBaggageCheckin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'ADT'
                                })
                            }
                        }
                        else {
                            if (adultBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    maxWeight: adultBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    numberOfPieces: adultBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (adultBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces = adultBaggageCheckin;
                    }
                    else if (adultBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight = adultBaggageCheckin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(b => b.type === 'Check-in Allowance')[0] === undefined) && childBaggageCheckin !== '') {
                        let childBaggageCheckinUnit = childBaggageCheckin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance === undefined) {
                            if (childBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        maxWeight: childBaggageCheckin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'CNN'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        numberOfPieces: childBaggageCheckin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'CNN'
                                })
                            }
                        }
                        else {
                            if (childBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    maxWeight: childBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    numberOfPieces: childBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (childBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces = childBaggageCheckin;
                    }
                    else if (childBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight = childBaggageCheckin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(b => b.type === 'Check-in Allowance')[0] === undefined) && lapInfantBaggageCheckin !== '') {
                        let lapInfantBaggageCheckinUnit = lapInfantBaggageCheckin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance === undefined) {
                            if (lapInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        maxWeight: lapInfantBaggageCheckin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INF'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        numberOfPieces: lapInfantBaggageCheckin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INF'
                                })
                            }
                        }
                        else {
                            if (lapInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    maxWeight: lapInfantBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    numberOfPieces: lapInfantBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (lapInfantBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces = lapInfantBaggageCheckin;
                    }
                    else if (lapInfantBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight = lapInfantBaggageCheckin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(b => b.type === 'Check-in Allowance')[0] === undefined) && seatInfantBaggageCheckin !== '') {
                        let seatInfantBaggageCheckinUnit = seatInfantBaggageCheckin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance === undefined) {
                            if (seatInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        maxWeight: seatInfantBaggageCheckin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INS'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'Check-in Allowance',
                                        numberOfPieces: seatInfantBaggageCheckin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INS'
                                })
                            }
                        }
                        else {
                            if (seatInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    maxWeight: seatInfantBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                    type: 'Check-in Allowance',
                                    numberOfPieces: seatInfantBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (seatInfantBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces = seatInfantBaggageCheckin;
                    }
                    else if (seatInfantBaggageCheckin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight = seatInfantBaggageCheckin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(b => b.type === 'for Carry-On Allowances')[0] === undefined) && adultBaggageCabin !== '') {
                        let adultBaggageCabinUnit = adultBaggageCabin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance === undefined) {
                            if (adultBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        maxWeight: adultBaggageCabin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'ADT'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        numberOfPieces: adultBaggageCabin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'ADT'
                                })
                            }
                        }
                        else {
                            if (adultBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    maxWeight: adultBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: adultBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (adultBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces = adultBaggageCabin;
                    }
                    else if (adultBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight = adultBaggageCabin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(b => b.type === 'for Carry-On Allowances')[0] === undefined) && childBaggageCabin !== '') {
                        let childBaggageCabinUnit = childBaggageCabin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance === undefined) {
                            if (childBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        maxWeight: childBaggageCabin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'CNN'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        numberOfPieces: childBaggageCabin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'CNN'
                                })
                            }
                        }
                        else {
                            if (childBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    maxWeight: childBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: childBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (childBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces = childBaggageCabin;
                    }
                    else if (childBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight = childBaggageCabin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(b => b.type === 'for Carry-On Allowances')[0] === undefined) && lapInfantBaggageCabin !== '') {
                        let lapInfantBaggageCabinUnit = lapInfantBaggageCabin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance === undefined) {
                            if (lapInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        maxWeight: lapInfantBaggageCabin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INF'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        numberOfPieces: lapInfantBaggageCabin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INF'
                                })
                            }
                        }
                        else {
                            if (lapInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    maxWeight: lapInfantBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: lapInfantBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (lapInfantBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces = lapInfantBaggageCabin;
                    }
                    else if (lapInfantBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight = lapInfantBaggageCabin;
                    }

                    if ((originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0] === undefined || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(b => b.type === 'for Carry-On Allowances')[0] === undefined) && seatInfantBaggageCabin !== '') {
                        let seatInfantBaggageCabinUnit = seatInfantBaggageCabin.includes("p") === false ? 'kg' : 'piece';

                        if (originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS').length === 0 || originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance === undefined) {
                            if (seatInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        maxWeight: seatInfantBaggageCabin,
                                        unit: 'kg',
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INS'
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.push({
                                    baggageAllowance: [{
                                        type: 'for Carry-On Allowances',
                                        numberOfPieces: seatInfantBaggageCabin,
                                        airlineCode: marketingAirlineCode
                                    }],
                                    type: 'INS'
                                })
                            }
                        }
                        else {
                            if (seatInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    maxWeight: seatInfantBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                })
                            }
                            else {
                                originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: seatInfantBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                })
                            }
                        }
                    }
                    else if (seatInfantBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces = seatInfantBaggageCabin;
                    }
                    else if (seatInfantBaggageCabin !== '' && originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined) {
                        originDestinationOption[0].segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight = seatInfantBaggageCabin;
                    }

                    originDestinationOption[0].directionInd = tripType === 'M' ? 'M' : tripType === 'O' ? 'O' : tripType === 'R' && group === '1' ? 'O' : 'I';
                    originDestinationOption[0].tripType = tripType;
                    originDestinationOption[0].group = group;
                }

                let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                if (bookingJSON.data.bookingReferenceID !== undefined) {
                    bookingJSON.data.bookingReferenceID.filter(a => a.type === 'PNRNO')[0].id = props.airItinerary.bookingReferenceID !== undefined ? props.airItinerary.bookingReferenceID.filter(a => a.type === 'PNRNO')[0].id : '';
                }
                bookingJSON.data.airItinerary[0].originDestinationOptions = props.airItinerary.airItinerary[0].originDestinationOptions;

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

                bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                let groupDuration = [];
                for (var index = 1; index < 9; index++) {
                    let groupDurationTemp = [];
                    groupDurationTemp = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.group === index.toString());

                    if (groupDurationTemp.length > 0) {
                        let durationValue = 0;
                        groupDurationTemp.forEach(elementDurationTemp => {
                            elementDurationTemp.flightSegment.forEach(elementDuration => {
                                durationValue = parseInt(durationValue) + parseInt(elementDuration.duration);
                            });
                        });
                        groupDuration.push(durationValue);
                    }
                }

                let groupDurations = [];
                groupDuration.forEach((value, index) => {
                    let index2 = index + 1;
                    groupDurations.push({
                        id: index2.toString(),
                        duration: value.toString()
                    })
                });

                bookingJSON.data.airItinerary[0].validatingCarrier = validatingCarrier;
                bookingJSON.data.airItinerary[0].groupDurations = groupDurations;

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                history.push(`/ImportPNR`);

                let clickControlIdTemp = [];
                clickControlIdTemp.push("advanceFlightDetails", "advanceSector", "advanceClassOfService");

                if ((equipment !== undefined && equipment !== '') || (document.querySelector('#airMiles').value !== '')) {
                    clickControlIdTemp.push("advanceAdditional");
                }

                clickControlIdTemp.forEach(element => {
                    document.getElementById(element).click();
                });

                clearFlightDetailsFields();

                let controlIdTemp = [];
                controlIdTemp.push("switchFlightDetails", "switchSector", "switchClassOfService", "switchAdditional", "divFlightDetails", "divSector", "divClassOfService", "divAdditional");

                controlIdTemp.forEach(element => {
                    var control = document.getElementById(element);
                    control.style.display = "none";
                });

                const yOffset = -240;
                const element2 = document.getElementById("airMiles");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
            else {
                let rph = 0;
                if (props.airItinerary.airItinerary !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                    props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(element => {
                        rph = element.rph.toString()
                    });
                }

                rph = parseInt(rph) + 1;
                let adultBaggageCheckinUnit = adultBaggageCheckin.includes("p") === false ? 'kg' : 'piece';
                let childBaggageCheckinUnit = childBaggageCheckin.includes("p") === false ? 'kg' : 'piece';
                let lapInfantBaggageCheckinUnit = lapInfantBaggageCheckin.includes("p") === false ? 'kg' : 'piece';
                let seatInfantBaggageCheckinUnit = seatInfantBaggageCheckin.includes("p") === false ? 'kg' : 'piece';

                let adultBaggageCabinUnit = adultBaggageCabin.includes("p") === false ? 'kg' : 'piece';
                let childBaggageCabinUnit = childBaggageCabin.includes("p") === false ? 'kg' : 'piece';
                let lapInfantBaggageCabinUnit = lapInfantBaggageCabin.includes("p") === false ? 'kg' : 'piece';
                let seatInfantBaggageCabinUnit = seatInfantBaggageCabin.includes("p") === false ? 'kg' : 'piece';
                let paxTemp = [];

                if (paxTemp.filter(a => a.type === 'ADT').length === 0 || paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance === undefined) {
                    if (adultBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (adultBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    maxWeight: adultBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'ADT'
                            })
                        }
                    }
                    else {
                        if (adultBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    numberOfPieces: adultBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'ADT'
                            })
                        }
                    }
                }
                else {
                    if (adultBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (adultBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                maxWeight: adultBaggageCheckin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (adultBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                numberOfPieces: adultBaggageCheckin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'CNN').length === 0 || paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance === undefined) {
                    if (childBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (childBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    maxWeight: childBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'CNN'
                            })
                        }
                    }
                    else {
                        if (childBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    numberOfPieces: childBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'CNN'
                            })
                        }
                    }
                }
                else {
                    if (childBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (childBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                maxWeight: childBaggageCheckin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (childBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                numberOfPieces: childBaggageCheckin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'INF').length === 0 || paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance === undefined) {
                    if (lapInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (lapInfantBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    maxWeight: lapInfantBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INF'
                            })
                        }
                    }
                    else {
                        if (lapInfantBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    numberOfPieces: lapInfantBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INF'
                            })
                        }
                    }
                }
                else {
                    if (lapInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (lapInfantBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                maxWeight: lapInfantBaggageCheckin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (lapInfantBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                numberOfPieces: lapInfantBaggageCheckin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'INS').length === 0 || paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance === undefined) {
                    if (seatInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (seatInfantBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    maxWeight: seatInfantBaggageCheckin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INS'
                            })
                        }
                    }
                    else {
                        if (seatInfantBaggageCheckin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'Check-in Allowance',
                                    numberOfPieces: seatInfantBaggageCheckin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INS'
                            })
                        }
                    }
                }
                else {
                    if (seatInfantBaggageCheckinUnit.toLowerCase() === 'kg') {
                        if (seatInfantBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                maxWeight: seatInfantBaggageCheckin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (seatInfantBaggageCheckin !== '') {
                            paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                type: 'Check-in Allowance',
                                numberOfPieces: seatInfantBaggageCheckin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'ADT').length === 0 || paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance === undefined) {
                    if (adultBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (adultBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    maxWeight: adultBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'ADT'
                            })
                        }
                    }
                    else {
                        if (adultBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: adultBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'ADT'
                            })
                        }
                    }
                }
                else {
                    if (adultBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (adultBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                maxWeight: adultBaggageCabin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (adultBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'ADT')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                numberOfPieces: adultBaggageCabin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'CNN').length === 0 || paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance === undefined) {
                    if (childBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (childBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    maxWeight: childBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'CNN'
                            })
                        }
                    }
                    else {
                        if (childBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: childBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'CNN'
                            })
                        }
                    }
                }
                else {
                    if (childBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (childBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                maxWeight: childBaggageCabin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (childBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'CNN')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                numberOfPieces: childBaggageCabin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'INF').length === 0 || paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance === undefined) {
                    if (lapInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (lapInfantBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    maxWeight: lapInfantBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INF'
                            })
                        }
                    }
                    else {
                        if (lapInfantBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: lapInfantBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INF'
                            })
                        }
                    }
                }
                else {
                    if (lapInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (lapInfantBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                maxWeight: lapInfantBaggageCabin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (lapInfantBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'INF')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                numberOfPieces: lapInfantBaggageCabin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                if (paxTemp.filter(a => a.type === 'INS').length === 0 || paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance === undefined) {
                    if (seatInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (seatInfantBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    maxWeight: seatInfantBaggageCabin,
                                    unit: 'kg',
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INS'
                            })
                        }
                    }
                    else {
                        if (seatInfantBaggageCabin !== '') {
                            paxTemp.push({
                                baggageAllowance: [{
                                    type: 'for Carry-On Allowances',
                                    numberOfPieces: seatInfantBaggageCabin,
                                    airlineCode: marketingAirlineCode
                                }],
                                type: 'INS'
                            })
                        }
                    }
                }
                else {
                    if (seatInfantBaggageCabinUnit.toLowerCase() === 'kg') {
                        if (seatInfantBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                maxWeight: seatInfantBaggageCabin,
                                unit: 'kg',
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                    else {
                        if (seatInfantBaggageCabin !== '') {
                            paxTemp.filter(a => a.type === 'INS')[0].baggageAllowance.push({
                                type: 'for Carry-On Allowances',
                                numberOfPieces: seatInfantBaggageCabin,
                                airlineCode: marketingAirlineCode
                            })
                        }
                    }
                }

                let originDestinationOptionTemp = {
                    flightSegment: [{
                        flightRefNumber: rph.toString(),
                        flightPnrNumber: document.querySelector('#airlinePnr').value,
                        duration: getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString(),
                        marketingAirline: {
                            airlineLogo: marketingAirlineCode + ".gif",
                            code: marketingAirlineCode,
                            flightNumber: document.querySelector('#flightNumber').value,
                            name: marketingAirlineName
                        },
                        operatingAirline: {
                            airlineLogo: marketingAirlineCode + ".gif",
                            code: marketingAirlineCode,
                            flightNumber: document.querySelector('#flightNumber').value,
                            name: marketingAirlineName
                        },
                        departureAirport: {
                            locationName: departureLocationName,
                            locationCode: departureLocationCode,
                            cityName: departureCityName,
                            cityCode: departureCityCode,
                            countryName: departureCountryName,
                            countryCode: departureCountryCode
                        },
                        arrivalAirport: {
                            locationName: arrivalLocationName,
                            locationCode: arrivalLocationCode,
                            cityName: arrivalCityName,
                            cityCode: arrivalCityCode,
                            countryName: arrivalCountryName,
                            countryCode: arrivalCountryCode
                        },
                        equipment: [
                            {
                                airEquipType: equipment,
                                name: equipmentLabel
                            }
                        ],
                        departureDate: departureDate,
                        departureTime: document.querySelector('#departureTime').value.replace(':', ''),
                        arrivalDate: arrivalDate,
                        arrivalTime: document.querySelector('#arrivalTime').value.replace(':', ''),
                        flightNumber: document.querySelector('#flightNumber').value,
                        flownMileageQty: document.querySelector('#airMiles').value,
                        bookingClassAvails: [{
                            cabinName: cabinValue
                        }],
                        status: flightStatus,
                        resBookDesigCode: rbd
                    }],
                    segmentRefDetail: [{
                        pax: paxTemp
                    }],

                    directionInd: tripType === 'M' ? 'M' : tripType === 'O' ? 'O' : tripType === 'R' && group === '1' ? 'O' : 'I',
                    tripType: tripType,
                    group: group,
                    rph: rph.toString(),
                }

                let bookingJSON = [];
                if (sessionStorage.getItem("BookingData") !== null) {
                    bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                }
                else {
                    bookingJSON = CreateImportBookingJSON();
                }

                bookingJSON.data.bookingReferenceID.filter(a => a.type === 'PNRNO')[0].id = sessionStorage.getItem("sourcePNR") !== null ? sessionStorage.getItem("sourcePNR") : '';

                let totalOutBoundDuration = 0;
                let totalInBoundDuration = 0;
                let totalItineraryDuration = 0;

                if (bookingJSON.data.airItinerary !== undefined) {
                    if (tripType === "O" || tripType === "M") {
                        let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                        totalOutBoundDuration = bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalOutBoundDuration) + parseInt(durationTemp) : 0 + parseInt(durationTemp);
                        bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                    }
                    else if (tripType === "R") {
                        if (group === 1) {
                            let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                            totalOutBoundDuration = bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalOutBoundDuration) + parseInt(durationTemp) : 0 + parseInt(durationTemp);
                            bookingJSON.data.airItinerary[0].totalOutBoundDuration = totalOutBoundDuration.toString();
                        }
                        else {
                            let durationTemp = getHrstoMinuteWithoutFormat(hoursValue + ":" + minutesValue).toString();
                            totalInBoundDuration = bookingJSON.data.airItinerary[0].totalInBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalInBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalInBoundDuration) + parseInt(durationTemp) : 0 + parseInt(durationTemp);
                            bookingJSON.data.airItinerary[0].totalInBoundDuration = totalInBoundDuration.toString();
                        }
                    }

                    totalOutBoundDuration = bookingJSON.data.airItinerary[0].totalOutBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalOutBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalOutBoundDuration) : 0;
                    totalInBoundDuration = bookingJSON.data.airItinerary[0].totalInBoundDuration !== undefined && bookingJSON.data.airItinerary[0].totalInBoundDuration !== '' ? parseInt(bookingJSON.data.airItinerary[0].totalInBoundDuration) : 0;

                    totalItineraryDuration = parseInt(totalOutBoundDuration) + parseInt(totalInBoundDuration);
                    bookingJSON.data.airItinerary[0].totalItineraryDuration = totalItineraryDuration.toString();

                    if (bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                        bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.push(originDestinationOptionTemp);
                    }
                    else {
                        let originDestinationOption = [];
                        originDestinationOption.push(originDestinationOptionTemp);

                        let originDestinationOptions = {
                            originDestinationOption: originDestinationOption
                        }
                        bookingJSON.data.airItinerary[0].originDestinationOptions = originDestinationOptions;
                    }
                }

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

                bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                let groupDuration = [];
                for (var index = 1; index < 9; index++) {
                    let groupDurationTemp = [];
                    groupDurationTemp = bookingJSON.data.airItinerary !== undefined ? bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.group === index.toString()) : '';

                    if (groupDurationTemp.length > 0) {
                        let durationValue = 0;
                        groupDurationTemp.forEach(elementDurationTemp => {
                            elementDurationTemp.flightSegment.forEach(elementDuration => {
                                durationValue = parseInt(durationValue) + parseInt(elementDuration.duration);
                            });
                        });
                        groupDuration.push(durationValue);
                    }
                }

                let groupDurations = [];
                groupDuration.forEach((value, index) => {
                    let index2 = index + 1;
                    groupDurations.push({
                        id: index2.toString(),
                        duration: value.toString()
                    })
                });

                bookingJSON.data.airItinerary[0].validatingCarrier = validatingCarrier;
                bookingJSON.data.airItinerary[0].groupDurations = groupDurations;

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                history.push(`/ImportPNR`);

                let clickControlIdTemp = [];
                clickControlIdTemp.push("advanceFlightDetails", "advanceSector", "advanceClassOfService");

                if ((equipment !== undefined && equipment !== '') || (document.querySelector('#airMiles').value !== '')) {
                    clickControlIdTemp.push("advanceAdditional");
                }

                clickControlIdTemp.forEach(element => {
                    document.getElementById(element).click();
                });

                clearFlightDetailsFields();

                let controlIdTemp = [];
                controlIdTemp.push("switchFlightDetails", "switchSector", "switchClassOfService", "switchAdditional", "divFlightDetails", "divSector", "divClassOfService", "divAdditional");

                controlIdTemp.forEach(element => {
                    var control = document.getElementById(element);
                    control.style.display = "none";
                });

                const yOffset = -240;
                const element2 = document.getElementById("airMiles");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }

        if (document.querySelector('#btnAddFlight') !== undefined && document.querySelector('#btnAddFlight') !== null) {
            document.querySelector('#btnAddFlight').innerHTML = "&nbsp; &nbsp;&nbsp; Add &nbsp; &nbsp;&nbsp;";
        }
    }

    function onDeleteFlight(rph) {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let bookingJSONModified = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph !== rph);

        let newRph = 1;
        if (bookingJSONModified !== undefined) {
            bookingJSONModified.forEach(element => {
                element.rph = newRph.toString();
                newRph = parseInt(newRph) + 1;
            });
        }

        bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption = bookingJSONModified;
        if (bookingJSON.data.airItinerary[0].originDestinationOptions === '' || bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length === 0) {
            setValidatingCarrier('');
        }

        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        history.push(`/ImportPNR`);
    }

    function onConfirm(rph) {
        SetRphForDelete(rph);
        setConfirm(true);
    }
    function okHandler() {
        onDeleteFlight(rphForDelete);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function dateDiff(targerDate, sourceDate) {
        var year = targerDate.getFullYear();
        var month = targerDate.getMonth() + 1;
        var day = targerDate.getDate();

        var yy = sourceDate.getFullYear();
        var mm = sourceDate.getMonth() + 1;
        var dd = sourceDate.getDate();
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
            months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
            years = years - 1;
            months = months + 12;
        }
        // days
        days = Math.floor((targerDate.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
        //
        return { years: years, months: months, days: days };
    }

    function assignFlightDetails(event) {
        event.preventDefault();
        let setValidationStaus = true;

        if (sessionStorage.getItem("BookingData") === null) {
            setValidationStaus = false;
            setError2({
                title: "Message",
                message: `${t('ErrorMessage')}`,
            });
        }
        else {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (bookingJSON.data.airItinerary === null || bookingJSON.data.airItinerary === undefined || bookingJSON.data.airItinerary[0] === undefined || bookingJSON.data.airItinerary[0].originDestinationOptions === null || bookingJSON.data.airItinerary[0].originDestinationOptions === '' || bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption === undefined || bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.length === 0) {
                setValidationStaus = false;
                setError2({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
        }

        if (sessionStorage.getItem("BookingData") !== null) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (bookingJSON.data.airItinerary !== undefined) {
                if (bookingJSON.data.airItinerary[0].originDestinationOptions !== '') {
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        if (elementOriginDestinationOption.tripType === undefined || elementOriginDestinationOption.tripType === '') {
                            setValidationStaus = false;
                            setError2({
                                title: "Message",
                                message: `${t('validationTripType1')}`,
                            });
                        }
                    });

                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        if (elementOriginDestinationOption.group === undefined || elementOriginDestinationOption.group === '') {
                            setValidationStaus = false;
                            setError2({
                                title: "Message",
                                message: `${t('validationGroup1')}`,
                            });
                        }
                    });

                    let flightStatusTemp = '';
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        if (elementOriginDestinationOption.flightSegment[0].status !== undefined && elementOriginDestinationOption.flightSegment[0].status !== '') {
                            if (flightStatusTemp === '') {
                                flightStatusTemp = elementOriginDestinationOption.flightSegment[0].status;
                            }

                            if (elementOriginDestinationOption.flightSegment[0].status !== flightStatusTemp) {
                                setValidationStaus = false;
                                setError2({
                                    title: "Message",
                                    message: `${t('validationStatus1')}`,
                                });
                            }
                        }
                    });

                    let finalTripType = "";
                    let finalGroupValue = 0;
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        if (elementOriginDestinationOption.tripType !== undefined && elementOriginDestinationOption.tripType !== "" && elementOriginDestinationOption.group !== undefined && elementOriginDestinationOption.group !== "") {
                            if (elementOriginDestinationOption.tripType === "M") {
                                finalTripType = elementOriginDestinationOption.tripType;
                                finalGroupValue = parseInt(elementOriginDestinationOption.group);
                            }
                        }
                    });

                    if (finalTripType === "M" && finalGroupValue < 2) {
                        setValidationStaus = false;
                        setError2({
                            title: "Message",
                            message: `${t('validationGroup2')}`,
                        });
                    }

                    let finalTripTypeRTrip = "";
                    let finalGroupValueRTrip = [];
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        if (elementOriginDestinationOption.tripType !== undefined && elementOriginDestinationOption.tripType !== "" && elementOriginDestinationOption.group !== undefined && elementOriginDestinationOption.group !== "") {
                            if (elementOriginDestinationOption.tripType === "R") {
                                finalTripTypeRTrip = elementOriginDestinationOption.tripType;
                                finalGroupValueRTrip.push(elementOriginDestinationOption.group);
                            }
                        }
                    });

                    if (finalTripTypeRTrip === "R" && (finalGroupValueRTrip.filter(a => a === '1').length === 0 || finalGroupValueRTrip.filter(a => a === '2').length === 0)) {
                        setValidationStaus = false;
                        setError2({
                            title: "Message",
                            message: `${t('validationGroupRTrip')}`,
                        });
                    }

                    if (bookingJSON.data !== undefined && bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined) {
                        var travelingDate = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate;
                        var travelingYear = travelingDate.substring(0, 4);
                        var travelingMonth = travelingDate.substring(4, 6);
                        var travelingDay = travelingDate.substring(6, 8);
                        var formattedTravelingDate = new Date(travelingYear, travelingMonth - 1, travelingDay);

                        bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
                            if (elementTraveler.dateOfBirth !== undefined && elementTraveler.dateOfBirth.date !== undefined && elementTraveler.dateOfBirth !== null && elementTraveler.dateOfBirth.date !== null && elementTraveler.dateOfBirth.date !== '') {
                                var dobDate = elementTraveler.dateOfBirth.date;
                                var dobYear = dobDate.substring(0, 4);
                                var dobMonth = dobDate.substring(4, 6);
                                var dobDay = dobDate.substring(6, 8);
                                var formattedDobDate = new Date(dobYear, dobMonth - 1, dobDay);

                                if (dobYear !== '0000' && dobMonth !== '00' && dobDay !== '00') {
                                    var dateDiffValue = dateDiff(formattedTravelingDate, formattedDobDate);

                                    if (elementTraveler.passengerTypeCode === "ADT") {
                                        if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years >= 12 && (dateDiffValue.months >= 0 || dateDiffValue.days > 0)) {

                                        }
                                        else {
                                            if (setValidationStaus) {
                                                setValidationStaus = false;
                                                setError2({
                                                    title: "Message",
                                                    message: `${t('Date of Birth is not valid for ' + elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname)}`,
                                                });
                                            }
                                        }
                                    }
                                    else if (elementTraveler.passengerTypeCode === "CNN") {
                                        if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years > 2 && dateDiffValue.years < 12) {

                                        }
                                        else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years === 2 && (dateDiffValue.months >= 0 || dateDiffValue.days >= 0)) {

                                        }
                                        else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years === 12 && dateDiffValue.months === 0 && dateDiffValue.days === 0) {

                                        }
                                        else {
                                            if (setValidationStaus) {
                                                setValidationStaus = false;
                                                setError2({
                                                    title: "Message",
                                                    message: `${t('Date of Birth is not valid for ' + elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname)}`,
                                                });
                                            }
                                        }
                                    }
                                    else if (elementTraveler.passengerTypeCode === "INF") {
                                        if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years > 0 && dateDiffValue.years < 2) {

                                        }
                                        else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years === 0 && (dateDiffValue.months > 0 || dateDiffValue.days >= 15)) {

                                        }
                                        else {
                                            if (setValidationStaus) {
                                                setValidationStaus = false;
                                                setError2({
                                                    title: "Message",
                                                    message: `${t('Date of Birth is not valid for ' + elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname)}`,
                                                });
                                            }
                                        }
                                    }
                                    else if (elementTraveler.passengerTypeCode === "INS") {
                                        if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years > 0 && dateDiffValue.years < 2) {

                                        }
                                        else if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && moment(`${dobYear}-${dobMonth}-${dobDay}`) <= new Date() && dateDiffValue.years === 0 && (dateDiffValue.months > 0 || dateDiffValue.days >= 15)) {

                                        }
                                        else {
                                            if (setValidationStaus) {
                                                setValidationStaus = false;
                                                setError2({
                                                    title: "Message",
                                                    message: `${t('Date of Birth is not valid for ' + elementTraveler.personName.givenName + " " + elementTraveler.personName.middleName + " " + elementTraveler.personName.surname)}`,
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }

        if (setValidationStaus) {
            props.onContinueButton("3");
            window.scrollTo({
                top: 350,
                behavior: "smooth",
            });
        }
    }

    const baggageAllowedCheckin = () => {
        setCheckin({
            title: "Add Check-in Baggage",
            message: <div className='col-md-12'>
                <BaggageAllowedCheckin inputData={props} inputRph={selectedRph} adultBaggageCheckin={adultBaggageCheckin} childBaggageCheckin={childBaggageCheckin} lapInfantBaggageCheckin={lapInfantBaggageCheckin} seatInfantBaggageCheckin={seatInfantBaggageCheckin}></BaggageAllowedCheckin>
            </div>,
        });
    }

    const baggageAllowedCabin = () => {
        setCabin({
            title: "Add Cabin Baggage",
            message: <div className='col-md-12'>
                <BaggageAllowedCabin inputData={props} inputRph={selectedRph} adultBaggageCabin={adultBaggageCabin} childBaggageCabin={childBaggageCabin} lapInfantBaggageCabin={lapInfantBaggageCabin} seatInfantBaggageCabin={seatInfantBaggageCabin}></BaggageAllowedCabin>
            </div>,
        });
    }

    const handleOnFromSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                let airportSuggestionTemp = [];
                JSON.parse(localStorage.getItem("airportSuggestion")).forEach(element => {
                    if (element.airportName !== 'All Airports') {
                        airportSuggestionTemp.push(element);
                    }
                });
                setItems(airportSuggestionTemp);
            }
        }
    }

    const handleOnToSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                let airportSuggestionTemp = [];
                JSON.parse(localStorage.getItem("airportSuggestion")).forEach(element => {
                    if (element.airportName !== 'All Airports') {
                        airportSuggestionTemp.push(element);
                    }
                });
                setItems(airportSuggestionTemp);
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.airportCode} - {item.airportName}</span>
            </>
        )
    }

    const handleOnSelectFrom = (item) => {
        document.querySelector('#from').value = item.name;

        SetDepartureLocationName(item.airportName);
        SetDepartureLocationCode(item.airportCode);
        SetDepartureCountryName(item.countryName);
        SetDepartureCountryCode(item.countryCode);
        SetDepartureCityName(item.cityName);
        SetDepartureCityCode(item.cityCode);
    }

    const handleOnSelectTo = (item) => {
        document.querySelector('#to').value = item.name;

        SetArrivalLocationName(item.airportName);
        SetArrivalLocationCode(item.airportCode);
        SetArrivalCountryName(item.countryName);
        SetArrivalCountryCode(item.countryCode);
        SetArrivalCityName(item.cityName);
        SetArrivalCityCode(item.cityCode);
    }

    useEffect(() => {
        const onSearchAirline = ({
            airlineCode: "",
            airlineName: ""
        });

        sendRequestAirline(onSearchAirline);

        const onSearchCommonListRbd = ({
            methodName: "RBD",
            methodCode: "RBD",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListRbd(onSearchCommonListRbd);

        const onSearchEquipment = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "key": "EquipmentList",
            "mode": "DATA"
        });

        sendRequestEquipment(onSearchEquipment);

        const onSearchCommonListFlightStatus = ({
            methodName: "BookingActionList",
            methodCode: "BAL",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListFlightStatus(onSearchCommonListFlightStatus);


        document.querySelector('#from').style.display = "none";
        document.querySelector('#to').style.display = "none";

        if (document.querySelector('[class="rmdp-input"]') !== null) {
            var controlCount = document.querySelectorAll('[class="rmdp-input"]');
            controlCount.forEach(element => {
                element.style.display = "none";
            });
        }

        if (localStorage.getItem("airportSuggestion") != null) {
            let airportSuggestion = JSON.parse(localStorage.getItem("airportSuggestion"));
            airportSuggestion.forEach(element => {
                element.name = element.airportName + "(" + element.airportCode + "), " + element.countryName + "(" + element.countryCode + ")";
            });
            localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestion));
            setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
        }

        var currentDate = fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
        var currentDateTemp = currentDate.split(',')[2];
        document.querySelector('#calFrom').value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
        const fromDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetDepartureDate(fromDateDB);

        var currentDate = toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
        var currentDateTemp = currentDate.split(',')[2];
        document.querySelector('#calTo').value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
        const toDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetArrivalDate(toDateDB);

        let controlIdTemp = [];
        controlIdTemp.push("switchFlightDetails", "switchSector", "switchClassOfService", "switchAdditional", "divFlightDetails", "divSector", "divClassOfService", "divAdditional");

        controlIdTemp.forEach(element => {
            var control = document.getElementById(element);
            control.style.display = "none";
        });

        clearFlightDetailsFields();
    }, [])

    const calGetDateFrom = (inputDate) => {
        document.querySelector('#calFrom').value = moment(new Date(inputDate.target.value)).format("ddd, MMM DD, 'YY");
        const fromDate = new Date(inputDate.target.value.replace("'", ""));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetDepartureDate(fromDateDB);
    }

    const calGetDateTo = (inputDate) => {
        document.querySelector('#calTo').value = moment(new Date(inputDate.target.value)).format("ddd, MMM DD, 'YY");
        const toDate = new Date(inputDate.target.value.replace("'", ""));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        SetArrivalDate(toDateDB);
    }

    const handleFromClick = (event) => {
        document.querySelector('#dvFrom').style.display = "";
        document.querySelector('#from').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount[0].focus();
        }
    }

    const handleToClick = (event) => {
        document.querySelector('#dvTo').style.display = "";
        document.querySelector('#to').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount[1].focus();
        }
    }

    const errorHandlerCheckin = () => {
        setCheckin(null);
        SetAdultBaggageCheckin(document.querySelector('#adultBaggageCheckin').value);
        SetChildBaggageCheckin(document.querySelector('#childBaggageCheckin').value);
        SetLapInfantBaggageCheckin(document.querySelector('#lapInfantBaggageCheckin').value);
        SetSeatInfantBaggageCheckin(document.querySelector('#seatInfantBaggageCheckin').value);
    };

    const errorHandlerCabin = () => {
        setCabin(null);
        SetAdultBaggageCabin(document.querySelector('#adultBaggageCabin').value);
        SetChildBaggageCabin(document.querySelector('#childBaggageCabin').value);
        SetLapInfantBaggageCabin(document.querySelector('#lapInfantBaggageCabin').value);
        SetSeatInfantBaggageCabin(document.querySelector('#seatInfantBaggageCabin').value);
    };

    const marketingAirlineChangeHandler = (event) => {
        if (event.value !== '') {
            setMarketingAirlineCode(event.value);
            setMarketingAirlineName(event.label.split("(")[0].trim());
        }
        else {
            setMarketingAirlineCode('');
            setMarketingAirlineName('');
        }
    };

    const validatingCarrierChangeHandler = (event) => {
        if (event.value !== '') {
            setValidatingCarrier(event.value);
        }
        else {
            setValidatingCarrier('');
        }
    };

    const errorHandler2 = () => {
        setError2(null);
        const yOffset = -250;
        const element2 = document.getElementById("airlinePnr");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const rbdChangeHandler = (event) => {
        if (event.value !== '') {
            setRbd(event.value);
        }
        else {
            setRbd('');
        }
    };

    const flightStatusChangeHandler = (event) => {
        if (event.value !== '') {
            setFlightStatus(event.value);
        }
        else {
            setFlightStatus('');
        }
    };

    const hoursChangeHandler = (event) => {
        if (event.value !== '') {
            setHoursValue(event.value);
        }
        else {
            setHoursValue('');
        }
    };

    const minutesChangeHandler = (event) => {
        if (event.value !== '') {
            setMinutesValue(event.value);
        }
        else {
            setMinutesValue('');
        }
    };

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10 || (e.code !== undefined && e.code.substring(0, e.code.length - 1) !== 'Digit')) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown' && e.code !== 'Tab')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    function toggleHandler(controlId1, controlId2) {
        var control1 = document.getElementById(controlId1);
        var control2 = document.getElementById(controlId2);
        if (control1.style.display === "none") {
            control1.style.display = "";
            control2.classList.remove("fa-plus-square");
            control2.classList.add("fa-minus-square");
        } else {
            control1.style.display = "none";
            control2.classList.add("fa-plus-square");
            control2.classList.remove("fa-minus-square");
        }
    }

    function openHandler(controlId1, controlId2) {
        var control1 = document.getElementById(controlId1);
        var control2 = document.getElementById(controlId2);
        control1.style.display = "";
        control2.classList.remove("fa-plus-square");
        control2.classList.add("fa-minus-square");
    }

    return (
        <Fragment>
            {error2 && (
                <ErrorModal
                    title={error2.title}
                    message={t(error2.message)}
                    onConfirm={errorHandler2}
                ></ErrorModal>
            )}
            {checkin && (
                <MdModal
                    title={checkin.title}
                    message={checkin.message}
                    onConfirm={errorHandlerCheckin}
                ></MdModal>
            )}
            {cabin && (
                <MdModal
                    title={cabin.title}
                    message={cabin.message}
                    onConfirm={errorHandlerCabin}
                ></MdModal>
            )}
            {confirm && (
                <ConfirmationModal
                    title={t('confirmationMessageTitle')}
                    message={t('confirmationMessage')}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            <div className="accordion_flex_area">
                <div className="accordion_left_side position-relative text-muted">
                    <h5>3</h5>
                    <span id="completeThree" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" >
                        <button id="headingThree" className="text-muted accordion-button collapsed" type="button"
                            data-bs-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                            Flight Details
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse"
                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className='row'>
                            <div className="control col-md-6 col-sm-12 col-12">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Trip Type<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={trip_list} onChange={handleChangeTripType}
                                    value={trip_list.filter(option => option.value === tripType)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    focus={true}
                                />
                                {tripTypeInputHasError &&
                                    <p className="error-text">{t('validationTripType')}</p>
                                }
                                {tripTypeInvalidInputHasError &&
                                    <p className="error-text">{t('validationTripTypeInvalidData')}</p>
                                }
                            </div>
                            <div className="control col-md-6 col-sm-12 col-12">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Group<span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={tripType === 'O' ? journey_list_oneway : tripType === 'R' ? journey_list_roundtrip : journey_list_multicity} onChange={handleChangeGroup}
                                    value={tripType === 'O' ? journey_list_oneway.filter(option => option.value === group) : tripType === 'R' ? journey_list_roundtrip.filter(option => option.value === group) : journey_list_multicity.filter(option => option.value === group)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isDisabled={disabledControl}
                                />
                                {groupInputHasError &&
                                    <p className="error-text">{t('validationGroup')}</p>
                                }
                                {groupInvalidInputHasError &&
                                    <p className="error-text">{t('validationGroupInvalidData')}</p>
                                }
                                {groupSequenceInvalidInputHasError &&
                                    <p className="error-text">{t('validationGroupSequenceInvalidData')}</p>
                                }
                            </div>
                        </div>
                        <div className='row'>

                        </div>
                        <div className='row' id='switchFlightDetails'>
                            <div className="control col-md-3 mb-0 mt-2">
                                <div className="form-switch form-switch-sm padding-left-0 mb-2">
                                    <label for="advanceFlightDetails" className="mb-2 h6 text-primary me-2 cursor-pointer"><i id="lblAdvanceFlightDetails" className="fas fa-plus-square"></i> Flight Details</label>
                                    <input id="advanceFlightDetails" className="form-check-input ms-1 d-none" type="checkbox" onChange={() => toggleHandler("divFlightDetails", "lblAdvanceFlightDetails")} />
                                </div>
                            </div>
                        </div>
                        <div className='row' id='divFlightDetails'>
                            <div className="control col-md-2">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Airline PNR <span className="error-icon"> * </span></label>
                                <input type="text" id='airlinePnr' maxLength={8} className="form-control form-control-sm" disabled={disabledControl} />
                                {airlinePnrInputHasError &&
                                    <p className="error-text">{t('validationAirlinePnr')}</p>
                                }
                                {airlinePnrInvalidInputHasError &&
                                    <p className="error-text">{t('validationAirlinePnrInvalidData')}</p>
                                }
                            </div>
                            <div className="control col-md-4">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Marketing Airline<span className="error-icon"> * </span></label>
                                <Dropdown onChange={marketingAirlineChangeHandler}
                                    value={airlineOptions.filter(option => option.value === marketingAirlineCode)}
                                    options={airlineOptions}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                    placeholder="Select" isDisabled={disabledControl}
                                />
                                {marketingAirlineCodeInputHasError &&
                                    <p className="error-text">{t('validationMarketingAirlineCode')}</p>
                                }
                            </div>
                            <div className="control col-md-4">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Validating Airline<span className="error-icon"> * </span></label>
                                {
                                    (selectedRph === undefined || selectedRph === '' || parseInt(selectedRph !== undefined && selectedRph !== '' ? selectedRph : 0) > 1) &&
                                    <Dropdown onChange={validatingCarrierChangeHandler}
                                        value={airlineOptions.filter(option => option.value === validatingCarrier)}
                                        options={airlineOptions}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                        placeholder="Select" isDisabled={disabledValidatingAirline}
                                    />
                                }
                                {
                                    (selectedRph !== undefined && selectedRph === '1') &&
                                    <Dropdown onChange={validatingCarrierChangeHandler}
                                        value={airlineOptions.filter(option => option.value === validatingCarrier)}
                                        options={airlineOptions}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                        placeholder="Select" isDisabled={false}
                                    />
                                }
                                {validatingCarrierInputHasError &&
                                    <p className="error-text">{t('validationValidatingCarrier')}</p>
                                }
                            </div>
                            <div className="control col-md-2">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Flight No. <span className="error-icon"> * </span></label>
                                <input type="number" id='flightNumber' className="form-control form-control-sm" disabled={disabledControl} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                {flightNumberInputHasError &&
                                    <p className="error-text">{t('validationFlightNumber')}</p>
                                }
                                {flightNumberInvalidInputHasError &&
                                    <p className="error-text">{t('validationFlightNumberInvalidData')}</p>
                                }
                            </div>
                        </div>
                        <div className='row' id='switchSector'>
                            <div className="control col-md-3 mb-0 mt-2">
                                <div className="form-switch form-switch-sm padding-left-0 mb-2">
                                    <label for="advanceSector" className="mb-2 h6 text-primary me-2 cursor-pointer"><i id="lblAdvanceSector" className="fas fa-plus-square"></i> Sector</label>
                                    <input id="advanceSector" className="form-check-input ms-1 d-none" type="checkbox" onChange={() => toggleHandler("divSector", "lblAdvanceSector")} />
                                </div>
                            </div>
                        </div>
                        <div className='row' id='divSector'>
                            <div className="control col-md-6 dropdown-overflow">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">From <span className="error-icon"> * </span></label>
                                <input type="text" id='from' className="form-control form-control-sm" onClick={handleFromClick} />
                                <div id={'dvFrom'} >
                                    {
                                        disabledControl &&
                                        <input type="text" className="form-control form-control-sm" disabled={disabledControl} />
                                    }
                                    {
                                        !disabledControl &&
                                        <ReactSearchAutocomplete
                                            onSearch={handleOnFromSearch}
                                            items={items}
                                            formatResult={formatResult}
                                            //autoFocus
                                            onSelect={handleOnSelectFrom}
                                            showNoResults={false}
                                            maxResults={10}
                                            //showNoResultsText={'Loading...'}
                                            //inputDebounce={50}
                                            fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                            resultStringKeyName="name"
                                        />
                                    }
                                </div>
                                {fromInputHasError &&
                                    <p className="error-text">{t('validationFrom')}</p>
                                }
                            </div>
                            <div className="control col-md-6 dropdown-overflow">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">To <span className="error-icon"> * </span></label>
                                <input type="text" id='to' className="form-control form-control-sm" onClick={handleToClick} />
                                <div id={'dvTo'} >
                                    {
                                        disabledControl &&
                                        <input type="text" className="form-control form-control-sm" disabled={disabledControl} />
                                    }
                                    {
                                        !disabledControl &&
                                        <ReactSearchAutocomplete
                                            onSearch={handleOnToSearch}
                                            items={items}
                                            formatResult={formatResult}
                                            //autoFocus
                                            onSelect={handleOnSelectTo}
                                            showNoResults={false}
                                            maxResults={10}
                                            //showNoResultsText={'Loading...'}
                                            //inputDebounce={50}
                                            fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                            resultStringKeyName="name"
                                        />
                                    }
                                </div>
                                {toInputHasError &&
                                    <p className="error-text">{t('validationTo')}</p>
                                }
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 ">
                                <label htmlFor="calFrom" className="mb-2 h6 text-primary">Departure Date and Time <span className="error-icon"> * </span></label>
                                <DateRangePicker
                                    //onApply={calGetDateFrom}
                                    onHide={calGetDateFrom}
                                    initialSettings={{
                                        startDate: document.querySelector('#calFrom') === null ? fromTodayDate : new Date(document.querySelector('#calFrom').value.replace("'", "")), endDate: '', singleDatePicker: true,
                                        showDropdowns: true, autoApply: true, minDate: new Date()
                                    }} >
                                    <input id={'calFrom'} type="text" autoComplete='off' className='form-control form-control-sm' disabled={disabledControl} />
                                </DateRangePicker>
                                {calFromInputHasError &&
                                    <p className="error-text">{t('validationCalFrom')}</p>
                                }
                                {calFromInvalidInputHasError &&
                                    <p className="error-text">{t('validationCalFromInvalidData')}</p>
                                }
                            </div>
                            <div className="control col-lg-2 col-md-6 col-sm-4">
                                <label htmlFor="departureTime" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                <input type="time" id='departureTime' className="form-control form-control-sm" disabled={disabledControl} />
                                {departureTimeInputHasError &&
                                    <p className="error-text">{t('validationDepartureTime')}</p>
                                }
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 ">
                                <label htmlFor="calTo" className="mb-2 h6 text-primary">Arrival Date and Time <span className="error-icon"> * </span></label>
                                <DateRangePicker
                                    //onApply={calGetDateTo}
                                    onHide={calGetDateTo}
                                    initialSettings={{
                                        startDate: document.querySelector('#calTo') === null ? toTodayDate : new Date(document.querySelector('#calTo').value.replace("'", "")), endDate: '', singleDatePicker: true,
                                        showDropdowns: true, autoApply: true, minDate: new Date()
                                    }} >
                                    <input id={'calTo'} type="text" autoComplete='off' className='form-control form-control-sm' disabled={disabledControl} />
                                </DateRangePicker>
                                {calToInputHasError &&
                                    <p className="error-text">{t('validationCalTo')}</p>
                                }
                                {calToInvalidInputHasError &&
                                    <p className="error-text">{t('validationCalToInvalidData')}</p>
                                }
                            </div>
                            <div className="control col-lg-2 col-md-6 col-sm-4">
                                <label htmlFor="arrivalTime" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                <input type="time" id='arrivalTime' className="form-control form-control-sm" disabled={disabledControl} />
                                {arrivalTimeInputHasError &&
                                    <p className="error-text">{t('validationArrivalTime')}</p>
                                }
                                {arrivalTimeInvalidInputHasError &&
                                    <p className="error-text">{t('validationArrivalTimeInvalidData')}</p>
                                }
                            </div>
                        </div>
                        <div className='row' id='switchClassOfService'>
                            <div className="control col-md-3 mb-0 mt-2">
                                <div className="form-switch form-switch-sm padding-left-0 mb-2">
                                    <label for="advanceClassOfService" className="mb-2 h6 text-primary me-2 cursor-pointer"><i id="lblAdvanceClassOfService" className="fas fa-plus-square"></i> Class of Service</label>
                                    <input id="advanceClassOfService" className="form-check-input ms-1 d-none" type="checkbox" onChange={() => toggleHandler("divClassOfService", "lblAdvanceClassOfService")} />
                                </div>
                            </div>
                        </div>
                        <div className='row' id='divClassOfService'>
                            <div className="control col-md-4">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Cabin <span className="error-icon"> * </span></label>
                                <Dropdown
                                    options={cabin_list} onChange={handleChangeCabin}
                                    value={cabin_list.filter(option => toPascalCase(option.value) === toPascalCase(cabinValue))}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isDisabled={disabledControl}
                                />
                                {cabinCodeInputHasError &&
                                    <p className="error-text">{t('validationCabinCode')}</p>
                                }
                            </div>
                            <div className="control col-md-4">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">RBD <span className="error-icon"> * </span></label>
                                <Dropdown onChange={rbdChangeHandler}
                                    value={rbdOptions.filter(option => option.value === rbd)}
                                    options={rbdOptions}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                    placeholder="Select" isDisabled={disabledControl}
                                />
                                {rbdInputHasError &&
                                    <p className="error-text">{t('validationRbd')}</p>
                                }
                            </div>
                            <div className="control col-md-4">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Status <span className="error-icon"> * </span></label>
                                <Dropdown onChange={flightStatusChangeHandler}
                                    value={flightStatusOptions.filter(option => option.value === flightStatus)}
                                    options={flightStatusOptions}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                    placeholder="Select" isDisabled={disabledControl}
                                />
                                {flightStatusInputHasError &&
                                    <p className="error-text">{t('validationStatus')}</p>
                                }
                            </div>
                        </div>
                        <div className='row' id='switchAdditional'>
                            <div className="control col-md-4 mb-0 mt-2">
                                <div className="form-switch form-switch-sm padding-left-0 mb-2">
                                    <label for="advanceAdditional" className="mb-2 h6 text-primary me-2 cursor-pointer"><i id="lblAdvanceAdditional" className="fas fa-plus-square"></i> Additional (Optional)</label>
                                    <input id="advanceAdditional" className="form-check-input ms-1 d-none" type="checkbox" onChange={() => toggleHandler("divAdditional", "lblAdvanceAdditional")} />
                                </div>
                            </div>
                        </div>
                        <div className='row' id='divAdditional'>
                            <div className="control col-md-6">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Equipment<span className="error-icon">  </span></label>
                                <Dropdown
                                    options={equipmentOptions} onChange={handleChangeEquipment}
                                    value={equipmentOptions.filter(option => option.value === equipment)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isDisabled={disabledControl}
                                />
                                {equipmentInputHasError &&
                                    <p className="error-text">{t('validationEquipment')}</p>
                                }
                            </div>
                            <div className="control col-md-6">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Air Miles</label>
                                <input type="number" id='airMiles' className="form-control form-control-sm" disabled={disabledControl} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                {airMilesInvalidInputHasError &&
                                    <p className="error-text">{t('validationAirMilesInvalidData')}</p>
                                }
                            </div>
                            <div className='control col-md-6'>
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Duration (Hours) <span className="error-icon">  </span> </label>
                                <Dropdown
                                    options={hoursList} onChange={hoursChangeHandler}
                                    value={hoursList.filter(option => option.value === hoursValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isDisabled={disabledControl}
                                />
                                {hoursInputHasError &&
                                    <p className="error-text">{t('validationHours')}</p>
                                }
                            </div>
                            <div className='control col-md-6'>
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Duration (Minutes) <span className="error-icon">  </span> </label>
                                <Dropdown
                                    options={minutesList} onChange={minutesChangeHandler}
                                    value={minutesList.filter(option => option.value === minutesValue)}
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isDisabled={disabledControl}
                                />
                                {minutesInputHasError &&
                                    <p className="error-text">{t('validationMinutes')}</p>
                                }
                            </div>
                            <div className='row'>
                                <div className="control col-md-12">
                                    <div className='my-auto'>
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary">&nbsp;</label>
                                        <button className="btn btn_theme btn_sm" onClick={baggageAllowedCheckin}><i className="fas fa-plus-circle"></i> Add Check-in Baggage</button>
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary">&nbsp;</label>
                                        <button className="btn btn_theme btn_sm mx-2" onClick={baggageAllowedCabin}><i className="fas fa-plus-circle"></i> Add Cabin Baggage</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="control col-md-12">
                                <label htmlFor="bottomText" className="mb-2 h6 text-primary">&nbsp;</label>
                                <button id='btnAddFlight' className="btn  text-white gt-main-btn ms-1 btn-sm float-lg-end float-md-end" onClick={onAddFlight}>&nbsp;  &nbsp; &nbsp;Add&nbsp; &nbsp;  &nbsp;</button>
                            </div>
                        </div>
                        <hr className=''></hr>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className=' table-responsive mt-2 table-wrapper width-900px'>
                                    {
                                        props.airItinerary.airItinerary !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined &&
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className='w-15' scope='col'>Trip Type</th>
                                                    <th className='min-width-125' scope='col'>Group</th>
                                                    <th className='w-15' scope="col">Airline PNR</th>
                                                    <th className="min-width-185" scope="col">Marketing Airline</th>
                                                    {/* <th className="min-width-185" scope="col">Validating Airline</th> */}
                                                    <th className='w-15' scope="col">Flight No.</th>
                                                    <th scope="col" className="min-width-210">From</th>
                                                    <th scope="col" className="min-width-210">To</th>
                                                    <th scope="col" className="min-width-185" >Departure Date and Time</th>
                                                    {/* <th scope="col" className="min-width-185" >Arrival Date and Time</th> */}
                                                    <th scope="col" className="min-width-185" >Cabin</th>
                                                    {/* <th scope="col">RBD</th> */}
                                                    <th scope="col" className="min-width-185">Status</th>
                                                    {/* <th className='w-15' scope="col">Equipment</th>
                                                    <th scope="col" className='w-15'>Air Miles</th>
                                                    <th scope="col" className='w-15'>Duration</th>
                                                    <th scope="col" className="min-width-185">Baggage Allowance</th> */}
                                                    <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined && props.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.map((originDestinationOption) => (
                                                        <tr>
                                                            <td>{originDestinationOption.tripType !== undefined && originDestinationOption.tripType !== '' ? originDestinationOption.tripType === 'O' ? 'One Way' : originDestinationOption.tripType === 'R' ? 'Round Trip' : 'Multi City' : ''}</td>
                                                            <td>{originDestinationOption.tripType !== undefined && originDestinationOption.tripType !== '' && originDestinationOption.group !== undefined && originDestinationOption.group !== null && originDestinationOption.group !== '' ? originDestinationOption.tripType === 'O' ? journey_list_oneway.filter(option => option.value === originDestinationOption.group)[0].label : originDestinationOption.tripType === 'R' ? journey_list_roundtrip.filter(option => option.value === originDestinationOption.group)[0].label : journey_list_multicity.filter(option => option.value === originDestinationOption.group)[0].label : originDestinationOption.group}</td>
                                                            <td>{originDestinationOption.flightSegment[0].flightPnrNumber}</td>
                                                            <td>{originDestinationOption.flightSegment[0].marketingAirline.name !== undefined && originDestinationOption.flightSegment[0].marketingAirline.name !== null && originDestinationOption.flightSegment[0].marketingAirline.code !== undefined && originDestinationOption.flightSegment[0].marketingAirline.code !== null ? originDestinationOption.flightSegment[0].marketingAirline.name + " (" + originDestinationOption.flightSegment[0].marketingAirline.code + ") " : ''}</td>
                                                            {/* <td>{props.airItinerary.airItinerary[0].validatingCarrier !== undefined ? airlineOptions.filter(option => option.value === props.airItinerary.airItinerary[0].validatingCarrier)[0] !== undefined ? airlineOptions.filter(option => option.value === props.airItinerary.airItinerary[0].validatingCarrier)[0].label : '' : ''}</td> */}
                                                            <td>{originDestinationOption.flightSegment[0].flightNumber}</td>
                                                            <td>{originDestinationOption.flightSegment[0].departureAirport.locationName + "(" + originDestinationOption.flightSegment[0].departureAirport.locationCode + ")" + ", " + originDestinationOption.flightSegment[0].departureAirport.countryName + "(" + originDestinationOption.flightSegment[0].departureAirport.countryCode + ")"}</td>
                                                            <td>{originDestinationOption.flightSegment[0].arrivalAirport.locationName + "(" + originDestinationOption.flightSegment[0].arrivalAirport.locationCode + ")" + ", " + originDestinationOption.flightSegment[0].arrivalAirport.countryName + "(" + originDestinationOption.flightSegment[0].arrivalAirport.countryCode + ")"}</td>
                                                            <td>{getFormatDate(originDestinationOption.flightSegment[0].departureDate) + ", " + getFormatTime(originDestinationOption.flightSegment[0].departureTime)}</td>
                                                            {/* <td>{getFormatDate(originDestinationOption.flightSegment[0].arrivalDate) + " " + getFormatTime(originDestinationOption.flightSegment[0].arrivalTime)}</td> */}
                                                            <td>{originDestinationOption.flightSegment[0].bookingClassAvails[0].cabinName}</td>
                                                            {/* <td>{originDestinationOption.flightSegment[0].resBookDesigCode}</td> */}
                                                            <td>{originDestinationOption.flightSegment[0].status !== undefined && originDestinationOption.flightSegment[0].status !== '' ? flightStatusOptions.filter(option => option.value === originDestinationOption.flightSegment[0].status)[0] !== undefined ? flightStatusOptions.filter(option => option.value === originDestinationOption.flightSegment[0].status)[0].label : '' : ''}</td>
                                                            {/* <td>{originDestinationOption.flightSegment[0].equipment !== undefined && originDestinationOption.flightSegment[0].equipment[0].airEquipType !== undefined && equipmentOptions.filter(option => option.value === originDestinationOption.flightSegment[0].equipment[0].airEquipType)[0] !== undefined ? equipmentOptions.filter(option => option.value === originDestinationOption.flightSegment[0].equipment[0].airEquipType)[0].label : ''}</td>
                                                            <td>{originDestinationOption.flightSegment[0].flownMileageQty}</td>
                                                            <td align={'right'}>{originDestinationOption.flightSegment[0].duration !== '0' ? originDestinationOption.flightSegment[0].duration : ''}</td>
                                                            {
                                                                originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] !== undefined &&
                                                                <td>Adult: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].unit}
                                                                    <br></br>   Child: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].unit}
                                                                    <br></br>   Infant: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance[0].unit}</td>
                                                            }
                                                            {
                                                                originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] === undefined &&
                                                                <td>Adult: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].unit}
                                                                    <br></br>   Child: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance[0].unit}</td>
                                                            }
                                                            {
                                                                originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] === undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] === undefined &&
                                                                <td>Adult: {originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].numberOfPieces + " piece" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].maxWeight + " " + originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance[0].unit}</td>
                                                            }
                                                            {
                                                                originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] === undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] === undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] === undefined &&
                                                                <td></td>
                                                            } */}

                                                            <td className='text-center sticky-col last-col bg-light'>
                                                                <div className="text-center">
                                                                    <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" onClick={() => onEditFlight(originDestinationOption.rph)}><i className="fa-solid fa-pen"></i></a>
                                                                    <button className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" onClick={() => onConfirm(originDestinationOption.rph)}><i className="fas fa-trash-alt"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="float-md-end float-lg-end">
                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                    <button type="button" onClick={assignFlightDetails} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Fragment>)
}
export default FlightDetails
