// import { Fragment } from 'react';
import { Fragment,useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import PopupModal from '../../components/Common/Flight/PrintPopupModal';
import { Link, animateScroll as scroll } from "react-scroll";
import HotelImagePopup from '../HotelDetails/Components/HotelImagePopup'
const HotelConfirmation = () => {
    useDocumentTitle('Hotel Search')
    const [error1, setError] = useState();
    const errorHandler = () => {
        setError(null);
   
    };

    const HotelImage = () => {
        setError({
            title: "Fare Rules",
            message: <div className=''>
                 <header className='header'>
                    <h4 className="text-white">Bulgari Hotel London</h4>
                </header>
                <div className='content'>
                    <p>rjhtgjk</p>
                </div>
                {/* Bulgari Hotel London 
                <div className='gt-h-400px'>
                    <div className='row'>
                        
                     
                        <div className='text-12px'>
                            note - the following text is informational and not validated for autopricing.
                            air canada restricted fare //-a-// application area these fares apply from
                            area 2area 3 to area 1. class of service these fares apply for economy class
                            service. capacity limitations the carrier shall limit the number of passengers
                            carried on any one flight at fares governed by this rule and such fares will
                            not necessarily be available on all fights. the number of seats, which the
                            cartier shall make available on a given flight, will be determined by the
                            carrier's best judgement. other conditions sequential use of flight coupons
                            ~ this fare is only valid if the flights are taken in the booked sequence.
                            ‘otherwise the fare will be recalculated based on the actual flight routing.

                        </div>

                    </div>

                </div> */}

            </div>,
            // onConfirm={errorHandler}
        });
    }
    return <Fragment>
        {error1 && (
            <HotelImagePopup
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></HotelImagePopup>
        )}


<section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-3">
                                <div className="col-lg-12">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Booking Details <span className="badge h6 rounded-pill mb-0 bg-primary"><i className="fa-solid fa-circle-check"></i> <span className="ms-1"></span>  Booking Request</span></label>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='float-lg-end'>
                                                
                                                <span id="sendMail" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end'><i className="fas fa-envelope"></i>
                                                </span>
                                                <span id="Print" className="btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end"><i className="fa-solid fa-print"></i></span>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-12'>
                                                <div className='col-md-12'>
                                                    <div className='text-muted h6 mb-2 mt-2'><b className='text-primary'>Confirmation No. </b> <span className='text-black'>122345</span>
                                                            <span> | <b className='text-primary'>PNR </b> <span className='text-black'>12344</span></span> </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list mt-1">
                                            <div  className="sort-view clearfix">
                                            </div>
                                        
                                            <div  className="hotel-list-cn clearfix">
                                                <div  className="hotel-list-item position-relative">
                                                    {/* <figure  className="hotel-img float-left">
                                                        <a href="hotel-detail.html" title="">
                                                            <img src="\.\./\.\./\.\./assets/images/hotel/hotel-bg2.png" alt="" />
                                                        </a>
                                                    </figure> */}
                                                    {/* <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span> */}
                                                    <div  className="hotel-text m-0">
                                                        <div  className="hotel-name">
                                                            <a href="#" title="" className='text-primary fw-bold'>Yotel London City 
                                                            {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                            </a>

                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='text-14px my-3'>96-100 Clerkenwell Road, London, England, EC1M 5RJ</div>
                                                                    
                                                                </div>
                                                                {/* <div className="col-md-12 mb-2"><span className="text-muted">Room Type:</span><span className="text-primary fw-bold ms-1">Deluxe</span></div> */}
                                                                <div className='col-md-12'>
                                                                Year Built - 1928 Year Remodeled - 2019 Additional Property Description - Built in 1927 and boasting renovations that have kept its rooms up to date, the Paramount Hotel is a grand feature of Times Square. With great flair and thoughtful amenities, our rooms offer a comfortable stay featuring flat-screen TVs, high-speed WiFi, and more. Add to that our sumptuous pillow-top mattresses and high-quality bedding, and you have the ingredients for the perfect New York getaway.The Paramount puts you within easy walking distance of some of the worlds most famous sights, museums, and shopping. You will also appreciate the offerings found right here in our hotel: The Paramount Cafe & Bar offers delicious cocktails and bites as well as table games for a lively atmosphere. Sony Hall offers a full menu of dinner and drink options and live performances by well-known bands, musicians, and DJs.Hotel is adhering to AHLA Cleaning Standards and Stay Safe guidelines. For specific details on hotels cleaning, health, and wellness policy please visit the hotel website.
                                                                </div>
                                                                {/* <div  className="col-md-4 hotel-service text-end">
                                                                    <div  className="price special-price text-primary fw-bold h3 mb-0">$1,345</div>
                                                                    <div className='text-muted text-10px'>Avg Per Night <br></br>Including Taxes</div>
                                                                
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        
                                                    
                                                        </div>
                                                        
                                                        
                                                        <hr className='my-1'></hr>
                                                        <div className='row'>
                                                            <div className='col-md-12 my-auto'>
                                                                {/* <div className='text-14px mt-2 text-muted'>
                                                                    <span>1 Room,</span>
                                                                    <span className='ms-1'>2 Nights</span>
                                                                    <span> stay (Taxes & fees included)</span>
                                                                </div> */}
                                                                {/* <div className="col-md-12 text-14px text-muted pb-2 ms-2"> Source: <span className="text-primary fw-bold">Sabre</span> | Commission:  <span className="text-primary fw-bold">$9.48</span></div> */}
                                                                <div className='mb-2'>
                                                                <span className="text-14px text-sky-blue ms-2 my-2 "><i className="fas fa-money-check-alt"></i>  Refundable</span> 
                                                                </div>
                                                            </div>
                                                        {/* <div className='col-md-4 text-end'>  
                                                            <button className="my-2 btn btn_theme btn_sm">Book Now</button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tour_details_boxed_inner">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion_flex_area">
                                                    <div className="accordion-item">
                                                        <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                                                        Booking Details
                                                        </h5>
                                                        <div id="collapsesix" className="accordion-collapse collapse show">
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='row'>
                                                                        <div className="col-md-2"><div className='h6 mb-2 text-primary'>Check-in</div> </div>
                                                                        <div className="col-md-10"><div className='h6 mb-2'> 8 June 2023, 12:00 PM </div> </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <div className='row'>
                                                                        <div className="col-md-2">
                                                                            <div className='h6 text-primary'> Check-out</div> 
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className='h6'>9 June 2023, 10:00 AM </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <div className='row'>
                                                                        <div className="col-md-2">
                                                                            <div className='h6 text-primary'>Total length of stay</div> 
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className='h6'>1 night </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <hr className='my-2'></hr>    
                                            <div className="accordion_flex_area">
                                                
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                        Price
                                                    </h5>
                                                    
                                                    <div className="mt-3">
                                                        <label className="form-check-label text-14px " for="flexCheckDefaultf1">
                                                            <span className="area_flex_one text-muted">

                                                                <span className="fw-bold h6">2 X Deluxe</span>
                                                                {/* <span className="text-12px"> $200 </span> */}
                                                            </span>
                                                        </label>

                                                        <div className='row'>
                                                            <div className='col-md-8'>Base Rate</div>
                                                            <div className='col-md-4 text-end'>$400</div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-8'>Taxes</div>
                                                            <div className='col-md-4 text-end'>$400</div>
                                                        </div>
                                                        <hr className='my-1'></hr>
                                                        <div className='row'>
                                                            <div className='col-md-8 h6 fw-bold text-primary'>Rate</div>
                                                            <div className='col-md-4 text-end h6 fw-bold'>$800</div>
                                                        </div>
                                                        {/* <label className="form-check-label text-14px mt-2" for="flexCheckDefaultf1">
                                                            <span className="area_flex_one text-muted">

                                                                <span className="fw-bold h6">Room 2 </span>
                                                                
                                                            </span>
                                                        </label>
                                                        <div className='row'>
                                                            <div className='col-md-8'>Base Rate</div>
                                                            <div className='col-md-4 text-end'>$200</div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-8'>Taxes</div>
                                                            <div className='col-md-4 text-end'>$200</div>
                                                        </div>
                                                        <hr className='my-1'></hr>
                                                        <div className='row'>
                                                            <div className='col-md-8 h6 text-primary fw-bold'>Rate</div>
                                                            <div className='col-md-4 text-end h6 fw-bold'>$400</div>
                                                        </div> */}
                                                        
                                                        <div className='row mt-2'>
                                                            <div className="tour_details_boxed_inner fare-summary-pop">
                                                                <div className="accordion" id="accordionExample">

                                                                    <div className="tour_details_boxed_inner fare-summary">
                                                                        <div className="accordion" id="accordionExample">
                                                                            <div className="accordion_flex_area">
                                                                                <div className="accordion-item fare-toggle-btn">
                                                                                    <h2 className="accordion-header" id="headingthreee">
                                                                                        <button className="accordion-button collapsed text-16px" type="button" data-bs-toggle="collapse" data-bs-target="#collapselast" aria-expanded="false" aria-controls="collapselast">
                                                                                            <div className='ms-4 col-md-7 h6 my-auto'> Others  </div>
                                                                                            <div className='right-0 position-absolute h6'>$90</div>
                                                                                        </button>
                                                                                        
                                                                                    </h2>
                                                                                    <div id="collapselast" className="accordion-collapse collapse"
                                                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                                        <div className="accordion-body">
                                                                                            <div className="accordion_itinerary_list">
                                                                                                <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                                                                    <span className="area_flex_one ">
                                                                                                        <span>Markup</span>
                                                                                                        <span className='h6'>$90</span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='my-2'></hr>
                                                        <div className="row"><div className="col-md-6"><div className="h5 mb-1 fw-bold text-primary">Total Rate </div></div><div className="col-md-6"><div className="h5 mb-2 fw-bold text-primary text-end float-lg-end">$890</div></div></div>

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                                                        Booking Contact 
                                                    </h5>
                                                    <div id="collapsesix" className="accordion-collapse collapse show">
                                                        <div className='row my-2'>
                                                            <div className='col-md-12'>
                                                                <div className='row'>
                                                                    <div className="col-md-2"><div className='h6 mb-2 text-primary'> Mobile No. </div> </div>
                                                                    <div className="col-md-10"><div className='h6 mb-2'>12698234651 </div> </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className='row'>
                                                                    <div className="col-md-2">
                                                                        <div className='h6 mb-2 text-primary'> Email</div> 
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className='h6 mb-2'>lorem@gmail.com</div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold mb-3" id="headingTwo">
                                                        Guest
                                                    </h5>
                                                    <div id="collapseTwo" className="accordion-collapse collapse show"
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                            <th scope='col'>Room No.</th>
                                                                            <th scope="col">Name</th>
                                                                            {/* <th scope="col">Pax Type</th> */}
                                                                            {/* <th scope="col">Age(yrs)</th> */}
                                                                            
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td scope="row">Mr. Oscar Roney Urena</td>
                                                                                {/* <td>Adult</td> */}
                                                                                {/* <td>38</td> */}
                                                                                
                                                                            </tr>
                                                                            <tr>
                                                                                <td>2</td>
                                                                                <td scope="row">Mr. Smith Urena</td>
                                                                                {/* <td>Child</td> */}
                                                                                {/* <td>11</td> */}
                                                                                
                                                                            </tr>
                                                                            <tr>
                                                                                <td>3</td>
                                                                                <td scope="row">Mrs. Jenny Malli Urena</td>
                                                                                {/* <td>Adult</td> */}
                                                                                {/* <td>38</td> */}
                                                                            
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                                                            Payment
                                                    </h5>
                                                    <div id="collapseseven" className="accordion-collapse collapse show"
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        
                                                        <div className='row'>
                                                            <div className='col-md-12'>

                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <div className="h6 mb-2 text-primary"> Payment Mode </div> </div>
                                                                    <div className="col-md-10">
                                                                        <div className="h6 mb-2">Credit Card </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                                                            Booking Remarks
                                                    </h5>
                                                    <div id="collapseseven" className="accordion-collapse collapse show"
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        
                                                        <div className='row'>
                                                            <div className='col-md-12'>

                                                                <div className="row">
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="h6 mb-2">Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default HotelConfirmation;