import {

    SEARCH_FLIGHT_BOOKING, SEARCH_SALES_BOOKING, SEARCH_HOTEL_BOOKING,SEARCH_WALLET_UTILIZATION
} from './CONSTANTS'

export async function searchFlightBookingApi(searchData) {
    const response = await fetch(SEARCH_FLIGHT_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch flight Booking.');
    }

    return data;
}
export async function searchWalletUtilization(searchData) {
    const response = await fetch(SEARCH_WALLET_UTILIZATION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch  Wallet Utilization Details.');
    }

    return data;
}
export async function searchSalesBooking(searchData) {
    const response = await fetch(SEARCH_SALES_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch flight Booking.');
    }

    return data;
}
export async function searchHotelBookingApi(searchData) {
    const response = await fetch(SEARCH_HOTEL_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch hotel booking.');
    }

    return data;
}