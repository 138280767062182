import {GET_ALL_CONTINENT_DETAILS,GET_CONTINENT_DETAILS,ADD_CONTINENT_DETAILS,DELETE_CONTINENT_DETAILS,UPDATE_CONTINENT_DETAILS,GET_CONTINENT_BY_NAME} from './CONSTANTS'

export async function getAllcontinent() {
  
  const response = await fetch(GET_ALL_CONTINENT_DETAILS(), {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
});
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch continent.');
  }

  const transformedContinent = [];

  for (const key in data) {
    const quoteObj = {
      id: key,
      ...data[key],
    };

    transformedContinent.push(quoteObj);
  }
  return transformedContinent;
}
export async function geteContinentByName(name) {
  console.log(name)
  const response = await fetch(GET_CONTINENT_BY_NAME(name), {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
});
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch continent.');
  }

  const transformedContinent = [];

  for (const key in data) {
    const quoteObj = {
      id: key,
      ...data[key],
    };

    transformedContinent.push(quoteObj);
  }

  return transformedContinent;
}
export async function getSingleContinent(continentID) {
  const response = await fetch(GET_CONTINENT_DETAILS(continentID), {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
});
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch continent.');
  }

  const loadedQuote = {
    id: continentID,
    ...data,
  };

  return loadedQuote;
}

export async function deleteSingleContinent(continentID) {
  let id=continentID.id;   

  const response = await fetch(DELETE_CONTINENT_DETAILS(id), {
    method: 'DELETE',   
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  });
 
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch continent.');
  }

  const loadedQuote = {
    id: id,
    ...data,
  };

  return loadedQuote;
}

export async function addContinent(continentData) { 
  const response = await fetch(ADD_CONTINENT_DETAILS(), {
    method: 'POST',
    body: JSON.stringify(continentData),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not create continent.');
  }

  return null;
}
export async function updateContinent(continentData) {
  console.log(continentData);
  const response = await fetch(UPDATE_CONTINENT_DETAILS(), {
    method: 'PUT',
    body: JSON.stringify(continentData),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not create continent.');
  }

  return null;
}

