import { Fragment, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import ConfirmationModalPopUp from "../../../../components/Common/Confirmation/ConfirmationModalPopUp";
import MdModalPopUp from '../../../Flights/MdModalPopUp';
import { useTranslation } from 'react-i18next';
import ErrorModalPopUp from "../../../../components/Common/Error/ErrorModalPopUp";
import { searchTaxMaster } from "../../../../services/Tax-API";
import useHttp from '../../../../services/use-http';
import Dropdown from 'react-select';

const TaxDetails = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const history = useHistory();
    const [confirm, setConfirm] = useState();
    const [taxCodeForDelete, SetTaxCodeForDelete] = useState();
    const [error1, setError] = useState();
    const [refresh, setRefesh] = useState(false);
    const [error2, setError2] = useState();

    const [taxDescriptionInputHasError, setTaxDescriptionInputHasError] = useState(false);
    const [taxAmountInputHasError, setTaxAmountInputHasError] = useState(false);
    const [taxAmountInvalidInputHasError, setTaxAmountInvalidInputHasError] = useState(false);

    const [taxDescription, setTaxDescription] = useState('');
    const [taxDescriptionCode, setTaxDescriptionCode] = useState('');
    const [disabledControl, setDisabledControl] = useState(false);

    const { sendRequest, status, data: loadedTax, error } = useHttp(searchTaxMaster);

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

    useEffect(() => {
        const onSearchTax = (
            {
                taxCode: ""
            });
        sendRequest(onSearchTax);
    }, [])

    useEffect(() => {
        if (refresh) {
            setRefesh(false);
        }
    }, [refresh])

    let taxArrayData = [];
    for (let i in loadedTax) {
        if (loadedTax['errors'].status === 'FALSE') {
            if (i === 'data') {
                taxArrayData = loadedTax[i];
            }
        }
    }

    let taxOptions = [];
    for (let index = 0; index < taxArrayData.length; index++) {
        taxOptions = [...taxOptions, { value: taxArrayData[index].taxCode, label: taxArrayData[index].taxCode + "-" + taxArrayData[index].taxDescription }]
    }

    const taxDescriptionChangeHandler = (event) => {
        if (event.value !== '') {
            setTaxDescriptionCode(event.value);
            setTaxDescription(event.label.split("-")[1]);
        }
        else {
            setTaxDescriptionCode('');
            setTaxDescription('');
        }
    };

    function onAddTax() {
        setDisabledControl(false);

        if (taxDescription === undefined || taxDescription == '') {
            setTaxDescriptionInputHasError(true);
            // const yOffset = -150;
            // const element2 = document.getElementById("taxAmount");
            // const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            // window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTaxDescriptionInputHasError(false);
        }

        if (document.querySelector('#taxAmount').value === '') {
            setTaxAmountInputHasError(true);
            setTaxAmountInvalidInputHasError(false);
            // const yOffset = -150;
            // const element2 = document.getElementById("taxAmount");
            // const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            // window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTaxAmountInputHasError(false);
        }

        if (document.querySelector('#taxAmount').value !== '' && !document.querySelector('#taxAmount').value.match('^[0-9.]+$')) {
            setTaxAmountInputHasError(false);
            setTaxAmountInvalidInputHasError(true);
            // const yOffset = -150;
            // const element2 = document.getElementById("taxAmount");
            // const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            // window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTaxAmountInvalidInputHasError(false);
        }

        let setValidationStaus = true;
        if (sessionStorage.getItem("BookingData") !== null) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== "") {

                let taxAmount = 0;
                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax !== undefined) {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.forEach(elementTax => {
                        taxAmount = taxAmount + parseFloat(elementTax.amount !== undefined && elementTax.amount !== '' ? elementTax.amount : 0);
                    });
                }

                let actionMode = '';
                let taxCodeExist = 0;
                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType) !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax !== undefined) {
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.length > 0) {
                        taxCodeExist = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.filter(b => b.taxCode === taxDescriptionCode).length;
                    }
                }

                if (document.querySelector('#btnAddTax') !== null && (document.querySelector('#btnAddTax').innerHTML.replaceAll('&nbsp;', '').toLowerCase() === 'update' || taxCodeExist > 0)) {
                    //taxAmount = parseFloat(document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : 0);
                    actionMode = 'U';
                }
                else {
                    taxAmount = taxAmount + parseFloat(document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : 0);
                    actionMode = 'A';
                }

                let totalPaxTypeTaxAmount = parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount : 0);

                if (Math.floor(taxAmount) > Math.floor(totalPaxTypeTaxAmount) && actionMode !== 'U') {
                    setValidationStaus = false;
                    setError2({
                        title: "Message",
                        message: `${t('taxAmountMisMatchMessage')}${new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode : 'USD' }).format(totalPaxTypeTaxAmount)}`,
                    });
                    throw false;
                }
            }
        }

        if (setValidationStaus === true) {
            if (props.paxType !== undefined && props.paxType !== '') {
                let bookingJSONModified = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType);

                if (bookingJSONModified[0].taxes.tax === undefined) {
                    let tax = [];
                    tax.push({
                        key: "1",
                        amount: document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : "0",
                        currencyCode: bookingJSONModified[0].taxes !== undefined ? bookingJSONModified[0].taxes.currencyCode : '',
                        taxCode: taxDescriptionCode,
                        taxDesc: taxDescription
                    });

                    bookingJSONModified[0].taxes.tax = tax;
                }
                else {
                    let bookingJSONModifiedTax = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.filter(b => b.taxCode === taxDescriptionCode);
                    if (bookingJSONModifiedTax.length === 0) {
                        let taxKey = 0;
                        bookingJSONModified[0].taxes.tax.forEach(elementTax => {
                            taxKey = elementTax.key;
                        });

                        taxKey = parseInt(taxKey) + 1;
                        taxKey = taxKey.toString();
                        bookingJSONModified[0].taxes.tax.push({
                            key: taxKey.toString(),
                            amount: document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : "0",
                            currencyCode: bookingJSONModified[0].taxes !== undefined ? bookingJSONModified[0].taxes.currencyCode : '',
                            taxCode: taxDescriptionCode,
                            taxDesc: taxDescription
                        });
                    }
                    else {
                        bookingJSONModifiedTax[0].amount = document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : "0";
                        bookingJSONModifiedTax[0].taxDesc = taxDescription;

                        // let taxesAmount = 0;

                        // bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.filter(b => b.taxCode !== taxDescriptionCode).forEach(elementTax => {                            
                        //     taxesAmount = taxesAmount + parseFloat(elementTax.amount);                            
                        // });

                        // bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount = parseFloat(taxesAmount) + parseFloat(document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : 0)
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount = parseFloat(document.querySelector('#taxes').value !== '' ? document.querySelector('#taxes').value : 0)
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount.toString();
                    }
                }

                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                //history.push(`/ImportPNR`);
                setRefesh(true);
                //history.go();
                setTaxDescriptionCode('');
                setTaxDescription('');
                document.querySelector('#taxAmount').value = '';
            }
            else {
                //alert('select pax details.')
            }

            if (document.querySelector('#btnAddTax') !== undefined && document.querySelector('#btnAddTax') !== null) {
                document.querySelector('#btnAddTax').innerHTML = " &nbsp;&nbsp; &nbsp; Add &nbsp; &nbsp;&nbsp;  ";
            }
        }
    }

    function onEditTax(taxCode) {
        if (document.querySelector('#btnAddTax') !== undefined && document.querySelector('#btnAddTax') !== null) {
            document.querySelector('#btnAddTax').innerHTML = "Update";
        }

        if (document.querySelector('#react-select-38-input') !== null) {
            document.querySelector('#react-select-38-input').focus();
        }
        setDisabledControl(true);
        let TaxDetails = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.filter(b => b.taxCode === taxCode)[0];

        setTaxDescriptionCode(TaxDetails.taxCode);
        setTaxDescription(TaxDetails.taxDesc);
        document.querySelector('#taxAmount').value = TaxDetails.amount;
    }

    function onDeleteTax(taxCode) {
        let bookingJSONModifiedTax = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.filter(b => b.taxCode !== taxCode);

        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax = bookingJSONModifiedTax;

        // let taxAmount = 0;
        // bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.forEach(elementTax => {
        //     taxAmount = taxAmount + parseFloat(elementTax.amount !== undefined && elementTax.amount !== '' ? elementTax.amount : 0);
        // });
        // bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.amount = taxAmount.toString();

        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        history.push(`/ImportPNR`);
        //history.go();
    }

    function onConfirm(taxCode) {
        SetTaxCodeForDelete(taxCode);
        setConfirm(true);
    }
    function okHandler() {
        onDeleteTax(taxCodeForDelete);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };

    const errorHandler = () => {
        setError(null);
    };

    const errorHandler2 = () => {
        setError2(null);
    };

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10 || (e.code !== undefined && e.code.substring(0, e.code.length - 1) !== 'Digit')) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown' && e.code !== 'Tab')) {
            if ((e.code !== undefined && e.code !== 'Period')) {
                e.preventDefault();
            }
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    return <Fragment>
        {error1 && (
            <MdModalPopUp
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModalPopUp>
        )}
        {error2 && (
            <ErrorModalPopUp
                title={error2.title}
                message={t(error2.message)}
                onConfirm={errorHandler2}
            ></ErrorModalPopUp>
        )}
        {confirm && (
            <ConfirmationModalPopUp
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModalPopUp>
        )}
        <div className='gt-h-400px'>
            <div className='row'>
                <div className="control col-md-8">
                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Description <span className="error-icon"> * </span></label>
                    <Dropdown onChange={taxDescriptionChangeHandler}
                        value={taxOptions.filter(option => option.value === taxDescriptionCode)}
                        options={taxOptions}
                        textField="label"
                        dataItemKey="value"
                        arrowClosed={<span className="arrow-closed" />}
                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                        placeholder="Select" isDisabled={disabledControl}
                    />
                    {taxDescriptionInputHasError &&
                        <p className="error-text">{t('validationDescription')}</p>
                    }
                </div>
                <div className="control col-md-4">
                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Amount <span className="error-icon"> * </span></label>
                    <input type="number" id="taxAmount" className="form-control form-control-sm" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                    {taxAmountInputHasError &&
                        <p className="error-text">{t('validationTaxAmount')}</p>
                    }
                    {taxAmountInvalidInputHasError &&
                        <p className="error-text">{t('validationTaxAmountInvalidData')}</p>
                    }
                </div>
                <div className="control col-md-12">
                    {/* <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label> */}
                    <button id='btnAddTax' className="btn btn_theme btn_sm float-lg-end float-md-end" onClick={() => onAddTax()}>&nbsp; &nbsp; Add &nbsp; &nbsp;</button>
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12">
                    <div className='table-responsive mt-2 table-wrapper width-900px'>
                        {
                            bookingJSON !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax !== undefined &&
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col" align="right" class="text-end">Amount</th>
                                        <th scope="col" className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bookingJSON !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === props.paxType)[0].taxes.tax.map((tax) => (
                                            <tr>
                                                <td>{tax.taxCode + "-" + tax.taxDesc}</td>
                                                <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: tax.currencyCode !== '' ? tax.currencyCode : 'USD'
                                                }).format(tax.amount)}</td>
                                                <td className='min-width-125 '>
                                                    <div className="text-center">
                                                        <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" onClick={() => onEditTax(tax.taxCode)}><i className="fa-solid fa-pen"></i></a>
                                                        <button className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" onClick={() => onConfirm(tax.taxCode)}><i className="fas fa-trash-alt"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6 col-md-6 col-sm-6 my-auto d-none">
                    <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                </div>
            </div>
        </div>
    </Fragment>

};
export default TaxDetails;