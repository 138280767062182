import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';


const FlightRecommendation = () => {
    useDocumentTitle('Flight Book');
    const [error1, setError] = useState();

    const errorHandler = () => {
        setError(null);
    };
    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}

        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                      
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="my-5">
            <div className="container">
                <div className="col-md-12">
                    <table align="center" width="100%"  bgcolor='' className='d-none'>
                        <tr className='row' >
                            <td className='col-lg-4 col-md-6 col-sm-4 px-1 mt-2'>
                                <div className="card">
                                    <div className="card-header bg-main-color text-light fw-bold">
                                        Option 1
                                    </div>
                                    <div className="card-body">

                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="flight_logo">

                                                    <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                                                    <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines </span>
                                                    <span className="text-muted text-14px ms-2"> DL 264<br />Airbus A330-300</span>

                                                </div>
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>

                                                        <div className='h6'>4 Mar 2024, 10:30 PM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon"></div>
                                                            <div className='mt-2 fw-bold text-primary'>15h 50m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>4 Mar 2024, 11:20 PM</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center mb-3'>
                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='col-md-12'>
                                                    <div className="flight_logo">
                                                        <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                                                        <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines</span>
                                                        <span className="text-muted text-14px ms-2">  DL 265 <br />Airbus A330-300</span>

                                                    </div>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>11 Mar 2024, 1:30 AM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon gt-return-flight"></div>
                                                            <div className='mt-2 fw-bold text-primary'>24h 45m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>
                                                        <div className='h6'>11 Mar 2024, 6:15 PM</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center'>

                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='col-md-12 my-auto'>
                                        <div className='h4 text-primary text-end fw-bold pt-1 mb-0 me-3' >
                                            $ 10,236.65
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='row  mb-2'>
                                            <div className='col-md-6'>
                                                <div className="text-12px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</div>
                                            </div>
                                            <div className='col-md-6'>
                                                <p className='text-14px text-end me-3'>Includes taxes</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </td>


                            <td className='col-lg-4 col-md-6 col-sm-4 px-1 mt-2'>
                                <div className="card">
                                    <div className="card-header bg-main-color text-light fw-bold">
                                        Option 2
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="flight_logo">

                                                    <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" className=" p-1 gt-h30" />
                                                    <span className="text-14px text-primary fw-bold gt-w-75px">Multi Airlines </span>
                                                    <span className="text-muted text-14px ms-2"> Virgin Atlantic  VS 46<br />Airbus A330-300</span>

                                                </div>
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>

                                                        <div className='h6'>4 Mar 2024, 7:01 PM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon"></div>
                                                            <div className='mt-2 fw-bold text-primary'>15h 59m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>4 Mar 2024, 8:00 PM</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center mb-3'>
                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='col-md-12'>
                                                    <div className="flight_logo">
                                                        <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" className=" p-1 gt-h30" />
                                                        <span className="text-14px text-primary fw-bold gt-w-75px">Multi Airlines </span>
                                                        <span className="text-muted text-14px ms-2"> Virgin Atlantic  VS 45<br />Airbus A330-300</span>

                                                    </div>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>11 Mar 2024, 7:45 AM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon gt-return-flight"></div>
                                                            <div className='mt-2 fw-bold text-primary'>17h 40m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>
                                                        <div className='h6'>11 Mar 2024, 5:25 PM</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center'>

                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='col-md-12 my-auto'>
                                        <div className='h4 text-primary text-end fw-bold pt-1 mb-0 me-3' >
                                            $ 9,252.95
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='row  mb-2'>
                                            <div className='col-md-6'>
                                                <div className="text-12px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</div>
                                            </div>
                                            <div className='col-md-6'>
                                                <p className='text-14px text-end me-3'>Includes taxes</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td className='col-lg-4 col-md-6 col-sm-4 px-1 mt-2'>
                                <div className="card">
                                    <div className="card-header bg-main-color text-light fw-bold">
                                        Option 3
                                    </div>
                                    <div className="card-body">

                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="flight_logo">
                                                    <img src="https://dev.fareladder.com/assets/logos/flights/AF.gif" alt="img" className=" p-1 gt-h30" />
                                                    <span className="text-14px text-primary fw-bold gt-w-75px">Air France </span>
                                                    <span className="text-muted text-14px ms-2"> AF 3634<br />Airbus A330-300</span>
                                                </div>
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>

                                                        <div className='h6'>4 Mar 2024, 10:30 PM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon"></div>
                                                            <div className='mt-2 fw-bold text-primary'>15h 50m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>4 Mar 2024, 11:20 PM</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center mb-3'>
                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='col-md-12'>
                                                    <div className="flight_logo">
                                                        <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                                                        <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines </span>
                                                        <span className="text-muted text-14px ms-2"> DL 8747<br />Airbus A350-900</span>

                                                    </div>
                                                </div>
                                                <hr className='my-1'></hr>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            Dubai
                                                        </div>
                                                        <div className='text-14px'>DXB - Dubai, United Arab Emirates, AE</div>
                                                        <div className='h6'>11 Mar 2024, 1:30 AM</div>
                                                    </div>
                                                    <div className='col-md-12 px-0 my-2'>
                                                        <div className="text-center text-12px">
                                                            <div className="recomendation-flight-icon gt-return-flight"></div>
                                                            <div className='mt-2 fw-bold text-primary'>18h 10m (1 stop)</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 text-center'>
                                                        <div className=' text-primary h5 my-1'>
                                                            New York
                                                        </div>
                                                        <div className='text-14px'>JFK - John F Kennedy, United States of America, US</div>
                                                        <div className='h6'>11 Mar 2024, 11:40 AM</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 text-14px mt-2 text-center'><span className='fw-bold text-primary'>Check-in Baggage:</span> <span>Per Adult 2 pieces</span></div>
                                                <div className='text-center'>

                                                    <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='col-md-12 my-auto'>
                                        <div className='h4 text-primary text-end fw-bold pt-1 mb-0 me-3' >
                                            $10,313.25
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='row  mb-2'>
                                            <div className='col-md-6'>
                                                <div className="text-12px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</div>
                                            </div>
                                            <div className='col-md-6'>
                                                <p className='text-14px text-end me-3'>Includes taxes</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>



                    <div  className="main-cn hotel-page clearfix my-4">
                        <div  className="row">
                        
                            <div  className="col-md-12 col-md-push-3">
                                <section  className="hotel-list">
                                    <div  className="sort-view clearfix">
                                    </div>
                                    
                                    <div  className="hotel-list-cn clearfix">
                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/aak8hcx6yraydrgbb4ox.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Classic Room
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Classic King Bed Courtyard Or City View 335 Sqft Flrs 4 To 21 <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> King Bed</p>
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,049.61</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$224.61</span> Taxes</div>
                                                </div>
                                            </div>
                                                  
                                                 
                                               
                                               
                                            
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                                                    </div>
                                                
                                                    </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - Feb 6, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Minibar</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Cable television</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Telephone</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Air conditioning</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Superior Room
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Superior King Bed. Courtyard View 360 Sqft Flrs 4 To 12 <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> Courtyard Bed</p>
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,023.11</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$219.11</span> Taxes</div>
                                                </div>
                                            </div>
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                                                    </div>
                                                
                                                    </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - March 6, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Minibar</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Cable television</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Telephone</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Air conditioning</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Deluxe Room
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Deluxe King Room Courtyard View. 375 Sqft Flrs 3 To 18 <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> Courtyard View</p>
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> King Bed</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,130.39</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$241.39</span> Taxes</div>
                                                </div>
                                            </div>
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                                                    </div>
                                                
                                                    </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - March 6, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Minibar</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Cable television</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Telephone</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Air conditioning</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Superior Suite
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Superior Suite - King Bed - 620 Sq Ft. Courtyard View Flr 4 To 14 <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> King Bed</p>
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> Courtyard view</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,743.85</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$368.85</span> Taxes</div>
                                                </div>
                                            </div>
                                                  
                                                 
                                               
                                               
                                            
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                   
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - Feb 25, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                   
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                   
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Presidential Suite
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Presidential Suite - 2722 Sq.ft 3 Bedrooms 4.5 Half Bedrooms City And Central Park View <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-bed"></i> King Bed</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> Park view</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$30,775.60</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$640,0.60</span> Taxes</div>
                                                </div>
                                            </div>
                                                  
                                                 
                                               
                                               
                                            
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                   
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - Feb 25, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                   
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                   
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div  className="hotel-list-item position-relative perk-border light-yellow-bg">
                                            <figure  className="hotel-img float-left">
                                                <a href="hotel-detail.html" title="">
                                                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                                                </a>
                                            </figure>
                                            <span className='hotel-flag' title="GT Blue"><i className="fas fa-bell trophy-icon"></i></span>
                                            <div  className="hotel-text mt-2">
                                                <div  className="hotel-name">
                                                    <a href="hotel-detail.html" title="">Classic Room
                                                    {/* <span className='text-warning ms-1 text-12px'><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span> */}
                                                    </a>
                                                </div>
                                                <div  className="hotel-star-address">
                                                    <address  className="hotel-address" style={{ height: "40px" }}>
                                                    Classic King Bed Courtyard Or City View 335 Sqft Flrs 4 To 21 <br></br>
                                                    </address>
                                                </div>
                                                
                                            </div>
                                            <div className='row' style={{ height: "95px"}}>
                                                <div className='col-md-6'>
                                                    <div className='text-12px ms-2 text-muted'>
                                                    {/* <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p> */}
                                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> King Bed</p>
                                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                                </div>
                                                    
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>
                                                    <p class="card-text text-black">  <span class="fw-bold">Meal Plan:</span> Breakfast</p> */}
                                                </div>
                                            </div>
                                                
                                            <div className="col-md-12 text-14px text-muted mt-2"> <span className="text-14px text-sky-blue ms-3"><i className="fas fa-money-check-alt"></i>  Refundable</span> </div>
                                            <div  className="col-md-12 hotel-service mt-2">
                                                
                                            <div className=' px-2'>
                                                {/* <div className=''>
                                                    <span class="area_flex_one "><span>Base Rate</span><span class="h6">$1,075.00</span></span>
                                                </div>
                                                <div className=''>
                                                <span class="area_flex_one "><span>Taxes</span><span class="h6">$269.57</span></span>
                                                </div> */}
                                                {/* <div className=''>
                                                <span class="area_flex_one "><span>Total Rate</span><span class="h6">$1,344.57</span></span>
                                                </div> */}
                                                
                                            </div>
                                            <hr className='my-1'></hr>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div  className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,049.61</div>
                                                    <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$224.61</span> Taxes</div>
                                                </div>
                                            </div>
                                                  
                                                 
                                               
                                               
                                            
                                                
                                            </div>
                                            
                                            <div className='col-md-12'>
                                            <hr className='my-1'></hr>
                                            <div className='row px-3 mt-3'>
                                           
                                                <div className='col-md-6'>
                                                    <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                                                    </div>
                                                    <div class="text-black">
                                                        <span>
                                                        <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                                                    </div>
                                                
                                                    </div>
                                                
                                                <div className='col-md-6'>
                                                <div>
                                                    <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                                                    <ul>
                                                        <li>
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">Deadline - Feb 24, 2024, 12:00 AM.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                                        </li>
                                                        <li class="display-line">
                                                        <i class="fas fa-circle text-8px text-black py-1"></i>
                                                        <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-4'>
                                                    <div>
                                                        <div class=" card-title text-primary h5 ">Amenities</div>
                                                       <div className='row my-3'>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Minibar</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Cable television</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Safe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Private bathroom</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> TV</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <ul>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Telephone</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Air conditioning</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Hairdryer</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Wireless internet connection</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Bathrobe</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Alarm clock</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> AM/FM radio</span>
                                                                    </li>
                                                                    <li>
                                                                    <i class="fas fa-circle text-8px text-black py-1"></i>
                                                                    <span class="ms-1 text-black"> Desk</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </section>
                                {/*   <!-- End Hotel List --> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>





    </Fragment>




};

export default FlightRecommendation;