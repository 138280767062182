export const HotelDetailsJSON = (selectHotel) => {    
    //console.log(JSON.stringify(createPaxInformation))
const HotelSerach=JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'))

    console.log(selectHotel);
   const hotelImageSize=({"Images": [
        {
            "ImageType": "ORIGINAL"
        },
        {
            "ImageType": "SMALL"
        }
    ]});
    const DetailsJSON = ({
        "officeID": JSON.parse(localStorage.getItem('officeID')),
        "Data": {
            "POS": null,
            "requestDetails": {            
                "Environment": "PRODUCTION",        
                "primaryLanguageId": "EN",
                "echoToken": JSON.parse(sessionStorage.getItem('HotelEchoToken')),
                "traceId": JSON.parse(localStorage.getItem('traceID')),
            },
            "hotels": HotelSerach.Data,
            "hotelResults":[ selectHotel ],
            "Images": [               
                {
                    "ImageType": "LARGE"
                }
            ],
            "TPAExtensions": null
        }
    })

    return DetailsJSON;
}