import {
    SEARCH_TAX_MASTER
}
    from './CONSTANTS'

export async function searchTaxMaster(searchData) {
    //console.log(searchData);
    const response = await fetch(SEARCH_TAX_MASTER(), {
        method: "POST",
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch tax');
    }
    //console.log(data);
    return data;
}