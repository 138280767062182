import { Fragment } from "react";
import { toCamelCase, splitString } from "../../../utils/CommonFunction";

const Nearby = (props) => {
    let attaractios = "";
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
    }

    let Attractions = [];
    let regex = /^([^\d]+)([\d\.]+\s*\w+)/;
    if (attaractios !== null && attaractios !== undefined) {
        let attaraction = attaractios.split("\n");
        attaraction.forEach((element, index) => {

            if (element !== '' && element.trim() !== "Attractions -") {


                const parts = splitString(element.trim());

                let match = element.trim().match(regex);

                if (parts !== null && parts.length === 2) {

                    let firstPart = parts[0].trim();
                    let secondPart = parts[1].trim();
                    Attractions.push(
                        <div className='row py-1'>
                            <div className='col-md-8 fff col-sm-8 col-8'><i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{(firstPart)}</span></div>
                            <div className='col-md-3 gg col-sm-3 col-3 text-end'><span >{(secondPart)}</span></div>
                            <div className='col-md-1 gg col-sm-1 col-1 text-end'></div>
                        </div>

                    );

                }
                else if (match !== null) {
                    let part1 = match[1].trim();
                    let part2 = match[2].trim();

                    Attractions.push(
                        <div className='row py-1'>
                            <div className='col-md-8 fff col-sm-8 col-8'><i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{toCamelCase(part1)}</span></div>
                            <div className='col-md-3 gg col-sm-3 col-3 text-end'><span >{toCamelCase(part2)}</span></div>
                            <div className='col-md-1 gg col-sm-1 col-1 text-end'></div>
                        </div>

                    );

                }
                else {
                    Attractions.push(
                        <div className='row py-1'>
                            <div className='col-md-9 fff'><i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{toCamelCase(element)}</span></div>

                        </div>


                    )
                }

            }



        });
    }
    return <Fragment>
        {attaractios === '' ? "" : <div className=''>
            <h4 className="h5 mt-1 text-primary"><i className="fas fa-map-marker-alt"></i> What's Nearby</h4>
            {Attractions}
        </div>}
    </Fragment>
};
export default Nearby;