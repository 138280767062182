import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import WebStatusForm from './Components/WebStatusForm'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { checkAuthentication } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
import { useHistory,NavLink } from 'react-router-dom';

import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import AuthContext from '../../../redux/contexts/auth-context'
import { FLIGHTSEARCH, LOADING, LOGINWEB } from '../../../navigation/CONSTANTS';
import { GET_ALL_AIRPORT_LIST } from '../../../services/CONSTANTS';


const WebStatus = () => {
    useDocumentTitle('Web Status')
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(checkAuthentication);
    const [errors, setErrors] = useState();
    const history = useHistory();
    

    const logoutHandler = () => {
      localStorage.removeItem("LoginWebStatus");
        //history.push(LOGINWEB);
        window.location.href=LOGINWEB;
    };
    return (
        <Fragment>

            <div className='position-relative'>
                <img src="assets/img/globetrotterdark.png" alt="" className='log-in-head-logo-bg position-absolute' />
                <NavLink to="" onClick={logoutHandler} className="dropdown-item gt-transperant-bg text-end web-status-logout" ><i className="fas fa-sign-out-alt fa-fw"></i>  Logout</NavLink>
            </div>
            <div></div>
            <section id="common_banner-WithoutAuth2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <WebStatusForm  />

        </Fragment>)

};



export default WebStatus;
