import { Fragment } from 'react';
import Dropdown from 'react-select';
const GuestDetails = (props) => {

    window.scrollTo({
        top: 400,
        behavior: "smooth",
    });

    const titles_name = [
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
        { value: 'Dr.', label: 'Dr.' }
    ];

    function assignGuestDetails(event) {

        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        let setValidationStaus = true;

        hotelBookingJSON.data.TravelerInfo.GuestInfo.forEach(element => {
            if (element.PersonName.NamePrefix === '') {
                if (setValidationStaus) {
                    document.getElementById("msgtitle" + element.GuestRefNumber).innerHTML = "Title is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("title" + element.GuestRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                document.getElementById("msgtitle" + element.GuestRefNumber).innerHTML = '';
            }

            if (document.getElementById("fName" + element.GuestRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msgfName" + element.GuestRefNumber).innerHTML = "First Name is required.";
                    setValidationStaus = false;
                    document.getElementById("fName" + element.GuestRefNumber).focus();
                }
            } else {
                document.getElementById("msgfName" + element.GuestRefNumber).innerHTML = '';
            }
            if (document.getElementById("fName" + element.GuestRefNumber).value !== '') {
                if (!document.getElementById("fName" + element.GuestRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgfName" + element.GuestRefNumber).innerHTML = "First Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("fName" + element.GuestRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgfName" + element.GuestRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("mName" + element.GuestRefNumber).value !== '') {
                if (!document.getElementById("mName" + element.GuestRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgmName" + element.GuestRefNumber).innerHTML = "Middle Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("mName" + element.GuestRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgmName" + element.GuestRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("lName" + element.GuestRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msglName" + element.GuestRefNumber).innerHTML = "Last Name is required.";
                    setValidationStaus = false;
                    document.getElementById("lName" + element.GuestRefNumber).focus();
                }
            } else {
                document.getElementById("msglName" + element.GuestRefNumber).innerHTML = '';
            }
            if (document.getElementById("lName" + element.GuestRefNumber).value !== '') {
                if (!document.getElementById("lName" + element.GuestRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msglName" + element.GuestRefNumber).innerHTML = "Last Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("lName" + element.GuestRefNumber).focus();
                    }
                } else {
                    document.getElementById("msglName" + element.GuestRefNumber).innerHTML = '';
                }
            }

            let nameLength = parseInt(document.getElementById("fName" + element.GuestRefNumber).value.length) + parseInt(document.getElementById("mName" + element.GuestRefNumber).value.length) + parseInt(document.getElementById("lName" + element.GuestRefNumber).value.length);
            if (setValidationStaus) {
                if (nameLength > 59) {
                    document.getElementById("totalNameLength" + element.GuestRefNumber).innerHTML = 'First Name, Middle Name and Last Name cannot exceed 59 characters in total.';
                    setValidationStaus = false;
                }
                else {
                    document.getElementById("totalNameLength" + element.GuestRefNumber).innerHTML = '';
                }
            }

            element.PersonName.GivenName = document.getElementById("fName" + element.GuestRefNumber).value;
            element.PersonName.Surname = document.getElementById("lName" + element.GuestRefNumber).value;
            element.PersonName.MiddleName = document.getElementById("mName" + element.GuestRefNumber).value;
        });

        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
        console.log(hotelBookingJSON)

        if (setValidationStaus) {
            props.onContinueButton("1");
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
        }
    }

    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));

    const TextBox = ({ keyName, Messsage, ValidMessage, mandatory, regularExpression, controlType = "text", value, maxLength }) => {

        function changeHandler(e) {
            if (mandatory && e.target.value === '') {
                document.getElementById('msg' + keyName).innerHTML = Messsage;

            } else if (regularExpression !== '' && (e.target.value !== '' && !e.target.value.match(regularExpression))) {
                document.getElementById('msg' + keyName).innerHTML = ValidMessage;
            }
            else {
                document.getElementById('msg' + keyName).innerHTML = '';
            }
        }

        return (<div><input type={controlType} id={keyName}
            className="mb-2 form-control form-control-sm col-lg-12"
            defaultValue={value}
            onBlur={changeHandler} maxLength={maxLength} />
            <span id={'msg' + keyName} className="error-text"></span>
        </div>

        );
    };
    const DropDowndata = ({ keyName, data, value, id, updatePropertyName, placeholder }) => {
        function changeHandler(e) {
            if (document.getElementById("msgtitle" + id) !== null) {
                document.getElementById("msgtitle" + id).innerHTML = '';
            }

            updateValue(id, e.value, updatePropertyName)
        }

        return (<div><Dropdown textField="ddName"
            dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
            controlClassName='form-control control form-select bg_input mb-0'
            placeholder={placeholder === "" ? "Selcet" : placeholder}
            defaultValue={data.filter(option => option.value === value)}
            onChange={changeHandler}
            options={data}
            id={keyName} />
            <span id={'msg' + keyName} className="error-text"></span></div>);

    };

    function updateValue(id, Value, updatePropertyName) {

        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        let setValidationStaus = true;

        if (updatePropertyName === "NamePrefix")
            hotelBookingJSON.data.TravelerInfo.GuestInfo[id - 1].PersonName[updatePropertyName] = Value;

        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
        console.log(hotelBookingJSON);
    }

    return (<Fragment>
        <div className="accordion_flex_area">
            <div className="accordion_left_side position-relative text-muted">
                <h5>1</h5>
                <span id="completeOne" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button id="headingOne" className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        Guests
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    {
                        hotelBookingJSON.data.TravelerInfo.GuestInfo.map((roomGuest) =>
                            <>
                                <div className=''>
                                    <div className='row my-2'>
                                        <div className='col-md-6 text-primary h6'><i className='fa fa-hotel text-12px'></i> Room ({roomGuest.NoOfAdults > 1 ? roomGuest.NoOfAdults + " Guests" : roomGuest.NoOfAdults + " Guest"})</div>
                                        <div className='text-12px'>Please specify the name of one of the guests staying in this room.</div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-2'>
                                            <div className='control'>
                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>
                                                <DropDowndata keyName={"title" + roomGuest.RoomIndex}
                                                    value={hotelBookingJSON.data.TravelerInfo.GuestInfo[roomGuest.RoomIndex - 1].PersonName.NamePrefix}
                                                    id={roomGuest.RoomIndex}
                                                    data={titles_name}
                                                    updatePropertyName="NamePrefix"></DropDowndata>
                                            </div>
                                        </div>
                                        <div className='col-md-10'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                                        <TextBox keyName={"fName" + roomGuest.RoomIndex} value={hotelBookingJSON.data.TravelerInfo.GuestInfo[roomGuest.RoomIndex - 1].PersonName.GivenName} Messsage="First Name is required." ValidMessage="First Name is not valid." mandatory={true} regularExpression='^[a-zA-Z ]+$' maxLength={58}></TextBox>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Middle Name</label>
                                                        <TextBox keyName={"mName" + roomGuest.RoomIndex} value={hotelBookingJSON.data.TravelerInfo.GuestInfo[roomGuest.RoomIndex - 1].PersonName.MiddleName} Messsage="Middle Name is required." ValidMessage="Middle Name is not valid." mandatory={false} regularExpression='^[a-zA-Z ]+$' maxLength={59}></TextBox>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                                        <TextBox keyName={"lName" + roomGuest.RoomIndex} value={hotelBookingJSON.data.TravelerInfo.GuestInfo[roomGuest.RoomIndex - 1].PersonName.Surname} Messsage="Last Name is required." ValidMessage="Last Name is not valid." mandatory={true} regularExpression='^[a-zA-Z ]+$' maxLength={57}></TextBox>
                                                    </div>
                                                </div>
                                                <span id={"totalNameLength" + roomGuest.RoomIndex} className="error-text"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <hr className=''>
                                </hr> */}
                            </>
                        )
                    }

                    <div className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="float-md-end float-lg-end">
                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                <button type="button" onClick={assignGuestDetails} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>)
}

export default GuestDetails;