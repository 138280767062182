import { Fragment, useEffect } from 'react';
import ContentItem from './ContentItem';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { sortData } from '../../../../utils';

const ContentList = (props) => {
  
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['translation.Content']);
  const queryParams = new URLSearchParams(location.search);
  const isSortingAscending = queryParams.get('sort') === 'asc';
  let sortedProvider = sortData(props.content,'serviceName', isSortingAscending);
  sortedProvider = sortData(props.content,'serviceName', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };

  useEffect(() => {

    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
    history.push({
      pathname: '/SearchContent',
      search: `?sort=${('asc')}`
    })
  }, [])

  return <Fragment>
    <div className="container-fluid mb-3 list-view">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
          <div className='row mb-3'>
            <div className="col-md-6"></div>
            <div className='col-md-2 my-auto text-muted text-left'>
              <div className="float-lg-end float-md-end">{t('sortBy')}</div>
            </div>
            <div className="col-md-4 form-group">
              <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                <option>{t('sortServiceDsc')}</option>
                <option>{t('sortServiceAsc')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {sortedProvider.map((content) => (
        <ContentItem
          key={content.id}
          id={content.id}
          serviceName={content.serviceName}
          providerName={content.providerName}
          isActive={content.isActive}
          toRegionName={content.toRegionName}
          fromRegionName={content.fromRegionName}
        />
      ))}
    </div>
  </Fragment>


}
export default ContentList;