import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useHttp from '../../../services/use-http';
import { seatMap } from '../../../services/Flight-API';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { indexOf } from 'lodash';
import ErrorModal from "../Error/ErrorModal";
import { getFormatDate, getFormatTime } from './../../../utils/CommonFunction';
import { getConfigurationByBoolen } from './../../../utils/ConfigurationsFunction';

const SeatMap = (props) => {
    const { t } = useTranslation(['translation.Booking']);
    const [airItineraryList, setAirItineraryList] = useState([]);
    const [seatMapResult, setSeatMapResult] = useState([]);
    const [seatDetails, setSeatDetails] = useState([]);
    const [seatColumnGap, setSeatColumnGap] = useState([]);
    const [error1, setError] = useState();
    const [seatRequest, setSeatRequest] = useState([]);
    const [sector, setSecotr] = useState();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [departureAirport, setDepartureAirport] = useState('');
    const [arrivalAirport, setArrivalAirport] = useState('');

    const { sendRequest: sendRequestSeatMap, status: statusSeatMap, data: loadedSeatMap, error } = useHttp(seatMap);

    const onAirItineraryTabChangeHandler = (flightSegment, airItineraryPricingInfo) => {
        document.querySelector('#spnErrorMsg').style.display = "none";
        setSecotr(flightSegment.departureAirport.locationCode + flightSegment.arrivalAirport.locationCode);
        const onSeatMapRequest = ({
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "Data": {
                "POS": null,
                "SeatMapRequest": [
                    {
                        "FlightSegmentInfo": {
                            "FareBasisCode": null,
                            "CabinType": flightSegment.bookingClassAvails[0].cabinType,
                            "SectorInd": "I",
                            "DepartureAirport": {
                                "LocationCode": flightSegment.departureAirport.locationCode
                            },
                            "ArrivalAirport": {
                                "LocationCode": flightSegment.arrivalAirport.locationCode,
                                "Terminal": null,
                                "CodeContext": null,
                                "Gate": null,
                                "Text": null
                            },
                            "MarketingAirline": {
                                "Code": flightSegment.marketingAirline.code,
                                "FlightNumber": flightSegment.marketingAirline.flightNumber,
                                "Name": flightSegment.marketingAirline.code,
                                "SingleVendorInd": null,
                                "CodeContext": null,
                                "CompanyShortName": null,
                                "TravelSector": null,
                                "CountryCode": "IN",
                                "Division": null,
                                "Department": null,
                                "Text": null
                            },
                            "OperatingAirline": {
                                "FlightNumber": flightSegment.operatingAirline.code,
                                "ResBookDesigCode": flightSegment.resBookDesigCode,
                                "Code": flightSegment.operatingAirline.code,
                                "Name": flightSegment.operatingAirline.code

                            },
                            "Equipment": [
                                {
                                    "AirEquipType": null,
                                    "Name": null,
                                    "AirEquipTypeSuffix": null,
                                    "AircraftType": null,
                                    "ChangeofGauge": null,
                                    "AircraftTailNumber": null,
                                    "Capacity": null
                                }
                            ],
                            "TPA_Extensions": {
                                "Ext": [
                                    {
                                        "Key": null,
                                        "Value": null
                                    }
                                ]
                            },
                            "Remarks": null,
                            "FlightNumber": flightSegment.flightNumber,
                            "Key": null,
                            "DepartureDate": flightSegment.departureDate,
                            "DepartureTime": null,
                            "DepartureTimeZone": null,
                            "ArrivalDate": flightSegment.arrivalDate,
                            "ArrivalTime": null,
                            "ArrivalTimeZone": null,
                            "ResBookDesigCode": flightSegment.resBookDesigCode,
                            "RPH": "1",
                            "FlightID": flightSegment.flightID
                        },
                        "TPA_Extensions": {
                            "Ext": [
                                {
                                    "Key": null,
                                    "Value": null
                                }
                            ]
                        }
                    }
                ],
                "Currency": airItineraryPricingInfo.totalFare.currencyCode,
                "PricingInfo": null,
                "PromotionInfoPaxWise": [],
                "AvailableFlightsOnly": null,
                "OffersInfo": null,
                "ArrangerInfo": null,
                "TraceId": JSON.parse(localStorage.getItem('traceID')),
                "TimeStamp": null,
                "Environment": "PRODUCTION",
                "Version": null,
                "IPAddress": null,
                "SequenceNmbr": null,
                "PrimaryLangID": null,
                "AltLangID": null
            }
        });

        sendRequestSeatMap(onSeatMapRequest);
    }

    let passengerCountTotal = parseInt(sessionStorage.getItem("spnPassengerAdt1")) + parseInt(sessionStorage.getItem("spnPassengerChd1")) + parseInt(sessionStorage.getItem("spnPassengerSeatInf1"))

    const onSeatChangeHandler = (event) => {
        let passengerSeatSelected = document.querySelector('#spnPassengerSeatSelected') !== null && document.querySelector('#spnPassengerSeatSelected').innerHTML !== '' ? document.querySelector('#spnPassengerSeatSelected').innerHTML : 0;

        if (event.target.checked) {
            if (passengerSeatSelected < passengerCountTotal) {
                passengerSeatSelected = passengerSeatSelected + 1;
            }
            else {
                document.querySelector('#' + event.target.id).checked = false;
            }
        }
        else {
            passengerSeatSelected = passengerSeatSelected - 1;
        }

        document.querySelector('#spnPassengerSeatSelected').innerHTML = document.querySelectorAll('.seatmap-input-selected:checked').length;

        let seatRequestTemp = [];
        if (document.querySelectorAll('.seatmap-input-selected') !== null) {
            document.querySelectorAll('.seatmap-input-selected:checked').forEach(function (element, index) {
                let travelerRefNumber = (index + 1);

                //User Story 1073: Booking Summary for Single PNR
                let bookingJSON = "";
                if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                    bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
                }
                else {
                    bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                }

                while (bookingJSON.data.travelerInfo.traveler[travelerRefNumber - 1].PassengerTypeCode === 'INF') {
                    travelerRefNumber = travelerRefNumber + 1
                }

                seatRequestTemp.push({
                    "SeatPreference": "",
                    "RowNumber": element.getAttribute("data").split('_')[0],
                    "SeatNumber": element.getAttribute("data").split('_')[0] + element.getAttribute("data").split('_')[1],
                    "TravelerRefNumber": travelerRefNumber.toString(),
                    "FlightRefNumber": element.getAttribute("data").split('_')[3],
                    "Amount": element.getAttribute("data").split('_')[2].length > 1 ? element.getAttribute("data").split('_')[2].substring(1, element.getAttribute("data").split('_')[2].length) : element.getAttribute("data").split('_')[2],
                    "PartialSeatingInd": "",
                    "DeckLevel": "",
                    "SmokingAllowed": "",
                    "Status": "",
                    "SeatKey": null,
                    "Sector": sector
                });
            });
        }

        let seatRequestTempFinal = [];
        if (seatRequest.length > 0) {
            seatRequestTempFinal = seatRequest.filter((item) => item.Sector !== sector);
            seatRequestTemp.forEach(element => {
                seatRequestTempFinal.push(element);
            });
        }
        else {
            seatRequestTempFinal = seatRequestTemp;
        }

        setSeatRequest(seatRequestTempFinal);

        //User Story 1073: Booking Summary for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            sessionStorage.setItem("SeatMapData", JSON.stringify(seatRequestTempFinal));
        }
        else {
            localStorage.setItem("SeatMapData", JSON.stringify(seatRequestTempFinal));
        }

    }

    useEffect(() => {
        var airItineraryListTemp = [];
        props.seatMap.airItinerary.forEach(element1 => {
            element1.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    airItineraryListTemp.push({
                        id: `${element3.departureAirport.locationCode}_${element3.arrivalAirport.locationCode}`,
                        departureAirport: element3.departureAirport.locationCode,
                        arrivalAirport: element3.arrivalAirport.locationCode,
                        flightSegment: element3,
                        airItineraryPricingInfo: element1.airItineraryPricingInfo
                    });
                });
            });
        });

        setAirItineraryList(airItineraryListTemp);

        let loadedData = [];
        for (let i in loadedSeatMap) {
            if (loadedSeatMap.data.errors.status === 'FALSE') {
                loadedData = loadedSeatMap.data.seatMapResponse;
            }
        }

        setSeatMapResult(loadedData);

        if (loadedData.length > 0 && statusSeatMap === 'completed') {
            const results = [];
            const resultsAllColumns = [];
            const seatAvailable = [];
            const seatOccupied = [];
            const seatColumnGapTemp = [];
            const seatColumnNameGap = [];

            loadedData[0].seatInfo.columnDetail.forEach(element => {
                resultsAllColumns.push({
                    seatColumnID: element.seatColumnID,
                    rowNumber: element.seatDetails.map(element => element.rowNumber)
                })
            });

            let maxRow = loadedData[0].seatInfo.columnDetail.map(element => element.seatDetails).map(element2 => element2.length);
            seatAvailable.push("Available");
            seatOccupied.push("SeatIsOccupied");
            seatOccupied.push("Occupied");

            var regex = /-/gi, result;
            while ((result = regex.exec(loadedData[0].seatInfo.seatColumnsDetail.seatType))) {
                seatColumnNameGap.push(result.input[result.index - 1]);
            }
            let seatColumnNameWithoutHyphen = loadedData[0].seatInfo.seatColumnsDetail.seatType.replace('-', '');
            seatColumnNameGap.forEach(element => {
                seatColumnGapTemp.push(indexOf(seatColumnNameWithoutHyphen, element));
            });

            setSeatColumnGap(seatColumnGapTemp);

            function getFormattedLimitations(value) {
                var formattedValue = "";
                if (getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP")) {
                    value.filter(el => el.code === 'Limitations').forEach(element => {
                        if (formattedValue === "") {
                            formattedValue = element.description;
                        }
                        else {
                            formattedValue = formattedValue + "</br>" + element.description;
                        }
                    });
                }
                return formattedValue !== "" ? "</br>" + formattedValue.replace(/([A-Z])/g, ' $1').trim() : "";
            }

            // Changes for User Story 2348: Display limitations on seat map
            loadedData[0].seatInfo.columnDetail[indexOf(maxRow, Math.max(...maxRow.values()))].seatDetails.forEach(function (elementSeat, index) {
                results.push(
                    <li className={`row row--${elementSeat.rowNumber}`}>
                        <ol className="seats" type="A">
                            {
                                (loadedData[0].seatInfo.columnDetail[0] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[0].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[0].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation.description : '')) ?
                                        <>
                                            <li className='seat seatColumn0'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[0].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[0].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[0].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[0].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[0].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[0].type !== undefined ? loadedData[0].seatInfo.columnDetail[0].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[0].type + ' Seat' : loadedData[0].seatInfo.columnDetail[0].type.substring(0, loadedData[0].seatInfo.columnDetail[0].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[0].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[0].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn0 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[0].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[0].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[0].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn0 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn0 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[0].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[0].seatColumnID}</label>
                                                </li> : ''

                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[1] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[1].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[1].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn1'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[1].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[1].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[1].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[1].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[1].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[1].type !== undefined ? loadedData[0].seatInfo.columnDetail[1].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[1].type + ' Seat' : loadedData[0].seatInfo.columnDetail[1].type.substring(0, loadedData[0].seatInfo.columnDetail[1].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[1].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[1].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn1 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[1].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[1].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[1].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn1 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn1 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[1].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[1].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[2] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[2].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[2].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn2'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[2].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[2].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[2].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[2].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[2].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[2].type !== undefined ? loadedData[0].seatInfo.columnDetail[2].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[2].type + ' Seat' : loadedData[0].seatInfo.columnDetail[2].type.substring(0, loadedData[0].seatInfo.columnDetail[2].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[2].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[2].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn2 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[2].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[2].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[2].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn2 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn2 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[2].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[2].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[3] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[3].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[3].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn3'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[3].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[3].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[3].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[3].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[3].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[3].type !== undefined ? loadedData[0].seatInfo.columnDetail[3].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[3].type + ' Seat' : loadedData[0].seatInfo.columnDetail[3].type.substring(0, loadedData[0].seatInfo.columnDetail[3].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[3].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[3].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn3 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[3].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[3].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[3].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn3 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn3 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[3].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[3].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[4] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[4].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[4].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn4'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[4].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[4].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[4].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[4].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[4].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[4].type !== undefined ? loadedData[0].seatInfo.columnDetail[4].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[4].type + ' Seat' : loadedData[0].seatInfo.columnDetail[4].type.substring(0, loadedData[0].seatInfo.columnDetail[4].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[4].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[4].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn4 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[4].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[4].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[4].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn4 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn4 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[4].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[4].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[5] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[5].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[5].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn5'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[5].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[5].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[5].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[5].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[5].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[5].type !== undefined ? loadedData[0].seatInfo.columnDetail[5].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[5].type + ' Seat' : loadedData[0].seatInfo.columnDetail[5].type.substring(0, loadedData[0].seatInfo.columnDetail[5].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[5].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[5].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn5 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[5].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[5].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[5].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn5 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn5 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[5].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[5].seatColumnID}</label>
                                                </li> : ''
                            }
                            {(loadedData[0].seatInfo.columnDetail[6] !== undefined) ?
                                (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[6].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[6].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                    <>
                                        <li className='seat seatColumn6'>
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                <>
                                                    <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[6].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                    <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                <>
                                                    <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[6].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                    <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type) !== "" &&
                                                <>
                                                    <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[6].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                    <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type) === "" &&
                                                <>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} disabled />
                                                    <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                <>
                                                    <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[6].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                    <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            {
                                                (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                <>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} disabled />
                                                    <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                                </>
                                            }
                                            <ReactTooltip
                                                anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}
                                                place="bottom"
                                                variant="info"
                                                className="tooltip"
                                                html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[6].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[6].type !== undefined ? loadedData[0].seatInfo.columnDetail[6].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[6].type + ' Seat' : loadedData[0].seatInfo.columnDetail[6].type.substring(0, loadedData[0].seatInfo.columnDetail[6].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type)}
                                            />
                                        </li>
                                    </>
                                    :
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[6].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[6].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                        <li className='seat seatColumn6 gt-seat-map-occupied'>
                                            <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} disabled />
                                            <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                        </li>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[6].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[6].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[6].seatDetails[index].seatOccupation.description : '')) ?
                                            <li className='seat seatColumn6 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                            </li>
                                            :
                                            <li className='seat seatColumn6 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[6].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[6].seatColumnID}</label>
                                            </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[7] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[7].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[7].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn7'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[7].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[7].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[7].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[7].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[7].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[7].type !== undefined ? loadedData[0].seatInfo.columnDetail[7].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[7].type + ' Seat' : loadedData[0].seatInfo.columnDetail[7].type.substring(0, loadedData[0].seatInfo.columnDetail[7].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[7].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[7].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn7 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[7].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[7].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[7].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn7 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn7 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[7].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[7].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[8] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[8].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[8].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn8'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[8].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[8].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[8].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[8].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[8].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[8].type !== undefined ? loadedData[0].seatInfo.columnDetail[8].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[8].type + ' Seat' : loadedData[0].seatInfo.columnDetail[8].type.substring(0, loadedData[0].seatInfo.columnDetail[8].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[8].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[8].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn8 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[8].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[8].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[8].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn8 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn8 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[8].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[8].seatColumnID}</label>
                                                </li> : ''
                            }
                            {
                                (loadedData[0].seatInfo.columnDetail[9] !== undefined) ?
                                    (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[9].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[9].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined)) ?
                                        <>
                                            <li className='seat seatColumn9'>
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') === undefined) &&
                                                    <>
                                                        <input onClick={(e) => onSeatChangeHandler(e)} type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[9].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='bg-sky-blue' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[9].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type) !== "" &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[9].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='gt-seat-map-charge-limit-off' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") && getFormattedLimitations(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type) === "" &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" checked={false} id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`} className='seatmap-input-selected' data={elementSeat.rowNumber + "_" + loadedData[0].seatInfo.columnDetail[9].seatColumnID + "_" + (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.amount) : '0') + "_" + loadedData[0].flightSegment.flightID} />
                                                        <label style={{ cursor: 'pointer' }} className='seat-limit-charge-bg' id={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}${loadedData[0].flightSegment.departureAirport.locationCode}${loadedData[0].flightSegment.arrivalAirport.locationCode}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                {
                                                    (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined) && !getConfigurationByBoolen("SHOW_LIMITATION_ON_SEATMAP") && !getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                    <>
                                                        <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} disabled />
                                                        <label className="gt-seat-map-charge-limit-off" for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                    </>
                                                }
                                                <ReactTooltip
                                                    anchorId={`lbl${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="tooltip"
                                                    html={elementSeat.rowNumber + loadedData[0].seatInfo.columnDetail[9].seatColumnID + " | " + (loadedData[0].seatInfo.columnDetail[9].type !== undefined ? loadedData[0].seatInfo.columnDetail[9].type.slice(-4).toLowerCase() !== 'seat' ? loadedData[0].seatInfo.columnDetail[9].type + ' Seat' : loadedData[0].seatInfo.columnDetail[9].type.substring(0, loadedData[0].seatInfo.columnDetail[9].type.length - 4) + " Seat" : '') + (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges !== undefined && getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") ? " | " + new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.currency }).format(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatCharges.totalCharges.amount) : '') + getFormattedLimitations(loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type)}
                                                />
                                            </li>
                                        </>
                                        :
                                        (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[9].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatAvailable.includes(loadedData[0].seatInfo.columnDetail[9].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation.description : '') && (loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatType.type.find(el => el.description === 'ChargeableSeat') !== undefined)) ?
                                            <li className='seat seatColumn9 gt-seat-map-occupied'>
                                                <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} disabled />
                                                <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                            </li>
                                            :
                                            (resultsAllColumns.find(el => el.seatColumnID === loadedData[0].seatInfo.columnDetail[9].seatColumnID && el.rowNumber.includes(elementSeat.rowNumber)) && seatOccupied.includes(loadedData[0].seatInfo.columnDetail[9].seatDetails[index] !== undefined && loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation !== undefined ? loadedData[0].seatInfo.columnDetail[9].seatDetails[index].seatOccupation.description : '')) ?
                                                <li className='seat seatColumn9 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                </li>
                                                :
                                                <li className='seat seatColumn9 gt-seat-map-occupied'>
                                                    <input type="checkbox" id={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`} disabled />
                                                    <label for={`chk${elementSeat.rowNumber}${loadedData[0].seatInfo.columnDetail[9].seatColumnID}`}>{elementSeat.rowNumber}{loadedData[0].seatInfo.columnDetail[9].seatColumnID}</label>
                                                </li> : ''
                            }
                        </ol>
                    </li>
                )
            });
            setSeatDetails(results);
        }

        if (statusSeatMap === null) {
            onAirItineraryTabChangeHandler(airItineraryListTemp[0].flightSegment, airItineraryListTemp[0].airItineraryPricingInfo)
        }

        let knownErrorList = [];
        knownErrorList.push('PRS NOT ALLOWED THIS CLASS OR ZONE');
        knownErrorList.push('AIRPORT CHECKIN ONLY');
        knownErrorList.push('FLIGHT OPERATED BY ANOTHER CARRIER');

        if (statusSeatMap === 'completed') {
            if (loadedSeatMap !== undefined && loadedSeatMap !== null) {
                if (loadedSeatMap.data.errors.status === 'TRUE') {
                    // setError({
                    //     title: "Message",
                    //     message: `${t('ErrorMessage')}`,
                    // });

                    // if (document.querySelector('.modal-top') !== null) {
                    //     document.querySelector('.modal-top').style.display = "none";                    
                    //     document.querySelector('#backdrop-root').firstChild.classList.remove("backdrop");
                    // }
                    document.querySelector('#spnErrorMsg').style.display = "";
                    //document.querySelector('#spnErrorMsg').innerHTML = "Seat map unavailable from the airline.";
                    document.querySelector('#spnErrorMsg').innerHTML = loadedSeatMap.data.errors.error.description;
                    // if (knownErrorList.includes(loadedSeatMap.data.errors.error.description.trim())) {
                    //     document.querySelector('#spnErrorMsg').innerHTML = "Seat map unavailable from the airline.";
                    // }
                }
                if (isInitialLoad === true) {
                    if (document.querySelector('#tabButton_' + airItineraryList[0].id) !== null) {
                        document.querySelector('#tabButton_' + airItineraryList[0].id).classList.add("active");
                    }
                    setIsInitialLoad(false);
                }
            }
        }

    }, [statusSeatMap])

    useEffect(() => {
        for (let index = 0; index < 10; index++) {
            var columnElement = document.querySelectorAll('.seatColumn' + index);
            columnElement.forEach(element => {
                if (seatColumnGap.includes(index)) {
                    element.classList.add('seat-column-gap');
                }
            });
        }

        if (sector !== '') {
            seatRequest.forEach(element => {
                if (document.querySelector('#chk' + element.SeatNumber + element.Sector) !== null) {
                    document.querySelector('#chk' + element.SeatNumber + element.Sector).checked = true;
                }
            });
        }

        if (document.querySelector('#spnPassengerSeatSelected') !== null) {
            document.querySelector('#spnPassengerSeatSelected').innerHTML = document.querySelectorAll('.seatmap-input-selected:checked').length;
        }

        if (seatMapResult.length > 0) {
            let sourceAirport = "";
            if (seatMapResult[0].flightSegment.departureAirport.locationName !== undefined) {
                sourceAirport = seatMapResult[0].flightSegment.departureAirport.locationName;
            }
            if (seatMapResult[0].flightSegment.departureAirport.locationCode !== undefined) {
                sourceAirport = seatMapResult[0].flightSegment.departureAirport.locationName !== undefined ? sourceAirport + "(" + seatMapResult[0].flightSegment.departureAirport.locationCode + ")" : sourceAirport + seatMapResult[0].flightSegment.departureAirport.locationCode;
            }
            if (seatMapResult[0].flightSegment.departureAirport.countryName !== undefined) {
                sourceAirport = sourceAirport + ", " + seatMapResult[0].flightSegment.departureAirport.countryName;
            }
            if (seatMapResult[0].flightSegment.departureAirport.countryCode !== undefined) {
                sourceAirport = seatMapResult[0].flightSegment.departureAirport.countryName !== undefined ? sourceAirport + "(" + seatMapResult[0].flightSegment.departureAirport.countryCode + ")" : sourceAirport + ", " + seatMapResult[0].flightSegment.departureAirport.countryCode;
            }
            setDepartureAirport(sourceAirport);

            let destinationAirport = "";
            if (seatMapResult[0].flightSegment.arrivalAirport.locationName !== undefined) {
                destinationAirport = seatMapResult[0].flightSegment.arrivalAirport.locationName;
            }
            if (seatMapResult[0].flightSegment.arrivalAirport.locationCode !== undefined) {
                destinationAirport = seatMapResult[0].flightSegment.arrivalAirport.locationName !== undefined ? destinationAirport + "(" + seatMapResult[0].flightSegment.arrivalAirport.locationCode + ")" : destinationAirport + seatMapResult[0].flightSegment.arrivalAirport.locationCode;
            }
            if (seatMapResult[0].flightSegment.arrivalAirport.countryName !== undefined) {
                destinationAirport = destinationAirport + ", " + seatMapResult[0].flightSegment.arrivalAirport.countryName;
            }
            if (seatMapResult[0].flightSegment.arrivalAirport.countryCode !== undefined) {
                destinationAirport = seatMapResult[0].flightSegment.arrivalAirport.countryName !== undefined ? destinationAirport + "(" + seatMapResult[0].flightSegment.arrivalAirport.countryCode + ")" : destinationAirport + ", " + seatMapResult[0].flightSegment.arrivalAirport.countryCode;
            }
            setArrivalAirport(destinationAirport);
        }

        if (props.isReadOnlyMode) {
            for (let index = 0; index < 10; index++) {
                var columnElement = document.querySelectorAll('.seatColumn' + index);
                columnElement.forEach(element => {
                    //element.style="pointer-events: none";
                    element.classList.add('plane-seats-readonly');
                });
            }

            if (document.querySelector('#seatMapTitle') !== null) {
                document.querySelector('#seatMapTitle').innerHTML = "";
            }
            if (document.querySelector('#dvPassengerSeatSelection') !== null) {
                document.querySelector('#dvPassengerSeatSelection').innerHTML = "";
            }
        }

    }, [seatDetails])

    const errorHandler = () => {
        setError(null);
    };

    return (
        <Fragment>
            {error1 && (
                <ErrorModal
                    title={error1.title}
                    message={t(error1.message)}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            <div className="row">
                <div className="col-md-12 pop-ups-tab-border-parent">
                    <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                        {
                            airItineraryList.map(element => (
                                <li className="nav-item pb-0" role="presentation">
                                    <button className="tabs-btn nav-link" id={`tabButton_${element.id}`} data-bs-toggle="tab"
                                        data-bs-target={`#tabArea_${element.id}`} type="button" role="tab" aria-controls={`#tabArea_${element.id}`}
                                        aria-selected="true" onClick={(e) => onAirItineraryTabChangeHandler(element.flightSegment, element.airItineraryPricingInfo)}>{element.departureAirport}-{element.arrivalAirport}</button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="col-md-12 ">
                    <div className="px-3 tab-content include-exclude-tab" id="myTabContent">
                        {statusSeatMap === 'pending' &&
                            <div className='py-2 text-center'>
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        }
                        <div className='text-center py-2' id='spnErrorMsg' style={{ "display": "none" }}>Sorry, we're having a problem on our end.<br />Please try again later.</div>
                        {seatMapResult !== undefined && seatMapResult.length > 0 &&
                            < div className="tab-pane fade show active" id={`#tabArea_${seatMapResult[0].flightSegment.departureAirport.locationCode}_${seatMapResult[0].flightSegment.arrivalAirport.locationCode}`} role="tabpanel" aria-labelledby="departure">
                                <div className=''>
                                    <div className=' position-relative'>
                                        <div className='row mt-3'>
                                            <div className='col-md-5 position relative'>
                                                <div className=''>
                                                    <div className='col-md-12'>
                                                        <div className='text-secondary'><i className="fa-solid fa-square"></i> Booked</div>
                                                        <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Available</div>
                                                        {
                                                            !props.isReadOnlyMode &&
                                                            <div className='text-warning'><i className="fa-solid fa-square"></i> Selected</div>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("SHOW_AMOUNT_ON_SEATMAP") &&
                                                            <div className='text-light-red'><i className="fa-solid fa-square"></i> Unavailable</div>
                                                        }
                                                    </div>
                                                    <div className='col-md-12 mt-3'>
                                                        <div className='card'>
                                                            <div className='card-body'>
                                                                <div className=''><span className='fw-bold'>From: </span>{departureAirport} <br></br> <span className='fw-bold'>To: </span> {arrivalAirport}</div>
                                                                <hr></hr>
                                                                <div className='text-14px'><span className='fw-bold'>Date: </span> {getFormatDate(seatMapResult[0].flightSegment.departureDate)}{seatMapResult[0].flightSegment.departureTime !== undefined ? ", " + getFormatTime(seatMapResult[0].flightSegment.departureTime) : ''}</div>
                                                                <div className='text-14px'><span className='fw-bold'>Flight: </span> {seatMapResult[0].flightSegment.marketingAirline.code} {seatMapResult[0].flightSegment.marketingAirline.flightNumber}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 mt-3'>
                                                        <div id='dvPassengerSeatSelection' className='text-12px'><span id='spnPassengerSeatSelected'></span>{` of ${passengerCountTotal} Seat(s) Selected`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                {
                                                    <div className="plane-seats">
                                                        <div className="cockpit">
                                                            <h5 id='seatMapTitle' className='plane-text'>Please select seat</h5>
                                                        </div>
                                                        <div className="exit exit--front fuselage">

                                                        </div>
                                                        <ol className="cabin fuselage gt-seat-overflow">
                                                            {seatDetails}
                                                        </ol>
                                                        <div className="exit exit--back fuselage">

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment >)
}
export default SeatMap