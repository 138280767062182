import { useState, Fragment, useEffect, useCallback } from "react";
import { getAllCustomFields, searchCustomFields } from '../../../services/CustomFields-API'
import { NEWCUSTOMFIELDS } from "../../../navigation/CONSTANTS";
import useHttp from "../../../services/use-http";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { NavLink, useHistory } from "react-router-dom";
import CustomFieldsList from "./Components/CustomFieldsList";
import { useTranslation } from "react-i18next";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import Dropdown from 'react-select';

const AllCustomFields = () => {
    useDocumentTitle('Search Custom Fields')
    const [companyCode, setCompanyCode] = useState("");
    const [enteredCode, setEnteredCode] = useState("");
    const [isFirtTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);
    const history = useHistory();

    const { t } = useTranslation(['translation.CustomFields']);

    let companyOptions = [
        { value: 'Microsoft', label: 'Microsoft' }
    ];

    let varHttp;
    if (companyCode !== undefined && companyCode !== null && companyCode !== '') {
        varHttp = getAllCustomFields; //searchCustomFields
    } else {
        varHttp = getAllCustomFields;
    }

    const companyChangeHandler = (event) => {
        setCompanyCode(event.value);
    };

    const codechangehandler = (event) => {
        setEnteredCode(event.target.value);
    }

    const { sendRequest, status, data: loadedApprover, error } = useHttp(varHttp);



    const onResetHandler = () => {
        // event.preventDefault();
        // setEnterName('');
        // setEnteredCode('');
        history.replace('/allCustomFields');
        // history.go();

    }


    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        setFirstTime(true);        
        if (companyCode !== undefined && companyCode !== null && companyCode !== '') {
            const searchAirlineParameter = { airlineCode: enteredCode};
            sendRequest(searchAirlineParameter);
        } else {
            sendRequest(enteredCode);
        }

    }, [sendRequest, enteredCode])

    useEffect(() => {
        setFirstTime(true);
        sendRequest(enteredCode);
    }, [])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }
    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>

    }

    let loadedData = [];
    for (let i in loadedApprover) {
        if (loadedApprover['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedApprover[i];
            }
        }
    }

    function okayHandler() {
        setError1(false);
        history.go('/allCustomFields');

    }
    const errorHandler = () => {
        history.go('/allCustomFields');
    };

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }
    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''
    const customFieldsList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <CustomFieldsList approver={loadedData} /> : ErrorShow

    return <Fragment>
        {error1 && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('Search Custom Fields')}</label>
                                            <form>
                                                <div className="row">
                                                    <div className='control col-lg-12'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='company'>{t('Company Name')}</label>
                                                        <Dropdown onChange={companyChangeHandler}
                                                            value={companyOptions.filter(option => option.value === companyCode)}
                                                            options={companyOptions}
                                                            textField="listValue"
                                                            dataItemKey="listID"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={false}
                                                            autoFocus
                                                        />
                                                    </div>
                                                    <div className='control col-lg-6 d-none'>
                                                        <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">{t('airlinecode')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' value={enteredCode} maxLength={2} onChange={codechangehandler}
                                                            onInput={changeToUpperCase} />

                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "CustomFields", "Master_CustomFields_Insert") && <NavLink className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' to={NEWCUSTOMFIELDS}>
                                                        {t('new')}
                                                    </NavLink>}
                                                    {checkSecurityDetails("Master", "CustomFields", "Master_CustomFields_Search") && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {customFieldsList}
    </Fragment>
};

export default AllCustomFields;