import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
const FeesItem = (props) => {
    const { t } = useTranslation(['translation.Fees']);
    const [confirm, setConfirm] = useState();
    let isUserActive;
    const isActive = props.isActive.toString();
    if (isActive === 'true') {
        isUserActive = 'Yes';
    }
    else if (isActive === 'false') {
        isUserActive = 'No';
    }
    function onConfirm() {
        setConfirm(true);
    }
    function okHandler() {
        deleteFormHandler(props)
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };
    function deleteFormHandler(props) {
        // optional: Could validate here    
        props.onDelete({
            id: props.id,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
        });
    }
    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.providerName}
                                </h6>
                                <hr className="my-1" />
                            </div>
                            <div className="col-md-4">
                                <p className="card-text mb-0"><strong>{t('service')}</strong>{props.serviceName}</p>

                                <p className="card-text mb-0"><strong>{t('fromRegionName')}</strong>{props.fromRegionName}</p>
                                {props.serviceID !== "21" && <p className="card-text mb-0"><strong>{t('toRegionName')} </strong>{props.toRegionName}</p>}
                                {props.serviceID !== "21" && <p className="card-text mb-0"><strong>{t('cabinType')}: </strong>{props.cabinClass === "0" ? "All" : props.cabinClass}</p>}
                            </div>
                            <div className="col-md-4">


                                <p className="card-text mb-0"><strong>{t('posType')}: </strong>{props.pos === "0" ? "All" : props.pos}</p>
                                {props.serviceID !== "21" && <p className="card-text mb-0"><strong>{t('paxType')}: </strong>{props.paxType === "0" ? "All" : props.paxType === "ADT" ? "Adult" : props.paxType === "CNN" ? "Child" : props.paxType === "INS" ? "Seat Infant" : props.paxType === "INF" ? "Lap Infant" : props.paxType}</p>}
                                {props.serviceID !== "21" && <p className="card-text mb-0"><strong>{t('fareType')}: </strong>{props.fareTypeID === "0" ? "All" : props.fareTypeID}</p>}
                            </div>
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Master", "Fee", "Master_Fee_View") &&
                                        <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateFees/${props.id}/0`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                                    }
                                    {checkSecurityDetails("Master", "Fee", "Master_Fee_Update") &&
                                        <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateFees/${props.id}/1`, edit: 1 }}>
                                            <i className="fa-solid fa-pen"></i>
                                        </Link>}
                                    {checkSecurityDetails("Master", "Fee", "Master_Fee_Delete") &&
                                        <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title='Delete'><i className="fas fa-trash-alt"></i></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default FeesItem;