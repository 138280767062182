import {
    GET_CREDIT_LIMIT,
    ADD_CREDIT_LIMIT,
    UPDATE_CREDIT_LIMIT,
    DELETE_CREDIT_LIMIT,
    SEARCH_CREDIT_LIMIT   
} from './CONSTANTS'


export async function searchCreditLimit(searchData) {
    const response = await fetch(SEARCH_CREDIT_LIMIT(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }
    
    return data;
}
export async function getSingleCreditLimit(ID) {
    const response = await fetch(GET_CREDIT_LIMIT(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}

export async function deleteSingleCreditLimit(deleteData) {
    const response = await fetch(DELETE_CREDIT_LIMIT(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete credit Limit.');
    }
    return data;
}

export async function addCreditLimit(addData) {
    const response = await fetch(ADD_CREDIT_LIMIT(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create credit Limit.');
    }
    return data;
}
export async function updateCreditLimit(updateData) {    
    const response = await fetch(UPDATE_CREDIT_LIMIT(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update credit Limit.');
    }
    return data;
}

