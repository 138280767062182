import { Fragment, useEffect } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';


const HomePageBottomArea = () => {
    useDocumentTitle('Home')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        sessionStorage.setItem("LoaderHeader", "0");
    }, [])

    return <Fragment>       

        <section id="destinations_area" class="section_padding_top position-relative mt-5 pt-5">
            <div class="container">
                <div class="row mt-5 pt-5">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="section_heading_left">
                            <h2>Hot flight deals for you!</h2>
                            {/* <p>Nostrud aliqua ipsum dolore velit labore nulla fugiat.</p> */}
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-end">
                        <div class="section_heading_left_view_all">
                            {/* <a href="top-destinations.html">View all</a> */}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small1.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small3.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small1.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small4.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small6.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="tab_destinations_boxed">
                            <div class="tab_destinations_img">
                                <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small5.png" alt="img" /></a>
                            </div>
                            <div class="tab_destinations_conntent">
                                <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                                <p>Economy Class <span>$105.00</span></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section id="popular_destination_eight" class="section_padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        {/* <h2>Top trending holiday deals</h2> */}
                        <div class="section_heading_eight">
                            {/* <h5>Popular destinations</h5> */}
                            <h2>Top trending holiday deals</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="popular_tours_eight_slider button_style_top_left owl-theme owl-carousel owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage">
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/60nv5WGh_2024_02_01/IMG_shannonfalls.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">London aliqua irure proident esse</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/me4kMxiF_2024_01_29/IMG_womaninmontrealwithbike.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/60nv5WGh_2024_02_01/IMG_shannonfalls.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">London aliqua irure proident esse</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://cloudinary.fclmedia.com/fctg/image/fetch/w_720,c_fit,f_webp/q_80/https://travelbox.fclmedia.com/surf-documents/travelbox/tbxcontent/tbx_images/cms_images/60nv5WGh_2024_02_01/IMG_shannonfalls.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p className='text-16px' style={{ fontSize: "18px" }}><i class="fa-solid fa-bed"></i> Stay</p>
                                                        <h6 className='mt-2 text-14px' style={{ fontSize: "14px" }}>2 adults, 2 children from</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                                        <div class="common_card_eight">
                                            <div class="common_card_eight_img">
                                                <a href="tour-search.html">
                                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/tour/popular-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div class="common_card_eight_text">
                                                <ul class="common_card_eight_list">
                                                    <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                                    </li>
                                                    <li>Family tour</li>
                                                </ul>
                                                <h3>
                                                    <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                                </h3>
                                                <p>
                                                    <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                                </p>
                                                <div class="common_card_eight_bottom">
                                                    <div class="common_card_eight_bottom_left">
                                                        <p>4.8/5 Excellent</p>
                                                        <h6>(1214 reviewes)</h6>
                                                    </div>
                                                    <div class="common_card_eight_bottom_right">
                                                        <h4>$99.00 <sub>/Per person</sub>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav">
                                <button type="button" role="presentation" class="owl-prev">
                                    <i class="fas fa-arrow-left"></i>
                                </button>
                                <button type="button" role="presentation" class="owl-next">
                                    <i class=" fas fa-arrow-right"></i>
                                </button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="home_news_four" class="section_padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="section_heading_left">
                            <h2>Blogs</h2>
                            {/* <p>Nostrud aliqua ipsum dolore velit labore nulla fugiat.</p> */}
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="section_heading_left_view_all">
                            <a href="news.html">View all</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="news_card_four_wrapper">
                            <div class="news_card_four_img">
                                <a href="news-details.html">
                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/news/news-6.png" alt="img" />
                                </a>
                            </div>
                            <div class="news_card_four_heading">
                                <h3>
                                    <a href="news-details.html">
                                        Revolutionizing the travel industry, one partnership at a time
                                    </a>
                                </h3>
                                <ul>
                                    <li>24th January <i class="fas fa-circle"></i></li>
                                    <li>5min read</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="news_card_four_wrapper">
                            <div class="news_card_four_img">
                                <a href="news-details.html">
                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/news/news-5.png" alt="img" />
                                </a>
                            </div>
                            <div class="news_card_four_heading">
                                <h3>
                                    <a href="news-details.html">
                                        It is a long established fact that a reader will be distracted.
                                    </a>
                                </h3>
                                <ul>
                                    <li>24th January <i class="fas fa-circle"></i></li>
                                    <li>5min read</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="news_card_four_wrapper">
                            <div class="news_card_four_img">
                                <a href="news-details.html">
                                    <img src="https://andit.co/projects/html/and-tour/demo/assets/img/news/news-6.png" alt="img" />
                                </a>
                            </div>
                            <div class="news_card_four_heading">
                                <h3>
                                    <a href="news-details.html">
                                        There are many variations of passages of sum available
                                    </a>
                                </h3>
                                <ul>
                                    <li>24th January <i class="fas fa-circle"></i></li>
                                    <li>5min read</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="home_six_news_area" class=" section_padding slider_btn">
            <div class="container">
                <div class="col-12">
                    <div class="home_six_section_heading">
                        {/* <h5>News</h5> */}
                        <h2> Blogs</h2>
                    </div>
                    <div class="home_six_news_slider_wrapper owl-theme owl-carousel owl-loaded owl-drag">
                        <div class="owl-stage-outer">
                            <div class="owl-stage">
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item active col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item active col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item active col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list" >
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>8<br></br>
                                                    MAR
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item cloned col-md-4"  >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>26 <br></br>
                                                    DEC
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Mollit exercitation voluptate veniam
                                                excepteur ullamco nisi non sit</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-item clone col-md-4" >
                                    <div class="news_card_six">
                                        <div class="news_card_six_img">
                                            <a href="news-details.html">
                                                <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img" />
                                            </a>
                                        </div>
                                        <div class="news_card_six_text">
                                            <div class="news_card_six_top">
                                                <h3>14 <br></br>
                                                    FEB
                                                </h3>
                                            </div>
                                            <ul class="news_card_six_list">
                                                <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                                                <li>Admin <i class="fas fa-square"></i></li>
                                                <li>Comments (8)</li>
                                            </ul>
                                            <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                                                esse incididunt officia laboris</a>
                                            </h3>
                                            <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                        <div class="owl-dots"><button role="button" class="owl-dot active"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button></div>
                    </div>
                </div>
            </div>
        </section>

        <footer id="footer_area">
            <div class="container">
                <div className='row mb-5'>
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="copyright_right">
                            <img src="https://www.kanootravel.com/kanootravel.com/images/kanoologo.png" alt="img" style={{ float: "left" }} />
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>OUR COMPANY</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><a href="about.html">About Us</a></li>
                                <li><a href="testimonials.html">YBA Kanoo</a></li>
                                <li><a href="faqs.html">Services</a></li>
                                <li><a href="terms-service.html">Awards</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>PRESENCE</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><a href="room-details.html">United Arab Emirates</a></li>
                                <li><a href="hotel-details.html">Saudi Arabiak</a></li>
                                <li><a href="hotel-booking.html">Bahrain</a></li>
                                <li><a href="tour-search.html">Oman</a></li>
                                <li><a href="tour-booking.html">Egypt </a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>MEDIA CENTRE</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><a href="room-details.html">Press Releases</a></li>
                                <li><a href="hotel-details.html">Media Library</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>POLICIES</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><a href="dashboard.html">Privacy Policy</a></li>
                                <li><a href="faq.html">Terms & Conditions</a></li>
                                <li><a href="testimonials.html">Website Acceptable Use Policy</a></li>
                                <li><a href="contact.html">Cookies Policy</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>GET IN TOUCH</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><a href="top-destinations-details.html">Contact Us</a></li>
                                <li><a href="top-destinations-details.html">Disclaimer</a></li>
                                <li><a href="flight-search-result.html">FAQs</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="co-lg-12 col-md-6 col-sm-12 col-12 ">
                    <div class="copyright_right text-right">

                        <div class="d-flex float-end" style={{ color: "red" }}>
                            <span className="ms-2"><a href="#!"><i class="fab fa-facebook"></i></a></span>
                            <span className="ms-2"><a href="#!"><i class="fab fa-twitter-square"></i></a></span>
                            <span className="ms-2"><a href="#!"><i class="fab fa-instagram"></i></a></span>
                            <span className="ms-2"><a href="#!"><i class="fab fa-linkedin"></i></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="copyright_area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_left">
                            <p>© Copyright Kanoo Travel. All Rights Reserved</p>
                        </div>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_right">
                            <span><img src="https://www.kanootravel.com/kanootravel.com/images/pg2.png" alt="img" /><img src="https://www.kanootravel.com/kanootravel.com/images/pg3.png" className="ms-2" alt="img2"></img></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="go-top">
            <i class="fas fa-chevron-up"></i>
            <i class="fas fa-chevron-up"></i>
        </div>

    </Fragment>

};

export default HomePageBottomArea;