
import { Fragment } from 'react';

const LoadingSpinner = () => {
  return (
    <Fragment>
      {/* <div className='centered'>
        <div className={classes.spinner}></div>
      </div> */}


        <div className='loading'>
          <div className="loader">
            <div className="plane">
              <img src="assets/img/loader5.gif" className="plane-img" />
            </div>
            <div className='postion-relative'>
              <div className="earth-wrapper">
                <div className="earth"></div>
              </div> 
            </div> 
            <div className="iata_code arrival_city">Globetrotter</div>
          </div>
        </div>
    </Fragment>)
}

export default LoadingSpinner;
