import React from "react";
import { Fragment ,useEffect} from "react";

const Session = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
     
    },)
  return (   

     <Fragment>
        {/* <section className="gt_session_expired_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        <div className=" text-end gt_error_banner-text">
                            <div className="title text-center">
                                <h1 className="text-white h1 mb-2"><i className="fas fa-clock-o"></i> Your sssion has expired.</h1>
                                <div className="h6"><a className="text-white" href="/Login">Click here for a new session  <i className="fas fa-arrow-right"></i></a></div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>       */}

        <section className="bg-white">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className=" mt-5 py-5">
                            <img src="assets/img/session-bg.png" alt="img"  className="img-responsive pb-3"/>
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto">
                        <div className=" text-end ">
                            <div className="title text-center">
                                <div className="">
                                    <h1 className="text-primary h1 mb-2"><i className="fas fa-clock-o"></i> Your session has expired.</h1>
                                    <div className="h6"><a className="text-primary" href="/Login">Click here for a new session <i class="fa fa-link" aria-hidden="true"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
       
    </Fragment>

  );
};
export default Session;
