import {
    GET_ALL_USER,
    GET_USER,
    SEARCH_USER,
    DELETE_USER,
    UPDATE_USER,
    ADD_USER,
    ROLE_OFFICE_WISE_LIST,
}
    from './CONSTANTS'

export async function getAllUser() {
    const response = await fetch(GET_ALL_USER(), {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || "could not fetch user");
    }
    return data;
}

export async function searchUser(searchData) {
    //console.log(searchData);
    const response = await fetch(SEARCH_USER(), {
        method: "POST",
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch user');
    }
   // console.log(data);
    return data;

}

export async function getSingleUser(ID) {
    const response = await fetch(GET_USER(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch user');
    }
    return data;
}

export async function deleteSingleUser(deleteData) {
    const response = await fetch(DELETE_USER(), {
        method: "DELETE",
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'could not delete user');
    }
    return data;
};

export async function updateUser(updateData) {
    console.log(updateData);
    const response = await fetch(UPDATE_USER(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update user');
    }
    console.log(data);
    return data;

};

export async function addUser(addData){
    console.log(addData);
    const response =await fetch(ADD_USER(),{
        method:"POST",
        body:JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add user');
    }
    console.log(data);
    return data;

}

export async function RoleOfficeWiseList(searchData) {
   // console.log(searchData);
    const response = await fetch(ROLE_OFFICE_WISE_LIST(), {
        method: "POST",
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data =await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch user');
    }
    //
    return data;

}