import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import FlightItemGds from '../../components/Common/Flight/FlightItemGds';
import FareSummary from '../../components/Common/Flight/FareSummary';
import PrintConfirmation from '../../components/Common/Flight/PrintConfirmation';
import SendEmail from '../BookingConfirmation/SendEmail';
import { Tooltip as ReactTooltip } from "react-tooltip";
import TravellerInfo from '../BookingConfirmation/Components/TravellerInfo';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import PopupModal from '../../components/Common/Flight/PrintPopupModal';

const RetrieveGdsTrip = (props) => {
    useDocumentTitle('View Sabre PNR');

    let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
    let pnr = bookingJSON.data.bookingReferenceID !== undefined ? bookingJSON.data.bookingReferenceID[0].id : null;

    const [errorMail, setErrorMail] = useState();

    const onSendEmail = () => {
        setErrorMail({
            title: "Send Itinerary",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <SendEmail mailData={bookingJSON} hideData="0" errorHandler={errorHandler}></SendEmail>
                </div></div>,
        })
    }

    const errorHandler = () => {
        setErrorMail(null);
    };

    return <Fragment>
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        <div >
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-3">
                                <div className="col-lg-12">
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i>Booking Details </label>
                                        </div>
                                        <div className='col-md-5'>
                                            {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && <div className='float-lg-end'>
                                                <PrintConfirmation page="retrieveGds"></PrintConfirmation>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {bookingJSON.data.bookingReferenceNumber !== undefined && bookingJSON.data.bookingReferenceNumber !== '' && props.hideData !== '1' && <div className='col-md-12'>
                                                <div className='text-muted h6 mb-2 mt-2'>
                                                    <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                                                    {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                            </div>}
                                            {props.hideData !== '1' && <div className='col-md-12'>
                                                <div className='text-muted h6 mb-2 mt-2'>
                                                    {pnr !== null && <span> <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <FlightItemGds
                                            key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            airItinerary={bookingJSON.data.airItinerary[0]}
                                            searchFlight={bookingJSON.data.airItinerary[0]}
                                            bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                            fareRuleData={bookingJSON.data.airItinerary[0]}
                                            showData="3"
                                            hideIcon="2"
                                            hideCommision="4"
                                        />

                                        <div className="tour_details_boxed_inner">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion_flex_area">
                                                    <div className="accordion-item xyz pb-0">
                                                        <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                            Price
                                                        </h5>

                                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                                            <div className='row my-2'>
                                                                <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                                {props.hideData !== '1' && <TravellerInfo bookingJSON={bookingJSON}></TravellerInfo>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
};

export default RetrieveGdsTrip;