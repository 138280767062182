import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortData } from "../../../../utils";
import RatePlanItem from './RatePlanItem'
import { deleteSingleRatePlan } from '../../../../services/RatePlan-API'
import useHttp from '../../../../services/use-http'
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
const RatePlanList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.RatePlan']);
    const queryParams = new URLSearchParams(location.search);
    const isSortingAscending = queryParams.get('sort') === 'asc';
    const sortedRatePlanName = sortData(props.ratePlan, 'code', isSortingAscending);
    const [error1, setError] = useState();


    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };
    //Code for delete
    const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleRatePlan, true);
    let isErrorOnDelete = false;
    useEffect(() => {

        window.scrollTo({
            top: 200,
            behavior: "smooth",
        });
        history.push({
            pathname: '/SearchRatePlan',
            search: `?sort=${('asc')}`
        })

        if (status === 'pending') {
            <Fragment>
                <section id='common_banner'><div className='container'></div></section>
                <div className='centered'>
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </Fragment>
        }

        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'TRUE') {
                if (loadedQuote['errors'].error['code'] === '1010') {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordInUseDelete')}`,
                    });
                }
                else {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordDelete')}`,
                    });
                }
            }
            else {
                setError({
                    title: "Message",
                    message: `${t('recordDeletedMessage')}`,
                });
            }
        }
    }, [status, history, loadedQuote, t])

    const deleteItemsHandler = (props) => {
        const onDeleteRatePlan = ({
            id: props.id,
            traceID: "",
            userID: "",
            ipAddress: "",
            code: "",
            gtCode: "",
            gtName: "",
            default: true,
        });

        sendRequest(onDeleteRatePlan);

        if (error) {
            return <p className='centered focused'>{t('errorRecordDelete')}</p>
        }
        if ((!loadedQuote || loadedQuote.length === 0)) {
            return <p>{t('errorRecordDelete')}</p>
        }
    };
    //End code for delete
    const errorHandler = () => {
        setError(null);
        if (!isErrorOnDelete) {
            history.go({
                pathname: '/SearchRatePlan'
            })
        }
        else {
            history.push({
                pathname: '/SearchRatePlan'
            })
        }
    };



    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <div className="container-fluid mb-3 list-view">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6"></div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">{t('sortBy')}</div>
                        </div>
                        <div className="col-md-4 form-group">
                            <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                                <option>{t('sortRatePlanCodeDsc')}</option>
                                <option>{t('sortRatePlanCodeAsc')}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {sortedRatePlanName.map((item) => (
                <RatePlanItem
                    onDelete={deleteItemsHandler}
                    key={item.id}
                    id={item.id}
                    ratePlanName={item.gtName}
                    ratePlanCode={item.code}
                    gtCode={item.gtCode}
                    default={item.default}
                />
            ))}
        </div>
    </Fragment>
};
export default RatePlanList;