
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addCreditLimit, getSingleCreditLimit, updateCreditLimit } from '../../../services/CreditLimit-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import CreditLimitForm from './Components/CreditLimitForm';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateCreditLimit = (props) => {
    useDocumentTitle('Credit Limit')
    const { t } = useTranslation(['translation.CreditLimit']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addCreditLimit
    } else {
        varHttp = updateCreditLimit
    }
    const [confirm, setConfirm] = useState();
    const { sendRequest: sendRequest1, data: loadedCreditLimitDetails, error } = useHttpGet(getSingleCreditLimit);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (props.location.edit !== undefined) {
        sessionStorage.setItem('isEditable', props.location.edit);
    } else if (Id !== undefined) {
        if (!checkSecurityDetails("Master", "Credit Limit", "Master_Credit Limit_Update") && props.location.edit === 1)
            history.push('/Unauthorized')
        else if (!checkSecurityDetails("Master", "Credit Limit", "Master_Credit Limit_View") && props.location.edit === 0)
            history.push('/Unauthorized')
    }

    if (props.loadedProfileDetails === undefined && props.location.pathname === '/UpdateCreditLimit') {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        })
    }, []);
    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1016') {
                        setConfirm({
                            title: "Message",
                            message: `Your current credit limit is changed from ${loadedQuote['data'].currencyCode} ${loadedQuote['data'].amount} to ${loadedQuote['data'].currencyCode} ${loadedQuote['data'].oldAmount} , so now your updated Credit limit will be ${loadedQuote['data'].currencyCode} ${loadedQuote['data'].oldAmount} + ${loadedQuote['data'].currencyCode} ${loadedQuote['data'].transactionAmount}. Are you sure you want to update credit limit to ${loadedQuote['data'].currencyCode} ${Math.round((loadedQuote['data'].oldAmount + loadedQuote['data'].transactionAmount) * 100) / 100} ?`,
                        });

                    }
                    else if (loadedQuote['errors'].error['code'] === '1017') {
                        setError({
                            title: "Message",
                            message: 'Credit limit can not be in negative.',
                        });
                    }
                    else if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, loadedQuote, t])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }

    let loadedData = [];
    for (let index in loadedCreditLimitDetails) {
        if (loadedCreditLimitDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedCreditLimitDetails[index];
            }
        }
    }
    const addCreditLimitHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchcreditlimit');
        }
    };



    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    function okHandler() {


        addCreditLimitHandler({
            id: loadedQuote['data'].id,
            amount: loadedQuote['data'].oldAmount,
            transactionAmount: loadedQuote['data'].transactionAmount,
            currencyCode: loadedQuote['data'].currencyCode,
            service: 'MS',
            currencyName: '',
            isSubracted: loadedQuote['data'].isSubracted,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
        })
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };
    const CreditLimitFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <CreditLimitForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddCreditLimit={addCreditLimitHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {confirm && (
            <ConfirmationModal
                title={confirm.title}
                message={confirm.message}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {CreditLimitFormContent}
    </Fragment>
};
export default UpdateCreditLimit
