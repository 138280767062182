import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const RegionItem = (props) => {
  const { t } = useTranslation(['translation.Region']);
  const [confirm, setConfirm] = useState();

  function deleteFormHandler(props) {
    // optional: Could validate here     
    props.onDelete(
      {
        id: props.id,
        traceID: "",
        userID: "",
        regionName: "",
        regionDescription: "",
        moduleDetail: [
          {
            value: "",
            label: "",
            children: [
              {
                value: "",
                label: "",
                children: [
                  {
                    value: "",
                    label: ""
                  }
                ]
              }
            ]
          }
        ]
      }
    );
  }

  function onConfirm() {
    setConfirm(true);
  }

  function okHandler() {
    deleteFormHandler(props)
    setConfirm(false);
  };

  function cancelHandler() {
    setConfirm(false);
  };
  return (
    <Fragment>
      {confirm && (
        <ConfirmationModal
          title={t('confirmationMessageTitle')}
          message={t('confirmationMessage')}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}



      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark card-bg-blue mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    {props.regionName}
                  </h6>
                  <hr className="my-1" />
                </div>
                <div className="col-md-8">
                  <p className="card-text mb-0"><strong>Service: </strong>{props.serviceName}</p>
                </div>

                <div className="col-md-4 my-auto">
                    <div className='float-lg-end float-md-end'>

                          {checkSecurityDetails("Master", "Region", "Master_Region_View") &&
                            <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateRegion/${props.id}/0`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                          }
                           
                          {checkSecurityDetails("Master", "Region", "Master_Region_Update") &&
                            <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateRegion/${props.id}/1`, edit: 1 }}>
                              <i className="fa-solid fa-pen"></i>
                            </Link>}
                            {checkSecurityDetails("Master", "Region", "Master_Region_Delete") &&
                            <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title='Delete'><i className="fas fa-trash-alt"></i></button>
                          }
                           
                    </div>
                </div>

                {/* <div className="col-md-4 my-auto">
                  {checkSecurityDetails("Master", "Region", "Master_Region_Delete") &&
                    <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger float-sm-start float-lg-end ms-1 gt-border-radius-50 gt-red-btn" title='Delete'><i className="fas fa-trash-alt"></i></button>
                  }
                  {checkSecurityDetails("Master", "Region", "Master_Region_Update") &&
                    <Link className="btn btn-sm float-sm-start text-white float-lg-end ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateRegion/${props.id}`, edit: 1 }}>
                      <i className="fa-solid fa-pen"></i>
                    </Link>}
                    {checkSecurityDetails("Master", "Region", "Master_Region_View") &&
                    <Link className="btn btn-sm float-sm-start btn-warning text-white float-lg-end ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateRegion/${props.id}`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                  }
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegionItem;