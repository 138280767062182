import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { cancelTrip, cancelTripShoppingCart, cancelVoidTrip } from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";
import { useHistory } from 'react-router-dom';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { use } from "i18next";
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const CancelPNR = (props) => {
    const inputEmailIdRef = useRef("");
    const [error1, setError1] = useState();
    const [cancelTicketError, setCancelTicketError] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(cancelTrip);
    const { sendRequest: sendCancelVoidTicket, status: cancelVoidStatus, data: loadedCancelVoidTicketDetails } = useHttp(cancelVoidTrip);
    const { sendRequest: sendRequestShoppingCart, status: statusShoppingCart, data: loadedQuoteShoppingCart } = useHttp(cancelTripShoppingCart);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [cancelledHotel, setCancelledHotel] = useState(false);
    const history = useHistory();
    const [invalidServiceFeeError, setInvalidServiceFeeError] = useState(false);
    const [invalidAirlineCancelationChargesError, setInvalidAirlineCancelationChargesError] = useState(false);
    const [invalidRevisedAirlineCommissionError, setInvalidRevisedAirlineCommissionError] = useState(false);
    const [invalidHotelServiceFeeError, setInvalidHotelServiceFeeError] = useState(false);
    const [invalidHotelCancelationChargesError, setInvalidHotelCancelationChargesError] = useState(false);
    const [invalidRevisedHotelCommissionError, setInvalidRevisedHotelCommissionError] = useState(false);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [mandatoryCheckError, setMandatoryCheckError] = useState(false);


    const checkBoxChangeHandler = (event) => {
        setIsCheckBoxChecked(!isCheckBoxChecked)

    }
    function functionExit() {
        props.errorHandler();
        if (props.source !== undefined && props.source === 'flightPlusHotel') {

            if (document.querySelector('#checkServiceBoth') !== null && document.querySelector('#checkServiceBoth').checked === true) {
                if (cancelled && cancelledHotel) {
                    sessionStorage.setItem("myTripIsCanceled", true);
                    sessionStorage.setItem("myTripIsHotelCancelled", true);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: true }
                    });
                    history.go();
                }
                else {
                    sessionStorage.setItem("myTripIsCanceled", false);
                    sessionStorage.setItem("myTripIsHotelCancelled", false);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: false }
                    });
                    history.go();
                }
            }
            else if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceFlight').checked === true) {
                if (cancelled) {
                    sessionStorage.setItem("myTripIsCanceled", true);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: true }
                    });
                    history.go();
                }
                else {
                    sessionStorage.setItem("myTripIsCanceled", false);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: false }
                    });
                    history.go();
                }
            }
            else if (document.querySelector('#checkServiceHotel') !== null && document.querySelector('#checkServiceHotel').checked === true) {
                if (cancelledHotel) {
                    sessionStorage.setItem("myTripIsHotelCancelled", true);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: true }
                    });
                    history.go();
                }
                else {
                    sessionStorage.setItem("myTripIsHotelCancelled", false);

                    history.push({
                        pathname: 'TripDetails',
                        mytrip: { isCanceled: false }
                    });
                    history.go();
                }
            }
        }
        else {
            if (cancelled) {
                sessionStorage.setItem("myTripIsCanceled", true);

                history.push({
                    pathname: '/RetrieveTrip',
                    mytrip: { isCanceled: true }
                });
                history.go();
            }
            else {
                sessionStorage.setItem("myTripIsCanceled", false);

                history.push({
                    pathname: '/RetrieveTrip',
                    mytrip: { isCanceled: false }
                });
                history.go();
            }
        }
    }

    useEffect(() => {
        if (props.mailData.data.reasons.reason !== undefined) {
            inputEmailIdRef.current.value = props.mailData.data.reasons.reason[0].text;

            if (props.source !== undefined && props.source === 'flightPlusHotel') {
                if (document.querySelector('#checkServiceBoth') !== null && document.querySelector('#checkServiceBoth').checked === true) {
                    setCancelled(true);
                    setCancelledHotel(true);
                }
                else if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceFlight').checked === true) {
                    setCancelled(true);
                }
                else if (document.querySelector('#checkServiceHotel') !== null && document.querySelector('#checkServiceHotel').checked === true) {
                    setCancelledHotel(true);
                }
            }
            else {
                setCancelled(true);
            }
        }

        if (document.querySelector('#checkServiceBoth') !== null) {
            document.querySelector('#checkServiceBoth').checked = true;

            if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                document.querySelector('#lblServiceFee').style.display = "";
                document.querySelector('#serviceFee').style.display = "";
            }
            if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                document.querySelector('#lblAirlineCancelationCharge').style.display = "";
                document.querySelector('#airlineCancelationCharge').style.display = "";
            }
            if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                document.querySelector('#lblRevisedAirlineCommission').style.display = "";
                document.querySelector('#revisedAirlineCommission').style.display = "";
            }

            if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                document.querySelector('#lblHotelServiceFee').style.display = "";
                document.querySelector('#hotelServiceFee').style.display = "";
            }
            if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                document.querySelector('#lblHotelCancelationCharge').style.display = "";
                document.querySelector('#hotelCancelationCharge').style.display = "";
            }
            if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                document.querySelector('#lblRevisedHotelCommission').style.display = "";
                document.querySelector('#revisedHotelCommission').style.display = "";
            }
        }

        if (props.source !== undefined && props.source === 'flightPlusHotel') {
            if ((sessionStorage.getItem("myTripIsCanceled") !== null && sessionStorage.getItem("myTripIsCanceled") === 'true') && (sessionStorage.getItem("myTripIsHotelCancelled") !== null && sessionStorage.getItem("myTripIsHotelCancelled") === 'false')) {
                document.querySelector('#divCheckServiceBoth').classList.add("d-none");
                document.querySelector('#divCheckServiceFlight').classList.add("d-none");
                document.querySelector('#checkServiceHotel').checked = true;
                document.querySelector('#txtCancelationReason').value = "";

                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "";
                    document.querySelector('#hotelServiceFee').style.display = "";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "";
                    document.querySelector('#hotelCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "";
                    document.querySelector('#revisedHotelCommission').style.display = "";
                }

                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "none";
                    document.querySelector('#serviceFee').style.display = "none";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "none";
                    document.querySelector('#airlineCancelationCharge').style.display = "none";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "none";
                    document.querySelector('#revisedAirlineCommission').style.display = "none";
                }
            }
            else if ((sessionStorage.getItem("myTripIsCanceled") !== null && sessionStorage.getItem("myTripIsCanceled") === 'false') && (sessionStorage.getItem("myTripIsHotelCancelled") !== null && sessionStorage.getItem("myTripIsHotelCancelled") === 'true')) {
                document.querySelector('#divCheckServiceBoth').classList.add("d-none");
                document.querySelector('#divCheckServiceHotel').classList.add("d-none");
                document.querySelector('#checkServiceFlight').checked = true;
                document.querySelector('#txtCancelationReason').value = "";

                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "";
                    document.querySelector('#serviceFee').style.display = "";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "";
                    document.querySelector('#airlineCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "";
                    document.querySelector('#revisedAirlineCommission').style.display = "";
                }

                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "none";
                    document.querySelector('#hotelServiceFee').style.display = "none";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "none";
                    document.querySelector('#hotelCancelationCharge').style.display = "none";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "none";
                    document.querySelector('#revisedHotelCommission').style.display = "none";
                }
            }
            else if (sessionStorage.getItem("isHotelFailed") !== null && sessionStorage.getItem("isHotelFailed") === 'true') {
                document.querySelector('#divCheckServiceBoth').classList.add("d-none");
                document.querySelector('#divCheckServiceHotel').classList.add("d-none");
                document.querySelector('#checkServiceFlight').checked = true;
                document.querySelector('#txtCancelationReason').value = "";

                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "";
                    document.querySelector('#serviceFee').style.display = "";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "";
                    document.querySelector('#airlineCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "";
                    document.querySelector('#revisedAirlineCommission').style.display = "";
                }

                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "none";
                    document.querySelector('#hotelServiceFee').style.display = "none";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "none";
                    document.querySelector('#hotelCancelationCharge').style.display = "none";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "none";
                    document.querySelector('#revisedHotelCommission').style.display = "none";
                }
            }
        }

    }, [props.bookingJSON])
    const errorHandler1 = () => {
        setError1(null);
        setCancelTicketError(null);
    };
    function okHandler() {
        setCancelTicketError(null);
        let bookingJSON = props.source !== undefined && sessionStorage.getItem("sourcePNR") !== null ? JSON.parse(sessionStorage.getItem("BookingData")) : JSON.parse(localStorage.getItem("BookingData"));
        let cancellationRemarks = [];
        if (props.source !== undefined) {
            cancellationRemarks = JSON.stringify({
                "Reason": [
                    {
                        "Type": "cancel",
                        "Language": null,
                        "Text": inputEmailIdRef.current.value,
                        "ServiceFee": document.querySelector('#serviceFee') !== null ? document.querySelector('#serviceFee').value === '' ? "0" : document.querySelector('#serviceFee').value : "0",
                        "CancellationCharge": document.querySelector('#airlineCancelationCharge') !== null ? document.querySelector('#airlineCancelationCharge').value === '' ? "0" : document.querySelector('#airlineCancelationCharge').value : "0",
                        "Commission": document.querySelector('#revisedAirlineCommission') !== null ? document.querySelector('#revisedAirlineCommission').value === '' ? "0" : document.querySelector('#revisedAirlineCommission').value : "0"
                    }
                ]
            });
        }
        bookingJSON.data.reasons = JSON.parse(cancellationRemarks);
        bookingJSON.data.traceId = JSON.parse(localStorage.getItem('traceID'));
        bookingJSON.data.travelAgentName = JSON.parse(localStorage.getItem('displayName'));
        sendCancelVoidTicket(bookingJSON)
    };

    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote.data.errors.status === 'FALSE') {
                setCancelled(true);
                document.querySelector('#btnSubmit').classList.add("d-none");
                document.querySelector('#msgArea').innerHTML = "Trip canceled successfully.";
                document.querySelector('#msgArea').classList.add("success-text");
                document.querySelector('#msgArea').classList.remove("error-text");
            } else {
                if (loadedQuote.data.errors.error.type === 'SEGMENT_NOT_FOUND') {
                    setCancelled(true);
                    document.querySelector('#btnSubmit').classList.add("d-none");
                    document.querySelector('#msgArea').innerHTML = "Trip already canceled.";
                    document.querySelector('#msgArea').classList.add("success-text");
                    document.querySelector('#msgArea').classList.remove("error-text");
                } else if (loadedQuote.data.errors.error.code === 'CancelVoidTicketing') {
                    setCancelTicketError({
                        title: "Message",
                        message: loadedQuote.data.errors.error.description,
                    });
                    document.querySelector('#btnSubmit').classList.add("d-none");
                    return;

                }
                else {
                    setCancelled(false);
                    document.querySelector('#btnSubmit').classList.remove("d-none");
                    document.querySelector('#msgArea').innerHTML = "Some error has occurred.";
                    document.querySelector('#msgArea').classList.add("error-text");
                    document.querySelector('#msgArea').classList.remove("success-text");
                }
            }
            return;
        }

    }, [status])
    useEffect(() => {
        if (cancelVoidStatus === 'completed') {
            if (loadedCancelVoidTicketDetails.errors.status === 'FALSE') {

                document.querySelector('#btnSubmit').classList.add("d-none");
                document.querySelector('#msgArea').innerHTML = "The trip is being canceled by our team. We will advise once done.";
                document.querySelector('#msgArea').classList.add("success-text")
                document.querySelector('#msgArea').classList.remove("error-text");
            } else if (loadedCancelVoidTicketDetails.errors.error.description === 'Ticket Already in Queue') {

                document.querySelector('#btnSubmit').classList.add("d-none");
                document.querySelector('#msgArea').innerHTML = "Ticket Already in Queue.";
                document.querySelector('#msgArea').classList.add("success-text")
                document.querySelector('#msgArea').classList.remove("error-text");
            }
            else {

                document.querySelector('#btnSubmit').classList.add("d-none");
                document.querySelector('#msgArea').innerHTML = "Some error has occurred.";
                document.querySelector('#msgArea').classList.add("error-text");
                document.querySelector('#msgArea').classList.remove("success-text");
            }
            return;
        }
    }, [cancelVoidStatus])

    useEffect(() => {
        if (statusShoppingCart === 'completed') {
            if ((sessionStorage.getItem("myTripIsCanceled") !== null && sessionStorage.getItem("myTripIsCanceled") === 'true') && (sessionStorage.getItem("myTripIsHotelCancelled") !== null && sessionStorage.getItem("myTripIsHotelCancelled") === 'false')) {
                setCancelled(true);
            }
            else if ((sessionStorage.getItem("myTripIsCanceled") !== null && sessionStorage.getItem("myTripIsCanceled") === 'false') && (sessionStorage.getItem("myTripIsHotelCancelled") !== null && sessionStorage.getItem("myTripIsHotelCancelled") === 'true')) {
                setCancelledHotel(true);
            }

            if (loadedQuoteShoppingCart.data.errors.status === 'FALSE') {
                document.querySelector('#btnSubmit').classList.add("d-none");
                document.querySelector('#msgArea').innerHTML = "Trip canceled successfully.";
                document.querySelector('#msgArea').classList.add("success-text");
                document.querySelector('#msgArea').classList.remove("error-text");

                if (document.querySelector('#checkServiceBoth') !== null && document.querySelector('#checkServiceBoth').checked === true) {
                    document.querySelector('#msgArea').innerHTML = "Trip canceled successfully.";
                    setCancelled(true);
                    setCancelledHotel(true);
                }
                else if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceFlight').checked === true) {
                    document.querySelector('#msgArea').innerHTML = "Flight canceled successfully.";
                    setCancelled(true);
                }
                else if (document.querySelector('#checkServiceHotel') !== null && document.querySelector('#checkServiceHotel').checked === true) {
                    document.querySelector('#msgArea').innerHTML = "Hotel canceled successfully.";
                    setCancelledHotel(true);
                }

                if (document.querySelector('#checkServiceFlight').checked === true) {
                    sessionStorage.setItem("myTripIsCanceled", 'true');
                }
                else if (document.querySelector('#checkServiceHotel').checked === true) {
                    sessionStorage.setItem("myTripIsHotelCancelled", 'true');
                } else {
                    sessionStorage.setItem("myTripIsCanceled", 'true');
                    sessionStorage.setItem("myTripIsHotelCancelled", 'true');
                }
            } else {
                if (loadedQuoteShoppingCart.data.errors.error.type === 'SEGMENT_NOT_FOUND') {
                    document.querySelector('#btnSubmit').classList.add("d-none");
                    document.querySelector('#msgArea').innerHTML = "Trip already canceled.";
                    document.querySelector('#msgArea').classList.add("success-text");
                    document.querySelector('#msgArea').classList.remove("error-text");

                    if (document.querySelector('#checkServiceBoth') !== null && document.querySelector('#checkServiceBoth').checked === true) {
                        setCancelled(true);
                        setCancelledHotel(true);
                    }
                    else if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceFlight').checked === true) {
                        setCancelled(true);
                    }
                    else if (document.querySelector('#checkServiceHotel') !== null && document.querySelector('#checkServiceHotel').checked === true) {
                        setCancelledHotel(true);
                    }

                } else {
                    document.querySelector('#btnSubmit').classList.remove("d-none");
                    document.querySelector('#msgArea').innerHTML = "Some error has occurred.";
                    document.querySelector('#msgArea').classList.add("error-text");
                    document.querySelector('#msgArea').classList.remove("success-text");

                    if (document.querySelector('#checkServiceBoth') !== null && document.querySelector('#checkServiceBoth').checked === true) {
                        setCancelled(false);
                        setCancelledHotel(false);
                    }
                    else if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceFlight').checked === true) {
                        setCancelled(false);
                    }
                    else if (document.querySelector('#checkServiceHotel') !== null && document.querySelector('#checkServiceHotel').checked === true) {
                        setCancelledHotel(false);
                    }
                }
            }
            return;
        }

    }, [statusShoppingCart])



    function submitFormHandler() {

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        if (document.querySelector('#serviceFee') !== null && document.querySelector('#serviceFee').value !== '' && !document.querySelector('#serviceFee').value.match('^[0-9.]+$')) {
            document.querySelector('#serviceFee').focus();
            setInvalidServiceFeeError(true);
            return;
        }
        else {
            setInvalidServiceFeeError(false);
        }

        if (document.querySelector('#airlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge').value !== '' && !document.querySelector('#airlineCancelationCharge').value.match('^[0-9.]+$')) {
            document.querySelector('#airlineCancelationCharge').focus();
            setInvalidAirlineCancelationChargesError(true);
            return;
        }
        else {
            setInvalidAirlineCancelationChargesError(false);
        }

        if (document.querySelector('#revisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission').value !== '' && !document.querySelector('#revisedAirlineCommission').value.match('^[0-9.]+$')) {
            document.querySelector('#revisedAirlineCommission').focus();
            setInvalidRevisedAirlineCommissionError(true)
            return;
        }
        else {
            setInvalidRevisedAirlineCommissionError(false);
        }

        if (props.source !== undefined && props.source === 'flightPlusHotel') {
            if (document.querySelector('#hotelServiceFee') !== null && document.querySelector('#hotelServiceFee').value !== '' && !document.querySelector('#hotelServiceFee').value.match('^[0-9.]+$')) {
                document.querySelector('#hotelServiceFee').focus();
                setInvalidHotelServiceFeeError(true)
                return;
            }
            else {
                setInvalidHotelServiceFeeError(false);
            }

            if (document.querySelector('#hotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge').value !== '' && !document.querySelector('#hotelCancelationCharge').value.match('^[0-9.]+$')) {
                document.querySelector('#hotelCancelationCharge').focus();
                setInvalidHotelCancelationChargesError(true);
                return;
            }
            else {
                setInvalidHotelCancelationChargesError(false);
            }

            if (document.querySelector('#revisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission').value !== '' && !document.querySelector('#revisedHotelCommission').value.match('^[0-9.]+$')) {
                document.querySelector('#revisedHotelCommission').focus();
                setInvalidRevisedHotelCommissionError(true)
                return;
            }
            else {
                setInvalidRevisedHotelCommissionError(false);
            }
        }
        if (!isCheckBoxChecked) {
            document.querySelector('#checkBox').focus();
            setMandatoryCheckError(true)
            return;
        }
        else {
            setMandatoryCheckError(false);
        }
        let bookingJSON = props.source !== undefined && props.source === 'flightPlusHotel' ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : sessionStorage.getItem("sourcePNR") !== null ? JSON.parse(sessionStorage.getItem("BookingData")) : JSON.parse(localStorage.getItem("BookingData"));
        let cancellationRemarks = [];

        if (props.source !== undefined && props.source === 'flightPlusHotel') {
            cancellationRemarks = JSON.stringify({
                "Reason": [
                    {
                        "Type": "cancel",
                        "Language": null,
                        "Text": inputEmailIdRef.current.value,
                        "ServiceFee": document.querySelector('#serviceFee') !== null ? document.querySelector('#serviceFee').value === '' ? "0" : document.querySelector('#serviceFee').value : "0",
                        "HotelServiceFee": document.querySelector('#hotelServiceFee') !== null ? document.querySelector('#hotelServiceFee').value === '' ? "0" : document.querySelector('#hotelServiceFee').value : "0",
                        "CancellationCharge": document.querySelector('#airlineCancelationCharge') !== null ? document.querySelector('#airlineCancelationCharge').value === '' ? "0" : document.querySelector('#airlineCancelationCharge').value : "0",
                        "CancellationChargeHotel": document.querySelector('#hotelCancelationCharge') !== null ? document.querySelector('#hotelCancelationCharge').value === '' ? "0" : document.querySelector('#hotelCancelationCharge').value : "0",
                        "Commission": document.querySelector('#revisedAirlineCommission') !== null ? document.querySelector('#revisedAirlineCommission').value === '' ? "0" : document.querySelector('#revisedAirlineCommission').value : "0",
                        "HotelCommission": document.querySelector('#revisedHotelCommission') !== null ? document.querySelector('#revisedHotelCommission').value === '' ? "0" : document.querySelector('#revisedHotelCommission').value : "0"
                    }
                ]
            });
        }
        else {
            cancellationRemarks = JSON.stringify({
                "Reason": [
                    {
                        "Type": "cancel",
                        "Language": null,
                        "Text": inputEmailIdRef.current.value,
                        "ServiceFee": document.querySelector('#serviceFee') !== null ? document.querySelector('#serviceFee').value === '' ? "0" : document.querySelector('#serviceFee').value : "0",
                        "CancellationCharge": document.querySelector('#airlineCancelationCharge') !== null ? document.querySelector('#airlineCancelationCharge').value === '' ? "0" : document.querySelector('#airlineCancelationCharge').value : "0",
                        "Commission": document.querySelector('#revisedAirlineCommission') !== null ? document.querySelector('#revisedAirlineCommission').value === '' ? "0" : document.querySelector('#revisedAirlineCommission').value : "0"
                    }
                ]
            });
        }
        bookingJSON.data.reasons = JSON.parse(cancellationRemarks);
        bookingJSON.data.traceId = JSON.parse(localStorage.getItem('traceID'));
        bookingJSON.data.travelAgentName = JSON.parse(localStorage.getItem('displayName'));
        if (props.source !== undefined && props.source === 'flightPlusHotel') {
            bookingJSON.data.CancellationType = {
                Type: document.querySelector('#checkServiceBoth').checked === true ? "F" : "P"
            }
        }

        if (document.querySelector('#checkServiceFlight') !== null && document.querySelector('#checkServiceHotel') !== null) {
            if (document.querySelector('#checkServiceFlight').checked === true) {
                let flight = delete bookingJSON.data.hotelResults;
            }
            else if (document.querySelector('#checkServiceHotel').checked === true) {
                let hotel = delete bookingJSON.data.airItinerary;
            }
        }

        if (props.source !== undefined && props.source === 'flightPlusHotel') {
            sendRequestShoppingCart(
                bookingJSON
            );
        }
        else {
            sendRequest(
                bookingJSON
            );
        }

    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    //User Story 2409: New fields in Flight Cancelation pop-up
    const onServiceTypeHandler = (event) => {
        document.querySelector('#txtCancelationReason').value = "";
        if (document.querySelector('#serviceFee') !== null) {
            document.querySelector('#serviceFee').value = "";
        }
        if (document.querySelector('#airlineCancelationCharge') !== null) {
            document.querySelector('#airlineCancelationCharge').value = "";
        }
        if (document.querySelector('#revisedAirlineCommission') !== null) {
            document.querySelector('#revisedAirlineCommission').value = "";
        }
        if (document.querySelector('#hotelServiceFee') !== null) {
            document.querySelector('#hotelServiceFee').value = "";
        }
        if (document.querySelector('#hotelCancelationCharge') !== null) {
            document.querySelector('#hotelCancelationCharge').value = "";
        }
        if (document.querySelector('#revisedHotelCommission') !== null) {
            document.querySelector('#revisedHotelCommission').value = "";
        }

        setBlankMailError(false);
        setInvalidServiceFeeError(false);
        setInvalidAirlineCancelationChargesError(false);
        setInvalidRevisedAirlineCommissionError(false);
        setInvalidHotelServiceFeeError(false);
        setInvalidHotelCancelationChargesError(false);
        setInvalidRevisedHotelCommissionError(false);

        if (document.querySelector('#checkServiceFlight') !== null) {
            if (event.target.id === 'checkServiceFlight') {
                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "";
                    document.querySelector('#serviceFee').style.display = "";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "";
                    document.querySelector('#airlineCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "";
                    document.querySelector('#revisedAirlineCommission').style.display = "";
                }
                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "none";
                    document.querySelector('#hotelServiceFee').style.display = "none";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "none";
                    document.querySelector('#hotelCancelationCharge').style.display = "none";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "none";
                    document.querySelector('#revisedHotelCommission').style.display = "none";
                }
            }
            else if (event.target.id === 'checkServiceHotel') {
                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "none";
                    document.querySelector('#serviceFee').style.display = "none";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "none";
                    document.querySelector('#airlineCancelationCharge').style.display = "none";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "none";
                    document.querySelector('#revisedAirlineCommission').style.display = "none";
                }
                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "";
                    document.querySelector('#hotelServiceFee').style.display = "";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "";
                    document.querySelector('#hotelCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "";
                    document.querySelector('#revisedHotelCommission').style.display = "";
                }
            }
            else {
                if (document.querySelector('#lblServiceFee') !== null && document.querySelector('#serviceFee') !== null) {
                    document.querySelector('#lblServiceFee').style.display = "";
                    document.querySelector('#serviceFee').style.display = "";
                }
                if (document.querySelector('#lblAirlineCancelationCharge') !== null && document.querySelector('#airlineCancelationCharge') !== null) {
                    document.querySelector('#lblAirlineCancelationCharge').style.display = "";
                    document.querySelector('#airlineCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedAirlineCommission') !== null && document.querySelector('#revisedAirlineCommission') !== null) {
                    document.querySelector('#lblRevisedAirlineCommission').style.display = "";
                    document.querySelector('#revisedAirlineCommission').style.display = "";
                }
                if (document.querySelector('#lblHotelServiceFee') !== null && document.querySelector('#hotelServiceFee') !== null) {
                    document.querySelector('#lblHotelServiceFee').style.display = "";
                    document.querySelector('#hotelServiceFee').style.display = "";
                }
                if (document.querySelector('#lblHotelCancelationCharge') !== null && document.querySelector('#hotelCancelationCharge') !== null) {
                    document.querySelector('#lblHotelCancelationCharge').style.display = "";
                    document.querySelector('#hotelCancelationCharge').style.display = "";
                }
                if (document.querySelector('#lblRevisedHotelCommission') !== null && document.querySelector('#revisedHotelCommission') !== null) {
                    document.querySelector('#lblRevisedHotelCommission').style.display = "";
                    document.querySelector('#revisedHotelCommission').style.display = "";
                }
            }
        }
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}
        {cancelTicketError && (
            <ConfirmationModal
                title={cancelTicketError.title}
                message={cancelTicketError.message}
                onOk={okHandler}
                onCancel={errorHandler1}
            ></ConfirmationModal>
        )}

        <div className="row">
            <div className="col-md-12 gt-h-340px">
                <div className="control">
                    {
                        props.source !== undefined && props.source === 'flightPlusHotel' &&
                        <div className="row">
                            <div className="col-md-4">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Cancel Service <span className="error-icon"> *</span>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <div className='row'>
                                    <div id="divCheckServiceBoth" className="form-check col-md-4">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="checkServiceBoth" value="white"
                                            onClick={onServiceTypeHandler}
                                        />
                                        <label className="form-check-label" for="checkCreditBoth">
                                            Both
                                        </label>
                                    </div>
                                    <div id="divCheckServiceFlight" className="form-check col-md-4">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="checkServiceFlight" value="white"
                                            onClick={onServiceTypeHandler}
                                        />
                                        <label className="form-check-label" for="checkServiceFlight">
                                            Flight
                                        </label>
                                    </div>
                                    <div id="divCheckServiceHotel" className="form-check col-md-4">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="checkServiceHotel" value="white"
                                            onClick={onServiceTypeHandler}
                                        />
                                        <label className="form-check-label" for="checkServiceHotel">
                                            Hotel
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <label className="h6 text-primary mb-2 col-lg-12" for="category"> Cancelation Reason <span className="error-icon"> *</span>
                    </label>
                    <div>
                        <textarea id="txtCancelationReason" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" ref={inputEmailIdRef}
                            autoFocus>
                        </textarea>
                        {balnkMailError && <p className="error-text">Cancelation Reason is required.</p>}
                        {invalidEmailError && <p className="error-text">Cancelation Reason is not valid.</p>}
                    </div>
                    {/* User Story 2409: New fields in Flight Cancelation pop-up */}
                    <div className="row">
                        {
                            getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                            <div className="col-lg-12">
                                <label id="lblServiceFee" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Service Fee </label>
                                <input type="number" id="serviceFee" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidServiceFeeError && <p className="error-text">Service Fee is not valid.</p>}
                            </div>
                        }
                        {
                            getConfigurationByBoolen("ALLOW_B2B") &&
                            <div className="col-lg-6">
                                <label id="lblAirlineCancelationCharge" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Airline Cancelation Charges </label>
                                <input type="number" id="airlineCancelationCharge" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidAirlineCancelationChargesError && <p className="error-text">Airline Cancelation Charges is not valid.</p>}
                            </div>
                        }
                        {
                            getConfigurationByBoolen("ALLOW_COMISSION") &&
                            <div className="col-lg-6">
                                <label id="lblRevisedAirlineCommission" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Revised Airline Commission </label>
                                <input type="number" id="revisedAirlineCommission" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidRevisedAirlineCommissionError && <p className="error-text">Revised Airline Commission is not valid.</p>}
                            </div>
                        }
                    </div>
                    {
                        props.source !== undefined && props.source === 'flightPlusHotel' &&
                        <>
                            <div className="row">
                                {
                                    getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                                    <div className="col-lg-12">
                                        <label id="lblHotelServiceFee" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Hotel Service Fee </label>
                                        <input type="number" id="hotelServiceFee" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                        </input>
                                        {invalidHotelServiceFeeError && <p className="error-text">Hotel Service Fee is not valid.</p>}
                                    </div>
                                }
                                {
                                    getConfigurationByBoolen("ALLOW_B2B") &&
                                    <div className="col-lg-6">
                                        <label id="lblHotelCancelationCharge" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Hotel Cancelation Charges </label>
                                        <input type="number" id="hotelCancelationCharge" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                        </input>
                                        {invalidHotelCancelationChargesError && <p className="error-text">Hotel Cancelation Charges is not valid.</p>}
                                    </div>
                                }
                                {
                                    getConfigurationByBoolen("ALLOW_COMISSION") &&
                                    <div className="col-lg-6">
                                        <label id="lblRevisedHotelCommission" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Revised Hotel Commission </label>
                                        <input type="number" id="revisedHotelCommission" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                        </input>
                                        {invalidRevisedHotelCommissionError && <p className="error-text">Revised Hotel Commission is not valid.</p>}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    <div className='text-black col-md-12 mt-2'>
                        <input className="form-check-input tandc-payment-option" type="checkbox" id="checkBox" checked={isCheckBoxChecked} onChange={checkBoxChangeHandler}></input>&nbsp;<span> I understand and agree that cancelation will be subject to applicable penalties from the provider.</span>
                        {mandatoryCheckError && < p className="error-text">Please agree to the cancelation conditions .</p>}
                    </div>
                    <p id="msgArea"></p>
                </div>
            </div>
            <div className="row">
                <div className="my-auto col-md-6"><span className="error-icon">*</span><span id='requiredIndicator'>Indicates required fields</span></div>
                <div className="col-md-6 my-auto align-right">
                    <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSubmit' onClick={submitFormHandler}>
                        {status === 'pending' || statusShoppingCart === 'pending' ?
                            <i className="fa fa-spinner fa-spin" ></i>
                            : 'Confirm'}
                    </button>
                    <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnExit' onClick={functionExit}>
                        Exit
                    </button>
                </div>
            </div>


        </div>
    </Fragment>
};
export default CancelPNR;