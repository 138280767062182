import { dateList, monthList } from '../utils/DropdownData';

export const getFormatDate = (date) => {
    if (date === '' || date === undefined) {
        return "";
    }
    let dateModified = dateList.filter(a => a.value === date.slice(6))[0].label;
    let monthModified = monthList.filter(a => a.value === date.slice(4, 6))[0].label;
    let fullDate = dateModified + " " + monthModified + " " + date.slice(0, 4);
    return fullDate;
}
export function splitString(str) {
    const parts = str.split(/\s{2,}/); // Split the string on 2 or more spaces

    return parts;
}
export function removeFirstAndLastSpecialChar(str) {
    const specialChars = /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g;
    return str.replace(specialChars, '');
}

export const getFormatTime = (time) => {
    if (time === '') {
        return '';
    }
    time = time.replaceAll(":", "");
    let hour = '';
    let min = '';
    let formattedTime = '';

    if (time.includes("-")) {
        time.split("-").forEach(elementTime => {
            hour = elementTime.slice(0, 2);
            min = elementTime.slice(2);
            if (formattedTime === '') {
                formattedTime = timeConvert(hour + ":" + min);
            }
            else {
                formattedTime = formattedTime + " - " + timeConvert(hour + ":" + min);
            }
        });
    }
    else {
        if (time.includes('AM') || time.includes('PM')) {
            if (time.length < 7) {
                hour = time.slice(0, 1);
                min = time.slice(1);
            } else {
                hour = time.slice(0, 2);
                min = time.slice(2);
            }
        } else {
            hour = time.slice(0, 2);
            min = time.slice(2);
        }


        formattedTime = timeConvert(hour + ":" + min);
    }
    return formattedTime;
}

export const getMinutetoHrsMin = (time) => {
    if (time === '0' || time === '') {
        return "";
    }
    var Hours = Math.floor(time / 60)
    var minutes = time % 60
    return Hours + "h " + minutes + "m";
}

export function toCamelCase(str) {
    return str.replace(/[-_](.)/g, (_, c) => c.toUpperCase());
}
export function toPascalCase(string) {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}
export function toTitleCase(str) {
    if (str !== undefined && str !== null && str !== '') {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}


export function timeConvert(time) {
    if (time === undefined) {
        time = "00:00";
    }

    if (time.toString().split(':')[0].length < 2) {
        time = "0" + time.toString().split(':')[0] + ":" + time.toString().split(':')[1];


    }
    if (time.toString().split(':')[1].length < 2) {
        time = time.toString().split(':')[0] + ":0" + time.toString().split(':')[1];

    }

    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}
export function breakTeaxtOnFullStop(text) {
    let show;
    if (text !== "" && text !== undefined) {
        // Remove '\n' from the string

        const removedNewlines = text.replace(/\n/g, ' ');
        const sentences = removedNewlines.split(/(?<=(?<!\d\.)\s*(?<!i\.e\.)\.\s*)(?!\S)/);
        if (sentences.length !== 0) {
            let desc = sentences.map(item => {
                if (item.trim() !== '.' && item !== '') {
                    return <div className='display-line mb-1'>{removeHtmlTags(item)}</div>;
                }

            });

            show = desc;
        } else {
            show = removeHtmlTags(text);
        }

    }
    return show;
}
export function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(
        dob.slice(0, 4),
        dob.slice(4, 6) - 1,
        dob.slice(6, 8)
    );

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

    const tempDate = new Date(birthDate.getFullYear() + ageInYears, birthDate.getMonth(), birthDate.getDate());
    if (tempDate > today) {
        ageInYears--;
    }

    if (ageInYears >= 1) {
        return ageInYears + (ageInYears === 1 ? " year" : " years");
    } else {
        const ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12 + today.getMonth() - birthDate.getMonth();
        const daysDiff = today.getDate() - birthDate.getDate();


        if (ageInMonths === 0 && daysDiff < 0) {

            return "0 months";
        } else if (ageInMonths === 0 && daysDiff === 0) {

            return "1 day";
        } else if (daysDiff > 30) {

            return ageInMonths + 1 + (ageInMonths === 1 ? " month" : " months");
        } else if (daysDiff === 30) {

            return ageInMonths + 1 + " month";
        } else if (daysDiff < 0) {
            return ageInMonths - 1 + " months"
        }

        return ageInMonths + (ageInMonths === 1 ? " month" : " months");
    }
}



export function getAge(date, personType) {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    // Example date of birth.
    let dobDate = new Date(date.slice(0, 4), date.slice(4, 6), date.slice(6));
    // var dobDate = new Date("03/31/2001");

    var years = nowDate.getFullYear() - dobDate.getFullYear();
    var months = nowDate.getMonth() - dobDate.getMonth();
    var days = nowDate.getDate() - dobDate.getDate();

    // Work out the difference in months.
    months += years * 12;
    if (days < 0) {
        months -= 1;
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months);
    // Calculate the difference in milliseconds.
    var diff = nowDate - dobDate;
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000);
    // Now convert months back to years and months.
    years = parseInt(months / 12);
    months -= (years * 12);
    // Format age as "xx years, yy months, zz days"
    var text = "";
    if (years) {
        text = years + (years > 1 ? " years" : " year");
    }
    if (personType === 'ADT' || personType === 'CNN') {
        return text;
    }
    else {

        if (years >= 1) {
            return text;
        }
        else if (months >= 1) {
            if (months) {
                return months + (months > 1 ? " months" : " month")
            }
        }
        else {
            if (days) {
                return days + (days > 1 ? " days" : " day");
            }
        }

    }

    return text;

}

// function to validate credit card numbers using the Luhn algorithm
export function validateByLuhn(cardNumber) {
    let sum = 0;
    let shouldDouble = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i));
        if (shouldDouble) {
            if ((digit *= 2) > 9) digit = digit - 9;
        }
        sum = sum + digit;
        shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
}


export const formatDate = (date) => {
    const parts = date.split(/[- :]/);
    const wanted = `${parts[2]}/${parts[1]}/${parts[0]} ${parts[3]}:${parts[4]}`;
    return wanted;
}

export const getDateDiffDays = (sourceDate, targetDate) => {
    if (sourceDate === undefined || targetDate === undefined) {
        return "";
    }

    var dateSource = new Date(sourceDate.slice(4, 6) + "/" + sourceDate.slice(6) + "/" + sourceDate.slice(0, 4));
    var dateTarget = new Date(targetDate.slice(4, 6) + "/" + targetDate.slice(6) + "/" + targetDate.slice(0, 4));

    let diffTime = dateTarget.getTime() - dateSource.getTime();
    let diffDays = diffTime / (1000 * 3600 * 24);
    return diffDays;
}

export function removeHtmlTags(str) {
    if (str == '' || str == undefined) {
        return "";
    }
    return str.replace(/(<([^>]+)>|amp;|&apos;|apos;|aposs|&amp;)/ig, '');
}
export function removeSpacesAndSpecialChars(str) {
    // Remove all special characters and spaces using regex
    return str.replace(/[^a-zA-Z0-9]/g, '');
}
export function convertToCamelCase(str) {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Split the string into words
    const words = str.split(/\b/);

    // Convert each word to camel case
    const camelCaseWords = words.map((word, index) => {
        // Skip non-alphanumeric characters
        if (!word.match(/[a-zA-Z0-9]/)) {
            return word;
        }

        // Convert to camel case
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words together
    const camelCaseString = camelCaseWords.join('');

    return camelCaseString;
}

// Example usage
const inputString = "NOTE - THE FOLLOWING TEXT IS INFORMATIONAL AND NOT VALIDATED FOR AUTOPRICING. BASIC ECONOMY FARES APPLICATION CLASS OF SERVICE THESE FARES APPLY FOR ECONOMY CLASS SERVICE. CAPACITY LIMITATIONS SEATS ARE LIMITED. OTHER CONDITIONS NON REFUNDABLE FARES CHANGES ALLOWED FOR TICKETING ON/BEFORE 31MAY20 NO PRERESERVED SEATS UNUSED COUPONS HAVE NO RESIDUAL VALUE.";

const camelCaseResult = convertToCamelCase(inputString);


export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
export function removeStartingNewlines(string) {
    while (string.startsWith("\n")) {
        string = string.slice(1);
    }
    return string;
}

export function removeStartingAndEndingNewlines(string) {
    while (string.startsWith("\n")) {
        string = string.slice(1);
    }
    while (string.endsWith("\n")) {
        string = string.slice(0, -1);
    }
    return string;
}

export const getMinutetoHrsWithoutFormat = (time) => {
    var hours = Math.floor(time / 60)
    var minutes = time % 60
    return hours + " " + minutes;
}

export const getHrstoMinuteWithoutFormat = (time) => {
    var timeParts = time.split(":");
    return Number(timeParts[0] === undefined || timeParts[0] === '' ? 0 : timeParts[0]) * 60 + Number(timeParts[1] === undefined || timeParts[1] === '' ? 0 : timeParts[1]);
}
 
export function parseHotelPolicy(policy) {

    if (policy === '' || policy === undefined) {
        return;
    }
    let check = policy.includes("\n\n");
    const hotelPolicyMap = new Map();
    const hotelPolicyArray = [];
    if (check) {
        let hotelPolicy = policy.split("\n\n");
        for (let items of hotelPolicy) {
            if (items.length > 0) {
                if (!items.toLowerCase().includes("commission")) {

                    let content = items.replace(/\n/g, "-");
                    let check = content.includes("-");
                    if (check) {

                        let text;
                        if (content.trim().substring(0, 1) === '-') {
                            text = content.substring(1);
                        } else {
                            text = content;
                        }
                        let labelValue = text.split("-")

                        if (labelValue.length > 0) {

                            hotelPolicyMap.set(labelValue[0], labelValue.slice(1).join(" "));



                        }
                    } else {
                        hotelPolicyMap.set("", items)
                    }


                } else {


                }

            }
        }
    } else {
        let hotelPolicy = policy.split("\n");
        for (let items of hotelPolicy) {
            if (items.length > 0) {
                if (!items.toLowerCase().includes("commission") && !items.toLowerCase().includes('wps')) {
                    let text;
                    if (items.includes('|')) {
                        text = items.replace('|', '')
                    } else {
                        text = items;
                    }
                    hotelPolicyArray.push(text);
                }

            }

        }



    }
   

    let showPolicy = [];
    for (let [key, value] of hotelPolicyMap) {
        showPolicy.push(<div className='mb-3'>
            <div className='fw-bold text-primary mb-2'>
                {key}
            </div>
           <div className='display-line mb-1'>{removeHtmlTags(value)}</div> 
        </div>)

    }
    for (let items of hotelPolicyArray) {
        showPolicy.push(<div className='mb-3'>{removeHtmlTags(items)}</div>)
    }
    return showPolicy

}
export const getRefundType = (list) => {
    let refundType = [];
    if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined) {
        list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo.forEach(elementFareInfo => {
            if (Object.keys(elementFareInfo.ruleInfo).length > 0) {
                elementFareInfo.ruleInfo !== undefined && elementFareInfo.ruleInfo.chargesRules !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined &&
                    elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.length !== 0
                    && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.forEach(element => {
                        if (element.conditions === undefined) {
                            if (element.amount === undefined && element.departureStatus === 'Before' && (element.refundable === 'false' || element.refundable === 'False')) {
                                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non Refundable</div>);
                            }
                            else if (element.amount !== undefined && element.departureStatus === 'Before' && (element.refundable === 'false' || element.refundable === 'False')) {
                                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non Refundable</div>);
                            }
                            else if (element.amount !== undefined && parseInt(element.amount) === 0 && element.departureStatus === 'Before' && (element.refundable === 'True' || element.refundable === 'true')) {
                                refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>)
                            } else if (element.amount !== undefined && parseInt(element.amount) > 0 && element.departureStatus === 'Before' && (element.refundable === 'True' || element.refundable === 'true')) {
                                refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>)
                            }
                        }
                        else {
                            if (element.amount === undefined && element.departureStatus === 'Before' && element.conditions === 'NONREFUNDABLE') {
                                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non
                                    Refundable</div>);
                            }
                            else if (element.amount !== undefined && parseInt(element.amount) === 0 && element.departureStatus === 'Before' && element.conditions === 'FULL') {
                                refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>)
                            } else if (element.amount !== undefined && parseInt(element.amount) > 0 && element.departureStatus === 'Before' && element.conditions === 'PARTIAL') {
                                refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>)
                            }
                        }
                    });
            }
        });
    }

    return refundType.length > 1 ? refundType[0] : refundType;

}


