import Dropdown from 'react-select';
const MealDetails = (props) => {

    const DropDowndata = ({ keyName, data, value, id, updatePropertyName, placeholder, traveler }) => {
        function changeHandler(e) {
            updateValue(id, e.value, updatePropertyName, props.id, traveler)
        }

        return (<div><Dropdown textField="ddName"
            dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
            controlClassName='form-control control form-select bg_input mb-0'
            placeholder={placeholder === "" ? "Selcet" : placeholder}
            defaultValue={data.filter(option => option.value === value)}
            onChange={changeHandler}
            options={data}
            id={keyName} />
            <span id={'msg' + keyName} className="error-text"></span></div>);

    };

    let specialServiceRequestTemp = [];
    let totalItinerary = 0;
    let itineraryStatus = "";
    function updateValue(id, Value, updatePropertyName, passengerSequence, traveler) {
        traveler.airItinerary.forEach(elementOuter => {
            totalItinerary = totalItinerary + elementOuter.originDestinationOptions.originDestinationOption.length;
            itineraryStatus = elementOuter.originDestinationOptions.originDestinationOption[0].flightSegment[0].status;
        });

        let found = specialServiceRequestTemp.find(element => element.PassengerNo === passengerSequence && element.ServiceType === updatePropertyName);
        if (found) {
            let specialServiceRequestTemp2 = JSON.parse(sessionStorage.getItem("AdditionalServicesData"));
            let specialServiceRequestTemp3 = [];
            specialServiceRequestTemp2.forEach(element => {
                if (element.ServiceType !== updatePropertyName) {
                    specialServiceRequestTemp3.push(element);
                }
                else {
                    if (element.PassengerNo !== passengerSequence) {
                        specialServiceRequestTemp3.push(element);
                    }
                }
            });

            if (Value !== '') {
                specialServiceRequestTemp3.push({
                    "PassengerNo": passengerSequence,
                    "ServiceType": updatePropertyName,
                    "ServiceCode": Value,
                    "ItineraryCount": totalItinerary,
                    "itineraryStatus": itineraryStatus
                });
            }

            specialServiceRequestTemp = specialServiceRequestTemp3;
        }
        else {
            if (sessionStorage.getItem("AdditionalServicesData") === null) {
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value,
                        "ItineraryCount": totalItinerary,
                        "itineraryStatus": itineraryStatus
                    });
                }
            } else {
                specialServiceRequestTemp = JSON.parse(sessionStorage.getItem("AdditionalServicesData"));
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value,
                        "ItineraryCount": totalItinerary,
                        "itineraryStatus": itineraryStatus
                    });
                }
            }
        }

        sessionStorage.setItem("AdditionalServicesData", JSON.stringify(specialServiceRequestTemp));
        //console.log(specialServiceRequestTemp);


        
    }
   
    return (       
        < div className='col-md-6' >
            <div id={"dvMeal" + props.segment.flightSegment[0].flightID + props.id} className='control hide-meals'>
                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight No. {props.segment.flightSegment[0].marketingAirline.code} {props.segment.flightSegment[0].marketingAirline.flightNumber} ({props.segment.flightSegment[0].departureAirport.locationCode} to {props.segment.flightSegment[0].arrivalAirport.locationCode})</label>
                <DropDowndata keyName={"mealDetails" + props.segment.flightSegment[0].flightID}
                    value={props.traveler.travelerInfo.SpecialReqDetails[0] !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== null && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.flightSegment[0].flightID && a.TravelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.flightSegment[0].flightID && a.TravelerRefNumber === props.id)[0].SSRCode : ''}
                    //id={props.segment.flightSegment[0].flightID}                    
                    data={props.mealDetailsOptions}
                    updatePropertyName={"MealDetails" + "_" + props.segment.flightSegment[0].flightID}
                    traveler={props.traveler}>
                </DropDowndata>
            </div>
        </div >



    )
}
export default MealDetails