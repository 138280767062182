import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { updateNotes, getNotes } from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";

const Notes = (props) => {
    const [isFirstTime, setFirstTime] = useState(false);
    const inputEmailIdRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(updateNotes);
    const { sendRequest: sendRequestNotes, status: notesStatus, data: loadedRequestNotes } = useHttp(getNotes)
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState(false);
    function functionExit() {
        props.errorHandler();
    }
    useEffect(() => {
        const onRegionSearch = ({
            "shoppingID": props.bookingJSON.data.shoppingID
        });
        sendRequestNotes(onRegionSearch);
    }, [sendRequestNotes]);

    useEffect(() => {
        if (notesStatus === 'completed') {
            if (loadedRequestNotes.errors.status === 'FALSE') {

                inputEmailIdRef.current.value = loadedRequestNotes.data[0].description;
                console.log(loadedRequestNotes.data[0].description);
            }
            return;
        }

    }, [notesStatus])
    const errorHandler1 = () => {
        setError1(null);
    };



    useEffect(() => {
        let internalRemarks = "";
        props.bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.forEach(element => {
            if (element.remarkType === 'InternalRemarks') {
                internalRemarks = element.text;
            }

        });
        inputEmailIdRef.current.value = internalRemarks;
    }, [props.bookingJSON])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote.errors.status === 'FALSE') {
                setMailConfirm('Internal Notes saved successfully.');

            } else {

                setMailConfirm("Some error has occurred.");

            }
            return;
        }

    }, [status])
    function onBlurCheck() {

        if (inputEmailIdRef.current.value.trim() === '') {
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

    }
    function submitFormHandler() {




        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        let notesRemarks = JSON.stringify({
            "shoppingID": parseInt(props.bookingJSON.data.shoppingID),
            "gdsid": parseInt(props.bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo[0].id),
            "code": "",
            "description": inputEmailIdRef.current.value,



            "remarkType": "InternalRemarks",
            "userID": JSON.parse(localStorage.getItem('userID')),
            "traceID": JSON.parse(localStorage.getItem('traceID')),
            'officeID': JSON.parse(localStorage.getItem('officeID')),


        });

        sendRequest(
            JSON.parse(notesRemarks)
        );
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Details <span className="error-icon"> *</span>

                                </label>
                                <div>
                                    <textarea type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={inputEmailIdRef}
                                        autoFocus>
                                    </textarea>
                                    {balnkMailError && <p className="error-text">Details is required.</p>
                                    }
                                    {invalidEmailError && <p className="error-text">Details is not valid.</p>}
                                    {!balnkMailError && !invalidEmailError && <p className="success-text">{mailConfirm}</p>}
                                    <span id="msgfName1" className="error-text"></span></div>
                            </div>
                        </div>
                        {/* </div> */}
                        <div className="col-md-7 my-auto">

                        </div>
                        <div className="col-md-5 my-auto align-right">
                            <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : 'Save'}
                            </button>
                            <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
};
export default Notes;