import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import CityDropDownList from '../../../../components/Common/DropDownList/CityDropDownList';
import { getFormatDate, getFormatTime, getMinutetoHrsWithoutFormat, getHrstoMinuteWithoutFormat } from '.././../../../utils/CommonFunction';

const MeetingDetails = () => {
    const [meetingStartDate, setMeetingStartDate] = useState();
    const [meetingEndDate, setMeetingEndDate] = useState();
    const [meetingStartDateDb, setMeetingStartDateDb] = useState();
    const [meetingEndDateDb, setMeetingEndDateDb] = useState();
    const [purposeOfTravelValue, setPurposeOfTravelValue] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [meetingStartDate_, setMeetingStartDate_] = useState();
    const [meetingEndDate_, setMeetingEndDate_] = useState();


    useEffect(() => {
        if (sessionStorage.getItem("spnToAirportDetails1") !== null) {
            let selectedToCountry = sessionStorage.getItem("spnToAirportDetails1").split(',')[sessionStorage.getItem("spnToAirportDetails1").split(',').length - 1].trim();
            let selectedToCity = sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim();

            if (localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let bookingDataTravelRequestJson = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let originDestinationOptionLengthOutbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'O').length;
                let originDestinationOptionLengthInbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I').length;
                let originDestinationOptionLengthMulticity = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'M').length;

                selectedToCountry = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryCode;
                selectedToCity = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.cityCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.cityCode;

                setCountryCode(selectedToCountry);
                setCityCode(selectedToCity);

                let departureDate = getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate);
                let arrivalDate = getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate);

                setMeetingStartDate_(new Date(departureDate));
                var departureDate2 = new Date(getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate));
                var departureDate3 = departureDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var departureDate4 = departureDate3.split(',')[2];
                document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' ? moment(new Date(meetingStartDate)).format("ddd, MMM DD, 'YY") : departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();

                setMeetingEndDate_(new Date(arrivalDate));
                var arrivalDate2 = new Date(getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate));
                var arrivalDate3 = arrivalDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var arrivalDate4 = arrivalDate3.split(',')[2];
                document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' ? moment(new Date(meetingEndDate)).format("ddd, MMM DD, 'YY") : arrivalDate3.substring(0, arrivalDate3.length - 2) + "'" + arrivalDate4.trim();

                document.querySelector('#meetingStartTime').value = "09:00";
                document.querySelector('#meetingEndTime').value = "18:00";
            }
            else {
                setCountryCode(selectedToCountry);
                setCityCode(selectedToCity);

                setMeetingStartDate_(new Date(sessionStorage.getItem("calFrom1").replace("'", "")));
                document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' && sessionStorage.getItem("fromDateChangedCBT") === null ? moment(new Date(meetingStartDate)).format("ddd, MMM DD, 'YY") : moment(new Date(sessionStorage.getItem("calFrom1").replace("'", ""))).format("ddd, MMM DD, 'YY");

                if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    setMeetingEndDate_(new Date(sessionStorage.getItem("calTo1").replace("'", "")));
                    document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' && sessionStorage.getItem("toDateChangedCBT") === null ? moment(new Date(meetingEndDate)).format("ddd, MMM DD, 'YY") : moment(new Date(sessionStorage.getItem("calTo1").replace("'", ""))).format("ddd, MMM DD, 'YY");
                }
                else {
                    let endDateTemp = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
                    endDateTemp.setDate(endDateTemp.getDate() + 1);
                    setMeetingEndDate_(new Date(endDateTemp));
                    document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' && sessionStorage.getItem("fromDateChangedCBT") === null ? moment(new Date(meetingEndDate)).format("ddd, MMM DD, 'YY") : moment(new Date(endDateTemp)).format("ddd, MMM DD, 'YY");
                }

                document.querySelector('#meetingStartTime').value = "09:00";
                document.querySelector('#meetingEndTime').value = "18:00";
            }
        }

    }, [sessionStorage.getItem("spnToAirportDetails1"), sessionStorage.getItem("calFrom1"),
    sessionStorage.getItem("calTo1"), meetingStartDate_, meetingEndDate_, countryCode,
        cityCode, meetingStartDate, meetingEndDate])

    const calGetMeetingStartDate = (inputDate) => {
        sessionStorage.removeItem("fromDateChangedCBT");
        document.querySelector('#meetingStartDate').value = moment(new Date(inputDate.target.value)).format("ddd, MMM DD, 'YY");
        const fromDate = new Date(inputDate.target.value.replace("'", ""));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingStartDate(fromDate);
        setMeetingStartDateDb(fromDateDB);
    }

    const calGetMeetingEndDate = (inputDate) => {
        sessionStorage.removeItem("toDateChangedCBT");
        document.querySelector('#meetingEndDate').value = moment(new Date(inputDate.target.value)).format("ddd, MMM DD, 'YY");
        const toDate = new Date(inputDate.target.value.replace("'", ""));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingEndDate(toDate);
        setMeetingEndDateDb(toDateDB);
    }

    let purposeOfTravelOptions = [
        { value: 'Clientvisit', label: "Client visit" },
        { value: 'Conference', label: "Conference" },
        { value: 'Meeting', label: "Meeting" },
        { value: 'Onboarding', label: "Onboarding" },
        { value: 'Training', label: "Training" }
    ];

    let projectOptions = [
        { value: 'Globetrotter', label: "Globetrotter" },
        { value: 'Globetrotter-OBT', label: "Globetrotter OBT" }
    ];

    const purposeOfTravelChangeHandler = (event) => {
        setPurposeOfTravelValue(event.value);
    };

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };

    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);
    };

    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingCounty">Country<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingCity">City<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} countryCode={countryCode} />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingStartDate">Start Date<span className="error-icon"> *</span></label>
                                    <DateRangePicker key={meetingStartDate_}
                                        onHide={calGetMeetingStartDate}
                                        initialSettings={{
                                            startDate: document.querySelector('#meetingStartDate') !== null ? document.querySelector('#meetingStartDate').value !== '' ? new Date(document.querySelector('#meetingStartDate').value.replace("'", "")) : meetingStartDate_ !== undefined && meetingStartDate_ !== '' ? new Date(meetingStartDate_) : new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : meetingStartDate_ !== undefined && meetingStartDate_ !== '' ? new Date(meetingStartDate_) : sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : new Date(), endDate: '', singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingStartDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingEndDate">End Date<span className="error-icon"> *</span></label>
                                    <DateRangePicker key={meetingEndDate_}
                                        onHide={calGetMeetingEndDate}
                                        initialSettings={{
                                            startDate: document.querySelector('#meetingEndDate') !== null ? document.querySelector('#meetingEndDate').value !== '' ? new Date(document.querySelector('#meetingEndDate').value.replace("'", "")) : meetingEndDate_ !== undefined && meetingEndDate_ !== '' ? new Date(meetingEndDate_) : new Date(sessionStorage.getItem("calTo1").replace("'", "")) : meetingEndDate_ !== undefined && meetingEndDate_ !== '' ? new Date(meetingEndDate_) : sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : new Date(meetingEndDate_), endDate: '', singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingEndDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingStartTime" className="mb-2 h6 text-primary col-lg-12">Start Time<span className="error-icon"> *</span></label>
                                    <input type="time" id='meetingStartTime' className="form-control form-control-sm" />
                                </div>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingEndTime" className="mb-2 h6 text-primary col-lg-12">End Time<span className="error-icon"> *</span></label>
                                    <input type="time" id='meetingEndTime' className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="purposeOfTravel">Purpose of Travel<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown onChange={purposeOfTravelChangeHandler}
                                            value={purposeOfTravelOptions.filter(option => option.value === purposeOfTravelValue)}
                                            options={purposeOfTravelOptions}
                                            textField="value"
                                            dataItemKey="label"
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='mt-2'>
                                <label class="h5 mb-3 col-lg-6">Custom Fields</label>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label class="h6 text-primary mb-2 col-lg-12" for="project">Project</label>
                                        <div className="dropdown pb-2 col-md-12">
                                            <Dropdown
                                                value={projectOptions.filter(option => option.value === 'Globetrotter')}
                                                options={projectOptions}
                                                textField="value"
                                                dataItemKey="label"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label class="h6 text-primary mb-2 col-lg-6" for="division">Division</label>
                                        <input maxLength={30} className="form-control bg_input col-md-6" type='text'
                                            id='division' value={'Software'} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default MeetingDetails;

