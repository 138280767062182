import {
    GET_ALL_CITY,
    GET_CITY,
    ADD_CITY,
    UPDATE_CITY,
    DELETE_CITY,
    SEARCH_CITY
} from './CONSTANTS'

export async function getAllCity() {

    const response = await fetch(GET_ALL_CITY(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch city.');
    }
    return data;
}
export async function searchCity(searchData) {
    const response = await fetch(SEARCH_CITY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch city.');
    }
    
    return data;
}
export async function getSingleCity(ID) {
    const response = await fetch(GET_CITY(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch city.');
    }

    return data;
}

export async function deleteSingleCity(deleteData) {
    const response = await fetch(DELETE_CITY(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not delete city.');
    }
    
    return data;
}

export async function addCity(addData) {
    const response = await fetch(ADD_CITY(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create city.');
    }

    return data;
}
export async function updateCity(updateData) {
    const response = await fetch(UPDATE_CITY(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update city.');
    }
    return data;
}

