
import CountryForm from './Components/CountryForm'
import { useHistory } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addCountry, getSingleCountry, updateCountry } from '../../../services/Country-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { decrypted } from '../../../utils';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
const NewProfileDetails = () => {
    
    const [isEdit, setEditState] = useState(false);
    const location = useLocation();
    const value = queryString.parse(location.search);
    const token = value.Id;
    useDocumentTitle(token===undefined?'New Country':'Update Country')
    const [error1, setError] = useState();
    let varHttp;
    if (token === undefined) {
        varHttp = addCountry
    } else {
        varHttp = updateCountry
    }
    const { sendRequest: sendRequest1, data: loadedProfileDetails, error } = useHttpGet(getSingleCountry);
    const { sendRequest, status } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (token !== undefined) ? 'Please note record updated sucessfully.' : 'Please note record inserted sucessfully.'
    useEffect(() => {
        if (isEdit || token === undefined) {

            if (status === 'completed') {
                setError({
                    title: "Message",
                    message: `${sucsssMessage}`,
                });
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, token, isEdit, sucsssMessage])

    const [data, dataSet] = useState(null)
    useEffect(() => {
        (async () => {
            if (token !== undefined) {
                let response = await decrypted(token.replaceAll(' ', '+'));
                response = await response
                dataSet(response);
            }
        })();
    }, [token])

    useEffect(() => {
        if (data !== undefined) {
            sendRequest1(data)
        }
    }, [sendRequest1, data])
    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
    }

    let loadedData = [];
    for (let i in loadedProfileDetails) {
        if (loadedProfileDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedProfileDetails[i];
            }
        }
    }

    const addContinentHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        history.push('/allcountry');
    };



    const ProfileForm = (token !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p>No profile details found!</p> :
        <CountryForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddContinent={addContinentHandler} />

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {ProfileForm}
    </Fragment>

};

export default NewProfileDetails;