import React from "react";
import ReactDOM from "react-dom";
const Backdrop = (props) => {
  return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {
  return (
    <div className='modalcard modal'>
      {/* <header className='header '>
        <h4 className="text-white">{props.title}</h4>
      </header> */}
      <header className='header'>
        <div className="row">
              <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                  <h4 className="text-white">{props.title}</h4>

              </div>
              {/* <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={props.onConfirm}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div> */}
          </div>
      </header>
      <div className='content'>
        <p>{props.message}</p>
      </div>
      <footer className='actions float-end px-3 py-2'>
        <button className="btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1" onClick={props.onCancel}>Logout</button>
        <span>&nbsp;&nbsp;</span>
        <button className="btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1" onClick={props.onOk}>Extend session</button>
      </footer>
    </div>
  );
};

const ConfirmationModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClick={props.onConfirm}></Backdrop>,
        document.getElementById("backdrop-root")
      )}
      {/*  {ReactDOM.createPortal(
        <okButton onClick={props.onOk}></okButton>,
        document.getElementById("backdrop-root")
      )}      
      {ReactDOM.createPortal(
        <cancelButton onClick={props.onCancel}></cancelButton>,
        document.getElementById("backdrop-root")
      )} */}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          message={props.message}
          onOk={props.onOk}
          onCancel={props.onCancel}
        ></ModalOverlay>,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};
export default ConfirmationModal;
