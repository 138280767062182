import {
    GET_ALL_AIRLINE,
    GET_AIRLINE,
    ADD_AIRLINE,
    UPDATE_AIRLINE,
    DELETE_AIRLINE,
    SEARCH_AIRLINE,
    SEARCH_ALL_AIRLINE
} from './CONSTANTS';

//function to get all airline
export async function getAllAirline() {

    const response = await fetch(GET_ALL_AIRLINE(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;


}

//function to get single airline
export async function getSingleAirline(ID) {
    const response = await fetch(GET_AIRLINE(ID), {
        method: "GET",
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }
    return data;

}


//function to add airline
export async function addAirline(addData) {
    const response = await fetch(ADD_AIRLINE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not create airline');
    }
    return data;

}


//function to update airline
export async function updateAirline(updateData) {
    const response = await fetch(UPDATE_AIRLINE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {

            'Content-Type': 'application/json',

            'Authorization': 'Bearer ' + localStorage.getItem('token'),

        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update airline');
    }
    return data;
}
export async function deleteSingleAirline(deleteData) {
    const response = await fetch(DELETE_AIRLINE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete city.');
    }
    return data;
}
export async function searchairline(searchData) {    
    const response = await fetch(SEARCH_AIRLINE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    
    const data = await response.json()
    
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }    
    return data;
}

export async function searchAllAirline(searchData) {    
    const response = await fetch(SEARCH_ALL_AIRLINE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    
    const data = await response.json()
    
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch airline');
    }    
    return data;
}