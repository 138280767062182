import { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { ForgotUpdatePassword } from '../../../services/Authentication-API'
import { useTranslation } from 'react-i18next';
import { passwordValidation } from '../../../utils/Validation'
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import useHttp from '../../../services/use-http'
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";

const UpdatePassword = () => {
  useDocumentTitle('Update Password');
  const parms = useParams();
  const { t } = useTranslation(['translation.ForgotPassword']);
  const NewPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const history = useHistory();
  const { Id } = parms;
  const { sendRequest, status, data: updatePasswordDetails, error } = useHttp(ForgotUpdatePassword);

  const location = useLocation();
  const value = queryString.parse(location.search);
  const token = value.Id;

  const [errors, setErrors] = useState();
  const [newPasswordInputInputHasError, setnewPasswordInputInputHasError] = useState(false);
  const [newPasswordValidInputInputHasError, setnewPasswordValidInputInputHasError] = useState(false);
  const [confirmPasswordInputInputHasError, setconfirmPasswordInputInputHasError] = useState(false);
  const [confirmPasswordValidInputInputHasError, setconfirmPasswordValidInputInputHasError] = useState(false);
  useEffect(() => {
    NewPasswordInputRef.current.focus();
  }, [])

  const errorHandler = () => {
    if (errors.message === 'Password updated successfully.')
      history.push('/login');
    else
      setErrors(null);
  };

  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in updatePasswordDetails) {
        if (updatePasswordDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            loadedData = updatePasswordDetails[i];
            setErrors({
              title: "Message",
              message: `Password updated successfully.`,
            });
          }
        }
        else if (updatePasswordDetails['errors'].status === 'TRUE') {



          if (updatePasswordDetails.errors.error.code === "1019") {
            setErrors({
              title: "Message",
              message: 'You have already used that password, Please try another combination.',
            });
          } else {
            setErrors({
              title: "Message",
              message: 'Sorry, an unexpected error has occurred. Please try again.',
            });
          }
        }
      }
    }
    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, updatePasswordDetails, history])
  const submitHandler = useCallback((event) => {
    event.preventDefault();
    const enteredNewPassword = NewPasswordInputRef.current.value;
    const enteredConfirmPassword = confirmPasswordInputRef.current.value;
    // optional: Could validate here
    if (enteredNewPassword === '') {
      NewPasswordInputRef.current.focus();
      setnewPasswordInputInputHasError(true);
      setnewPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredNewPassword)) {
      setnewPasswordInputInputHasError(false);
      setnewPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setnewPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword === '') {
      NewPasswordInputRef.current.focus();
      setconfirmPasswordInputInputHasError(true);
      setconfirmPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredConfirmPassword)) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword !== enteredNewPassword) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }

    sendRequest({ loginEmailID: token, newPassword: enteredNewPassword });
    // optional: Add validation 
  }, [sendRequest]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);

  return (
    <Fragment>
      {errors && (
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      <section className='gt_login_banner'>
        <div className='position-relative'>
          <a href='/login' className="">
            <img src="assets/img/login_Globetrotter_logo.png" alt="" className='log-in-head-logo-bg' />
          </a>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 mb-1 position-relative">
            <div className="gt-common_author_boxed position-absolute width-100 gt-login-banner-div">
              <div className="card-body">
                <div className="col-md-10 col-sm-12 mx-auto signin-log">
                  <div className='text-center'>
                  <label className="mb-2 gt-heading_theme h4 text-primary mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Reset Password</label>
                  </div>
                  <div className="form-group col-md-12">
                    <form action="#" id="main_author_form">
                      <div className='control'>
                        <div className='input-group'>
                          {/* <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('loginid')}</label> */}
                          <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                          {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                          <input type='password'
                            className="form-control" tabIndex={1} id='NewPassword'
                            placeholder={t('NewPassword')}
                            /*  onChange={onloginIDChangeCheck} */
                            required ref={NewPasswordInputRef} />
                          <ReactTooltip
                            anchorId="NewPassword"
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={t('passwordHint')}
                          />
                          <span className="input-group-text w-50px h-50px">
                             <i className="fa fa-eye-slash text-primary" id="togglePassword2"></i>
                          </span>
                        </div>
                        <div>
                          {newPasswordInputInputHasError && <p className="error-text">{t('validationNewPassword')}</p>}
                          {newPasswordValidInputInputHasError && <p className="error-text">{t('validationValidNewpassword')}</p>}
                        </div>
                      </div>
                      <div className='input-group'>
                        {/* <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('loginid')}</label> */}
                        <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                        {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                        <input type='password'
                          className="form-control" tabIndex={1} id='confirmPassword'
                          placeholder={t('confirmPassword')}
                          /*  onChange={onloginIDChangeCheck} */
                          required ref={confirmPasswordInputRef} />
                        <ReactTooltip
                          anchorId="confirmPassword"
                          place="bottom"
                          variant="info"
                          multiline={true}
                          className="tooltip"
                          html={t('passwordHint')}
                        />
                         <span className="input-group-text w-50px h-50px">
                             <i className="fa fa-eye-slash text-primary" id="togglePassword3"></i>
                          </span>

                      </div>
                      <div>
                        {confirmPasswordInputInputHasError && <p className="error-text">{t('validationConfirmpassword')}</p>}
                        {confirmPasswordValidInputInputHasError && <p className="error-text">{t('validationMatchConfirmpassword')}</p>}
                      </div>
                      <div className="form-group control">
                      </div>
                      <div className="common_form_submit">

                        <button className="btn main-btn-width-auto text-white gt-main-btn-WithoutAuth float-lg-end float-md-end ms-1 mt-1" onClick={submitHandler} tabIndex={4}>Submit</button>
                        <a href="/login" className="btn main-btn-width-auto text-white gt-main-btn-WithoutAuth float-lg-end float-md-end ms-1 mt-1">Back to Login</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;