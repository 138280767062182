import { useState, useRef } from "react";
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import { useTranslation } from "react-i18next";
import Dropdown from 'react-select';
import { useEffect } from "react";
import useHttp from "../../../../services/use-http";
import { getPccList, getRatePlanList, getRatePlanListOfficeWise } from "../../../../services/RatePlanAllowed-API";
import { searchOfficeList } from "../../../../services/Office-API";
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from './../../../../components/Common/Loading/LoadingSpinner'
import { map } from "jquery";

const RatePlanForm = (props) => {
    const { t } = useTranslation(['translation.RatePlanAllowed']);
    const [pageTitle, setPageTitle] = useState(" Rate Plan Allowed");
    const { sendRequest, data: loadedPccList } = useHttp(getPccList);
    const { sendRequest: sendRatePlanRequest, data: loadedRatePlanList } = useHttp(getRatePlanListOfficeWise);
    const parms = useParams();
    const { OfficeID, CompanyName, } = parms;


    //State
    const [companyNameInput, setCompanyNameInput] = useState(CompanyName);
    const [isRatePlanDisabled, setIsRatePlanDisabled] = useState(false);
    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    const ratecodeArray = [
        {
            'pccName': "",
            "rateCode": [{
                'rateCodeID': "",
                'rateCodes': ""

            }]
        }
    ]
    const [rateCodes, setRateCodes] = useState([]);



    useEffect(() => {

        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
        if (props.loadedProfileDetails.length !== 0) {

            if (props.loadedProfileDetails.ratePlanPCC.length > 0) {
                setRateCodes(props.loadedProfileDetails.ratePlanPCC)
            }


        }
        if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            setPageTitle(" View Rate Plan Allowed")
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.getElementById("requiredIndicator").style.display = "none";
            setIsRatePlanDisabled(true)
        }
        else if (isEditMode === 1) {
            setPageTitle(" Edit Rate Plan Allowed")
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementById("requiredIndicator").style.display = "";
            setIsRatePlanDisabled(false);
        }

        // if (props.loadedProfileDetails.length === 0) {
        //     setPageTitle(" New Rate Plan Allowed")
        //     sessionStorage.setItem('isEditable', '100');
        //     setIsRatePlanDisabled(false);

        // }

    }, [props.loadedProfileDetails.length, props.loadedProfileDetails.ratePlanPCC,

    ]);

    useEffect(() => {
        const onSearchPccList = ({
            "id": "",
            "userID": "",
            "serviceID": "21",
            "officeID": OfficeID,
            "status": 2

        });

        sendRequest(onSearchPccList);
        sendRatePlanRequest({
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });
    }, [OfficeID])
    useEffect(() => {
        sendRatePlanRequest({
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });
    }, [])

    let pccArray = [];
    for (let i in loadedPccList) {
        if (loadedPccList['errors'].status === 'FALSE') {
            if (i === 'data') {
                pccArray = loadedPccList[i];
            }
        }
    }

    let ratePlanOptions = []
    let ratePlanArrayData = [];
    for (let i in loadedRatePlanList) {
        if (loadedRatePlanList['errors'].status === 'FALSE') {
            if (i === 'data') {
                ratePlanArrayData = loadedRatePlanList[i];
            }
        }
    }
    for (let index = 0; index < ratePlanArrayData.length; index++) {

        ratePlanOptions = [...ratePlanOptions, { value: ratePlanArrayData[index].gtCode, label: ratePlanArrayData[index].gtName, id: ratePlanArrayData[index].id }]
    }

    const companyNameInputRef = useRef('');
    function ratePlanChangeHandlers(e, id) {

        let intialRatecodes = [...rateCodes]
        let matchFound = false;
        if (intialRatecodes.length > 0) {
            for (let item of intialRatecodes) {
                if (item.pccName === id) {
                    matchFound = true;
                    let rateCode = [];
                    for (let i of e) {
                        rateCode.push({
                            'rateCodeID': i.id,
                            'rateCodes': i.value
                        })
                    }
                    item.rateCode = rateCode
                }
            }
            setRateCodes(intialRatecodes);
        }
        if (!matchFound) {
            let rateCode = [];
            let temp = [...rateCodes];
            for (let i of e) {
                rateCode.push({
                    'rateCodeID': i.id,
                    'rateCodes': i.value
                })
            }

            let obj = {
                'pccName': id,
                "rateCode": rateCode,

            }

            temp.push(obj)
            setRateCodes(temp);
        }



    }

    function submitFormHandler(event) {
        let validationStatus = false;
        event.preventDefault();
      /*   if (pccArray.length > 0) {
            pccArray.forEach(item => {
                let foundPcc = rateCodes.filter(i => i.pccName === item.pcc)
               if (foundPcc.length !== 0) {
                    for (let i of rateCodes) {
                        if (i.pccName === item.pcc) {
                         if (i.rateCode === null || i.rateCode.length === 0) {
                                document.getElementById(item.pcc).innerHTML = "Rate Plan Name is required.";
                                validationStatus = true;
                                return;
                            } else {
                                document.getElementById(item.pcc).innerHTML = "";
                            } 
                        }
                    }
                }
                else {
                    document.getElementById(item.pcc).innerHTML = "Rate Plan Name is required.";
                    validationStatus = true;
                    return;
                } 


            })

        } */
        let ID = '';
        if (props.loadedProfileDetails.length !== 0) {
            ID = props.loadedProfileDetails.id
        }

        const onSearchRatePlanAllowed = ({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: OfficeID,
            companyName: companyNameInput,
            ratePlanPCC: rateCodes.length > 0 ? rateCodes : ratecodeArray,

        });
      /*  
        if (rateCodes.length === 0) {
            return;
        }
        */
        if (!validationStatus) {
            props.onAddRatePlan(
                onSearchRatePlanAllowed
            );
        }



    }
    function changeLocation() {
        window.location.reload(true);
    }

    const pccList = [];
    const getPccListMethod = (list) => {

        list.forEach((item, index) => {
            let autofocus = false;
            if (index === 0) {
                autofocus = true;
            }
            pccList.push(<div className="row" >
                <div className="col-md-4">
                    <div className="control">
                        <label htmlFor="pcc" className="mb-2 h6 text-primary col-lg-12">{'PCC' + parseInt(1 + index)}</label>
                        <input id={"PCC" + index}
                            value={item.pcc}
                            minLength="1"
                            maxLength="100"
                            type="text"
                            readOnly
                            className="form-control form-control-sm"

                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className='control'>
                        <label htmlFor="ratePlanName" className="mb-2 h6 text-primary col-lg-12">{t('ratePlanName')}</label>
                        <Dropdown
                            id={'ratePlanName' + index}
                            key={'ratePlanName' + index}
                            onChange={(e, id) => ratePlanChangeHandlers(e, item.pcc)}
                            // value={rateCodes.filter(option=>option.pccName===item.pcc)}
                            options={ratePlanOptions}
                            textField="label"
                            dataItemKey="value"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                            isMulti={true}
                            isDisabled={isRatePlanDisabled}
                            autoFocus={autofocus}

                        />

                        <span id={item.pcc} className="error-text"></span>
                    </div>
                </div>

            </div>)

        });
        return pccList

    }
    const getPccListMethodByRateCodes = (list) => {


        list.forEach((item, index) => {
            let autofocus = false;
            if (index === 0) {
                autofocus = true;
            }
            pccList.push(<div className="row" >
                <div className="col-md-4">
                    <div className="control">
                        <label htmlFor="pcc" className="mb-2 h6 text-primary col-lg-12">{'PCC' + parseInt(1 + index)}</label>
                        <input id={"PCC" + index}
                            value={item.pccName}
                            minLength="1"
                            maxLength="100"
                            type="text"
                            readOnly
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className='control'>
                        <label htmlFor="ratePlanName" className="mb-2 h6 text-primary col-lg-12">{t('ratePlanName')}</label>
                        <Dropdown
                            id={'ratePlanName' + index}
                            key={'ratePlanName' + index}
                            onChange={(e, id) => ratePlanChangeHandlers(e, item.pccName)}
                            value={ratePlanOptions.filter(option => item.rateCode !== null && item.rateCode.some(rc => rc.rateCodes === option.value))}
                            options={ratePlanOptions}
                            textField="label"
                            dataItemKey="value"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                            isMulti={true}
                            isDisabled={isRatePlanDisabled}
                            autoFocus={autofocus}
                        />

                        <span id={item.pccName} className="error-text"></span>
                    </div>
                </div>

            </div>)

        });
        return pccList

    }


    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                                    <form className='form'>

                                                        <div className="col-md-12">
                                                            <div className="control">
                                                                <label htmlFor="companyName" className="mb-2 h6 text-primary col-lg-12">{t('companyName')}</label>
                                                                <input id="companyName"
                                                                    ref={companyNameInputRef}
                                                                    value={companyNameInput}
                                                                    minLength="1"
                                                                    maxLength="100"
                                                                    type="text"
                                                                    readOnly
                                                                    className="form-control form-control-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        {props.loadedProfileDetails.length <= 0 && pccArray.length > 0 && getPccListMethod(pccArray)}
                                                        {props.loadedProfileDetails.length !== 0 && rateCodes.length > 0 && getPccListMethodByRateCodes(rateCodes)}




                                                        <p><span className="error-icon">  {isEditMode === 0 ? "" : "*"}</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/SearchRatePlanAllowed" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchRatePlanAllowed" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                        {t('save')}
                                                                    </button>
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>
};
export default RatePlanForm;