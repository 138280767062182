import { useState, useRef, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'

import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { SYSTEM_PAGE_LOST } from '../../../../config/CONSTANTS';

import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';

const ProfileDetailsForm = (props) => {
    const { t } = useTranslation(['translation.Country']);
    const history = useHistory();
    /*  */
    const countryCodeInputRef = useRef('');
    const countryNameInputRef = useRef('');
    const isoCountryCodeInputRef = useRef('');
    const [valueCurrency, setValueCurrency] = useState();

    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [countryNameInputHasError, setCountryNameInputHasError] = useState(false);
    const [isoCountryCodeInputHasError, setISOCountryCodeInputHasError] = useState(false);

    useEffect(() => {
        if (props.loadedProfileDetails.length !== 0) {
            countryCodeInputRef.current.value = `${props.loadedProfileDetails.countryCode !== undefined ? props.loadedProfileDetails.countryCode : ''}`;
            countryNameInputRef.current.value = props.loadedProfileDetails.countryName;
            isoCountryCodeInputRef.current.value = props.loadedProfileDetails.isoCountryCode;

        }
    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails.countryName,
    props.loadedProfileDetails.countryCode,
    props.loadedProfileDetails.isoCountryCode
    ]);

    const [isEntering, setIsEntering] = useState(false);
    const handleChangeCurrency = (e) => {
        setValueCurrency(e.value);
    };

    function onBlurCheck() {
        const enteredCountryCode = countryCodeInputRef.current.value;
        const enteredCountryName = countryNameInputRef.current.value;
        // optional: Could validate here

        if (enteredCountryName === '') {
            setCountryNameInputHasError(true); return;
        }
        else {
            setCountryNameInputHasError(false);
        }
        if (enteredCountryCode === '') {
            setCountryCodeInputHasError(true); return;
        }
        else {
            setCountryCodeInputHasError(false);
        }
    }
    function submitFormHandler(event) {
        event.preventDefault();
        setIsEntering(false);

        const enteredCountryCode = countryCodeInputRef.current.value;
        const enteredCountryName = countryNameInputRef.current.value;
        const enteredISOCountryCode = isoCountryCodeInputRef.current.value;

        // optional: Could validate here
        if (enteredCountryName === '') {
            countryNameInputRef.current.focus();
            setCountryNameInputHasError(true);
            return;
        }
        if (enteredCountryCode === '') {
            countryCodeInputRef.current.focus();
            setCountryCodeInputHasError(true);
            return;
        }
        if (enteredISOCountryCode === '') {
            isoCountryCodeInputRef.current.focus();
            setISOCountryCodeInputHasError(true); return;
        }

        let ID;
        if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedProfileDetails.id;
        }
        props.onAddContinent({
            id: ID,
            ContinetID: 'dddddddd',
            countryName: enteredCountryName,
            countryCode: enteredCountryCode,
            isoCountryCode: enteredISOCountryCode,
            currencyCode: valueCurrency,
            userID:'sdsdsd',
            officeID:'testing'
        });
    }
    const formFocusedHandler = () => {
        setIsEntering(false);
    };
    const onResetHandler = (event) => {
        event.preventDefault();
        countryNameInputRef.current.value = '';
        countryCodeInputRef.current.value = '';
        countryCodeInputRef.current.value = '';
    }

    const currencyCodeOptions = [
        { value: 'INR', label: 'Indian' },
        { value: 'USD', label: 'United States dollar ' },
        { value: 'AED', label: 'Arabic Dirham' }
    ];

    function changeLocation(event) {
        setIsEntering(false);
        history.push('/NewCountry')
    }
   
    return (
        <Wrapper>
            <Prompt
                when={isEntering}
                message={(location) => `${SYSTEM_PAGE_LOST}`
                }
            />
            <form onFocus={formFocusedHandler}
                className='form'>
                {props.isLoading && (
                    <div className='loading'>
                        <LoadingSpinner />
                    </div>
                )}
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid my-4">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">

                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="mb-2  h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i>{props.loadedProfileDetails === null? ' New Country':' Update Country'}</label>
                                            <div className="form-group col-md-12">
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('countryname')}</label>   
                                                    <input maxLength="20" type='text'
                                                        id='countryName'
                                                        ref={countryNameInputRef}
                                                        onBlur={onBlurCheck}
                                                        className="mb-2 form-control form-control-sm col-lg-12"
                                                    />
                                                    {countryNameInputHasError && <p className="error-text">{t('validationCountryname')}</p>}

                                                </div>
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('countryCode')}</label>  
                                                    <input maxLength="20" type='text'
                                                        id='countryCode'
                                                        ref={countryCodeInputRef}
                                                        onBlur={onBlurCheck}
                                                        className="mb-2 form-control form-control-sm col-lg-12" />
                                                    {countryCodeInputHasError && <p className="error-text">{t('validationCountryCode')}</p>}
                                                </div>
                                            
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>Currency</label>  
                                                    <Dropdown onChange={handleChangeCurrency}
                                                        value={props.loadedProfileDetails.currencyCode !== undefined ? props.loadedProfileDetails.currencyCode : ''}
                                                        options={currencyCodeOptions}
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />}  
                                                        controlClassName='form-control control form-select bg_input'
                                                        placeholder={t('currency')}
                                                        />
                                                </div>
                                                <div className="control">
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('isoCode')}</label>  
                                                    <input maxLength="20" type='text' id='lastName'
                                                        ref={isoCountryCodeInputRef}
                                                        onBlur={onBlurCheck} 
                                                        className="mb-2 form-control form-control-sm col-lg-12"
                                                        />
                                                    {isoCountryCodeInputHasError && <p className="error-text"></p>}
                                                </div>

                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                        
                                                        <a href="/AllCountry" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">
                                                            <a href="/AllCountry" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                            {/* <button onClick={onBackLocation} className='btn  gt-btn_md text-white gt-main-btn' title='Back to Previous'><span><i className="fa fa-arrow-left"></i></span></button> */}
                                                            <button onClick={submitFormHandler} className='btn  gt-btn_md text-white gt-main-btn ms-1'>Save</button>
                                                            <button onClick={changeLocation} className='btn  gt-btn_md text-white gt-main-btn ms-1'>New</button>
                                                            <button onClick={onResetHandler} className='btn  gt-btn_md text-white gt-main-btn ms-1'>Reset</button>

                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="mt-3 col-md-12">
                                              
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>

        </Wrapper>
    );
};

export default ProfileDetailsForm;