import { Fragment } from 'react';
import { getFormatDate } from '../../../utils/CommonFunction';
const LoadingFlight = (props) => {
  function LoaderMenuData() {
    var show = [];
    show.push(<div>Searching for: <br /></div>);

    props.searchFlight.Data.OriginDestinationInformation.forEach(element => {
      show.push(<div>
        {element.OriginLocation.Location + " to " + element.DestinationLocation.Location + " on " + getFormatDate(element.DepartureDateTime.WindowAfter)}
      </div>);
    })
    return show;
  }


  return (
    <Fragment>
      <div className='loading'>

        <div className="loader flight-loader-width">
          <div className="plane">
            <img src="assets/img/loader5.gif" className="plane-img" />
          </div>
          <div className='postion-relative flight-loaders'>
            <div className="earth-wrapper">
              <div className="earth"></div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          <div className='position-absolute bottom-0 text-center w-100'>
            {/* <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div> */}
            <div >
              We are searching for <i>live</i> flights and fares<i> in real-time.</i>
              <br></br>
              Please wait for a few seconds…
            </div>
          </div>
        </div>
      </div>
      {/* <div className='loading'>
        <div className='flight-loader2'>
        </div>
        <div className='position-absolute bottom-0 text-center'>
            <div >
              We are looking for the best available flights for your search.
              <br></br>
              This might take a few seconds. Please do not close this window.
            </div>
            <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div>
          </div>
      </div> */}

    </Fragment>)
}

export default LoadingFlight;
