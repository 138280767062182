import { Fragment } from 'react';
import PrintBookingDetails from './Components/PrintBookingDetails';
import HotelDetails from './Components/HotelDetails';
import RateDetails from './Components/RateDetails';
import ContactPerson from './Components/ContactPerson';
import GuestInformation from './Components/GuestInformation';
import BillingDetails from './Components/BillingDetails';
import BookingRemarks from './Components/BookingRemarks';
import Booking from './Components/Booking';
import HotelPolicy from './Components/HotelPolicy';
import TravellerInfo from '../BookingConfirmation/Components/TravellerInfo';
import PrintHotelBookingDetails from '../ShoppingCart/Confirmation/Components/PrintHotelBookingDetails';

const HotelPrintConfrimation = (props) => {
    let hotelBookingJSON = "";
    console.log(props.page);
    if (props.page !== undefined && props.page === 'hotelRetrieve') {
        hotelBookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
    } else {
        hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
    }
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-confirm-hotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    let hotelFirstImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length > 0 ? hotelBookingJSON.data.hotelResults[0].images[0].imagePath : hotelLogoLink;
    let hotelSecondImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length > 0 ? hotelBookingJSON.data.hotelResults[0].images[1] !== undefined ? hotelBookingJSON.data.hotelResults[0].images[1].imagePath : "" : "";
    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="col-lg-12">
                                <div className='row'>
                                    {props.page !== 'hotelRetrieve' && <PrintBookingDetails hotelBookingJSON={hotelBookingJSON}></PrintBookingDetails>}
                                    {
                                        props.page !== undefined && props.page === 'hotelRetrieve' &&
                                        <PrintHotelBookingDetails hotelBookingJSON={hotelBookingJSON} />
                                    }
                                    {hotelSecondImage !== '' &&
                                        <div className="hotel-list mt-1 row">
                                            <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-6'>
                                                <img src={hotelFirstImage} className='w-100' />
                                            </div>
                                            <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-6'>
                                                <img src={hotelSecondImage} className='w-100' />
                                            </div>
                                            <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                        </div>}
                                    {hotelSecondImage === '' &&
                                        <div className="hotel-list mt-1 row">
                                            <div className='hotel-confirmation-single-img col md-12'>
                                                <img src={hotelFirstImage} className='w-100' />
                                            </div>

                                            <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                        </div>}
                                    <div className="tour_details_boxed_inner">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion_flex_area">
                                                <Booking hotelBookingJSON={hotelBookingJSON}></Booking>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <RateDetails hotelBookingJSON={hotelBookingJSON} show="show"></RateDetails>
                                            </div>
                                            <hr></hr>
                                            {
                                                props.page !== undefined && props.page === 'hotelRetrieve' && <div className="accordion_flex_area">
                                                    <TravellerInfo bookingJSON={hotelBookingJSON} serviceCode="FLHT"></TravellerInfo>
                                                </div>
                                            }
                                            {(props.page === undefined || props.page !== 'hotelRetrieve') &&
                                                <div className="accordion_flex_area">
                                                    <ContactPerson hotelBookingJSON={hotelBookingJSON} print="1"></ContactPerson>
                                                </div>
                                            }

                                            {props.page !== 'hotelRetrieve' && <hr></hr>}
                                            {
                                                (props.page !== 'hotelRetrieve') &&
                                                <div className="accordion_flex_area">
                                                    <GuestInformation hotelBookingJSON={hotelBookingJSON}></GuestInformation>
                                                </div>
                                            }

                                            {props.page !== 'hotelRetrieve' && <hr></hr>}
                                            {
                                                props.page !== 'hotelRetrieve' &&
                                                <div className="accordion_flex_area">
                                                    <BillingDetails hotelBookingJSON={hotelBookingJSON} print="1"></BillingDetails>
                                                </div>
                                            }

                                            {(props.page === undefined || props.page !== 'hotelRetrieve') && hotelBookingJSON.data.hotelMiscellaneousInfo.miscellaneousInfo[0].text !== "" ? <Fragment><hr></hr>
                                                <div className="accordion_flex_area">
                                                    <BookingRemarks hotelBookingJSON={hotelBookingJSON}></BookingRemarks>
                                                </div></Fragment> : ''}
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <HotelPolicy hotelBookingJSON={hotelBookingJSON}></HotelPolicy>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default HotelPrintConfrimation;