import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import { NavLink, useHistory } from 'react-router-dom';
import useDocumentTitle from "../../../../components/Common/layout/useDocumentTitle";
import useHttp from "../../../../services/use-http";
import { SearchRegionApi } from "../../../../services/Region-API";
import { searchCommonList } from "../../../../services/Fee-API";
import { searchAllAirline } from "../../../../services/Airline-API";
import { SearchHotelChain } from "../../../../services/Fee-API";
const postionOptions = [
    { value: 1, label: "Manager" },
    { value: 2, label: "Product Manager" },
    { value: 3, label: "Team Leader" },
    { value: 4, label: "Manager-Social Media" },
    { value: 5, label: "Manager-Marketing" },
    { value: 6, label: "Manager-Operations" },
    { value: 7, label: "Manager-Sales" },
    { value: 8, label: "Manager-Human Resources" },
    { value: 9, label: "Manager-Customer Success" },
    { value: 10, label: "Manager-Training" },
    { value: 11, label: "Vice President-Sales" },
    { value: 12, label: "Vice President-Marketing" },
    { value: 13, label: "Vice President-Operations" },
    { value: 14, label: "Vice President-Business Development" },
    { value: 15, label: "Chief Executive Officer" },
    { value: 16, label: "Chief Financial Officer" },
    { value: 17, label: "Executive - Marketing" },
    { value: 18, label: "Executive-Social Media" },
    { value: 19, label: "Executive - Operations" },
    { value: 20, label: "Executive - Training" },
    { value: 21, label: "Executive - Sales" },


];




const tripReasonOptions = [
    { value: 1, label: "All" },
    { value: 2, label: "Business" },
    { value: 5, label: "Personal" },
];
const cabinClassOptions = [
    { value: 1, label: "Economy" },
    { value: 2, label: "Premium Economy" },
    { value: 3, label: "Business" },
    { value: 4, label: "First" },

];
const lowestFareRuleOptions = [
    { value: 1, label: "Allow lowest available fare only" },
    { value: 2, label: "Allow lowest with maximum 1 connecting flight" },
    { value: 3, label: "Allow Layovers" },
    { value: 4, label: "Lowest refundable" },

];
const lowestRateRuleOptions = [
    { value: 1, label: "Allow lowest available rate only" },
    { value: 2, label: "Lowest refundable" },
];

const upgradeClassOptions = [
    { value: 1, label: "Business Class" },
    { value: 2, label: "Premium Economy and if not available, to Business Class" },

];

const ratingOptions = [
    { value: 'All', label: 'All' },
    { value: '5', label: '5' },
    { value: '4', label: '4' },
    { value: '3', label: '3' },
    { value: '2', label: '2' },
    { value: '1', label: '1' }
];




const RulesForm = () => {
    postionOptions.sort((a, b) => a.label.localeCompare(b.label));
    cabinClassOptions.sort((a, b) => a.label.localeCompare(b.label));
    lowestFareRuleOptions.sort((a, b) => a.label.localeCompare(b.label));
    useDocumentTitle("New Policy")
    const { t } = useTranslation(['translation.Rules']);
    const [postion, setPostion] = useState('');
    const [tripReason, setTripReason] = useState('');
    const [CabinClass, setCabinClass] = useState();
    const [rbd, setRbd] = useState('');
    const [regionFromCode, setRegionFromCode] = useState('');
    const [regionToCode, setRegionToCode] = useState('');
    const [lowestFareRule, setLowestFareRule] = useState('');
    const [UpgradeClass, setUpgradeClass] = useState('');
    const [rating, setRating] = useState('');
    const [hotelRegion, setHotelRegion] = useState('');
    const [preferredAirlineOneValue, setPreferredAirlineOneValue] = useState('');
    const [lowestRateRule, setLowestRateRule] = useState('');
    const [chainCode, setChainCode] = useState('');
    const [ischecked, setIschecked] = useState('false');
    const { sendRequest: sendRequestRegion, data: loadedRegion } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestCommonListRbd, data: loadedRbd } = useHttp(searchCommonList);
    const { sendRequest: sendRequestAirline, statusAirline, data: loadedAirline } = useHttp(searchAllAirline);
    const { sendRequest: sendRequestHotelRegion, data: loadedHotelRegion } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestChainCode, statusChainCode, data: loadedChainCode } = useHttp(SearchHotelChain);

    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const onSearchChainCode = ({
            hotelCode: "",
            hotelName: ""
        });

        sendRequestChainCode(onSearchChainCode);
        const onSearchHotelRegion = ({
            regionID: "",
            serviceID: '21',
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestHotelRegion(onSearchHotelRegion);
        const onSearchAirline = ({
            airlineCode: "",
            airlineName: ""
        });

        sendRequestAirline(onSearchAirline);

        const onSearchRegion = ({
            regionID: "",
            serviceID: '20',
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestRegion(onSearchRegion);
        const onSearchCommonListRbd = ({
            methodName: "RBD",
            methodCode: "RBD",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListRbd(onSearchCommonListRbd);

    }, [
        sendRequestRegion, sendRequestCommonListRbd, sendRequestAirline, sendRequestHotelRegion, sendRequestChainCode
    ])

    let chainCodeOptions = [];
    let chainCodeArrayData = [];
    for (let i in loadedChainCode) {
        if (loadedChainCode['errors'].status === 'FALSE') {
            if (i === 'data') {
                chainCodeArrayData = loadedChainCode[i];
            }
        }
    }

    for (let index = 0; index < chainCodeArrayData.length; index++) {
        chainCodeOptions = [...chainCodeOptions, { value: chainCodeArrayData[index].hotelCode, label: chainCodeArrayData[index].hotelName + " (" + chainCodeArrayData[index].hotelCode + ")" }]
    }

    let airlineArrayData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                airlineArrayData = loadedAirline[i];
            }
        }
    }

    let airlineOptions = [];
    for (let index = 0; index < airlineArrayData.length; index++) {
        airlineOptions = [...airlineOptions, { value: airlineArrayData[index].airlineCode, label: airlineArrayData[index].airlineName + " (" + airlineArrayData[index].airlineCode + ")" }]
    }

    // airlineOptions = [{ value: '', label: t('Please Select') }, ...airlineOptions]

    let rbdArrayData = [];
    for (let i in loadedRbd) {
        if (loadedRbd['errors'].status === 'FALSE') {
            if (i === 'data') {
                rbdArrayData = loadedRbd[i];
            }
        }
    }

    let rbdOptions = [];
    for (let index = 0; index < rbdArrayData.length; index++) {
        rbdOptions = [...rbdOptions, { value: rbdArrayData[index].listValue, label: rbdArrayData[index].listValue }]
    }
    let regionOptions = []
    let regionArrayData = [];
    for (let i in loadedRegion) {
        if (loadedRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                regionArrayData = loadedRegion[i];
            }
        }
    }


    for (let index = 0; index < regionArrayData.length; index++) {
        regionOptions = [...regionOptions, { value: regionArrayData[index].id, label: regionArrayData[index].regionName }]
    }
    let hotelRegionOtions = []
    let hotelRegionArrayData = [];
    for (let i in loadedHotelRegion) {
        if (loadedHotelRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                hotelRegionArrayData = loadedHotelRegion[i];
            }
        }
    }


    for (let index = 0; index < hotelRegionArrayData.length; index++) {
        hotelRegionOtions = [...hotelRegionOtions, { value: hotelRegionArrayData[index].id, label: hotelRegionArrayData[index].regionName }]
    }

    const positionChangeChangeHandler = (event) => {
        setPostion(event);
    };
    const lowestFareRuleChangeChangeHandler = (event) => {
        setLowestFareRule(event);
    };
    const cabinClassChangeChangeHandler = (event) => {
        setCabinClass(event.value);
    };

    const tripReasonChangeHandler = (event) => {
        setTripReason(event.value);
    };
    const regionFromChangeHandler = (event) => {
        setRegionFromCode(event.value);
    };

    const regionToChangeHandler = (event) => {
        setRegionToCode(event.value);
    };
    const hotelRegionChangeHandler = (event) => {
        setHotelRegion(event.value);
    }
    const rbdChangeHandler = (event) => {
        if (event.value !== '') {
            setRbd(event.value);
        }
        else {
            setRbd('');
        }
    };
    const preferredAirlineOneChangeHandler = (event) => {
        setPreferredAirlineOneValue(event)
    };
    const upgradeClassChangeChangeHandler = (event) => {
        setUpgradeClass(event.value);
    };
    const ratingChangeHandler = (event) => {
        setRating(event.value);
    };
    const lowestRateRuleChangeChangeHandler = (event) => {
        setLowestRateRule(event);
    };
    const brandsChangeHandler = (event) => {
        setChainCode(event);
    };
    const submitFormHandler = () => { }
    const changeLocation = () => { }
    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            <div className="card-body">
                                <div className="col-md-12 ">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'> New Policy</span></label>
                                        <form className='form'>


                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('position')} <span class="error-icon"> *</span></label>
                                                        <Dropdown
                                                            onChange={positionChangeChangeHandler}
                                                            value={postion}
                                                            options={postionOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={false}
                                                            isMulti={true}
                                                            autoFocus

                                                        />
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('tripReason')} <span class="error-icon"> *</span></label>
                                                        <Dropdown onChange={tripReasonChangeHandler}
                                                            value={tripReasonOptions.filter(option => option.value === tripReason)}
                                                            options={tripReasonOptions}
                                                            textField="listValue"
                                                            dataItemKey="listID"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={false}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Allowed Services</label>
                                                        <div className="row ">
                                                            <div className='col-md-3 col-sm-12 control '>
                                                                <span className="text-primary fw-bold"> <input id="active" className="form-check-input" checked type="checkbox" name="Flight"></input>  Flight</span>
                                                            </div>
                                                            <div className='col-md-3 col-sm-12 control'>
                                                                <span className="text-primary fw-bold"> <input className="form-check-input" type="checkbox" name="Hotel" checked></input> Hotel</span>
                                                            </div>
                                                            <div className='col-md-4 col-sm-12 control'>
                                                                <span className="text-primary fw-bold"> <input className="form-check-input" type="checkbox" name=""></input> Service Request</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <label className="h5 text-black mb-3 mt-2 col-lg-12" htmlFor='position'><i class="fas fa-plane-departure"></i> {t('flightRules')}</label>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('cabinClass')} <span class="error-icon"> *</span></label>
                                                        <Dropdown
                                                            onChange={cabinClassChangeChangeHandler}
                                                            value={cabinClassOptions.filter(option => option.value === CabinClass)}
                                                            options={cabinClassOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={false}

                                                        />
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('fromRegion')}</label>
                                                        <Dropdown onChange={regionFromChangeHandler}
                                                            value={regionOptions.filter(option => option.value === regionFromCode)}
                                                            options={regionOptions}
                                                            textField="regionName"
                                                            dataItemKey="id"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" />
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('toRegion')}</label>
                                                        <Dropdown onChange={regionToChangeHandler}
                                                            value={regionOptions.filter(option => option.value === regionToCode)}
                                                            options={regionOptions}
                                                            textField="regionName"
                                                            dataItemKey="id"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" />
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('rbd')}</label>
                                                        <Dropdown onChange={rbdChangeHandler}
                                                            //value={rbdOptions.filter(option => option.value === rbd)}
                                                            options={rbdOptions}
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                                            placeholder="Please Select"
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('airlinePreference')}</label>
                                                        <Dropdown onChange={preferredAirlineOneChangeHandler}
                                                            value={preferredAirlineOneValue}
                                                            options={airlineOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                            placeholder="Please Select"
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('executives')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                    </div>

                                                </div>
                                            </div>

                                            <div>
                                                <label className="h5 text-black my-3 col-lg-12" htmlFor='position'>{t('lowestFareandMarginRule')}</label>
                                                <div className="col-md-12 rule-box">
                                                    <div className='row '>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('lowestFareRule')}</label>
                                                            <Dropdown
                                                                onChange={lowestFareRuleChangeChangeHandler}
                                                                value={lowestFareRule}
                                                                options={lowestFareRuleOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                isDisabled={false}
                                                                isMulti={true}

                                                            />

                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control '>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label htmlFor="active" className="mb-2 h6 text-primary">   {t('marginRule')}</label>
                                                                <input id="active" className="form-check-input ms-3" type="checkbox" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control'>
                                                            {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('percentage')}</label>
                                                                <input id="percentage" className="form-check-input ms-3" type="radio" name="fav_language" /> */}

                                                            <span class="text-primary"> <input id="percentage" className="form-check-input ms-3" type="radio" />  {t('percentage')}</span>
                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control'>
                                                            {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('amount')}</label>
                                                                <input id="amount" className="form-check-input ms-3" type="radio" name="fav_language" /> */}

                                                            <span class="text-primary"> <input id="amount" className="form-check-input ms-3" type="radio" name="fav_language" />  {t('amount')}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('cheapestFlight')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <hr className="my-2"></hr> */}
                                            <div>
                                                <label className="h5 text-black mt-5 mb-3 col-lg-12" htmlFor='position'>{t('upgradeRulePolicy')}</label>

                                                <div className="col-md-12 rule-box">
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('nextHigher')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{t('economyClass')}</label>
                                                        <Dropdown onChange={upgradeClassChangeChangeHandler}
                                                            value={upgradeClassOptions.filter(option => option.value === UpgradeClass)}
                                                            options={upgradeClassOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                            placeholder="Please Select"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <label className="h5 text-black mt-5 mb-3 col-lg-12" htmlFor='position'>{t('timeWindow')}</label>
                                                <div className="col-md-12 rule-box">
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-12 control '>
                                                            <span className="text-primary"> <input id="active" className="form-check-input" type="radio" name="segment"></input>  Segment-wise</span>
                                                        </div>
                                                        <div className='col-md-6 col-sm-12 control'>
                                                            <span className="text-primary"> <input id="active" className="form-check-input" type="radio" name="segment"></input>  Itinerary-wise</span>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Allow fast flights within the margins of the fastest flight:</label>
                                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="h5 text-black mt-5 mb-3 col-lg-12" htmlFor='position'>Time Window for meeting start time</label>
                                                <div className="col-md-12 rule-box">

                                                    <div className='row'>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Allow flights if arrival time is within the below minutes of the meeting start time</label>
                                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <hr className="mt-4 mb-1"></hr> */}
                                            <label className="h5 text-black mt-5 mb-3 col-lg-12" htmlFor='position'><i class="fas fa-hotel"></i> Hotel</label>
                                            <div className="col-md-12 rule-box">
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>Region</label>
                                                        <Dropdown
                                                            onChange={hotelRegionChangeHandler}
                                                            value={hotelRegionOtions.filter(option => option.value === hotelRegion)}
                                                            options={hotelRegionOtions}
                                                            textField="regionName"
                                                            dataItemKey="id"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={false}

                                                        />
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Rating</label>
                                                        <Dropdown onChange={ratingChangeHandler}
                                                            value={ratingOptions.filter(option => option.value === rating)}
                                                            options={ratingOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="h5 text-black mt-5 mb-3 col-lg-12" htmlFor='position'>Lowest Rate and Margin Rules</label>
                                                <div className="col-md-12 rule-box">
                                                    <div className='row'>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>Lowest Rate Rules</label>
                                                            <Dropdown
                                                                onChange={lowestRateRuleChangeChangeHandler}
                                                                value={lowestRateRule}
                                                                options={lowestRateRuleOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                isDisabled={false}
                                                                isMulti={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control '>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label htmlFor="active" className="mb-2 h6 text-primary">   {t('marginRule')}</label>
                                                                <input id="active" className="form-check-input ms-3" type="checkbox" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control'>
                                                            {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('percentage')}</label>
                                                                <input id="percentage" className="form-check-input ms-3" type="radio" name="fav_language" /> */}

                                                            <span class="text-primary"> <input id="percentage" className="form-check-input ms-3" type="radio" />  {t('percentage')}</span>
                                                        </div>
                                                        <div className='col-md-4 col-sm-12 control'>
                                                            {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('amount')}</label>
                                                                <input id="amount" className="form-check-input ms-3" type="radio" name="fav_language" /> */}

                                                            <span class="text-primary"> <input id="amount" className="form-check-input ms-3" type="radio" name="fav_language" />  {t('amount')}</span>
                                                        </div>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>Allow cheapest hotel rooms within the margins of the lowest rate:</label>
                                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text'></input>
                                                        </div>
                                                        <div className='col-md-12 col-sm-12 control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>Preferred Hotel Brands</label>
                                                            <Dropdown onChange={brandsChangeHandler}
                                                                value={chainCode}
                                                                options={chainCodeOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                isMulti={true}
                                                                isDisabled={false}
                                                            />
                                                        </div>


                                                    </div>

                                                </div>
                                                <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                            </div>

                                        </form>
                                        <div className='mt-3 actions row'>
                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                <a href="/searchRules" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                            </div>
                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                <div className="float-md-end float-lg-end">
                                                    <a href="/searchRules" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                    <button id="btnNew" onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('new')}</button>
                                                    <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
    </Fragment>
}

export default RulesForm;