import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import Dropdown from 'react-dropdown';

// const company_name = [
//     { value: 'Fareladder', label: 'Fareladder' },
//     { value: 'Amazon Travel Agency', label: 'Amazon Travel Agency' },
//     { value: 'Delta travels', label: 'Delta travels' }
//   ];
const cabin_list = [
    { value: 'Lorem ipsum', label: 'Lorem ipsum' }
  ];
const fare_rule = [
    { value: 'Rule Application and other conditions ', label: 'Rule Application and other conditions ' },
    { value: 'Flight Application', label: 'Flight Application' },
    { value: 'Advance Reservations/Ticketing ', label: 'Advance Reservations/Ticketing ' },
    { value: 'Accompanied Travel ', label: 'Accompanied Travel ' },
    { value: 'Travel Restrictions ', label: 'Travel Restrictions ' },
    { value: 'Ticket Endorsements', label: 'Ticket Endorsements' },
    { value: 'Rule Application and other condition', label: 'Rule Application and other condition' }
];
//   const time_slot = [
//     { value: 'Any time', label: 'Any time' },
//     { value: 'Morning', label: 'Morning' },
//     { value: 'Afternoon', label: 'Afternoon' },
//     { value: 'Evening', label: 'Evening' },
//     { value: 'Night', label: 'Night' }
//   ];
    const titles_name = [
   
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Master', label: 'Master' },
    { value: 'Miss', label: 'Miss' },

  ];
  const titles_name_child = [
    { value: 'Master', label: 'Master' },
    { value: 'Miss', label: 'Miss' }

  ];
  const gender_list = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];
  const card_list = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' },
  ];
  const wheel_list = [
    { value: 'Required', label: 'Required' },
    { value: 'Not Required', label: 'Not Required' },
  ];
  const prefernce_list = [
    { value: 'No Preference', label: 'No Preference' }
  ];
  const airline_provider_list = [
    { value: 'Air Miles', label: 'Air Miles' },
    { value: 'Air New Zealand Air Points', label: 'Air New Zealand Air Points' },
    { value: 'Asiana Club', label: 'Asiana Club' },
    { value: 'Life Miles', label: 'Life Miles' },
    { value: 'Delta Skymiles', label: 'Delta Skymiles' },

  ];
const priceList = [
    { value: 'Price', label: 'Price' },
    { value: 'Duration', label: 'Duration' },
    { value: 'Price + Duration ', label: 'Price + Duration ' },
    { value: 'Outbound Departure Time', label: 'Outbound Departure Time' },
    { value: 'Outbound Arrival Time', label: 'Outbound Arrival Time' },
    { value: 'Outbound Duration', label: 'Outbound Duration' }, 
    { value: 'Outbound Airline', label: 'Outbound Airline' },
    { value: 'Inbound Arrival Time', label: 'Inbound Arrival Time' },
    { value: 'Inbound Duration', label: 'Inbound Duration' },
  ];
//   const sortList = [
//     { value: 'Ascending A-Z', label: 'Ascending A-Z' },
//     { value: 'Decending Z-A', label: 'Decending Z-A' },
//   ];  

// let isClicked = false;

const FlightDetails = () => {
useDocumentTitle('Flight Book');
const [error1, setError] = useState();

const test = () => {
    setError({
        title: "Flight Details",
        message: 
        <div className='col-md-12'>
            <div className='gt-h-400px'>
            <div className='row'>
                <div className='col-md-6 h6 text-muted my-auto'><i className="fa-solid fa-clock"></i> Flight Duration</div>
                <div className='col-md-6 h6 text-muted my-auto'> 24h 30m</div>
            </div>
            <hr className='my-1'></hr>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Flight Number</div>
                <div className='col-md-6 text-12px my-auto'> BA 108 - AIRBUS INDUSTRIE A330-300 JET</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Journey Duration</div>
                <div className='col-md-6 text-12px my-auto'> 24h 05m</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Flight Status</div>
                <div className='col-md-6 text-12px my-auto'> On Schedule</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Airport Location Code</div>
                <div className='col-md-6 text-12px my-auto'> LGW</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Airport</div>
                <div className='col-md-6 text-12px my-auto'> LGW - London Gadwick, GB</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport Location Code</div>
                <div className='col-md-6 text-12px my-auto'> CDG</div>
            </div> */}
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport Diversion</div>
                <div className='col-md-6 text-12px my-auto'> False</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport</div>
                <div className='col-md-6 text-12px my-auto'> CDG - Paris Charles de Gaulle, FR</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Validating Airline Code</div>
                <div className='col-md-6 text-12px my-auto'> U2</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Validating Airline </div>
                <div className='col-md-6 text-12px my-auto'>U2 - Easyjet</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Equipment</div>
                <div className='col-md-6 text-12px my-auto'></div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Equipment Airline Type</div>
                <div className='col-md-6 text-12px my-auto'>319</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Date Time Scheduled</div>
                <div className='col-md-6 text-12px my-auto'>7th Jun 2023, 12:00 AM</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Date Time Scheduled</div>
                <div className='col-md-6 text-12px my-auto'>9th Jun 2023, 12:00 AM</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Comments</div>
                <div className='col-md-6 text-12px my-auto'>LGW CDG-9/NON-SMOKING</div>
            </div>
            </div>
        </div>
        
        
        
        ,
        // onConfirm={errorHandler}
    });
}  

const baggageAllowed = () => {
    setError({
        title: "Add Baggage",
        message: <div className='col-md-12'>
            <div className='gt-h-400px'>
                <div className='row'>
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Adult</label>
                        <input
                        type="text"
                        className="form-control form-control-sm" />
                    </div>
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Child</label>
                        <input
                        type="text"
                        className="form-control form-control-sm" />
                    </div>
                </div>
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
}  
const taxesPopup = () => {
    setError({
        title: "Add Taxes",
        message: <div className='col-md-12'>
            <div className='gt-h-400px'>
                <div className='row'>
                    <div className="control col-md-2">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Code</label>
                        <input
                        type="text"
                        className="form-control form-control-sm" />
                    </div>
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Name</label>
                        <input
                        type="text"
                        className="form-control form-control-sm" />
                    </div>
                    <div className="control col-md-4">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Amount</label>
                        <input
                        type="text"
                        className="form-control form-control-sm" />
                    </div>
                    
                </div>
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
}  
const viewSeat = () => {
    setError({
        title: "View Seats",
        message: <div className='col-md-12'>
            <div className='gt-h-400px position-relative'>
                <div className='row'>
                    <div className='col-md-5 position relative'>
                        <div className='position-fixed'>
                            <div className='col-md-12'>
                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                        <hr></hr>
                                        <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                        <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div className="plane-seats">
                            <div className="cockpit">
                                <h5 className='plane-text'>Please select a seat</h5>
                            </div>
                            <div className="exit exit--front fuselage">
                                
                            </div>
                          
                            <div className="exit exit--back fuselage">
                                
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <div className='col-md-12'>
                            <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                            <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                            <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                        </div>
                    </div> */}
                </div>
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
} 
const fareRule = () => {
    setError({
        title: "Fare Rules",
        message: <div className='col-md-12'>
            <div className='gt-h-400px'>
                <div className='row'>
                    <div className='col-md-12  my-auto'>
                        <Dropdown                
                        options={fare_rule}
                        textField=""
                        dataItemKey=""
                        arrowClosed={<span className="arrow-closed" />}
                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Rule Application and other condition"  />
                    </div>
                    <hr className='my-3'></hr>
                    <div className='text-12px'>
                    note - the following text is informational and not validated for autopricing.
air canada restricted fare //-a-// application area these fares apply from
area 2area 3 to area 1. class of service these fares apply for economy class
service. capacity limitations the carrier shall limit the number of passengers
carried on any one flight at fares governed by this rule and such fares will
not necessarily be available on all fights. the number of seats, which the
cartier shall make available on a given flight, will be determined by the
carrier's best judgement. other conditions sequential use of flight coupons
~ this fare is only valid if the flights are taken in the booked sequence.
‘otherwise the fare will be recalculated based on the actual flight routing.

                    </div>
                    
                </div>
               
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
}  

const errorHandler = () => {
    setError(null);
};
return <Fragment>
{error1 && (
    <MdModal
        title={error1.title}
        message={error1.message}
        onConfirm={errorHandler}
    ></MdModal>
)}
        
{/*     <!-- Common Banner Area --> */}
    <section id="common_banner">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                    {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    </section>

    <section id="theme_search_form_tour"  className="fligth_top_search_main_form_wrapper">
        <div  className="container">
            <div  className="row">
            <div  className="col-lg-12">
                <div  className="theme_search_form_area">
                <div  className="row">
                    <div  className="col-lg-12">
                    <div className='row'>
                        <div className='col-md-12'>
                        <label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Import Flight</label>
                        </div>
                        <div className='col-md-12'>
                        <div className="tour_details_boxed_inner">
                            <div className="accordion" id="accordionExample">
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                <h5>1</h5>
                                </div>
                                <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    Travelers
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className='col-lg-12'>
                                    {/* <label for="" className="h5 text-primary mb-3"><i className="fas fa-user"></i> Traveller Details </label> */}
                                    <div className='row'>
                                        <div className="col-md-2">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Title <span className="error-icon"> *</span></label>
                                        <Dropdown 
                                        options={titles_name}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        </div>
                                        <div className="col-md-10">
                                        <div className='row'>
                                            <div className="control col-md-4">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm" />
                                            </div>
                                            <div className="control col-md-4">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Middle Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm" />
                                            </div>
                                            <div className="control col-md-4">
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Last Name <span className="error-icon"> *</span></label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="control col-md-3">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Pax Type <span className="error-icon"> * </span></label>
                                        <Dropdown 
                                        options={cabin_list}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        </div>
                                        <div className="control col-md-3">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Gender <span className="error-icon"> *</span></label>
                                        <Dropdown 
                                        options={gender_list}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        </div>
                                        <div className="control col-md-6">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Date of Birth <span className="error-icon"> * </span> <i title= "Date of Birth(Age of Adult should be 12 or above on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                            <Dropdown 
                                            options={cabin_list}
                                            textField=""
                                            dataItemKey=""
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            />
                                            </div>
                                            <div className='col-md-4'>
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                            <Dropdown 
                                            options={cabin_list}
                                            textField=""
                                            dataItemKey=""
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            />
                                            </div>
                                            <div className='col-md-4'>
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                            <Dropdown 
                                            options={cabin_list}
                                            textField=""
                                            dataItemKey=""
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                            />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Ticket Number </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                        <div className="control col-md-6 my-auto">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <button class="btn btn_theme btn_sm float-lg-end float-md-end">Add</button>
                                        </div>
                                        {/* 
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Meal Preference </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                        */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr >
                                                <th scope='col'>Name</th>
                                                <th scope='col'>Pax Type</th>
                                                <th scope="col">Currency Code</th>
                                                <th scope="col">Base Fare</th>
                                                <th scope="col">Taxes</th>
                                                <th scope="col">Total Fare</th>
                                                <th scope="col" className='text-center'>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='min-width-125 '>
                                                <div class="text-center"><a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" href="/NewCity/642edcc2a2b72fbb43e35697"><i class="fa-solid fa-pen"></i></a><button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete"><i class="fas fa-trash-alt"></i></button></div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className='row'>
                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="float-md-end float-lg-end">
                                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                <h5>2</h5>
                                </div>
                                <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">Booking Contact
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className='col-lg-12'>
                                            {/* <label for="" className="h5 text-primary mb-3"><i className="fas fa-scroll"></i> Booking Contact </label> */}
                                            <div className='row'>
                                                <div className="control col-md-6">
                                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Phone <span className="error-icon"> *</span> </label>
                                                    <input
                                                    type="text"
                                                    className="form-control form-control-sm" />
                                                </div>
                                                <div className="control col-md-6">
                                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Email <span className="error-icon"> *</span> </label>
                                                    <input
                                                    type="text"
                                                    className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                    <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="float-md-end float-lg-end">
                                                        {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                        <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                <h5>3</h5>
                                </div>
                                <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                    aria-expanded="false" aria-controls="collapseFour">
                                    Flight Details
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse"
                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                    {/* <div className='row'>
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Flight Details </label>
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Airline PNR <span className="error-icon"> * </span></label>
                                        <input
                                        type="text"
                                        maxLength={100}
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Airline <span className="error-icon"> * </span></label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" />
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">From <span className="error-icon"> * </span></label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">To <span className="error-icon"> * </span></label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" />
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className="control col-md-4">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Departure Date Time <span className="error-icon"> * </span></label>
                                        <input
                                        type="date"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-2">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <input
                                        type="time"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-4">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Arrival Date Time <span className="error-icon"> * </span></label>
                                        <input
                                        type="date"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-2">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <input
                                        type="time"
                                        className="form-control form-control-sm" />
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Flight Number <span className="error-icon"> * </span></label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Cabin <span className="error-icon"> * </span></label>
                                        <Dropdown 
                                        options={cabin_list}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Air Miles</label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" />
                                    </div>
                                    <div className="control col-md-6">
                                        {/* <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Baggage Allowed </label>
                                        <input
                                        type="text"
                                        className="form-control form-control-sm" /> */}
                                        <div className='my-auto'>
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        {/* href='javascript:void(0);' onClick={fareSummery} */}
                                            <button class="btn btn_theme btn_sm" onClick={baggageAllowed}>Add Baggage</button>
                                        </div>
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className="control col-md-12 mb-3">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <button class="btn btn_theme btn_sm float-lg-end float-md-end">Add</button>
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div className='col-md-12'>
                                        <table className="table table-bordered">
                                        <thead>
                                            <tr >
                                            <th scope='col'>Airline PNR</th>
                                            <th scope="col">From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Departure Date Time</th>
                                            <th scope="col">Arrival Date Time</th>
                                            <th scope="col">Cabin</th>
                                            <th scope="col">Flight Number</th>
                                            <th scope="col">Air Miles</th>
                                            <th scope="col">Baggage Allowed </th>
                                            <th scope="col" className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='min-width-125 '>
                                                <div class="text-center"><a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" href="/NewCity/642edcc2a2b72fbb43e35697"><i class="fa-solid fa-pen"></i></a><button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete"><i class="fas fa-trash-alt"></i></button></div>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="float-md-end float-lg-end">
                                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                    <h5>4</h5>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                        aria-expanded="false" aria-controls="collapseseven">
                                    Add-ons
                                    </button>
                                    </h2>
                                    <div id="collapseseven" className="accordion-collapse collapse"
                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                        <div class="col-md-12">
                                            <div class="control">
                                                <label class="h6 text-primary mb-2 col-lg-12" for="category"><i class="fa fa-user text-12px"></i> Passenger Name:  <span class="text-black" id="passengerName1">Miss Minal Dhore</span></label>
                                            </div>
                                            <div class="control">
                                                <label class="h6 text-primary mb-2 col-lg-12" for="category">Flight No.:  <span class="text-black" id="passengerName1">DL 884 (JFK to SFO)</span></label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="control col-md-6">
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Meal Preference </label>
                                                <Dropdown 
                                                options={cabin_list}
                                                textField=""
                                                dataItemKey=""
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                />
                                            </div>
                                        
                                            <div className="control col-md-6">
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Seat No. </label>
                                                <input
                                                type="text"
                                                className="form-control form-control-sm" />
                                            </div>
                                       </div>
                                       <div className='row'>
                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                {/* <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p> */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="float-md-end float-lg-end">
                                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                <h5>5</h5>
                                </div>
                                <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                    aria-expanded="false" aria-controls="collapseseven">
                                    Price
                                    </button>
                                </h2>
                                <div id="collapseseven" className="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className='col-lg-12'>
                                    {/* <label for="" className="h5 text-primary mb-3"><i className="fas fa-money"></i> Price</label> */}
                                    <div className='row'>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Pax Type <span className="error-icon"> * </span></label>
                                        <Dropdown 
                                        options={cabin_list}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        </div>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Currency Code <span className="error-icon"> * </span></label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Base Fare <span className="error-icon"> * </span></label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Taxes <span className="error-icon"> * </span></label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="control col-md-6">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Total Fare</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm" />
                                        </div>
                                        <div className="control col-md-6">
                                            <div className='my-auto'>
                                            <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                            {/* href='javascript:void(0);' onClick={fareSummery} */}
                                            <button class="btn btn_theme btn_sm" onClick={taxesPopup}>Add Taxes</button>
                                        </div>
                                    </div>
                                    <div className="control col-md-12 my-2">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                        <button class="btn btn_theme btn_sm float-lg-end float-md-end">Add</button>
                                    </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr >
                                                <th scope='col'>Pax Type</th>
                                                <th scope="col">Currency Code</th>
                                                <th scope="col">Base Fare</th>
                                                <th scope="col">Taxes</th>
                                                <th scope="col">Total Fare</th>
                                                <th scope="col" className='text-center'>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='min-width-125 '>
                                                <div class="text-center"><a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" href="/NewCity/642edcc2a2b72fbb43e35697"><i class="fa-solid fa-pen"></i></a><button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete"><i class="fas fa-trash-alt"></i></button></div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="float-md-end float-lg-end">
                                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                <h5>6</h5>
                                </div>
                                <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                    aria-expanded="false" aria-controls="collapseThree">
                                    Add Service Fee
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='control'>
                                        <input  type='text' className="mb-0 form-control" id='markup'></input>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='control'>
                                        <Dropdown 
                                        options={prefernce_list}
                                        textField=""
                                        dataItemKey=""
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                        />
                                        </div>
                                    </div>
                                    <div class="text-black my-1"><input class="form-check-input tandc-options" type="checkbox" id="chkTandcChargeMerchant" />&nbsp; I agree that all service fees and markups will be charged by Fareladder's merchant and incur a 3% credit card charge.</div>
                                    <div class="text-black my-1"><input class="form-check-input tandc-options" type="checkbox" id="chkTandcChargeName" />&nbsp; I agree that the charge will be in the name of "Fareladder".</div>
                                    <div class="text-black my-1"><input class="form-check-input tandc-options" type="checkbox" id="chkTandcZeroDispute" />&nbsp; I agree that it is the agent's responsibility to ensure zero disputes.</div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="float-md-end float-lg-end">
                                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="accordion_flex_area">
                                <div className="accordion_left_side">
                                    <h5>7</h5>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                        aria-expanded="false" aria-controls="collapseFive">
                                    Payment
                                    </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse"
                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                    <div className='col-lg-12'>
                                        {/* <label for="" className="h5 text-primary mb-3"><i className="fas fa-money"></i> Payment </label> */}
                                        <div className='row'>
                                            <div className="control col-md-4">
                                                <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12"> Payment Mode <span className="error-icon"> * </span></label>
                                                <Dropdown 
                                                options={cabin_list}
                                                textField=""
                                                dataItemKey=""
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="float-md-end float-lg-end">
                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 
                                        <div className='row'>
                                        <div className="control col-md-12 my-auto">
                                            <button class="btn btn_theme btn_sm float-lg-end float-md-end">Add</button>
                                        </div>
                                        </div>
                                        */}
                                        {/* 
                                        <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <table className="table table-bordered">
                                            <thead>
                                                <tr >
                                                <th scope='col'>Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col" className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td></td>
                                                <td></td>
                                                <td className='min-width-125 '>
                                                    <div class="text-center"><a class="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" href="/NewCity/642edcc2a2b72fbb43e35697"><i class="fa-solid fa-pen"></i></a><button class="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title="Delete"><i class="fas fa-trash-alt"></i></button></div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        */}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
    </Fragment>




};

export default FlightDetails;