import {
    GET_ALL_REGION,
    GET_REGION,
    ADD_REGION,
    UPDATE_REGION,
    DELETE_REGION,
    GET_REGION_NAME,    
    SEARCH_REGION,
    REMOVE_REGION
} from './CONSTANTS'

export async function getAllRegion() {
    const response = await fetch(GET_ALL_REGION(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch region.');
    }    
    return data;
}

export async function getRegionByName(name) {
    const response = await fetch(GET_REGION_NAME(name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch region.');
    }
    return data;
}

export async function getSingleRegion(ID) {      
    const response = await fetch(GET_REGION(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch region.');
    }    
    return data;
}

export async function deleteSingleRegion(deleteData) {    
    const response = await fetch(DELETE_REGION(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not delete region.');
    }    
    return data;
}

export async function addRegion(addData) {    
    const response = await fetch(ADD_REGION(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });    
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create region.');
    }    
    return data;
}

export async function updateRegion(updateData) {    
    const response = await fetch(UPDATE_REGION(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });    
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update region.');
    }    
    return data;
}

export async function SearchRegionApi(searchData) {
    const response = await fetch(SEARCH_REGION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch region.');
    }    
    return data;
}

export async function removeRegionApi(removeData) {    
    const response = await fetch(REMOVE_REGION(), {
        method: 'POST',
        body: JSON.stringify(removeData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });    
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not remove region.');
    }    
    return data;
}
