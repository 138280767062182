import {
    SEARCH_RATE_PLAN_ALLOWED, UPDATE_RATE_PLAN_ALLOWED, ADD_RATE_PLAN_ALLOWED, GET_RATE_PLAN_ALLOWED, SEARCH_PCC_LIST,
    SEARCH_RATEPLAN_LIST,GET_ALL_COMPANY_LIST,SEARCH_RATEPLAN_PCC_WISE_LIST, SEARCH_RATEPLAN_LIST_OFFICE_WISE
} from './CONSTANTS'

export async function updateRatePlanAllowed(updateData) {
    // console.log(updateData);
    const response = await fetch(UPDATE_RATE_PLAN_ALLOWED(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update Rate Plan.');
    }
    // console.log(data);
    return data;

}
export async function addRatePlanAllowed(addData) {
    //  console.log(addData);
    const response = await fetch(ADD_RATE_PLAN_ALLOWED(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add Rate Plan');
    }
    //  console.log(data);
    return data;

}
export async function searchRatePlanAllowed(searchData) {
    console.log(searchData)
    const response = await fetch(SEARCH_RATE_PLAN_ALLOWED(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Rate Plan.');
    }

    console.log(data)
    return data;


}
export async function getPccList(searchData) {
    const response = await fetch(SEARCH_PCC_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not PCC LIST.');
    }
    return data;
}
export async function getRatePlanList(searchData) {
    const response = await fetch(SEARCH_RATEPLAN_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not RATE PLAN LIST.');
    }
    return data;
}
export async function getRatePlanListOfficeWise(searchData) {
    const response = await fetch(SEARCH_RATEPLAN_LIST_OFFICE_WISE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not RATE PLAN LIST.');
    }
    return data;
}
export async function getRatePlanPCCWiseList(searchData) {
    const response = await fetch(SEARCH_RATEPLAN_PCC_WISE_LIST(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not RATE PLAN LIST.');
    }
    return data;
}
export async function getAllCompanyList() {
    const response = await fetch(GET_ALL_COMPANY_LIST(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch company list.');
    }    
    return data;
}
export async function getSingleRatePlanAllowed(ID) {
    const response = await fetch(GET_RATE_PLAN_ALLOWED(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch rate plan Allowed.');
    }

    return data;
}