import { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { emailValidation } from '../../../../utils/Validation';
import { useTranslation } from 'react-i18next';
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

const ContactPerson = (props) => {
    const { t } = useTranslation(['translation.Booking']);
    const emailInputRef = useRef('');
    const phoneInputRef = useRef('');
    const phoneDestinationInputRef = useRef('');

    const [emailInputHasError, setEmailInputHasError] = useState(false);
    const [emailInvalidInputHasError, setEmailInvalidInputHasError] = useState(false);
    const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);
    const [phoneNumberDestinationInputHasError, setPhoneNumberDestinationInputHasError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    const [activeTabSet, setActiveTab] = useState();
    const [phoneNumberDestination, setPhoneNumberDestination] = useState('');
    const [countryDialCodeDestination, setCountryDialCodeDestination] = useState('');


    useEffect(() => {
        if (props.traveler.travelerInfo !== undefined && props.traveler.travelerInfo.traveler[0] !== undefined) {
            if (props.traveler.travelerInfo.traveler[0].email !== undefined && props.traveler.travelerInfo.traveler[0].email[0] !== undefined) {
                if (props.traveler.travelerInfo.traveler[0].email[0].emailId !== '') {
                    emailInputRef.current.value = props.traveler.travelerInfo.traveler[0].email[0].emailId;
                }
            }

            if (props.traveler.travelerInfo.traveler[0].telephone !== undefined && props.traveler.travelerInfo.traveler[0].telephone[0] !== undefined) {
                if (phoneNumber === '' && countryDialCode === '') {
                    let countryDialCodeLength = props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.split(' ').length <= 1 ? 0 : props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.split(' ')[0].length;
                    let phoneNumberLength = props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.replace(/[^0-9]/g, '').length;

                    setCountryDialCode(props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.split(' ')[0]);

                    setPhoneNumber(props.traveler.travelerInfo.traveler[0].telephone[0].phoneNumber.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
                }
                if (phoneNumberDestination === '' && countryDialCodeDestination === '') {
                    let countryDialCodeLength = props.traveler.travelerInfo.traveler[0].telephone[0].mobile !== undefined ? props.traveler.travelerInfo.traveler[0].telephone[0].mobile.split(' ').length <= 1 ? 0 : props.traveler.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[0].length : 0;
                    let phoneNumberLength = props.traveler.travelerInfo.traveler[0].telephone[0].mobile !== undefined ? props.traveler.travelerInfo.traveler[0].telephone[0].mobile.replace(/[^0-9]/g, '').length : 0;

                    setCountryDialCodeDestination(props.traveler.travelerInfo.traveler[0].telephone[0].mobile !== undefined ? props.traveler.travelerInfo.traveler[0].telephone[0].mobile.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : props.traveler.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[0] : '');

                    setPhoneNumberDestination(props.traveler.travelerInfo.traveler[0].telephone[0].mobile !== undefined ? props.traveler.travelerInfo.traveler[0].telephone[0].mobile.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength) : '');
                }
            }
        }

    }, [])
    const preferredCountries = ['us']
    const setPhone = (value) => {
        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            let countryDialCodeLength = phoneInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneInputRef.current.numberInputRef.value.split(' ')[0]);
            setPhoneNumber(phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberInputHasError(false);
        }
    };

    const setPhoneDestination = (value) => {
        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            //setPhoneNumberDestinationInputHasError(true);
            //return;
        }
        else {
            let countryDialCodeLength = phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCodeDestination(phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0]);

            setPhoneNumberDestination(phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberDestinationInputHasError(false);
        }
    };

    const onBlurPhoneDestination = (value) => {
        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
    };

    function assignContactPerson(event) {
        event.preventDefault();
        if (emailInputRef.current.value === '') {
            setEmailInputHasError(true);
            setEmailInvalidInputHasError(false);
            return;
        }
        else {
            setEmailInputHasError(false);
        }

        if (!emailValidation(emailInputRef.current.value)) {
            setEmailInvalidInputHasError(true);
            setEmailInputHasError(false);
            return;
        }
        else {
            setEmailInvalidInputHasError(false);
        }

        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
        else {
            setPhoneNumberDestinationInputHasError(false);
        }

        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

        bookingJSON.data.travelerInfo.traveler.forEach(element => {
            if (element.email !== undefined) {
                element.email[0].emailId = emailInputRef.current.value;
            }
            else {
                let email = [];
                email = [
                    {
                        "emailId": emailInputRef.current.value
                    }
                ]

                element.email = email;
            }

            if (element.telephone !== undefined) {
                element.telephone[0].phoneNumber = countryDialCode + " " + phoneNumber;
                element.telephone[0].mobile = countryDialCodeDestination + " " + phoneNumberDestination;
            }
            else {
                let telephone = [];
                telephone = [
                    {
                        "phoneNumber": countryDialCode + " " + phoneNumber,
                        "mobile": countryDialCodeDestination + " " + phoneNumberDestination
                    }
                ]

                element.telephone = telephone;
            }
        });
        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

        props.onContinueButton("2");
        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });
    }
    function onBlurCheck() {

        if (emailInputRef.current.value === '') {
            setEmailInputHasError(true);
            setEmailInvalidInputHasError(false);
            return;
        }
        else {
            setEmailInputHasError(false);
        }

        if (!emailValidation(emailInputRef.current.value)) {
            setEmailInvalidInputHasError(true);
            setEmailInputHasError(false);
            return;
        }
        else {
            setEmailInvalidInputHasError(false);
        }

        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
        else {
            setPhoneNumberDestinationInputHasError(false);
        }
    }

    return (<div className="accordion_flex_area" >
        <div className="accordion_left_side position-relative text-muted">
            <h5>2</h5>
            <span id="completeTwo" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header"  >
                <button id="headingTwo" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">Booking Contact
                </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse "
                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Email<span className="error-icon"> *</span></label>
                                <input type='text' className="mb-0 form-control"
                                    ref={emailInputRef}
                                    maxLength={100}
                                    onBlur={onBlurCheck}
                                    id='email'></input>
                                {emailInputHasError &&
                                    <p className="error-text">{t('validationEmailId')}</p>
                                }
                                {emailInvalidInputHasError &&
                                    <p className="error-text">{t('validationEmailId')}</p>
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone<span className="error-icon"> *</span></label>

                                <PhoneInput type='text' required
                                    country={'us'}
                                    id='phone'
                                    value={countryDialCode + phoneNumber}
                                    //onBlur={setPhone}
                                    //onBlur={onBlurCheck}
                                    placeholder=''
                                    //preferredCountries={preferredCountries}
                                    enableSearch={true}
                                    ref={phoneInputRef}
                                    onChange={setPhone}

                                />
                                {phoneNumberInputHasError &&
                                    <p className="error-text">{t('validationPhoneNumber')}</p>
                                }

                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone at Destination</label>
                                <PhoneInput type='text' required
                                    country={'us'}
                                    id='phoneDestination'
                                    value={countryDialCodeDestination + phoneNumberDestination}
                                    //onBlur={setPhoneDestination}
                                    onBlur={onBlurPhoneDestination}
                                    placeholder=''
                                    //preferredCountries={preferredCountries}
                                    enableSearch={true}
                                    ref={phoneDestinationInputRef}
                                    onChange={setPhoneDestination}
                                />
                                {phoneNumberDestinationInputHasError &&
                                    <p className="error-text">{t('validationPhoneDestinationNumber')}</p>
                                }

                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="float-md-end float-lg-end">
                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                <button type="button" onClick={assignContactPerson} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default ContactPerson