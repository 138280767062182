import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ProfileDetailsEN from "./en/ProfileDetails.json"
import ProfileDetailsFR from "./fr/ProfileDetails.json"
import RegistrationEN from "./en/Registration.json"
import RegistrationFR from "./fr/Registration.json"
import ContinentEN from "./en/Continent.json"
import ContinentFR from "./fr/Continent.json"

import CountryEN from "./en/Country.json"
import CountryFR from "./fr/Country.json"

import LoginFormEN from "./en/LoginForm.json"
import LoginFormFR from "./fr/LoginForm.json"

import CityEN from "./en/City.json"
import CityFR from "./fr/City.json"
import AirportEN from "./en/Airport.json"
import UserEN from "./en/User.json";

import AirportFR from "./fr/Airport.json"

import AirlineEN from "./en/Airline.json"
import AirlineFR from "./fr/Airline.json"

import ContentEN from "./en/Content.json";
import ContentFR from "./en/Content.json";

import OfficeEN from "./en/Office.json"
import OfficeFR from "./fr/Office.json"
import CustomizeEN from "./en/Customize.json"

import RoleEN from "./en/Role.json"
import RoleFR from "./fr/Role.json"

import CreditLimitEN from "./en/CreditLimit.json"
import CreditLimitFR from "./fr/CreditLimit.json"

import RegionEN from "./en/Region.json"
import RegionFR from "./fr/Region.json"
import ConfigurationEN from "./en/Configuration.json"
import ConfigurationFR from "./fr/Configuration.json"

import ForgotPasswordEN from "./en/ForgotPassword.json"
import ForgotPasswordFR from "./fr/ForgotPassword.json"

import FeeEN from "./en/Fee.json"
import FeeFR from "./fr/Fee.json"


import EmailServerEN from "./en/EmailServer.json"
import EmailServerFR from './fr/EmailServer.json'

import SearchFlightEN from "./en/SearchFlight.json"
import SearchFlightFR from "./fr/SearchFlight.json"

import BookingEN from "./en/Booking.json"
import BookingFR from "./fr/Booking.json"
import TravelAgentEN from "./en/TravelAgent.json"
import TravelAgentFR from "./fr/TravelAgent.json"

import MyTripsEN from "./en/MyTrips.json"
import MyTripsFR from "./fr/MyTrips.json"

import SearchHotelEN from "./en/SearchHotel.json"
import SearchHotelFR from "./fr/SearchHotel.json"

import HotelBookingEN from "./en/HotelBooking.json"
import HotelBookingFR from "./fr/HotelBooking.json"

import UserFriendlyEN from "./en/UserFriendly.json"
import UserFriendlyFR from "./fr/UserFriendly.json"

import FeesEN from './en/Fees.json'

import ShoppingCartBookingEN from "./en/ShoppingCartBooking.json"
import ShoppingCartBookingFR from "./fr/ShoppingCartBooking.json"

import WalletEn from "./en/Wallet.json"
import WalletFr from './fr/Wallet.json'

import WalletUtilizationEn from './en/WalletUtilization.json'
import WalletUtilizationFr from './fr/WalletUtilization.json'

import RatePlanAllowedEn from './en/RatePlanAllowed.json'
import RatePlanAllowedFr from './fr/RatePlanAllowed.json'

import RatePlanEn from './en/RatePlan.json'
import RatePlanFr from './fr/RatePlan.json'

import SightseeingEN from "./en/Sightseeing.json"
import SightseeingFR from "./fr/Sightseeing.json"
import ROEEN from "./en/Roe.json"
import ROEFR from "./fr/Roe.json"
import RulesEN from "./en/Rules.json"
import RulesFR from "./fr/Rules.json"

import ApproverEN from "./en/Approver.json"
import ApproverFR from "./fr/Approver.json"

import CustomFieldsEN from "./en/CustomFields.json"
import CustomFieldsFR from "./fr/CustomFields.json"

// the translations
const resources = {
  en: {
    'translation.ProfileDetails': ProfileDetailsEN,
    'translation.Registration': RegistrationEN,
    'translation.Continent': ContinentEN,
    'translation.Country': CountryEN,
    'translation.LoginForm': LoginFormEN,
    'translation.City': CityEN,
    'translation.Airport': AirportEN,
    'translation.User': UserEN,
    'translation.Airline': AirlineEN,
    'translation.Office': OfficeEN,
    'translation.Customize': CustomizeEN,
    'translation.Role': RoleEN,
    'translation.CreditLimit': CreditLimitEN,
    'translation.Content': ContentEN,
    'translation.Region': RegionEN,
    'translation.Configuration': ConfigurationEN,
    'translation.ForgotPassword': ForgotPasswordEN,
    'translation.Fee': FeeEN,
    'translation.EmailServer': EmailServerEN,
    'translation.SearchFlight': SearchFlightEN,
    'translation.Booking': BookingEN,
    'translation.TravelAgent': TravelAgentEN,
    'translation.MyTrips': MyTripsEN,
    'translation.SearchHotel': SearchHotelEN,
    'translation.HotelBooking': HotelBookingEN,
    'translation.Fees': FeesEN,
    'translation.UserFriendly': UserFriendlyEN,
    'translation.ShoppingCartBooking': ShoppingCartBookingEN,
    'translation.Wallet': WalletEn,
    'translation.RatePlanAllowed': RatePlanAllowedEn,
    'translation.RatePlan': RatePlanEn,
    'translation.WalletUtilization': WalletUtilizationEn,
    'translation.Sightseeing': SightseeingEN,
    'translation.Rules': RulesEN,
    'translation.ROE': ROEEN,
    'translation.Approver': ApproverEN,
    'translation.CustomFields': CustomFieldsEN

  },
  es: {
    'translation.ProfileDetails': ProfileDetailsFR,
    'translation.Registration': RegistrationFR,
    'translation.Continent': ContinentFR,
    'translation.Country': CountryFR,
    'translation.LoginForm': LoginFormFR,
    'translation.City': CityFR,
    'translation.Airport': AirportFR,
    'translation.Airline': AirlineFR,
    'translation.Office': OfficeFR,
    'translation.Role': RoleFR,
    'translation.CreditLimit': CreditLimitFR,
    'translation.Content': ContentFR,
    'translation.Region': RegionFR,
    'translation.Configuration': ConfigurationFR,
    'translation.ForgotPassword': ForgotPasswordFR,
    'translation.Fee': FeeFR,
    'translation.EmailServer': EmailServerFR,
    'translation.SearchFlight': SearchFlightFR,
    'translation.Booking': BookingFR,
    'translation.TravelAgent': TravelAgentFR,
    'translation.MyTrips': MyTripsFR,
    'translation.SearchHotel': SearchHotelFR,
    'translation.HotelBooking': HotelBookingFR,
    'translation.UserFriendly': UserFriendlyFR,
    'translation.ShoppingCartBooking': ShoppingCartBookingFR,
    'translation.Wallet': WalletFr,
    'translation.RatePlanAllowed': RatePlanAllowedFr,
    'translation.RatePlan': RatePlanFr,
    'translation.WalletUtilizationFr': WalletUtilizationFr,
    'translation.ROE': ROEFR,
    'translation.Rules': RulesFR,
    'translation.Sightseeing': SightseeingFR,
    'translation.Approver': ApproverFR,
    'translation.CustomFields': CustomFieldsFR
  },
};


let language = "en";

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: `${language}`,
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;