import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

let regionList = [];
let isDisabled = false;

function checkAllSelectedNodes(regionList) {
    let ids = [];
    regionList?.forEach(({ value, children }) => {
        ids = [...ids, value, ...checkAllSelectedNodes(children)];
    });
    return ids;
}

class Widget extends React.Component {
    constructor(props) {
        super(props);

        if (props.allRegions[0] !== undefined && props.allRegions[0].data !== undefined && sessionStorage.getItem('regionsJson') !== null) {
            isDisabled = parseInt(sessionStorage.getItem('isEditable')) === 0 ? true : false;
            regionList = JSON.parse(sessionStorage.getItem('regionsJson'));
            //regionList = props.allRegions[0].data.includeExcludeRegion.continentlst;

            regionList.forEach(element => {
                if (element.status === false) {
                    element.label = <span>{element.label} <i className="fas fa-times-circle text-danger"></i></span>
                }
                else {                    
                    element.label = <span>{element.label} <i className="fas fa-check-circle text-success"></i></span>                    
                }
                element.children.forEach(element => {
                    if (element.status === false) {
                        element.label = <span>{element.label} <i className="fas fa-times-circle text-danger"></i></span>
                    }
                    else {
                        element.label = <span>{element.label} <i className="fas fa-check-circle text-success"></i></span>
                    }
                    element.children.forEach(element => {
                        if (element.status === false) {
                            element.label = <span>{element.label} <i className="fas fa-times-circle text-danger"></i></span>
                        }
                        else {
                            element.label = <span>{element.label} <i className="fas fa-check-circle text-success"></i></span>
                        }
                    });
                });
            });
        }
        else {
            regionList = [];
        }
        this.state = { checked: checkAllSelectedNodes(), expanded: checkAllSelectedNodes(regionList) };
    }

    getSelectedItem(value) {
        this.props.fetchSelectedItem(value);
    }

    render() {
        const { checked, expanded } = this.state;
        return (
            <CheckboxTree
                nodes={regionList}
                checked={checked}
                expanded={expanded}
                onCheck={checked => this.setState({ checked })}
                onClick={this.getSelectedItem(checked)}
                onExpand={expanded => this.setState({ expanded })}
                disabled={isDisabled !== undefined ? isDisabled === true ? true : false : false}
                noCascade={true}
                iconsClass="fa5"
                icons={{
                    check: <span className="rct-icon rct-icon-check" />,
                    uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    halfCheck: <span className="rct-icon rct-icon-half-check" />,
                    expandClose: <span className="rct-icon rct-icon-expand-close" />,
                    expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                    expandAll: <span className="rct-icon rct-icon-expand-all" />,
                    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                    parentClose: <span className="rct-icon rct-icon-parent-close" />,
                    parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                    leaf: <span className="rct-icon rct-icon-leaf" />,
                }}
            />
        );
    }
}

export default Widget;
