import { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const FareSummary = (props) => {

    const location = useLocation();
    let hidePlusIcon = props.hideIcon === '1' ? "tour_details_boxed_inner fare-summary-pop plus-icon-hide ms-0" : "tour_details_boxed_inner fare-summary-pop";
    let taxPosClass = props.hideIcon === '1' ? "col-md-7" : "ms-4 col-md-7";
    let showOtherService = props.hideIcon === '1' ? "accordion-collapse collapse show" : "accordion-collapse collapse";
    let seatValue = props.bookingData !== undefined ? props.bookingData.data.travelerInfo.SpecialReqDetails !== undefined ? props.bookingData.data.travelerInfo.SpecialReqDetails[0].SeatRequests !== null ? props.bookingData.data.travelerInfo.SpecialReqDetails[0].SeatRequests.SeatRequest[0].Amount : "" : "" : "";
    let bookingJSON = '';

    if (location.pathname === '/RetrieveTrip' || location.pathname === 'RetrieveTrip') {
        bookingJSON = sessionStorage.getItem("sourcePNR") !== null ? JSON.parse(sessionStorage.getItem("BookingData")) : JSON.parse(localStorage.getItem("BookingData"));
    }
    else {
        bookingJSON = props.bookingData;
    }

    if (seatValue === "") {
        seatValue = bookingJSON !== null ? bookingJSON !== undefined ? bookingJSON.length > 0 ? bookingJSON.data.travelerInfo.SpecialReqDetails !== undefined ? bookingJSON.data.travelerInfo.SpecialReqDetails[0].SeatRequests !== null ? bookingJSON.data.travelerInfo.SpecialReqDetails[0].SeatRequests.SeatRequest[0].Amount : "" : "" : "" : "" : "";
    }

    const [showDataPaxwise, setShowDataPaxwise] = useState();
    let markup = props.FlightData.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && props.FlightData.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== null ? props.FlightData.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee : "";
    let markupAmount = '0';
    if (markup.length !== 0) {
        markup.filter(element => {
            if (element.text === "ServiceFee-Amount" || "ServiceFee-Percentage"
            ) {
                markupAmount = element.amount;
            }
        })
    }

    let airlineTransactionCharges = 0;
    let commission = 0;

    airlineTransactionCharges = bookingJSON !== undefined && bookingJSON !== null && bookingJSON !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount : 0;
    commission = bookingJSON !== undefined && bookingJSON !== null && bookingJSON !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount : 0;

    let addOnBaggageAmount = 0;
    let addOnMealAmount = 0;
    let addOnSeatAmount = 0;
    let addOnSpecialServiceAmount = 0;

    if (bookingJSON !== null && bookingJSON !== undefined) {
        if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
            if (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined) {
                bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.forEach(elementAddOn => {
                    if (elementAddOn.ssrType === 'BAGGAGE') {
                        addOnBaggageAmount = addOnBaggageAmount + parseFloat(elementAddOn.ssrCharge !== null && elementAddOn.ssrCharge !== '' ? elementAddOn.ssrCharge : 0);
                    }
                    if (elementAddOn.ssrType === 'MEAL') {
                        addOnMealAmount = addOnMealAmount + parseFloat(elementAddOn.ssrCharge !== null && elementAddOn.ssrCharge !== '' ? elementAddOn.ssrCharge : 0);
                    }
                    if (elementAddOn.ssrType === 'SEAT') {
                        addOnSeatAmount = addOnSeatAmount + parseFloat(elementAddOn.ssrCharge !== null && elementAddOn.ssrCharge !== '' ? elementAddOn.ssrCharge : 0);
                    }
                    if (elementAddOn.ssrType === 'SPECIAL') {
                        addOnSpecialServiceAmount = addOnSpecialServiceAmount + parseFloat(elementAddOn.ssrCharge !== null && elementAddOn.ssrCharge !== '' ? elementAddOn.ssrCharge : 0);
                    }
                });
            }
        }
    }

    let taxes = [];
    let dataPaxWise = [];
    function showTax(objTax) {
        let taxes = [];
        if (objTax.taxes !== undefined && objTax.taxes !== '' && objTax.taxes.tax !== undefined && objTax.taxes.tax !== '') {
            objTax.taxes.tax.forEach(tax => {
                taxes.push(
                    <label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                        <span className="area_flex_one text-muted">
                            <span className="ms-4 text-14px">{tax.taxDesc}{"(" + tax.taxCode + ")"}</span>
                            <span className="">{new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: tax.currencyCode,
                            }).format(tax.amount)}</span>

                        </span>
                    </label>)
            })
        }

        return taxes;
    }
    const innerFunction = useCallback(() => {
        props.FlightData.airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {

            if (element.code === 'ADT') {
                let MarkupValue = 0;
                let MarkupUnit = "";
                if (element.commisionInfo !== undefined) {
                    if (element.commisionInfo[0].earnedCommissionAmount !== undefined && element.commisionInfo[0].earnedCommissionAmount !== '' && parseFloat(element.commisionInfo[0].earnedCommissionAmount) !== 0) {
                        MarkupValue = MarkupValue + (parseFloat(element.commisionInfo[0].earnedCommissionAmount) * parseInt(element.quantity));
                        MarkupUnit = element.commisionInfo[0].currency;
                    }
                }
                dataPaxWise.push(
                    <div className="print-mt-15">
                        <div className='h6 text-black fw-bold'>Adult</div>
                        <label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">

                                <span className="h6 ms-2">Base Fare ({element.quantity} * {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount)})</span>
                                <span className="h6"> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount * element.quantity)}</span>
                            </span>
                        </label>

                        {element.taxes !== undefined && <div className='row'>
                            <div className={hidePlusIcon}>
                                <div >

                                    <div className="accordion_flex_area">

                                        <div className="accordion-item  fare-toggle-btn pb-0 fare-summry-fs-12px">
                                            <h2 className="ms-2 accordion-header" id="headingThree">
                                                <button className="accordion-button text-black collapsed mt-0" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                    <div className={taxPosClass}>

                                                        <span className="h6 text-black">Taxes ({element.quantity} * {element.taxes !== undefined ? new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode === undefined ? 'USD' : element.taxes.currencyCode
                                                        }).format(element.taxes.amount) : 0})</span>
                                                    </div>
                                                    <div className='right-0 position-absolute h6 text-black'>
                                                        <span> {element.taxes !== undefined ? new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount * element.quantity) : 0}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse"
                                                aria-labelledby="headingfive" data-bs-parent="#accordionFareSummary">
                                                {element.taxes !== undefined && showTax(element)}
                                            </div>
                                            <label className="form-check-label h6 mt-3" for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted h6">
                                                    <span className='fw-bold ms-2 text-primary'>Fare</span>
                                                    <span className='fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: element.totalFare.currencyCode
                                                    }).format((element.totalFare.amount - parseFloat(element.transaction !== undefined && element.transaction.amount !== undefined && element.transaction.amount !== '' ? element.transaction.amount : 0)) * element.quantity)}</span>
                                                </span>
                                            </label>
                                            {MarkupValue !== 0 && MarkupUnit !== '' && props.hideCommision == '5' && getConfigurationByBoolen("ALLOW_COMISSION") && <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted h6">
                                                    <span className='fw-bold ms-2 text-primary'>Commission</span>
                                                    <span className='fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: MarkupUnit
                                                    }).format(MarkupValue)}</span>
                                                </span>
                                            </label>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>

                )
                setShowDataPaxwise(dataPaxWise);

            } else if (element.code === 'CNN') {
                let MarkupValue = 0;
                let MarkupUnit = "";
                if (element.commisionInfo !== undefined) {
                    if (element.commisionInfo[0].earnedCommissionAmount !== undefined && element.commisionInfo[0].earnedCommissionAmount !== '' && parseFloat(element.commisionInfo[0].earnedCommissionAmount) !== 0) {
                        MarkupValue = MarkupValue + (parseFloat(element.commisionInfo[0].earnedCommissionAmount) * parseInt(element.quantity));
                        MarkupUnit = element.commisionInfo[0].currency;
                    }
                }

                dataPaxWise.push(
                    <div>
                        <hr className="my-1"></hr>
                        <div className='h6 my-2 text-black fw-bold '>Child</div>

                        <label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="h6 ms-2">Base Fare({element.quantity} * {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount)})</span>
                                <span> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount * element.quantity)}</span>
                            </span>
                        </label>

                        {element.taxes !== undefined && <div className='row'>
                            <div className={hidePlusIcon}>
                                <div >
                                    <div className="accordion_flex_area">

                                        <div className="accordion-item  fare-toggle-btn pb-0">
                                            <h2 className="ms-2 accordion-header" id="headingThree">
                                                <button className="accordion-button text-black collapsed mt-0" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">
                                                    <div className={taxPosClass}>

                                                        <span className="text-black h6">Taxes ({element.quantity} * {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount)})</span>
                                                    </div>
                                                    <div className='right-0 position-absolute h6 text-black'>
                                                        <span> {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.baseFare.currencyCode
                                                        }).format(element.taxes.amount * element.quantity)}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse"
                                                aria-labelledby="headingfive" data-bs-parent="#accordionFareSummary">

                                                {element.taxes !== undefined && showTax(element)}
                                            </div>
                                            <label className="form-check-label h6 mt-3" for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted">

                                                    <span className='ms-2 fw-bold text-primary'>Fare</span>
                                                    <span className='ms-2 fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: element.totalFare.currencyCode
                                                    }).format((element.totalFare.amount - parseFloat(element.transaction !== undefined && element.transaction.amount !== undefined && element.transaction.amount !== '' ? element.transaction.amount : 0)) * element.quantity)}</span>
                                                </span>
                                            </label>
                                            {MarkupValue !== 0 && MarkupUnit !== '' && props.hideCommision === '5' && getConfigurationByBoolen("ALLOW_COMISSION") && <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted h6">


                                                    <span className='fw-bold ms-2 text-primary'>Commission</span>
                                                    <span className='fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: MarkupUnit
                                                    }).format(MarkupValue)}</span>
                                                </span>
                                            </label>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>)
                setShowDataPaxwise(dataPaxWise);
                if (element.taxes !== undefined) {
                    element.taxes.tax.forEach(tax => {
                        taxes.push(<label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="ms-4 text-14px">{tax.taxDesc}{"(" + tax.taxCode + ")" + " : "}</span>
                                <span className="">{new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: tax.currencyCode
                                }).format(tax.amount)}</span>


                            </span>
                        </label>
                        )
                    })
                }



            }

            else if (element.code === 'INF') {
                let MarkupValue = 0;
                let MarkupUnit = "";
                if (element.commisionInfo !== undefined) {
                    if (element.commisionInfo[0].earnedCommissionAmount !== undefined && element.commisionInfo[0].earnedCommissionAmount !== '' && parseFloat(element.commisionInfo[0].earnedCommissionAmount) !== 0) {
                        MarkupValue = MarkupValue + (parseFloat(element.commisionInfo[0].earnedCommissionAmount) * parseInt(element.quantity));
                        MarkupUnit = element.commisionInfo[0].currency;
                    }
                }

                dataPaxWise.push(
                    <div>
                        <hr className="my-1"></hr>
                        <div className='h6 my-2 text-black fw-bold'> Lap Infant</div>

                        <label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="h6  ms-2 ">Base Fare({element.quantity} * {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount)})</span>
                                <span> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount * element.quantity)}</span>
                            </span>
                        </label>
                        {element.taxes !== undefined && <div className='row'>
                            <div className={hidePlusIcon}>
                                <div >

                                    <div className="accordion_flex_area">

                                        <div className="accordion-item  fare-toggle-btn pb-0">
                                            <h2 className="ms-2 accordion-header" id="headingThree">
                                                <button className="accordion-button text-black collapsed mt-0" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSeven"
                                                    aria-expanded="false" aria-controls="collapseSeven">

                                                    <div className={taxPosClass}>
                                                        <span className="text-black h6">Taxes ({element.quantity} * {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount)})</span>
                                                    </div>
                                                    <div className='right-0 position-absolute h6 text-black'>
                                                        <span> {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount * element.quantity)}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" className="accordion-collapse collapse"
                                                aria-labelledby="headingfive" data-bs-parent="#accordionFareSummary">
                                                {element.taxes !== undefined && showTax(element)}
                                            </div>
                                            <label className="form-check-label h6 mt-3  " for="flexCheckDefaultf1">

                                                <span className="area_flex_one text-muted">

                                                    <span className='ms-2 fw-bold text-primary'>Fare</span>
                                                    <span className='ms-2 fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: element.totalFare.currencyCode
                                                    }).format((element.totalFare.amount - parseFloat(element.transaction !== undefined && element.transaction.amount !== undefined && element.transaction.amount !== '' ? element.transaction.amount : 0)) * element.quantity)}</span>
                                                </span>
                                            </label>
                                            {MarkupValue !== 0 && MarkupUnit !== '' && props.hideCommision === '5' && getConfigurationByBoolen("ALLOW_COMISSION") && <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted h6">


                                                    <span className='fw-bold ms-2 text-primary'>Commission</span>
                                                    <span className='fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: MarkupUnit
                                                    }).format(MarkupValue)}</span>
                                                </span>
                                            </label>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>)
                setShowDataPaxwise(dataPaxWise);
                if (element.taxes !== undefined) {
                    element.taxes.tax.forEach(tax => {
                        taxes.push(<label className="form-check-label h6 mt-3" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="ms-4 text-14px">{tax.taxDesc}{"(" + tax.taxCode + ")" + " : "}</span>
                                <span className="">{new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: tax.currencyCode,
                                }).format(tax.amount)}</span>

                            </span>
                        </label>

                        )
                    })

                }



            }
            else {
                let MarkupValue = 0;
                let MarkupUnit = "";
                if (element.commisionInfo !== undefined) {
                    if (element.commisionInfo[0].earnedCommissionAmount !== undefined && element.commisionInfo[0].earnedCommissionAmount !== '' && parseFloat(element.commisionInfo[0].earnedCommissionAmount) !== 0) {
                        MarkupValue = MarkupValue + (parseFloat(element.commisionInfo[0].earnedCommissionAmount) * parseInt(element.quantity));
                        MarkupUnit = element.commisionInfo[0].currency;
                    }
                }
                dataPaxWise.push(
                    <div>
                        <hr className="my-1"></hr>
                        <div className='h6 my-2 text-black fw-bold'> Seat Infant</div>

                        <label className="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="h6  ms-2 ">Base Fare({element.quantity} * {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount)})</span>
                                <span> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: element.baseFare.currencyCode
                                }).format(element.baseFare.amount * element.quantity)}</span>
                            </span>
                        </label>
                        {element.taxes !== undefined && <div className='row'>
                            <div className={hidePlusIcon}>
                                <div >

                                    <div className="accordion_flex_area">

                                        <div className="accordion-item  fare-toggle-btn pb-0">
                                            <h2 className="ms-2 accordion-header" id="headingThree">
                                                <button className="accordion-button text-black collapsed mt-0" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTen"
                                                    aria-expanded="false" aria-controls="collapseTen">

                                                    <div className={taxPosClass}>
                                                        <span className="text-black h6">Taxes ({element.quantity} * {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount)})</span>
                                                    </div>
                                                    <div className='right-0 position-absolute h6 text-black'>
                                                        <span> {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: element.taxes.currencyCode
                                                        }).format(element.taxes.amount * element.quantity)}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseTen" className="accordion-collapse collapse"
                                                aria-labelledby="headingfive" data-bs-parent="#accordionFareSummary">
                                                {element.taxes !== undefined && showTax(element)}
                                            </div>
                                            <label className="form-check-label h6 mt-3 " for="flexCheckDefaultf1">

                                                <span className="area_flex_one text-muted">

                                                    <span className='ms-2 fw-bold text-primary'>Fare</span>
                                                    <span className='ms-2 fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: element.totalFare.currencyCode
                                                    }).format(element.totalFare.amount - (parseFloat(element.transaction !== undefined && element.transaction.amount !== undefined && element.transaction.amount !== '' ? element.transaction.amount : 0)) * element.quantity)}</span>
                                                </span>
                                            </label>
                                            {MarkupValue !== 0 && MarkupUnit !== '' && props.hideCommision === '5' && getConfigurationByBoolen("ALLOW_COMISSION") && <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted h6">


                                                    <span className='fw-bold ms-2 text-primary'>Commission</span>
                                                    <span className='fw-bold text-primary'> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: MarkupUnit
                                                    }).format(MarkupValue)}</span>
                                                </span>
                                            </label>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>)
                setShowDataPaxwise(dataPaxWise);
                if (element.taxes !== undefined) {
                    element.taxes.tax.forEach(tax => {
                        taxes.push(<label className="form-check-label h6 mt-3" for="flexCheckDefaultf1">
                            <span className="area_flex_one text-muted">
                                <span className="ms-4 text-14px">{tax.taxDesc}{"(" + tax.taxCode + ")" + " : "}</span>
                                <span className="">{new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: tax.currencyCode,
                                }).format(tax.amount)}</span>

                            </span>
                        </label>

                        )
                    })

                }

            }

        });
    });
    useEffect(() => {

        innerFunction();

    }, [])

    let totalServicesAmount = parseFloat(seatValue !== '' ? seatValue : 0) + parseFloat(markupAmount) + parseFloat(addOnBaggageAmount) + parseFloat(addOnMealAmount) + parseFloat(addOnSeatAmount) + parseFloat(airlineTransactionCharges) + parseFloat(addOnSpecialServiceAmount);

    return (<div className='col-md-12'>
        <div className=''>
            <div className=''>
                <div className='col-md-12 accordion' id="accordionFareSummary" >
                    {showDataPaxwise}
                    {
                        totalServicesAmount > 0 &&
                        <hr className="my-2"></hr>
                    }

                    {(seatValue !== '' || markupAmount !== '0' || addOnBaggageAmount !== 0 || addOnMealAmount !== 0 || addOnSeatAmount !== 0 || airlineTransactionCharges !== 0 || addOnSpecialServiceAmount !== 0) && <div><div className='row'>
                        <div className={hidePlusIcon}>
                            <div >
                                <div className="accordion_flex_area">
                                    <div className="accordion-item  fare-toggle-btn pb-0 fare-summry-fs-12px">
                                        <h2 className="ms-2 accordion-header" id="headingThree">
                                            {
                                                totalServicesAmount > 0 &&
                                                <button className="accordion-button text-primary collapsed mt-0" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false"
                                                    aria-controls="#collapseEight">
                                                    <div className={taxPosClass}>
                                                        <span className="fw-bold text-primary h6">Others</span>
                                                    </div>
                                                    <div className='right-0 position-absolute'>
                                                        <span className="fw-bold text-primary h6"> {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode,
                                                        }).format(parseFloat(seatValue !== '' ? seatValue : 0) + parseFloat(markupAmount) + parseFloat(addOnBaggageAmount) + parseFloat(addOnMealAmount) + parseFloat(addOnSeatAmount) + parseFloat(airlineTransactionCharges) + parseFloat(addOnSpecialServiceAmount))}</span>
                                                    </div>
                                                </button>
                                            }
                                        </h2>
                                        <div id="collapseEight" className={showOtherService} aria-labelledby="headingfive"
                                            data-bs-parent="#accordionFareSummary">
                                            {(seatValue !== '') &&
                                                <span className="area_flex_one text-muted">
                                                    <span className="ms-4 h6">Seats</span>
                                                    <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                    }).format(seatValue)} </span>
                                                </span>}

                                            {markupAmount !== 0 && markupAmount !== '0' && getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Service Fee</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(markupAmount)}</span>
                                            </span>}

                                            {airlineTransactionCharges !== 0 && airlineTransactionCharges !== '0' && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Airline Transaction Charges</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(airlineTransactionCharges)}</span>
                                            </span>}

                                            {addOnBaggageAmount !== 0 && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Baggage</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(addOnBaggageAmount)}</span>
                                            </span>}

                                            {addOnMealAmount !== 0 && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Meal</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(addOnMealAmount)}</span>
                                            </span>}

                                            {addOnSeatAmount !== 0 && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Seat</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(addOnSeatAmount)}</span>
                                            </span>}

                                            {addOnSpecialServiceAmount !== 0 && <span className="area_flex_one text-muted">
                                                <span className={`${props.hideIcon === '1' ? "ms-1" : "ms-4"}` + " h6"}>Special Service</span>
                                                <span className="h6 text-black">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                                                }).format(addOnSpecialServiceAmount)}</span>
                                            </span>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <hr className="my-2"></hr>
                    </div>}
                    <div className='row'>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                            <div className='h5 mb-1 fw-bold text-primary'>Total Fare </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className='h5 mb-2 fw-bold text-primary text-end float-lg-end'>

                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.FlightData.airItineraryPricingInfo.totalFare.currencyCode
                            }).format(props.FlightData.airItineraryPricingInfo.totalFare.amount)}

                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>)
}
export default FareSummary