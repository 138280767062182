import { Fragment, useState, useEffect } from "react";
import RatePlanForm from "./Components/RatePlanForm";
import { addRatePlan, getSingleRatePlan, updateRatePlan } from './../../../services/RatePlan-API'
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { checkSecurityDetails } from "../../../utils/RoleUtils";

const UpdateRatePlan = (props) => {
    useDocumentTitle('Rate Plan ')
    const { t } = useTranslation(['translation.RatePlan']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;

    let varHttp;

    if (Id === undefined) {
        varHttp = addRatePlan
    } else {
        varHttp = updateRatePlan
    }
    const { sendRequest: sendRequestRatePlan, data: loadedRatePlanDetails, error } = useHttpGet(getSingleRatePlan);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (!checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Update") && Action === '1')
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_View") && Action === '0')
        history.push('/Unauthorized')

    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    } else if (loadedQuote['errors'].error['code'] === '1026') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateGtCode')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])
    useEffect(() => {
        if (Id !== undefined) {

            sendRequestRatePlan(Id)
        }
    }, [sendRequestRatePlan, Id])
    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }
    let loadedData = [];
    for (let index in loadedRatePlanDetails) {
        if (loadedRatePlanDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedRatePlanDetails[index];
            }
        }
    }
    const addRatePlanHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/SearchRatePlan');
        }
    };

    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    const RatePlanFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <RatePlanForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddRatePlan={addRatePlanHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />


    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {RatePlanFormContent}

    </Fragment>
};
export default UpdateRatePlan;