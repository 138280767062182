import {
    GET_CRYPITC_COMMAND,
    CLOSE_CRYPITC_COMMAND,
    GET_CRYPITC_COMMAND_WITH_CLOSE
}
    from './CONSTANTS'

export async function getCrypticCommand(addData) {
    //  console.log(addData);
    const response = await fetch(GET_CRYPITC_COMMAND(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not get cryptic command');
    }

    return data;

}
export async function getCrypticCommandWithCloseConnection(addData) {
    //  console.log(addData);
    const response = await fetch(GET_CRYPITC_COMMAND_WITH_CLOSE(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not get cryptic command');
    }

    return data;

}
export async function closeCrypticCommand(addData) {
    //  console.log(addData);
    const response = await fetch(CLOSE_CRYPITC_COMMAND(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not close cryptic command');
    }

    return data;

}
