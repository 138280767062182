import {    
    SEND_EMAIL,SEND_EMAIL_Proposal,SEND_HOTEL_EMAIL_Proposal
} from './CONSTANTS'
export async function sendEmail(addData) {   
    const response = await fetch(SEND_EMAIL(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create role.');
    }    
    return data;
}
export async function sendEmailProposal(addData) {   
    const response = await fetch(SEND_EMAIL_Proposal(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not send mail.');
    }    
    return data;
}
export async function sendHotelEmailProposal(addData) {   
    const response = await fetch(SEND_HOTEL_EMAIL_Proposal(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not send mail.');
    }    
    return data;
}

