
import {  useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'

import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'

import useInput from '../../../../components/Common/Input/use-input'
import { useTranslation } from 'react-i18next';
const isNotEmpty = value => value.trim() !== '';
const ContinentForm = (props) => {
    const history = useHistory();
    const { t } = useTranslation(['translation.Continent']);
    const {
        value: enteredName,
        isValid: enterdNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput
    } = useInput(isNotEmpty);

  

    let formIsValid = false;
    if (enterdNameIsValid) {
        formIsValid = true;
    }

    function submitFormHandler(event) {
        event.preventDefault();
   
        // optional: Could validate here
        let ID;
        if (props.loadedContinent === null) {
            ID = '';
        } else {
            ID = props.loadedContinent.id;
        }
        props.onAddContinent({ id: ID, 
            name: enteredName,
        userID:''});
    }
   
    const onResetHandler = (event) => {
        event.preventDefault();
        resetNameInput('');
    }
    function changeLocation(event) {       
        history.push('/NewContinent')
    }
   
    return (
        <Wrapper>
           
            <form 
                className='form'>
                {props.isLoading && (
                    <div className='loading'>
                        <LoadingSpinner />img/banner/flight-banner.jpg
                    </div>
                )}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-2">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <div className="col-md-12 gt-country-details-form">
                                        <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {props.loadedContinent === null? ' New Continent':' Update Continent'}</label>
                                        <div className="form-group col-md-12">
                                            <div className="">

                                                <input maxLength="20" type='text' id='name'
                                                    onChange={nameChangeHandler}
                                                    onBlur={nameBlurHandler}
                                                    className="mb-2 form-control form-control-sm col-lg-12"
                                                    defaultValue={props.loadedContinent !== null ? props.loadedContinent.name : ''} />
                                                {nameInputHasError && <p className="error-text">{t('nameValidation')}</p>}

                                            </div>


                                            <div className='mt-3 actions row'>
                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                    
                                                    <a href="/AllContinent" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                    <div className="float-md-end float-lg-end">
                                                        <a href="/AllContinent" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                        {/* <button onClick={onBackLocation} className='btn  gt-btn_md text-white gt-main-btn ms-1' title='Back to Previous'><span><i className="fa fa-arrow-left"></i></span></button> */}
                                                        <button disabled={!formIsValid} onClick={submitFormHandler} className='btn  gt-btn_md text-white gt-main-btn ms-1'>{props.loadedContinent === null ? 'Add' : 'Update'}</button>
                                                        
                                                        <button onClick={changeLocation} className='btn  gt-btn_md text-white gt-main-btn ms-1'>New</button>
                                                        
                                                        <button onClick={onResetHandler} className='btn  gt-btn_md text-white gt-main-btn ms-1'>Reset</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </form>
        </Wrapper>
    );
};

export default ContinentForm;