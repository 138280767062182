import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import { searchCurrency } from '../../../../services/Currency-API'
import Dropdown from 'react-select';
import useHttp from '../../../../services/use-http'
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const CreditLimitForm = (props) => {
    const { t } = useTranslation(['translation.CreditLimit']);
    const [currencyCode, setCurrencyCode] = useState('');
    const amountInputRef = useRef('');
    const updateAmountInputRef = useRef('');
    const { sendRequest: sendRequestCurrencyList, data: loadedCurrency } = useHttp(searchCurrency);
    const [amountInputHasError, setAmountInputHasError] = useState(false);
    const [amountInputInvalidHasError, setAmountInvalidInputHasError] = useState(false);
    const [updateAmountInputHasError, setUpdateAmountInputHasError] = useState(false);
    const [updateAmountInputInvalidHasError, setUpdateAmountInvalidInputHasError] = useState(false);
    const [currencyInputHasError, setCurrencyInputHasError] = useState(false);
    const [iSDisabled, setiSDisabledDDL] = useState(false);


    useEffect(() => {
        const onSearchCurrencyList = ({
            currCode: "",
            currName: ""
        });

        sendRequestCurrencyList(onSearchCurrencyList);
    }, [sendRequestCurrencyList])

    let currencyOptions = []
    let currencyArrayData = [];
    for (let i in loadedCurrency) {
        if (loadedCurrency['errors'].status === 'FALSE') {
            if (i === 'data') {
                currencyArrayData = loadedCurrency[i];
            }
        }
    }
    for (let index = 0; index < currencyArrayData.length; index++) {
        currencyOptions = [...currencyOptions, { value: currencyArrayData[index].currCode, label: currencyArrayData[index].currName }]
    }

    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        if (props.loadedProfileDetails.length !== 0) {
            amountInputRef.current.value = `${props.loadedProfileDetails.amount !== undefined ? props.loadedProfileDetails.amount : ''}`;

            if (currencyCode === undefined || currencyCode === '') {
                setCurrencyCode(props.loadedProfileDetails.currencyCode);
            }
        }
        if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Credit Limit';
            document.getElementById("amount").readOnly = true;
            document.getElementById("divUpdateAmount").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            setiSDisabledDDL(true);
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Credit Limit';
            document.getElementById("amount").readOnly = true;
            document.querySelector("#currency").readOnly = true;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL(true);
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
        }

        if (props.loadedProfileDetails.length === 0) {
            document.getElementById("btnNew").style.display = "none";
            document.getElementById('pageTitle').innerHTML = ' New Credit Limit';
            sessionStorage.setItem('isEditable', '100');
            document.getElementById("divUpdateAmount").style.display = "none";
        }

    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails.currCode
    ]);



    function onBlurCheck(event) {
        event.preventDefault();
        if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
            if (currencyCode === '') {
                setCurrencyInputHasError(true);
                return;
            } else {
                setCurrencyInputHasError(false);
            }

            if (amountInputRef.current.value.trim() === '') {
                amountInputRef.current.focus();
                setAmountInputHasError(true);
                setAmountInvalidInputHasError(false);
                return;
            }
            else {
                setAmountInputHasError(false);
            }
            if (!amountInputRef.current.value.match('^[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
                setAmountInvalidInputHasError(true);
                setAmountInputHasError(false);
                return;
            }
            else {
                setAmountInvalidInputHasError(false);
            }
        } else {
            if (updateAmountInputRef.current.value.trim() === '') {
                updateAmountInputRef.current.focus();
                setUpdateAmountInputHasError(true);
                setUpdateAmountInvalidInputHasError(false);
                return;
            }
            else {
                setUpdateAmountInputHasError(false);
            }
            if (!updateAmountInputRef.current.value.match('^-?[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
                updateAmountInputRef.current.focus();
                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            }
            else {
                setUpdateAmountInvalidInputHasError(false);
            }
        }
    }
    function submitFormHandler(event) {
        event.preventDefault();

        let ID;
        let isSubtracted = false;
        if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
            ID = 0;
            if (currencyCode === '') {
                setCurrencyInputHasError(true);
                return;
            } else {
                setCurrencyInputHasError(false);
            }

            if (amountInputRef.current.value.trim() === '') {
                amountInputRef.current.focus();
                setAmountInputHasError(true);
                setAmountInvalidInputHasError(false);
                return;
            }
            else {
                setAmountInputHasError(false);
            }
            if (!amountInputRef.current.value.match('^[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
                setAmountInvalidInputHasError(true);
                setAmountInputHasError(false);
                return;
            }
            else {
                setAmountInvalidInputHasError(false);
            }
        } else {
            ID = props.loadedProfileDetails.id;

            if (updateAmountInputRef.current.value.trim() === '') {
                updateAmountInputRef.current.focus();
                setUpdateAmountInputHasError(true);
                setUpdateAmountInvalidInputHasError(false);
                return;
            }
            else {
                setUpdateAmountInputHasError(false);
            }
            if (!updateAmountInputRef.current.value.match('^-?[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
                updateAmountInputRef.current.focus();
                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            }
            else {
                setUpdateAmountInvalidInputHasError(false);
            }
            if (updateAmountInputRef.current.value.trim().includes('-')) {
                isSubtracted = true;
            }
        }

        props.onAddCreditLimit({
            id: ID,
            amount: amountInputRef.current.value,
            transactionAmount: updateAmountInputRef.current.value !== '' ? updateAmountInputRef.current.value : 0,
            currencyCode: currencyCode,
            isSubracted: false,
            service: 'MS',
            currencyName: '',
            isSubracted: isSubtracted,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            iPAddress:localStorage.getItem("IPAddress")
        });
    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdateCreditLimit')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedProfileDetails.length === 0) {
                history.go('/UpdateCreditLimit')
            }
            else {
                history.go(`/UpdateCreditLimit/${props.loadedProfileDetails.id}`)
            }
        }
    }
    const history = useHistory();
    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }
    const currencyChangeHandler = (event) => {
        setCurrencyCode(event.value);
        setCurrencyInputHasError(false);
    };

    return (
        <Wrapper>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>

                                                        <form className='form'>

                                                            <div className='control'>
                                                                <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Currency'>Currency<span className="error-icon"> *</span></label>
                                                                <Dropdown onChange={currencyChangeHandler}
                                                                    id='currency'
                                                                    value={currencyOptions.filter(option => option.value === currencyCode)}
                                                                    options={currencyOptions}
                                                                    textField="currName"
                                                                    dataItemKey="currCode"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input'
                                                                    placeholder="Please Select"
                                                                    isDisabled={iSDisabled} />
                                                                {currencyInputHasError &&
                                                                    <p className="error-text">{t('validationCurrency')}</p>
                                                                }
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='amount'>{t('amount')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength="10" type='text' className="form-control form-control-sm" id='amount' ref={amountInputRef}
                                                                            onInput={changeToUpperCase} onChange={onBlurCheck} />
                                                                        {amountInputHasError &&
                                                                            <p className="error-text">{t('validationAmount')}</p>
                                                                        }
                                                                        {amountInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidAmount')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div id='divUpdateAmount' className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='UpdateAmount'>{t('UpdateAmount')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength="10" type='text' className="form-control form-control-sm" id='UpdateAmount' ref={updateAmountInputRef}
                                                                            onBlur={onBlurCheck} />
                                                                        {updateAmountInputHasError &&
                                                                            <p className="error-text">{t('validationAmount')}</p>
                                                                        }
                                                                        {updateAmountInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidAmount')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/SearchCreditLimit" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href="/SearchCreditLimit" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                        {(checkSecurityDetails("Master", "Credit Limit", "Master_Credit Limit_Insert") || checkSecurityDetails("Master", "Credit Limit", "Master_Credit Limit_Update")) ?
                                                                            <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                                            : <span id="btnNew"></span>}
                                                                        {checkSecurityDetails("Master", "Credit Limit", "Master_Credit Limit_Insert") ?
                                                                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                                {t('new')}
                                                                            </button> : <span id="btnNew"></span>}
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default CreditLimitForm
