import { Fragment, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';

import Card from '../../../components/Common/Card/Card';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import classes from '../Registration.module.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { SYSTEM_PAGE_LOST } from '../../../config/CONSTANTS';
import { Trans } from 'react-i18next';
import useInput from '../../../components/Common/Input/use-input'

const isNotEmpty = value => value.trim() !== '';
const isContactNumber=value=>value.trim().match(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);

const RegistrationForm = (props) => {

    const {
        value: enteredName,
        isValid: enterdNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput
    } = useInput(isNotEmpty);
    const {
        value: enteredUserName,
        isValid: enterdUserNameIsValid,
        hasError: userNameInputHasError,
        valueChangeHandler: userNameChangeHandler,
        inputBlurHandler: userNameBlurHandler,
        reset: resetUserNameInput
    } = useInput(isNotEmpty);
    const {
        value: enteredContactDetails,
        isValid: enterdContactDetailsIsValid,
        hasError: contactDetailsInputHasError,
        valueChangeHandler: contactDetailsChangeHandler,
        inputBlurHandler: contactDetailsBlurHandler,
        reset: resetContactDetailsInput
    } = useInput(isContactNumber);
    const {
        value: enteredPreferredsettlementterms,
        isValid: enterdPreferredsettlementtermsIsValid,
        hasError: preferredsettlementtermsInputHasError,
        valueChangeHandler: preferredsettlementtermsChangeHandler,
        inputBlurHandler: preferredsettlementtermsBlurHandler,
        reset: resetPreferredsettlementtermsInput
    } = useInput(isNotEmpty);
    const {
        value: enteredMaximumnumberofusers,
        isValid: enterdMaximumnumberofusersIsValid,
        hasError: maximumnumberofusersInputHasError,
        valueChangeHandler: maximumnumberofusersChangeHandler,
        inputBlurHandler: maximumnumberofusersBlurHandler,
        reset: resetMaximumnumberofusersInput
    } = useInput(isNotEmpty);


    const [isEntering, setIsEntering] = useState(false);


    const [valueModuleWantedOptions, setValueModuleWantedOptions] = useState();
    const [valueServiceWantedOptions, setValueServiceWantedOptions] = useState();
    const [valueProviderWantedOptions, setValueProviderWantedOptions] = useState();
    const [valueTransactionCurrency, setValueTransactionCurrency] = useState();


    const handleChangeModuleWantedOptions = (e) => {
        setValueModuleWantedOptions(e.value);
    };
    const handleChangeServiceWantedOptions = (e) => {
        setValueServiceWantedOptions(e.value);
    };
    const handleChangeProviderWantedOptions = (e) => {
        setValueProviderWantedOptions(e.value);
    };
    const handleChangeTransactionCurrency = (e) => {
        setValueTransactionCurrency(e.value);
    };


    const iataNumberInputRef = useRef();
    const preferredformsofpaymentInputRef = useRef();
    const transactionCurrencyInputRef = useRef();
    const maximumnumberofsubagentsInputRef = useRef();
    const maximumnumberofcorporatesInputRef = useRef();
    const maximumnumberofbranchesInputRef = useRef();


    let formIsValid = false;
    if (enterdNameIsValid && enterdUserNameIsValid && enterdContactDetailsIsValid && enterdPreferredsettlementtermsIsValid && enterdMaximumnumberofusersIsValid) {
        formIsValid = true;
    }

    function submitFormHandler(event) {
        event.preventDefault();

        if (!enterdNameIsValid && !enterdUserNameIsValid && !enterdContactDetailsIsValid && !enterdPreferredsettlementtermsIsValid && !enterdMaximumnumberofusersIsValid) {
            return;
        }
        const enteredIATANumber = iataNumberInputRef.current.value;
        const enteredPreferredformsofpayment = preferredformsofpaymentInputRef.current.value;     
        const enteredMaximumnumberofsubagents = maximumnumberofsubagentsInputRef.current.value;
        const enteredMaximumnumberofcorporates = maximumnumberofcorporatesInputRef.current.value;
        const enteredMaximumnumberofbranches = maximumnumberofbranchesInputRef.current.value;

        // optional: Could validate here
        var data = {
            Id: '',
            Name: enteredName,
            UserName: enteredUserName,
            ContactDetails: enteredContactDetails,
            IATANumber: enteredIATANumber,
            ModuleWanted: valueModuleWantedOptions,
            ServiceWanted: valueServiceWantedOptions,
            ProviderWanted: valueProviderWantedOptions,           
            PreferredFormsofPayment: enteredPreferredformsofpayment,
            PreferredSettlementTerms: enteredPreferredsettlementterms,
            TransactionCurrency: valueTransactionCurrency,
            MaximumNumberofUsers: enteredMaximumnumberofusers,
            MaximumNumberofSubAgents: enteredMaximumnumberofsubagents,
            MaximumNumberofCorporates: enteredMaximumnumberofcorporates,
            MaximumNumberofBranches: enteredMaximumnumberofbranches
        }

        props.onAddRegistration(data);

        resetNameInput();
        resetUserNameInput();
        resetContactDetailsInput();
        resetPreferredsettlementtermsInput();
        resetMaximumnumberofusersInput();
    }

    const finishEnteringHandler = () => {
        setIsEntering(false);
    };

    const formFocusedHandler = () => {
        setIsEntering(true);
    };
    const moduleWantedOptions = [
        'B2C', 'B2B', 'Corporate'
    ]; 
    const serviceWantedOptions = [
        'Flights', 'Hotels'
    ];
    const providerWantedOptions = [
        'Sabre', 'Amadeus'
    ];
    const currencyOptions = [
        'USD', 'ZAR', 'INR'
    ];    

    return (
        <Fragment>
            <Prompt
                when={isEntering}
                message={(location) => `${SYSTEM_PAGE_LOST}`
                }
            />
          
            <Card>
                <form
                    onFocus={formFocusedHandler}
                    className={classes.form}
                    onSubmit={submitFormHandler}
                >
                    {props.isLoading && (
                        <div className={classes.loading}>
                            <LoadingSpinner />
                        </div>
                    )}

                    <div className={classes.control}>
                        <label htmlFor='name'><Trans i18nKey="name">Name</Trans></label>
                        <input maxLength="20" type='text' id='name'
                            onChange={nameChangeHandler}
                            onBlur={nameBlurHandler}
                            value={enteredName} />
                        {nameInputHasError && <p className="error-text">Name must be not empty.</p>}
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='username'>User Name</label>
                        <input maxLength="20" type='text' id='name'
                            onChange={userNameChangeHandler}
                            onBlur={userNameBlurHandler}
                            value={enteredUserName} />
                        {userNameInputHasError && <p className="error-text">User Name must be not empty.</p>}

                    </div>
                    <div className={classes.control}>
                        <label htmlFor='contactdetails'>Contact Details</label>
                        <input maxLength="14" type='text' id='name'
                            onChange={contactDetailsChangeHandler}
                            onBlur={contactDetailsBlurHandler}
                            value={enteredContactDetails} />
                        {contactDetailsInputHasError && <p className="error-text">Contact Details must be not empty and valid format.</p>}

                    </div>
                    <div className={classes.control}>
                        <label htmlFor='iatanumber'>IATA Number <span className="optional-text">#</span></label>
                        <input type='text' id='iatanumber' ref={iataNumberInputRef} />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='modulewanted'>Module wanted</label>
                        <Dropdown onChange={handleChangeModuleWantedOptions} value={valueModuleWantedOptions} options={moduleWantedOptions} arrowClosed={<span className="arrow-closed" />} arrowOpen={<span className="arrow-open" />} controlClassName={classes.control} placeholder="Select an option" />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='servicewanted'>Service wanted</label>
                        <Dropdown onChange={handleChangeServiceWantedOptions} value={valueServiceWantedOptions}  options={serviceWantedOptions} arrowClosed={<span className="arrow-closed" />} arrowOpen={<span className="arrow-open" />}  placeholder="Select an option" />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='providerwanted'>Provider wanted</label>
                        <Dropdown onChange={handleChangeProviderWantedOptions} value={valueProviderWantedOptions}  options={providerWantedOptions} arrowClosed={<span className="arrow-closed" />} arrowOpen={<span className="arrow-open" />}  placeholder="Select an option" />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='preferredformsofpayment'>Preferred forms of payment <span className="optional-text">#</span></label>
                        <input type='text' id='preferredformsofpayment' ref={preferredformsofpaymentInputRef} />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='preferredsettlementterms'>Preferred settlement terms</label>
                        <input type='text' id='name'
                            onChange={preferredsettlementtermsChangeHandler}
                            onBlur={preferredsettlementtermsBlurHandler}
                            value={enteredPreferredsettlementterms} />
                        {preferredsettlementtermsInputHasError && <p className="error-text">Preferred settlement terms must be not empty.</p>}

                    </div>
                    <div className={classes.control}>
                        <label htmlFor='transactionCurrency'>Transaction Currency</label>
                        <Dropdown onChange={handleChangeTransactionCurrency} value={valueTransactionCurrency}  options={currencyOptions} id='transactionCurrency' ref={transactionCurrencyInputRef} arrowClosed={<span className="arrow-closed" />} arrowOpen={<span className="arrow-open" />}  placeholder="Select an option" />

                    </div>
                    <div className={classes.control}>
                        <label htmlFor='maximumnumberofusers'>Maximum number of users</label>
                        <input type='text' id='name'
                            onChange={maximumnumberofusersChangeHandler}
                            onBlur={maximumnumberofusersBlurHandler}
                            value={enteredMaximumnumberofusers} />
                        {maximumnumberofusersInputHasError && <p className="error-text">Maximum number of users must be not empty.</p>}
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='maximumnumberofsubagents'>Maximum number of sub-agents <span className="optional-text">#</span></label>
                        <input type='text' id='maximumnumberofsubagents' ref={maximumnumberofsubagentsInputRef} />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='maximumnumberofcorporates'>Maximum number of corporates <span className="optional-text">#</span></label>
                        <input type='text' id='maximumnumberofcorporates' ref={maximumnumberofcorporatesInputRef} />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='maximumnumberofbranches'>Maximum number of branches <span className="optional-text">#</span></label>
                        <input type='text' id='maximumnumberofbranches' ref={maximumnumberofbranchesInputRef} />
                    </div>
                    <div className={classes.control}>
                    (Fields marked <span className="optional-text">#</span> are optional)
                        
                    </div>
                    <div className={classes.actions}>
                        <button disabled={!formIsValid} onClick={finishEnteringHandler} className='btn'>Submit</button>
                    </div>
                </form>
            </Card>
        </Fragment>
    );
};

export default RegistrationForm;