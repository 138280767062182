import { Fragment, useCallback, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { NEWUSER } from "../../../navigation/CONSTANTS";
import Dropdown from "react-select";
import { useTranslation } from "react-i18next";
import useHttp from "../../../services/use-http";
import { getAllUser, searchUser, RoleOfficeWiseList } from "../../../services/User-API";
import TravelArrangerList from './Components/TravelArrangerList';
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { checkSecurityDetails } from '../../../utils/RoleUtils';

const statusOption = [
    { value: true, label: "Active" },
    { value: undefined, label: "Both" },
    { value: false, label: "Inactive" },
];

const AllTravelArranger = () => {
    useDocumentTitle('Travel Arranger');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState();
    const [UserStatus, setUserStatus] = useState(statusOption[0].value);
    const { t } = useTranslation(['translation.User']);
    const [isFirstTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);
    const { sendRequest: sendRequestRoleList, data: loadedRole, error: roleError } = useHttp(RoleOfficeWiseList);
    const history = useHistory();

    let varHttp;
    if (firstName.trim().length === 0 && lastName.trim().length === 0 && role === '') {
        varHttp = getAllUser;
    }
    else {
        varHttp = searchUser;
    }

    const firstNamechangehandler = (event) => {
        setFirstName(event.target.value);

    }
    const lastNamechangehandler = (event) => {
        setLastName(event.target.value)
    }

    const statusChangeHandler = (event) => {
        setUserStatus(event.value);
    }
    const roleChangeHandler = (event) => {
        setRole(event.value);

    }



    // const history=useHistory();
    const { sendRequest, status, data: loadedUser, error } = useHttp(varHttp);

    function onResetHandler(event) {
        history.go('/alltravelarranger');
    }

    useEffect(() => {
        sessionStorage.setItem('isEditable', '');
        if (status === 'completed') {
            localStorage.removeItem("selectedTravelArrangers");
            if (loadedUser['errors'].error['code'] === '1008' || loadedUser['errors'].error['code'] === '9001') {
                setError1(true);
                return;
            }

        }
    }, [status, loadedUser])
    useEffect(() => {
        const onRoleList = ({
            officeID: JSON.parse(localStorage.getItem('officeID')),
            roleName: "",
        });
        sendRequestRoleList(onRoleList);
    }, [sendRequestRoleList])

    let roleOptions = []
    let roleArrayData = [];
    for (let i in loadedRole) {
        if (loadedRole['errors'].status === 'FALSE') {
            if (i === 'data') {
                roleArrayData = loadedRole[i];
            }
        }
    }

    for (let index = 0; index < roleArrayData.length; index++) {
        roleOptions = [...roleOptions, { value: roleArrayData[index].id, label: roleArrayData[index].roleName }]
    }

    useEffect(() => {
        setFirstTime(true);
        const onSearchUser = (
            {
                firstName: firstName,
                lastName: lastName,
                roleID: `${role === undefined ? "" : role}`,
                status: `${UserStatus === undefined ? true : ""}`,
                isActive: UserStatus,
                officeID: JSON.parse(localStorage.getItem('officeID')),
            });

        sendRequest(onSearchUser);
    }, [])

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);
        const onSearchUser = (
            {
                firstName: firstName,
                lastName: lastName,
                roleID: `${role === undefined ? "" : role}`,
                status: `${UserStatus === undefined ? true : ""}`,
                isActive: UserStatus,
                officeID: JSON.parse(localStorage.getItem('officeID')),
            });

        sendRequest(onSearchUser);

    }, [sendRequest, firstName, lastName, UserStatus, role])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }


    if (error || roleError) {
        history.push('/Error')
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }

    let loadedData = [];
    for (let i in loadedUser) {
        if (loadedUser['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedUser[i];
            }
        }
    }
    function okayHandler() {
        setError1(false);
        history.go('/alltravelarranger');

    }


    const travelArrangerList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <TravelArrangerList user={loadedData} /> : ""

    return (

        <Fragment>
            {error1 && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {status === 'pending' &&
                                                <div className=''>
                                                    {/* <section id='common_banner'><div className='container'></div></section> */}
                                                    <div className=''>
                                                        <LoadingSpinner></LoadingSpinner>
                                                    </div>
                                                </div>
                                            }
                                            <div className="card-body">
                                                <div className="col-md-12 gt-country-details-form">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('Employee Details')}</label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12"> {t('firstName')}</label>

                                                                <input type='text' id='firstname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" value={firstName} onChange={firstNamechangehandler} placeholder='First Name' autoFocus />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> {t('lastName')}</label>

                                                                <input type='text' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" value={lastName} onChange={lastNamechangehandler} placeholder='Last Name' />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="role" className="mb-2 h6 text-primary col-lg-12">{t('role')}</label>
                                                                <Dropdown onChange={roleChangeHandler}
                                                                    value={roleOptions.filter(option => option.value === role)}
                                                                    options={roleOptions}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="status" className="mb-2 h6 text-primary col-lg-12">{t('status')}</label>
                                                                <Dropdown
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                    options={statusOption}

                                                                    onChange={statusChangeHandler}
                                                                    value={statusOption.filter(option => option.value === UserStatus)} />
                                                            </div>
                                                        </div>

                                                        <div className="mt-3 col-md-12">
                                                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                                {t('reset')}
                                                            </button>
                                                            {checkSecurityDetails("User Management", "User", "User Management_User_Insert") &&
                                                                <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={NEWUSER} >
                                                                    {t('new')}
                                                                </NavLink>}
                                                            {checkSecurityDetails("User Management", "User", "User Management_User_Search") &&
                                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                                    {t('search')}
                                                                </button>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid my-3">
            </div>
            {travelArrangerList}
        </Fragment>)

};

export default AllTravelArranger;