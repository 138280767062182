import { Fragment, useEffect } from "react";

const BaggageAllowedCheckin = (props) => {

    useEffect(() => {
        if (props.adultBaggageCheckin !== '') {
            document.querySelector('#adultBaggageCheckin').value = props.adultBaggageCheckin;
        }
        else {
            if (props.inputData.airItinerary.airItinerary !== undefined) {
                if (props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                    let originDestinationOption = props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === props.inputRph)[0];
                    if (originDestinationOption !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined) {
                        document.querySelector('#adultBaggageCheckin').value = originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight + " kg" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces + " piece";
                    }
                    else {
                        document.querySelector('#adultBaggageCheckin').value = '';
                    }
                }
            }
        }

        if (props.childBaggageCheckin !== '') {
            document.querySelector('#childBaggageCheckin').value = props.childBaggageCheckin;
        }
        else {
            if (props.inputData.airItinerary.airItinerary !== undefined) {
                if (props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                    let originDestinationOption = props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === props.inputRph)[0];
                    if (originDestinationOption !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined) {
                        document.querySelector('#childBaggageCheckin').value = originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight + " kg" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'CNN')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces + " piece";
                    }
                    else {
                        document.querySelector('#childBaggageCheckin').value = '';
                    }
                }
            }
        }

        if (props.lapInfantBaggageCheckin !== '') {
            document.querySelector('#lapInfantBaggageCheckin').value = props.lapInfantBaggageCheckin;
        }
        else {
            if (props.inputData.airItinerary.airItinerary !== undefined) {
                if (props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                    let originDestinationOption = props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === props.inputRph)[0];
                    if (originDestinationOption !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined) {
                        document.querySelector('#lapInfantBaggageCheckin').value = originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight + " kg" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INF')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces + " piece";
                    }
                    else {
                        document.querySelector('#lapInfantBaggageCheckin').value = '';
                    }
                }
            }
        }

        if (props.seatInfantBaggageCheckin !== '') {
            document.querySelector('#seatInfantBaggageCheckin').value = props.seatInfantBaggageCheckin;
        }
        else {
            if (props.inputData.airItinerary.airItinerary !== undefined) {
                if (props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined) {
                    let originDestinationOption = props.inputData.airItinerary.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === props.inputRph)[0];
                    if (originDestinationOption !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0] !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance !== undefined && originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined) {
                        document.querySelector('#seatInfantBaggageCheckin').value = originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight + " kg" : originDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'INS')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces + " piece";
                    }
                    else {
                        document.querySelector('#seatInfantBaggageCheckin').value = '';
                    }
                }
            }
        }

    }, [])

    return <Fragment>
        <div className='gt-h-400px'>
            <div className='row'>
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'ADT').length === 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Adult</label>
                        <input type="text" id='adultBaggageCheckin' className="form-control form-control-sm" disabled />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'ADT').length > 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Adult</label>
                        <input type="text" id='adultBaggageCheckin' className="form-control form-control-sm" />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'CNN').length === 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Child</label>
                        <input type="text" id='childBaggageCheckin' className="form-control form-control-sm" disabled />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'CNN').length > 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Child</label>
                        <input type="text" id='childBaggageCheckin' className="form-control form-control-sm" />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'INF').length === 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Lap Infant</label>
                        <input type="text" id='lapInfantBaggageCheckin' className="form-control form-control-sm" disabled />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'INF').length > 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Lap Infant</label>
                        <input type="text" id='lapInfantBaggageCheckin' className="form-control form-control-sm" />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'INS').length === 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Seat Infant</label>
                        <input type="text" id='seatInfantBaggageCheckin' className="form-control form-control-sm" disabled />
                    </div>
                }
                {
                    props.inputData.airItinerary.airItinerary !== undefined && props.inputData.airItinerary.travelerInfo !== undefined && props.inputData.airItinerary.travelerInfo.traveler !== undefined && props.inputData.airItinerary.travelerInfo.traveler.filter(a => a.passengerTypeCode === 'INS').length > 0 &&
                    <div className="control col-md-6">
                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Seat Infant</label>
                        <input type="text" id='seatInfantBaggageCheckin' className="form-control form-control-sm" />
                    </div>
                }
            </div>
        </div>
    </Fragment>

};
export default BaggageAllowedCheckin;