import {
    GET_ALL_COUNTRY,
    GET_COUNTRY,
    ADD_COUNTRY,
    UPDATE_COUNTRY,
    DELETE_COUNTRY,
    GET_COUNTRY_BY_NAME,
    SEARCH_COUNTRY
} from './CONSTANTS'

export async function getAllCountry() {

    const response = await fetch(GET_ALL_COUNTRY(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }
    return data;
}
export async function getCountryByName(name) {

    const response = await fetch(GET_COUNTRY_BY_NAME(name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }

    return data;
}
export async function getSingleCountry(ID) {
    const response = await fetch(GET_COUNTRY(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }

    return data;
}


export async function deleteSingleCountry(deleteData) {
    const response = await fetch(DELETE_COUNTRY(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete country.');
    }
    return data;
}
export async function addCountry(addData) {
    const response = await fetch(ADD_COUNTRY(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create country.');
    }

    return data;
}
export async function updateCountry(updateData) {
    const response = await fetch(UPDATE_COUNTRY(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update country.');
    }
    return data;
}
export async function SearchCountry(searchData) {
    const response = await fetch(SEARCH_COUNTRY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }
    
    return data;
}
