import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { encrypted } from '../../../../utils';

const ProfileDetailsItem = (props) => {
  let history = useHistory();

  function deleteFormHandler(id) {
    // optional: Could validate here
    props.onDelete({
      id: id,
      traceID: '',
      continetID:'',
      countryCode: '',
      countryName: '',
      currencyCode: "",    
      iSOCountryCode: "",
      userID: ""
  });
  }
  async function EditCountryItem(id) {
    const queryParams = await encrypted(id);
    history.push({
      pathname: '/newcountry',
      search: `?Id=${queryParams}`,
      state: { detail: queryParams },
    }
    );
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark card-bg-blue mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    {props.countryName} ({props.countryCode})
                  </h6>
                  <hr className="my-1" />
                </div>
                <div className="col-md-8">
                  <p className="card-text mb-0"><strong>ISO Code: </strong>{props.isoCountryCode}</p>
                  <p className="card-text">
                  <strong>Currency Code: </strong>{props.currencyCode}
                  </p>
                </div>
                <div className="col-md-4 my-auto">

                  <button onClick={() => deleteFormHandler(props.id)} className="btn btn-sm btn-danger float-sm-start float-lg-end ms-1 gt-border-radius-50 gt-red-btn"><i className="fas fa-trash-alt"></i></button>

                  <button className="btn btn-sm float-sm-start text-white float-lg-end ms-1 gt-border-radius-50 gt-green-btn" onClick={() => EditCountryItem(props.id)}  >
                    <i className="fa-solid fa-pen"></i>
                  </button>

                  <button className="btn btn-sm float-sm-start btn-warning text-white float-lg-end ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show"><i className="fa-sharp fa-solid fa-tv"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileDetailsItem;