import { Fragment, useState } from "react";
import Dropdown from 'react-select';
import { Tooltip as ReactTooltip } from "react-tooltip";
import PopupModal from "../../../components/Common/Flight/PrintPopupModal";
import ApproverDetails from "./ApproverDetails";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";

const serviceOptions = [
    { value: 1, label: "Cancellation" },
    { value: 2, label: "Rebooking" },
    { value: 3, label: "Visa Assistance" },
    { value: 4, label: "Transfer" },
    { value: 5, label: "MICE Package" },
    { value: 6, label: "Other" },


];
const purposeOptions = [
    { value: 1, label: "Meeting with Client (1)" },
    { value: 2, label: "Demo (2)" },
];


const RetrieveTravelRequest = () => {
    useDocumentTitle("View Travel Request")
    const [errorMail, setErrorMail] = useState();
    const [service, setService] = useState(1);
    const [purposeOfTravel, setpurposeOfTravel] = useState(1);
    const onSendApproverDetails = () => {
        setErrorMail({
            title: "Status Update",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <ApproverDetails errorHandler={errorHandler}></ApproverDetails>
                </div></div>,
        })

    }
    const errorHandler = () => {
        setErrorMail(null);
    };
    const serviceChangeHandler = (event) => {
        setService(event.value);
    };
    const purposeChangeHandler = (event) => {
        setpurposeOfTravel(event.value)
    };
    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;

    return (<Fragment>
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        <div >

            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="theme_search_form_area mb-3">
                                {JSON.parse(localStorage.getItem('displayName')) === "Rahul Srinivasan" && <div className='col-md-12'>
                                    <div className='float-lg-end'>
                                        <span id="sendMail1" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end hidereportmenu'><i className="fas fa-envelope"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"sendMail1"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Email"}
                                        />
                                    </div>
                                    <div className='float-lg-end'>
                                        <span id="print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"print"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Print"}
                                        />
                                    </div>
                                    <div className='float-lg-end'>
                                        <span id="approverDetails" className='btn btn-sm text-white mt-1 ms-1 gt-main-btn  float-end' onClick={() => onSendApproverDetails()}>
                                            <i class="fas fa-user-plus"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"approverDetails"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Approve | Reject"}
                                        />
                                    </div>

                                    <div className='float-lg-end'>
                                        <span id="history" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end hidemenu hidereportmenu' ><i className="fas fa-history"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"history"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Approval History"}
                                        />
                                    </div>

                                </div>}
                                <div className="col-lg-12 ">
                                    <div className=''>
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Travel Request View</label>
                                        </div>
                                    </div>
                                    <p className="h5 mb-3 mt-2 text-black fw-bold">Meeting Details</p>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Meeting Country</label>
                                            <Dropdown
                                                textField="listValue"
                                                dataItemKey="listID"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="United States Of America"
                                                isDisabled={true}

                                            />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Meeting City</label>
                                            <Dropdown
                                                textField="listValue"
                                                dataItemKey="listID"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="New York City"
                                                isDisabled={true}

                                            />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Meeting Start Date</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={"1 March 2024"}
                                            />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Meeting End Date</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={"4 March 2024"}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Meeting Start Time </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={"10:30 PM"} />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Meeting End Time</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={"5:00 PM"}
                                            />

                                        </div>

                                    </div>
                                    <div className="row">

                                        <div className='control col-lg-12'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Purpose of Travel</label>
                                            <Dropdown
                                                onChange={purposeChangeHandler}
                                                value={purposeOptions.filter(option => option.value === purposeOfTravel)}
                                                options={purposeOptions}
                                                textField="listValue"
                                                dataItemKey="listID"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                isDisabled={true}

                                            />

                                        </div>

                                    </div>
                                    <hr></hr>
                                    <p className="h5 mb-3 text-black fw-bold">Approver and Traveler details </p>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Employee Name </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={"Akhilesh Pathak"} />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Employee Code</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={"2302"}
                                            />

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">First Approver Employee Name  </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={"Rahul Srinivasan "}
                                            />

                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">First Approver Employee Code </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={"2301 "} />
                                        </div>


                                    </div>
                                    <div>
                                        <div class="col-md-12">
                                            <div class="flight_search_item_wrappper">
                                                <div data="collapseExampleCommon" class="flight_policy_refund collapse show" id="collapseExampleundefined">
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="departure-text">
                                                                    <span>
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </span> Departure
                                                                </div>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <div class="pt-10 float-md-end float-lg-end"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-lg-flex flight_show_down_wrapper w-100">
                                                                <div class="flight-shoe_dow_item col-md-9 ccc">
                                                                    <div class="airline-details ">
                                                                        <div class="img">
                                                                            <img src={airlineLogoLink + "EK.gif"} alt="img" class="details-flight-img"></img>
                                                                        </div>
                                                                        <span class="airlineName fw-500">Emirates &nbsp;EK 201</span>
                                                                        <span class=" airlineName fw-500 text-white badge rounded-pill class-purple-bg"> Business</span>
                                                                        <span class="flightNumber">Airbus A380-800 </span>
                                                                    </div>
                                                                    <div class="flight_inner_show_component">
                                                                        <div class="flight_det_wrapper">
                                                                            <div class="flight_det">
                                                                                <div class="code_time">
                                                                                    <span class="code">Dubai</span>
                                                                                </div>
                                                                                <div class="code_time">
                                                                                    <span class="time">8:30 AM</span>
                                                                                </div>
                                                                                <p class="date">5 Aug 2024</p>
                                                                                <p class="airport">Dubai(DXB), United Arab Emirates(AE)</p>
                                                                                <p class="airport mb-2">Terminal 3</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="flight_duration">
                                                                            <div class="arrow_right"></div>
                                                                            <span>13h 55m</span>
                                                                            <p> 0 stops</p>
                                                                        </div>
                                                                        <div class="flight_det_wrapper">
                                                                            <div class="flight_det">
                                                                                <div class="code_time">
                                                                                    <span class="code">New York City</span>
                                                                                </div>
                                                                                <div class="code_time">
                                                                                    <span class="time">2:25 PM</span>
                                                                                </div>
                                                                                <p class="date">5 Aug 2024</p>
                                                                                <p class="airport">John F. Kennedy(JFK), United States of America(US)</p>
                                                                                <p class="airport mb-2">Terminal 4</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <div class="col-md-6">
                                                                            <div class="text-primary text-14px fw-bold">
                                                                                <i class="fas fa-tachometer-alt"></i> 6849 Air miles
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="flight_refund_policy col-md-3">
                                                                    <div class="TabPanelInner col-sm-12">
                                                                        <h4 class="col-md-12">
                                                                            <span class="gt-border-bottom">Check-in Baggage </span>
                                                                        </h4>
                                                                        <div class="flight_info_taable">
                                                                            <p>Per Adult &nbsp; &nbsp; <span>2 pieces &nbsp; </span>
                                                                            </p>
                                                                        </div>
                                                                        <h4 class="col-md-12">
                                                                            <span class="gt-border-bottom"> Cabin Baggage</span>
                                                                        </h4>
                                                                        <div class="flight_info_taable">
                                                                            <p>Per Adult &nbsp; &nbsp; <span>2 pieces &nbsp; </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <hr class="my-1"></hr>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="departure-text">
                                                                    <span>
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </span> Return
                                                                </div>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <div class="pt-10 float-md-end float-lg-end"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-lg-flex flight_show_down_wrapper w-100">
                                                                <div class="flight-shoe_dow_item col-md-9 aaa">
                                                                    <div class="airline-details">
                                                                        <div class="img">
                                                                            <img src={airlineLogoLink + "EK.gif"} alt="img" class="details-flight-img"></img>
                                                                        </div>
                                                                        <span class="airlineName fw-500">Emirates &nbsp;EK 204</span>
                                                                        <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> Business</span>
                                                                        <span class="flightNumber">Airbus A380-800</span>
                                                                    </div>
                                                                    <div class="flight_inner_show_component">
                                                                        <div class="flight_det_wrapper">
                                                                            <div class="flight_det">
                                                                                <div class="code_time">
                                                                                    <span class="code">New York City</span>
                                                                                </div>
                                                                                <div class="code_time">
                                                                                    <span class="time">11:20 AM</span>
                                                                                </div>
                                                                                <p class="date">10 Aug 2024</p>
                                                                                <p class="airport">John F. Kennedy(JFK), United States of America(US)</p>
                                                                                <p class="airport mb-2">Terminal 4</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="flight_duration">
                                                                            <div class="arrow_right"></div>
                                                                            <span>12h 30m</span>
                                                                            <p> 0 stops</p>
                                                                        </div>
                                                                        <div class="flight_det_wrapper">
                                                                            <div class="flight_det">
                                                                                <div class="code_time">
                                                                                    <span class="code">Dubai</span>
                                                                                </div>
                                                                                <div class="code_time">
                                                                                    <span class="time">7:50 AM</span>
                                                                                </div>
                                                                                <p class="date">11 Aug 2024</p>
                                                                                <p class="airport">Dubai(DXB), United Arab Emirates(AE)</p>
                                                                                <p class="airport mb-2">Terminal 3</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <div class="col-md-6">
                                                                            <div class="text-primary fw-bold text-14px">
                                                                                <i class="fas fa-info-circle"></i> This flight arrives the next day.
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="text-primary text-14px fw-bold">
                                                                                <i class="fas fa-tachometer-alt"></i> 6849 Air miles
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tour_details_boxed_inner">
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion_flex_area">
                                                        <div class="accordion-item">
                                                            <h5 class="accordion-header text-sky-blue fw-bold" id="headingOne">Price</h5>
                                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div class="row my-2">
                                                                    <div class="col-md-12">
                                                                        <div class="">
                                                                            <div class="">
                                                                                <div class="col-md-12 accordion" id="accordionFareSummary">
                                                                                    <div class="print-mt-15">
                                                                                        <div class="h6 text-black fw-bold">Adult</div>
                                                                                        <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                            <span class="area_flex_one text-muted">
                                                                                                <span class="h6 ms-2">Base Fare (1 * $4,841.00)</span>
                                                                                                <span class="h6"> $4,841.00</span>
                                                                                            </span>
                                                                                        </label>
                                                                                        <div class="row">
                                                                                            <div class="tour_details_boxed_inner fare-summary-pop">
                                                                                                <div>
                                                                                                    <div class="accordion_flex_area">
                                                                                                        <div class="accordion-item  fare-toggle-btn pb-0 fare-summry-fs-12px">
                                                                                                            <h2 class="ms-2 accordion-header" id="headingThree">
                                                                                                                <button class="accordion-button text-black collapsed mt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                                                                    <div class="ms-4 col-md-7">
                                                                                                                        <span class="h6 text-black">Taxes (1 * $1,726.40)</span>
                                                                                                                    </div>
                                                                                                                    <div class="right-0 position-absolute h6 text-black">
                                                                                                                        <span> $1,726.40</span>
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                            </h2>
                                                                                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionFareSummary">
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">PASSENGER SERVICE CHARGE INTERNATIONAL(AE4)</span>
                                                                                                                        <span class="">$20.40</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">PASSENGER FACILITIES CHARGE(F62)</span>
                                                                                                                        <span class="">$9.50</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">PASSENGER SECURITY AND SAFETY FEE(TP)</span>
                                                                                                                        <span class="">$1.40</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">INTERNATIONAL ADVANCED PASSENGER INFORMATION FEE DEPARTURES(ZR2)</span>
                                                                                                                        <span class="">$1.40</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">PASSENGER CIVIL AVIATION SECURITY SERVICE FEE(AY)</span>
                                                                                                                        <span class="">$5.60</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">TRANSPORTATION TAX INTERNATIONAL DEPARTURE INCLUDING AK AND HI(US2)</span>
                                                                                                                        <span class="">$22.20</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">TRANSPORTATION TAX INTERNATIONAL ARRIVAL(US2)</span>
                                                                                                                        <span class="">$22.20</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">APHIS PASSENGER FEE PASSENGERS(XA)</span>
                                                                                                                        <span class="">$3.83</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">IMMIGRATION USER FEE(XY2)</span>
                                                                                                                        <span class="">$7.00</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">CUSTOMS USER FEE(YC)</span>
                                                                                                                        <span class="">$6.97</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">PASSENGER FACILITY CHARGE(XF)</span>
                                                                                                                        <span class="">$4.50</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">INTERNATIONAL ADVANCED PASSENGER INFORMATION FEE ARRIVALS(ZR)</span>
                                                                                                                        <span class="">$1.40</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">SERVICE FEE - CARRIER-IMPOSED FUEL(YQF)</span>
                                                                                                                        <span class="">$805.00</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">SERVICE FEE - CARRIER-IMPOSED FUEL(YQF)</span>
                                                                                                                        <span class="">$805.00</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                                <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                                                                                    <span class="area_flex_one text-muted">
                                                                                                                        <span class="ms-4 text-14px">SERVICE FEE - CARRIER-IMPOSED MISC(YRI)</span>
                                                                                                                        <span class="">$10.00</span>
                                                                                                                    </span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <label class="form-check-label h6 mt-3" for="flexCheckDefaultf1">
                                                                                                                <span class="area_flex_one text-muted h6">
                                                                                                                    <span class="fw-bold ms-2 text-primary">Fare</span>
                                                                                                                    <span class="fw-bold text-primary"> $6,567.40</span>
                                                                                                                </span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr className="my-2"></hr>
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                                                            <div class="h5 mb-1 fw-bold text-primary">Total Fare </div>
                                                                                        </div>
                                                                                        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                                                            <div class="h5 mb-2 fw-bold text-primary text-end float-lg-end">$6,567.40</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="hotel-list row">
                                                <div class="sort-view clearfix"></div>
                                                <div class="hotel-list-cn clearfix shadow-none">
                                                    <div class="hotel-list-item position-relative">
                                                        <div class="hotel-text m-0 pt-3">
                                                            <div class="mb-2"><a title="" class="text-primary fw-bold h3">Hampton Inn Manhattan-chelsea</a>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="text-16px my-3">108 West 24th St , New York, United States of America</div>
                                                                    </div>
                                                                    <div class="col-md-12 text-16px display-line">
                                                                        <ul>
                                                                            <li>The Hampton Inn Manhattan-Chelsea, NY hotel is a new
                                                                                modern 20 story high-rise hotel located in the trendy up and coming district of
                                                                                Chelsea, New York, bordering the West Village and Midtown Manhattan.<br></br><br></br></li>
                                                                            <li> This New York Hampton Inn hotel is equipped with all
                                                                                the latest conveniences to make you feel right at home in the Big Apple.<br></br><br></br>
                                                                            </li>
                                                                            <li> The Hampton Inn hotel at Chelsea is located on the
                                                                                south side of 24th Street near the southwest corner of 6th Avenue, between 6th and
                                                                                7th Avenues.<br></br><br></br></li>
                                                                            <li> The Hampton Inn Manhattan-Chelsea hotel in New York
                                                                                features some of the latest state-of-the-art offerings including complimentary
                                                                                high-speed internet access.<br></br><br></br></li>
                                                                            <li> Guests can relax in our lobby area in comfort and
                                                                                style overlooking the garden terrace.<br></br><br></br></li>
                                                                            <li> This New York Hampton Inn hotel offers the luxury of
                                                                                being located in the heart of Manhattan and the convenience of all major
                                                                                transportation hubs right near our doorstep.<br></br><br></br></li>
                                                                            <li> Transportation venues nearby include the NJ Path
                                                                                train station, Subway lines F and V, 1 and 9, Metro North to Grand Central and the
                                                                                Long Island Rail Road to Penn Station.<br></br><br></br></li>
                                                                            <li> Parking is available adjacent to the Hampton Inn
                                                                                Manhattan-Chelsea hotel and rates vary by times of entry and length of
                                                                                parking.<br></br><br></br></li>
                                                                            <li> As a member of Hilton Hotels, the Hampton Inn
                                                                                Manhattan-Chelsea hotel in New York proudly offers the HHonors guest rewards program
                                                                                and our 100% satisfaction guaranteed program.<br></br><br></br></li>
                                                                            <li> We love having you here at the Hampton Inn
                                                                                Manhattan-Chelsea hotel in New York, New York.<br></br><br></br></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr class="my-1"></hr>
                                                        <div class="row">
                                                            <div class="col-md-12 my-auto">
                                                                <div class="mb-2">
                                                                    <div class="ms-2 text-14px text-sky-blue mt-2"><i class="fas fa-money-check-alt"></i> Refundable
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion_flex_area row mt-2">
                                        <div class=" pb-0">
                                            <h5 class="accordion-header text-sky-blue fw-bold mb-2" id="headingOne">Price</h5>
                                            <div class="mt-0"><label class="form-check-label text-14px " for="flexCheckDefaultf1"><span
                                                class="area_flex_one text-muted"><span class="fw-bold h6">1 X Queen
                                                    Bedroom</span></span></label>
                                                <div class="row">
                                                    <div class="col-lg-8 col-md-8 col-sm-8 col-8 h6">Base Rate</div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break">$118.15</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-8 col-md-8 col-sm-8 col-8 h6">Taxes &amp; Fees</div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break">$35.93</div>
                                                </div>
                                                <hr class="my-2"></hr>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div class="h5 mb-1 fw-bold text-primary">Total Rate </div>
                                                    </div>
                                                    <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                        <div class="h5 mb-2 fw-bold text-primary  float-lg-end text-end">$154.08</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-2"></hr>
                                    <p className="h5 mb-3 text-black fw-bold mt-3">Service Request </p>
                                    <div className="row">
                                        <div className='control col-lg-12'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Service </label>
                                            <Dropdown
                                                onChange={serviceChangeHandler}
                                                value={serviceOptions.filter(option => option.value === service)}
                                                options={serviceOptions}
                                                textField="listValue"
                                                dataItemKey="listID"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                isDisabled={false}

                                            />
                                        </div>
                                        {/* <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Remarks</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' />
                                        </div> */}


                                    </div>
                                    <div className="control">
                                        <label className="h6 text-primary mb-2 col-lg-12" for="category"> Remarks
                                        </label>
                                        <div>
                                            <textarea id="thresholdAlertEmail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12"
                                                value={"cancellation"}
                                            >
                                            </textarea>
                                        </div>

                                    </div>
                                    <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12 mb-3 mt-2">Existing Confirmation Number:  <span className="text-dark"> AEHFH</span></label>
                                    <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Total Rate of service: <span className="text-dark"> $ 15</span></label>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </div >
    </Fragment >);
}

export default RetrieveTravelRequest;