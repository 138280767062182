import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AirportItem from './AirportItem'
import useHttp from '../../../../services/use-http'
import { deleteSingleAirport } from '../../../../services/Airport-API'
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import { sortData } from '../../../../utils';
const AirportList = (props) => {
  const { t } = useTranslation(['translation.Airport']);
  const history = useHistory();
  const location = useLocation();

  const [error1, setError] = useState();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';


  const sortedAirport = sortData(props.airport,'airportName', isSortingAscending);
  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };
  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleAirport, true);
  const [isErrorOnDelete,setIsErrorOnDelete]=useState(false);

  useEffect(() => {
    //document.querySelector("#sortBy").selectedIndex = 0;
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });

    history.push({
      pathname: '/AllAirport',
      search: `?sort=${('asc')}`
    })

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }
    if (status === 'completed') {
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
        setIsErrorOnDelete(true);
      }
      else {        
        setError({
          title: "Message",
          message: `${t('recordDeletedMessage')}`,
        });
      }
    }
  }, [status, history, loadedQuote, t])

  const deleteItemsHandler = (props) => {
    const onDeleteAirport = ({
      id: props.id,
      traceID: "",
      officeID: "",
      userID: "",
      countryCode: "",
      cityCode: "",
      airportCode: "",
      airportName: ""
    });

    sendRequest(onDeleteAirport);

    if (error) {
      return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordDelete')}</div>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  //End code for delete
  const errorHandler = () => {
    setError(null);
    if(!isErrorOnDelete)
    {
      history.go({
        pathname: '/AllAirport'
      })
    }
    else
    {
      history.push({
        pathname: '/AllAirport'
      })
    }    
  };
  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}

      <div id="sortByText" className="container-fluid mb-3 list-view my-3">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "30px" }}>
            <div className='row mb-3'>
              <div className="col-md-6"></div>
              <div className='col-md-2 my-auto text-muted text-left'>
                <div className="float-lg-end float-md-end">Sort By</div>
              </div>
              <div className="col-md-4 form-group">
                <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                  <option>{t('sortAirportOptionAsc')}</option>
                  <option>{t('sortAirportOptionDesc')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {sortedAirport.map((airport) => (
          <AirportItem onDelete={deleteItemsHandler}
            key={airport.id}
            id={airport.id}
            airportCode={airport.airportCode}
            airportName={airport.airportName}
            countryName={airport.countryName}
            countryCode={airport.countryCode}
            cityName={airport.cityName}
            cityCode={airport.cityCode}
          />
        ))}

      </div>
    </Fragment>
  );
};

export default AirportList
