import { Fragment, useEffect, useState } from 'react';

const ApproverDialog = (props) => {

    function onSelectEmployer() {
        props.errorHandlerApproverDialog();
    }

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mt-3'>
                                <div className='col-md-12'>
                                    <div className=' table-responsive mt-2 table-wrapper width-900px'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th scope='col'>S. No.</th>
                                                    <th scope='col'>Employee</th>
                                                    <th scope="col">Department</th>
                                                    <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Mr. Rahul Srinivasan</td>
                                                    <td>Human Resources</td>
                                                    <td className='text-center sticky-col last-col bg-light'>
                                                        <div className="text-center">
                                                            <a className="btn btn-sm ms-1 text-primary" title="Select" onClick={() => onSelectEmployer()}> Select</a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default ApproverDialog;

