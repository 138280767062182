import { Fragment, useEffect, useState } from "react";
import useHttp from '../../../services/use-http';
import { getFareRule } from '../../../services/Flight-API';
import Dropdown from 'react-select';


const FareRule = (props) => {
    const [fareRuleList, setFareRuleList] = useState([]);
    const [fareRuleResult, setFareRuleResult] = useState([]);
    const [flag, setFlag] = useState(true);
    // const [fareRuleOption, setFareRuleOption] = useState([]);
    // const [fareRuleArrayData, setFareRuleArrayData] = useState([]);
    let fareRuleOptions = []
    let seleted_option = []
    let fareRuleArrayData = [];

    let selectValue = "";
    let ID = "";
    const { sendRequest, status, data: loadedFareRules, error } = useHttp(getFareRule);
    const [selectOption, setSelectionOption] = useState();
    const [selectId, setSelectId] = useState();
    const [isFirst, setIsFirst] = useState(false);
    function fareRuleChangeHandler(event) {
        selectValue = '';
        ID = ""
        setFlag(false);
        setSelectionOption(event.id);
        setSelectId(event.value);
        document.querySelector("#fareRulesDetails").innerHTML = event.id;
        document.querySelector("#fareRulesDetailsFirst").innerHTML = "";

    }



    useEffect(() => {
        let fareRuleInput;
        if (props.type !== 'multicity') {
            sendRequest(props.inputData[0]);
            setFareRuleList(props.inputData);
        } else {
            setFareRuleList(props.fareInfo)
            props.fareInfo[0].segments.segment.forEach(item => {

                props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {

                    if (item.id === parseInt(element.flightSegment[0].flightID)) {

                        fareRuleInput = (
                            {

                                "officeID": JSON.parse(localStorage.getItem('officeID')),
                                "Data": {
                                    "FareRuleSourceInfo": [
                                        {
                                            "FlightSegmentRuleInfo": [
                                                {
                                                    "DepartureAirport": {
                                                        "LocationCode": props.fareInfo[0].operation === "FROM" ? props.fareInfo[0].segments.beginAirport : props.fareInfo[0].segments.endAirport,

                                                    },
                                                    "ArrivalAirport": {
                                                        "LocationCode": props.fareInfo[0].operation === "FROM" ? props.fareInfo[0].segments.endAirport : props.fareInfo[0].segments.beginAirport,

                                                    },
                                                    "MarketingAirline": {
                                                        "Code": element.flightSegment[0].marketingAirline.code,
                                                        "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                    },

                                                    "FareRules": {
                                                        "FareRule": [{

                                                            "FareBasisCode": props.fareInfo[0].fareBasisCode
                                                        }],
                                                    },
                                                    "DepartureDate": element.flightSegment[0].departureDate,
                                                    "DepartureTime": element.flightSegment[0].departureTime,
                                                    "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                    "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                }
                                            ],
                                            "SourceId": "1W",

                                            "SectorInd": props.inputData.sectorInd
                                        }
                                    ],
                                    "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                }
                            }
                        );



                    }


                })

            })

            sendRequest(fareRuleInput);

        }

    }, [sendRequest, props.inputData, props.type, props.fareInfo])


    if (status === 'completed') {
        //  setFareRuleResult(loadedFareRules.data.fareRuleSourceInfo);

        if (loadedFareRules !== null && loadedFareRules.data.errors.status === 'FALSE') {
            if (loadedFareRules.data.errors.status === "FALSE") {
                const fareRuleInfoArray = loadedFareRules.data.fareRuleSourceInfo.map(
                    (fareRuleSourceInfo) =>
                        fareRuleSourceInfo.flightSegmentRuleInfo.map((flightSegmentRuleInfo) =>
                            flightSegmentRuleInfo.fareRules.fareRule.map((fareRule) => fareRule.fareRuleInfo)
                        )
                );

                fareRuleArrayData = fareRuleInfoArray[0][0][0];
            }

        }

        for (let index = 0; index < fareRuleArrayData.length; index++) {
            fareRuleOptions = [...fareRuleOptions, { value: fareRuleArrayData[index].id, label: fareRuleArrayData[index].title, id: fareRuleArrayData[index].text }]
            if (index === 0) {
                selectValue = fareRuleArrayData[index].text;
                ID = fareRuleArrayData[index].id;
                if (!isFirst) {
                    if (document.querySelector("#fareRulesDetails") !== null) {
                        document.querySelector("#fareRulesDetails").innerHTML = fareRuleArrayData[index].text;
                    }
                    setIsFirst(true)
                }

            }
        }
        //

    }



    useEffect(() => {

        let loadedData = [];
        if (status === 'completed') {
            if (loadedFareRules.data.errors.status === 'FALSE') {
                loadedData = loadedFareRules.data.fareRuleSourceInfo;

            }
            setFareRuleResult(loadedData);
        }



    }, [status])
    useEffect(() => {

        setSelectionOption(selectValue);
        setSelectId(ID);

    }, [selectValue, ID])

    const tabChangeHandler = (data, sectorType) => {
        let fareRuleInput;
        setFareRuleResult([]);
        fareRuleOptions = [];
        if (sectorType !== 'multicity') {
            sendRequest(data);
        }
        else {

            data.segments.segment.forEach(item => {

                props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {
                    if (item.id === parseInt(element.flightSegment[0].flightID)) {
                        fareRuleInput = (
                            {

                                "officeID": JSON.parse(localStorage.getItem('officeID')),
                                "Data": {
                                    "FareRuleSourceInfo": [
                                        {
                                            "FlightSegmentRuleInfo": [
                                                {
                                                    "DepartureAirport": {
                                                        "LocationCode": data.operation === "FROM" ? data.segments.beginAirport : data.segments.endAirport,

                                                    },
                                                    "ArrivalAirport": {
                                                        "LocationCode": data.operation === "FROM" ? data.segments.endAirport : data.segments.beginAirport,

                                                    },
                                                    "MarketingAirline": {
                                                        "Code": element.flightSegment[0].marketingAirline.code,
                                                        "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                    },

                                                    "FareRules": {
                                                        "FareRule": [{

                                                            "FareBasisCode": data.fareBasisCode
                                                        }],
                                                    },
                                                    "DepartureDate": element.flightSegment[0].departureDate,
                                                    "DepartureTime": element.flightSegment[0].departureTime,
                                                    "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                    "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                }
                                            ],
                                            "SourceId": "1W",

                                            "SectorInd": props.inputData.sectorInd
                                        }
                                    ],
                                    "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                }
                            }
                        );
                    }

                })

            })

            sendRequest(fareRuleInput);
        }

        setFlag(true);


    }
    function tabList() {
        let tab = [];
        //RoundTrip
        if (fareRuleList.length !== 0 && fareRuleList.length === 2 && props.type !== 'multicity') {
            let id1 = fareRuleList[0].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode1 + "-" +
                fareRuleList[0].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode1;
            let id2 = fareRuleList[1].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode1 + "-" +
                fareRuleList[1].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode1;

            tab.push(<li className="nav-item pb-0" role="presentation">
                <button class='tabs-btn nav-link active' text="bcd" id={`departure_${id1}`} data-bs-toggle="tab"
                    data-bs-target={`#departure-tb${id1}`} type="button" role="tab" aria-controls={`departure-tb${id1}`}
                    aria-selected="true" onClick={(e) => tabChangeHandler(fareRuleList[0], 'roundTrip')}>{id1}</button>

            </li>);
            tab.push(<li className="nav-item pb-0" role="presentation">
                <button class='tabs-btn nav-link' text="bcd" id={`departure_${id2}`} data-bs-toggle="tab"
                    data-bs-target={`#departure-tb${id2}`} type="button" role="tab" aria-controls={`departure-tb${id2}`}
                    aria-selected="true" onClick={(e) => tabChangeHandler(fareRuleList[1], 'roundTrip')}>{id2}</button>

            </li>);
            return tab;

        }
        //multicity
        else if (props.type === 'multicity' && fareRuleList.length !== 0) {
            {
                fareRuleList.forEach((element, index) => {
                    let tab1 = element.segments.beginAirport + "-" + element.segments.endAirport;
                    let activetab = "tabs-btn nav-link";
                    if (index === 0) {
                        activetab = "tabs-btn nav-link active"
                    }
                    tab.push(<li className="nav-item pb-0" role="presentation">
                        <button class={activetab} text="bcd" id={`departure_${tab1}`} data-bs-toggle="tab"
                            data-bs-target={`#departure-tb${tab1}`} type="button" role="tab" aria-controls={`departure-tb${tab1}`}
                            aria-selected="true" onClick={(e) => tabChangeHandler(element, 'multicity')}>{tab1}</button>

                    </li>);
                })
            }
        }
        //oneway
        else if (fareRuleList.length === 1) {
            {
                fareRuleList.length !== 0 && fareRuleList.forEach((element, index) => {
                    let id1 = element.Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode + "-" + element.Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode;
                    let activetab = "tabs-btn nav-link";
                    if (index === 0) {
                        activetab = "tabs-btn nav-link active"
                    }
                    tab.push(<li className="nav-item pb-0" role="presentation">
                        <button class={activetab} text="bcd" id={`departure_${id1}`} data-bs-toggle="tab"
                            data-bs-target={`#departure-tb${id1}`} type="button" role="tab" aria-controls={`departure-tb${id1}`}
                            aria-selected="true" onClick={(e) => tabChangeHandler(element, 'oneway')}>{id1}</button>

                    </li>);
                })
            }

        }



        return tab;
    }

    return <Fragment>

        <div className='col-md-12'>
            <div className="row">
                <div className="col-md-12 pop-ups-tab-border-parent">
                    <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                        {tabList()}
                    </ul>
                </div>
                <div className="col-md-12 ">

                    <div className="px-3 tab-content include-exclude-tab" id="myTabContent">
                        {status === 'pending' &&
                            <div className='py-2 text-center'>
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        }
                        {status === 'completed' && fareRuleOptions.length === 0 && <div className='text-center py-2'>Sorry, fare rule info is not available.</div>}

                        {status === 'completed' && fareRuleResult !== undefined && fareRuleResult.length > 0 && fareRuleOptions.length > 0 && <div className="tab-pane fade show active" id={`#departure-tb${fareRuleResult[0].flightSegmentRuleInfo[0].departureAirport.locationCode}-${fareRuleResult[0].flightSegmentRuleInfo[0].departureAirport.locationCode}`} role="tabpanel" aria-labelledby="departure">
                            <div className=''>
                                <div className=' position-relative'>
                                    <div className='row mt-3'>

                                        <div className='col-md-12 gt-h-340px'>
                                            <div className='gt-mh-150px' >

                                                <div className='row'>
                                                    <div className='col-md-12  my-auto postion-relative'>
                                                        {/* {status === 'pending' && <i className="fa fa-spinner fa-spin fare-details-dropdown-loader" ></i>} */}

                                                        <Dropdown
                                                            options={fareRuleOptions}
                                                            value={fareRuleOptions.filter(option => option.value === selectId)}
                                                            onChange={fareRuleChangeHandler}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            isDisabled={status === 'pending' ? true : false}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' />
                                                    </div>
                                                    <i></i>
                                                    {/* <hr className='my-3'></hr> */}
                                                    <div className='text-16px  mt-3 display-line' id="fareRulesDetails">

                                                    </div>
                                                    <div className='text-16px mt-3 display-line' id="fareRulesDetailsFirst">
                                                        {flag && selectValue}
                                                    </div>




                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}


                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default FareRule;