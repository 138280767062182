import {    
    SEARCH_STATE
} from './CONSTANTS'

export async function searchState(searchData) {
    const response = await fetch(SEARCH_STATE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch state.');
    }
    
    return data;
}