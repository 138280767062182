import React from "react";
import ReactDOM from "react-dom";

import Button from "../../components/Common/Button/Button";
import TermsCondition from "./Components/TermsCondition";


const Backdrop = (props) => {
    return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {
    //console.log(props.hotelDetails);
    return (
        <div className='imagemodalcard imageModal1 modal-top'>
            <header className='header'>
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                        <h4 className="text-white">Terms and Conditions</h4>

                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={props.onConfirm}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
                </div>

            </header>
            <div className="gt-h-430px">

                <div className='content'>
                    <br></br> <br></br> <br></br>
                    <TermsCondition></TermsCondition>
                </div>
            </div>
            <footer className='actions float-end px-3 py-2'>
                <Button onClick={props.onConfirm}>OK</Button>
            </footer>
        </div>
    );
};

const TermsConditionsPopup = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClick={props.onConfirm}></Backdrop>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                    title={props.title}
                    message={props.message}
                    onConfirm={props.onConfirm}
                ></ModalOverlay>,
                document.getElementById("overlay-root")
            )}
        </React.Fragment>
    );
};
export default TermsConditionsPopup;
