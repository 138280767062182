import AirportForm from './Components/AirportForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addAirport, getSingleAirport, updateAirport } from '../../../services/Airport-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const NewAirport = (props) => {
    useDocumentTitle('Airport')
    const { t } = useTranslation(['translation.Airport']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addAirport
    } else {
        varHttp = updateAirport
    }

    const { sendRequest: sendRequest1, data: loadedAirportDetails, error } = useHttpGet(getSingleAirport);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("Master", "Airport", "Master_Airport_Update") && Action === '1')
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "Airport", "Master_Airport_View") && Action === '0')
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, loadedQuote, t])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }

    let loadedData = [];
    for (let index in loadedAirportDetails) {
        if (loadedAirportDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedAirportDetails[index];
            }
        }
    }

    const addAirportHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/allairport');
        }
    };



    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                {/* <section id='common_banner'><div className='container'></div></section>
                <div className='centered'>
                    <LoadingSpinner></LoadingSpinner>
                </div> */}
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    const AirportFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <AirportForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddAirport={addAirportHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                            {/* <h2>
                        {t('airportDetails')}</h2>
                        <ul>
                            <li><a href="index.html">{t('home')}</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> {t('airportDetails')}</li>
                        </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {AirportFormContent}
    </Fragment>
};

export default NewAirport
