import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from './MdModal'
import { SendMailHotel } from "../../../services/Hotel-API";
import useHttp from "../../../services/use-http";
import { validateEmail } from "../../../utils/Validation";
const HotelSendMail = (props) => {
    const inputEmailIdRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(SendMailHotel);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState('');

    const errorHandler1 = () => {
        setError1(null);
    };
    function functionExit() {
        props.errorHandler();
    }

    useEffect(() => {

        if (status === 'completed') {
            if (loadedQuote && loadedQuote['errors'].status === 'FALSE') {
                setMailConfirm('Email sent successfully.');

            } else {
                setMailConfirm("Some error occurred");
            }
            return;
        }

    }, [status])
    function onBlurCheck() {
        setMailConfirm('');
        setInvalidEmailError(false);
        document.querySelector('#msgSendMail').innerHTML = "";
    }
    function submitFormHandler(event) {
        event.preventDefault();
        let mailDataObject = props.mailData;
        let mail = inputEmailIdRef.current.value.trim()
        let cleanMail = mail.replace(/[\r\n]/gm, '');

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        if (inputEmailIdRef.current.value.includes(";")) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;
        }
        else {
            setInvalidEmailError(false);
        }

        if (!mail.includes(',') && validateEmail(mail) === null) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;

        } else {
            setInvalidEmailError(false);
        }
        if (cleanMail.includes(',')) {
            const mailArray = cleanMail.split(',');
            for (let email of mailArray) {
                if (validateEmail(email.trim()) === null) {
                    setInvalidEmailError(true);
                    inputEmailIdRef.current.focus();
                    return;

                } else {
                    setInvalidEmailError(false);
                }
            }

        }

        mailDataObject.data.travelerInfo.guestInfo[0].sendEmailID = cleanMail;
        mailDataObject.data.requestDetails.userID = JSON.parse(localStorage.getItem('userID'));

        if (inputEmailIdRef.current.value !== '' && invalidEmailError === false) {
            sendRequest(
                mailDataObject
            );
        }
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div className="container">
                    <div className="row">
                        <div className="control">
                            <label className="h6 text-primary mb-2 col-lg-12" for="category"> Email ID<span className="error-icon"> * </span>
                            </label>
                            <div>
                                <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12"
                                    onChange={onBlurCheck} ref={inputEmailIdRef}
                                    autoFocus>
                                </textarea>
                                {balnkMailError && <p className="error-text">Email ID is required.</p>
                                }
                                {invalidEmailError && <p className="error-text">Please specify valid Email ID.</p>}
                                {!balnkMailError && !invalidEmailError && <p className="success-text">{mailConfirm}</p>}
                                <span id="msgSendMail" className="error-text"></span></div>
                            <span className="h6 text-primary mb-2 col-lg-12" > <span className="text-black fw-bold">Note : </span>  Please use commas to separate multiple Email IDs.</span>
                        </div>


                        <div className="col-md-6 my-auto"></div>
                        <div className="col-md-6 my-auto align-right">

                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : 'Send'}
                            </button>
                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit} >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
}
export default HotelSendMail;