import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchCity } from '../../../services/City-API'
import CityList from './Components/CityList'
import { NavLink, useHistory } from 'react-router-dom';
import { NEWCITY } from '../../../navigation/CONSTANTS'
import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../components/Common/DropDownList/CountryDropDownList';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const AllCity = () => {
    useDocumentTitle('Search City');

    const [enteredCode, setEnterCode] = useState("");
    const [enteredName, setEnterName] = useState("");
    const [country_Code, setCountry_Code] = useState('');
    /*  const [country_Name,setCountry_Name]=useState('');     */
    const [isFirtTime, setFirstTime] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const { t } = useTranslation(['translation.City']);

    const codechangehandler = (event) => {
        setEnterCode(event.target.value);
    };
    const namechangehandler = (event) => {
        setEnterName(event.target.value);
    };
    const countryCodeValue = (country_Code) => {
        setCountry_Code(country_Code);
    };
    const countryNameText = (country_Name) => {
        /*    setCountry_Name(country_Name); */
    };

    const history = useHistory();

    const { sendRequest, status, data: loadedCity, error } = useHttp(searchCity);
    const onResetHandler = (event) => {
        // event.preventDefault();
        // setEnterCode('');
        // setEnterName('');
        // setCountry_Code('');
        history.push('/allcity');
        history.go();
    }

    const enteredCodeInputRef = useRef('');
    const [enteredCodeLengthInputHasError, setEnteredCodeLengthInputHasError] = useState(false);
    const [enteredCodeInvalidInputError, setEnteredCodeInvalidInputError] = useState(false);

    const enteredNameInputRef = useRef('');
    const [enteredNameInvalidInputError, setEnteredNameInvalidInputError] = useState(false);

    function onBlurCheck() {
        if (enteredCode.length !== 0) {
            if (enteredCode.length !== 3) {
                setEnteredCodeLengthInputHasError(true);
                setEnteredCodeInvalidInputError(false);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredCodeLengthInputHasError(false);
            }

            if (!enteredCode.match('^[a-zA-Z]+$')) {
                setEnteredCodeInvalidInputError(true);
                setEnteredCodeLengthInputHasError(false);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredCodeInvalidInputError(false);
            }
        }
        else {
            setEnteredCodeLengthInputHasError(false);
            setEnteredCodeInvalidInputError(false);
        }

        if (enteredName.length !== 0) {
            if (!enteredName.match('^[a-zA-Z. ]+$')) {
                setEnteredNameInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredNameInvalidInputError(false);
            }
        }
        else {
            setEnteredNameInvalidInputError(false);
        }
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);

        if (enteredCode.length !== 0) {
            if (enteredCode.length !== 3) {
                enteredCodeInputRef.current.focus();
                setEnteredCodeLengthInputHasError(true);
                return false;
            }
            else {
                setEnteredCodeLengthInputHasError(false);
            }

            if (!enteredCode.match('^[a-zA-Z]+$')) {
                enteredCodeInputRef.current.focus();
                setEnteredCodeInvalidInputError(true);
                return;
            }
            else {
                setEnteredCodeInvalidInputError(false);
            }
        }
        else {
            setEnteredCodeLengthInputHasError(false);
            setEnteredCodeInvalidInputError(false);
        }

        if (enteredName.length !== 0) {
            if (!enteredName.match('^[a-zA-Z. ]+$')) {
                enteredNameInputRef.current.focus();
                setEnteredNameInvalidInputError(true);
                return;
            }
            else {
                setEnteredNameInvalidInputError(false);
            }
        }
        else {
            setEnteredNameInvalidInputError(false);
        }

        const onSearchCity = ({
            countryCode: country_Code,
            cityCode: enteredCode,
            cityName: enteredName
        });

        if (enteredCodeLengthInputHasError === false && enteredCodeInvalidInputError === false && enteredNameInvalidInputError === false) {
            setIsSubmitClicked(true);
            sendRequest(onSearchCity);
        }
    }, [sendRequest, country_Code, enteredCode, enteredName, enteredCodeInvalidInputError,
        enteredCodeLengthInputHasError, enteredNameInvalidInputError])
    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        //document.getElementById('code').focus();
        /*  setEnteredCodeLengthInputHasError(false);
         setEnteredCodeInvalidInputError(false);
         setEnteredNameInvalidInputError(false); */
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

   
    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    for (let i in loadedCity) {
        if (loadedCity['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCity[i];
            }
        }
    }

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }

    // const cityList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
    //     <CityList city={loadedData} /> : <div className='centered focused col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>

    const errorHandler = () => {

        history.go('/allcity');
    };

    const ErrorShow = (!enteredCodeLengthInputHasError && !enteredCodeInvalidInputError && isSubmitClicked && enteredNameInvalidInputError === false && status !== 'pending') ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''


    const cityList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <CityList city={loadedData} /> : ErrorShow

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {/* <div className=''>
                                    <LoadingSpinner />   
                                </div>   */}

                                {status === 'pending' &&
                                    <div className=''>
                                        {/* <section id='common_banner'><div className='container'></div></section> */}
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }

                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search City</label>
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label htmlFor='Country' className="h6 text-primary mb-2 col-lg-12">City Code</label>
                                                    <input maxLength={3} type='text' className="mb-0 form-control form-control-sm col-lg-12" id='code' value={enteredCode} onChange={codechangehandler} onInput={changeToUpperCase} placeholder='' ref={enteredCodeInputRef} onBlur={onBlurCheck} autoFocus />
                                                    {enteredCodeLengthInputHasError &&
                                                        <p className="error-text">{t('validationCityCodeLength')}</p>
                                                    }
                                                    {enteredCodeInvalidInputError &&
                                                        <p className="error-text">{t('validationCityCodeInvalidData')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('cityName')}</label>
                                                    <input maxLength="100" type='text' className="mb-0 form-control form-control-sm col-lg-12" id='name' value={enteredName} onChange={namechangehandler} ref={enteredNameInputRef} onBlur={onBlurCheck} />
                                                    {enteredNameInvalidInputError &&
                                                        <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                    }
                                                </div>
                                            </div>

                                            <label htmlFor='Country' className="h6 text-primary mb-2 col-lg-12"> {t('country')}</label>

                                            <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={country_Code} iSDisabled={false} />

                                            <div className="mt-3 col-md-12">
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>
                                                {checkSecurityDetails("Master", "City","Master_City_Insert") &&
                                                <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={NEWCITY}>
                                                    {t('new')}
                                                </NavLink>}
                                                {checkSecurityDetails("Master", "City","Master_City_Search") &&
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

            {cityList}
        </Fragment>)
};

export default AllCity;
