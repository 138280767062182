import { Fragment } from 'react';

const GuestInformation = (props) => {
    return (<tr>
            <td>{props.id}</td>
            <td scope="row">{props.guestInfo.personName.namePrefix  +" "+ props.guestInfo.personName.givenName+" "+ props.guestInfo.personName.middleName +" "+ props.guestInfo.personName.surname}</td>
           

        </tr>
    )

};

export default GuestInformation;