import Dropdown from 'react-select';
import { calculateAge, getFormatDate } from '.././../../../utils/CommonFunction';
import { useState, Fragment, useEffect } from 'react';
import { dateList, monthList, expiryYearList, dobYearList, gender_list, dobInfantYearList, dobChildYearList } from '../../../../utils/DropdownData';
import { useHistory } from 'react-router-dom';
import { CreateImportBookingJSON } from '../../../../utils/BookingFunction';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import TaxDetails from './TaxDetails';
import MdModal from '../../../Flights/MdModal';
import { searchCurrency } from '../../../../services/Currency-API';
import useHttp from '../../../../services/use-http';
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';
import ErrorModalPopUp from '../../../../components/Common/Error/ErrorModalPopUp';

const PriceDetails = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [paxTypeInputHasError, setPaxTypeInputHasError] = useState(false);
    const [currencyCodeInputHasError, setCurrencyCodeInputHasError] = useState(false);
    const [baseFareInputHasError, setBaseFareInputHasError] = useState(false);
    const [baseFareInvalidInputHasError, setBaseFareInvalidInputHasError] = useState(false);
    const [taxesInputHasError, setTaxesInputHasError] = useState(false);
    const [taxesInvalidInputHasError, setTaxesInvalidInputHasError] = useState(false);
    const [fareTypeInputHasError, setFareTypeInputHasError] = useState(false);
    const [fareOptionInputHasError, setFareOptionInputHasError] = useState(false);
    const [fareBasisCodeInputHasError, setFareBasisCodeInputHasError] = useState(false);
    const [fareBasisCodeInvalidInputHasError, setFareBasisCodeInvalidInputHasError] = useState(false);
    const [airlineTransactionChargesInvalidInputHasError, setAirlineTransactionChargesInvalidInputHasError] = useState(false);
    const [commissionInvalidInputHasError, setCommissionInvalidInputHasError] = useState(false);


    const [paxTypeValue, SetPaxTypeValue] = useState();
    const [confirm, setConfirm] = useState();
    const [paxTypeForDelete, SetPaxTypeForDelete] = useState();
    const [error1, setError] = useState();
    const [error2, setError2] = useState();
    const [error3, setError3] = useState();
    const history = useHistory();
    const [currencyCode, setCurrencyCode] = useState('');
    const [fareTypeValue, SetFareTypeValue] = useState();
    const [fareOptionValue, SetFareOptionValue] = useState();

    const { sendRequest: sendRequestCurrencyList, data: loadedCurrency } = useHttp(searchCurrency);

    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

    const pax_type_list = [];

    if (bookingJSON !== null) {
        if (bookingJSON.data.travelerInfo !== undefined) {
            bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
                //Pax Type is being repeated
                const found = pax_type_list.find(el => el.value === elementTraveler.passengerTypeCode);
                if (!found) {
                    let paxTypeText = elementTraveler.passengerTypeCode === 'ADT' ? 'Adult' : elementTraveler.passengerTypeCode === 'CNN' ? 'Child' : elementTraveler.passengerTypeCode === 'INF' ? 'Lap Infant' : elementTraveler.passengerTypeCode === 'INS' ? 'Seat Infant' : '';
                    pax_type_list.push({ value: elementTraveler.passengerTypeCode, label: paxTypeText })
                }
            });
        }
    }


    const FareType = [
        { value: "Published", label: "Published" },
        { value: "Private", label: "Private" },
        { value: "Nego", label: "Nego" },
    ];

    const FareOption = [
        { value: "NONREFUNDABLE", label: "Non Refundable" },
        { value: "PARTIAL", label: "Partially Refundable" },
        { value: "FULL", label: "Fully Refundable" }
    ];

    useEffect(() => {
        if (getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE") !== null && getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE") !== '') {
            let FareDisplayCurrencyCode = getConfigurationByValue("FARE_DISPLAY_CURRENCY_CODE");
            if (FareDisplayCurrencyCode.length > 0) {
                setCurrencyCode(FareDisplayCurrencyCode);
            }
        }

        if (bookingJSON !== null && bookingJSON.data !== null && bookingJSON.data.airItinerary !== null && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
            if (document.querySelector('#btnAddPrice') !== null) {
                document.querySelector('#btnAddPrice').classList.add('d-none');
            }
        }

    }, [])

    useEffect(() => {
        const onSearchCurrencyList = ({
            currCode: "",
            currName: ""
        });

        sendRequestCurrencyList(onSearchCurrencyList);

    }, [sendRequestCurrencyList])

    // useEffect(() => {
    //     const pax_type_list_new = [
    //         { value: 'ADT', label: 'Adult' },
    //         { value: 'CNN', label: 'Child' },
    //         { value: 'INF', label: 'Lap Infant' },
    //         { value: 'INS', label: 'Seat Infant' }
    //     ];

    //     if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
    //         let travelersCount = 0;
    //         let travelersPriceCount = 0;
    //         let paxTypesAdded = [];

    //         if (bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.length > 0 && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.length > 0) {
    //             pax_type_list_new.forEach(elementPaxTypeList => {
    //                 travelersCount = bookingJSON.data.travelerInfo !== undefined && bookingJSON.data.travelerInfo.traveler !== undefined && bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPaxTypeList.value);
    //                 travelersPriceCount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0].quantity !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPaxTypeList.value)[0].quantity : 0;

    //                 if (travelersPriceCount > 0 && travelersCount.length === 0 && travelersCount.length < travelersPriceCount) {
    //                     onDeletePrice(elementPaxTypeList.value);
    //                     paxTypesAdded.push(elementPaxTypeList.value);
    //                 }
    //                 else if (travelersPriceCount > 0 && travelersCount.length > 0 && travelersCount.length !== travelersPriceCount) {
    //                     paxTypesAdded.push(elementPaxTypeList.value);
    //                 }
    //             });
    //         }

    //         if (paxTypesAdded.length > 0) {
    //             let totalBaseFare = 0;
    //             let totalTax = 0;
    //             let totalFare = 0;
    //             let totalCommission = 0;
    //             let totalTransaction = 0;

    //             if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
    //                     elementPassengerTypeQuantity.quantity = bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPassengerTypeQuantity.code).length.toString();
    //                     totalBaseFare = (parseFloat(elementPassengerTypeQuantity.baseFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalBaseFare;
    //                     totalTax = (parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTax;
    //                     totalTransaction = (parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTransaction;
    //                     totalCommission = (parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalCommission;
    //                     totalFare = ((parseFloat(elementPassengerTypeQuantity.totalFare.amount)) * parseInt(elementPassengerTypeQuantity.quantity)) + totalFare;
    //                 });

    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = totalFare;
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = totalBaseFare;
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = totalTax;
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = totalTransaction;
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = totalCommission;

    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount.toString();
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount.toString();
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount.toString();
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount.toString();
    //                 bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount.toString();
    //             }

    //             sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
    //             //sessionStorage.setItem("finalTotalFare", bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
    //         }
    //     }
    // })

    let currencyOptions = []
    let currencyArrayData = [];
    for (let i in loadedCurrency) {
        if (loadedCurrency['errors'].status === 'FALSE') {
            if (i === 'data') {
                currencyArrayData = loadedCurrency[i];
            }
        }
    }
    for (let index = 0; index < currencyArrayData.length; index++) {
        currencyOptions = [...currencyOptions, { value: currencyArrayData[index].currCode, label: currencyArrayData[index].currName }]
    }

    const handleChangePaxType = (e) => {
        SetPaxTypeValue(e.value);

        if (bookingJSON !== null && bookingJSON.data !== null && bookingJSON.data.airItinerary !== null && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
            if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === e.value).length === 0 || bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === e.value)[0] === undefined) {
                if (document.querySelector('#btnAddPrice') !== null) {
                    document.querySelector('#btnAddPrice').classList.remove('d-none');
                }
            }
            else {
                if (document.querySelector('#btnAddPrice') !== null) {
                    document.querySelector('#btnAddPrice').classList.add('d-none');
                }
            }
        }
    };

    const handleChangeFareType = (e) => {
        SetFareTypeValue(e.value);
    };

    const handleChangeFareOption = (e) => {
        SetFareOptionValue(e.value);
    };

    const taxesPopup = () => {
        onAddPriceFinal();

        if (document.querySelector('#taxes').value !== '0') {
            setError({
                title: "Add Taxes",
                message: <div className='col-md-12'>
                    <TaxDetails paxType={paxTypeValue}></TaxDetails>
                </div>,
            });
        }

        // if (document.querySelector('#taxes').value === '0') {
        //     clearPriceDetailsFields();
        // }
    }

    const errorHandler = () => {
        if (sessionStorage.getItem("BookingData") !== null) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

            if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== "") {
                if (parseFloat(document.querySelector('#taxes').value !== '' ? document.querySelector('#taxes').value : 0) > 0 && (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax === undefined || bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax.length === 0)) {
                    setError(null);
                    document.querySelector('#taxes').value = '0';
                    document.querySelector('#taxes').focus();
                    document.querySelector('#btnContinue').focus();
                    onDeletePrice(paxTypeValue);
                    onAddPrice();
                }
                else {
                    let taxAmount = 0;
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax !== undefined) {
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax.forEach(elementTax => {
                            taxAmount = taxAmount + parseFloat(elementTax.amount !== undefined && elementTax.amount !== '' ? elementTax.amount : 0);
                        });
                    }

                    //taxAmount = taxAmount + (parseFloat(document.querySelector('#taxAmount').value !== '' ? document.querySelector('#taxAmount').value : 0));
                    let totalPaxTypeTaxAmount = parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.amount !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.amount !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.amount : 0);

                    if (Math.floor(taxAmount) < Math.floor(totalPaxTypeTaxAmount)) {
                        setError3({
                            title: "Message",
                            message: `${t('taxAmountMisMatchMessage')}${new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode : 'USD' }).format(totalPaxTypeTaxAmount)}`,
                        });
                        throw false;
                    }
                    else if (Math.floor(taxAmount) > Math.floor(totalPaxTypeTaxAmount)) {
                        setError3({
                            title: "Message",
                            message: `${t('taxAmountMisMatchMessage')}${new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== '' ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode : 'USD' }).format(totalPaxTypeTaxAmount)}`,
                        });
                        throw false;
                    }
                    else {
                        let bookingJSONTax = JSON.parse(sessionStorage.getItem("BookingData"));
                        if (bookingJSONTax.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue).length === 0 || bookingJSONTax.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax === undefined) {

                        }
                        else {
                            clearPriceDetailsFields();
                            setError(null);
                        }
                    }
                }
            }
        }
    };

    function onDeletePrice(paxType) {
        //let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let bookingJSONModified = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code !== paxType);

        if (bookingJSONModified.length > 0) {
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity = bookingJSONModified;
        }
        else {
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo = "";
        }

        sortAirItineraryPassenger(bookingJSON);
        history.push(`/ImportPNR`);
        //history.go();
    }

    function onConfirm(rph) {
        SetPaxTypeForDelete(rph);
        setConfirm(true);
    }
    function okHandler() {
        onDeletePrice(paxTypeForDelete);
        setConfirm(false);
        if (bookingJSON !== null && bookingJSON.data !== null && bookingJSON.data.airItinerary !== null && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo === '') {
            if (document.querySelector('#btnAddPrice') !== null) {
                document.querySelector('#btnAddPrice').classList.remove('d-none');
            }
        }
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function clearPriceDetailsFields() {
        SetPaxTypeValue('');
        //setCurrencyCode('');
        document.querySelector('#baseFare').value = "";
        document.querySelector('#taxes').value = "";
        document.querySelector('#totalFare').value = "";
        SetFareTypeValue('');
        SetFareOptionValue('');
        document.querySelector('#fareBasisCode').value = "";
        document.querySelector('#airlineTransactionCharges').value = "";
        document.querySelector('#commission').value = "";
    }

    function onAddPrice() {
        //let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));       

        if (paxTypeValue === undefined || paxTypeValue === '') {
            setPaxTypeInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setPaxTypeInputHasError(false);
        }

        if (currencyCode === undefined || currencyCode === '') {
            setCurrencyCodeInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setCurrencyCodeInputHasError(false);
        }

        if (document.querySelector('#baseFare').value === '') {
            setBaseFareInputHasError(true);
            setBaseFareInvalidInputHasError(false);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setBaseFareInputHasError(false);
        }

        if (document.querySelector('#baseFare').value !== '' && !document.querySelector('#baseFare').value.match('^[0-9.]+$')) {
            setBaseFareInputHasError(false);
            setBaseFareInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setBaseFareInvalidInputHasError(false);
        }

        if (document.querySelector('#taxes').value === '') {
            setTaxesInputHasError(true);
            setTaxesInvalidInputHasError(false);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTaxesInputHasError(false);
        }

        if (document.querySelector('#taxes').value !== '' && !document.querySelector('#taxes').value.match('^[0-9.]+$')) {
            setTaxesInputHasError(false);
            setTaxesInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setTaxesInvalidInputHasError(false);
        }

        if (document.querySelector('#airlineTransactionCharges').value !== '' && !document.querySelector('#airlineTransactionCharges').value.match('^[0-9.]+$')) {
            setAirlineTransactionChargesInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("airlineTransactionCharges");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setAirlineTransactionChargesInvalidInputHasError(false);
        }

        if (document.querySelector('#commission').value !== '' && !document.querySelector('#commission').value.match('^[0-9.]+$')) {
            setCommissionInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("commission");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setCommissionInvalidInputHasError(false);
        }

        if (fareTypeValue === undefined || fareTypeValue === '') {
            setFareTypeInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFareTypeInputHasError(false);
        }

        if (fareOptionValue === undefined || fareOptionValue === '') {
            setFareOptionInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFareOptionInputHasError(false);
        }

        if (document.querySelector('#fareBasisCode').value === '') {
            setFareBasisCodeInputHasError(true);
            setFareBasisCodeInvalidInputHasError(false);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFareBasisCodeInputHasError(false);
        }

        if (document.querySelector('#fareBasisCode').value !== '' && !document.querySelector('#fareBasisCode').value.match('^[a-zA-Z0-9/]+$')) {
            setFareBasisCodeInputHasError(false);
            setFareBasisCodeInvalidInputHasError(true);
            const yOffset = -150;
            const element2 = document.getElementById("baseFare");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        }
        else {
            setFareBasisCodeInvalidInputHasError(false);
        }

        if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo == '') {
            if (paxTypeValue !== '' && document.querySelector('#fareBasisCode').value !== '') {
                taxesPopup();
            }
        }
        else if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue).length === 0) {
            taxesPopup();
        }
        else {
            if (document.querySelector('#btnAddPrice') !== null && document.querySelector('#btnAddPrice').innerHTML.replaceAll('&nbsp;', '').toLowerCase() === 'update') {
                taxesPopup();
            }
            onAddPriceFinal();
            // if (document.querySelector('#btnAddPrice') !== null && document.querySelector('#btnAddPrice').innerHTML.replaceAll('&nbsp;', '').toLowerCase() === 'add') {
            //     clearPriceDetailsFields();
            // }
            if (document.querySelector('#taxes').value === '0') {
                clearPriceDetailsFields();
            }
        }
    }

    function onAddPriceFinal() {
        if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity === undefined) {
            let airItineraryPricingInfo = props.airItinerary.airItinerary[0].airItineraryPricingInfo;
            let passengerTypeQuantity = [];
            let penaltyTemp = [];
            if (fareOptionValue === 'NONREFUNDABLE') {
                penaltyTemp = [{
                    penaltyType: "Refund",
                    departureStatus: "Before",
                    conditions: fareOptionValue,
                    refundable: "false"
                },
                {
                    penaltyType: "Refund",
                    departureStatus: "After",
                    refundable: ""
                }];
            }
            else {
                penaltyTemp = [{
                    penaltyType: "Refund",
                    departureStatus: "Before",
                    conditions: fareOptionValue,
                    refundable: "true",
                    amount: fareOptionValue === 'PARTIAL' ? '1' : '0'
                },
                {
                    penaltyType: "Refund",
                    departureStatus: "After",
                    refundable: ""
                }];
            }

            if (document.querySelector('#taxes').value === '0') {
                passengerTypeQuantity.push(
                    {
                        baseFare: {
                            amount: document.querySelector('#baseFare').value,
                            currencyCode: currencyCode
                        },
                        transaction: {
                            amount: document.querySelector('#airlineTransactionCharges').value,
                            currencyCode: currencyCode
                        },
                        commission: {
                            amount: document.querySelector('#commission').value,
                            currencyCode: currencyCode
                        },
                        totalFare: {
                            amount: document.querySelector('#totalFare').value,
                            currencyCode: currencyCode
                        },
                        code: paxTypeValue,
                        requestedPaxType: paxTypeValue,
                        quantity: bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === paxTypeValue).length.toString() : 0,
                        fareInfos: {
                            fareInfo: [{
                                fareBasisCode: document.querySelector('#fareBasisCode').value,
                                fareType: fareTypeValue,
                                ruleInfo: {
                                    chargesRules: {
                                        voluntaryChanges: {
                                            penalty: [{

                                                penaltyType: "",
                                                departureStatus: "Before",
                                                currencyCode: currencyCode,
                                                amount: "",
                                                conditions: "",
                                                changeable: ""
                                            },
                                            {
                                                penaltyType: "",
                                                departureStatus: "After",
                                                conditions: "",
                                                changeable: ""
                                            }]
                                        },
                                        voluntaryRefunds: {
                                            penalty: penaltyTemp
                                        }
                                    }
                                }
                            }]
                        }
                    }
                );
            }
            else {
                passengerTypeQuantity.push(
                    {
                        baseFare: {
                            amount: document.querySelector('#baseFare').value,
                            currencyCode: currencyCode
                        },
                        taxes: {
                            amount: document.querySelector('#taxes').value,
                            currencyCode: currencyCode
                        },
                        transaction: {
                            amount: document.querySelector('#airlineTransactionCharges').value,
                            currencyCode: currencyCode
                        },
                        commission: {
                            amount: document.querySelector('#commission').value,
                            currencyCode: currencyCode
                        },
                        totalFare: {
                            amount: document.querySelector('#totalFare').value,
                            currencyCode: currencyCode
                        },
                        code: paxTypeValue,
                        requestedPaxType: paxTypeValue,
                        quantity: bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === paxTypeValue).length.toString() : 0,
                        fareInfos: {
                            fareInfo: [{
                                fareBasisCode: document.querySelector('#fareBasisCode').value,
                                fareType: fareTypeValue,
                                ruleInfo: {
                                    chargesRules: {
                                        voluntaryChanges: {
                                            penalty: [{

                                                penaltyType: "",
                                                departureStatus: "Before",
                                                currencyCode: currencyCode,
                                                amount: "",
                                                conditions: "",
                                                changeable: ""
                                            },
                                            {
                                                penaltyType: "",
                                                departureStatus: "After",
                                                conditions: "",
                                                changeable: ""
                                            }]
                                        },
                                        voluntaryRefunds: {
                                            penalty: penaltyTemp
                                        }
                                    }
                                }
                            }]
                        }
                    }
                );
            }

            let totalBaseFare = 0;
            let totalTax = 0;
            let totalFare = 0;
            let totalCommission = 0;
            let totalTransaction = 0;

            passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                totalBaseFare = (parseFloat(elementPassengerTypeQuantity.baseFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalBaseFare;
                totalTax = (parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTax;
                totalTransaction = (parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTransaction;
                totalCommission = (parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalCommission;
                totalFare = (parseFloat(elementPassengerTypeQuantity.totalFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalFare;
            });

            airItineraryPricingInfo = {
                totalFare: {
                    amount: totalFare.toString(),
                    currencyCode: currencyCode
                },
                totalBaseFare: {
                    amount: totalBaseFare.toString(),
                    currencyCode: currencyCode
                },
                totalTax: {
                    amount: totalTax.toString(),
                    currencyCode: currencyCode
                },
                totalTransaction: {
                    amount: totalTransaction.toString(),
                    currencyCode: currencyCode
                },
                totalCommission: {
                    amount: totalCommission.toString(),
                    currencyCode: currencyCode
                },
                "passengerTypeQuantity": passengerTypeQuantity
            };

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo = airItineraryPricingInfo;
            sortAirItineraryPassenger(bookingJSON);
            history.push(`/ImportPNR`);
            //history.go();
            //clearPriceDetailsFields();
        }
        else {
            let paxTypeData = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue);

            if (paxTypeData.length === 0) {
                let penaltyTemp = [];
                if (fareOptionValue === 'NONREFUNDABLE') {
                    penaltyTemp = [{
                        penaltyType: "Refund",
                        departureStatus: "Before",
                        conditions: fareOptionValue,
                        refundable: "false"
                    },
                    {
                        penaltyType: "Refund",
                        departureStatus: "After",
                        refundable: ""
                    }];
                }
                else {
                    penaltyTemp = [{
                        penaltyType: "Refund",
                        departureStatus: "Before",
                        conditions: fareOptionValue,
                        refundable: "true",
                        amount: fareOptionValue === 'PARTIAL' ? '1' : '0'
                    },
                    {
                        penaltyType: "Refund",
                        departureStatus: "After",
                        refundable: ""
                    }];
                }

                let passengerTypeQuantity = [];

                if (document.querySelector('#taxes').value === '0') {
                    passengerTypeQuantity = ({
                        baseFare: {
                            amount: document.querySelector('#baseFare').value,
                            currencyCode: currencyCode
                        },
                        transaction: {
                            amount: document.querySelector('#airlineTransactionCharges').value,
                            currencyCode: currencyCode
                        },
                        commission: {
                            amount: document.querySelector('#commission').value,
                            currencyCode: currencyCode
                        },
                        totalFare: {
                            amount: document.querySelector('#totalFare').value,
                            currencyCode: currencyCode
                        },
                        code: paxTypeValue,
                        requestedPaxType: paxTypeValue,
                        quantity: bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === paxTypeValue).length.toString() : 0,
                        fareInfos: {
                            fareInfo: [{
                                fareBasisCode: document.querySelector('#fareBasisCode').value,
                                fareType: fareTypeValue,
                                ruleInfo: {
                                    chargesRules: {
                                        voluntaryChanges: {
                                            penalty: [{

                                                penaltyType: "",
                                                departureStatus: "Before",
                                                currencyCode: currencyCode,
                                                amount: "",
                                                conditions: "",
                                                changeable: ""
                                            },
                                            {
                                                penaltyType: "",
                                                departureStatus: "After",
                                                conditions: "",
                                                changeable: ""
                                            }]
                                        },
                                        voluntaryRefunds: {
                                            penalty: penaltyTemp
                                        }
                                    }
                                }
                            }]
                        }
                    })
                }
                else {
                    passengerTypeQuantity = ({
                        baseFare: {
                            amount: document.querySelector('#baseFare').value,
                            currencyCode: currencyCode
                        },
                        taxes: {
                            amount: document.querySelector('#taxes').value,
                            currencyCode: currencyCode
                        },
                        transaction: {
                            amount: document.querySelector('#airlineTransactionCharges').value,
                            currencyCode: currencyCode
                        },
                        commission: {
                            amount: document.querySelector('#commission').value,
                            currencyCode: currencyCode
                        },
                        totalFare: {
                            amount: document.querySelector('#totalFare').value,
                            currencyCode: currencyCode
                        },
                        code: paxTypeValue,
                        requestedPaxType: paxTypeValue,
                        quantity: bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === paxTypeValue).length.toString() : 0,
                        fareInfos: {
                            fareInfo: [{
                                fareBasisCode: document.querySelector('#fareBasisCode').value,
                                fareType: fareTypeValue,
                                ruleInfo: {
                                    chargesRules: {
                                        voluntaryChanges: {
                                            penalty: [{

                                                penaltyType: "",
                                                departureStatus: "Before",
                                                currencyCode: currencyCode,
                                                amount: "",
                                                conditions: "",
                                                changeable: ""
                                            },
                                            {
                                                penaltyType: "",
                                                departureStatus: "After",
                                                conditions: "",
                                                changeable: ""
                                            }]
                                        },
                                        voluntaryRefunds: {
                                            penalty: penaltyTemp
                                        }
                                    }
                                }
                            }]
                        }
                    })
                }

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(passengerTypeQuantity);

                let totalBaseFare = 0;
                let totalTax = 0;
                let totalFare = 0;
                let totalCommission = 0;
                let totalTransaction = 0;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    totalBaseFare = (parseFloat(elementPassengerTypeQuantity.baseFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalBaseFare;
                    totalTax = (parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTax;
                    totalTransaction = (parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTransaction;
                    totalCommission = (parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalCommission;
                    totalFare = ((parseFloat(elementPassengerTypeQuantity.totalFare.amount)) * parseInt(elementPassengerTypeQuantity.quantity)) + totalFare;
                });

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = totalFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = totalBaseFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = totalTax;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = totalTransaction;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = totalCommission;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount.toString();

                sortAirItineraryPassenger(bookingJSON);
                history.push(`/ImportPNR`);
                //history.go();
                //clearPriceDetailsFields();
            }
            else {
                if (document.querySelector('#taxes').value === '0') {
                    if (paxTypeData[0].transaction !== undefined && paxTypeData[0].commission !== undefined) {
                        let paxTypeDataTemp = [{
                            baseFare: paxTypeData[0].baseFare,
                            transaction: paxTypeData[0].transaction,
                            commission: paxTypeData[0].commission,
                            totalFare: paxTypeData[0].totalFare,
                            code: paxTypeData[0].code,
                            requestedPaxType: paxTypeData[0].requestedPaxType,
                            quantity: paxTypeData[0].quantity,
                            fareInfos: paxTypeData[0].fareInfos
                        }];
                        paxTypeData = paxTypeDataTemp;
                    }
                    else if (paxTypeData[0].transaction === undefined && paxTypeData[0].commission === undefined) {
                        let paxTypeDataTemp = [{
                            baseFare: paxTypeData[0].baseFare,
                            totalFare: paxTypeData[0].totalFare,
                            code: paxTypeData[0].code,
                            requestedPaxType: paxTypeData[0].requestedPaxType,
                            quantity: paxTypeData[0].quantity,
                            fareInfos: paxTypeData[0].fareInfos
                        }];
                        paxTypeData = paxTypeDataTemp;
                    }
                    else {
                        if (paxTypeData[0].transaction === undefined) {
                            let paxTypeDataTemp = [{
                                baseFare: paxTypeData[0].baseFare,
                                commission: paxTypeData[0].commission,
                                totalFare: paxTypeData[0].totalFare,
                                code: paxTypeData[0].code,
                                requestedPaxType: paxTypeData[0].requestedPaxType,
                                quantity: paxTypeData[0].quantity,
                                fareInfos: paxTypeData[0].fareInfos
                            }];
                            paxTypeData = paxTypeDataTemp;
                        }
                        else if (paxTypeData[0].commission === undefined) {
                            let paxTypeDataTemp = [{
                                baseFare: paxTypeData[0].baseFare,
                                transaction: paxTypeData[0].transaction,
                                totalFare: paxTypeData[0].totalFare,
                                code: paxTypeData[0].code,
                                requestedPaxType: paxTypeData[0].requestedPaxType,
                                quantity: paxTypeData[0].quantity,
                                fareInfos: paxTypeData[0].fareInfos
                            }];
                            paxTypeData = paxTypeDataTemp;
                        }
                    }
                }
                else if (document.querySelector('#taxes').value !== '0') {
                    if (paxTypeData[0].taxes !== undefined) {
                        paxTypeData[0].taxes.amount = document.querySelector('#taxes').value;
                        paxTypeData[0].taxes.currencyCode = currencyCode;
                    }
                    else {
                        paxTypeData[0].taxes = {
                            amount: document.querySelector('#taxes').value,
                            currencyCode: currencyCode
                        }
                    }
                }

                paxTypeData[0].baseFare.amount = document.querySelector('#baseFare').value;
                paxTypeData[0].baseFare.currencyCode = currencyCode;
                if (paxTypeData[0].transaction !== undefined) {
                    paxTypeData[0].transaction.amount = document.querySelector('#airlineTransactionCharges').value;
                    paxTypeData[0].transaction.currencyCode = currencyCode;
                }
                else {
                    paxTypeData[0].transaction = {
                        amount: document.querySelector('#airlineTransactionCharges').value,
                        currencyCode: currencyCode
                    }
                }

                if (paxTypeData[0].commission !== undefined) {
                    paxTypeData[0].commission.amount = document.querySelector('#commission').value;
                    paxTypeData[0].commission.currencyCode = currencyCode;
                }
                else {
                    paxTypeData[0].commission = {
                        amount: document.querySelector('#commission').value,
                        currencyCode: currencyCode
                    }
                }
                paxTypeData[0].totalFare.amount = document.querySelector('#totalFare').value;
                paxTypeData[0].totalFare.currencyCode = currencyCode;
                paxTypeData[0].fareInfos.fareInfo[0].fareBasisCode = document.querySelector('#fareBasisCode').value;
                paxTypeData[0].fareInfos.fareInfo[0].fareType = fareTypeValue;
                if (paxTypeData[0].fareInfos.fareInfo[0].ruleInfo !== undefined && paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules !== undefined && paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds !== undefined && paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined) {
                    paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].conditions = fareOptionValue;
                    if (fareOptionValue !== 'NONREFUNDABLE') {
                        paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].amount = fareOptionValue === 'PARTIAL' ? '1' : '0';
                    }

                    if (fareOptionValue === 'NONREFUNDABLE') {
                        paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].refundable = "false";
                    }
                    else {
                        paxTypeData[0].fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].refundable = "true";
                    }
                }

                if (document.querySelector('#taxes').value === '0') {
                    let paxTypeDataAll = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity;
                    let paxTypeDataAllTemp = paxTypeDataAll.filter(a => a.code !== paxTypeValue);
                    paxTypeDataAllTemp.push(paxTypeData[0]);
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity = paxTypeDataAllTemp;
                }

                let totalFareAmount = 0;
                let totalBaseFareAmount = 0;
                let totalTaxAmount = 0;
                let totalTransactionAmount = 0;
                let totalCommissionAmount = 0;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code !== paxTypeValue).forEach(elementPassengerTypeQuantity => {
                    totalFareAmount = totalFareAmount + parseFloat(elementPassengerTypeQuantity.totalFare.amount);
                    totalBaseFareAmount = totalBaseFareAmount + parseFloat(elementPassengerTypeQuantity.baseFare.amount);
                    totalTaxAmount = totalTaxAmount + parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0);
                    totalTransactionAmount = totalTransactionAmount + parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0);
                    totalCommissionAmount = totalCommissionAmount + parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0);
                });

                let totalBaseFare = 0;
                let totalTax = 0;
                let totalFare = 0;
                let totalCommission = 0;
                let totalTransaction = 0;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    totalBaseFare = (parseFloat(elementPassengerTypeQuantity.baseFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalBaseFare;
                    totalTax = (parseFloat(elementPassengerTypeQuantity.taxes !== undefined ? elementPassengerTypeQuantity.taxes.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTax;
                    totalTransaction = (parseFloat(elementPassengerTypeQuantity.transaction !== undefined && elementPassengerTypeQuantity.transaction.amount !== undefined && elementPassengerTypeQuantity.transaction.amount !== '' ? elementPassengerTypeQuantity.transaction.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalTransaction;
                    totalCommission = (parseFloat(elementPassengerTypeQuantity.commission !== undefined && elementPassengerTypeQuantity.commission.amount !== undefined && elementPassengerTypeQuantity.commission.amount !== '' ? elementPassengerTypeQuantity.commission.amount : 0) * parseInt(elementPassengerTypeQuantity.quantity)) + totalCommission;
                    totalFare = (parseFloat(elementPassengerTypeQuantity.totalFare.amount) * parseInt(elementPassengerTypeQuantity.quantity)) + totalFare;
                });

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = totalFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = totalBaseFare;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = totalTax;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = totalTransaction;
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = totalCommission;

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTransaction.amount.toString();
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalCommission.amount.toString();

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].quantity = bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === paxTypeValue).length.toString() : 0;

                sortAirItineraryPassenger(bookingJSON);
                history.push(`/ImportPNR`);
                //history.go();
                //clearPriceDetailsFields();
            }
        }

        if (document.querySelector('#btnAddPrice') !== undefined && document.querySelector('#btnAddPrice') !== null) {
            document.querySelector('#btnAddPrice').innerHTML = " &nbsp;&nbsp; &nbsp; Add &nbsp; &nbsp;&nbsp;  ";
        }
        if (bookingJSON !== null && bookingJSON.data !== null && bookingJSON.data.airItinerary !== null && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
            if (document.querySelector('#btnAddPrice') !== null) {
                document.querySelector('#btnAddPrice').classList.add('d-none');
            }
        }

        if (document.querySelector('#taxes').value === '0') {
            if (sessionStorage.getItem("BookingData") !== null) {
                let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== "") {
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax !== undefined) {
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].taxes.tax = [];
                    }
                }
                sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            }
        }

        //sessionStorage.setItem("finalTotalFare", bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
    }

    function onEditPrice(paxType) {
        if (document.querySelector('#btnAddPrice') !== null) {
            document.querySelector('#btnAddPrice').classList.remove('d-none');
        }

        if (document.querySelector('#btnAddPrice') !== undefined && document.querySelector('#btnAddPrice') !== null) {
            document.querySelector('#btnAddPrice').innerHTML = "Update";
        }

        let passengerTypeQuantity = props.airItinerary.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxType)[0];

        document.querySelector('#baseFare').value = passengerTypeQuantity.baseFare.amount;
        setCurrencyCode(passengerTypeQuantity.baseFare.currencyCode);
        document.querySelector('#taxes').value = passengerTypeQuantity.taxes !== undefined ? passengerTypeQuantity.taxes.amount : '0';
        document.querySelector('#totalFare').value = passengerTypeQuantity.totalFare.amount;
        SetPaxTypeValue(paxType);
        document.querySelector('#fareBasisCode').value = passengerTypeQuantity.fareInfos.fareInfo[0].fareBasisCode;
        SetFareTypeValue(passengerTypeQuantity.fareInfos.fareInfo[0].fareType !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].fareType !== null ? passengerTypeQuantity.fareInfos.fareInfo[0].fareType : passengerTypeQuantity.fareType);
        SetFareOptionValue(passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined ? passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].conditions : '');
        document.querySelector('#airlineTransactionCharges').value = passengerTypeQuantity.transaction !== undefined && passengerTypeQuantity.transaction.amount !== undefined ? passengerTypeQuantity.transaction.amount : '';
        document.querySelector('#commission').value = passengerTypeQuantity.commission !== undefined && passengerTypeQuantity.commission.amount !== undefined ? passengerTypeQuantity.commission.amount : '';
    }

    function assignPrice(event) {
        event.preventDefault();
        let setValidationStaus = true;
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let currencyCode = '';

        if (sessionStorage.getItem("BookingData") === null) {
            setValidationStaus = false;
            setError2({
                title: "Message",
                message: `${t('ErrorMessage')}`,
            });
        }
        else {
            if (bookingJSON.data.airItinerary === null || bookingJSON.data.airItinerary[0] === undefined || bookingJSON.data.airItinerary[0].airItineraryPricingInfo === null || bookingJSON.data.airItinerary[0].airItineraryPricingInfo === '') {
                setValidationStaus = false;
                setError2({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
            else {
                let passengerTypeCode = [];
                bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
                    const found = passengerTypeCode.find(el => el.passengerTypeCode === elementTraveler.passengerTypeCode);
                    if (!found) {
                        passengerTypeCode.push({
                            passengerTypeCode: elementTraveler.passengerTypeCode
                        });
                    }
                });

                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue) !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0] !== undefined) {
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === paxTypeValue)[0].length > 0) {
                        setValidationStaus = false;
                        setError2({
                            title: "Message",
                            message: `${t('ErrorMessage')}`,
                        });
                    }
                }

                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.length < passengerTypeCode.length) {
                        setValidationStaus = false;
                        setError2({
                            title: "Message",
                            message: `${t('ErrorMessageFillAllPaxTypeData')}`,
                        });
                    }
                }
            }
        }

        if (bookingJSON !== null && bookingJSON !== '' && bookingJSON !== undefined) {
            if (bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare !== undefined) {
                currencyCode = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].totalFare.currencyCode;
            }

            if (currencyCode !== '') {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    if (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined) {
                        bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.forEach(elementAddOn => {
                            if (elementAddOn.ssrCurrency !== undefined && elementAddOn.ssrCurrency !== '') {
                                if (elementAddOn.ssrCurrency !== currencyCode) {
                                    setValidationStaus = false;
                                    setError3({
                                        title: "Message",
                                        message: `${t('CurrencyErrorMessage')}`,
                                    });
                                }
                            }
                        });
                    }
                }

                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== currencyCode) {
                    setValidationStaus = false;
                    setError3({
                        title: "Message",
                        message: `${t('CurrencyErrorMessage')}`,
                    });
                }

                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.currencyCode !== currencyCode) {
                    setValidationStaus = false;
                    setError3({
                        title: "Message",
                        message: `${t('CurrencyErrorMessage')}`,
                    });
                }

                if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.currencyCode !== currencyCode) {
                    setValidationStaus = false;
                    setError3({
                        title: "Message",
                        message: `${t('CurrencyErrorMessage')}`,
                    });
                }

                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    if (elementPassengerTypeQuantity.baseFare.currencyCode !== currencyCode) {
                        setValidationStaus = false;
                        setError3({
                            title: "Message",
                            message: `${t('CurrencyErrorMessage')}`,
                        });
                    }

                    if (elementPassengerTypeQuantity.taxes !== undefined && elementPassengerTypeQuantity.taxes.currencyCode !== currencyCode) {
                        setValidationStaus = false;
                        setError3({
                            title: "Message",
                            message: `${t('CurrencyErrorMessage')}`,
                        });
                    }

                    if (elementPassengerTypeQuantity.totalFare.currencyCode !== currencyCode) {
                        setValidationStaus = false;
                        setError3({
                            title: "Message",
                            message: `${t('CurrencyErrorMessage')}`,
                        });
                    }

                    if (elementPassengerTypeQuantity.taxes !== undefined && elementPassengerTypeQuantity.taxes.tax !== undefined) {
                        elementPassengerTypeQuantity.taxes.tax.forEach(elementTax => {
                            if (elementTax.currencyCode !== currencyCode) {
                                setValidationStaus = false;
                                setError3({
                                    title: "Message",
                                    message: `${t('CurrencyErrorMessage')}`,
                                });
                            }
                        });
                    }

                    if (setValidationStaus) {
                        let taxesAmount = 0;
                        let taxAmount = 0;

                        if (elementPassengerTypeQuantity.taxes !== undefined && elementPassengerTypeQuantity.taxes.amount !== undefined && elementPassengerTypeQuantity.taxes.tax !== undefined && elementPassengerTypeQuantity.taxes.tax[0] !== undefined) {
                            taxesAmount = elementPassengerTypeQuantity.taxes.amount !== '' ? parseFloat(elementPassengerTypeQuantity.taxes.amount) : 0;

                            if (taxesAmount > 0) {
                                elementPassengerTypeQuantity.taxes.tax.forEach(elementTax => {
                                    taxAmount = taxAmount + (elementTax.amount !== '' ? parseFloat(elementTax.amount) : 0);
                                });

                                let paxType = elementPassengerTypeQuantity.code === 'ADT' ? 'Adult' : elementPassengerTypeQuantity.code === 'CNN' ? 'Child' : elementPassengerTypeQuantity.code === 'INF' ? 'Lap Infant' : elementPassengerTypeQuantity.code === 'INS' ? 'Seat Infant' : '';
                                if (Math.floor(taxesAmount) !== Math.floor(taxAmount)) {
                                    setValidationStaus = false;
                                    setError2({
                                        title: "Message",
                                        message: `${t('taxBreakupAmountMisMatchMessage')}${paxType}`,
                                    });
                                }
                            }
                        }

                        if (elementPassengerTypeQuantity.taxes !== undefined && elementPassengerTypeQuantity.taxes.amount !== undefined && (elementPassengerTypeQuantity.taxes.tax === undefined || elementPassengerTypeQuantity.taxes.tax.length === 0)) {
                            taxesAmount = elementPassengerTypeQuantity.taxes.amount !== '' ? parseFloat(elementPassengerTypeQuantity.taxes.amount) : 0;

                            if (taxesAmount > 0) {
                                let paxType = elementPassengerTypeQuantity.code === 'ADT' ? 'Adult' : elementPassengerTypeQuantity.code === 'CNN' ? 'Child' : elementPassengerTypeQuantity.code === 'INF' ? 'Lap Infant' : elementPassengerTypeQuantity.code === 'INS' ? 'Seat Infant' : '';
                                setValidationStaus = false;
                                setError2({
                                    title: "Message",
                                    message: `${t('taxBreakupAmountMisMatchMessage')}${paxType}`,
                                });
                            }
                        }
                    }
                });
            }
        }

        if (setValidationStaus) {
            let addOnsAmount = 0;
            if (bookingJSON !== null && bookingJSON !== undefined) {
                if (bookingJSON.data.travelerInfo.specialReqDetails !== undefined) {
                    if (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined) {
                        bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.forEach(elementAddOn => {
                            addOnsAmount = addOnsAmount + parseFloat(elementAddOn.ssrCharge !== null && elementAddOn.ssrCharge !== '' ? elementAddOn.ssrCharge : 0);
                        });
                    }
                }
            }

            if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== null && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount) + parseFloat(addOnsAmount);
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount.toString();
            }

            if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    if (elementPassengerTypeQuantity.quantity !== bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPassengerTypeQuantity.code).length.toString()) {
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === elementPassengerTypeQuantity.code)[0].quantity = bookingJSON.data.travelerInfo.traveler !== undefined ? bookingJSON.data.travelerInfo.traveler.filter(a => a.passengerTypeCode === elementPassengerTypeQuantity.code).length.toString() : 0;
                    }
                });
            }

            if (bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount !== undefined) {
                sessionStorage.setItem("finalTotalFare", bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
            }

            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));

            props.onContinueButton("6");
            window.scrollTo({
                top: 450,
                behavior: "smooth",
            });
        }
    }

    function calculateTotalFare() {
        let baseFare = document.querySelector('#baseFare').value !== '' ? parseFloat(document.querySelector('#baseFare').value) : 0;
        let taxes = document.querySelector('#taxes').value !== '' ? parseFloat(document.querySelector('#taxes').value) : 0;
        let airlineTransactionCharges = document.querySelector('#airlineTransactionCharges').value !== '' ? parseFloat(document.querySelector('#airlineTransactionCharges').value) : 0;
        let commission = document.querySelector('#commission').value !== '' ? parseFloat(document.querySelector('#commission').value) : 0;

        let totalFare = baseFare + taxes + airlineTransactionCharges;
        document.querySelector('#totalFare').value = totalFare.toFixed(2);
    }

    const currencyChangeHandler = (event) => {
        if (event.value !== '') {
            setCurrencyCode(event.value);
        }
        else {
            setCurrencyCode('');
        }
    };

    const errorHandler2 = () => {
        setError2(null);
        const yOffset = -220;
        const element2 = document.getElementById("baseFare");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const errorHandler3 = () => {
        setError3(null);
        const yOffset = -220;
        const element2 = document.getElementById("baseFare");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10 || (e.code !== undefined && e.code.substring(0, e.code.length - 1) !== 'Digit')) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown' && e.code !== 'Tab')) {
            if ((e.code !== undefined && e.code !== 'Period')) {
                e.preventDefault();
            }
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    function sortAirItineraryPassenger(bookingJSON) {
        let passengerTypeQuantityTemp = [];
        let adtPassengerType = '';
        let cnnPassengerType = '';
        let infPassengerType = '';
        let insPassengerType = '';

        if (bookingJSON !== '' && bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '') {
            adtPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'ADT');
            cnnPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'CNN');
            infPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'INF');
            insPassengerType = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'INS');

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity = passengerTypeQuantityTemp;

            if (adtPassengerType.length > 0) {
                adtPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (cnnPassengerType.length > 0) {
                cnnPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (infPassengerType.length > 0) {
                infPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }

            if (insPassengerType.length > 0) {
                insPassengerType.forEach(elementPassengerType => {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.push(elementPassengerType);
                });
            }
        }

        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        //return bookingJSON;
    }

    return (
        <Fragment>
            {error3 && (
                <ErrorModalPopUp
                    title={error3.title}
                    message={t(error3.message)}
                    onConfirm={errorHandler3}
                ></ErrorModalPopUp>
            )}
            {error2 && (
                <ErrorModal
                    title={error2.title}
                    message={t(error2.message)}
                    onConfirm={errorHandler2}
                ></ErrorModal>
            )}
            {error1 && (
                <MdModal
                    title={error1.title}
                    message={error1.message}
                    onConfirm={errorHandler}
                ></MdModal>
            )}
            {confirm && (
                <ConfirmationModal
                    title={t('confirmationMessageTitle')}
                    message={t('confirmationMessage')}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            <div className="accordion_flex_area">
                <div className="accordion_left_side position-relative text-muted">
                    <h5>6</h5>
                    <span id="completeSix" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button id="headingSix" className="text-muted accordion-button collapsed" type="button"
                            data-bs-target="#collapseSix" aria-expanded="false"
                            aria-controls="collapseSix">
                            Price
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse"
                        aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className='col-lg-12'>
                            {/* <label for="" className="h5 text-primary mb-3"><i className="fas fa-money"></i> Price</label> */}
                            <div className='row'>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Pax Type <span className="error-icon"> * </span></label>
                                    <Dropdown
                                        options={pax_type_list} onChange={handleChangePaxType}
                                        value={pax_type_list.filter(option => option.value === paxTypeValue)}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    />
                                    {paxTypeInputHasError &&
                                        <p className="error-text">{t('validationPaxType')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Currency <span className="error-icon"> * </span></label>
                                    <Dropdown onChange={currencyChangeHandler}
                                        value={currencyOptions.filter(option => option.value === currencyCode)}
                                        options={currencyOptions}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                        placeholder="Select"
                                        isDisabled={currencyCode !== '' ? true : false}
                                    />
                                    {currencyCodeInputHasError &&
                                        <p className="error-text">{t('validationCurrencyCode')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Base Fare <span className="error-icon"> * </span></label>
                                    <input type="number" id='baseFare' className="form-control form-control-sm" onBlur={calculateTotalFare} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                    {baseFareInputHasError &&
                                        <p className="error-text">{t('validationBaseFare')}</p>
                                    }
                                    {baseFareInvalidInputHasError &&
                                        <p className="error-text">{t('validationBaseFareInvalidData')}</p>
                                    }
                                </div>
                            </div>
                            <div className='row'>

                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Total Taxes <span className="error-icon"> * </span></label>
                                    <input type="number" id='taxes' className="form-control form-control-sm" onBlur={calculateTotalFare} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                    {taxesInputHasError &&
                                        <p className="error-text">{t('validationTaxes')}</p>
                                    }
                                    {taxesInvalidInputHasError &&
                                        <p className="error-text">{t('validationTaxesInvalidData')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Airline Transaction Charges <span className="error-icon">  </span></label>
                                    <input type="number" id='airlineTransactionCharges' className="form-control form-control-sm" onBlur={calculateTotalFare} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                    {airlineTransactionChargesInvalidInputHasError &&
                                        <p className="error-text">{t('validationAirlineTransactionChargesInvalidData')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Commission <span className="error-icon">  </span></label>
                                    <input type="number" id='commission' className="form-control form-control-sm" onBlur={calculateTotalFare} min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10" />
                                    {commissionInvalidInputHasError &&
                                        <p className="error-text">{t('validationCommissionInvalidData')}</p>
                                    }
                                </div>
                            </div>

                            <div className='row'>

                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Fare Type <span className="error-icon"> * </span></label>
                                    <Dropdown
                                        options={FareType} onChange={handleChangeFareType}
                                        value={FareType.filter(option => option.value === fareTypeValue)}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    />
                                    {fareTypeInputHasError &&
                                        <p className="error-text">{t('validationFareType')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Refundable <span className="error-icon"> * </span></label>
                                    <Dropdown
                                        options={FareOption} onChange={handleChangeFareOption}
                                        value={FareOption.filter(option => option.value === fareOptionValue)}
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    />
                                    {fareOptionInputHasError &&
                                        <p className="error-text">{t('validationFareOption')}</p>
                                    }
                                </div>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Fare Basis Code <span className="error-icon"> * </span></label>
                                    <input type="text" id='fareBasisCode' maxLength={20} className="form-control form-control-sm" />
                                    {fareBasisCodeInputHasError &&
                                        <p className="error-text">{t('validationFareBasisCode')}</p>
                                    }
                                    {fareBasisCodeInvalidInputHasError &&
                                        <p className="error-text">{t('validationFareBasisCodeInvalidData')}</p>
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <div className="control col-md-4">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">Total Fare</label>
                                    <input type="text" id='totalFare' maxLength={10} className="form-control form-control-sm" disabled />
                                </div>
                            </div>
                            <div className='row'>

                                <div className="control col-md-6">
                                    {paxTypeValue !== undefined && paxTypeValue !== '' && document.querySelector('#fareBasisCode').value !== '' && document.querySelector('#taxes').value !== '0' &&
                                        <div className='my-auto'>
                                            {/* <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label> */}
                                            <button className="btn btn_theme btn_sm" id='addTaxes' onClick={taxesPopup}><i className="fas fa-plus-circle"></i> Add/View Taxes</button>
                                        </div>
                                    }
                                </div>
                                <div className="control col-md-6">
                                    {/* <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label> */}
                                    <button id='btnAddPrice' className="btn text-white gt-main-btn ms-1 btn-sm float-lg-end float-md-end" onClick={() => onAddPrice()}>&nbsp; &nbsp; Add &nbsp; &nbsp;</button>
                                </div>

                            </div>
                            {/* <div className='row'>
                                <div className="control col-md-12 my-2">
                                    <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12">&nbsp;</label>
                                    <button className="btn btn_theme btn_sm float-lg-end float-md-end" onClick={() => onAddPrice()}>Add</button>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-md-12'>
                                    <hr></hr>
                                    <div className='table-responsive mt-2 table-wrapper width-900px'>
                                        {
                                            bookingJSON !== null && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined &&
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr >
                                                        <th scope='col'>Pax Type</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col" align="right" class="text-end">Base Fare</th>
                                                        <th scope="col" align="right" class="text-end">Total Taxes</th>
                                                        <th scope="col" align="right" class="text-end">Airline Txn. Chg.</th>
                                                        {/* <th scope="col">Commission</th> */}
                                                        <th scope="col" align="right" class="text-end">Total Fare</th>
                                                        {/* <th scope="col">Fare Type</th>
                                                        <th scope="col">Refundable</th>
                                                        <th scope="col">Fare Basis Code</th> */}
                                                        <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bookingJSON !== null && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.map((passengerTypeQuantity) => (
                                                            <tr>
                                                                <td>{passengerTypeQuantity.code === "ADT" ? "Adult" : passengerTypeQuantity.code === "CNN" ? "Child" : passengerTypeQuantity.code === "INS" ? "Seat Infant" : "Lap Infant"}</td>
                                                                <td>{passengerTypeQuantity.baseFare.currencyCode}</td>
                                                                <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyCode !== '' ? currencyCode : passengerTypeQuantity.baseFare.currencyCode !== '' ? passengerTypeQuantity.baseFare.currencyCode : 'USD'
                                                                }).format(passengerTypeQuantity.baseFare.amount)}</td>
                                                                <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyCode !== '' ? currencyCode : passengerTypeQuantity.baseFare.currencyCode !== '' ? passengerTypeQuantity.baseFare.currencyCode : 'USD'
                                                                }).format(passengerTypeQuantity.taxes !== undefined ? passengerTypeQuantity.taxes.amount : 0)}</td>
                                                                <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyCode !== '' ? currencyCode : passengerTypeQuantity.baseFare.currencyCode !== '' ? passengerTypeQuantity.baseFare.currencyCode : 'USD'
                                                                }).format(passengerTypeQuantity.transaction !== undefined && passengerTypeQuantity.transaction.amount !== undefined ? passengerTypeQuantity.transaction.amount : 0)}</td>
                                                                {/* <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyCode !== '' ? currencyCode : passengerTypeQuantity.baseFare.currencyCode !== '' ? passengerTypeQuantity.baseFare.currencyCode : 'USD'
                                                                }).format(passengerTypeQuantity.commission !== undefined && passengerTypeQuantity.commission.amount !== undefined ? passengerTypeQuantity.commission.amount : 0)}</td> */}
                                                                <td align={'right'}>{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyCode !== '' ? currencyCode : passengerTypeQuantity.baseFare.currencyCode !== '' ? passengerTypeQuantity.baseFare.currencyCode : 'USD'
                                                                }).format(passengerTypeQuantity.totalFare.amount)}</td>
                                                                {/* <td>{passengerTypeQuantity.fareInfos.fareInfo[0].fareType !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].fareType !== null && passengerTypeQuantity.fareInfos.fareInfo[0].fareType !== '' ? passengerTypeQuantity.fareInfos.fareInfo[0].fareType : passengerTypeQuantity.fareType !== undefined && passengerTypeQuantity.fareType !== null ? passengerTypeQuantity.fareType : ''}</td>
                                                                <td>{passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds !== undefined && passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined ? passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].conditions === 'NONREFUNDABLE' ? 'Non Refundable' : passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].conditions === 'PARTIAL' ? 'Partially Refundable' : passengerTypeQuantity.fareInfos.fareInfo[0].ruleInfo.chargesRules.voluntaryRefunds.penalty[0].conditions === 'FULL' ? 'Fully Refundable' : '' : ''}</td>
                                                                <td>{passengerTypeQuantity.fareInfos.fareInfo[0].fareBasisCode}</td> */}

                                                                <td className='text-center sticky-col last-col bg-light'>
                                                                    <div className="text-center">
                                                                        <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" onClick={() => onEditPrice(passengerTypeQuantity.code)}><i className="fa-solid fa-pen"></i></a>
                                                                        <button className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" onClick={() => onConfirm(passengerTypeQuantity.code)}><i className="fas fa-trash-alt"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                    <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="float-md-end float-lg-end">
                                        {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                        <button type="button" id='btnContinue' onClick={assignPrice} className="btn  text-white gt-main-btn ms-1 btn-sm mt-2">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}
export default PriceDetails