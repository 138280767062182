import { Fragment, useEffect,useState } from "react";
import EmailServerForm from "./Components/EmailServerForm";
import useHttpGet from '../../../services/use-http';
import useHttp from '../../../services/use-http'
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { addEmailServer, getSingleEmailServer, updateEmailServer } from "../../../services/EmailServer-API";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
const UpdateEmailServer = () => {
    useDocumentTitle(" Email Server")
    const { t } = useTranslation(['translation.EmailServer']);
    const parms = useParams();
    const history = useHistory();
    const [error, setError] = useState();
    const { Id } = parms;
    const { sendRequest: sendRequestEmailServer, data: loadedEmailServerDetails, status: getEmailServerStatus, error: getEmailServerError } = useHttpGet(getSingleEmailServer);
    console.log(loadedEmailServerDetails);
    let varHttp;

    if (getEmailServerStatus === 'completed') {
        if (loadedEmailServerDetails!==null && loadedEmailServerDetails['data']===null) {
            varHttp = addEmailServer
        }
        else {
            varHttp = updateEmailServer
        }
    }

    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    useEffect(() => {
        if (Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote && loadedQuote['errors'].status === 'TRUE') {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordSave')}`,
                    });
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
            }
        }
    }, [status, history, Id, sucsssMessage, t, loadedQuote])
    const officeID = JSON.parse(localStorage.getItem('officeID'));

    useEffect(() => {
        console.log(officeID);
        sendRequestEmailServer(officeID);
       // setIsFirstTime(true);
    }, [sendRequestEmailServer])

    if (getEmailServerStatus === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    if (getEmailServerError) {
        history.push('/Error')
        //return <p className='centered focused'>{t('error')}</p>
    }


    let loadedData = [];
    for (let i in loadedEmailServerDetails) {
        if (loadedEmailServerDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedEmailServerDetails[i];
            }
        }
    }
    const addEmailServerHandler = (requestData) => {
        console.log(requestData);
        sendRequest(requestData);

    };
    const errorHandler = () => {
        setError(null);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });

    };
    const emailServerFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p>{t('CustomizeDetailsError')}</p> :
        <EmailServerForm loadedEmailServerDetails={loadedData} isLoading={status === 'pending'} onAddEmailServer={addEmailServerHandler}
        />

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {emailServerFormContent}
    </Fragment>
};
export default UpdateEmailServer;