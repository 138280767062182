import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const FeeItem = (props) => {
  const { t } = useTranslation(['translation.Fee']);
  const [confirm, setConfirm] = useState();

  function deleteFormHandler(props) {
    // optional: Could validate here     
    props.onDelete({
      id: props.id,
      traceID: "",
      userID: "",
      serviceID: "",
      channelID: "",
      airlineCode: [
        {
          airlineCode: ""
        }
      ],
      rbdDetails: [
        {
          rbdName: ""
        }
      ],
      providers: [
        {
          providersID: ""
        }
      ],
      chainCode: [
        {
          hotelCode: ""
        }
      ],
      starCategoryID: "",
      cabinClassID: "",
      bookingDateFrom: 0,
      bookingDateTo: 0,
      travelDateFrom: 0,
      travelDateTo: 0,
      pcc: "",
      feeName: "",
      feeCode: "",
      fareBasisCode: "",
      fareBasis: true,
      feeAppliedID: "",
      taxType: "",
      specificTaxDetails: [
        {
          taxCode: ""
        }
      ],
      calculationType: "",
      feeValue: "",
      minimumFeeAmount: "",
      feeCalculationID: "",
      faresID: "",
      displayID: "",
      currencyCode: "",
      fromRegion: "",
      toRegion: "",
      officeID: ""
    });
  }

  function onConfirm() {
    setConfirm(true);
  }

  function okHandler() {
    deleteFormHandler(props)
    setConfirm(false);
  };

  function cancelHandler() {
    setConfirm(false);
  };

  return (
    <Fragment>
      {confirm && (
        <ConfirmationModal
          title={t('confirmationMessageTitle')}
          message={t('confirmationMessage')}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}

      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark card-bg-blue mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    {props.feeName} ({props.feeCode})
                  </h6>
                  <hr className="my-1" />
                </div>
                <div className="col-md-8">
                  <p className="card-text mb-0"><strong>{t('channel')}: </strong>{props.channel}</p>
                  <p className="card-text mb-0"><strong>{t('service')}: </strong>{props.service}</p>
                  {(props.service.toString().toLowerCase() === 'air') &&
                    <p className="card-text mb-0"><strong>{t('fromregion')}: </strong>{props.fromRegion}</p>
                  }
                  {(props.service.toString().toLowerCase() === 'hotel') &&
                    <p className="card-text mb-0"><strong>{t('region')}: </strong>{props.fromRegion}</p>
                  }
                  {(props.service.toString().toLowerCase() === 'air') &&
                    <p className="card-text mb-0"><strong>{t('toregion')}: </strong>{props.toRegion}</p>
                  }
                </div>
                <div className="col-md-4 my-auto">
                  <div className='float-lg-end float-md-end'>
                    {checkSecurityDetails("Settings", "Fee", "Settings_Fee_Delete") &&
                      <button onClick={() => { onConfirm() }} className="btn btn-sm btn-danger float-sm-start float-lg-end ms-1 gt-border-radius-50 gt-red-btn" title='Delete'><i className="fas fa-trash-alt"></i></button>
                    }
                    {checkSecurityDetails("Settings", "Fee", "Settings_Fee_View") &&
                      <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateFee/${props.id}`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                    }
                    {checkSecurityDetails("Settings", "Fee", "Settings_Fee_Update") &&
                      <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn" title='Edit' to={{ pathname: `/UpdateFee/${props.id}`, edit: 1 }}>
                        <i className="fa-solid fa-pen"></i>
                      </Link>}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FeeItem