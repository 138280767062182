import { Fragment, useState, useEffect } from 'react';
import TravelAgentItem from './TravelAgentItem';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useHttp from '../../../services/use-http';
import { deleteSingleUser } from '../../../services/User-API';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import { sortData } from '../../../utils';


const TravelAgentList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.TravelAgent'])

    const [error1, setError] = useState();
    const queryParams = new URLSearchParams(location.search);

    const isSortingAscending = queryParams.get('sort') === 'asc';


    const sortedUser = sortData(props.user, 'firstName', isSortingAscending);
    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };

  
    

    useEffect(() => {

        window.scrollTo({
            top: 400,
            behavior: "smooth",
        });
        history.push({
            pathname: '/SearchTravelAgent',
            search: `?sort=${('asc')}`
        })


       
    }, [ history])

    

    const errorHandler = () => {
        setError(null);       
    };




    return (<Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}

        <div className="container-fluid mb-3 list-view">

            

            <div className="row">

                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6"></div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">Sort By</div>
                        </div>
                        <div className="col-md-4 form-group">
                            <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                                <option>{t('firstNameAsc')}</option>
                                <option>{t('firstNameDsc')}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-1">
                    <div >
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12">{t('searchUserAfter')}<b> {t('switch')}</b></label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sortedUser.map((user) => (
                <TravelAgentItem 
                    key={user.id}
                    id={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    middleName={user.middleName}
                    isActive={user.isActive}
                    roleID={user.roleName}
                    loginEmailID={user.loginEmailID}
                    employeeCode={user.employeeCode}
                />
            ))}

        </div>
    </Fragment>
    );
};

export default TravelAgentList;