// import { Fragment } from 'react';
import { Fragment } from 'react';
import SegmentItem from './SegmentItem';
import RevenueItem from './RevenueItem';
import PieChart from './PieChart';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const SalesList = (props) => {

    const AllowImportPNR = getConfigurationByBoolen("ALLOW_IMPORT_PNR");
    console.log(AllowImportPNR);
    //segments colors
    const FlightSegmentsBackgroundColor = [
        '#ffc107',//on hold-yellow
        '#0d6efd',//booking request-blue
        '#198754',//booked-green
        '#e75858',//canceled-red
        '#FF5E0E'//Modfied-orange
    ]

    const FlightSegmentsBorderColor = [
        '#ffc107',
        '#0d6efd',
        '#198754',
        '#e75858',
        '#FF5E0E'
    ]

    const HotelSegmentsBackgroundColor = [
        '#198754',
        '#0d6efd',
        '#e75858',
    ]

    const HotelSegmentsBorderColor = [
        '#198754',
        '#0d6efd',
        '#e75858',
    ]

    //revenue colors

    const FlightRevenueBackgroundColor = [
        '#ffc107',//on hold-yellow
        '#0d6efd',//booking request-blue
        '#198754',//booked-green
        '#590248',//service-orange   
        '#8b3eea',//Transaction Amount -orange
        '#06224e'//Markup -Navy blue
    ]

    const FlightRevenueBorderColor = [
        '#ffc107',
        '#0d6efd',
        '#198754',
        '#590248',
        '#8b3eea',
        '#06224e'
    ]

    const HotelRevenueBackgroundColor = [
        '#198754',
        '#0d6efd',
        '#590248',
        '#8b3eea',
        '#06224e'
    ]

    const HotelRevenueBorderColor = [
        '#198754',
        '#0d6efd',
        '#590248',
        '#8b3eea',
        '#06224e'
    ]

    let FlightSegmentLabel = [];
    let FlightSegmentdata = [];
    if (props.reportJSON[0].segments.filter(x => x.service === "FL").length > 0) {
        FlightSegmentLabel = AllowImportPNR ? ['On Hold', 'Booking Request', 'Booked', 'Canceled', 'Modified'] : ['On Hold', 'Booking Request', 'Booked', 'Canceled'];
        FlightSegmentdata = AllowImportPNR ? [props.reportJSON[0].segments.filter(x => x.service === "FL")[0].booked, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].sendRequest, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].ticketed, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].cancelled, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].modified] : [props.reportJSON[0].segments.filter(x => x.service === "FL")[0].booked, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].sendRequest, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].ticketed, props.reportJSON[0].segments.filter(x => x.service === "FL")[0].cancelled];
    }

    let HotelSegmentLabel = [];
    let HotelSegmentdata = [];
    if (props.reportJSON[0].segments.filter(x => x.service === "HT").length > 0) {
        HotelSegmentLabel = ['Booked', 'Booking Request', 'Canceled'];
        HotelSegmentdata = [props.reportJSON[0].segments.filter(x => x.service === "HT")[0].booked, props.reportJSON[0].segments.filter(x => x.service === "HT")[0].sendRequest, props.reportJSON[0].segments.filter(x => x.service === "HT")[0].cancelled];
    }

    let FlightRevenueLabel = [];
    let FlightRevenuedata = [];
    if (props.reportJSON[0].revenue.filter(x => x.service === "FL").length > 0) {
        FlightRevenueLabel = getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? ['On Hold', 'Booking Request', 'Booked', 'Service Fee', 'Txn. Charges', 'Markup'] : ['On Hold', 'Booking Request', 'Booked', 'Txn. Charges', 'Markup'];
        FlightRevenuedata = getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? [props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].booked, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].sendRequest, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].ticketed, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].serviceFee, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].airlineTransactionAmount, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].markup] : [props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].booked, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].sendRequest, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].ticketed, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].airlineTransactionAmount, props.reportJSON[0].revenue.filter(x => x.service === "FL")[0].markup];
    }

    let HotelRevenueLabel = [];
    let HotelRevenuedata = [];
    if (props.reportJSON[0].revenue.filter(x => x.service === "HT").length > 0) {
        HotelRevenueLabel = getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? ['Booked', 'Booking Request', 'Service Fee', 'Txn. Charges', 'Markup'] : ['Booked', 'Booking Request', 'Txn. Charges', 'Markup'];
        HotelRevenuedata = getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? [props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].booked, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].sendRequest, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].serviceFee, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].airlineTransactionAmount, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].markup] : [props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].booked, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].sendRequest, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].airlineTransactionAmount, props.reportJSON[0].revenue.filter(x => x.service === "HT")[0].markup];
    }

    function salesTableChangeHandler() {
        document.querySelector('#salesChartTab').classList.remove('active');
        document.querySelector('#salesTableTab').classList.add('active');
        document.querySelector('#btnSalesChart').classList.remove('active');
        document.querySelector('#btnSalesTable').classList.add('active');
    }

    return <Fragment>
        <div className="col-md-12 ">
            {
                props.reportType === 'chart' &&
                <div className='row'>
                    <div className='col-md-12'>
                        <h3 className='text-center mt-2 h4 text-primary'> Segments </h3>
                        <hr className='my-1'></hr>
                    </div>

                    {
                        FlightSegmentdata.length > 0 &&
                        <div className='col-md-6 mt-3'>
                            <table className='mx-auto'>
                                <tr>
                                    <td align={'center'}> <h4 className='mb-2 text-black'>{FlightSegmentdata.length > 0 ? ' Flight' : ''}</h4></td>
                                </tr>
                                <tr>
                                    <td onClick={salesTableChangeHandler}>
                                        <PieChart graphType='segment' labels={FlightSegmentLabel}
                                            data={FlightSegmentdata}
                                            BackgroundColor={FlightSegmentsBackgroundColor}
                                            BorderColor={FlightSegmentsBorderColor}
                                        ></PieChart>
                                    </td>
                                </tr>
                            </table>


                        </div>
                    }

                    {
                        HotelSegmentdata.length > 0 &&
                        <div className='col-md-6 mt-3'>
                            <table className='mx-auto'>
                                <tr>
                                    <td align={'center'}> <h4 className='mb-2 text-black'>{HotelSegmentdata.length > 0 ? ' Hotel' : ''}</h4></td>
                                </tr>
                                <tr>
                                    <td onClick={salesTableChangeHandler}>
                                        <PieChart graphType='segment' labels={HotelSegmentLabel}
                                            data={HotelSegmentdata}
                                            BackgroundColor={HotelSegmentsBackgroundColor}
                                            BorderColor={HotelSegmentsBorderColor}></PieChart>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    }

                    <div className='col-md-12 mt-3'>
                        <hr className='my-1'></hr>
                        <h3 className='text-center mt-2 h4 text-primary'>  Revenue </h3>
                        <hr className='my-1'></hr>
                    </div>

                    {
                        FlightRevenuedata.length > 0 &&
                        <div className='col-md-6 mt-3'>
                            <table className='mx-auto'>
                                <tr>
                                    <td align={'center'}> <h4 className='mb-2 text-black'>{FlightRevenuedata.length > 0 ? ' Flight' : ''}</h4></td>
                                </tr>
                                <tr>
                                    <td onClick={salesTableChangeHandler}>
                                        <PieChart graphType='revenue' labels={FlightRevenueLabel}
                                            data={FlightRevenuedata}
                                            BackgroundColor={FlightRevenueBackgroundColor}
                                            BorderColor={FlightRevenueBorderColor}></PieChart>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    }

                    {
                        HotelRevenuedata.length > 0 &&
                        <div className='col-md-6 mt-3'>
                            <table className='mx-auto'>
                                <tr>
                                    <td align={'center'}> <h4 className='mb-2 text-black'>{HotelRevenuedata.length > 0 ? ' Hotel' : ''}</h4></td>
                                </tr>
                                <tr>
                                    <td onClick={salesTableChangeHandler}>
                                        <PieChart graphType='revenue' labels={HotelRevenueLabel}
                                            data={HotelRevenuedata}
                                            BackgroundColor={HotelRevenueBackgroundColor}
                                            BorderColor={HotelRevenueBorderColor}></PieChart>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    }

                </div>
            }

            {
                props.reportType === 'table' &&
                <div className=''>
                    <div className='row'>
                        <div className='col-md-12 table-responsive mt-4 table-head-bg last-table-title'>
                            <div>
                                <h4 className='mb-2'>Segments</h4>
                            </div>

                            <table className="table table-bordered">

                                <thead>
                                    <tr>
                                        <th scope='col' >Service</th>
                                        <th scope="col" className='text-end'>On Hold</th>
                                        <th scope="col" className='text-end'>Booking Request</th>
                                        <th scope="col" className='text-end'>Booked</th>
                                        <th scope="col" className='text-end'>Canceled</th>
                                        {AllowImportPNR && <th scope="col" className='text-end'>Modified</th>}
                                        <th scope="col" className='text-end'>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.reportJSON[0].segments.map((reportJSON) =>
                                        <SegmentItem key={reportJSON.service}
                                            id={reportJSON.service}
                                            reportJSON={reportJSON}
                                            AllowImportPNR={AllowImportPNR}></SegmentItem>)}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-12 table-responsive mt-4 table-head-bg last-table-title'>
                            <div>
                                <h4 className='mb-2'>Revenue</h4>
                            </div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope='col'>Service</th>
                                        <th scope="col" className='text-center'>Currency</th>
                                        <th scope="col" className='text-end'>On Hold</th>
                                        <th scope="col" className='text-end'>Booking Request</th>
                                        <th scope="col" className='text-end'>Booked</th>
                                        <th scope="col" className='text-end'>Canceled</th>
                                        {AllowImportPNR && <th scope="col" className='text-end'>Modified</th>}
                                        <th scope="col" className='text-end'>Net</th>
                                        {/* <th scope="col" className='text-end'>Equiv.Net</th> */}
                                        {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <th scope="col" className='text-end'>Service Fee</th>}
                                        <th scope="col" className='text-end'>Markup</th>
                                        <th scope="col" className='text-end'>Txn. Charges</th>
                                        {/* <th scope="col" className='text-end'>Equiv. Service Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.reportJSON[0].revenue.map((reportJSON) =>
                                        <RevenueItem key={reportJSON.service}
                                            id={reportJSON.service}
                                            reportJSON={reportJSON}
                                            AllowImportPNR={AllowImportPNR}></RevenueItem>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </div>
    </Fragment>

};

export default SalesList;