import { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import CreditLimitItem from './CreditLimitItem';
import { sortData } from '../../../../utils';

const CreditLimitList = (props) => {
  const { t } = useTranslation(['translation.CreditLimit']);
  const history = useHistory();
  const location = useLocation();  
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';

  const sortedCreditLimit = sortData(props.creditLimit,'currencyName', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };

useEffect(()=>{
  history.push({
    pathname: '/SearchCreditLimit',
    search: `?sort=${('asc')}`
  })
},[])

  return (
    <Fragment>
     
      <div id="sortByText" className="container-fluid mb-3 list-view my-3">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "30px" }}>
            <div className='row mb-3'>
              <div className="col-md-6"></div>
              <div className='col-md-2 my-auto text-muted text-left'>
                <div className="float-lg-end float-md-end">Sort By</div>
              </div>
              <div className="col-md-4 form-group">
                <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                  <option>{t('sortAirportOptionAsc')}</option>
                  <option>{t('sortAirportOptionDesc')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {sortedCreditLimit.map((creditLimit) => (
          <CreditLimitItem 
            key={creditLimit.id}
            id={creditLimit.id}
            currencyCode={creditLimit.currencyCode}
            currencyName={creditLimit.currencyName}
            amount={creditLimit.amount}           
          />
        ))}

      </div>
    </Fragment>
  );
};

export default CreditLimitList
