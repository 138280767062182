import { Fragment } from 'react';
import { removeFirstAndLastSpecialChar, removeHtmlTags } from '../../../utils/CommonFunction';


const PropertyAmenities = (props) => {
    let foodAndDrink = "";
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining') {
                foodAndDrink = result.text.value;
            }
        });
    }

    let foodAndDrinks = "";
    let showFoodAndDrink = [];
    if (foodAndDrink !== undefined && foodAndDrink !== '') {
        foodAndDrinks = foodAndDrink.split(/\r?\n/);
        if (foodAndDrinks !== '') {
            foodAndDrinks.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showFoodAndDrink.push(<div className='py-1'>{item}</div>);

                }
            });
        } else {
            showFoodAndDrink.push(<div className='py-1'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{removeHtmlTags(removeFirstAndLastSpecialChar(foodAndDrink))}</div>)

        }

    }
    let service = '';
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Services') {
                service = result.text.value;
            }
        });
    }

    let services;
    if (service !== undefined) {
        services = service.split(/\r?\n/);
    }
    ;

    let facilities;

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Facilities') {
                facilities = result.text.value;
            }
        });
    }

    let facility = "";
    let firstArray = [];
    let secondArray = [];
    if (facilities !== undefined) {
        facility = facilities.split(/\r?\n/);

        if (facility.length > 0) {
            if (facility.length % 2 === 0) {
                firstArray = facility.slice(0, facility.length / 2);
                secondArray = facility.slice(facility.length / 2);

            } else {
                firstArray = facility.slice(0, Math.floor(facility.length / 2) + 1)
                secondArray = facility.slice(Math.floor(facility.length / 2) + 1);
            }
        }
    }
    return (<Fragment>

        <div className="tour_details_boxed mt-2" >

            <h3 className="heading_theme">Hotel Details</h3>
            <div className='row'>
                {foodAndDrinks !== '' && <div className='col-md-6'>
                    <h5 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Food and drink</h5>
                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                <div className='row'>
                                    {showFoodAndDrink}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {service !== '' && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Services</h4>

                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                <div>
                                    {services !== '' && services.map(item => {
                                        let service = removeHtmlTags(removeFirstAndLastSpecialChar(item))
                                        if (service !== '') {

                                            return <div className='py-1 col-md-12'><i className="fas fa-check  py-1 me-2 text-sky-blue"></i>{service}</div>
                                        }

                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
            {facility !== '' && <div className='row'>
                <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Facilities</h4>
                <div className='col-md-6'>
                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                <div className='row'>
                                    {facility !== '' && firstArray.map(item => {
                                        let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                        if (ele !== '') {
                                            return <div className='py-1 col-md-12'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{ele}</div>
                                        }

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                <div className='row'>
                                    {facility !== '' && secondArray.map(item => {
                                        let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                        if (ele !== '') {
                                            return <div className='py-1 col-md-12'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{ele}</div>
                                        }

                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <span id='location_section'></span>

        </div>
        <div> </div>

    </Fragment>)
}

export default PropertyAmenities;