import MyTripsItem from "./MyTripsItem";
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { sortMyTripsResult } from '../../../utils';

const MyTripsList = (props) => {
    const { t } = useTranslation(['translation.MyTrips']);
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [sortedMyTrips, setSortedMyTrips] = useState([]);
    const isSortingAscending = queryParams.get('sort') === 'asc';

    const changeSortingHandler = (event) => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    }
    useEffect(() => {
        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });

        if (sortedMyTrips.length === 0) {
            document.querySelector("#sortBy").selectedIndex = 1;
        }
        setSortedMyTrips(sortMyTripsResult(props.MyTrips, document.querySelector("#sortBy").selectedIndex));
    }, [isSortingAscending])


    return (<Fragment>
        <div className="container-fluid mb-3 list-view my-3">
            <div className="row">
                <div className="col-md-6"></div>
                <div className='col-md-2 my-auto text-muted text-left'>
                    <div className="float-lg-end float-md-end">Sort By</div>
                </div>
                <div className="col-md-4  alighn-right">
                    <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                        <option>{t('bookingDateAsc')}</option>
                        <option>{t('bookingDateDsc')}</option>
                        <option>{t('firstNameAsc')}</option>
                        <option>{t('firstNameDsc')}</option>
                        <option>{t('lastNameAsc')}</option>
                        <option>{t('lastNameDsc')}</option>
                    </select>
                </div>
            </div>
         {sortedMyTrips.map((MyTrips) => (

                <MyTripsItem
                    key={MyTrips.id}
                    id={MyTrips.id}
                    product={MyTrips.product}
                    bookDate={MyTrips.bookDate}
                    referenceNumber={MyTrips.referenceNumber}
                    officeName={MyTrips.officeName}
                    serviceCode={MyTrips.serviceCode}
                    serviceType={MyTrips.serviceType}
                    bookedBy={MyTrips.bookedBy}
                    isCanceled={MyTrips.isCanceled}
                    data={MyTrips}

                />
            ))
            }
        </div>
    </Fragment>)
}

export default MyTripsList;