import { Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RatePlanItem from './RatePlanItem'
import { sortData } from "../../../../utils";

const RatePlanList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.RatePlanAllowed']);
    const queryParams = new URLSearchParams(location.search);
    const isSortingAscending = queryParams.get('sort') === 'asc';
    const sortedCompanyName = sortData(props.ratePlanAllowed, 'companyName', isSortingAscending);
    

    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };
    useEffect(() => {

        window.scrollTo({
            top: 200,
            behavior: "smooth",
        });
        history.push({
            pathname: '/SearchRatePlanAllowed',
            search: `?sort=${('asc')}`
        })
    }, [])

    return <Fragment>
    <div className="container-fluid mb-3 list-view">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
          <div className='row mb-3'>
            <div className="col-md-6"></div>
            <div className='col-md-2 my-auto text-muted text-left'>
              <div className="float-lg-end float-md-end">{t('sortBy')}</div>
            </div>
            <div className="col-md-4 form-group">
              <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                <option>{t('sortcompanyNameDsc')}</option>
                <option>{t('sortCompanyNameAsc')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    
      {sortedCompanyName.map((rate) => (
        
        <RatePlanItem
          key={rate.loginEmailID+rate.officeID}
          id={rate.id}
          companyName={rate.companyName}
          officeID={rate.officeID}
          loginEmailID={rate.loginEmailID}
          

        />
      ))}
      </div>
      </Fragment>

};
export default RatePlanList;