import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import useHttp from '../../../../services/use-http'
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { searchCommonList } from '../../../../services/Office-API'
const UserFriendlyForm = (props) => {
    const { t } = useTranslation(['translation.UserFriendly']);
    const [module, setModule] = useState('');
    const [functionalties, setfunctionalties] = useState('');
    const [modulelabel, setModulelabel] = useState('');
    const [functionaltieslabel, setfunctionaltieslabel] = useState('');
    const errorInputRef = useRef('');
    const friendlyMessageInputRef = useRef('');
    const [errorInputHasError, setErrorInputHasError] = useState(false);
    const [errorInputInvalidHasError, setErrorInvalidInputHasError] = useState(false);
    const [friendlyMessageInputHasError, setUpdateAmountInputHasError] = useState(false);
    const [friendlyMessageInputInvalidHasError, setFriendlyMessageInvalidInputHasError] = useState(false);
    const [moduleInputHasError, setModuleInputHasError] = useState(false);
    const [functionalitiesInputHasError, setFunctionalitiesInputHasError] = useState(false);
    const [iSDisabled, setiSDisabledDDL] = useState(false);
    const { sendRequest: sendRequestCommonListModule, data: loadedModule } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListFunctinlites, data: loadedFunctinlites } = useHttp(searchCommonList);

    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        console.log(isEditMode)
        if (props.loadedProfileDetails.length !== 0) {
            errorInputRef.current.value = props.loadedProfileDetails.currentMessage;
            friendlyMessageInputRef.current.value = props.loadedProfileDetails.friendlyMessage;
            setModule(props.loadedProfileDetails.moduleCode)
            setfunctionalties(props.loadedProfileDetails.functionlityCode)
            setModulelabel(props.loadedProfileDetails.module)
            setfunctionaltieslabel(props.loadedProfileDetails.functionlityName)
            setiSDisabledDDL(false);
        }
        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Message';
            document.getElementById("friendly").readOnly = true;
            document.getElementById("error").readOnly = true;
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            setiSDisabledDDL(true);
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Message';
            document.getElementById("friendly").readOnly = false;
            document.getElementById("error").readOnly = false;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL(false);
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
        }
        else if (props.loadedProfileDetails.length === 0) {
            document.getElementById("friendly").readOnly = false;
            document.getElementById("error").readOnly = false;
            document.getElementById("btnNew").style.display = "none";
            document.getElementById('pageTitle').innerHTML = ' New Message';
            sessionStorage.setItem('isEditable', '100');
            setiSDisabledDDL(false);
        }

    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails.currCode
    ]);



    function onBlurCheck(event) {
        event.preventDefault();
        if (module === '') {
            setModuleInputHasError(true);
            return;
        } else {
            setModuleInputHasError(false);
        }
        if (functionalties === '') {
            setFunctionalitiesInputHasError(true);
            return;
        } else {
            setFunctionalitiesInputHasError(false);
        }
        if (errorInputRef.current.value.trim() === '') {
            errorInputRef.current.focus();
            setErrorInputHasError(true);
            setErrorInvalidInputHasError(false);
            return;
        }
        else {
            setErrorInputHasError(false);
        }
        if (friendlyMessageInputRef.current.value.trim() === '') {
            friendlyMessageInputRef.current.focus();
            setFunctionalitiesInputHasError(true);
            setFriendlyMessageInvalidInputHasError(false);
            return;
        }
        else {
            setFunctionalitiesInputHasError(false);
        }
    }
    function submitFormHandler(event) {
        event.preventDefault();
        if (module === '') {
            setModuleInputHasError(true);
            return;
        } else {
            setModuleInputHasError(false);
        }
        if (functionalties === '') {
            setFunctionalitiesInputHasError(true);
            return;
        } else {
            setFunctionalitiesInputHasError(false);
        }
        if (errorInputRef.current.value.trim() === '') {
            errorInputRef.current.focus();
            setErrorInputHasError(true);
            setErrorInvalidInputHasError(false);
            return;
        }
        else {
            setErrorInputHasError(false);
        }
        if (friendlyMessageInputRef.current.value.trim() === '') {
            friendlyMessageInputRef.current.focus();
            setFunctionalitiesInputHasError(true);
            setFriendlyMessageInvalidInputHasError(false);
            return;
        }
        else {
            setFunctionalitiesInputHasError(false);
        }
        let ID;
        let isSubtracted = false;
        if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
            ID = "";
        }
        else {
            ID = props.loadedProfileDetails.id;
        }
        props.onAddCreditLimit({
            id: ID,
            module: modulelabel,
            moduleCode: module,
            // createdDate: "2023-07-08T11:52:38.636Z",
            functionlityName: functionaltieslabel,
            functionlityCode: functionalties,
            currentMessage: errorInputRef.current.value,
            friendlyMessage: friendlyMessageInputRef.current.value,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            iPAddress: localStorage.getItem("IPAddress")
        });
    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdateUserFriendly')
            history.go()
        }

        else if (event.target.id === 'btnReset') {
            if (props.loadedProfileDetails.length === 0) {
                history.go('/UpdateUserFriendly')
            }
            else {
                history.go(`/UpdateUserFriendly/${props.loadedProfileDetails.id}`)
            }
        }
    }
    const history = useHistory();

    const moduleChangeHandler = (event) => {
        setModulelabel(event.label);
        setModule(event.value);
        setModuleInputHasError(false);
    };
    const functinlitesChangeHandler = (event) => {
        setfunctionaltieslabel(event.label);
        setfunctionalties(event.value);
        setFunctionalitiesInputHasError(false);
    };

    useEffect(() => {

        const onSearchCommonListCat = ({
            methodName: "Module",
            methodCode: "MOD",
            listID: "",
            listValue: "",
            subListID: ""
        });
        sendRequestCommonListModule(onSearchCommonListCat);
        const onSearchCommonListSubCat = ({
            methodName: "",
            methodCode: "FUNS",
            listID: "",
            listValue: "",
            subListID: module
        });
        if (module !== '') {
            sendRequestCommonListFunctinlites(onSearchCommonListSubCat);
        }

    }, [sendRequestCommonListModule,
        sendRequestCommonListFunctinlites, module
    ]);

    let moduleOptions = []
    let moduleArrayData = [];
    for (let i in loadedModule) {
        if (loadedModule['errors'].status === 'FALSE') {
            if (i === 'data') {
                moduleArrayData = loadedModule[i];
            }
        }
    }
    for (let index = 0; index < moduleArrayData.length; index++) {
        moduleOptions = [...moduleOptions, { value: moduleArrayData[index].listID, label: moduleArrayData[index].listValue }]
    }
    let functinlitesOptions = []
    let functinlitesArrayData = [];
    for (let i in loadedFunctinlites) {
        if (loadedFunctinlites['errors'].status === 'FALSE') {
            if (i === 'data') {
                functinlitesArrayData = loadedFunctinlites[i];
            }
        }
    }

    for (let index = 0; index < functinlitesArrayData.length; index++) {
        functinlitesOptions = [...functinlitesOptions, { value: functinlitesArrayData[index].listID, label: functinlitesArrayData[index].listValue }]
    }
    return (
        <Wrapper>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>

                                                        <form className='form'>

                                                            <div className='row'>
                                                                <div className='col-md-6 col-sm-6'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Currency'>Module<span className="error-icon"> *</span></label>
                                                                        <Dropdown onChange={moduleChangeHandler}
                                                                            id='currency'
                                                                            value={moduleOptions.filter(option => option.value === module)}
                                                                            options={moduleOptions}
                                                                            textField="currName"
                                                                            dataItemKey="currCode"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />}
                                                                            controlClassName='form-control control form-select bg_input'
                                                                            placeholder="Please Select"
                                                                            isDisabled={iSDisabled} />
                                                                        {moduleInputHasError &&
                                                                            <p className="error-text">Module is required.</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 col-sm-6'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Currency'>Functionality<span className="error-icon"> *</span></label>
                                                                        <Dropdown onChange={functinlitesChangeHandler}
                                                                            id='currency'
                                                                            value={functinlitesOptions.filter(option => option.value === functionalties)}
                                                                            options={functinlitesOptions}
                                                                            textField="currName"
                                                                            dataItemKey="currCode"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />}
                                                                            controlClassName='form-control control form-select bg_input'
                                                                            placeholder="Please Select"
                                                                            isDisabled={iSDisabled} />
                                                                        {functionalitiesInputHasError &&
                                                                            <p className="error-text">Functionality is required.</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>

                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='error'>Error Message<span className="error-icon"> *</span></label>
                                                                        <input maxLength="200" type='text' className="form-control form-control-sm" id='error' ref={errorInputRef}
                                                                            onChange={onBlurCheck} />
                                                                        <div className="h6 text-primary col-lg-12 mt-2"> <span className="text-black fw-bold">Note : </span>  Enter "DEFAULT" for define common error message.</div>
                                                                        {errorInputHasError &&
                                                                            <p className="error-text">Error message is required.</p>
                                                                        }
                                                                        {errorInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidAmount')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='UpdateAmount'>Friendly Message<span className="error-icon"> *</span></label>
                                                                        <input maxLength="200" type='text' className="form-control form-control-sm" id='friendly' ref={friendlyMessageInputRef}
                                                                            onBlur={onBlurCheck} />
                                                                        {friendlyMessageInputHasError &&
                                                                            <p className="error-text">Friendly message is required.</p>
                                                                        }
                                                                        {friendlyMessageInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidAmount')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/SearchUserFriendly" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href="/SearchUserFriendly" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                        <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>


                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button>
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default UserFriendlyForm
