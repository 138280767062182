import { Fragment } from 'react';
import Dropdown from 'react-dropdown';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'

const serviceOptions = [
    { value: 'Hidden', label: 'Hidden' },
    { value: 'Mandatory', label: 'Mandatory' },
    { value: 'Optional', label: 'Optional' }
];

const CostCenterSearch = () => {
    useDocumentTitle('Search Cost Center');
    return <Fragment>
   {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>


        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">   
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            

                            <div className="card-body">
                                <div className="col-lg-12">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Search Cost Center</label>
                                        </div>
                                        <div className='col-md-6'>
                                            {/* <div className='float-lg-end'><span title="Send a Mail" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fas fa-envelope"></i></span><span title="Take a Print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i></span></div> */}
                                        </div>
                                    </div>

                                    <div className='row '>
                                        <div className='col-md-12'>
                                            <div className='control'>
                                                <div className="form-group">
                                                    <label className='h6 text-primary mb-2'>Name</label>
                                                    <input type="text" className="form-control bg_input"  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-none'>
                                        <div className='col-md-6'>
                                            <div className='control'>
                                                <div className="form-group">
                                                    <label className='h6 text-primary mb-2'>Name <span class="error-icon"> *</span></label>
                                                    <input type="text" className="form-control bg_input"  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='control'>
                                                <div className="form-group">
                                                    <label className='h6 text-primary mb-2'>Code</label>
                                                    <input type="text" className="form-control bg_input"  />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='col-md-6'>
                                            <div className='control'>
                                                <div className="form-group">
                                                    <label className='h6 text-primary mb-2'>Meeting Details <span class="error-icon"> *</span></label>
                                                    <Dropdown
                                                    options={serviceOptions}
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />}
                                                    controlClassName='form-control control form-select bg_input'
                                                    placeholder="Please Select" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='control mt-4'>
                                                <div className="form-group">
                                                    <div class="">
                                                        <div class="text-primary"><input class="form-check-input tandc-options" type="checkbox" id=""/> Allow Family Booking </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className='col-md-12 top_form_search_button mt-3'>
                                            <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' >Reset</button>
                                            
                                            <a className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'  href='/CostCenterNew'>
                                                New
                                            </a>

                                            <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                                    <div className="col-lg-12 mb-1">
                                        <div className="card text-dark card-bg-blue mb-2">
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className='col-md-9 mt-1'>
                                                            <h6 className="card-title fw-bold text-primary mt-1">
                                                                Software
                                                            </h6>
                                                        </div>
                                                        <div className="col-md-3 my-auto">
                                                            <div className='float-lg-end float-md-end'>
                                                        
                                                                <button className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data bs-toggle="tooltip" data-bs-placement="top" title="Show">
                                                                <i className="fa-sharp fa-solid fa-tv"></i>
                                                                </button>
                                                            
                                                                <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' href='/CostCenterNew'>
                                                                <i className="fa-solid fa-pen"></i>
                                                                </a>
                                                            
                                                                <button  className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title='Delete'>
                                                                <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* <hr className="my-1" /> */}
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-9'>
                                                        
                                                        
                                                            
                                                        </div>
                                                        {/* <div className="col-md-3 my-auto">
                                                            <div className='float-lg-end float-md-end'>
                                                        
                                                                <button className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data bs-toggle="tooltip" data-bs-placement="top" title="Show">
                                                                <i className="fa-sharp fa-solid fa-tv"></i>
                                                                </button>
                                                            
                                                                <a className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' href='/CostCenterNew'>
                                                                <i className="fa-solid fa-pen"></i>
                                                                </a>
                                                            
                                                                <button  className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title='Delete'>
                                                                <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12 mb-1">
                                        <div className="card text-dark card-bg-blue mb-2">
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className='col-md-9 mt-1'>
                                                            <h6 className="card-title fw-bold text-primary mt-1">
                                                               Testing
                                                            </h6>
                                                        </div>
                                                        <div className="col-md-3 my-auto">
                                                            <div className='float-lg-end float-md-end'>
                                                                <button className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data bs-toggle="tooltip" data-bs-placement="top" title="Show">
                                                                <i className="fa-sharp fa-solid fa-tv"></i>
                                                                </button>
                                                            
                                                                <button className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit'>
                                                                <i className="fa-solid fa-pen"></i>
                                                                </button>
                                                            
                                                                <button  className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title='Delete'>
                                                                <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                   
                                                    {/* <hr className="my-1" /> */}
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-9'>
                                                            
                                                        </div>
                                                        {/* <div className="col-md-3 my-auto">
                                                            <div className='float-lg-end float-md-end'>
                                                        
                                                                <button className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data bs-toggle="tooltip" data-bs-placement="top" title="Show">
                                                                <i className="fa-sharp fa-solid fa-tv"></i>
                                                                </button>
                                                            
                                                                <button className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit'>
                                                                <i className="fa-solid fa-pen"></i>
                                                                </button>
                                                            
                                                                <button  className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn" title='Delete'>
                                                                <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
               
        </section>

    </Fragment>

};

export default CostCenterSearch;