import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import Wrapper from '../../../../components/Common/Helpers/Wrapper';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';
import { validateEmail } from '../../../../utils/Validation';
import { useState, useRef, useEffect } from 'react';
const WalletForm = (props) => {
    const { t } = useTranslation(['translation.Wallet']);


    //useRef
    const companyNameInputRef = useRef('');
    const loginEmailIdInputRef = useRef('');
    const currentWalletAmountInputRef = useRef('');
    const currencyInputRef = useRef('');
    const activeInputRef = useRef('');
    const [active, setActive] = useState(false);
    const updateWalletAmountInputRef = useRef('');
    const remarksInputRef = useRef('');
    const thresholdInputRef = useRef('')
    const thresholdEmailInputRef = useRef('');

    //USER STORY 2607: Enable/Disable UpdateWallet Field based on status.
    const [isUpdateWalletDisable, setIsUpdateWalletDisable] = useState(false);

    //error 
    const [updateAmountInputHasError, setUpdateAmountInputHasError] = useState(false);
    const [updateAmountInputInvalidHasError, setUpdateAmountInvalidInputHasError] = useState(false);
    const [remarksInputInvalidHasError, setRemarksInputInvalidHasError] = useState(false);
    const [thresholdInputInvalidHasError, setThresholdInvalidInputHasError] = useState(false);
    const [thresholdEmailInputInvalidHasError, setThresholdEmailInvalidInputHasError] = useState(false);
    const [thresholdEmailInputHasError, setThresholdEmailInputHasEroor] = useState(false);
    const [thresholdInputHasError, setThresholdInputHasError] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
    })
    //USER STORY 2607: Enable/Disable UpdateWallet Field based on status.
    useEffect(() => {

        if (active) {
            setIsUpdateWalletDisable(false);
        }
        else {
            setIsUpdateWalletDisable(true);
        }

    }, [active])

    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedProfileDetails.length !== 0) {
            companyNameInputRef.current.value = props.loadedProfileDetails[0].companyName !== undefined ? props.loadedProfileDetails[0].companyName : "";
            loginEmailIdInputRef.current.value = props.loadedProfileDetails[0].loginEmailID !== undefined ? props.loadedProfileDetails[0].loginEmailID : "";
            currencyInputRef.current.value = props.loadedProfileDetails[0].currencyName !== undefined && props.loadedProfileDetails[0].currencyCode !== undefined ? props.loadedProfileDetails[0].currencyName + "(" + props.loadedProfileDetails[0].currencyCode + ")" : props.loadedProfileDetails[0].currencyCode !== undefined ? props.loadedProfileDetails[0].currencyCode : "";
            if (props.loadedProfileDetails[0].isActive !== undefined) {
                setActive(props.loadedProfileDetails[0].isActive)
            }

            currentWalletAmountInputRef.current.value = props.loadedProfileDetails[0].amount !== undefined ? props.loadedProfileDetails[0].amount : "0";
            thresholdInputRef.current.value = props.loadedProfileDetails[0].thresholdAmount !== undefined && props.loadedProfileDetails[0].thresholdAmount !== 0 ? props.loadedProfileDetails[0].thresholdAmount : "";
            thresholdEmailInputRef.current.value = props.loadedProfileDetails[0].thresholdEmailId !== undefined ? props.loadedProfileDetails[0].thresholdEmailId : "";



        }
        if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Wallet';
            document.getElementById("CompanyName").readOnly = true;
            document.getElementById("loginEmailId").readOnly = true;
            document.querySelector("#currentWalletAmount").readOnly = true;
            document.querySelector("#updateWalletAmount").readOnly = true;
            document.querySelector("#currency").readOnly = true;
            document.querySelector("#remarks").readOnly = true;
            document.querySelector("#threshold").readOnly = true;
            document.querySelector("#thresholdAlertEmail").readOnly = true;
            document.querySelector("#active").disabled = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Wallet';
            document.getElementById("CompanyName").readOnly = true;
            document.getElementById("updateWalletAmount").focus();
            document.getElementById("loginEmailId").readOnly = true;
            document.querySelector("#currentWalletAmount").readOnly = true;
            document.querySelector("#currency").readOnly = true;
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementById("requiredIndicator").style.display = "";
        }

        if (props.loadedProfileDetails.length === 0) {
            document.getElementById('pageTitle').innerHTML = ' New Wallet';
            sessionStorage.setItem('isEditable', '100');
        }

    }, [props.loadedProfileDetails.length,
    props.loadedProfileDetails[0].companyName,
    props.loadedProfileDetails[0].loginEmailID,
    props.loadedProfileDetails[0].isActive,
    props.loadedProfileDetails[0].amount,
    props.loadedProfileDetails[0].remarks,
    props.loadedProfileDetails[0].thresholdAmount,
    props.loadedProfileDetails[0].thresholdEmailId,
    props.loadedProfileDetails[0].currencyName,
    props.loadedProfileDetails[0].currencyCode,

    ]);


    //ChangeHandler
    function activeChangeHandler() {
        if (active) {
            setActive(false)
            setIsUpdateWalletDisable(true)
            setUpdateAmountInputHasError(false)
            setUpdateAmountInvalidInputHasError(false)
            updateWalletAmountInputRef.current.value = "";
        } else {
            setActive(true);
            setIsUpdateWalletDisable(false);

        }


    }

    function changeLocation() {
        window.location.reload(true);
    }
    function onBlurCheck(event) {
        event.preventDefault();
        var pattern = /^[a-zA-Z0-9().'\-,\s]+$/;
        let updateAmount = updateWalletAmountInputRef.current.value.trim()
        let mail = thresholdEmailInputRef.current.value.trim()
        let cleanMail;
        if (mail !== '') {
            cleanMail = mail.replace(/[\r\n]/gm, '');
        }
        if (active) {
            if (updateAmount === '') {
                setUpdateAmountInputHasError(true);
                setUpdateAmountInvalidInputHasError(false);
                return;
            } else {
                setUpdateAmountInputHasError(false);
            }
            if (updateAmount === '0') {
                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            } else {
                setUpdateAmountInvalidInputHasError(false);
            }

            if (!updateAmount.match('^-?[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {

                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            } else {
                setUpdateAmountInvalidInputHasError(false);
            }
            if (updateAmount !== '' && updateAmount.length > 1) {
                let amount = updateAmount.slice(1);
                for (let char of amount) {
                    if (char === '-') {
                        setUpdateAmountInvalidInputHasError(true);
                        setUpdateAmountInputHasError(false);
                        return;
                    } else {
                        setUpdateAmountInvalidInputHasError(false);
                    }
                }

            }

        }


        if (remarksInputRef.current.value.trim() !== '') {
            if (pattern.test(remarksInputRef.current.value)) {
                setRemarksInputInvalidHasError(false);
            } else {
                setRemarksInputInvalidHasError(true);
                return;

            }
        }
        if (thresholdInputRef.current.value.trim() !== '' && !thresholdInputRef.current.value.match('^[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
            setThresholdInvalidInputHasError(true);
            setThresholdInputHasError(false);
            return;
        } else {
            setThresholdInvalidInputHasError(false);
        }
        if (thresholdInputRef.current.value.trim() !== '' && mail === '') {
            setThresholdEmailInputHasEroor(true)
            setThresholdEmailInvalidInputHasError(false);
            return;
        } else {
            setThresholdEmailInputHasEroor(false)
        }
        if (mail !== '' && mail.includes(';')) {
            setThresholdEmailInvalidInputHasError(true);
            setThresholdEmailInputHasEroor(false)
            return;
        } else {
            setThresholdEmailInvalidInputHasError(false);
        }
        if (mail !== '') {
            const mailArray = cleanMail.split(',')
            for (let email of mailArray) {

                if (email !== '' && validateEmail(email) === null) {
                    setThresholdEmailInvalidInputHasError(true);
                    return;

                } else {

                    setThresholdEmailInvalidInputHasError(false);
                }
            }

        }

        if (mail !== '' && thresholdInputRef.current.value.trim() === '') {
            setThresholdInputHasError(true);
            setThresholdInvalidInputHasError(false);
            return;
        } else {
            setThresholdInputHasError(false);
        }

    }
    function submitFormHandler(event) {
        event.preventDefault();

        let isSubtracted = false;
        var pattern = /^[a-zA-Z0-9().'\-,\s]+$/;
        let mail = thresholdEmailInputRef.current.value.trim()
        let cleanMail;
        if (mail !== '') {
            cleanMail = mail.replace(/[\r\n]/gm, '');
        }
        let updateAmount = updateWalletAmountInputRef.current.value.trim()
        if (active) {
            if (updateWalletAmountInputRef.current.value.trim() === '') {
                updateWalletAmountInputRef.current.focus();
                window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                });
                setUpdateAmountInputHasError(true);
                setUpdateAmountInvalidInputHasError(false);
                return;
            } else {
                setUpdateAmountInputHasError(false);
            }
            if (updateAmount === '0') {
                updateWalletAmountInputRef.current.focus();
                window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                });
                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            } else {
                setUpdateAmountInvalidInputHasError(false);
            }
            if (!updateWalletAmountInputRef.current.value.match('^-?[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
                updateWalletAmountInputRef.current.focus();
                window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                });
                setUpdateAmountInvalidInputHasError(true);
                setUpdateAmountInputHasError(false);
                return;
            } else {
                setUpdateAmountInvalidInputHasError(false);
            }
            if (updateAmount !== '' && updateAmount.length > 1) {
                let amount = updateAmount.slice(1);
                for (let char of amount) {
                    if (char === '-') {
                        updateWalletAmountInputRef.current.focus();
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                        setUpdateAmountInvalidInputHasError(true);
                        setUpdateAmountInputHasError(false);
                        return;
                    } else {
                        setUpdateAmountInvalidInputHasError(false);
                    }
                }

            }

        }





        if (remarksInputRef.current.value.trim() !== '') {
            if (pattern.test(remarksInputRef.current.value)) {
                setRemarksInputInvalidHasError(false);
            } else {
                setRemarksInputInvalidHasError(true);
                remarksInputRef.current.focus();
                return;

            }



        }
        if (thresholdInputRef.current.value.trim() !== '' && !thresholdInputRef.current.value.match('^[0-9]{1,11}(?:\.[0-9]{1,3})?$')) {
            thresholdInputRef.current.focus();
            setThresholdInvalidInputHasError(true);
            setThresholdInputHasError(false);
            return;
        } else {
            setThresholdInvalidInputHasError(false);
        }
        if (thresholdInputRef.current.value.trim() !== '' && mail === '') {
            setThresholdEmailInputHasEroor(true)
            setThresholdEmailInvalidInputHasError(false);
            thresholdEmailInputRef.current.focus();
            return;
        } else {
            setThresholdEmailInputHasEroor(false)
        }

        if (mail !== '' && mail.includes(';')) {
            thresholdEmailInputRef.current.focus();
            setThresholdEmailInvalidInputHasError(true);
            return;
        } else {
            setThresholdEmailInvalidInputHasError(false);
        }
        if (mail !== '') {
            const mailArray = cleanMail.split(',')
            for (let email of mailArray) {
                if (validateEmail(email) === null) {
                    setThresholdEmailInvalidInputHasError(true);
                    thresholdEmailInputRef.current.focus();
                    return;

                } else {

                    setThresholdEmailInvalidInputHasError(false);
                }
            }
        }
        if (mail !== '' && thresholdInputRef.current.value.trim() === '') {
            thresholdInputRef.current.focus();
            setThresholdInputHasError(true);
            setThresholdInvalidInputHasError(false);
            return;
        } else {
            setThresholdInputHasError(false);
        }



        if (updateWalletAmountInputRef.current.value.trim().includes('-')) {
            isSubtracted = true;
        }

        props.onAddWallet({
            id: props.loadedProfileDetails[0].id,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: props.loadedProfileDetails[0].officeID,
            currencyCode: props.loadedProfileDetails[0].currencyCode,
            currencyName: props.loadedProfileDetails[0].currencyName,
            transactionAmount: updateWalletAmountInputRef.current.value !== '' ? updateWalletAmountInputRef.current.value : 0,
            amount: props.loadedProfileDetails[0].amount,
            remarks: remarksInputRef.current.value,
            thresholdAmount: thresholdInputRef.current.value.trim() === '' ? 0 : thresholdInputRef.current.value.trim(),
            thresholdEmail: cleanMail,
            isSubracted: isSubtracted,
            service: 'MS',
            isActive: active

        });

    }
    return (
        <Wrapper>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>

                                                        <form className='form'>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className='control'>
                                                                        <label htmlFor="CompanyName" className="mb-2 h6 text-primary col-lg-12">{t('CompanyName')}</label>
                                                                        <input id="CompanyName"
                                                                            ref={companyNameInputRef}
                                                                            type='text'
                                                                            onBlur={onBlurCheck}
                                                                            className="form-control form-control-sm"
                                                                        >
                                                                        </input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className='control'>
                                                                        <label htmlFor="loginEmailId" className="mb-2 h6 text-primary col-lg-12">{t('loginEmailId')}</label>
                                                                        <input
                                                                            id="loginEmailId"
                                                                            ref={loginEmailIdInputRef}
                                                                            type='text'
                                                                            onBlur={onBlurCheck}
                                                                            className="form-control form-control-sm"
                                                                        >
                                                                        </input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12  my-auto">
                                                                    <div className='control '>
                                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                                            <label htmlFor="active" className="mb-2 h6 text-primary">   {t('active')}</label>
                                                                            <input id="active" className="form-check-input ms-3" type="checkbox" checked={active} onChange={activeChangeHandler} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='control'>
                                                                        <label htmlFor="currency" className="mb-2 h6 text-primary col-lg-12">{t('currency')}</label>
                                                                        <input id="currency"
                                                                            ref={currencyInputRef}
                                                                            type='text'
                                                                            className="form-control form-control-sm"
                                                                        >
                                                                        </input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='control'>
                                                                        <label htmlFor="currentWalletAmount" className="mb-2 h6 text-primary col-lg-12">{t('currentWalletAmount')}</label>
                                                                        <input id="currentWalletAmount"
                                                                            ref={currentWalletAmountInputRef}
                                                                            type='text'
                                                                            className="form-control form-control-sm"
                                                                        >
                                                                        </input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='control'>
                                                                        <label htmlFor="updateWalletAmount" className="mb-2 h6 text-primary col-lg-12">{t('updateWalletAmount')}<span className="error-icon"> *</span></label>
                                                                        <input id="updateWalletAmount"
                                                                            ref={updateWalletAmountInputRef}
                                                                            type='text'
                                                                            onBlur={onBlurCheck}
                                                                            className="form-control form-control-sm"
                                                                            disabled={isUpdateWalletDisable}
                                                                            minLength={1}
                                                                            maxLength={20}
                                                                        >
                                                                        </input>
                                                                        {updateAmountInputHasError && <p className="error-text">{t('updateAmountValidation')}</p>
                                                                        }
                                                                        {updateAmountInputInvalidHasError && <p className="error-text">{t('updateAmountInvalidInputValidation')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className='control'>
                                                                        <label htmlFor="remarks" className="mb-2 h6 text-primary col-lg-12">{t('remarks')}</label>
                                                                        <input id="remarks"
                                                                            ref={remarksInputRef}
                                                                            type='text'
                                                                            onBlur={onBlurCheck}
                                                                            className="form-control form-control-sm"
                                                                            maxLength={300}
                                                                        >
                                                                        </input>
                                                                        {remarksInputInvalidHasError && <p className="error-text">{t('remarksInvalidInputValidation')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className='control'>
                                                                    <label htmlFor="threshold" className="mb-2 h6 text-primary col-lg-12">{t('threshold')}</label>
                                                                    <input id="threshold"
                                                                        ref={thresholdInputRef}
                                                                        type='text'
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                        maxLength={20}
                                                                    >
                                                                    </input>
                                                                    {thresholdInputInvalidHasError && <p className="error-text">{t('thresholdInvalidInputValidation')}</p>
                                                                    }
                                                                    {thresholdInputHasError && <p className="error-text">{t('thresholdInputValidation')}</p>
                                                                    }

                                                                </div>

                                                            </div>
                                                            <div className="control">
                                                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> {t('thresholdAlertEmail')}
                                                                </label>
                                                                <div>
                                                                    <textarea id="thresholdAlertEmail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onBlur={onBlurCheck} ref={thresholdEmailInputRef}
                                                                    >
                                                                    </textarea>
                                                                    {thresholdEmailInputInvalidHasError && <p className="error-text">{t('thresholdEmailInvalidInputValidation')}</p>
                                                                    }
                                                                    {thresholdEmailInputHasError && <p className="error-text">{t('thresholdEmailInputValidation')}</p>
                                                                    }
                                                                    <span id="msgSendMail" className="error-text"></span></div>
                                                                <span className="h6 text-primary  col-lg-12" > <span className="text-black fw-bold">Note : </span>  </span>
                                                                <div className=''>  <span className="h6 text-primary mb-2 col-lg-12" >  1. In case the Wallet amount reaches the specified threshold, an alert will be sent to the specified Email IDs.<br></br>2. Please use commas to separate multiple Email IDs.</span></div>
                                                            </div>
                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/SearchWallet" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href="/SearchWallet" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                        <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );

};
export default WalletForm;