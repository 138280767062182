import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateBookingJSON } from './../../../utils/HotelBookingFunction';
import { toTitleCase } from "../../../utils/CommonFunction";
import { hotelPriceCheck } from '../../../services/Hotel-API'
import useHttp from '../../../services/use-http'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import RoomDetailsPopup from "./RoomDetailsPopup"
import RoomPopup from './RoomPopup';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SINGLEPNRBOOKING } from '../../../navigation/CONSTANTS';
import { CreateBookingJSONShoppingCart } from './../../../utils/ShoppingCartFunction';
import { insertRoom, removeRoom } from '../../../utils/SendRecommendation';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import OutPolicy from '../../../pages/CBT/TravelRequest/Components/OutPolicy';
import PopupModal from '../../../../src/components/Common/Flight/PopupModal';

const Room = (props) => {
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "defaultHotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    const history = useHistory();
    const [roomError, setRoomError] = useState();
    const [roomLimitError, setRoomLimitError] = useState();
    const [errorOutPolicy, setErrorOutPolicy] = useState();

    const { sendRequest, status, data: loadedHotelPriceCheck, error } = useHttp(hotelPriceCheck);

    let hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
    let hotelDetailsResponsePrice = hotelDetailsResponse.data.hotelResults[0].price.filter(a => a.rateKey === props.roomDetails.rateKey);
    let hotelDetailsResponseTaxes = hotelDetailsResponse.data.hotelResults[0].roomRateDetails.filter(a => a.rateKey === props.roomDetails.rateKey);
    let totalTax = 0;
    let totalBaseRate = 0;
    // User Story 3800: Remove space in Room details section
    let noOfSpaces = 0;
    if (hotelDetailsResponse.data.hotelResults[0].numberOfService !== undefined) {
        noOfSpaces = parseInt(hotelDetailsResponse.data.hotelResults[0].numberOfService);
    }

    hotelDetailsResponseTaxes.forEach(item => {
        if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
            totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
            totalBaseRate += item.price.publishedPrice.pricePublished;
        }
        else {
            totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
            totalBaseRate += item.price !== undefined && item.price !== null ? item.price.publishedPrice.pricePublished : 0;
        }
    });

    let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'));
    const [confirm, setConfirm] = useState('');
    const [confirmModel, setConfirmModel] = useState();

    const onBookNow = (id) => {

        if (props.travelReq !== undefined && props.travelReq === 'true') {
            if (hotelDetailsResponsePrice[0].inPolicy !== undefined && hotelDetailsResponsePrice[0].inPolicy === true) {
                let bookingJSON = CreateBookingJSON(props.roomDetails)
                sessionStorage.setItem("HotelBookingDataTravelRequest", JSON.stringify(bookingJSON));
                sessionStorage.removeItem("HotelIntialTotalAmount");

                history.push('/TravelRequest?return=1&service=hotel');
                history.go();
            }
            else if (hotelDetailsResponsePrice[0].inPolicy !== undefined && hotelDetailsResponsePrice[0].inPolicy !== '' && hotelDetailsResponsePrice[0].inPolicy === false) {
                OutPolicyPopup();
            }
            else if (hotelDetailsResponsePrice[0].inPolicy === undefined || hotelDetailsResponsePrice[0].inPolicy === '') {
                OutPolicyPopup();
            }
        }
        else {
            //User Story 1070: Change in Search for Single PNR
            let bookingJSON = "";
            if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                bookingJSON = CreateBookingJSONShoppingCart(props.roomDetails, "HT")
                sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
                sessionStorage.removeItem("HotelIntialTotalAmount");
            }
            else {
                bookingJSON = CreateBookingJSON(props.roomDetails)
                sessionStorage.setItem("HotelBookingData", JSON.stringify(bookingJSON));
                sessionStorage.removeItem("HotelIntialTotalAmount");
            }
            sendRequest(bookingJSON);
        }
    }

    const roomDetails = () => {
        setRoomError({
            title: 'Room Details',
            message: <RoomPopup roomDetails={props.roomDetails} hotelDetailsResponsePrice={hotelDetailsResponsePrice} hotelDetailsResponseTaxes={hotelDetailsResponseTaxes} totalBaseRate={totalBaseRate} totalTax={totalTax} onBookNow={onBookNow} hotelSearchRequestJson={hotelSearchRequestJson} status={status}></RoomPopup>
        });
    }

    const errorHandler = () => {
        setRoomError(null);
        setRoomLimitError(null);
    };

    useEffect(() => {
        if (status === 'completed') {
            if (loadedHotelPriceCheck !== null && loadedHotelPriceCheck.data !== null) {

                if (loadedHotelPriceCheck.data.errors.status === 'TRUE') {
                    setConfirm({
                        title: "Message",
                        message: loadedHotelPriceCheck.data.errors.error.description,
                    });
                }
                else {
                    let hotelBookingJSON = localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(sessionStorage.getItem("HotelBookingData"));
                    hotelBookingJSON.data.bookingKey = loadedHotelPriceCheck.data.bookingKey;

                    if (loadedHotelPriceCheck.data.hotelResults[0].isPriceChange) {
                        hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                            elementHotelResults.price.forEach(elementPrice => {
                                elementPrice.roomPrice = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.roomPrice;
                                elementPrice.publishedPrice.pricePublished = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished;
                                elementPrice.publishedPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.perNight;
                                elementPrice.offeredPrice.priceOffered = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.priceOffered;
                                elementPrice.offeredPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.perNight;
                            });
                        });

                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

                        setConfirmModel({
                            title: "Message",
                            message: `The room price has changed from $ ${loadedHotelPriceCheck.data.hotelResults[0].price[0].publishedPrice.pricePublished} to $ ${loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished}. Do you want to proceed with the new price?`,
                        });
                    } else {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
                        //User Story 1073: Booking Summary for Single PNR
                        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                            history.push(SINGLEPNRBOOKING);
                        }
                        else {
                            history.push(`HotelBookings`);
                        }
                    }
                }
            }
            else {
                setConfirm({
                    title: "Message",
                    message: `Sorry, we have an encountered an issue. Please try again.`,
                });
            }
            return;
        }
    }, [status, history, loadedHotelPriceCheck])

    function okHandler() {
        setConfirm('');
    };

    function okConfirmHandler() {
        setConfirmModel(true);
        //User Story 1073: Booking Summary for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push(SINGLEPNRBOOKING);
        }
        else {
            history.push(`HotelBookings`);
        }
    };

    function cancelHandler() {
        setConfirm(false);
        setConfirmModel(false);
    };

    function getServices(RoomData) {
        let showServices = [];
        if (RoomData.roomDetails !== undefined && RoomData.roomDetails !== null && RoomData.roomDetails.inclusions !== undefined && RoomData.roomDetails.inclusions !== null && (RoomData.roomDetails.inclusions.filter(a => a.name.toString().includes('Internet')) || RoomData.roomDetails.inclusions.filter(a => a.name.toString().includes('internet')))) {
            showServices.push(<p className="card-text text-black"><i className="fa-solid fa-wifi"></i> Free WiFi</p>)
        }
        if (props.roomDetails.roomViewDescription !== undefined && props.roomDetails.roomViewDescription !== undefined && props.roomDetails.roomViewDescription !== null) {
            showServices.push(<p className="card-text text-black"><i className="fa-solid fa-tree-city"></i> {props.roomDetails.roomViewDescription}</p>)
        }
        if (RoomData.roomDetails !== undefined && RoomData.roomDetails !== null && RoomData.roomDetails.bedTypes !== undefined && RoomData.roomDetails.bedTypes !== null) {
            showServices.push(<p className="card-text text-black"><i className="fa-solid fa-bed"></i> {props.roomDetails.roomDetails.bedTypes[0].bedTypeDescription}</p>)
        }
        // User Story 3800: Remove space in Room details section
        noOfSpaces = noOfSpaces - parseInt(showServices.length)
        return showServices;
    }

    function getMealType(Meals) {
        let showMeal = [];
        if (Meals !== null && Meals.length !== 0) {
            if (Meals[0].mealPlan !== undefined && Meals[0].mealPlan && Meals[0].mealPlanDescription !== undefined && Meals[0].mealPlanDescription !== '') {
                showMeal.push(<p className="card-text text-black">  <span className='fw-bold'>Meal Plan:</span> {Meals[0].mealPlanDescription}</p>)
            }
            if (Meals[0].breakfastIncluded !== undefined && Meals[0].breakfastIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>)
            }
            if (Meals[0].dinnerIncluded !== undefined && Meals[0].dinnerIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Dinner Included</p>)
            }

            if (Meals[0].lunchIncluded !== undefined && Meals[0].lunchIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Lunch Included</p>)
            }

        }
        // User Story 3800: Remove space in Room details section
        noOfSpaces = noOfSpaces - parseInt(showMeal.length)
        return showMeal
    }

    function maintainCardHeight(n) {
        let cardHeight = [];
        for (let i = 0; i < n; i++) {
            cardHeight.push(<div>
                <p className="card-text text-black">&nbsp;</p>
            </div>)
        }

        return cardHeight;
    }

    const addHotel = (event, HotelData, RoomData) => {
        let RoomList = []
        if (event.target.checked === true) {
            if (sessionStorage.getItem('RoomDetailJson') !== null) {
                RoomList = JSON.parse(sessionStorage.getItem('RoomDetailJson'));
            }
            if (RoomList.length < 6) {
                insertRoom(HotelData, RoomData)
            } else {
                setRoomLimitError(true);
                document.getElementById('addToProposal' + RoomData.roomID).checked = false
            }
        } else {
            removeRoom(HotelData, RoomData)
        }
    }

    const OutPolicyPopup = () => {
        setErrorOutPolicy({
            title: "Reason",
            message: <OutPolicy ></OutPolicy>,
        });
    }

    const errorHandlerOutPolicy = () => {
        setErrorOutPolicy(null);

        let bookingJSON = CreateBookingJSON(props.roomDetails)
        sessionStorage.setItem("HotelBookingDataTravelRequest", JSON.stringify(bookingJSON));
        sessionStorage.removeItem("HotelIntialTotalAmount");

        history.push('/TravelRequest?return=1&service=hotel');
    };

    const errorHandlerOutPolicyClose = () => {
        setErrorOutPolicy(null);

        // let bookingJSON = CreateBookingJSON(props.roomDetails)
        // sessionStorage.setItem("HotelBookingDataTravelRequest", JSON.stringify(bookingJSON));
        // sessionStorage.removeItem("HotelIntialTotalAmount");

        // history.push('/TravelRequest?return=1');
    };

    let bar = "";
    if ((hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113') || (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined) || (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName !== undefined && hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName)) {
        bar = " |"
    }

    return (<Fragment>
        <ReactTooltip
            anchorId={`PolicyIn${props.roomDetails.roomID}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"In Policy"}
        />
        <ReactTooltip
            anchorId={`PolicyOut${props.roomDetails.roomID}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"Out of Policy"}
        />
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        {confirmModel && (
            <ConfirmationModal
                title={confirmModel.title}
                message={confirmModel.message}
                onOk={okConfirmHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {
            roomError && (
                <RoomDetailsPopup
                    title={roomError.title}
                    message={roomError.message}
                    onConfirm={errorHandler}>

                </RoomDetailsPopup>
            )
        }
        {roomLimitError && <ErrorModal
            title="Message"
            message='You have added the maximum number of rooms allowed in the proposal.'
            onConfirm={errorHandler}
        />}
        {errorOutPolicy && (
            <PopupModal
                title={errorOutPolicy.title}
                message={errorOutPolicy.message}
                onConfirm={errorHandlerOutPolicy}
                onClose={errorHandlerOutPolicyClose}
            ></PopupModal>
        )}

        <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
            <div className="card">
                <span className="btn btn-sm btn-primary more-detail-btnslider" onClick={roomDetails}>
                    <i className="fa-solid fa-up-right-from-square px-1" ></i>
                </span>
                <span className="btn btn-sm btn-primary card-images-btnslider" onClick={roomDetails}>

                    <i className="fas fa-images"></i>
                </span>

                {
                    (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length > 0) &&
                    <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room')[0].imagePath} className="card-img-top  gt-h-240" alt="..." />
                }
                {
                    (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length > 0) &&
                    <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite')[0].imagePath} className="card-img-top  gt-h-240" alt="..." />
                }
                {
                    (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length <= 0) &&

                    <img src={hotelDetailsResponse.data.hotelResults[0].images[0].imagePath} className="card-img-top  gt-h-240" alt="..." />

                }
                {
                    (hotelDetailsResponse.data.hotelResults[0].images === undefined) &&
                    <img src={hotelLogoLink} className="card-img-top  gt-h-240" alt="..." />
                }
                <div className="card-body">
                    {/* <hr></hr> */}
                    <h5 className="card-title text-primary h5">{props.roomDetails.roomTypeName !== undefined ? toTitleCase(props.roomDetails.roomTypeName) : <span>&nbsp;</span>}</h5>
                    <h2 className="card-title h6 card-desc">{toTitleCase(props.roomDetails.roomDescription)}</h2>
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {/* User Story 3800: Remove space in Room details section */}
                    {props.roomDetails !== undefined && getServices(props.roomDetails)}
                    {
                        props.roomDetails.roomDetails !== undefined && props.roomDetails.roomDetails !== null && props.roomDetails.roomDetails.meals !== undefined && props.roomDetails.roomDetails.meals !== null && getMealType(props.roomDetails.roomDetails.meals)
                    }
                    {
                        maintainCardHeight(noOfSpaces)
                    }


                    <br></br>
                    {
                        <div className="col-md-12 text-14px text-muted pb-1">
                            {/* Source: <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'CGN' : 'Sabre'} </span> */}
                            {/* | PCC: <span className="text-primary fw-bold">{hotelDetailsResponsePrice[0].pcc}</span>  */}

                            {getConfigurationByBoolen("ALLOW_COMISSION") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && hotelDetailsResponsePrice[0].agentCommissionType !== undefined && (hotelDetailsResponsePrice[0].agentCommissionType === "Variable" || hotelDetailsResponsePrice[0].agentCommissionType === "Amount") ? 'Comm.:  ' : hotelDetailsResponsePrice[0].agentCommission !== undefined && hotelDetailsResponsePrice[0].agentCommission !== '' ? 'Comm.:  ' : ''}<span
                                className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && hotelDetailsResponsePrice[0].agentCommissionType !== undefined ? hotelDetailsResponsePrice[0].agentCommissionType === 'Percentage' ? hotelDetailsResponsePrice[0].agentCommission !== '' && hotelDetailsResponsePrice[0].agentCommission !== undefined ? Math.abs(hotelDetailsResponsePrice[0].agentCommission) + "% " + bar : '' : hotelDetailsResponsePrice[0].agentCommissionType === 'Variable' ? 'Variable ' + bar :
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: hotelDetailsResponsePrice[0].currencyCode
                                    }).format(hotelDetailsResponsePrice[0].agentCommissionAmount !== undefined ? hotelDetailsResponsePrice[0].agentCommissionAmount : hotelDetailsResponsePrice[0].agentCommission) + bar : ''}</span></span>}

                            {getConfigurationByBoolen("ALLOW_B2B") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? ' Rate Plan:' : hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined ? '  Rate Plan:' : ''} <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'Negotiated Net' : (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName !== undefined && hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined) ? hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName : ''}</span></span>}
                            {/* | Content:  <span className="text-primary fw-bold">Virtuoso</span> */}
                            {getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                            {getConfigurationByBoolen("ALLOW_B2B") && !getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                            {!getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                            {props.roomDetails.cancellationPolicies.filter(a => a.nonRefundable !== undefined).map(x => x.nonRefundable)[0] === 'true' ? <div className="text-14px text-danger mt-2"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="text-14px text-sky-blue mt-2"><i className="fas fa-money-check-alt"></i>  Refundable</div>}
                        </div>
                    }
                    {/* <p className="card-text text-black"><i className="fa-solid fa-star"></i> Collect and Redeem</p> */}
                    {/* <p className="card-text text-black"><span className="btn gt-seat-btn"><a><i className="fas fa-hotel"></i> <span className="mouseHover">Room Details</span></a></span></p> */}

                </div>
                <hr className='my-1'></hr>
                <div className='col-md-12 mt-2 px-2'>
                    <div className="tour_details_boxed_inner fare-summary-pop">
                        <div >
                            <div className="tour_details_boxed_inner fare-summary">
                                <div className="accordion" >
                                    <div className="accordion_flex_area">
                                        <div className="accordion-item fare-toggle-btn pb-0">
                                            <h2 className="accordion-header ms-2" id='headingThree' >
                                                <button className="accordion-button collapsed text-16px" type="button" data-bs-toggle="collapse" data-bs-target={"#collapselast" + props.roomDetails.roomID} aria-expanded="false" aria-controls={"collapselast" + props.roomDetails.roomID}>
                                                    {props.travelReq !== undefined && props.travelReq === 'true' ? hotelDetailsResponsePrice[0].inPolicy !== undefined ? hotelDetailsResponsePrice[0].inPolicy === true ? <div className='policy-in-out-hotel h3'><i id={`PolicyIn${props.roomDetails.roomID}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='policy-in-out-hotel h3'><i id={`PolicyOut${props.roomDetails.roomID}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='policy-in-out-hotel h3'><i id={`PolicyOut${props.roomDetails.roomID}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
                                                    <div className='ms-4 col-md-7 h6 my-auto'>
                                                        <div className='h4 text-primary col-md-12 ms-2 fw-bold pt-1 mb-0'>
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: hotelDetailsResponsePrice[0].currencyCode
                                                            }).format(hotelDetailsResponsePrice[0].offeredPrice.priceOffered)}
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id={"collapselast" + props.roomDetails.roomID} className="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExampleRoom">
                                                <div className="accordion-body px-2">
                                                    <div className="accordion_itinerary_list">
                                                        <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Base Rate</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponseTaxes[0].price !== undefined && hotelDetailsResponseTaxes[0].price !== null ? hotelDetailsResponseTaxes[0].price.currencyCode : 'USD'
                                                                    }).format(totalBaseRate)}</span>
                                                            </span>
                                                        </label>
                                                        <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Taxes & Fees</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponseTaxes[0].price !== undefined && hotelDetailsResponseTaxes[0].price !== null ? hotelDetailsResponseTaxes[0].price.currencyCode : 'USD'
                                                                    }).format(totalTax)}
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Total Rate</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponsePrice[0].currencyCode
                                                                    }).format(hotelDetailsResponsePrice[0].offeredPrice.priceOffered)}
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-md-12'>
                    {/* <div className='row'>
                        <div className='col-md-12'>
                            <div className='text-8px ms-3'>
                                <p>Includes taxes</p>
                            </div>
                        </div>
                        <div className='col-md-12 float-end  mt-2 mb-4 ms-3'>
                            <button className="btn btn_theme btn_sm" onClick={() =>
                                onBookNow()}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : hotelSearchRequestJson.Data.NoOfRooms > 1 ? `Reserve ${hotelSearchRequestJson.Data.NoOfRooms} rooms` : 'Reserve'} </button>
                        </div>
                    </div> */}
                    <div className='row mb-4 px-3'>
                        <div className='col-md-4 mt-2'>
                            <button className="btn btn_theme btn_sm" onClick={() =>
                                onBookNow()}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin"></i>
                                    : hotelSearchRequestJson.Data.NoOfRooms > 1 ? `Reserve ${hotelSearchRequestJson.Data.NoOfRooms} rooms` :
                                        props.travelReq !== undefined && props.travelReq === 'true' ? 'Select' : 'Reserve'} </button>
                        </div>
                        {getConfigurationByBoolen('ALLOW_HOTEL_PROPOSAL') && <div className='col-md-8 float-end mt-2'>
                            <div className="fw-bold text-primary add-to-proposal float-end my-auto" >Add to Proposal &nbsp;<input
                                class="form-check-input tandc-options hideproposal" type="checkbox" id={`addToProposal${props.roomDetails.roomID}`} onChange={(e) => { addHotel(e, props.hotelDetails, props.roomDetails) }} />&nbsp; </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>


    </Fragment>)
}

export default Room;