const initialState = {};

export const todoReducer = (state = initialState, action) => {

  switch (action.type) {
  
    case "UPDATE_TODO_LIST": { 
      return action.value.reduce(    
        (accumulator, current) => (
          {      
          ...accumulator,
           [current.keyCode] :current.controlType===1 ? current.defaultBoolenValue:current.defaultStringValue,
        }),
        {}
      )
    }
    case "UPDATE_TODO_FIELD": {   
    //  console.log(action.value);
      return {
        ...state,
        [action.keyCode]: {
          ...state[action.keyCode],
          [action.keyCode]: action.value,
        },
      };
      
    }
    default:
      return state;
  }
};