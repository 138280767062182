import FeeForm from './Components/FeeForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addFee, getSingleFee, updateFeeApi } from '../../../services/Fee-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateFee = (props) => {
    useDocumentTitle('Fee')
    const { t } = useTranslation(['translation.Fee']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addFee
    } else {
        varHttp = updateFeeApi
    }

    const { sendRequest: sendRequest1, data: loadedFeeDetails, error } = useHttpGet(getSingleFee);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (props.location.edit !== undefined) {
        sessionStorage.setItem('isEditable', props.location.edit);
    }else if(Id !== undefined){
        if (!checkSecurityDetails("Settings", "Fee", "Settings_Fee_Update") && props.location.edit === 1)
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Settings", "Fee", "Settings_Fee_View") && props.location.edit === 0)
        history.push('/Unauthorized')
    }

    if (props.loadedProfileDetails === undefined && props.location.pathname === '/UpdateFee') {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        if (isEdit || Id === undefined) {
            if (status === 'completed') {                
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });                        
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    useEffect(() => {
        // window.scrollTo({
        //     top: 600,
        //     behavior: "smooth",
        // });
    }, []);

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }

    let loadedData = [];
    for (let index in loadedFeeDetails) {
        if (loadedFeeDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedFeeDetails[index];
            }
        }
    }

    const addFeeHandler = (requestData) => { 
        //console.log(requestData);       
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchfee');
        }
    };

    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                    <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    const FeeFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <FeeForm loadedFeeDetails={loadedData.feeDetails === undefined ? loadedData : loadedData.feeDetails} isLoading={status === 'pending'} onAddFee={addFeeHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {FeeFormContent}
    </Fragment>
};

export default UpdateFee;