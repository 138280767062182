import RegionForm from './Components/RegionForm'
import { useParams, useHistory } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { getSingleRegion } from '../../../services/Region-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import { searchCommonList } from '../../../services/Office-API';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateRegion = (props) => {
    useDocumentTitle('Region')
    const { t } = useTranslation(['translation.Region']);
    //const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;

    const { sendRequest: sendRequest1, data: loadedRegionDetails, error } = useHttpGet(getSingleRegion);
    const { sendRequest: sendRequestCommonListApplicableTo, data: loadedApplicableTo } = useHttp(searchCommonList);

    const history = useHistory();
    //const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("Master", "Region", "Master_Region_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "Region", "Master_Region_View") && Action === "0")
        history.push('/Unauthorized')


        if (Action === '0') {
            sessionStorage.setItem('isEditable', Action);
        } else if (Action === '1') {
            sessionStorage.setItem('isEditable', Action);
        } else {
            sessionStorage.setItem('isEditable', '100');
        }

    useEffect(() => {
        const onSearchCommonListApplicableTo = ({
            methodName: "ApplicableTo",
            methodCode: "APP",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonListApplicableTo(onSearchCommonListApplicableTo);

    }, [sendRequestCommonListApplicableTo])

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }

    let loadedData = [];
    for (let index in loadedRegionDetails) {
        if (loadedRegionDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedRegionDetails[index];
            }
        }
    }

    const errorHandler = () => {
        setError(null);
        // if (loadedQuote['errors'].status !== 'TRUE') {
        //     history.push('/searchregion');
        // }
    };

    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                {/* <section id='common_banner'><div className='container'></div></section> */}
                {/* <div className='centered'> */}
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
                {/* </div> */}
            </Fragment>
        )
    }

    if ((!loadedApplicableTo || loadedApplicableTo.length === 0)) {
        return (
            <Fragment>
                {/* <section id='common_banner'><div className='container'></div></section> */}
                {/* <div className='centered'> */}
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
                {/* </div> */}
            </Fragment>
        )
    }

    let RegionFormContent = '';
    if (Id !== undefined) {
        RegionFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
            <div className="text-center p-5 h4 text-primary"></div> :
            <RegionForm loadedProfileDetails={loadedData} loadedProfileDetailsJson={loadedRegionDetails} loadedApplicableTo={loadedApplicableTo} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />
    }

    if (Id === undefined) {
        RegionFormContent = (!loadedApplicableTo || loadedApplicableTo.length === 0) ?
            <div className="text-center p-5 h4 text-primary"></div> :
            <RegionForm loadedProfileDetails={[]} loadedProfileDetailsJson={[]} loadedApplicableTo={loadedApplicableTo} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />
    }

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {RegionFormContent}
    </Fragment>
};

export default UpdateRegion;
