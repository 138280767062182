import { Link } from 'react-router-dom';

const QuoteItem = (props) => {
  function deleteFormHandler(id) {
    console.log(id);
    // optional: Could validate here
    props.onDeleteContinent({ id: id});
}

  return (
    <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    Continent Name : {props.name} 
                  </h6>
                  <hr className="my-1" />
                </div>
                <div className="col-md-8">
                
                  
                
                </div>
                <div className="col-md-4 my-auto">                  
                  <button onClick={() => deleteFormHandler(props.id)} id="delete" data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="btn btn-sm btn-danger float-sm-start float-lg-end ms-1 gt-border-radius-50 gt-red-btn"><i className="fas fa-trash-alt"></i></button>
                  <Link className="btn btn-sm float-sm-start text-white float-lg-end ms-1 gt-border-radius-50 gt-green-btn " to={`/newcontinent/${props.id}`}>
                  <i className="fa-solid fa-pen"></i>
                  </Link>

                  <button className="btn btn-sm float-sm-start btn-warning text-white float-lg-end ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show"><i className="fa-sharp fa-solid fa-tv"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
      
    
  );
};

export default QuoteItem;