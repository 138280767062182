import {   
    USER_LOGIN_SESSION
} from './CONSTANTS'
//creating function to load ip address from the API
export async function getIPData() {

    // const res = await axios.get('https://geolocation-db.com/json/')  


    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    localStorage.setItem("IPAddress", data.IPv4)
    localStorage.setItem("GeoLocation",JSON.stringify(data))
    return data;
}
export async function userLoginSessionApi(searchData) {    
    const response = await fetch(USER_LOGIN_SESSION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch user Login Session Api.');
    }    
    return data;
}