import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import Wrapper from "../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import Dropdown from 'react-select'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { RoleOfficeWiseList } from '../../../services/User-API'
import useHttp from '../../../services/use-http'
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { validateEmail, passwordValidation } from "../../../utils/Validation";
import { encrypted } from '../../../utils';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import CountryDropDownList from '../../../components/Common/DropDownList/CountryDropDownList';
import CityDropDownList from '../../../components/Common/DropDownList/CityDropDownList';
const postionOptions = [
    { value: 1, label: "Manager" },
    { value: 2, label: "Product Manager" },
    { value: 3, label: "Team Leader" },
    { value: 4, label: "Manager-Social Media" },
    { value: 5, label: "Manager-Marketing" },
    { value: 6, label: "Manager-Operations" },
    { value: 7, label: "Manager-Sales" },
    { value: 8, label: "Manager-Human Resources" },
    { value: 9, label: "Manager-Customer Success" },
    { value: 10, label: "Manager-Training" },
    { value: 11, label: "Vice President-Sales" },
    { value: 12, label: "Vice President-Marketing" },
    { value: 13, label: "Vice President-Operations" },
    { value: 14, label: "Vice President-Business Development" },
    { value: 15, label: "Chief Executive Officer" },
    { value: 16, label: "Chief Financial Officer" },
    { value: 17, label: "Executive - Marketing" },
    { value: 18, label: "Executive-Social Media" },
    { value: 19, label: "Executive - Operations" },
    { value: 20, label: "Executive - Training" },
    { value: 21, label: "Executive - Sales" },


];
const departmentOptions = [
    { value: 1, label: "Human Resources" },
    { value: 2, label: "Management" },
    { value: 3, label: "Marketing" },
    { value: 4, label: "Operations" },
    { value: 5, label: "Sales" },
    { value: 6, label: "Software" },
    { value: 7, label: "Testing" },
    { value: 8, label: "Training" },

];
const costCenterOptions = [
    { value: 1, label: "Software" },
    { value: 2, label: "Testing" },

];
const approvalOptions = [
    { value: 1, label: "Standard" },
    { value: 2, label: "Final Approver Only" },
    { value: 3, label: "None" },
];
const documentOptions = [
    { value: 1, label: "Passport" },
    { value: 2, label: "National ID" },
    { value: 3, label: "Iqama" },
];
const UserForm = (props) => {
    postionOptions.sort((a, b) => a.label.localeCompare(b.label));
    const { t } = useTranslation(['translation.User']);
    const history = useHistory();
    const inputFirstNameRef = useRef("");
    const inputmiddleNameRef = useRef("");
    const inputLastNameRef = useRef("");
    const inputEmployeeCodeRef = useRef("");
    const inputEmailIdeRef = useRef("");
    const activeInputRef = useRef('');
    const phoneNumberInputRef = useRef('');
    //const roleInputRef = useRef('')
    const [active, setActive] = useState(true);
    const [activeUserAction, setActiveUserAction] = useState(false);
    const [role, setRole] = useState("");
    const inputLoginEmailIdRef = useRef("");
    const inputPasswordRef = useRef('');
    const inputConfirmPasswordRef = useRef('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    const [roleDisable, setRoleDisable] = useState(false);
    const [iSDisabled, setiSDisabledDDL] = useState('false');
    const [isFirtTime, setFirstTime] = useState(true);

    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');

    const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
    const [firstNameInvalidInput, setFirstNameInvalidInput] = useState(false);

    const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
    const [employeeCodeInputHasError, setEmployeeCodeInputHasError] = useState(false);
    const [lastNameInvalidInput, setLastNameInvalidInput] = useState(false);

    const [emailIdInputHasError, setInputEmailIdHasError] = useState(false);
    const [emailIdInvalidInput, setEmailIdInvalidInput] = useState(false);

    const [middleNameInvalidInputError, setMiddleNameInvalidInputError] = useState(false);

    const [roleBlankError, setRoleBalnkError] = useState(false);
    const [loginEmailIdInputHasEroor, setLoginEmailIdInputHasError] = useState(false);
    const [invalidLoginEmailId, setInvalidLoginEmailId] = useState(false);
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordBlankError, setPasswordBlankError] = useState(false);
    const [confirmPasswordBlankError, setConfirmPasswordBlankError] = useState(false);
    const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);
    const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
    const [pageTitle, setPageTitle] = useState(" User");

    const { sendRequest: sendRequestRoleList, data: loadedRole } = useHttp(RoleOfficeWiseList);
    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };
    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);



    };
    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };
    const activeChangeHandler = () => {
        // alert(document.querySelector('#active').checked)

        setActiveUserAction(true);


        if (document.querySelector('#active').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }

    const preferredCountries = ['us']
    const setPhone = (value) => {
        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberHasError(true);
            return;
        }
        else {
            let countryDialCodeLength = phoneNumberInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneNumberInputRef.current.numberInputRef.value.split(' ')[0]);

            setPhoneNumber(phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberHasError(false);
        }
    };

    useEffect(() => {

        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedUserDetails.length !== 0 && isEditMode === 100) {

            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (props.loadedUserDetails.length !== 0) {
            inputFirstNameRef.current.value = props.loadedUserDetails.firstName;
            inputLastNameRef.current.value = props.loadedUserDetails.lastName;
            inputmiddleNameRef.current.value = props.loadedUserDetails.middleName;
            inputEmployeeCodeRef.current.value = props.loadedUserDetails.employeeCode;
            inputEmailIdeRef.current.value = props.loadedUserDetails.email;
            inputLoginEmailIdRef.current.value = props.loadedUserDetails.loginEmailID;
            // roleInputRef.current.value = props.loadedUserDetails.roleId;

            if (phoneNumber === '' && countryDialCode === '') {
                const phoneNumberCode = props.loadedUserDetails.phoneCode;
                const phoneNumbers = props.loadedUserDetails.phoneNumber;

                setCountryDialCode(phoneNumberCode.length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : phoneNumberCode);
                setPhoneNumber(phoneNumbers);
            }

            setRoleDisable(false);
            if (role === '') {
                setRole(props.loadedUserDetails.roleID);
            }


            if (activeUserAction === false) {
                setActive(props.loadedUserDetails.isActive);
            }

        }
        else {
            if (phoneNumber === '' && countryDialCode === '') {
                setCountryDialCode(getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE"));
            }
        }

        // setFirstTime(false);
        setiSDisabledDDL('false');
        if (props.loadedUserDetails.length === 0) {

            setRoleDisable(false);
            document.getElementById('btnNew').style.display = 'none';
            setPageTitle(" New User");
        }
        //View Page
        if (isEditMode === 0) {

            document.getElementById("firstName").readOnly = true;
            document.getElementById("lastName").readOnly = true;
            document.getElementById("employeeCode").readOnly = true;
            document.querySelector("#active").disabled = true;
            if (props.loadedUserDetails.middleName.length !== '') {
                document.getElementById("middleName").readOnly = true;
            }
            else {
                document.getElementById("middleName").style.visibility = "hidden";
            }

            document.getElementById("emailId").readOnly = true;
            document.getElementById("loginEmailId").readOnly = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            [].forEach.call(document.querySelectorAll('password'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
            setPageTitle(" View User");

            setRoleDisable(true);
            setiSDisabledDDL('true');

            document.getElementById("dvPasswordArea").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById("firstName").readOnly = false;
            document.getElementById("emailId").readOnly = false;
            document.getElementById("loginEmailId").readOnly = true;
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnReset").style.display = "";

            setRoleDisable(false);
            document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            setPageTitle(" Edit User");
            document.getElementById("dvPasswordArea").style.display = "none";
            setiSDisabledDDL('false');
        }

    }, [props.loadedUserDetails.length,
    props.loadedUserDetails.firstName,
    props.loadedUserDetails.lastName,
    props.loadedUserDetails.employeeCode,
    props.loadedUserDetails.emailID,
    props.loadedUserDetails.middleName,
    props.loadedUserDetails.phoneCode,
    props.loadedUserDetails.phoneNumber,
    props.loadedUserDetails.loginEmailID,
    props.loadedUserDetails.roleID,
    props.loadedUserDetails.email,
    props.loadedUserDetails.isActive,
        role,
        activeUserAction,
        active,
        phoneNumber,
        countryDialCode,
        roleDisable,

    ])




    let roleOptions = []
    let roleArrayData = [];
    for (let i in loadedRole) {
        if (loadedRole['errors'].status === 'FALSE') {
            if (i === 'data') {
                roleArrayData = loadedRole[i];
            }
        }
    }

    for (let index = 0; index < roleArrayData.length; index++) {
        roleOptions = [...roleOptions, { value: roleArrayData[index].id, label: roleArrayData[index].roleName }]
    }



    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
    };

    function changeLocation(event) {

        if (event.target.id === 'btnNew') {
            history.push('/NewUser');
            history.go();
        }

        if (event.target.id === 'btnReset') {
            if (props.props.loadedUserDetails === null || props.props.loadedUserDetails.length === 0) {

                history.go('/NewUser');
            } else {

                history.go(`/NewUser/${props.loadedProfileDetails.id}`);
            }
        }
    }


    function hideLastNameValidation() {
        setLastNameInputHasError(false);
    }
    function hideEmployeeCodeValidation() {
        setEmployeeCodeInputHasError(false);
    }
    function onBlurCheck() {
        const enteredFirstName = inputFirstNameRef.current.value;
        const enteredMiddleName = inputmiddleNameRef.current.value;
        const enteredLastName = inputLastNameRef.current.value;
        const enteredEmployeeCode = inputEmployeeCodeRef.current.value;
        const enteredEmailId = inputEmailIdeRef.current.value;
        const enteredLoginEmailId = inputLoginEmailIdRef.current.value;
        const enteredPassword = inputPasswordRef.current.value;
        const enteredConfirmPassword = inputConfirmPasswordRef.current.value;

        //Validation for First Name
        if (enteredFirstName.trim() === '') {
            setFirstNameInputHasError(true);
            setFirstNameInvalidInput(false);
            return;
        } else {
            setFirstNameInputHasError(false);

        }
        if (!enteredFirstName.match('^[a-zA-Z ]+$')) {
            setFirstNameInvalidInput(true);
            setFirstNameInputHasError(false);
            return;
        }
        else {
            setFirstNameInvalidInput(false);
        }
        //Validation For Middle Name
        if (enteredMiddleName.trim().length > 0) {
            if (!enteredMiddleName.match('^[a-zA-Z ]+$')) {
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        }
        //Validation for Last Name
        if (enteredLastName.trim() === '') {
            setLastNameInputHasError(true);
            setLastNameInvalidInput(false);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }
        //Validation for employeeCode
        if (enteredEmployeeCode.trim() === '') {
            setEmployeeCodeInputHasError(true);
            return;
        }
        else {
            setEmployeeCodeInputHasError(false);
        }
        if (!enteredLastName.match('^[a-zA-Z -]+$')) {
            setLastNameInvalidInput(true);
            setLastNameInputHasError(false);
            return;
        }
        else {
            setLastNameInvalidInput(false);
        }


        //email Id Validation
        if (enteredEmailId.trim().length === 0) {
            setInputEmailIdHasError(true);
            setEmailIdInvalidInput(false);
            return;
        }
        else {
            setInputEmailIdHasError(false);
        }
        if (!validateEmail(enteredEmailId)) {
            setEmailIdInvalidInput(true);
            setInputEmailIdHasError(false);
            return;
        }
        else {
            setEmailIdInvalidInput(false);
        }

        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberHasError(true);
            return;
        }
        else {
            setPhoneNumberHasError(false);
        }
        if (role === '' || role === undefined) {
            setRoleBalnkError(true);
            return;
        }
        else {
            setRoleBalnkError(false);
        }

        //loginEmailId
        if (enteredLoginEmailId.trim().length === 0) {
            setLoginEmailIdInputHasError(true);
            setInvalidLoginEmailId(false);
            return;
        }
        else {
            setLoginEmailIdInputHasError(false);
        }
        if (!validateEmail(enteredLoginEmailId)) {
            setInvalidLoginEmailId(true);
            setLoginEmailIdInputHasError(false);
            return;
        } else {
            setInvalidLoginEmailId(false);

        }
        ///password Validation
        if (enteredPassword.trim().length === 0) {
            setPasswordBlankError(true);
            setPasswordHasError(false);
            return;
        } else {
            setPasswordBlankError(false);
        }
        if (!passwordValidation(enteredPassword)) {
            setPasswordBlankError(false);
            setPasswordHasError(true);
            return;
        }
        else {
            setPasswordHasError(false);
        }
        ///confirmPasswordValidation
        if (enteredConfirmPassword.trim().length === 0) {
            setConfirmPasswordBlankError(true);
            setConfirmPasswordHasError(false);
            return;
        }
        else {
            setConfirmPasswordBlankError(false);
        }
        if (enteredConfirmPassword !== enteredPassword) {
            setConfirmPasswordBlankError(false);
            setConfirmPasswordHasError(true);
            return;
        }
        else {
            setConfirmPasswordHasError(false);
        }

    }
    useEffect(() => {
        //passing getData method to the lifecycle method        
        console.log(localStorage.getItem("IPAddress"));
    }, [])
    async function submitFormHandler(event) {
        event.preventDefault();
        const enteredFirstName = inputFirstNameRef.current.value;
        const enteredMiddleName = inputmiddleNameRef.current.value;
        const enterdLastName = inputLastNameRef.current.value;
        const enterdEmployeeCode = inputEmployeeCodeRef.current.value;
        const enteredEmailId = inputEmailIdeRef.current.value;
        const enteredLoginEmailId = inputLoginEmailIdRef.current.value;
        const enteredPassword = inputPasswordRef.current.value;
        const enteredConfirmPassword = inputConfirmPasswordRef.current.value;

        if (enteredFirstName.trim().length === 0) {
            inputFirstNameRef.current.focus();
            setFirstNameInputHasError(true);
            return;

        } else {
            setFirstNameInputHasError(false);
        }
        if (!enteredFirstName.match('^[a-zA-Z ]+$')) {
            inputFirstNameRef.current.focus();
            setFirstNameInvalidInput(true);
            return;
        }
        else {
            setFirstNameInvalidInput(false);
        }
        if (enteredMiddleName.trim().length > 0) {
            if (!enteredMiddleName.match('^[a-zA-Z ]+$')) {
                inputmiddleNameRef.current.focus();
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        }
        if (enterdEmployeeCode.trim().length === 0) {
            inputEmployeeCodeRef.current.focus();
            setEmployeeCodeInputHasError(true);
            return;
        }
        else {
            setEmployeeCodeInputHasError(false);
        }
        if (enterdLastName.trim().length === 0) {
            inputLastNameRef.current.focus();
            setLastNameInputHasError(true);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }
        if (!enterdLastName.match('^[a-zA-Z -]+$')) {
            inputLastNameRef.current.focus();
            setLastNameInvalidInput(true);
            return;
        }
        else {
            setLastNameInvalidInput(false);
        }

        if (enteredEmailId.trim().length === 0) {
            inputEmailIdeRef.current.focus();
            setInputEmailIdHasError(true);
            return;
        } else {
            setInputEmailIdHasError(false);
        }
        if (!validateEmail(enteredEmailId)) {
            inputEmailIdeRef.current.focus();
            setEmailIdInvalidInput(true);
            return;
        }
        else {
            setEmailIdInvalidInput(false);
        }
        if (phoneNumber === '') {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setPhoneNumberHasError(true);
            return;
        }
        else {
            setPhoneNumberHasError(false);
        }
        if (role === '' || role === undefined) {
            setRoleBalnkError(true);
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
            return;
        } else {
            setRoleBalnkError(false);
        }
        if (enteredLoginEmailId.trim().length === 0) {
            inputLoginEmailIdRef.current.focus();
            setLoginEmailIdInputHasError(true);
            return;
        } else {
            setLoginEmailIdInputHasError(false);
        }
        if (!validateEmail(enteredLoginEmailId)) {
            inputLoginEmailIdRef.current.focus();
            setInvalidLoginEmailId(true);
            return;
        } else {
            setInvalidLoginEmailId(false);
        }

        if (props.loadedUserDetails.length === 0) {
            if (enteredPassword.trim().length === 0) {
                inputPasswordRef.current.focus();
                setPasswordBlankError(true);
                return;
            } else {
                setPasswordBlankError(false);
            }
            if (!passwordValidation(enteredPassword)) {
                inputPasswordRef.current.focus();
                setPasswordHasError(true);
                return;
            }
            else {
                setPasswordHasError(false);
            }
            if (enteredConfirmPassword.trim().length === 0) {
                inputConfirmPasswordRef.current.focus();
                setConfirmPasswordBlankError(true);
                return;
            } else {
                setConfirmPasswordBlankError(false);

            } if (enteredConfirmPassword !== enteredPassword) {
                inputConfirmPasswordRef.current.focus();
                setConfirmPasswordHasError(true);
                return;
            }
            else {
                setConfirmPasswordHasError(false);
            }
        }



        let ID;
        if (props.loadedUserDetails === null || props.loadedUserDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedUserDetails.id;
        }



        props.onAddUser({
            id: ID,
            firstName: enteredFirstName,
            middleName: enteredMiddleName,
            lastName: enterdLastName,
            employeeCode: enterdEmployeeCode,
            loginEmailID: enteredLoginEmailId,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            email: enteredEmailId,
            password: await encrypted(enteredPassword),
            phoneCode: countryDialCode,
            phoneNumber: phoneNumber,
            roleID: role,
            officeID: JSON.parse(localStorage.getItem('officeID')),
            isActive: active,
            companyName: JSON.parse(localStorage.getItem('companyName')),
            iPAddress: localStorage.getItem("IPAddress")
            // defaultUser: false,
            // twoFactorEnabled:false
        });


    }



    const roleChangeHandler = (event) => {
        setRole(event.value);
        setRoleBalnkError(false);
    }
    useEffect(() => {
        if (isFirtTime) {
            const onRoleList = ({
                officeID: JSON.parse(localStorage.getItem('officeID')),
                roleName: "",

            });

            sendRequestRoleList(onRoleList);
        }
        setFirstTime(false);

    }, [sendRequestRoleList, isFirtTime]);

    useEffect(() => {
        if (props.loadedUserDetails.length === 0) {
            document.getElementById("btnSave").click();

        }
    }, [props.loadedUserDetails.length]);


    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                                    <form
                                                        className='form'>

                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12">{t('firstName')}<span className="error-icon"> *</span></label>
                                                                    <input id="firstName"
                                                                        ref={inputFirstNameRef}
                                                                        type='text'
                                                                        minLength="1"
                                                                        maxLength="50"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    >
                                                                    </input>
                                                                    {firstNameInputHasError && <p className="error-text">{t('validationFirstName')}</p>}
                                                                    {firstNameInvalidInput && <p className="error-text">{t('invalidInput')}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="middleName" className="mb-2 h6 text-primary col-lg-12">{t('middleName')}</label>
                                                                    <input id="middleName"
                                                                        ref={inputmiddleNameRef}
                                                                        type='text'
                                                                        minLength="1"
                                                                        maxLength="50"
                                                                        className="form-control form-control-sm"
                                                                        onBlur={onBlurCheck}
                                                                        onFocus={hideLastNameValidation}
                                                                    >
                                                                    </input>
                                                                    {middleNameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">{t('lastName')}<span className="error-icon"> *</span></label>
                                                                    <input id="lastName"

                                                                        ref={inputLastNameRef}
                                                                        minLength="2"
                                                                        maxLength="50"
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {lastNameInputHasError && <p className="error-text">{t('validationLastName')}</p>}
                                                                    {lastNameInvalidInput && <p className="error-text">{t('invalidInput')}</p>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className='control'>
                                                                    <label htmlFor="emailId" className="mb-2 h6 text-primary col-lg-12">{t('emailId')}<span className="error-icon"> *</span></label>
                                                                    <input id="emailId"
                                                                        ref={inputEmailIdeRef}
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {emailIdInputHasError && <p className="error-text">{t('blankEmailId')}</p>}
                                                                    {emailIdInvalidInput && <p className="error-text">{t('invalidEmailId')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div>
                                                                    <label htmlFor="phoneNumber" className="mb-2 h6 text-primary col-lg-12">{t('phoneNumber')}<span className="error-icon"> *</span></label>
                                                                    <PhoneInput type="text"
                                                                        country={'us'}
                                                                        value={countryDialCode + phoneNumber}
                                                                        onChange={setPhone}
                                                                        placeholder=''
                                                                        // preferredCountries={preferredCountries}
                                                                        enableSearch={true}
                                                                        disabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        id="phoneNumber"
                                                                        ref={phoneNumberInputRef} />
                                                                    {phoneNumberHasError && <p className="error-text">{t('phoneNumberBlankError')}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='control'>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label htmlFor="active" className="mb-2 h6 text-primary">   {t('active')}</label>
                                                                <input id="active" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} value={active} ref={activeInputRef} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="role" className="mb-2 h6 text-primary col-lg-12">{t('role')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown onChange={roleChangeHandler}
                                                                        value={roleOptions.filter(option => option.value === role)}
                                                                        options={roleOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        //isDisabled={roleDisable !== undefined ? roleDisable === "true" ? true : false : false}
                                                                        isDisabled={roleDisable}
                                                                    />

                                                                    {roleBlankError && <p className="error-text">{t('blankRole')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="control">
                                                                    <label htmlFor="loginEmailId" className="mb-2 h6 text-primary col-lg-12">{t('loginEmailId')}<span className="error-icon"> *</span></label>
                                                                    <input id="loginEmailId"
                                                                        ref={inputLoginEmailIdRef}
                                                                        minLength="1"
                                                                        maxLength="100"
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {loginEmailIdInputHasEroor && <p className="error-text">{t('blankLoginEmailId')}</p>}
                                                                    {!loginEmailIdInputHasEroor && invalidLoginEmailId && <p className="error-text">{t('invalidLoginEmailId')}</p>}
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-md-12 mt-2 mb-3">
                                                                <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold">Warning : </span> The role <em>Admin</em> gives the highest-level access to a user. To reduce access, please create a new user role from <em> User Management  <i className="fas fa-long-arrow-alt-right mx-1"></i>  Role.</em></span>
                                                            </div> */}
                                                        </div>

                                                        <div className="row" id='dvPasswordArea'>
                                                            <div className="col-md-6">
                                                                <div className="control">
                                                                    <label id="password1" htmlFor="password" className="mb-2 h6 text-primary col-lg-12">{t('password')}<span className="error-icon"> *</span></label>
                                                                    <input id="password"
                                                                        ref={inputPasswordRef}
                                                                        minLength="8"
                                                                        maxLength="30"
                                                                        type="password"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                        autocomplete="new-password"
                                                                    />
                                                                    {passwordBlankError && <p className="error-text">{t('blankPassword')}</p>}
                                                                    {passwordHasError && <p className="error-text">{t('invalidPassword')}</p>}
                                                                    <ReactTooltip
                                                                        anchorId="password"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={t('passwordHint')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="control">
                                                                    <label htmlFor="confirmPassword" id="confirmPassword1" className="mb-2 h6 text-primary col-lg-12">{t('confirmPassword')}<span className="error-icon"> *</span></label>
                                                                    <input id="confirmPassword"

                                                                        ref={inputConfirmPasswordRef}
                                                                        minLength="8"
                                                                        maxLength="30"
                                                                        type="password"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {confirmPasswordBlankError && <p className="error-text">{t('blankConfirmPassord')}</p>}
                                                                    {confirmPasswordHasError && <p className="error-text">{t('invalidConfirmPassord')}</p>}
                                                                    <ReactTooltip
                                                                        anchorId="confirmPassword"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={t('passwordHint')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-2"></hr>
                                                        <div className="col-md-12">
                                                            <label htmlFor="firstName" className="mb-2 mt-4 h5 col-lg-12"><i class="fas fa-user"></i> Employee Details</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12"> Employee Code <span class="error-icon"> *</span></label>


                                                                <input id="employeeCode"
                                                                    placeholder='Employee Code'
                                                                    ref={inputEmployeeCodeRef}
                                                                    minLength="2"
                                                                    maxLength="50"
                                                                    type="text"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                />
                                                                {employeeCodeInputHasError && <p className="error-text">{t('validationLastName')}</p>}


                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> Cost Center <span className="error-icon"> *</span></label>

                                                                <Dropdown
                                                                    options={costCenterOptions}
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12"> Department <span className="error-icon"> *</span></label>

                                                                <Dropdown
                                                                    options={departmentOptions}
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> Position <span className="error-icon"> *</span></label>

                                                                <Dropdown
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    options={postionOptions}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12"> Date of Birth <span className="error-icon"> *</span></label>

                                                                <input type='date' id='dateOfBirthd' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Date of Birth' autoFocus />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> Date of Joining <span className="error-icon"> *</span></label>

                                                                <input type='date' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Date of Joining' />
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> Address</label>
                                                                <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" autoFocus></textarea>
                                                            </div>
                                                            <div className="col-md-4 mt-5">
                                                                <label htmlFor="status" className="mb-2 h6 text-primary col-lg-12">Country</label>

                                                                <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={iSDisabled} />
                                                            </div>
                                                            <div className="col-md-4 mt-5">
                                                                <label htmlFor="status" className="mb-2 h6 text-primary col-lg-12">City</label>
                                                                <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} iSDisabled={iSDisabled} countryCode={countryCode} />
                                                            </div>
                                                            <div className="col-md-4 mt-5">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Postal Code</label>

                                                                <input type='text' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Postal Code' />
                                                            </div>
                                                        </div>

                                                        <hr className="mt-5 mb-2"></hr>
                                                        <div className="col-md-12">
                                                            <label htmlFor="DocumentType" className="mb-2 mt-4 h5 col-lg-12"><i class="fas fa-file"></i> Document Type</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Document Type</label>

                                                                <Dropdown
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    options={documentOptions}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Number</label>

                                                                <input type='text' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Number' />
                                                            </div>

                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="IssueDAte" className="mb-2 h6 text-primary col-lg-12"> Issue Date</label>

                                                                <input type='date' id='issueDAte' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Issue Date' autoFocus />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> Expiry Date</label>

                                                                <input type='date' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Expiry Date' />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Place of Issue</label>

                                                                <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={iSDisabled} />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Nationality</label>

                                                                <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={iSDisabled} />
                                                            </div>
                                                            <div className="col-md-12 mt-2">
                                                                <button class="btn btn_theme btn_sm float-end"><span class="">Add </span></button>
                                                            </div>
                                                        </div>

                                                        <hr className=" mt-4 mb-2"></hr>
                                                        <div className="col-md-12">
                                                            <label htmlFor="ApproverDetails" className="mb-2 mt-4 h5 col-lg-12"><i class="fas fa-check"></i> Roles and Privileges</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">First Approver</label>

                                                                <input type='text' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" value='Mr. Rahul Srinivasan' placeholder='Number' />
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">Approval Required <span class="error-icon"> *</span></label>

                                                                <Dropdown
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    options={approvalOptions}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                />
                                                            </div>
                                                            <div className='col-md-6 mt-3 col-sm-12 control '>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name="approver"></input> Is an Approver </span>
                                                            </div>
                                                            <div className='col-md-6 mt-3 col-sm-12 control'>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name=""></input> Is a Travel Arranger </span>
                                                            </div>
                                                            <div className='col-md-6 mt-3 col-sm-12 control'>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name=""></input> Can make Personal Bookings</span>
                                                            </div>
                                                            <div className='col-md-6 mt-3 col-sm-12 control'>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name=""></input> Needs approval for Personal Bookings </span>
                                                            </div>
                                                            <div className='col-md-12 mt-3 col-sm-12 control'>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name=""></input> Can delegate approvers on behalf of the actual approver</span>
                                                            </div>
                                                            <div className='col-md-6 mt-3 col-sm-12 control'>
                                                                <span className="text-primary"> <input id="active" className="form-check-input ms-2" type="checkbox" name=""></input> Is Exempt from the Travel Policy </span>
                                                            </div>






                                                        </div>

                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/AllUser" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/AllUser" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                    {(checkSecurityDetails("User Management", "User", "User Management_User_Insert") || checkSecurityDetails("User Management", "User", "User Management_User_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                            {t('save')}
                                                                        </button>
                                                                        : <span id="btnSave"></span>}
                                                                    {checkSecurityDetails("User Management", "User", "User Management_User_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>
};

export default UserForm;
