import { Fragment,useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ContinentItem from './ContinentItem'
import useHttp from '../../../../services/use-http'
import { deleteSingleContinent } from '../../../../services/Continent-API'
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";

const sortContinents = (continents, ascending) => {
  return continents.sort((continentA, continentB) => {
    if (ascending) {
      return continentA.id > continentB.id ? 1 : -1;
    } else {
      return continentA.id < continentB.id ? 1 : -1;
    }
  });
};

const ContinentList = (props) => {
  const history = useHistory();
  const location = useLocation();
 
  const [error1, setError] = useState();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';

  const sortedContinents = sortContinents(props.continents, isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })  
  };

  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleContinent, true);
  const sucsssMessage='Please note that your record deleted sucessfully.'
  useEffect(() => {   
    document.querySelector("#sortBy").selectedIndex = 1;
    history.push({
      pathname: '/AllContinent',
      search: `?sort=${('asc')}`
    })
    if (status === 'pending') {
      <Fragment>
          <section id='common_banner'><div className='container'></div></section>
          <div className='centered'>
              <LoadingSpinner></LoadingSpinner>
          </div>
      </Fragment>
    }
    if (status === 'completed') {
      setError({
        title: "Message",
        message: `${sucsssMessage}`,
      });
      return;
    }
  }, [status, history])

  const deleteContinentItemsHandler = (ContinentID) => {
    console.log(ContinentID);
    sendRequest(ContinentID);
    //history.push('/quotes')

    if (error) {
      return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>Continent not deleted!!</p>
    }
  };
  //End code for delete
  const errorHandler = () => {
    setError(null);
    history.go('/allcontinent');
  };
  return (
    <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
     
      
      <div className="container-fluid mb-3 list-view">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
            <div className='row mb-3'>
                <div className="col-md-6"></div>
                <div className='col-md-2 my-auto text-muted text-left'>
                  <div className="float-lg-end float-md-end">Sort By</div>
                </div>
                <div className="col-md-4 form-group">
                  <select id='sortBy' onChange={changeSortingHandler} className="form-control form-select bg_input">
                    <option>Continent Name (Z to A)</option>
                    <option>Continent Name (A to Z)</option>
                  </select>
                </div>
            </div>
          </div>
        </div>
        {sortedContinents.map((continent) => (
          <ContinentItem onDeleteContinent={deleteContinentItemsHandler}
            key={continent.id}
            id={continent.id}
            name={continent.name}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default ContinentList;