import { Fragment, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Notes from "../../../MyTrips/Components/Notes";
import SendEmail from "../../../BookingConfirmation/SendEmail";
import CancelPNR from "../../../MyTrips/Components/CancelPNR";
import Rebooking from "../../../MyTrips/Components/Rebooking";
import HistoryDetails from "../../../MyTrips/Components/HistoryDetails";
import Logs from "../../../MyTrips/Components/Logs";
import MdModal from "../../../Flights/MdModal";
import PopupModal from "../../../../components/Common/Flight/PrintPopupModal";
const PrintBookingDetails = (props) => {
    const [isCanceled, setIsCanceled] = useState('');
    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
    const location = useLocation();
    let pnr = bookingJSON.data.bookingReferenceID !== undefined ? bookingJSON.data.bookingReferenceID[0].id : null;
    let issueTicket = pnr === null ? " Booking Details" : " Booking Confirmation";




    useEffect(() => {
        if (location.mytrip !== undefined) {
            setIsCanceled(location.mytrip.isCanceled);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsCanceled") === 'true' ? true : false)
        }

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])

    return <Fragment>
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>

        <div className='row'>
            {props.hideData !== '1' && <div className='col-md-8'>
                <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket}
                </label>
            </div>}
        </div>
        <div className='row'>
            <div className='col-md-12'>

                {props.hideData !== '1' && <div className='col-md-12'>
                    <div className='text-muted h6 mb-2 mt-2'>
                        <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                        {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>}
                        {bookingJSON.data.bookingReferenceID !== undefined && bookingJSON.data.hotelResults[0].confirmationNumber !== undefined && <span> | <b className='text-primary'>Hotel Confirmation No. </b> <span className='text-black'>{bookingJSON.data.hotelResults[0].confirmationNumber}</span></span>}
                        <p className="card-text mb-0 mt-1 text-primary">  {props.hideData !== '1' && <div className='col-md-8'>
                            <label for="" className="h5 text-primary my-2"><span className="h6 "><i className="fas fa-plane-departure"></i> Flight </span><span class={bookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={bookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {bookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>
                        </div>}</p>


                    </div>
                </div>}
            </div>
        </div>
    </Fragment>

}
export default PrintBookingDetails;