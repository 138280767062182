import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FlightSearchControlMainPanel from '../../../Flights/Components/FlightSearchControlMainPanel';
import FlightSearchControlAdditionalPanel from '../../../Flights/Components/FlightSearchControlAdditionalPanel';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../../../services/CONSTANTS';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';

const SearchFlight = () => {
    const { t } = useTranslation(['translation.SearchFlight']);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isFirtTime, setFirstTime] = useState("");
    const [tripType, setTripType] = useState('');
    const [isAddedThree, setIsAddedThree] = useState(false);
    const [isAddedFour, setIsAddedFour] = useState(false);
    const [isAddedFive, setIsAddedFive] = useState(false);
    const [error1, setError] = useState();
    const [pageFocus, setPageFocus] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const valueParam = queryString.parse(location.search);
    const [loadComponent, setLoadComponent] = useState('');

    localStorage.setItem("tabActiveStatusSet", JSON.stringify([{ "Traveller": true },
    { "ContactPerson": false },
    { "SeatMap": false },
    { "AdditionalService": false },
    { "AddMarkUp": false },
    { "PaymentInformation": false }]))

    function onSearchHandler() {
        //window.open('http://localhost:3000/FlightSearch?flight=partial');
        //window.location.pathname = 'FlightSearch?flight=partial';
        sessionStorage.setItem("travelreqflightsearch", 'true');
        history.push('FlightSearch?flight=partial&travelreq=true&src=flight');
    }

    const errorHandler = () => {
        setError(null);
        setPageFocus(false);
        document.querySelector('#explore_area').classList.add('d-none');

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const tripTypeHandler = (event) => {
        setTripType(event.target.id.split('-')[0]);
        sessionStorage.setItem('tripType', event.target.id.split('-')[0]);        
    }

    const showMultiCityRow = (event) => {
        if (isAddedThree === false) {
            setIsAddedThree(true);
            if (isAddedFour && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFour === false) {
            setIsAddedFour(true);
            if (isAddedThree && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFive === false) {
            setIsAddedFive(true);
            if (isAddedThree && isAddedFour) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }

        if (document.querySelector('#removeMultiCityRowThree') !== null && document.querySelector('#removeMultiCityRowFour') !== null && document.querySelector('#removeMultiCityRowFive') !== null) {
            document.querySelector('#showMultiCityRow').style.display = "none";
        }
    }

    const hideMultiCityRow = (event) => {
        if (event.target.id === 'removeMultiCityRowThree') {
            setIsAddedThree(false);
            sessionStorage.removeItem('txtFrom3');
            sessionStorage.removeItem('spnFromAirportDetails3');
        }
        else if (event.target.id === 'removeMultiCityRowFour') {
            setIsAddedFour(false);
            sessionStorage.removeItem('txtFrom4');
            sessionStorage.removeItem('spnFromAirportDetails4');
        }
        else if (event.target.id === 'removeMultiCityRowFive') {
            setIsAddedFive(false);
            sessionStorage.removeItem('txtFrom5');
            sessionStorage.removeItem('spnFromAirportDetails5');
        }
        document.querySelector('#showMultiCityRow').style.display = "";
    }

    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

    }, [])



    useEffect(() => {

        if (sessionStorage.getItem('txtFrom3') !== null) {
            setIsAddedThree(true)
        }
        if (sessionStorage.getItem('txtFrom4') !== null) {
            setIsAddedFour(true)
        }
        if (sessionStorage.getItem('txtFrom5') !== null) {
            setIsAddedFive(true)
        }

        if (tripType === "") {
            if (sessionStorage.getItem("tripType") !== null && sessionStorage.getItem("tripType") !== '') {
                setTripType(sessionStorage.getItem("tripType"));

                if (sessionStorage.getItem("serviceType") !== null && sessionStorage.getItem("serviceType") !== '') {
                    if (sessionStorage.getItem("tripType") === 'oneway') {
                        document.querySelector('#oneway_flight').classList.add("active");
                        document.querySelector('#oneway_flight').classList.add("show");
                        document.querySelector('#oneway-tab').click();
                    }
                    else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                        document.querySelector('#roundtrip').classList.add("active");
                        document.querySelector('#roundtrip').classList.add("show");
                        document.querySelector('#roundtrip-tab').click();
                    }
                    else if (sessionStorage.getItem("tripType") === 'multi_city') {
                        document.querySelector('#multi_city').classList.add("active");
                        document.querySelector('#multi_city').classList.add("show");
                        document.querySelector('#multi_city-tab').click();
                    }
                    else {
                        document.querySelector('#roundtrip').classList.add("active");
                        document.querySelector('#roundtrip').classList.add("show");
                        document.querySelector('#roundtrip-tab').click();
                    }
                }
                else {
                    document.querySelector('#roundtrip').classList.add("active");
                    document.querySelector('#roundtrip').classList.add("show");
                    document.querySelector('#roundtrip-tab').click();
                }
            }
            else {
                setTripType("roundtrip");
                sessionStorage.setItem('tripType', "roundtrip");

                if (document.querySelector('#roundtrip') !== null) {
                    document.querySelector('#roundtrip').classList.add("active");
                    document.querySelector('#roundtrip').classList.add("show");
                }
                document.querySelector('#roundtrip-tab').click();
            }
        }

        if (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') {
            if (document.querySelector('#btnBusiness1') !== null) {
                document.querySelector('#btnBusiness1').className = "label-select-btn active";
            }
            sessionStorage.setItem('spnClass1', "Business");
            sessionStorage.setItem('btnClassDropDown1', "Business");
        }

    }, [])

    return (<Fragment>
        <div className="row position-relative">
            <div className="col-lg-12 flight-search-tabs">
                <div className="flight_categories_search" id='divTripType'>
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="oneway-tab" data-bs-toggle="tab"
                                data-bs-target="#oneway_flight" type="button" role="tab"
                                aria-controls="oneway_flight" aria-selected="false" onClick={tripTypeHandler}>
                                {t('oneway')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="roundtrip-tab" data-bs-toggle="tab"
                                data-bs-target="#roundtrip" type="button" role="tab"
                                aria-controls="roundtrip"
                                aria-selected="true" onClick={tripTypeHandler}>{t('roundtrip')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                data-bs-target="#multi_city" type="button" role="tab"
                                aria-controls="multi_city" aria-selected="false" onClick={tripTypeHandler}>
                                {t('multicity')}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="myTabContent1">
                <div className="tab-pane fade " id="oneway_flight" role="tabpanel"
                    aria-labelledby="oneway-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="row">
                                    {
                                        (tripType === 'oneway') &&
                                        <FlightSearchControlMainPanel controlId={1} tripType={tripType} travelReq={true} />
                                    }

                                </div>
                                {
                                    (tripType === 'oneway') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} travelReq={true} />
                                }

                                <div className="top_form_search_button mt-3">
                                    <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                        {t('search')}
                                    </button>
                                    {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">New</button> */}
                                    {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">Reset</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="roundtrip" role="tabpanel"
                    aria-labelledby="roundtrip-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="row">
                                    {
                                        (tripType === 'roundtrip') &&
                                        <FlightSearchControlMainPanel controlId={1} tripType={tripType} travelReq={true} />
                                    }

                                </div>
                                {
                                    (tripType === 'roundtrip') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} travelReq={true} />
                                }

                                <div className="top_form_search_butto mx-auto text-center pt-1">
                                    <div className='row mt-2'>
                                        <div className='col-lg-3 col-sm-12'></div>
                                        {
                                            getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen('ALLOW_SINGLE_PNR') === true &&
                                            <div className='col-lg-6 col-md-12 col-sm-12 col-12 mx-auto'>
                                                <button className='btn text-white mt-2 gt-main-btn btn_md aaaa' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>
                                            </div>
                                        }
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12'></div>

                                        {
                                            getConfigurationByBoolen('ALLOW_SINGLE_PNR') === null || getConfigurationByBoolen('ALLOW_SINGLE_PNR') === false &&
                                            <div className='mx-auto'>
                                                <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                                    {t('search')}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade " id="multi_city" role="tabpanel"
                    aria-labelledby="multi_city-tab">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="oneway_search_form">
                                <div className="multi_city_form_wrapper">
                                    <div className="multi_city_form">
                                        <div className="row">
                                            {
                                                (tripType === 'multi_city') &&
                                                <FlightSearchControlMainPanel controlId={1} tripType={tripType} travelReq={true} />
                                            }
                                        </div>
                                    </div>
                                    <div className="multi_city_form">
                                        <div className="row">
                                            {
                                                (tripType === 'multi_city') &&
                                                <FlightSearchControlMainPanel controlId={2} tripType={tripType} travelReq={true} />
                                            }
                                            {
                                                (isAddedThree) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowThree'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedThree === true) &&
                                                <FlightSearchControlMainPanel controlId={3} tripType={tripType} travelReq={true} />
                                            }
                                            {
                                                (isAddedFour) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowFour'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedFour === true) &&
                                                <FlightSearchControlMainPanel controlId={4} tripType={tripType} travelReq={true} />
                                            }
                                            {
                                                (isAddedFive) &&
                                                <div className="col-lg-12">
                                                    <div className="multi_form_remove">
                                                        <button type="button" id='removeMultiCityRowFive'
                                                            onClick={hideMultiCityRow}>{t('remove')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (tripType === 'multi_city' && isAddedFive === true) &&
                                                <FlightSearchControlMainPanel controlId={5} tripType={tripType} travelReq={true} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="add_multy_form">
                                            <button id='showMultiCityRow' type="button" onClick={showMultiCityRow}>{t('addanotherflight')}</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (tripType === 'multi_city') &&
                                    <FlightSearchControlAdditionalPanel tripType={tripType} travelReq={true} />
                                }
                                <div className="top_form_search_button">
                                    <button className='btn text-white mt-4 gt-main-btn btn_md bbb' onClick={onSearchHandler}>
                                        {t('search')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>)
};

export default SearchFlight;
