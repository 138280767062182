import { Fragment } from "react";
import RulesForm from "./Components/RulesForm";

const UpdateRules = () => {
    const RulesFormContent = <RulesForm></RulesForm>
    return (<Fragment>
        <section id="common_banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="common_bannner_text">
                    </div>
                </div>
            </div>
        </div>
    </section>
       <RulesForm></RulesForm>
    </Fragment>);
}

export default UpdateRules;