import { Fragment, useEffect, useState } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import Dropdown from 'react-select';
import SearchFlight from '../TravelRequest/Components/SearchFlight';
import SearchHotel from '../TravelRequest/Components/SearchHotel';
import ServiceRequest from '../TravelRequest/Components/ServiceRequest';
import Approver from '../TravelRequest/Components/Approver';
import MeetingDetails from '../TravelRequest/Components/MeetingDetails';
import Document from '../TravelRequest/Components/Document';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import HotelDetails from '../../HotelBookingConfirmation/Components/HotelDetails';
import Booking from '../../HotelBookingConfirmation/Components/Booking';
import RateDetails from '../../HotelBookingConfirmation/Components/RateDetails';
import FlightItem from '../../../components/Common/Flight/FlightItem';
import FareSummary from '../../../components/Common/Flight/FareSummary';
import BookingLoadingSpinner from '../../Booking/Components/BookingLoadingSpinner';

const TravelRequest = () => {
    useDocumentTitle('Travel Request');
    const [tripReasonValue, setTripReasonValue] = useState('');
    const [error1, setError] = useState();
    const history = useHistory();
    const location = useLocation();
    const [tripReasonInputHasError, setTripReasonInputHasError] = useState();
    const valueParam = queryString.parse(location.search);
    const [selectedTab, setSelectedTab] = useState('');
    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;
    const [status, setStatus] = useState('');

    let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));

    let tripReasonOptions = [
        { value: 'Business', label: 'Business' },
        { value: 'Personal', label: 'Personal' }
    ];

    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingDataTravelRequest"));

    useEffect(() => {
        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            if (localStorage.getItem("tripReason") !== null && localStorage.getItem("tripReason") !== '') {
                setTripReasonValue(localStorage.getItem("tripReason"));
            }
        }

        if (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') {
            sessionStorage.removeItem("travelreqflightsearch");
            sessionStorage.removeItem("travelreqhotelsearch");
            localStorage.removeItem("BookingDataTravelRequest");
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'flight') {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'hotel') {
                onTabChangeHandler('Hotel');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("active");
                }
            }
            else {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
            }
        }
        else {
            onTabChangeHandler('Flight');

            if (document.querySelector('#tabButton_Flight') !== null) {
                document.querySelector('#tabButton_Flight').classList.add("active");
            }
            if (document.querySelector('#tabArea_Flight') !== null) {
                document.querySelector('#tabArea_Flight').classList.add("active");
            }
            if (document.querySelector('#tabArea_Hotel') !== null) {
                document.querySelector('#tabArea_Hotel').classList.remove("active");
            }
        }

    }, [])

    const tripReasonChangeHandler = (event) => {
        setTripReasonValue(event.value);
        localStorage.setItem("tripReason", event.value);
    };

    const onTabChangeHandler = (tabOption) => {
        setSelectedTab(tabOption);

        if (document.querySelector('#tabButton_' + tabOption) !== null) {
            document.querySelector('#tabButton_' + tabOption).classList.add("active");
        }

        //tabOption === 'ServiceRequest' || tabOption === 'Approver' || tabOption === 'Document'
        if (tabOption === 'MeetingDetails') {
            if (document.querySelector('#btnSubmit') !== null) {
                document.querySelector('#btnSubmit').classList.remove("d-none");
            }
            if (document.querySelector('#btnReset') !== null) {
                document.querySelector('#btnReset').classList.remove("d-none");
            }
        }
        else {
            if (document.querySelector('#btnSubmit') !== null) {
                document.querySelector('#btnSubmit').classList.add("d-none");
            }
            if (document.querySelector('#btnReset') !== null) {
                document.querySelector('#btnReset').classList.add("d-none");
            }
        }

        if (tabOption === 'ServiceRequest' || tabOption === 'MeetingDetails') {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.remove("d-none");
            }
        }
        else {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.add("d-none");
            }
        }

        if (tabOption === 'Hotel') {
            sessionStorage.setItem("HotelCBT", "1")
        }
        else {
            sessionStorage.removeItem("HotelCBT");
        }
    }

    function onSubmit() {
        // if (serviceRequestValue === '') {
        //     setServiceRequestInputHasError(true);
        //     return;
        // }
        // else {
        //     setServiceRequestInputHasError(false);
        // }

        // if (value.trim() === '') {
        //     setRemarksInputHasError(true);
        //     setRemarksInvalidInputError(false);
        //     return;
        // }
        // else {
        //     setRemarksInputHasError(false);
        // }

        if (tripReasonValue !== undefined) {
            if (tripReasonValue === '') {
                setTripReasonInputHasError(true);
                window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                });
                return;
            }
            else {
                setTripReasonInputHasError(false);
                setStatus('pending');

                window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                });

                setTimeout(function () {
                    setStatus('');

                    setError({
                        title: "Message",
                        message: 'Your Travel Request has been submitted successfully.',
                    });
                }, 5000);
            }
        }
        else {
            setStatus('pending');
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });

            setTimeout(function () {
                setStatus('');

                setError({
                    title: "Message",
                    message: 'Your Travel Request has been submitted successfully.',
                });
            }, 5000);
        }
    }

    function onReset() {
        // history.push('/TravelRequest');
        // history.go();
        window.location.reload(true);
    }

    const errorHandler = () => {
        setError(null);
        history.push('/mytrips');
    };

    return (
        <Fragment>
            {error1 && (
                <ErrorModal
                    title={error1.title}
                    message={error1.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            {/*     <!-- Common Banner Area --> */}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>
            {(status === 'pending') &&
                <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mt-5">
                                    <div className="row  position-relative gt-h-255 my-auto">
                                        {
                                            <BookingLoadingSpinner action='import'></BookingLoadingSpinner>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*   <!-- Form Area --> */}
            {
                (status !== 'pending') &&
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area position-relative my-2">
                                    <div className="row position-relative">
                                        <div className="col-lg-12">
                                            <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Travel Request</label>
                                            <div className="tour_search_form">
                                                <form action="!#">
                                                    <div className='row mb-4'>
                                                        <div className='col-md-12 dropdown-search-text'>
                                                            <label class="h6 text-primary mb-2 col-lg-12" for="tripReason">Trip Reason<span className="error-icon"> *</span></label>
                                                            <div className="dropdown pb-2 col-md-12">
                                                                <Dropdown onChange={tripReasonChangeHandler}
                                                                    value={tripReasonOptions.filter(option => option.value === tripReasonValue)}
                                                                    options={tripReasonOptions}
                                                                    textField="value"
                                                                    dataItemKey="label"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                />
                                                                {tripReasonInputHasError &&
                                                                    <p className="error-text">{'Trip Reason is required.'}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 pop-ups-tab-border-parent">
                                                            <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link active" id='tabButton_Flight' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Flight' type="button" role="tab" aria-controls='#tabArea_Flight'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Flight')}>Flight</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" id='tabButton_Hotel' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Hotel' type="button" role="tab" aria-controls='#tabArea_Hotel'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Hotel')}>Hotel</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" id='tabButton_ServiceRequest' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_ServiceRequest' type="button" role="tab" aria-controls='#tabArea_ServiceRequest'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('ServiceRequest')}>Service Request </button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" id='tabButton_Approver' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Approver' type="button" role="tab" aria-controls='#tabArea_Approver'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Approver')}>Approver</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" id='tabButton_Document' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Document' type="button" role="tab" aria-controls='#tabArea_Document'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Document')}>Documents</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link" id='tabButton_MeetingDetails' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_MeetingDetails' type="button" role="tab" aria-controls='#tabArea_MeetingDetails'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('MeetingDetails')}>Meeting Details</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="px-3 tab-content include-exclude-tab">
                                                                <div className="tab-pane fade show active" id='tabArea_Flight' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <SearchFlight></SearchFlight>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Hotel' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <SearchHotel></SearchHotel>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_ServiceRequest' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <ServiceRequest></ServiceRequest>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Approver' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <Approver></Approver>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_MeetingDetails' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <MeetingDetails prop={1}></MeetingDetails>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Document' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <Document></Document>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p id="requiredIndicator" className='d-none'><span className="error-icon">*</span><span>Indicates required fields.</span></p>
                                                    <div className="top_form_search_button my-3">
                                                        <button id='btnReset' onClick={onReset} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >Reset</button>
                                                        <button id='btnSubmit' onClick={onSubmit} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >Submit</button>
                                                    </div>

                                                    {
                                                        selectedTab === 'Flight' && valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("travelreqflightsearch") !== null && sessionStorage.getItem("travelreqflightsearch") === 'true' &&
                                                        <div><br></br>
                                                            <label for="" class="h5 text-primary"> Selected Flight Details </label>
                                                            <div class="text-muted h6 mb-2 mt-2 d-none"><b class="text-primary">PNR </b> <span class="text-black">MJCTDX</span> </div>
                                                            <div className='col-md-12'>
                                                                <FlightItem
                                                                    key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                    id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                    airItinerary={bookingJSON.data.airItinerary[0]}
                                                                    searchFlight={bookingJSON.data.airItinerary[0]}
                                                                    bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                                                    fareRuleData={bookingJSON.data.airItinerary[0]}
                                                                    showData="3"
                                                                    hideIcon="2"
                                                                    hideCommision="4"
                                                                />

                                                                <div className="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExample">
                                                                        <div className="accordion_flex_area">
                                                                            <div className="accordion-item xyz pb-0">
                                                                                <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                                                    Price
                                                                                </h5>

                                                                                <div id="collapseOne" className="accordion-collapse collapse show"
                                                                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                    <div className='row my-2'>
                                                                                        <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        selectedTab === 'Hotel' && valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("travelreqhotelsearch") !== null && sessionStorage.getItem("travelreqhotelsearch") === 'true' &&
                                                        <div><br></br>
                                                            <label for="" class="h5 text-primary"> Selected Hotel Details </label>
                                                            <div>
                                                                <div className="hotel-list mt-1 row">
                                                                    <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                                                </div>
                                                                <div className="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExample">
                                                                        <div className="accordion_flex_area">
                                                                            <Booking hotelBookingJSON={hotelBookingJSON}></Booking>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <div className="accordion_flex_area">
                                                                            <RateDetails hotelBookingJSON={hotelBookingJSON} show=""></RateDetails>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </Fragment>)
};

export default TravelRequest;

