import Dropdown from 'react-select';
import useHttp from '../../../services/use-http'
import { useEffect, useState } from 'react';
import { getAirProgramList, getSpecialService, getMealDetails } from '../../../services/CommonListData-API'
import AdditionalService from './SubComonents/AdditionaService';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const AdditionalServices = (props) => {
    const [remarksGeneralInvalidInputError, setRemarksGeneralInvalidInputError] = useState(false);
    let isFFAirlineSelected = false;
    //Code for Bind Airline Program Data
    const { sendRequest: sendRequestAirlineProgramData, data: loadedAirProgramData } = useHttp(getAirProgramList);

    useEffect(() => {
        sendRequestAirlineProgramData();
    }, [sendRequestAirlineProgramData])

    let arilineProgramOptions = []
    let arilineProgramArrayData = [];

    if (loadedAirProgramData !== null && loadedAirProgramData.errors.status === 'FALSE') {
        arilineProgramArrayData = loadedAirProgramData.data;
        arilineProgramOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < arilineProgramArrayData.length; index++) {
            arilineProgramOptions = [...arilineProgramOptions, { value: arilineProgramArrayData[index].code, label: arilineProgramArrayData[index].name }]
        }
    }
    //Code for Bind Airline Sepcial Service Data
    const { sendRequest: sendRequestSpecialServiceData, data: loadedSpecialServiceData } = useHttp(getSpecialService);
    useEffect(() => {
        sendRequestSpecialServiceData();
    }, [sendRequestSpecialServiceData])

    let specialServiceOptions = []
    let specialServiceArrayData = [];

    if (loadedSpecialServiceData !== null && loadedSpecialServiceData.errors.status === 'FALSE') {
        specialServiceArrayData = loadedSpecialServiceData.data;
        specialServiceOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < specialServiceArrayData.length; index++) {
            specialServiceOptions = [...specialServiceOptions, { value: specialServiceArrayData[index].code, label: specialServiceArrayData[index].name }]
        }
    }
    const { sendRequest: sendRequestMealDetailsData, data: loadedMealDetails } = useHttp(getMealDetails);
    useEffect(() => {
        sendRequestMealDetailsData();
    }, [sendRequestMealDetailsData])

    let mealDetailsOptions = []
    let mealDetailsArrayData = [];

    if (loadedMealDetails !== null && loadedMealDetails.errors.status === 'FALSE') {
        mealDetailsArrayData = loadedMealDetails.data;
        mealDetailsOptions.push({ value: '', label: 'Select...' });
        for (let index = 0; index < mealDetailsArrayData.length; index++) {
            mealDetailsOptions = [...mealDetailsOptions, { value: mealDetailsArrayData[index].code, label: mealDetailsArrayData[index].name }]
        }
    }
    function assignAdditionalService(event) {
        event.preventDefault();

        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                document.querySelector('#remarksGeneral').focus();
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        let additionalServicesJSON = JSON.parse(localStorage.getItem("AdditionalServicesData"));
        let setValidationStaus = true;

        bookingJSON.data.travelerInfo.traveler.forEach(elementAirTraveler => {
            if (additionalServicesJSON !== undefined && additionalServicesJSON !== null && additionalServicesJSON.length > 0) {
                if (additionalServicesJSON.filter(x => x.ServiceType === 'AirlineProgram_' + elementAirTraveler.TravelerRefNumber).length !== 0) {
                    if (document.getElementById("frequentFlyerNo" + elementAirTraveler.TravelerRefNumber) !== null) {
                        if (document.getElementById("frequentFlyerNo" + elementAirTraveler.TravelerRefNumber).value === '') {
                            if (setValidationStaus) {
                                document.getElementById("frequentFlyerNoMsg" + elementAirTraveler.TravelerRefNumber).innerHTML = "Frequent Flyer No. is required.";
                                document.getElementById("frequentFlyerNo" + elementAirTraveler.TravelerRefNumber).focus();
                                setValidationStaus = false;
                            }
                        }
                    }
                }
                else {
                    if (document.getElementById("frequentFlyerNo" + elementAirTraveler.TravelerRefNumber) !== null) {
                        if (document.getElementById("frequentFlyerNo" + elementAirTraveler.TravelerRefNumber).value !== '') {
                            if (setValidationStaus) {
                                document.getElementById("msgairlineProgram" + elementAirTraveler.TravelerRefNumber).innerHTML = "Frequent Flyer Airline is required.";
                                setValidationStaus = false;
                                const yOffset = -150;
                                const element2 = document.getElementById("airlineProgram" + elementAirTraveler.TravelerRefNumber);
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                            }
                        }
                    }
                }
            }
        });

        let SpecialServiceRequest = [];

        if (additionalServicesJSON !== null && additionalServicesJSON !== undefined && additionalServicesJSON.length > 0) {
            additionalServicesJSON.filter(element => element.ServiceType.split('_')[0] === "MealDetails").forEach(element => {
                SpecialServiceRequest.push({
                    "SSRCode": element.ServiceCode,
                    "SSRDescription": mealDetailsOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
                    "TravelerRefNumber": element.PassengerNo,
                    "FlightRefNumber": element.ServiceType.split('_')[1],
                    "SSRCategory": "",
                    "SSRCharge": "0",
                    "SSRType": "MEAL",
                    "ServiceQuantity": document.querySelector('#chkSelectAllFlights' + element.PassengerNo) !== null ? document.querySelector('#chkSelectAllFlights' + element.PassengerNo).checked ? element.ItineraryCount.toString() : "1" : "1",
                    "BirthDate": "",
                    "Status": element.itineraryStatus,
                    "Remarks": mealDetailsOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
                    "WayType": document.querySelector('#chkSelectAllFlights' + element.PassengerNo) !== null ? document.querySelector('#chkSelectAllFlights' + element.PassengerNo).checked ? "0" : "1" : "1",
                    "IsBundleService": null
                });
            });

            additionalServicesJSON.filter(element => element.ServiceType.split('_')[0] === "SpecialService").forEach(element => {
                SpecialServiceRequest.push({
                    "SSRCode": element.ServiceCode,
                    "SSRDescription": specialServiceOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
                    "TravelerRefNumber": element.PassengerNo,
                    "FlightRefNumber": "1",
                    "SSRCategory": "",
                    "SSRCharge": "0",
                    "SSRType": "Service",
                    "ServiceQuantity": "1",
                    "BirthDate": "",
                    "Status": "",
                    "Remarks": specialServiceOptions.filter((item) => item.value === element.ServiceCode).map(element => element.label)[0],
                    "WayType": "0",
                    "IsBundleService": null
                });
            });

            bookingJSON.data.travelerInfo.SpecialReqDetails.forEach(element => {
                element.SpecialServiceRequests = { SpecialServiceRequest };
            });

            bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
                if (additionalServicesJSON.filter(elementList => elementList.ServiceType === "FrequentFlyerNo_" + elementTraveler.TravelerRefNumber).map(value => value.ServiceCode)[0] !== undefined) {
                    let CustLoyalty = ([{
                        "MembershipID": additionalServicesJSON.filter(elementList => elementList.ServiceType === "FrequentFlyerNo_" + elementTraveler.TravelerRefNumber).map(value => value.ServiceCode)[0],
                        "ProgramID": additionalServicesJSON.filter(elementList => elementList.ServiceType === "AirlineProgram_" + elementTraveler.TravelerRefNumber).map(value => value.ServiceCode)[0]
                    }]);
                    elementTraveler.CustLoyalty = CustLoyalty;
                } else {
                    elementTraveler.CustLoyalty = null;
                }


            });
        }
        else {
            bookingJSON.data.travelerInfo.SpecialReqDetails.forEach(element => {
                element.SpecialServiceRequests = null;
            });

            bookingJSON.data.travelerInfo.traveler.forEach(elementTraveler => {
                elementTraveler.CustLoyalty = null;
            });
        }

        //localStorage.removeItem("AdditionalServicesData");
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        console.log(bookingJSON);
        if (setValidationStaus) {
            props.onContinueButton("4");
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
        }
    }
    function skipAdditionalService() {
        props.onContinueButton("4");
        window.scrollTo({
            top: 400,
            behavior: "smooth",
        });
    }

    function onBlurCheck() {
        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }
    }

    return (<div className="accordion_flex_area">
        <div id="sequenceFour" className="accordion_left_side position-relative text-muted">
            <h5>4</h5>
            <span id="completeFour" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" >
                <button id="headingFour" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseFour"
                    aria-expanded="false" aria-controls="collapseFour">
                    Add-ons
                </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse"
                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                {props.traveler.travelerInfo.traveler.map((traveler) =>
                    <AdditionalService
                        key={traveler.TravelerRefNumber}
                        id={traveler.TravelerRefNumber}
                        arilineProgramOptions={arilineProgramOptions}
                        mealDetailsOptions={mealDetailsOptions}
                        specialServiceOptions={specialServiceOptions}
                        traveler={props.traveler}
                    >
                    </AdditionalService>
                )}

                <div className='row'>
                    <div className='control input-placeholder'>
                        <label htmlFor='remarksGeneral' className='h6 text-primary mb-2'>Booking Remarks</label>
                        <textarea id='remarksGeneral'
                            //value={remarksGeneral}
                            className="form-control form-control-sm"
                            //onChange={(e) => setRemarksGeneral(e.target.value)}
                            rows={2} cols={100} maxLength={300}
                            onBlur={onBlurCheck}
                        />
                    </div>
                    {remarksGeneralInvalidInputError &&
                        <span className="error-text col-lg-12 mt-3">{('Booking Remarks is not valid.')}</span>
                    }

                    <div class="text-primary h6 mt-3">
                        <span class="h6 text-black fw-bold">Note :</span>
                        <span class="ms-1">These remarks are <strong>public.</strong> They are sent to the content provider and are visible in the booking confirmation.</span>
                    </div>

                </div>
                <div className='row mt-4'>
                    <div className='control input-placeholder'>
                        <label htmlFor='remarksInternal' className='h6 text-primary mb-2'>Internal Notes</label>
                        <textarea id='remarksInternal'
                            //value={remarksInternal}
                            className="form-control form-control-sm"
                            //onChange={(e) => setRemarksInternal(e.target.value)}
                            rows={2} cols={100} maxLength={300}
                        />
                    </div>
                    <div class="text-primary h6 mt-3">
                        <span class="h6 text-black fw-bold">Note :</span>
                        <span class="ms-1">These remarks are <strong>private</strong>. These can only be seen in <strong>My Trips</strong> by authorized users within your office.</span>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className="col-lg-6 col-md-12 col-sm-12 my-auto">
                        <p><span className="error-icon"></span><span></span></p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="float-md-end float-lg-end">
                            <button type="button" onClick={skipAdditionalService} className="btn btn margin-top-15 text-white btn-secondary ms-1 btn-sm">{getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? 'Skip to Add Service Fee' : 'Skip to Payment'}</button>
                            <button type="button" onClick={assignAdditionalService} className="btn margin-top-15   text-white gt-main-btn ms-1 btn-sm">Continue</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>)
}
export default AdditionalServices