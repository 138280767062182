import { Fragment, useEffect, useState, React } from 'react';
import Dropdown from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';

const MessageoftheDay = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [serviceRequestValue, setServiceRequestValue] = useState('');
    const [value, setValue] = useState('');

    let serviceRequestOptions = [
        { value: 'Cancellation', label: 'Cancellation' },
        { value: 'Rebooking', label: 'Rebooking' },
        { value: 'Transfer', label: 'Transfer' },
        { value: 'Visa Assistance', label: 'Visa Assistance' },
        { value: 'MICE Package', label: 'MICE Package' },
        { value: 'Other', label: 'Other' }
    ];

    const serviceRequestChangeHandler = (event) => {
        setServiceRequestValue(event.value);
    };

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8 mb-30">
                                            <div className="card card-primary card-outline">

                                                <div className="card-body">
                                                    <div className="col-md-12 ">
                                                        <div className="row position-relative">
                                                            <div className="col-lg-12">
                                                                <div className="tour_search_form">
                                                                    <form action="!#">
                                                                    <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Message of the day</label>
                                                                        <div className='row mb-4'>
                                                                            <div className='col-md-12'>
                                                                                <label class="h6 text-primary mb-2 col-lg-12" for="Service">Title<span className="error-icon"> *</span></label>
                                                                                <div className="dropdown pb-2 col-md-12">
                                                                                    <input maxLength={30} className="form-control bg_input" type='text'
                                                                                        id='existingConfirmationNumber' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mb-4'>
                                                                            <div className='col-md-12 dropdown-search-text'>
                                                                                <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Message of the day<span className="error-icon"> *</span></label>
                                                                                <div className="dropdown pb-2 col-md-12">

                                                                                    <div style={{ width: 1000, height: 200 }}>
                                                                                        <ReactQuill theme="snow" value={value} onChange={setValue} style={{ width: 820, height: 150 }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>Indicates required fields.</span></p>
                                                                        <div className='mt-3 actions row'>
                                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">

                                                                                
                                                                            </div>
                                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                                <div className="float-md-end float-lg-end">
                                                                                    
                                                                                    <button id='btnSave' className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Save')}</button> : <span id="btnSave"></span>


                                                                                  
                                                                                    <button id='btnReset' className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Reset')}</button>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>)

};

export default MessageoftheDay;

