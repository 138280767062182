import Dropdown from 'react-select';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getAllCountry } from '../../../../../services/Country-API';
import { useEffect, useState } from 'react';
import useHttp from '../../../../../services/use-http';
import { dateList, monthList, expiryYearList, dobYearList, gender_list, titles_name_child, titles_name, dobInfantYearList, dobChildYearList } from '../../../../../utils/DropdownData';
import { getConfigurationByValue } from '../../../../../utils/ConfigurationsFunction';


const TravellerDetail = (props) => {
    const IdentificationOptions = [{ value: "", label: "Select" }, { value: "KnownTravelerNumber", label: 'Known Traveler' }, { value: "RedressNumber", label: "Redress" }];
    const { sendRequest: sendRequestCountryList, data: loadedCountry } = useHttp(getAllCountry);
    const [isAdvance, setIsAdvance] = useState(false);
    
    function toggleHandler() {
        var x = document.getElementById("myDIV" + props.id);
        if (x.style.display === "none") {
            x.style.display = "";
        } else {
            x.style.display = "none";
        }
    }

    useEffect(() => {
        sendRequestCountryList();
    }, [sendRequestCountryList])

    useEffect(() => {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
        let passportIsRequired = false;
        if (getConfigurationByValue("PASSPORT_REQUIRED") !== null && getConfigurationByValue("PASSPORT_REQUIRED") !== '') {
            let validatingCarrierArray = getConfigurationByValue("PASSPORT_REQUIRED").split(',');
            if (validatingCarrierArray.length > 0) {
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (validatingCarrierArray.indexOf(elementAirItinerary.ValidatingCarrier) !== -1) {
                        passportIsRequired = true;
                        //For airlines that require Passport, "Additional" section in Pax info should be open
                        if (document.getElementById("myDIV" + props.id) !== null) {
                            var x = document.getElementById("myDIV" + props.id);
                            x.style.display = "";
                        }
                    }
                    else {
                        var x = document.getElementById("myDIV" + props.id);
                        x.style.display = "none";
                    }
                });
            }
            else {
                var x = document.getElementById("myDIV" + props.id);
                x.style.display = "none";
            }
        }
        else {
            var x = document.getElementById("myDIV" + props.id);
            x.style.display = "none";
        }

        if (passportIsRequired) {
            var controlList = document.querySelectorAll("#spnMandatoryIcon" + props.id);
            controlList.forEach(element => {
                element.classList.remove('d-none');
            });
        }
    }, [])

    let countryOptions = []
    let countryArraydata = [];
    for (let i in loadedCountry) {
        if (loadedCountry['errors'].status === 'FALSE') {
            if (i === 'data') {
                countryArraydata = loadedCountry[i];
            }
        }
    }
    for (let index = 0; index < countryArraydata.length; index++) {
        countryOptions = [...countryOptions, { value: countryArraydata[index].countryCode, label: countryArraydata[index].countryName }]
    }
    const TextBox = ({ keyName, Messsage, ValidMessage, mandatory, regularExpression, controlType = "text", value, maxLength }) => {

        function changeHandler(e) {
            if (mandatory && e.target.value === '') {
                document.getElementById('msg' + keyName).innerHTML = Messsage;

            } else if (regularExpression !== '' && (e.target.value !== '' && !e.target.value.match(regularExpression))) {
                document.getElementById('msg' + keyName).innerHTML = ValidMessage;
            }
            else {
                document.getElementById('msg' + keyName).innerHTML = '';
            }

            if (e.target.id === 'passportNumber' + props.id) {
                if (e.target.value !== '') {
                    document.querySelector('#passportNumberMsg' + props.id).innerHTML = "";
                }
            }
            if (e.target.id === 'Number' + props.id) {
                if (e.target.value !== '') {
                    document.querySelector('#NumberMsg' + props.id).innerHTML = "";
                }
            }

        }

        return (<div><input type={controlType} id={keyName}
            className="mb-2 form-control form-control-sm col-lg-12"
            defaultValue={value}
            onBlur={changeHandler} maxLength={maxLength} />
            <span id={'msg' + keyName} className="error-text"></span>
        </div>

        );
    };
    const DropDowndata = ({ keyName, data, value, id, updatePropertyName, placeholder }) => {
        function changeHandler(e) {
            if (document.getElementById("msgtitle" + props.id) !== null) {
                document.getElementById("msgtitle" + props.id).innerHTML = '';
            }
            if (document.querySelector("#genderMsg" + props.id) !== null) {
                document.querySelector("#genderMsg" + props.id).innerHTML = "";
            }
            if (document.querySelector("#dobMsg" + props.id) !== null) {
                document.querySelector("#dobMsg" + props.id).innerHTML = "";
            }
            if (document.getElementById("nationalityMsg" + props.id) !== null) {
                document.getElementById("nationalityMsg" + props.id).innerHTML = "";
            }
            if (document.querySelector("#passportExpiryMsg" + props.id) !== null) {
                document.querySelector("#passportExpiryMsg" + props.id).innerHTML = "";
            }
            if (document.getElementById("passportIssuingCountryMsg" + props.id) !== null) {
                document.getElementById("passportIssuingCountryMsg" + props.id).innerHTML = "";
            }
            if (document.getElementById("identificationTypeMsg" + props.id) !== null) {
                document.getElementById("identificationTypeMsg" + props.id).innerHTML = "";
            }
            if (document.getElementById("identificationIssuingCountryMsg" + props.id) !== null) {
                document.getElementById("identificationIssuingCountryMsg" + props.id).innerHTML = "";
            }

            updateValue(id, e.value, updatePropertyName)
        }

        return (<div><Dropdown textField="ddName"
            dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
            controlClassName='form-control control form-select bg_input mb-0'
            placeholder={placeholder === "" ? "Selcet" : placeholder}
            defaultValue={data.filter(option => option.value === value)}
            onChange={changeHandler}
            options={data}
            id={keyName} />
            <span id={'msg' + keyName} className="error-text"></span></div>);

    };

    function updateValue(id, Value, updatePropertyName) {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
        let setValidationStaus = true;



        if (updatePropertyName === "NamePrefix") {
            bookingJSON.data.travelerInfo.traveler[id - 1].PersonName[updatePropertyName] = Value;
            //bookingJSON.data.travelerInfo.GuestInfo[0].PersonName[updatePropertyName] = Value;
        }
        else if (updatePropertyName === "Gender") {
            bookingJSON.data.travelerInfo.traveler[id - 1][updatePropertyName] = Value;
            //bookingJSON.data.travelerInfo.GuestInfo[0][updatePropertyName] = Value;
        }
        else if (updatePropertyName === "DocHolderNationality") {
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[0][updatePropertyName] = Value;
            //bookingJSON.data.travelerInfo.GuestInfo[0].Document[0][updatePropertyName] = Value;
        }
        else if (updatePropertyName === "DocIssueCountry") {
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[0][updatePropertyName] = Value;
            //bookingJSON.data.travelerInfo.GuestInfo[0].Document[0][updatePropertyName] = Value;
        }
        else if (updatePropertyName === "Date-Date") {
            let strDob = bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date;

            strDob = strDob.substring(0, 4) + strDob.substring(6, 4) + Value;
            bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date = strDob;
        }
        else if (updatePropertyName === "Date-Month") {
            let strDob = bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date;
            strDob = strDob.substring(0, 4) + Value + strDob.substring(8, 6);
            bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date = strDob;
        }
        else if (updatePropertyName === "Date-Year") {
            let strDob = bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date;
            strDob = Value + strDob.substring(6, 4) + strDob.substring(8, 6);
            bookingJSON.data.travelerInfo.traveler[id - 1].dateOfBirth.date = strDob;
        }
        else if (updatePropertyName === "ExpireDate-Date") {
            let strExpireDate = bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate;
            strExpireDate = strExpireDate.substring(0, 4) + strExpireDate.substring(6, 4) + Value;
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate = strExpireDate;
        }
        else if (updatePropertyName === "ExpireDate-Month") {
            let strExpireDate = bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate;
            strExpireDate = strExpireDate.substring(0, 4) + Value + strExpireDate.substring(8, 6);
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate = strExpireDate;
        }
        else if (updatePropertyName === "ExpireDate-Year") {
            let strExpireDate = bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate;
            strExpireDate = Value + strExpireDate.substring(6, 4) + strExpireDate.substring(8, 6);
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[0].ExpireDate = strExpireDate;
        }
        else if (updatePropertyName === "DocIdentificationType") {
            bookingJSON.data.travelerInfo.traveler[id - 1].Document[1]['DocType'] = Value;
        } else if (updatePropertyName === "IdentificationDocIssueCountry") {

            bookingJSON.data.travelerInfo.traveler[id - 1].Document[1]['DocIssueCountry'] = Value;

        }

        sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
    }

    return (

        <div className=''>
            <div className='row my-2'>
                <div className='col-md-8 text-primary h6'><i className='fa fa-user text-12px'></i> Traveler {props.id} {props.paxType === "ADT" ? '(Adult 12 years+)' : props.paxType === "CNN" ? '(Child between 2 and 12 years)' : '(Infant between 15 days and 2 years)'}</div>
                <div className='col-md-6'>

                    <div className='float-lg-end'>
                        {/*    <a className=' ms-1 text-12px text-skyblue'><i className='fa fa-plus'></i> Add Adult</a> */}
                        {/* <a className='btn btn-sm ms-1  text-12px btn-primary'><i className='fa fa-plus'></i> Add Child</a> */}
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-2'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>

                        <DropDowndata keyName={"title" + props.id}
                            value={props.traveler.travelerInfo.traveler[props.id - 1].PersonName.NamePrefix}
                            id={props.id}
                            data={props.paxType === 'ADT' ? titles_name : titles_name_child}
                            updatePropertyName="NamePrefix"></DropDowndata>
                    </div>
                </div>
                <div className='col-md-10'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                <TextBox keyName={"fName" + props.id} value={props.traveler.travelerInfo.traveler[props.id - 1].PersonName.GivenName} Messsage="First Name is required." ValidMessage="First Name is not valid." mandatory={true} regularExpression='^[a-zA-Z ]+$' maxLength={58}></TextBox>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category' mandatory={false} validMandatory={true}>Middle Name</label>
                                <TextBox keyName={"mName" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].PersonName.MiddleName}
                                    Messsage="Middle Name is required." ValidMessage="Middle Name is not valid." mandatory={false} regularExpression='^[a-zA-Z ]+$' maxLength={59}></TextBox>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                <TextBox keyName={"lName" + props.id} value={props.traveler.travelerInfo.traveler[props.id - 1].PersonName.Surname} Messsage="Last Name is required." ValidMessage="Last Name is not valid." mandatory={true} regularExpression='^[a-zA-Z ]+$' maxLength={57}></TextBox>
                            </div>
                        </div>
                        <span id={"totalNameLength" + props.id} className="error-text"></span>
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className='col-md-4'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Gender<span className="error-icon"> *</span></label>

                        <DropDowndata keyName={"gender" + props.id}
                            id={props.id} data={gender_list}
                            value={props.traveler.travelerInfo.traveler[props.id - 1].Gender}
                            updatePropertyName="Gender"></DropDowndata>
                        <span id={"genderMsg" + props.id} className="error-text"></span>
                    </div>
                </div>

                <div className='col-md-8'>
                    <div className='control'>

                        <div className='row'>
                            <div className='col-md-4'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Date of Birth<span className="error-icon"> *</span> <i id={"tooltips" + props.id} className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                <DropDowndata keyName={"dob-Date" + props.id}
                                    id={props.id} data={dateList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].dateOfBirth.date.substring(8, 6)}
                                    updatePropertyName="Date-Date" placeholder="DD"></DropDowndata>
                            </div>
                            <div className='col-md-4'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>&nbsp;</label>
                                <DropDowndata keyName={"dob-Month" + props.id}
                                    id={props.id} data={monthList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].dateOfBirth.date.substring(6, 4)}
                                    updatePropertyName="Date-Month" placeholder="MM"></DropDowndata>
                            </div>
                            <div className='col-md-4'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>&nbsp;</label>
                                <DropDowndata keyName={"dob-Year" + props.id}
                                    id={props.id} data={props.paxType === 'ADT' ? dobYearList : props.paxType === 'CNN' ? dobChildYearList : dobInfantYearList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].dateOfBirth.date.substring(0, 4)}
                                    updatePropertyName="Date-Year" placeholder="YYYY"></DropDowndata>
                            </div>
                        </div>
                        <span id={"dobMsg" + props.id} className="error-text"></span>
                        <ReactTooltip
                            anchorId={"tooltips" + props.id}
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={props.paxType === 'ADT' ? "Age of adult should be more than<br> 12 years on date of travel." : props.paxType === 'CNN' ? 'Age of child should be 2 years to<br> 12 years on date of travel.' : 'Age of infant should be 15 days to<br> 2 years on date of travel.'}
                        />
                    </div>
                </div>



            </div>
            <div className='row'>
                {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Details - </label> */}
            </div>
            <div className='row'>
                <div className="control col-md-3 mb-0 mt-2">
                    <div className="form-switch form-switch-sm padding-left-0 mb-2">
                        <label for="advance" className="mb-2 h6 text-primary me-2"><i className="fas fa-plus-square"></i> Additional</label>
                        <input id="advance" className="form-check-input ms-1" type="checkbox" onChange={() => toggleHandler()} />
                    </div>
                </div>

            </div>
            <div className='row' id={'myDIV' + props.id}>
                <div className='col-md-6'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Nationality<span id={'spnMandatoryIcon' + props.id} className="error-icon d-none"> *</span></label>

                        <DropDowndata keyName={"nationality" + props.id} id={props.id}
                            value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].DocHolderNationality}
                            data={countryOptions} updatePropertyName="DocHolderNationality"></DropDowndata>
                        <span id={"nationalityMsg" + props.id} className="error-text"></span>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport No.<span id={'spnMandatoryIcon' + props.id} className="error-icon d-none"> *</span> </label>

                        <TextBox keyName={"passportNumber" + props.id}
                            value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].DocID}
                            Messsage="Passport No. is required." ValidMessage="Passport No. is not valid." mandatory={false} regularExpression='' maxLength={14}></TextBox>
                        <span id={"passportNumberMsg" + props.id} className="error-text"></span>
                    </div>
                </div>

                <div className='col-md-8'>
                    <div className='control'>
                        <div className='row'>
                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Expiry<span id={'spnMandatoryIcon' + props.id} className="error-icon d-none"> *</span>
                                <i id={"tooltipsPassportExpiry" + props.id} className="fa-solid fa-circle-info text-sky-blue ms-1"></i></label>

                            <div className='col-md-4'>
                                <DropDowndata keyName={"passportExpiry-Date" + props.id}
                                    id={props.id} data={dateList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].ExpireDate.substring(0, 4)}
                                    updatePropertyName="ExpireDate-Date" placeholder="DD"></DropDowndata>
                            </div>
                            <div className='col-md-4 margin-top-15'>
                                {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>&nbsp;</label> */}
                                <DropDowndata keyName={"passportExpiry-Month" + props.id}
                                    id={props.id} data={monthList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].ExpireDate.substring(6, 4)}
                                    updatePropertyName="ExpireDate-Month" placeholder="MM"></DropDowndata>
                            </div>
                            <div className='col-md-4 margin-top-15'>
                                {/* <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>&nbsp;</label> */}
                                <DropDowndata keyName={"passportExpiry-Year" + props.id}
                                    id={props.id} data={expiryYearList}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].ExpireDate.substring(8, 6)}
                                    updatePropertyName="ExpireDate-Year" placeholder="YYYY"></DropDowndata>
                            </div>
                            <span id={"passportExpiryMsg" + props.id} className="error-text"></span>
                        </div>
                        <ReactTooltip
                            anchorId={"tooltipsPassportExpiry" + props.id}
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={"Passport Expiry should be more than <br>6 months from date of travel."}
                        />
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='control'>
                        <label className="h6 text-primary mb-1 col-lg-12 xyx" htmlFor='category'>Issuing Country<span id={'spnMandatoryIcon' + props.id} className="error-icon d-none"> *</span> </label>

                        <DropDowndata keyName={"issueCountry" + props.id}
                            value={props.traveler.travelerInfo.traveler[props.id - 1].Document[0].DocIssueCountry}
                            id={props.id} data={countryOptions} updatePropertyName="DocIssueCountry"></DropDowndata>
                        <span id={"passportIssuingCountryMsg" + props.id} className="error-text"></span>
                    </div>
                </div>
                {props.paxType !== 'INF' && props.paxType !== 'INS' && <div className='control'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-1 col-lg-12" htmlFor='category'>Additional </label>

                                <DropDowndata keyName={"Identification" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[1].DocType}
                                    id={props.id} data={IdentificationOptions} updatePropertyName="DocIdentificationType"></DropDowndata>
                                <span id={"identificationTypeMsg" + props.id} className="error-text"></span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-1 col-lg-12" htmlFor='category'>Number </label>

                                <TextBox keyName={"Number" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[1].DocID}
                                    Messsage="" ValidMessage="" mandatory={false} regularExpression='^[a-zA-Z0-9 ]*$' maxLength={9}></TextBox>
                                <span id={"NumberMsg" + props.id} className="error-text"></span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-1 col-lg-12 xyx" htmlFor='category'>Issuing Country</label>

                                <DropDowndata keyName={"IdentificationIssueCountry" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].Document[1].DocIssueCountry}
                                    id={props.id} data={countryOptions} updatePropertyName="IdentificationDocIssueCountry"></DropDowndata>
                                <span id={"identificationIssuingCountryMsg" + props.id} className="error-text"></span>
                            </div>
                        </div>
                    </div>

                </div>

                }

            </div>
        </div>





    )
}
export default TravellerDetail