import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import ValidateForm from './Components/ValidateForm'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { authenticationOTPCheck } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
import { useHistory } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import AuthContext from '../../../redux/contexts/auth-context'
import { FLIGHTSEARCH, LOADING, MYTRIPS } from '../../../navigation/CONSTANTS';

const Validate = () => {
    useDocumentTitle('OTP Validate')
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(authenticationOTPCheck);
    const [errors, setErrors] = useState();
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];
                        secureLocalStorage.setItem("isCustomerAuthentication",true);
                        history.push(MYTRIPS);

                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct OTP code.',
                    });
                    document.getElementById('InputOTP').value = '';
                }
            }
        }



    }, [status, loadedAuthenticationDetails, authCtx, history])

    const checkOTP = (requestData) => {
        sendRequest(requestData);
    };

    const errorHandler = () => {
        setErrors(null);
    };
    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}

            <section id="common_banner-WithoutAuth1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <ValidateForm checkOTP={checkOTP} isLoading={status === 'pending'} />

        </Fragment>)

};



export default Validate;
