import React, { Fragment, useEffect, useState, Suspense } from 'react';
import RouterConfig from './navigation/RouterConfig'
import { appendScript, removeScript } from './utils';
import { getCustomizeDetails } from './utils/CustomizeUtils'
import "react-tooltip/dist/react-tooltip.css";
import LoadingSpinner from './components/Common/Loading/LoadingSpinner';
import { useLocation } from 'react-router-dom';
function App() {
  //const RouterConfig = React.lazy(() => import('./navigation/RouterConfig'));
  //'--btn-bg-color', "radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd"
  // document.documentElement.style.setProperty('--main-color', "#38c1f5");
  document.documentElement.style.setProperty('--main-color', `${getCustomizeDetails("borderColor")}`);
  document.documentElement.style.setProperty('--bg-navy', `${getCustomizeDetails("headerBannerColor")}`);
  document.documentElement.style.setProperty('--body-bg', `${getCustomizeDetails("backgroundColor")}`);
  document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails("buttonColor")} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails("buttonGradientColor2")}`);
  document.documentElement.style.setProperty('--footer-bg1', `${getCustomizeDetails("footerBackgroundColor")}`);
  document.documentElement.style.setProperty('--text-box-bg-skyblue', `${getCustomizeDetails("textBackgroundColor")}`);
  /*   : radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd; */
  //document.documentElement.style.setProperty('--btn-bg-color', "radial-gradient(circle at 4% 2%,  0%, rgba(87, 29, 127, 0) 97%),");
  //--bg-navy: #020f3b; 
 
  useEffect(() => {
   
    appendScript("./assets/js/jquery-3.6.0.min.js");
    appendScript("./assets/js/bootstrap.bundle.js");
    appendScript("./assets/js/jquery.meanmenu.js");
    appendScript("./assets/js/owl.carousel.min.js");
    appendScript("./assets/js/wow.min.js");
    appendScript("./assets/js/nouislider.min.js");
    appendScript("./assets/js/wNumb.js");
    appendScript("./assets/js/custom.js");
    appendScript("./assets/js/add-form.js");
  


    return () => {
      removeScript("./assets/js/jquery-3.6.0.min.js");
      removeScript("./assets/js/bootstrap.bundle.js");
      removeScript("./assets/js/jquery.meanmenu.js");
      removeScript("./assets/js/owl.carousel.min.js");
      removeScript("./assets/js/wow.min.js");
      removeScript("./assets/js/nouislider.min.js");
      removeScript("./assets/js/wNumb.js");
      removeScript("./assets/js/custom.js");
      removeScript("./assets/js/add-form.js");


    }
  }, []);
  const [loading, isLoading] = useState(false);

  const location = useLocation();
  console.log(location.pathname);
  useEffect(() => {
    if ( location.pathname === "/MFA") {
      isLoading(true);
      setTimeout(() => {
        isLoading(false);
      }, 2000);
      sessionStorage.setItem("LoaderHeader","1");
    }
   
  }, []);
  return (
    <Fragment>
      {loading &&
        <div className="preloader">
          <div className="d-table">
            <div >
              <div >
                <LoadingSpinner></LoadingSpinner>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>}
      <RouterConfig />
    </Fragment>
  );
}

export default App;