import { useState, useRef, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import RightsCheckBoxTree from '../../../../components/Common/CheckBoxTree/RightsCheckBoxTree';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const RoleForm = (props) => {
  const { t } = useTranslation(['translation.Role']);
  /*  */
  const roleInputRef = useRef('');
  const descriptionInputRef = useRef('');

  const [roleInputHasError, setRoleInputHasError] = useState(false);
  const [descriptionInputHasError, setDescriptionInputHasError] = useState(false);
  const [rightsInputHasError, setRightsInputHasError] = useState(false);

  const [roleInvalidInputError, setRoleInvalidInputError] = useState(false);
  const [descriptionInvalidInputError, setDescriptionInvalidInputError] = useState(false);

  const history = useHistory();
  let isRootNodeCreated = false;
  let finalJsonTemp = [];

  useEffect(() => {
    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    window.scrollTo({
      top: 100, 
      behavior: "smooth",
    });

    if (props.loadedProfileDetails.length !== 0) {
      roleInputRef.current.value = `${roleInputRef.current.value === '' ? props.loadedProfileDetails.roleName : roleInputRef.current.value}`;
      descriptionInputRef.current.value = `${descriptionInputRef.current.value === '' ? props.loadedProfileDetails.roleDescription : descriptionInputRef.current.value}`;

    }

    if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {

      isEditMode = parseInt(sessionStorage.getItem('isEditable'));
    }

    if (isEditMode === 0) {
      document.getElementById('pageTitle').innerHTML = ' View Role';
      document.getElementById("role").readOnly = true;
      document.getElementById("description").readOnly = true;
      document.getElementById("btnNew").style.display = "none";
      document.getElementById("btnSave").style.display = "none";
      document.getElementById("btnReset").style.display = "none";
      [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
        el.style.visibility = 'hidden';
      });

      document.getElementById("requiredIndicator").style.display = "none";
    }
    else if (isEditMode === 1) {
      document.getElementById('pageTitle').innerHTML = ' Edit Role';
      document.getElementById("description").focus();
      document.getElementById("role").readOnly = true;
      document.getElementById("description").readOnly = false;
      document.getElementById("btnNew").style.display = "";
      document.getElementById("btnSave").style.display = "";
      document.getElementById("btnReset").style.display = "";
      document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
      document.getElementById("requiredIndicator").style.display = "";
    }

    if (props.loadedProfileDetails.length === 0) {
      document.getElementById("btnNew").style.display = "none";
      document.getElementById('pageTitle').innerHTML = ' New Role';

    }

  }, [props.loadedProfileDetails.length,
  props.loadedProfileDetails.roleDescription,
  props.loadedProfileDetails.roleName]);


  useEffect(() => {
    if (props.loadedProfileDetails.length === 0) {
      document.getElementById("btnSave").click();
    }
  }, [props.loadedProfileDetails.length]);

  function onChangeCheck() {
    setDescriptionInputHasError(false);
  }

  function onBlurCheck() {

    if (roleInputRef.current.value.trim() === '') {
      setRoleInputHasError(true);
      setRoleInvalidInputError(false);
      return;
    }
    else {
      setRoleInputHasError(false);
    }

    if (!roleInputRef.current.value.match('^[a-zA-Z ]+$')) {
      setRoleInvalidInputError(true);
      setRoleInputHasError(false);
      return;
    }
    else {
      setRoleInvalidInputError(false);
    }

    if (descriptionInputRef.current.value.trim() === '') {
      setDescriptionInputHasError(true);
      setDescriptionInvalidInputError(false);
      return;
    }
    else {
      setDescriptionInputHasError(false);
    }

    if (!descriptionInputRef.current.value.match('^[a-zA-Z ]+$')) {
      setDescriptionInvalidInputError(true);
      setDescriptionInputHasError(false);
      return;
    }
    else {
      setDescriptionInvalidInputError(false);
    }

    if (finalJsonTemp[0].length === 0) {
      setRightsInputHasError(true);
      return;
    }
    else {
      setRightsInputHasError(false);
    }

  }

  function submitFormHandler(event) {
    event.preventDefault();

    if (roleInputRef.current.value.trim() === '') {
      roleInputRef.current.focus();
      setRoleInputHasError(true);
      setRoleInvalidInputError(false);
      return;
    }
    else {
      setRoleInputHasError(false);
    }

    if (!roleInputRef.current.value.match('^[a-zA-Z ]+$')) {
      roleInputRef.current.focus();
      setRoleInvalidInputError(true);
      setRoleInputHasError(false);
      return;
    }
    else {
      setRoleInvalidInputError(false);
    }

    if (descriptionInputRef.current.value.trim() === '') {
      descriptionInputRef.current.focus();
      setDescriptionInputHasError(true);
      setDescriptionInvalidInputError(false);
      return;
    }
    else {
      setDescriptionInputHasError(false);
    }

    if (!descriptionInputRef.current.value.match('^[a-zA-Z ]+$')) {
      descriptionInputRef.current.focus();
      setDescriptionInvalidInputError(true);
      setDescriptionInputHasError(false);
      return;
    }
    else {
      setDescriptionInvalidInputError(false);
    }

    if (finalJsonTemp[0].length === 0) {
      setRightsInputHasError(true);
      return;
    }
    else {
      setRightsInputHasError(false);
    }

    let ID;
    if (props.loadedProfileDetails === null || props.loadedProfileDetails.length === 0) {
      ID = '';
    } else {
      ID = props.loadedProfileDetails.id;
    }

    let rootNode = [];
    let childrenNodeTemp = [];
    let rootNodesTitle = [];

    finalJsonTemp[0].forEach(element => {
      const foundNode = rootNodesTitle.some(el => el === element.children[0].value.split('_')[0]);
      if (!foundNode) {
        rootNodesTitle.push(element.children[0].value.split('_')[0]);
        childrenNodeTemp = [];
        childrenNodeTemp.push(element);
        rootNode.push({
          value: element.children[0].value.split('_')[0],
          label: element.children[0].value.split('_')[0],
          children: childrenNodeTemp,
        })
      }
      else {
        childrenNodeTemp.push(element);
      }
    });

    if (sessionStorage.getItem('rootNoChild').split(',').length > 0) {
      sessionStorage.getItem('rootNoChild').split(',').forEach(element => {
        if (element !== '') {
          rootNode.push({
            value: element,
            label: element,
            children: [],
          })
        }
      });
    }
    //console.log(rootNode);

    props.onAddRole({
      id: ID,
      traceID: JSON.parse(localStorage.getItem('traceID')),
      userID: JSON.parse(localStorage.getItem('userID')),
      officeID: JSON.parse(localStorage.getItem('officeID')),
      roleName: roleInputRef.current.value,
      roleDescription: descriptionInputRef.current.value,
      moduleDetail: rootNode,
    });
  }

  function changeLocation(event) {
    if (event.target.id === 'btnNew') {
      history.push('/UpdateRole')
      history.go()
    }

    if (event.target.id === 'btnReset') {
      if (props.loadedProfileDetails.length === 0) {
        history.go('/UpdateRole')
      }
      else {
        history.go(`/UpdateRole/${props.loadedProfileDetails.id}`)
      }
    }
  }

  // function titleCase(value) {
  //   return value.toLowerCase().split(' ').map(function(word) {
  //     return (word.charAt(0).toUpperCase() + word.slice(1));
  //   }).join(' ');
  // }

  const fetchSelectedItem = (value) => {
    sessionStorage.removeItem('rootNoChild');
    if (value.length > 0) {
      setRightsInputHasError(false);
    }
    else {
      setRightsInputHasError(true);
    }

    let inputValue = value.filter(s => ~s.indexOf("_"));
    let parentTemp = [];
    let childrenTemp = [];
    let moduleName = [];
    let rootCreatedModules = [];
    let rootNoChildTemp = value.filter(s => s.indexOf("_") < 0);

    if (sessionStorage.getItem('isEditable') === "1") {
      value.filter(s => ~s.indexOf("_")).forEach(elementOuter => {
        const indexMaster = rootNoChildTemp.indexOf(elementOuter.split('_')[0]);
        if (indexMaster > -1) {
          rootNoChildTemp.splice(indexMaster, 1);
        }
        const indexChild = rootNoChildTemp.indexOf(elementOuter.split('_')[1]);
        if (indexChild > -1) {
          rootNoChildTemp.splice(indexChild, 1);
        }
      });
    }
    sessionStorage.setItem('rootNoChild', rootNoChildTemp);

    if (inputValue.length !== 0) {
      inputValue.forEach(items => {
        moduleName.push(items.toString().split('_')[1])

        let uniqueModules = [...new Set(moduleName)];

        let itemsTemp = {
          value: items.toString(),
          label: items.toString().split('_')[2]
        };

        const foundChildren = childrenTemp.some(el => el.value === items);
        if (foundChildren === false) {
          childrenTemp.push(itemsTemp);
        }

        // const result = temp2
        //     .map(item => ({
        //         ...item,
        //         children: item.children
        //             .filter(child => child.value.includes(items.toString().split('_')[1]))
        //     }))
        //     .filter(item => item.children.length > 0)

        const found = rootCreatedModules.find(element => element === items.toString().split('_')[1]);
        if (found === undefined) {
          rootCreatedModules.push(items.toString().split('_')[1]);
          if (isRootNodeCreated === false) {
            let temp =
            {
              // value: items.toString().split('_')[1],
              // label: items.toString().split('_')[1],
              // children: [{
              //   value: items.toString().split('_')[1],
              //   label: items.toString().split('_')[1],
              //   children: childrenTemp.filter(newData => {
              //     return newData.value.toString().split('_')[1] === items.toString().split('_')[1];
              //   }),
              // }]


              value: items.toString().split('_')[1],
              label: items.toString().split('_')[1],
              children: childrenTemp.filter(newData => {
                return newData.value.toString().split('_')[1] === items.toString().split('_')[1];
              }),

            }
            isRootNodeCreated = true;
            parentTemp.push(temp);
          }
          else {
            let temp =
            {
              children: [{
                value: items.toString().split('_')[1],
                label: items.toString().split('_')[1],
                children: childrenTemp.filter(newData => {
                  return newData.value.toString().split('_')[1] === items.toString().split('_')[1];
                }),
              }]
            }
            parentTemp.push(temp.children[0]);
          }
        }
        else {
          let index = 0;
          uniqueModules.forEach(element => {
            if (parentTemp[index] !== undefined) {
              // parentTemp[0].children[0].children = childrenTemp.filter(newData => {
              //     return newData.value.toString().split('_')[1] === element;
              // });

              // parentTemp[index][0].children[0] = childrenTemp.filter(newData => {
              //     return newData.value.toString().split('_')[1] === element;
              // });

              // parentTemp[index][0].children = childrenTemp.filter(newData => {
              //   return newData.value.toString().split('_')[1] === element;
              // });

              parentTemp[index].children = childrenTemp.filter(newData => {
                return newData.value.toString().split('_')[1] === element;
              });
            }
            index++;
          });
        }
      });
    }

    finalJsonTemp = [];
    finalJsonTemp.push(parentTemp);
  }

  return (
    <Fragment>
      <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-30">
              <div className="card card-primary card-outline">
                {props.isLoading && (
                  <div className='loading'>
                    <LoadingSpinner />
                  </div>
                )}
                <div className="card-body">
                  <div className="col-md-12 ">
                    <div className="form-group col-md-12">
                      <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>
                      <form className='form'>

                        <div className='row'>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control input-placeholder'>
                              <label htmlFor='role' className='h6 text-primary mb-2'>{t('role')}<span className="error-icon"> *</span></label>
                              <input maxLength={50} className="form-control form-control-sm" type='text' required
                                id='role'
                                ref={roleInputRef}
                                onBlur={onBlurCheck}
                              />
                              {roleInputHasError &&
                                <p className="error-text">{t('validationRole')}</p>
                              }
                              {roleInvalidInputError &&
                                <p className="error-text">{t('validationRoleInvalidData')}</p>
                              }
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control input-placeholder'>
                              <label className='h6 text-primary mb-2' htmlFor='description'>{t('description')}<span className="error-icon"> *</span></label>
                              <input maxLength="300" className='form-control form-control-sm ' required type='text'
                                id='description'
                                ref={descriptionInputRef}
                                onBlur={onBlurCheck}
                                onChange={onChangeCheck}
                              />
                              {descriptionInputHasError &&
                                <p className="error-text">{t('validationDescription')}</p>
                              }
                              {descriptionInvalidInputError &&
                                <p className="error-text">{t('validationDescriptionInvalidData')}</p>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='control tree-label-mb-05'>
                            <label className='h6 text-primary mb-2' htmlFor='rights'>{t('rights')}<span className="error-icon"> *</span></label>
                            <RightsCheckBoxTree fetchSelectedItem={fetchSelectedItem} allRights={props.loadedProfileDetailsRights} selectedRights={props.loadedProfileDetails.moduleDetail} />
                            {rightsInputHasError &&
                              <p className="error-text">{t('validationRights')}</p>
                            }
                          </div>
                        </div>
                        <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                      </form>

                      <div className='mt-3 actions row'>
                        <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                          <a href="/SearchRole" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                          <div className="float-md-end float-lg-end">
                            <a href="/SearchRole" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                            {(checkSecurityDetails("User Management", "Role", "User Management_Role_Insert") || checkSecurityDetails("User Management", "Role", "User Management_Role_Update")) ?
                              <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                {t('save')}
                              </button>
                              : <span id="btnNew"></span>}
                            {checkSecurityDetails("User Management", "Role", "Master_Role_Insert") ?
                              <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                {t('new')}
                              </button> : <span id="btnNew"></span>}
                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                              {t('reset')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RoleForm;