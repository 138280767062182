import sorter from "sort-nested-json";

export const sortHotelResult = (sortOption, hotelResults, sortBy) => {
    if (sortOption === '1') {
        var arrayTemp = [];
        hotelResults.forEach(element1 => {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                arrayTemp.push({
                    hotelName: element1.hotelName,
                    sequenceId: element1.sequenceId
                });
            }
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("hotelName") : sorter.sort(arrayTemp).desc("hotelName");

        let hotelResultsTemp = [];
        list.forEach(element => {
            hotelResultsTemp.push(
                hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
            );
        });

        hotelResults = hotelResultsTemp;
    }
    else if (sortOption === '2') {
        var arrayTemp = [];
        hotelResults.forEach(element1 => {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                arrayTemp.push({
                    roomPrice: parseFloat(element1.price[0].roomPrice),
                    sequenceId: element1.sequenceId
                });
            }
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("roomPrice") : sorter.sort(arrayTemp).desc("roomPrice");

        let hotelResultsTemp = [];
        list.forEach(element => {
            hotelResultsTemp.push(
                hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
            );
        });

        hotelResults = hotelResultsTemp;
    }
    else if (sortOption === '3') {        
        var arrayTemp = [];
        var arrayTemp2 = [];
        hotelResults.forEach(element1 => {
            if (element1.roomRateDetails[0].infoSource === 'API') {
                const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                if (!found) {
                    arrayTemp.push({
                        roomPrice: parseFloat(element1.price[0].roomPrice),
                        sequenceId: element1.sequenceId
                    });
                }
            }
            else {
                const found = arrayTemp2.find(el => el.sequenceId === element1.sequenceId);
                if (!found) {
                    arrayTemp2.push({
                        roomPrice: parseFloat(element1.price[0].roomPrice),
                        sequenceId: element1.sequenceId
                    });
                }
            }
        });

        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("roomPrice") : sorter.sort(arrayTemp).desc("roomPrice");
        let list2 = sortBy === 'asc' ? sorter.sort(arrayTemp2).asc("roomPrice") : sorter.sort(arrayTemp2).desc("roomPrice");

        let hotelResultsTemp = [];
        list.forEach(element => {
            hotelResultsTemp.push(
                hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
            );
        });

        list2.forEach(element => {
            hotelResultsTemp.push(
                hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
            );
        });

        hotelResults = hotelResultsTemp;
    }

    return hotelResults;
}