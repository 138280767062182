import { Fragment } from 'react';
const LoadingSightseeing = (props) => {

  return (
    <Fragment>
      <div className='loading'>

        <div className="loader flight-loader-width">
          <div className="plane">
            <img src="assets/img/loader5.gif" className="plane-img" />
          </div>
          <div className='postion-relative flight-loaders'>
            <div className="earth-wrapper">
              <div className="earth"></div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {
            props.action === 'search' &&
            <div className='position-absolute bottom-0 text-center w-100'>
              <div className='mb-4'>
                Please wait for a few seconds…                
              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>)
}
export default LoadingSightseeing;
