import {
    GET_CONTENTWISE_REGION,
    UPDATE_FEES,
    ADD_FEES,
    SEARCH_FEES,
    GET_FEES,
    DELETE_FEES
}
    from './CONSTANTS'

export async function searchContentWiseRegion(searchData) {
    // console.log(searchData)
    const response = await fetch(GET_CONTENTWISE_REGION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch To ContentWiseRegion');
    }

    console.log(data)
    return data;


}
export async function deleteFees(deleteData) {
    const response = await fetch(DELETE_FEES(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete fees.');
    }
    return data;
}
export async function updateFees(updateData) {
    // console.log(updateData);
    const response = await fetch(UPDATE_FEES(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update Fees');
    }
    // console.log(data);
    return data;

}

export async function addFees(addData) {
    //  console.log(addData);
    const response = await fetch(ADD_FEES(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add Fees');
    }
    //  console.log(data);
    return data;

}
export async function getSingleFees(ID) {
    // console.log(ID);
    const response = await fetch(GET_FEES(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch content');
    }
    // console.log(data);
    return data;
}

export async function searchFees(searchData) {
    console.log(searchData)
     const response = await fetch(SEARCH_FEES(), {
         method: 'POST',
         body: JSON.stringify(searchData),
         headers: {
 
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + localStorage.getItem('token'),
         },
 
     });
     const data = await response.json()
     
     if (!response.ok) {
         throw new Error(data.message || 'could not fetch Fees');
     }
   
    console.log(data)
     return data;
 
 
 }