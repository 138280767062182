import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";

import HotelBookingList from "./HotelBookingList";

export default function ExportExcel(props) {
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Hotel Booking Report",
        sheet: "Hotel Booking"
    });

    return (
        <div onClick={onDownload} className="btn btn-sm text-white mt-1 ms-1 btn-success">

            <span id="excel"><i className="fas fa-file-excel"></i>

            </span>
            <table ref={tableRef} style={{ display: "none" }}>
                <HotelBookingList currentItems={props.reportJSON}></HotelBookingList>
            </table>
        </div>
    );
}
