import { Fragment, useEffect } from 'react';
import LoginWebForm from './Components/LoginWebForm'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { getIPData } from "../../../services/Geolocation-API"
const LoginWeb = () => {
    useDocumentTitle('Login');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getIPData();
    },)
    sessionStorage.setItem("LoaderHeader", "1");
    document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd;`);
    return (
        // <LoginForm /> 
        <Fragment>
            {/*     <!-- Common Banner Area --> */}

            <section className='gt_login_banner'>
                <div className='position-relative'>
                    <span className="logohide">
                        <img src="assets/img/login_Globetrotter_logo.png" alt="" className='log-in-head-logo-bg position-absolute' />
                    </span>
                </div>
            </section>

            <img src="assets/img/globetrotterdark.png" alt="globetrotter" className='loginscroll-logo' />

            <div className="container">
                <LoginWebForm />
            </div>
        </Fragment>)
};



export default LoginWeb;
