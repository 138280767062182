import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PrintBookingDetails = (props) => {
    let pnr = props.hotelBookingJSON.data.bookingReferenceID !== undefined ?
        props.hotelBookingJSON.data.bookingReferenceID.id : null;
    const [isCanceled, setIsCanceled] = useState(false);
    const location = useLocation();
    useEffect(() => {

        if (location.hotelTrip !== undefined) {
            setIsCanceled(location.hotelTrip.isCanceled);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsHotelCancelled") === 'true' ? true : false)
        }

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])
    return <Fragment>
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
        {props.hideData !== '1' && <div className='col-md-12'>
            <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Booking Details <span class={props.hotelBookingJSON.data.bookingType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null ? "badge h6 rounded-pill mb-0 bg-success" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={props.hotelBookingJSON.data.bookingType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null ? "fa-solid fa-check-circle" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {props.hotelBookingJSON.data.bookingType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : pnr !== null ? "Booked" : "Failed"}</span></label>
        </div>}

        <div className='row mt-2'>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    <div className='text-muted h6 mb-2'>
                        <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{props.hotelBookingJSON.data.bookingReferenceNumber}</span>
                        <span>{props.hotelBookingJSON.data.bookingReferenceID !== undefined && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{props.hotelBookingJSON.data.bookingReferenceID.id}</span></span>} </span>
                        <span>{props.hotelBookingJSON.data.bookingReferenceID !== undefined && <span>| <b className='text-primary'>Hotel Confirmation No.  </b> <span className='text-black'>{props.hotelBookingJSON.data.hotelResults[0].confirmationNumber}</span></span>} </span>
                    </div>
                    {/* <span> | <b className='text-primary'>PNR </b> <span className='text-black'>12344</span></span> */} </div>
            </div>
        </div>
    </Fragment>

};

export default PrintBookingDetails;