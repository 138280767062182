// import { Fragment } from 'react';
import { Fragment } from 'react';
import FlightBookingItem from './FlightBookingItem';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';


const TravelDeviationList = (props) => {
    if (Object.keys(props.currentItems).length > 0) {
        props.currentItems.push({
            "shoppingID": "",
            "sequenceNo": "0",
            "confirmationNumber": "",
            "bookingData": "",
            "officeID": "",
            "companyName": "",
            "passengerName": "",
            "passengerCount": "",
            "bookingType": "",
            "departureDate": "",
            "arrivalDate": "",
            "from": "",
            "to": "",
            "validating": "",
            "airline": "",
            "rbd": "",
            "cabin": "",
            "source": "",
            "pnr": "",
            "airlinePNR": "",
            "ticketNumber": "",
            "status": "",
            "currencyCode": "",
            "baseFare": "",
            "taxes": "",
            "markup": "",
            "totalFare": "",
            "commission": "",
            "formOfPayment": "",
            "userID": "",
            "equivTotalFare": props.currentItems.equivTotalFare !== undefined && props.currentItems.equivTotalFare !== '' ? props.currentItems.map(a => parseFloat(a.equivTotalFare)).reduce((equivTotalFare1, equivTotalFare2) => equivTotalFare1 + equivTotalFare2).toFixed(2) : '',
            "bookedBy": ""
        })
    }

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    return <Fragment>

        <div className="col-lg-12" >{/* className="accordion-item"> */}
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <td colSpan={10}></td>
                                            <td colSpan={7} className='h5 fw-bold '>Selected Flights</td>
                                            <td colSpan={7} className='h5 fw-bold '>Lowest Flights</td>
                                            <td colSpan={2}></td>
                                        </tr>
                                        <tr>
                                            <th scope='col' className='text-end w-15'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='min-width-185'>Status</th>
                                            <th scope="col" className='w-15'>Company Name</th>
                                            <th scope="col" className='min-width-210' align={'left'}>Employee Name</th>
                                            <th scope="col" className='min-width-125' align={'left'}>Employee Code</th>
                                            <th scope="col" className='min-width-210'>Position</th>
                                            <th scope="col" className='min-width-125'>Itinerary Type</th>
                                            <th scope="col" className='min-width-125'>Cabin Class</th>
                                            <th scope="col" className='min-width-210'>Flight No.</th>
                                            <th scope="col" className='min-width-125'>Flight Date</th>
                                            <th scope="col" className='min-width-125'>Departure Time</th>
                                            <th scope="col" className='min-width-125'>From</th>
                                            <th scope="col" className='min-width-210'>To</th>
                                            <th scope="col" className='w-15'>Currency</th>
                                            <th scope="col" className='min-width-125 text-center' >Fare</th>
                                            <th scope="col" className='min-width-210'>Flight No.</th>
                                            <th scope="col" className='min-width-125'>Flight Date</th>
                                            <th scope="col" className='min-width-125'>Departure Time</th>
                                            <th scope="col" className='min-width-125'>From</th>
                                            <th scope="col" className='min-width-210'>To</th>
                                            <th scope="col" className='w-15'>Currency</th>
                                            <th scope="col" className='min-width-125 text-center'>Fare</th>
                                            <th scope="col" className='min-width-125'>Missed Saving</th>
                                            <th scope="col" className='min-width-210'>Reason</th>
                                            <th scope="col" className='min-width-210'>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <FlightBookingItem ></FlightBookingItem>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default TravelDeviationList;