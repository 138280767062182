import Dropdown from 'react-select';
import MealDetails from './MealDetails';

const AdditionalService = (props) => {
    const TextBox = ({ keyName, Messsage, ValidMessage, mandatory, regularExpression, controlType = "text", value, updatePropertyName, maxLength = 300 }) => {

        function changeHandler(e) {

            if (mandatory && e.target.value === '') {
                document.getElementById('msg' + keyName).innerHTML = Messsage;

            } else if (regularExpression !== '' && (e.target.value !== '' && !e.target.value.match(regularExpression))) {
                document.getElementById('msg' + keyName).innerHTML = ValidMessage;
            }
            else {
                document.getElementById('msg' + keyName).innerHTML = '';
            }

            if (e.target.id === 'frequentFlyerNo' + props.id) {
                if (e.target.value !== '') {
                    document.querySelector('#frequentFlyerNoMsg' + props.id).innerHTML = "";
                }
            }

            updateValue('', e.target.value, updatePropertyName, props.id)
        }

        return (<div><input type={controlType} id={keyName}
            className="mb-2 form-control form-control-sm col-lg-12"
            defaultValue={value}
            onBlur={changeHandler} maxLength={maxLength} />
            <span id={'msg' + keyName} className="error-text"></span>
        </div>

        );
    };
    const DropDowndata = ({ keyName, data, value, id, updatePropertyName, placeholder }) => {

        function changeHandler(e) {
            if (document.querySelector("#msg" + keyName) !== null) {
                document.querySelector("#msg" + keyName).innerHTML = "";
            }

            updateValue(id, e.value, updatePropertyName, props.id)
        }

        return (<div><Dropdown textField="ddName"
            dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
            controlClassName='form-control control form-select bg_input mb-0'
            placeholder={placeholder === "" ? "Selcet" : placeholder}
            defaultValue={data.filter(option => option.value === value)}
            onChange={changeHandler}
            options={data}
            id={keyName} />
            <span id={'msg' + keyName} className="error-text"></span></div>);
    };

    let specialServiceRequestTemp = [];
    function updateValue(id, Value, updatePropertyName, passengerSequence) {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));

        let found = specialServiceRequestTemp.find(element => element.PassengerNo === passengerSequence && element.ServiceType === updatePropertyName);
        if (found) {
            let specialServiceRequestTemp2 = JSON.parse(sessionStorage.getItem("AdditionalServicesData"));
            let specialServiceRequestTemp3 = [];
            specialServiceRequestTemp2.forEach(element => {
                if (element.ServiceType !== updatePropertyName) {
                    specialServiceRequestTemp3.push(element);
                }
                else {
                    if (element.PassengerNo !== passengerSequence) {
                        specialServiceRequestTemp3.push(element);
                    }
                }
            });

            if (Value !== '') {
                specialServiceRequestTemp3.push({
                    "PassengerNo": passengerSequence,
                    "ServiceType": updatePropertyName,
                    "ServiceCode": Value
                });
            }
            specialServiceRequestTemp = specialServiceRequestTemp3;
        }
        else {
            if (sessionStorage.getItem("AdditionalServicesData") === null) {
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value
                    });
                }
            } else {
                specialServiceRequestTemp = JSON.parse(sessionStorage.getItem("AdditionalServicesData"));
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value
                    });
                }
            }
        }

        sessionStorage.setItem("AdditionalServicesData", JSON.stringify(specialServiceRequestTemp));
        //console.log(specialServiceRequestTemp);
        
    }

    const onSelectAllFlightsChangeHandler = (event, value) => {
        if (event.target.checked) {

            for (let connectionCounter = 1; connectionCounter < 10; connectionCounter++) {
                if (document.querySelector('#dvMeal' + connectionCounter + value) !== null) {
                    document.querySelector('#dvMeal' + connectionCounter + value).style.display = "none";
                }
            }

            document.querySelector('#dvMeal1' + value).style.display = "";
        }
        else {
            for (let connectionCounter = 1; connectionCounter < 10; connectionCounter++) {
                if (document.querySelector('#dvMeal' + connectionCounter + value) !== null) {
                    document.querySelector('#dvMeal' + connectionCounter + value).style.display = "";
                }
            }
        }
    }

    return (
        <div >
            <div className='row'>
                <div className='col-md-12'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12"
                            htmlFor='category'>
                            <i className='fa fa-user text-12px'></i> Traveler Name :  <span className='text-black' id={'passengerName' + props.id}></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                {
                    props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode !== 'INF' && props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode !== 'INS' &&
                    <>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12"
                                    htmlFor='category'>Frequent Flyer Airline</label>
                                <DropDowndata keyName={"airlineProgram" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].CustLoyalty !== null ? props.traveler.travelerInfo.traveler[props.id - 1].CustLoyalty[0].ProgramID : ''}
                                    id={props.id}
                                    data={props.arilineProgramOptions}
                                    updatePropertyName={"AirlineProgram" + "_" + props.id}>
                                </DropDowndata>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='control'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Frequent Flyer No. </label>
                                <TextBox keyName={"frequentFlyerNo" + props.id}
                                    value={props.traveler.travelerInfo.traveler[props.id - 1].CustLoyalty !== null ? props.traveler.travelerInfo.traveler[props.id - 1].CustLoyalty[0].MembershipID : ''}
                                    Messsage="Frequent Flyer No. is required." ValidMessage="Frequent Flyer No. is not valid."
                                    mandatory={false} regularExpression='^[a-zA-Z0-9]+$' updatePropertyName={"FrequentFlyerNo" + "_" + props.id} maxLength="10"></TextBox>
                                <span id={"frequentFlyerNoMsg" + props.id} className="error-text"></span>
                            </div>
                        </div>
                    </>
                }
                <div className='col-md-4'>
                    <div className='control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Special Service</label>
                        <DropDowndata keyName={"specialService" + props.id}
                            value={props.traveler.travelerInfo.SpecialReqDetails[0] !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== null && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'Service' && a.TravelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'Service' && a.TravelerRefNumber === props.id)[0].SSRCode : ''}
                            id={props.id}
                            data={props.specialServiceOptions}
                            updatePropertyName={"SpecialService" + "_" + props.id}>
                        </DropDowndata>
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Meal Preference</label>
                </div>
                <div className='col-md-6 d-none'>
                    {
                        (sessionStorage.getItem('tripType') == 'oneway' && (sessionStorage.getItem('noOfStopsOwDep').trim() !== '0')) &&
                        <div className="form-check float-lg-end">
                            <input className="form-check-input" type="checkbox" id={"chkSelectAllFlights" + props.id} onClick={(e) => onSelectAllFlightsChangeHandler(e, props.id)}></input>
                            <label className="form-check-label" for="">
                                Same meal for all flights
                            </label>
                        </div>
                    }
                    {
                        (sessionStorage.getItem('tripType') == 'roundtrip') &&
                        <div className="form-check float-lg-end">
                            <input className="form-check-input" type="checkbox" id={"chkSelectAllFlights" + props.id} onClick={(e) => onSelectAllFlightsChangeHandler(e, props.id)}></input>
                            <label className="form-check-label" for="">
                                Same meal for all flights
                            </label>
                        </div>
                    }
                    {
                        (sessionStorage.getItem('tripType') == 'multi_city') &&
                        <div className="form-check float-lg-end">
                            <input className="form-check-input" type="checkbox" id={"chkSelectAllFlights" + props.id} onClick={(e) => onSelectAllFlightsChangeHandler(e, props.id)}></input>
                            <label className="form-check-label" for="">
                                Same meal for all flights
                            </label>
                        </div>
                    }
                </div>

            </div>

            <div className='row'>
                {props.traveler.airItinerary[0].originDestinationOptions.originDestinationOption.map((traveler) => <MealDetails
                    key={props.key}
                    id={props.id}
                    //mealDetailsOptions={props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'INF' || props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'INS' ? props.mealDetailsOptions.filter(a => a.value === 'BBML') : props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'CNN' ? props.mealDetailsOptions.filter(a => a.value === 'CHML') : props.mealDetailsOptions}
                    mealDetailsOptions={props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'INF' ? props.mealDetailsOptions.filter(a => a.value === 'BBML') : props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'INS' ? props.mealDetailsOptions.filter(a => a.value !== 'BBML') : props.traveler.travelerInfo.traveler[props.id - 1].PassengerTypeCode === 'CNN' ? props.mealDetailsOptions.filter(a => a.value !== 'BBML') : props.mealDetailsOptions.filter(a => a.value !== 'BBML' && a.value !== 'CHML')}
                    traveler={props.traveler}
                    segment={traveler}>
                </MealDetails>)}

            </div>

        </div>
    )
}
export default AdditionalService