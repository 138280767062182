import {
    GET_ALL_ROLE,
    GET_ROLE,
    ADD_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    GET_ROLE_NAME,
    GET_ALL_ROLE_DETAILS,
    GET_ROLE_LIST,
} from './CONSTANTS'

export async function getAllRole() {
    const response = await fetch(GET_ALL_ROLE(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch role.');
    }    
    return data;
}

export async function getRoleByName(name) {
    const response = await fetch(GET_ROLE_NAME(name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch role.');
    }
    return data;
}

export async function getSingleRole(ID) {
    const response = await fetch(GET_ROLE(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch role.');
    }
    return data;
}

export async function deleteSingleRole(deleteData) {    
    const response = await fetch(DELETE_ROLE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not delete role.');
    }    
    return data;
}

export async function addRole(addData) {   
    const response = await fetch(ADD_ROLE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create role.');
    }    
    return data;
}

export async function updateRole(updateData) {
    const response = await fetch(UPDATE_ROLE(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update role.');
    }
    return data;
}

export async function getAllRoleDetails(ID) {    
    const response = await fetch(GET_ALL_ROLE_DETAILS(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch role details.');
    }
    return data;
}

export async function getRoleList() {
    
    const response = await fetch(GET_ROLE_LIST(), {
	
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch role list.');
    }    
    console.log(data);
    return data;
	
}
