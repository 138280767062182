

import { Fragment } from 'react';

const BookingLoadingSpinner = (props) => {
  return (
    <Fragment>
      {/* <div className='centered'>
        <div className={classes.spinner}></div>
      </div> */}


      <div className='loading'>
        <div className="loader flight-loader-width">
          <div className="plane">
            <img src="assets/img/loader5.gif" className="plane-img" />
          </div>
          <div className='postion-relative flight-loaders'>
            <div className="earth-wrapper">
              <div className="earth"></div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {
            props.action === 'shopping' &&
            <div className='position-absolute gt-bottom-15 text-bottom w-100'>
              Please wait while the airline and hotel reservation systems confirm your bookings.
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          }
          {
            props.action === 'flight' &&
            <div className='position-absolute gt-bottom-15 text-bottom w-100'>
              Please wait while the airline reservation system confirms your booking.

              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          }
          {
            props.action === 'requestTypeOf' &&
            <div className='position-absolute gt-bottom-15 text-bottom w-100'>
              Please wait while your booking request is submitted.
            </div>
          }
        </div>
      </div>
    </Fragment>)
}

export default BookingLoadingSpinner;
