import useHttp from '../../../services/use-http'
import { useEffect, useState } from 'react';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const AdditionalServices = (props) => {
    const [remarksGeneralInvalidInputError, setRemarksGeneralInvalidInputError] = useState(false);

    function assignAdditionalService(event) {
        event.preventDefault();

        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                document.querySelector('#remarksGeneral').focus();
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }

        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        let setValidationStaus = true;

        hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.forEach(element => {
            if (element.RemarkType === "General") {
                element.Text = document.querySelector('#remarksGeneral').value;
            }
            else if (element.RemarkType === "InternalRemarks") {
                element.Text = document.querySelector('#remarksInternal').value;
            }
        });

        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
        console.log(hotelBookingJSON)

        if (setValidationStaus) {
            props.onContinueButton("3");
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
        }
    }
    function skipAdditionalService() {
        props.onContinueButton("3");
        window.scrollTo({
            top: 400,
            behavior: "smooth",
        });
    }

    function onBlurCheck() {
        if (document.querySelector('#remarksGeneral').value !== '') {
            if (!document.querySelector('#remarksGeneral').value.match('^[a-zA-Z 0-9/,-/]+$')) {
                setRemarksGeneralInvalidInputError(true);
                return;
            }
            else {
                setRemarksGeneralInvalidInputError(false);
            }
        }
    }

    return (<div className="accordion_flex_area">
        <div className="accordion_left_side position-relative text-muted">
            <h5>3</h5>
            <span id="completeThree" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button id="headingThree" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseThree"
                    aria-expanded="false" aria-controls="collapseThree">Remarks
                </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse"
                aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                <div className='row'>
                    <div className='control input-placeholder'>
                        <label htmlFor='remarksGeneral' className='h6 text-primary mb-2'>Booking Remarks</label>
                        <textarea id='remarksGeneral'
                            //value={remarksGeneral}
                            className="form-control form-control-sm"
                            //onChange={(e) => setRemarksGeneral(e.target.value)}
                            rows={2} cols={100} maxLength={300}
                            onBlur={onBlurCheck}
                        />
                    </div>
                    {remarksGeneralInvalidInputError &&
                        <span className="error-text col-lg-12 mt-3">{('Booking Remarks is not valid.')}</span>
                    }
                    <div class="text-primary h6 mt-3">
                        <span class="h6 text-black fw-bold">Note :</span>
                        <span class="ms-1">These remarks are <strong>public.</strong> They are sent to the content provider and are visible in the booking confirmation.</span>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='control input-placeholder'>
                        <label htmlFor='remarksInternal' className='h6 text-primary mb-2'>Internal Notes</label>
                        <textarea id='remarksInternal'
                            //value={remarksInternal}
                            className="form-control form-control-sm"
                            //onChange={(e) => setRemarksInternal(e.target.value)}
                            rows={2} cols={100} maxLength={300}
                        />
                    </div>
                    <div class="text-primary h6 mt-3">
                        <span class="h6 text-black fw-bold">Note :</span>
                        <span class="ms-1">These remarks are <strong>private</strong>. These can only be seen in <strong>My Trips</strong> by authorized users within your office.</span>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className="col-lg-6 col-md-12 col-sm-12 my-auto">
                        <p><span className="error-icon"></span><span></span></p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="float-md-end float-lg-end">
                            <button type="button" onClick={skipAdditionalService} className="btn margin-top-15  text-white btn-secondary ms-1 btn-sm">{getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? 'Skip to Add Service Fee' : 'Skip to Payment'}</button>
                            <button type="button" onClick={assignAdditionalService} className="btn margin-top-15 text-white gt-main-btn ms-1 btn-sm">Continue</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>)
}
export default AdditionalServices