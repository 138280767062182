import {
    GET_USER_FRIENDLY,
    ADD_USER_FRIENDLY,
    UPDATE_USER_FRIENDLY, 
    SEARCH_USER_FRIENDLY   
} from './CONSTANTS'


export async function searchUserFriendly(searchData) {
    const response = await fetch(SEARCH_USER_FRIENDLY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch User Friendly.');
    }
    
    return data;
}
export async function getSingleUserFriendly(ID) {
    const response = await fetch(GET_USER_FRIENDLY(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch User Friendly.');
    }

    return data;
}

export async function addUserFriendly(addData) {
    console.log(addData)
    const response = await fetch(ADD_USER_FRIENDLY(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create User Friendly.');
    }
    return data;
}
export async function updateUserFriendly(updateData) {    
    const response = await fetch(UPDATE_USER_FRIENDLY(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update User Friendly.');
    }
    return data;
}

