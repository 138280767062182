/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to 
 * keep your app working for UI changes and 
 * make it independent of network requirements.
 * 
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 * 
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Actual endpoints. Uncomment below section to use actual data.
// export const GET_ALL_USERS = () => `https://jsonplaceholder.typicode.com/users`;
// export const GET_USER_DETAILS = (id) => `https://jsonplaceholder.typicode.com/users/${id}`;


/// Local endpoints. Uncomment below section to use dummy local data.
//const CONTRACT_PATH='http://localhost:9010/api'
//const CONTRACT_PATH='https://localhost:7198/api/v1'

// Local endpoints. Uncomment below section to use dummy local data.
const CONTRACT_PATH = process.env.REACT_APP_CONTRACT_URL
//const CONTRACT_PATH='http://localhost:9002/api/v1'
//const CONTRACT_PATH_BOOKING='https://uat.fareladder.com/bookingapi/api/v1'


//Authentication API
export const GET_AUTHENTICATION_DETAILS = () => `${CONTRACT_PATH}/Authentication`;
export const UPDATE_FORGOT_PASSWORD = () => `${CONTRACT_PATH}/UserManagement/ForgotPassword`;
export const UPDATE_CHANGE_PASSWORD = () => `${CONTRACT_PATH}/UserManagement/ChangePassword`;
export const UPDATE_PASSWORD = () => `${CONTRACT_PATH}/UserManagement/UpdatePassword`;
//export const GET_AUTHENTICATION_DETAILS= () => `${CONTRACT_PATH}/Auth`;  
//Continent API
export const GET_ALL_CONTINENT_DETAILS = () => `${CONTRACT_PATH}/Continent`;
export const GET_CONTINENT_DETAILS = (id) => `${CONTRACT_PATH}/Continent/${id}`;
export const ADD_CONTINENT_DETAILS = () => `${CONTRACT_PATH}/Continent`;
export const UPDATE_CONTINENT_DETAILS = () => `${CONTRACT_PATH}/Continent`;
export const DELETE_CONTINENT_DETAILS = (id) => `${CONTRACT_PATH}/Continent/${id}`;
export const GET_CONTINENT_BY_NAME = (Name) => `${CONTRACT_PATH}/Continent/${Name}`;

export const ADD_REGISTRATION_DETAILS = () => `${CONTRACT_PATH}/Registration`;

//Profile details API
export const GET_ALL_PROFILE_DETAILS = () => `${CONTRACT_PATH}/profiledetails`;
export const GET_PROFILE_DETAILS = (id) => `${CONTRACT_PATH}/profiledetails/${id}`;
export const ADD_PROFILE_DETAILS = () => `${CONTRACT_PATH}/profiledetails`;
export const UPDATE_PROFILE_DETAILS = () => `${CONTRACT_PATH}/profiledetails`;
export const DELETE_PROFILE_DETAILS = (id) => `${CONTRACT_PATH}/profiledetails/${id}`;
export const GET_PROFILE_BY_NAME = (Name) => `${CONTRACT_PATH}/profiledetails/${Name}`;

//Country API
export const GET_ALL_COUNTRY = () => `${CONTRACT_PATH}/country`;
export const GET_COUNTRY = (id) => `${CONTRACT_PATH}/country/${id}`;
export const ADD_COUNTRY = () => `${CONTRACT_PATH}/country`;
export const UPDATE_COUNTRY = () => `${CONTRACT_PATH}/country`;
export const DELETE_COUNTRY = (id) => `${CONTRACT_PATH}/country`;
export const GET_COUNTRY_BY_NAME = (Name) => `${CONTRACT_PATH}/country/${Name}`;
export const SEARCH_COUNTRY = () => `${CONTRACT_PATH}/country/SearchCountry`;

//City API
export const GET_ALL_CITY = () => `${CONTRACT_PATH}/city`;
export const GET_CITY = (id) => `${CONTRACT_PATH}/city/${id}`;
export const ADD_CITY = () => `${CONTRACT_PATH}/city`;
export const UPDATE_CITY = () => `${CONTRACT_PATH}/city`;
export const DELETE_CITY = () => `${CONTRACT_PATH}/city`;
export const SEARCH_CITY = () => `${CONTRACT_PATH}/city/searchcity`;

//Airport API
export const GET_ALL_AIRPORT = () => `${CONTRACT_PATH}/airport`;
export const GET_AIRPORT = (id) => `${CONTRACT_PATH}/airport/${id}`;
export const ADD_AIRPORT = () => `${CONTRACT_PATH}/airport`;
export const UPDATE_AIRPORT = () => `${CONTRACT_PATH}/airport`;
export const DELETE_AIRPORT = () => `${CONTRACT_PATH}/airport`;
export const SEARCH_AIRPORT = () => `${CONTRACT_PATH}/airport/searchairport`;

//Airline API
export const GET_ALL_AIRLINE = () => `${CONTRACT_PATH}/airline`;
export const GET_AIRLINE = (id) => `${CONTRACT_PATH}/airline/${id}`;
export const ADD_AIRLINE = () => `${CONTRACT_PATH}/airline`;
export const UPDATE_AIRLINE = () => `${CONTRACT_PATH}/airline`;
export const DELETE_AIRLINE = () => `${CONTRACT_PATH}/airline`;
export const SEARCH_AIRLINE = () => `${CONTRACT_PATH}/airline/searchairline`;
export const SEARCH_ALL_AIRLINE = () => `${CONTRACT_PATH}/airline/allairline`;

//EncryptData API
export const ENCRYPT_DATA = () => `${CONTRACT_PATH}/EncryptDecrypt/EncryptData`;
export const DECRYPT_DATA = () => `${CONTRACT_PATH}/EncryptDecrypt/DecryptData`;

//Office API
export const GET_ALL_OFFICE = () => `${CONTRACT_PATH}/OfficeDetails`;
export const GET_OFFICE = (id) => `${CONTRACT_PATH}/OfficeDetails/${id}`;
export const ADD_OFFICE = () => `${CONTRACT_PATH}/OfficeDetails`;
export const UPDATE_OFFICE = () => `${CONTRACT_PATH}/OfficeDetails`;
export const DELETE_OFFICE = () => `${CONTRACT_PATH}/OfficeDetails`;
export const SEARCH_OFFICE = () => `${CONTRACT_PATH}/OfficeDetails/SearchOfficeDetails`;
export const SEARCH_OFFICE_LIST = () => `${CONTRACT_PATH}/OfficeDetails/SearchOfficeList`;
export const SEARCH_COMMON_LIST = () => `${CONTRACT_PATH}/commonlist/searchcommonlist`;

//User API
export const GET_ALL_USER = () => `${CONTRACT_PATH}/userDetails`;
export const GET_USER = (id) => `${CONTRACT_PATH}/userDetails/${id}`;
export const ADD_USER = () => `${CONTRACT_PATH}/userDetails`;
export const UPDATE_USER = () => `${CONTRACT_PATH}/userDetails`;
export const DELETE_USER = () => `${CONTRACT_PATH}/userDetails`;
export const SEARCH_USER = () => `${CONTRACT_PATH}/UserDetails/SearchUserDetails`;
export const ROLE_OFFICE_WISE_LIST = () => `${CONTRACT_PATH}/RoleDetailsSetting/RoleOfficeWiseList`;

//State API
export const SEARCH_STATE = () => `${CONTRACT_PATH}/state/searchstate`;

//Currency API
export const SEARCH_CURRENCY = () => `${CONTRACT_PATH}/currency/searchcurrency`;

//2FA API
export const GENERATEBARCODE = () => `${CONTRACT_PATH}/TwoFactorAuthentication/GenerateBarCode`;
export const CHECKAUTHENTICATION = () => `${CONTRACT_PATH}/Authentication/CheckAuthentication`;
export const CHECKAUTHENTICATIONB2C = () => `${CONTRACT_PATH}/Authentication/CheckAuthenticationB2C`;
export const AUTHENTICATIONWITHLOGIN = () => `${CONTRACT_PATH}/Authentication/TravelAgentLoginWithID`;
//OTP API
export const OTPAUTHENTICATION = () => `${CONTRACT_PATH}/OTPAuthentication`;
export const OTPAUTHENTICATIONCHECK = () => `${CONTRACT_PATH}/OTPAuthentication/CheckOTP`;

//Role API
export const GET_ALL_ROLE = () => `${CONTRACT_PATH}/RoleDetailsSetting`;
export const GET_ROLE = (id) => `${CONTRACT_PATH}/RoleDetailsSetting/${id}`;
export const ADD_ROLE = () => `${CONTRACT_PATH}/RoleDetailsSetting`;
export const UPDATE_ROLE = () => `${CONTRACT_PATH}/RoleDetailsSetting`;
export const DELETE_ROLE = () => `${CONTRACT_PATH}/RoleDetailsSetting`;
export const GET_ROLE_NAME = (name) => `${CONTRACT_PATH}/RoleDetailsSetting/${name}`;
export const GET_ALL_ROLE_DETAILS = (id) => `${CONTRACT_PATH}/RoleDetails/${id}`;
export const GET_ROLE_LIST = () => `${CONTRACT_PATH}/RoleDetailsSetting/getRoleList'`;

//Customize Setting Api
export const GET_CUSTOMIZESETTING = (id) => `${CONTRACT_PATH}/CustomizeSetting/${id}`;
export const ADD_CUSTOMIZE = () => `${CONTRACT_PATH}/CustomizeSetting`;
export const UPDATE_CUSTOMIZE = () => `${CONTRACT_PATH}/CustomizeSetting`;
export const GET_CUSTOMIZE = (id) => `${CONTRACT_PATH}/Customize/${id}`;

//Region API
export const GET_ALL_REGION = () => `${CONTRACT_PATH}/Region`;
export const GET_REGION = (id) => `${CONTRACT_PATH}/Region/${id}`;
export const ADD_REGION = () => `${CONTRACT_PATH}/Region`;
export const UPDATE_REGION = () => `${CONTRACT_PATH}/Region`;
export const DELETE_REGION = () => `${CONTRACT_PATH}/Region`;
export const GET_REGION_NAME = (name) => `${CONTRACT_PATH}/Region/${name}`;
export const SEARCH_REGION = () => `${CONTRACT_PATH}/Region/SearchRegion`;
export const REMOVE_REGION = () => `${CONTRACT_PATH}/Region/RemoveNode`;

//Content API
export const GET_COMMONLIST_BY_NAME = (NAME) => `${CONTRACT_PATH}/CommonList/${NAME}`;
export const GET_CONTENT = (id) => `${CONTRACT_PATH}/Content/${id}`;
export const ADD_CONTENT = () => `${CONTRACT_PATH}/Content`;
export const SEARCH_CONTENT = () => `${CONTRACT_PATH}/Content/SearchContent`;
export const UPDATE_CONTENT = () => `${CONTRACT_PATH}/Content`;
//export const SEARCH_REGION_List=()=>`${CONTRACT_PATH}/Region/SearchRegion`;

//Credit Limit API
export const GET_CREDIT_LIMIT = (id) => `${CONTRACT_PATH}/creditLimit/${id}`;
export const ADD_CREDIT_LIMIT = () => `${CONTRACT_PATH}/creditLimit`;
export const UPDATE_CREDIT_LIMIT = () => `${CONTRACT_PATH}/creditLimit`;
export const DELETE_CREDIT_LIMIT = () => `${CONTRACT_PATH}/creditLimit`;
export const SEARCH_CREDIT_LIMIT = () => `${CONTRACT_PATH}/creditLimit/SearchCreditLimit`;

//Configuration API
export const GET_CONFIGUARTION = (id) => `${CONTRACT_PATH}/ConfigurationsSettings/${id}`;
export const UPDATE_CONFIGURATION = () => `${CONTRACT_PATH}/ConfigurationsSettings`;
export const SERACH_CONIGURATION = () => `${CONTRACT_PATH}/ConfigurationsSettings/SearchConfigurationsSettings`;
export const ADD_CONFIGURATION = () => `${CONTRACT_PATH}/ConfigurationsSettings`;

//Fee API
export const GET_ALL_FEE = () => `${CONTRACT_PATH}/FeeDetails`;
export const GET_FEE = (id) => `${CONTRACT_PATH}/FeeDetails/${id}`;
export const ADD_FEE = () => `${CONTRACT_PATH}/FeeDetails`;
export const UPDATE_FEE = () => `${CONTRACT_PATH}/FeeDetails`;
export const DELETE_FEE = () => `${CONTRACT_PATH}/FeeDetails`;
export const SEARCH_FEE = () => `${CONTRACT_PATH}/FeeDetails/SearchFeeDetails`;
export const SEARCH_HOTELCHAIN_LIST = () => `${CONTRACT_PATH}/HotelChain/SearchHotelChain`;
export const SEARCH_HOTEL_EXCLUSIVE_DEALS_LIST = () => `${CONTRACT_PATH}/ExclusiveDeals/SearchExclusiveDeals`;
export const SEARCH_HOTEL_NAME = () => `${CONTRACT_PATH}/HotelNameDetails/SearchHotelNameDetails`;
//user login session check
export const USER_LOGIN_SESSION = () => `${CONTRACT_PATH}/SearchLoginUserSession`;


//Email Server API
export const GET_EMAIL_SERVER = (id) => `${CONTRACT_PATH}/CustomizeEmailSetting/${id}`;
export const ADD_EMAIL_SERVER = () => `${CONTRACT_PATH}/CustomizeEmailSetting`;
export const UPDATE_EMAIL_SERVER = () => `${CONTRACT_PATH}/CustomizeEmailSetting`;

//Search Flight
export const SEARCH_FLIGHT = () => `${CONTRACT_PATH}/FlightSearch`;
export const GET_AIRPORT_LIST = (requestData) => `${CONTRACT_PATH}/FlightListMethod/FromToList/${requestData}`;
export const GET_ALL_AIRPORT_LIST = () => `${CONTRACT_PATH}/FlightListMethod/AllFromToList`;
export const GET_FARE_RULE = () => `${CONTRACT_PATH}/FareRule`;
export const GET_BLOCK_COUNTRY_CITY_LIST = () => `${CONTRACT_PATH}/FlightListMethod/BlockCountryCityList`;
export const GET_ALL_SIGHTSEEING_LIST = () => `${CONTRACT_PATH}/FlightListMethod/AllSightseeingList`;

//Search Hotel
export const SEARCH_HOTEL = () => `${CONTRACT_PATH}/HotelSearch`;
export const DETAILS_HOTEL = () => `${CONTRACT_PATH}/HotelDetails`;
export const HOTEL_PRICE_CHECK = () => `${CONTRACT_PATH}/HotelPriceCheck`;
export const HOTEL_BOOKING = () => `${CONTRACT_PATH}/CreateBookingHotel`;

//Book Flight
export const BOOK_FLIGHT = () => `${CONTRACT_PATH}/FlightBook`;

// Flight Recommendation
export const FLIGHTRECOMMENDATION = () => `${CONTRACT_PATH}/FlightRecommendation`;
export const FLIGHTRECOMMENDATIONS = () => `${CONTRACT_PATH}/FlightRecommendations`;

//Booking Confirmation
export const BOOKINGCONFIRMATION = () => `${CONTRACT_PATH}/BookingConfirmation`;
export const FLIGHTDETAILS = () => `${CONTRACT_PATH}/FlightDetails`;
export const BOOKING = () => `${CONTRACT_PATH}/CreateBooking`;
export const SHOPPING_CART_BOOKING = () => `${CONTRACT_PATH}/Booking`;
export const IMPORT_PNR_BOOKING = () => `${CONTRACT_PATH}/ImportPNR`;
export const IMPORT_TICKET = () => `${CONTRACT_PATH}/ImportTicket`;
export const BLOCK_TICKET = () => `${CONTRACT_PATH}/BlockTicketing/SearchBlockTicketing`;
export const REVIEW_BOOKING = () => `${CONTRACT_PATH}/ReviewBooking`;

//Common
export const GET_AIRPROGRAM_DATA = () => `${CONTRACT_PATH}/AirProgramList`;
export const GEt_CREDIT_CARD_DATA = () => `${CONTRACT_PATH}/CreditCardDetails`;
export const GET_SERVICE_REQUEST_DATA = () => `${CONTRACT_PATH}/SpecialServiceRequestDetails`;
export const GET_MEAL_DETAILS_DATA = () => `${CONTRACT_PATH}/MealDetails`;
export const GET_API_KEY_DATA = () => `${CONTRACT_PATH}/APIKeys/SearchAPIKeys`;

//Seat map
export const GET_SEATMAP = () => `${CONTRACT_PATH}/SeatMap`;

//send Mail
export const Send_Mail = () => `${CONTRACT_PATH}/SendEmail`;

//My Trip
export const SEARCH_MYTRIP = () => `${CONTRACT_PATH}/Mytrips/SearchMyTrip`;

//Retrieve Trip
export const RETRIEVE_TRIP = (searchData) => `${CONTRACT_PATH}/Retrieve/${searchData}`;
export const HOTEL_RETRIEVE_TRIP = (searchData) => `${CONTRACT_PATH}/HotelRetrieve/${searchData}`;
export const FLIGHT_PLUS_HOTEL_RETRIEVE_TRIP = (searchData) => `${CONTRACT_PATH}/ShoppingCartBooking/${searchData}`;
export const SHOPPING_CART_SEND_EMAIL = () => `${CONTRACT_PATH}/SendShoppingCartEmail`;
export const GDS_RETRIEVE = () => `${CONTRACT_PATH}/GDSRetrieve`;

//Cancel Trip
export const CANCEL_TRIP = () => `${CONTRACT_PATH}/Cancellation/FlightCancellation`;
export const CANCEL_HOTEL = () => `${CONTRACT_PATH}/Cancellation/HotelCancellation`;
export const HOTEL_SEND_EMAIL = () => `${CONTRACT_PATH}/HotelSendEmail`;
export const CANCEL_TRIP_SHOPPING_CART = () => `${CONTRACT_PATH}/Cancellation`;
export const CANCEL_VOID_TRIP = () => `${CONTRACT_PATH}/Cancellation/CancelTicketingQueue`;


//update notes
export const UPDATE_NOTES = () => `${CONTRACT_PATH}/UpdateRemarks`;
export const GET_NOTES = () => `${CONTRACT_PATH}/UpdateRemarks/SearchRemarks`;

//get history
export const GET_HISTORY = (id) => `${CONTRACT_PATH}/HistoryDetails/${id}`;
export const GET_HISTORY_IMPORT_PNR = (id) => `${CONTRACT_PATH}/ImportPNRHistory/${id}`;

//get Logs
export const SEARCH_LOGS = () => `${CONTRACT_PATH}/FailedMessage/SearchFailedMessage`;

//Rebooking Trip
export const REBOOKING_TRIP = () => `${CONTRACT_PATH}/Reebooking`;
export const SEARCH_REBOOKING = () => `${CONTRACT_PATH}/Reebooking/SearchRebooking`;

//Reports API
export const SEARCH_FLIGHT_BOOKING = () => `${CONTRACT_PATH}/FlightBooking/Search`;
export const SEARCH_SALES_BOOKING = () => `${CONTRACT_PATH}/sales/Search`;
export const SEARCH_HOTEL_BOOKING = () => `${CONTRACT_PATH}/HotelBooking/Search`;
export const SEARCH_WALLET_UTILIZATION = () => `${CONTRACT_PATH}/Wallet/Search`;

export const GET_CRYPITC_COMMAND = () => `${CONTRACT_PATH}/Command`;
export const GET_CRYPITC_COMMAND_WITH_CLOSE = () => `${CONTRACT_PATH}/CommandWithCloseConnection`;
export const CLOSE_CRYPITC_COMMAND = () => `${CONTRACT_PATH}/SoapSessionClose`;

//FEES API
export const GET_FEES = (id) => `${CONTRACT_PATH}/FeesDetails/${id}`;
export const ADD_FEES = () => `${CONTRACT_PATH}/FeesDetails`;
export const DELETE_FEES = () => `${CONTRACT_PATH}/FeesDetails`;
export const SEARCH_FEES = () => `${CONTRACT_PATH}/FeesDetails/SearchFees`;
export const UPDATE_FEES = () => `${CONTRACT_PATH}/FeesDetails`;
export const GET_CONTENTWISE_REGION = () => `${CONTRACT_PATH}/Content/ContentWiseResionList`;
export const GET_FROM_REGION = () => "";

//USER_FRIENDLY API
export const GET_USER_FRIENDLY = (id) => `${CONTRACT_PATH}/UserFriendlyMessage/${id}`;
export const ADD_USER_FRIENDLY = () => `${CONTRACT_PATH}/UserFriendlyMessage`;
export const SEARCH_USER_FRIENDLY = () => `${CONTRACT_PATH}/UserFriendlyMessage/SearchWithField`;
export const UPDATE_USER_FRIENDLY = () => `${CONTRACT_PATH}/UserFriendlyMessage`;

//Wallet
export const SEARCH_WALLET = () => `${CONTRACT_PATH}/creditLimit/SearchWallte`;
export const ADD_WALLET = () => `${CONTRACT_PATH}/creditLimit`;
export const UPDATE_WALLET = () => `${CONTRACT_PATH}/creditLimit`;

//Manage Rate Plan Allowed
export const SEARCH_RATE_PLAN_ALLOWED = () => `${CONTRACT_PATH}/RatePlanAllowed/SearchRatePlanAllowed`;
export const ADD_RATE_PLAN_ALLOWED = () => `${CONTRACT_PATH}/RatePlanAllowed`;
export const UPDATE_RATE_PLAN_ALLOWED = () => `${CONTRACT_PATH}/RatePlanAllowed`;
export const GET_RATE_PLAN_ALLOWED = (id) => `${CONTRACT_PATH}/RatePlanAllowed/${id}`;
export const SEARCH_PCC_LIST = () => `${CONTRACT_PATH}/Content/GetPCCServiceWise`;
export const SEARCH_RATEPLAN_LIST = () => `${CONTRACT_PATH}/HotelRateCode/SearchHotelRateCode`;
export const SEARCH_RATEPLAN_LIST_OFFICE_WISE = () => `${CONTRACT_PATH}/HotelRateCode/SearchHotelRateCodeOfficeWise`;
export const GET_ALL_COMPANY_LIST = () => `${CONTRACT_PATH}/OfficeDetails/SearchAllOfficeWise`;
export const SEARCH_RATEPLAN_PCC_WISE_LIST = () => `${CONTRACT_PATH}/RatePlanAllowed/OfficeWisePCC`;

export const SEND_EMAIL = () => `${CONTRACT_PATH}/EmailSent`;

//Manage RatePlan
export const SEARCH_RATE_PLAN = () => `${CONTRACT_PATH}/HotelRateCode/SearchHotelRateCode`;
export const ADD_RATE_PLAN = () => `${CONTRACT_PATH}/HotelRateCode`;
export const UPDATE_RATE_PLAN = () => `${CONTRACT_PATH}/HotelRateCode`;
export const GET_RATE_PLAN = (id) => `${CONTRACT_PATH}/HotelRateCode/${id}`;
export const DELETE_RATE_PLAN = () => `${CONTRACT_PATH}/HotelRateCode`;

//Tax Master
export const SEARCH_TAX_MASTER = () => `${CONTRACT_PATH}/TaxMaster/SearchTaxMaster`;

//Flight Proposal
export const SEND_EMAIL_Proposal = () => `${CONTRACT_PATH}/SendProposalEmail`;

//Hotel Proposal
export const SEND_HOTEL_EMAIL_Proposal = () => `${CONTRACT_PATH}/HotelSendProposalEmail`;

//Sightseeing
export const GET_ALL_SIGHTSEEING = () => `${CONTRACT_PATH}/SightseeingDetails/SearchSightseeingDetails`;
export const GET_SIGHTSEEING = (name) => `${CONTRACT_PATH}/SightseeingDetails/${name}`;

//ROE
export const GET_ALL_ROE = () => `${CONTRACT_PATH}/ROEMaster`;
export const GET_ROE = (id) => `${CONTRACT_PATH}/ROEMaster/${id}`;
export const ADD_ROE = () => `${CONTRACT_PATH}/ROEMaster`;
export const UPDATE_ROE = () => `${CONTRACT_PATH}/ROEMaster`;
export const DELETE_ROE = () => `${CONTRACT_PATH}/ROEMaster`;
export const GET_ROE_NAME = (name) => `${CONTRACT_PATH}/ROEMaster/${name}`;
export const SEARCH_ROE = () => `${CONTRACT_PATH}/SearchROEMaster`;

