import FeesForm from "./Components/FeesForm";
import { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import useHttp from "../../../services/use-http";
import useHttpGet from "../../../services/use-http";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { addFees, updateFees, getSingleFees } from "../../../services/Fees-API";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
const UpdateFees = (props) => {
    const [serviceID, setServiceID] = useState('')
    useDocumentTitle(" Fee");
    const [error, setError] = useState();
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const history = useHistory();
    const { Id, Action } = parms;
    const { t } = useTranslation(['translation.Fees']);
    let varHttp;

    if (Id === undefined) {
        varHttp = addFees;
    } else {
        varHttp = updateFees;
    }
    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }
    const { sendRequest: sendRequestFees, data: loadedFeesDetails, status: getFeesStatus, error: getFeesError } = useHttpGet(getSingleFees);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (props.location.edit !== undefined) {
        sessionStorage.setItem('isEditable', props.location.edit);
    } else if (Id !== undefined) {
        if (!checkSecurityDetails("Master", "Fee", "Master_Fee_Update") && props.location.edit === 1)
            history.push('/Unauthorized')
        else if (!checkSecurityDetails("Master", "Fee", "Master_Fee_View") && props.location.edit === 0)
            history.push('/Unauthorized')
    }

    if (props.loadedFeesDetails === undefined && props.location.pathname === '/UpdateFees') {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {

                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: serviceID === '20' ? `${t('errorFlightDuplicateRecord')}` : `${t('errorHotelDuplicateRecord')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }

                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });

                }

                return;

            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])
    useEffect(() => {

        if (Id !== undefined) {
            sendRequestFees(Id)
        }
    }, [sendRequestFees, Id])

    if (getFeesStatus === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    if (getFeesError) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }



    let loadedData = [];
    for (let i in loadedFeesDetails) {
        if (loadedFeesDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedFeesDetails[i];
            }
        }
    }
    // console.log(loadedData);
    const addFeesHandler = (requestData) => {
        console.log(requestData);
        setServiceID(requestData.serviceID);
        sendRequest(requestData);

    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchFees');
        }
    };
    const FeesFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p className="text-primary text-center h5">{t('contentDetailsError')}</p> :
        <FeesForm loadedFeesDetails={loadedData} isLoading={status === 'pending'} onAddFees={addFeesHandler}
            IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />


    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {FeesFormContent}
    </Fragment>
};
export default UpdateFees;