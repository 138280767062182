import React from "react";
import { Fragment } from "react";

const Blank = () => {
  return (
    <>
      {/* Page Not Found! */}
  
  

     <Fragment>

        <section className="gt_coming_soon_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        <div className=" text-end">
                            <div className="title text-center  py-5">
                                <h1 className="text-white h1 my-3"><i className="fas fa-globe-asia"></i> This feature is coming soon.</h1>
                                {/* <p className="text-white">lorem ipsum In publishing and graphic design,<br></br> Lorem ipsum is a placeholder text commonly used.</p>
                                <p className="mt-4 text-white">Stay Connected</p>
                                <p className="">
                                <span><a href="#!" className="text-white ms-1"><i  className="fab fa-instagram"></i></a> </span>
                                <span><a href="#!" className="text-white ms-1"><i  className="fab fa-linkedin"></i></a></span>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
              
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            <div className="card-body">
                                <div className="col-md-12 gt-country-details-form">
                                    <div className="title text-center">
                                        <h1><i className="fas fa-globe-asia"></i> Coming Soon...!</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                   
            </div>
        </section> */}
       
    </Fragment>
    </>
  );
};
export default Blank;
