import RegistrationForm from './Components/RegistrationForm'

import useHttp from '../../services/use-http'
import { addRegistration } from '../../services/Registration-API'
import { Fragment, useEffect,useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorModal from "../../components/Common/Error/ErrorModal";
const Registration = () => {
  const { sendRequest, status } = useHttp(addRegistration);
  const [error1, setError] = useState();
  const history = useHistory();
  const sucsssMessage='Please note Travel agent register scussfully. Thank You.';

  useEffect(() => {
    if (status === 'completed') {
      setError({
        title: "Message",
        message: `${sucsssMessage}`,
      });
      return;
   
    }
  }, [status, history])
  const addRegistrationHandler = (requestData) => {
    sendRequest(requestData);
    //history.push('/quotes')
  };/*  */
  const errorHandler = () => {
    setError(null);
    history.push('/registration');
  };
  return (<Fragment>
     {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
              
{/*     <!-- Common Banner Area --> */}
    <section id="common_banner">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                    <div  className="common_bannner_text">
                        <h2>Travel Agent Registration</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span>Travel Agent Registration</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <RegistrationForm isLoading={status === 'pending'} onAddRegistration={addRegistrationHandler} />
  </Fragment>)
}

export default Registration;