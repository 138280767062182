import { Fragment } from 'react';
import { numberWithCommas } from '../../../../utils/CommonFunction';

const SegmentItem = (props) => {

    return <Fragment>
        <tr>
            <td>{props.reportJSON.service === 'FL' ? 'Flight' : props.reportJSON.service === 'HT' ? 'Hotel' : props.reportJSON.service === 'Total' ? 'Total' : ''}</td>
            <td scope="row" align={'right'}>{props.reportJSON.service === 'FL' || props.reportJSON.service === 'Total' ? props.reportJSON.booked !== '' ? numberWithCommas(props.reportJSON.booked) : 'N/A' : 'N/A'}</td>
            <td scope="row" align={'right'}>{props.reportJSON.sendRequest !== '' ? numberWithCommas(props.reportJSON.sendRequest) : 'N/A'}</td>
            <td scope="row" align={'right'}>{props.reportJSON.service === 'FL' || props.reportJSON.service === 'Total' ? numberWithCommas(props.reportJSON.ticketed) : numberWithCommas(props.reportJSON.booked)}</td>
            <td scope="row" align={'right'}>{props.reportJSON.cancelled !== '' ? numberWithCommas(props.reportJSON.cancelled) : 'N/A'}</td>
            {props.AllowImportPNR && <td scope="row" align={'right'}>{props.reportJSON.modified !== '' && props.reportJSON.service !== 'HT'  ? props.reportJSON.modified : 'N/A'}</td>}
            <td scope="row" align={'right'}>{props.reportJSON.total !== '' ? numberWithCommas(props.reportJSON.total) : 'N/A'}</td>
        </tr>
    </Fragment>

};

export default SegmentItem;