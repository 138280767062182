// import { Fragment } from 'react';
import { Fragment } from 'react';
import WalletUtilizationItem from './WalletUtilizationItem';
const WalletUtilizationList = (props) => {
    // if (Object.keys(props.currentItems).length > 0) {
    //     props.currentItems.push({
    //         "shoppingID": "",
    //         "sequenceNo": "0",
    //         "confirmationNumber": "",
    //         "bookingData": "",
    //         "officeID": "",
    //         "companyName": "",
    //         "passengerName": "",
    //         "passengerCount": "",
    //         "bookingType": "",
    //         "departureDate": "",
    //         "arrivalDate": "",
    //         "from": "",
    //         "to": "",
    //         "validating": "",
    //         "airline": "",
    //         "rbd": "",
    //         "cabin": "",
    //         "source": "",
    //         "pnr": "",
    //         "airlinePNR": "",
    //         "ticketNumber": "",
    //         "status": "",
    //         "currencyCode": "",
    //         "baseFare": "",
    //         "taxes": "",
    //         "markup": "",
    //         "totalFare": "",
    //         "commission": "",
    //         "formOfPayment": "",
    //         "userID": "",
    //         "equivTotalFare": props.currentItems.equivTotalFare !== undefined && props.currentItems.equivTotalFare !== '' ? props.currentItems.map(a => parseFloat(a.equivTotalFare)).reduce((equivTotalFare1, equivTotalFare2) => equivTotalFare1 + equivTotalFare2).toFixed(2) : '',
    //         "bookedBy": ""
    //     })
    // }

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    return <Fragment>

        <div className="col-lg-12" >{/* className="accordion-item"> */}
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12 table-responsive mt-4 table-wrapper'>
                            <table className="table table-bordered table-layout-design" border={1}>
                                <thead>
                                    <tr>
                                        <th scope='col' className='text-end w-15'>#</th>
                                        <th scope="col" className='w-15'>Conf. No.</th>
                                        <th scope="col" className='min-width-185'>Transaction Date</th>
                                        <th scope="col" className='min-width-185'>Company Name</th>
                                        <th scope="col" className=''>Service</th>
                                        <th scope="col" className='w-15'>Transaction Type</th>
                                        <th scope="col" className='w-15'>Currency</th>
                                        <th scope="col" className='min-width-125' align={'left'}>Txn. Amount</th>
                                        <th scope="col" className='min-width-125'>Wallet Limit</th>
                                        <th scope="col" className=''>Remarks</th>
                                        <th scope="col" className='min-width-165' align={'left'}>Done By</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {props.currentItems.map((currentItems) =>
                                        <WalletUtilizationItem key={currentItems.sequenceNo}
                                            id={currentItems.sequenceNo}
                                            reportJSON={currentItems}></WalletUtilizationItem>)}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default WalletUtilizationList;