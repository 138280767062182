import SeatMap from './../../../components/Common/Flight/SeatMap';
import { useState } from 'react';

const SeatSelection = (props) => {
    const [finalSeatAmountTotal, setFinalSeatAmountTotal] = useState(0.00);
    const [intialBookingJSON, setIntialBookingJSON] = useState([]);

    function assignSeatMap() {
        props.onContinueButton("3");
        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        let seatMapJSON = JSON.parse(localStorage.getItem("SeatMapData"));
        let SeatRequest = [];
        if (intialBookingJSON.length === 0) {
            setIntialBookingJSON(JSON.parse(localStorage.getItem("BookingData")));
        }

        if (seatMapJSON !== null && seatMapJSON !== undefined && seatMapJSON.length > 0) {
            SeatRequest = seatMapJSON;
            bookingJSON.data.travelerInfo.SpecialReqDetails.forEach(element => {
                element.SeatRequests = { SeatRequest };
            });

            const seatAmount = seatMapJSON.map(element => parseFloat(element.Amount));
            let sum = (prev, cur) => {
                return prev + cur;
            }
            const seatAmountTotal = parseFloat(seatAmount.reduce(sum, 0));
            setFinalSeatAmountTotal(seatAmountTotal);

            if (seatAmountTotal > 0) {
                //User Story 1073: Booking Summary for Single PNR
                document.querySelector('#dvOtherServicesAmountFlight').style.display = "";
                var totalFareAmount = 0.00;
                if (intialBookingJSON.length === 0) {
                    bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(seatAmountTotal);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(2);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    });
                }
                else {
                    intialBookingJSON.data.airItinerary.forEach(function (elementAirItinerary, index) {
                        bookingJSON.data.airItinerary[index].airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(seatAmountTotal);
                        bookingJSON.data.airItinerary[index].airItineraryPricingInfo.totalFare.amount = bookingJSON.data.airItinerary[index].airItineraryPricingInfo.totalFare.amount.toFixed(2);
                        totalFareAmount = bookingJSON.data.airItinerary[index].airItineraryPricingInfo.totalFare.amount;
                    });
                }

                let otherServiceSeatAmount = 0;
                let otherServiceMarupAmount = 0;

                otherServiceSeatAmount = seatAmountTotal;

                //User Story 1073: Booking Summary for Single PNR
                if (document.querySelector('#spnMarkupAmountFlight').innerHTML.length > 1) {
                    if (document.querySelector('#spnMarkupAmountFlight').innerHTML.indexOf('&nbsp;') !== -1) {
                        otherServiceMarupAmount = document.querySelector('#spnMarkupAmountFlight').innerHTML.split('&nbsp;')[1];
                    }
                    else {
                        otherServiceMarupAmount = document.querySelector('#spnMarkupAmountFlight').innerHTML.substring(1, document.querySelector('#spnMarkupAmountFlight').innerHTML.length);
                    }
                }

                let otherServiceTotal = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmount);

                document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
                }).format(otherServiceTotal.toFixed(2));

                document.querySelector('#spnSeatsAmount').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
                }).format(seatAmountTotal);

                document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
                }).format(parseFloat(totalFareAmount).toFixed(2));
            }
            else {
                document.querySelector('#dvOtherServicesAmountFlight').style.display = "";
            }
        }
        else {
            bookingJSON.data.travelerInfo.SpecialReqDetails.forEach(element => {
                element.SeatRequests = null;
            });

            //User Story 1073: Booking Summary for Single PNR
            let otherServicesAmount = document.querySelector('#dvOtherServicesAmountFlight').innerHTML.replace(',', '');

            if (otherServicesAmount.indexOf('&nbsp;') !== -1) {
                otherServicesAmount = otherServicesAmount.split('&nbsp;')[1];
            }
            else {
                otherServicesAmount = otherServicesAmount.substring(1, otherServicesAmount.length);
            }


            var otherServicesAmount2 = parseFloat(otherServicesAmount) - parseFloat(finalSeatAmountTotal);

            document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(otherServicesAmount2.toFixed(2));

            document.querySelector('#spnSeatsAmount').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format('0.00');

            var totalFareAmount = 0.00;
            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                totalFareAmount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalBaseFare.amount) + parseFloat(elementAirItinerary.airItineraryPricingInfo.totalTax.amount) + parseFloat(otherServicesAmount2);
                elementAirItinerary.airItineraryPricingInfo.totalFare.amount = totalFareAmount.toFixed(2);
            });

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(2));
        }

        //localStorage.removeItem("SeatMapData");
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        console.log(bookingJSON);
        window.scrollTo({
            top: 580,
            behavior: "smooth",
        });
    }

    function skipSeatMap() {
        props.onContinueButton("3");
        //localStorage.removeItem("SeatMapData");
        window.scrollTo({
            top: 580,
            behavior: "smooth",
        });
    }

    return (
        <div className="accordion_flex_area">

            <div className="accordion_left_side position-relative text-muted">
                <h5>3</h5>
                <span id="completeSeatMap" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
            </div>
            <div className="accordion-item">

                <h2 className="accordion-header" >

                    <button id="headingSeatMap" className="text-muted accordion-button collapsed" type="button"
                        data-bs-target="#collapseSeatMap" aria-expanded="true"
                        aria-controls="collapseSeatMap">
                        Seat Selection
                    </button>
                </h2>
                <div id="collapseSeatMap" className="accordion-collapse collapse"
                    aria-labelledby="headingSeatMap" data-bs-parent="#accordionExample">
                    <div className='col-md-12'>
                        <SeatMap seatMap={props.seatMap} isReadOnlyMode={props.isReadOnlyMode} ></SeatMap>
                        <div className='row my-4'>
                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                <p><span className="error-icon"></span><span id="requiredIndicator"></span></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="float-md-end float-lg-end">
                                    <button type="button" onClick={skipSeatMap} className="btn  text-white btn-secondary mt-1 ms-1 btn-sm">Skip to Add-ons</button>
                                    <button type="button" onClick={assignSeatMap} className="btn  text-white gt-main-btn mt-1 ms-1 btn-sm">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
export default SeatSelection